import { without } from 'lodash';
import React, { useState } from 'react'

import { NavLink } from 'react-router-dom';

import {
  Container,
  Button,
  Row,
  Col
} from 'reactstrap';

import { FooterLogo, Facebook, Twitter, Youtube } from '@assets';

export default function Footer(props) {

  return (<>
    <Container fluid className={'footer py-4 ' + props.className}>
      <Container>
        <Row>
          <Col lg="12">
            <NavLink to="/"><img src={FooterLogo} alt="logo" className="img-fluid footer-branding" /></NavLink>
          </Col>

          {/* <Col md="12" className="my-5">
            <NavLink to="/signup"><Button size="lg" color="blue" className="hover-yellow">JOIN</Button></NavLink>
          </Col> */}
        </Row>

        <Row className="copyright-section mb-5">
          <Col lg="12">
            <hr className="w-100 mb-4" />
          </Col>
          <Col lg="4" className="mb-4 mb-lg-0">
            {/* <a href="https://www.termsfeed.com/live/b929e886-28b9-4284-bd1d-2e179cec8c5e" target="_blank">Terms of Service</a> */}
            <NavLink to="/terms-of-service">Terms of Service</NavLink>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            {/* <NavLink to="/security">Security</NavLink> */}
          </Col>
          <Col lg="4" className="text-lg-center mb-4 mb-lg-0">
            {/* <a href="https://facebook.com" target="blank"><img src={Facebook} alt="facebook" /></a>
            <a href="https://twitter.com" target="blank"><img src={Twitter} alt="Twitter" /></a>
            <a href="https://youtube.com" target="blank"><img src={Youtube} alt="Youtube" /></a> */}
          </Col>
          <Col lg="4" className="text-lg-right">
            <p>© {new Date().getFullYear()} SparkAPT All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  </>)
}
