import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
	Badge
} from "reactstrap";
import ReactPaginate from "react-paginate";
import { useQuery } from "@apollo/client";

import { Icon } from '@assets';
import { getNotificationColor } from "../shared/sparkLib/notificationTypes";

export default function NotificationList({ allNotifications=[] }) {
  const [offset, setOffset] = useState(0);
  const [selected_page, setselected_page] = useState(0);
  const [per_page] = useState(10);
  const [page_count, setPageCount] = useState(0);
  useEffect(() => {
    var count = allNotifications
      ? Math.ceil(allNotifications.length / per_page)
      : 0;
    setPageCount(count);
  }, [allNotifications]);


  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "short", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };
function handlePageClick(e) {
  const selected_page = e.selected;
  setselected_page(selected_page);
  const offset = selected_page * per_page;
  setOffset(offset);
}
  const formatTimeStamp = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    

    return new Intl.DateTimeFormat("default", options).format(
      Date.parse(dateString)
    );
  };

  return (
    <Container className="py-3">
      <Row>
        <Col md="12" className="mt-md-3">
          <h4>Notifications</h4>
        </Col>
        <Col md="12" className="mt-md-3">
        {allNotifications?.length > 0 &&
        allNotifications
          .slice(offset, per_page + offset)
          .map(({ id, title, description, updatedAt, notificationType }, i) => (
            <Card className="notification-box" key={id} style={{backgroundColor: getNotificationColor(notificationType)}}>
              <CardBody>
                <Row>
                  <Col md="8">
                    <h5>{title}&nbsp;
											{(new Date - new Date(updatedAt) < 604800000) && 
												<Badge color="primary" pill>
													New
												</Badge>
											}
										</h5>
                  </Col>
                  <Col md="4">
                    <p className="disabled text-md-right mb-0">{formatTimeStamp(updatedAt)}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <p className="mb-md-0">{description}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
          {page_count > 1 ? (
            <ReactPaginate
              previousLabel={<Icon icon="chevron-left" />}
              nextLabel={<Icon icon="chevron-right" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={page_count}
              marginPagesDisplayed={3}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={selected_page}
            />
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Container>
  );
}