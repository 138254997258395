import React, { useEffect, useState } from 'react';
import NavBar from './components/newNavbar';
import Footer from './components/newFooter';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

import '../assets/styles/Style.css';

import { Container, Row, Col, Button, Media, Badge } from 'reactstrap';

import {
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature5,
  AgentIcon,
  BrokerIcon,
  USMap,
  Icon,
} from '@assets';
import { ToggleButton } from '@sparklib';

import DivisionTitle, { DivisionSubTitle } from './components/divisionTitle';

import PricingBox from './components/pricingBox';

export default function NewHome(props) {
  const [isYearly, setIsYearly] = useState(false);

  return (
    <>
      <NavBar />

      {/* Hero Section */}
      <Container
        fluid
        className='hero d-flex align-items-center justify-content-center py-0'
      >
        <Container className='custom-container'>
          <Row>
            <Col lg='12' className='text-center'>
              <h1>
                The first modern{' '}
                <span className='highlighted-text underline'>
                  CRM + database
                </span>
                <br />
                for multifamily real estate agents and brokers.
              </h1>
              {/* <h6 className='my-4'>
                Smarter than Smart.
                <br />
                commissions and more in seconds.
              </h6> */}

              <NavLink to='/signup'>
                <Button color='green' size='md' className='btn-rounded'>
                  Start 3-Day Trial
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='bg-blue-shade py-5em'>
        <Container className='custom-container'>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                className='mb-5'
                title={
                  <>
                    A full suite of CRM and database tools for <br />
                    apartment locators, real estate agents and brokers.
                    {/* A fully integrated suite of{' '}
                    <span className='highlighted-text'>
                      agent and broker
                      <br />
                      tools
                    </span>{' '}
                    for the multifamily industry. */}
                  </>
                }
              />
              <NavLink to='/signup'>
                <Button color='green' size='md' className='btn-rounded'>
                  Start 3-Day Trial
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* SparkAPT Features Section */}
      <Container fluid className='py-5em'>
        <Container className='custom-container'>
          <Row className='mb-5em'>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                className='mb-4'
                title={
                  <>
                    {/* A <span className='highlighted-text'>Multipurpose</span>{' '}
                    Data Platform */}
                    SparkAPT allows licensed agents to{' '}
                    <span className='highlighted-text'>
                      find and share listings
                    </span>
                    <br />
                    that update automatically.
                  </>
                }
              />
              {/* <DivisionSubTitle
                title={
                  <>
                    SparkAPT gives you the power to dynamically understand
                    different types of data
                    <br />
                    throughout the apartment industry. The first tool of its
                    kind.
                  </>
                }
              /> */}
            </Col>
          </Row>

          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                className='mb-4'
                title={
                  <>
                    <span className='highlighted-text'>SparkAPT</span> Features
                  </>
                }
              />
            </Col>
          </Row>

          <Row className='features-box'>
            <Col lg='12' className='mb-4'>
              <div className='rounded-box'>
                <Media className='align-items-center'>
                  <Media left>
                    <Media src={Feature5} className='img-80' alt='Feature 5' />
                  </Media>
                  <Media body className='pl-3 pl-lg-4'>
                    {/* Save time by automatically sending guest cards to apartments instead of creating and emailing them on your own */}
                    Import all your contacts from your current CRM! You can also
                    gather leads from all your social media without having to
                    manually type any client details and run searches based on
                    their original inquiry, all in one click.
                  </Media>
                </Media>
              </div>
            </Col>

            <Col lg='12' className='mb-4'>
              <div className='rounded-box'>
                <Media className='align-items-center'>
                  <Media left>
                    <Media src={Feature1} className='img-80' alt='Feature 1' />
                  </Media>
                  <Media body className='pl-3 pl-lg-4'>
                    {/* Updated Daily Pricing and Availibility */}
                    Daily-updated pricing and availability along with floor plan
                    images, unit numbers and move-in dates.
                  </Media>
                </Media>
              </div>
            </Col>

            <Col lg='12' className='mb-4'>
              <div className='rounded-box'>
                <Media className='align-items-center'>
                  <Media left>
                    <Media src={Feature2} className='img-80' alt='Feature 2' />
                  </Media>
                  <Media body className='pl-3 pl-lg-4'>
                    {/* Built-in CRM */}A database tailored specifically for
                    apartment locator/broker processes such as automated guest
                    card templates and one-click tour scheduling that is shared
                    directly with apartment buildings.
                  </Media>
                </Media>
              </div>
            </Col>

            <Col lg='12' className='mb-4'>
              <div className='rounded-box'>
                <Media className='align-items-center'>
                  <Media left>
                    <Media src={Feature3} className='img-80' alt='Feature 3' />
                  </Media>
                  <Media body className='pl-3 pl-lg-4'>
                    {/* Search using several filters including year built, neighborhoods, Google rating and more */}
                    Simplified searching that's dynamic so results are shown
                    live, as you update your filters and client preferences,
                    instead of clicking back and forth between different pages.
                  </Media>
                </Media>
              </div>
            </Col>

            <Col lg='12' className='mb-4'>
              <div className='rounded-box'>
                <Media className='align-items-center'>
                  <Media left>
                    <Media src={Feature4} className='img-80' alt='Feature 4' />
                  </Media>
                  <Media body className='pl-3 pl-lg-4'>
                    {/* Automated texts to clients when their lease is up for renewal */}
                    Automated reminders for client followup.
                  </Media>
                </Media>
              </div>
            </Col>

            <Col lg='12' className='mt-5 text-center'>
              <NavLink to='/signup'>
                <Button color='green' size='md' className='btn-rounded'>
                  Start 3-Day Trial
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* Get start with us section */}
      <Container fluid className='bg-blue-shade py-5em'>
        <Container>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                className='mb-4'
                title={
                  <>
                    Ready to{' '}
                    <span className='highlighted-text'>get started?</span>
                  </>
                }
              />
              <DivisionSubTitle
                title={
                  <>
                    Create an account now as a licensed agent or broker to begin
                    using
                    <br />
                    SparkAPT. You can also contact us for a custom package for
                    your team.
                  </>
                }
              />
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col lg={{ size: 4, offset: 4 }} md='6'>
              <div className='rounded-box contact-section red text-center mb-2 mb-lg-0'>
                <img src={AgentIcon} alt='Agent Icon' className='img-80' />
                <h4 className='mt-4 mb-4'>Agent</h4>
                <ul className='list-unstyled'>
                  <li>
                    <Icon icon='check-circle' />{' '}
                    <p>Find pricing and availability in seconds.</p>
                  </li>
                  <li>
                    <Icon icon='check-circle' />{' '}
                    <p>Send data to clients and partners within your team.</p>
                  </li>
                  <li>
                    <Icon icon='check-circle' />{' '}
                    <p>Allow clients to schedule tours within the app.</p>
                  </li>
                </ul>
                <NavLink to='/signup'>
                  <Button color='red' size='md' className='btn-rounded'>
                    Start 3-Day Trial
                  </Button>
                </NavLink>
              </div>
            </Col>

            {/* <Col lg="4" md='6'>
              <div className="rounded-box contact-section blue text-center">
                <img src={BrokerIcon} alt="Agent Icon" className="img-80" />
                <h4 className="mt-4 mb-4">Broker</h4>
                <ul className="list-unstyled">
                  <li><Icon icon='check-circle' /> <p>Applicant lead tracking from your current integrations.</p></li>
                  <li><Icon icon='check-circle' /> <p>Applicant lead tracking from your current integrations.</p></li>
                  <li><Icon icon='check-circle' /> <p>Create and send guest cards automatically.</p></li>
                </ul>
                <NavLink to='/contact-us'>
                  <Button color='theme-blue' size='md' className='btn-rounded'>
                    Contact Sales
                  </Button>
                </NavLink>
              </div>
            </Col> */}
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5em'>
        <Container>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                className='mb-5'
                title={
                  <>
                    <span className='highlighted-text'>Providing data</span>{' '}
                    from these cities
                  </>
                }
              />
            </Col>
            <Col lg={{ size: 10, offset: 1 }} className='text-center'>
              <img src={USMap} alt='U.S.A Map' className='img-fluid' />
            </Col>
            <Col lg='12' className='text-center mt-4'>
              <Badge color='theme-red' className='py-2 px-4 mx-2 mt-2' pill>
                Chicago
              </Badge>
              <Badge color='theme-red' className='py-2 px-4 mx-2 mt-2' pill>
                Austin
              </Badge>
              <Badge color='theme-red' className='py-2 px-4 mx-2 mt-2' pill>
                Houston
              </Badge>
              <Badge color='theme-red' className='py-2 px-4 mx-2 mt-2' pill>
                DFW
              </Badge>
              <Badge color='theme-red' className='py-2 px-4 mx-2 mt-2' pill>
                San Antonio
              </Badge>
              <Badge color='theme-red' className='py-2 px-4 mx-2 mt-2' pill>
                Tampa
              </Badge>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='bg-blue-shade py-5em'>
        <Container className='custom-container'>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle className='mb-4' title={<>Plans & Pricing</>} />
              <DivisionSubTitle
                title={
                  <>
                    SparkAPT is the all-in-one platform that will give you the
                    tools,
                    <br />
                    support and resources you need to succeed with multifamily
                    data.
                  </>
                }
              />
            </Col>
          </Row>
          {/*<Row className='mb-3 mt-4'>
            <Col md='12'>
              <div className='mx-auto w-100 text-center'>
                <ToggleButton
                  selected={isYearly}
                  firstOption='Yearly'
                  secondOption='Monthly'
                  width='130px'
                  rounded
                  toggleSelected={() => {
                    setIsYearly(!isYearly);
                  }}
                />
                <br />
              </div>
            </Col>
          </Row>*/}

          <Row className='mt-4'>
            <Col lg='4' md='6'>
              <PricingBox
                text='The Essentials'
                planName='Bronze'
                price={isYearly ? '39' : '49'}
                billingType={isYearly ? 'Yearly' : 'Monthly'}
                cityAccess='1'
                buttonColor='theme-blue'
              />
            </Col>
            <Col lg='4' md='6'>
              <PricingBox
                textColor='red'
                text='Most Popular'
                planName='Silver'
                price={isYearly ? '49' : '59'}
                billingType={isYearly ? 'Yearly' : 'Monthly'}
                cityAccess='2'
                buttonColor='red'
              />
            </Col>
            <Col lg='4' md='6'>
              <PricingBox
                text='The Desirable'
                planName='Gold'
                price={isYearly ? '59' : '69'}
                billingType={isYearly ? 'Yearly' : 'Monthly'}
                cityAccess='All'
                buttonColor='theme-blue'
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer />
    </>
  );
}
