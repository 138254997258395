import {
  AboutUs,
  ContactUs,
  Error404,
  Features,
  ForgotPassword,
  Home,
  Login,
  Pricing,
  Privacy,
  Registration,
  ResetPassword,
  Security,
  Terms,
} from '@website';

import NewHome from '../../NewLanding/NewHome'

import Logout from '../../components/Logout';
import UserDashboard from '../../components/user/Dashboard';
import {
  Recommendation,
  ReportDetail,
  RequestSent,
  RequestTour,
} from '@recommendation';
import AgentSignUp from '../../website/AgentSignUp';
import AdminDashboard from '../../components/admin/AdminDashboard';
import AssistantDashboard from '../../components/assistant/AssistantDashboard';

import { Switch, Route } from 'react-router-dom';
import Inquiry from '../../website/Inquiry';
import NewInquiry from '../../website/NewInquiry';
import PropertyPage from '../../modules/Property/Properties/PropertyPage';
// exporting all routes from here
// Can still be refactored based on roles

export const allRoutes = (
  <Switch>
    <Route exact path='/' component={NewHome}></Route>
    <Route exact path='/home-old' component={Home}></Route>
    <Route exact path='/signup' component={Registration}></Route>
    <Route exact path='/login' component={Login}></Route>
    {/* <Route exact path='/about-us' component={AboutUs}></Route> */}
    <Route exact path='/contact-us' component={ContactUs}></Route>
    <Route exact path='/forgot-password' component={ForgotPassword}></Route>
    <Route exact path='/features-old' component={Features}></Route>
    <Route exact path='/terms-of-service' component={Terms}></Route>
    <Route exact path='/privacy-policy' component={Privacy}></Route>
    {/* <Route exact path='/security' component={Security}></Route> */}
    <Route exact path='/pricing-old' component={Pricing}></Route>
    <Route exact path='/recommendations' component={Recommendation}></Route>
    <Route exact path='/agent-sign-up' component={AgentSignUp}></Route>
    <Route exact path='/property-detail' component={ReportDetail}></Route>
    <Route exact path='/request-tour' component={RequestTour}></Route>
    <Route exact path='/request-sent' component={RequestSent}></Route>

    <Route exact path='/logout' component={Logout}></Route>
    <Route exact path='/reset-password' component={ResetPassword}></Route>

    {/* New Landing Page */}
    <Route exact path='/new' component={NewHome}></Route>

    <Route exact path='/inquiry/:agentName' component={Inquiry}></Route>
    <Route exact path='/new-inquiry/:agentName' component={NewInquiry}></Route>

    {/* Admin Routes */}
    <Route
      path='/admin-dashboard'
      render={(props) => <AdminDashboard path='home' {...props} />}
      exact
    />
    <Route
      path='/admin-properties/:city_name?/:neighborhood_name?/:id?'
      render={(props) => <AdminDashboard path='admin-properties' {...props} />}
      exact
    />
    {/*<Route
      path='/properties/:city_name/:neighborhood_name/:slug'
      render={(props) => <PropertyPage {...props} />}
      exact
    />*/}
    <Route
      path='/manage-properties'
      render={(props) => <AdminDashboard path='manage-properties' {...props} />}
      exact
    />
    {/* <Route
      path='/users'
      render={(props) => <AdminDashboard path='users' {...props} />}
      exact
    /> */}
    <Route
      path={['/users', '/users/:operation(new|edit)/:id?']}
      render={(props) => <AdminDashboard path='users' {...props} />}
      exact
    />
    <Route
      path='/subscriptions'
      render={(props) => <AdminDashboard path='subscriptions' {...props} />}
      exact
    />
    <Route
      path='/admin-profile'
      render={(props) => <AdminDashboard path='admin-profile' {...props} />}
      exact
    />
    <Route
      path='/inquiries'
      render={(props) => <AdminDashboard path='inquiries' {...props} />}
      exact
    />
    <Route
      path={['/plans', '/plan/:operation(new|edit)/:id?']}
      render={(props) => <AdminDashboard path='plans' {...props} />}
      exact
    />
    <Route
      path={['/cities', '/city/:operation(new|edit)/:id?']}
      render={(props) => <AdminDashboard path='cities' {...props} />}
      exact
    />
    {/* <Route
      path="/plans"
      render={(props) => <AdminDashboard path="plans" {...props} />}
      exact
    /> */}
    {/* <Route
  path="/notifications"
  render={(props) => (
    <AdminDashboard path="notificaions" {...props} />
  )}
  exact
/> */}
    <Route
      path={['/notifications', '/notifications/:operation(new|edit)/:id?']}
      render={(props) => <AdminDashboard path='notifications' {...props} />}
      exact
    />
    <Route
      path='/new-property'
      render={(props) => <AdminDashboard path='new-property' {...props} />}
      exact
    />
    {/* <Route
      path='/virtual-assistants'
      render={(props) => <AdminDashboard path='va' {...props} />}
      exact
    />
    <Route
      path='/new-virtual-assistant'
      render={(props) => <AdminDashboard path='new-va' {...props} />}
      exact
    />
    <Route
      path='/edit-virtual-assistant/:id'
      render={(props) => <AdminDashboard path='edit-va' {...props} />}
      exact
    /> */}
    <Route
      path='/leads'
      render={(props) => <AdminDashboard path='leads' {...props} />}
      exact
    />

    {/* Virtual Assistant Routes */}

    <Route
      path='/assistant-dashboard'
      render={(props) => <AssistantDashboard path='home' {...props} />}
      exact
    />
    <Route
      path='/assistant-properties/:city_name?/:neighborhood_name?/:id?'
      render={(props) => <AssistantDashboard path='assistant-properties' {...props} />}
      exact
    />
    <Route
      path='/assistant-profile'
      render={(props) => <AssistantDashboard path='profile' {...props} />}
      exact
    />
    <Route
      path='/property/new'
      render={(props) => <AssistantDashboard path='new' {...props} />}
      exact
    />

    {/* User Dashboard */}

    <Route
      path='/user-dashboard'
      render={(props) => <UserDashboard path='dashboard' {...props} />}
      exact
    />
    <Route
      path={['/properties/send', '/properties/:city_name?/:neighborhood_name?/:id?']}
      render={(props) => <UserDashboard path='properties' {...props} />}
      exact
    />
    <Route
      path={['/clients', '/clients/:operation(new|edit|show)/:id?']}
      render={(props) => <UserDashboard path='clients' {...props} />}
      exact
    />
    <Route
      path={['/client-inquiries', '/client-inquiries/:id']}
      render={(props) => <UserDashboard path='client-inquiries' {...props} />}
      exact
    />
    <Route
      path={['/invoices', '/invoices/:operation(new|edit|show)/:id?']}
      render={(props) => <UserDashboard path='invoices' {...props} />}
      exact
    />
    <Route
      path='/my-recommendations'
      render={(props) => <UserDashboard path='recommendations' {...props} />}
      exact
    />
    {/* <Route
      path='/emails'
      render={(props) => <UserDashboard path='emails' {...props} />}
      exact
    /> */}
    {/* <Route
      path='/user-profile'
      render={(props) => <UserDashboard path='user-profile' {...props} />}
      exact
    /> */}
    {/* <Route
      path='/user-subscriptions'
      render={(props) => <UserDashboard path='user-subscriptions' {...props} />}
      exact
    /> */}
    {/* <Route
      path='/payment-method'
      render={(props) => <UserDashboard path='payment-method' {...props} />}
      exact
    /> */}
    <Route
      path='/user-notifications'
      render={(props) => <UserDashboard path='user-notifications' {...props} />}
      exact
    />
    {/* <Route
      path='/notification-settings'
      render={(props) => <UserDashboard path='notification-settings' {...props} />}
      exact
    /> */}
    <Route
      path='/properties-locations'
      render={(props) => (
        <UserDashboard path='properties-locations' {...props} />
      )}
      exact
    />
    <Route
      path={['/guest-cards', '/guest-cards/send']}
      render={(props) => <UserDashboard path='guest-cards' {...props} />}
      exact
    />
    <Route
      path='/settings'
      render={(props) => <UserDashboard path='settings' {...props} />}
      exact
    />

    {/* <Route
      path={['/invoices', '/invoice/send']}
      render={(props) => <UserDashboard path='invoices' {...props} />}
      exact
    /> */}
		<Route
			path={['/agents', '/agents/:operation(new|edit|show)/:id?']}
			render={(props) => <UserDashboard path='agents' {...props} />}
			exact
		/>

    <Route
			path={['/emails', '/emails/:operation(new|edit|show)/:id?']}
			render={(props) => <UserDashboard path='emails' {...props} />}
			exact
		/>

    <Route
			path='/tutorials'
			render={(props) => <UserDashboard path='tutorials' {...props} />}
			exact
		/>
    <Route path='*' render={(props) => <Error404 {...props} />} />

  </Switch>
);
