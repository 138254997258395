import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import NavBar from '../components/NavBar';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import {
  Error404Image
} from '@assets';

export default function Error404(props) {
  return (
    <>
      {/* <NavBar /> */}

      <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Page Not Found</h1>
        </Container>
      </Container>

      <Container fluid className='py-4 text-center'>
        <Container>
          <Row className='px-lg-5'>
            <Col md={{ size: 4, offset: 4 }}>
              <img src={Error404Image} alt="Try for free" className="img-fluid" />
            </Col>

            <Col md={{ size: 4, offset: 4 }}>
              <NavLink to="/" className="mx-2"><Button color="green">Back to Home</Button></NavLink>
              <NavLink to="/features" className="mx-2"><Button color="primary">Explore Features</Button></NavLink>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
