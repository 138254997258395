import React, { useEffect } from 'react';
import NavBar from '../components/NavBar';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';

import SectionTitle, { SectionSubTitle } from '../components/sectionTitle';

import Footer from '../components/footer';

import {
  BannerImage,
  HomeSection2,
  HomeSection3,
  HomeSection4,
  HomeSection501,
  HomeSection502,
  Icon,
} from '@assets';

// import Input from 'react-select/src/components/Input';

export default function Home(props) {
  //useEffect(() => {
  /* if (props.location.state) {
      toast(props.location.state.message, { type: props.location.state.type || "success" })
    } */
  //alert(localStorage.getItem("user_type"));

  /* switch (localStorage.getItem("user_type")) {
      case 'va':
        window.location.href = "/assistant-dashboard";
        break;
      case 'user':
        window.location.href = "/user-dashboard";
        break;
      case 'admin':
        window.location.href = "/admin-dashboard";
        break;
      default:
        break;
    } */

  // if (localStorage.getItem("user_token") !== null && localStorage.getItem("admin_token") === null) {
  //   window.location.href = "/user-dashboard";
  // } else if (localStorage.getItem("admin_token") !== null && localStorage.getItem("user_token") !== null) {
  //   window.location.href = "/user-dashboard";
  // } else if (localStorage.getItem("admin_token") !== null) {
  //   window.location.href = "/admin-dashboard";
  // } else if(localStorage.getItem("assistant_token") !== null) {
  //   window.location.href = "/assistant-dashboard";
  // }

  // }, []);
  // if (localStorage.getItem("user_type") === "va") {
  //   window.location.href = "/assistant-dashboard";
  // }
  // if (localStorage.getItem("user_type") === "user") {
  //   window.location.href = "/user-dashboard";
  // }
  // if (localStorage.getItem("user_type") === "admin") {
  //   window.location.href = "/admin-dashboard";
  // }
  return (
    <>
      <NavBar />

      {/* <Container fluid className="banner py-5 px-0">
      <Container>
        <Row className="mb-lg-5 flex-lg-row-reverse">
          <Col lg="6" className="text-center">
            <div className="banner-image-box pl-4 pl-lg-0 mb-5 mb-md-0 text-right">
              <img src={BannerImage} alt="Banner" className="img-fluid" />
            </div>
          </Col>

          <Col lg="6" className="text-center text-lg-left d-none d-lg-block">
            <h1>Find and share multifamily <br />pricing and availability in seconds.</h1>

            <SectionSubTitle title={<>SparkAPT replaces the need to make phone calls and check <br /> website manually for multifamily data.</>} className="py-4 mb-0" />

            <div className="banner-searchbar">
              <FormGroup>
                <Input type="email" name="email" id="exampleEmail" placeholder="Enter agent email..." />
                <NavLink to="/signup"><Button color="blue" size="lg" className="py-0">Try for free</Button></NavLink>
              </FormGroup>
            </div>
          </Col>

          <Col lg="6" className="text-center px-lg-5 d-lg-none mt-4">
            <h1 className="text-dark">Find and share multifamily pricing and availability in seconds.</h1>
            <SectionSubTitle title="SparkAPT replaces the need to make phone calls and check website manually for multifamily data." className="py-4 mb-0 text-dark" />

            <div className="banner-searchbar mx-auto">
              <FormGroup>
                <Input type="email" name="email" id="exampleEmail" placeholder="Enter agent email..." />
                <NavLink to="/signup"><Button color="blue" size="lg" className="py-0">Try for free</Button></NavLink>
              </FormGroup>
            </div>  
          </Col>
        </Row>
      </Container>
    </Container> */}

      <Container fluid className='banner py-5 px-0 position-relative'>
        <Container fluid>
          <Row className='flex-lg-row-reverse'>
            <Col lg='6' className='text-center px-0'>
              <div className='banner-image-box pl-4 pl-lg-0 mb-5 mb-md-0 text-right'>
                <img src={BannerImage} alt='Banner' className='img-fluid' />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='banner-text-overlapping'>
          <Row>
            <Col lg='6' className='text-center text-lg-left'>
              <h1>
                Find and share apartment pricing and availability in seconds.
              </h1>
              <SectionSubTitle
                title={
                  <>
                    SparkAPT replaces the need to make phone calls and check
                    websites.
                  </>
                }
                className='py-4 mb-0'
              />
              <div className='banner-searchbar'>
                <FormGroup>
                  <Input
                    type='email'
                    name='email'
                    id='exampleEmail'
                    placeholder='Enter agent email...'
                  />
                  <NavLink to='/signup'>
                    <Button color='blue' size='lg' className='py-0'>
                      Try for free
                    </Button>
                  </NavLink>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5 bg-blue'>
        <Container>
          <Row className='d-flex align-items-center text-white flex-lg-row-reverse'>
            <Col lg='6' className='text-center mb-3 mb-md-0'>
              <img
                src={HomeSection2}
                alt='Try for free'
                className='img-fluid'
              />
            </Col>
            <Col lg='6'>
              <SectionTitle
                title='A fully integrated suite of agent and broker tools for the multi family industry.'
                className='mb-4'
              />
              {/* <SectionSubTitle title="A single platform to find and share multifamily pricing, availability and specials not only makes the jobs of apartment locators and real estate agents much easier, but it also takes a load off of on-site agents as well as investors looking for market pricing data." className="mb-4" /> */}
              {/* <SectionSubTitle title="A single platform with updated pricing and specials simplifies your job as an agent." className="mb-4" /> */}
              <NavLink to='/signup'>
                <Button color='white' size='lg'>
                  Try for free
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5 bg-silver'>
        <Container>
          <Row>
            <Col lg='6' xl='4'>
              <SectionTitle
                title='Is SparkAPT right for you?'
                color='dark'
                className='mb-4'
              />
            </Col>
          </Row>
          <Row className='flex-row-reverse'>
            <Col lg='8' xl='9'>
              <img
                src={HomeSection3}
                alt='Section-3 Image'
                className='img-fluid'
              />
            </Col>

            <Col lg='4' xl='3'>
              <SectionSubTitle
                title={
                  <>
                    <Icon icon='check' /> Save 80% of your time as an apartment
                    locator.
                  </>
                }
                className='mb-3'
              />
              <SectionSubTitle
                title={
                  <>
                    <Icon icon='check' /> Automated distribution and tracking as
                    a broker.
                  </>
                }
                className='mb-3'
              />
              <NavLink to='/signup'>
                <Button color='blue' size='lg' className='mt-4'>
                  Try for free
                </Button>
              </NavLink>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5 pb-lg-0 bg-blue text-white'>
        <Container>
          <Row>
            <Col lg='8'>
              <img
                src={HomeSection4}
                alt='Section-4 Image'
                className='img-fluid'
              />
            </Col>
            <Col lg='4'>
              <SectionTitle title='Built-in CRM' className='mb-4 mt-lg-5' />
              <SectionSubTitle
                title='Use the built-in CRM to keep track of your clients and your next steps to close more leads.'
                className='mb-4'
              />
              <SectionSubTitle title='Take advantage of the Twilio integration to automatically send texts to clients when their lease up for renewal.' />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5 bg-silver'>
        <Container>
          <Row className='flex-md-row-reverse'>
            <Col lg='6'>
              <img
                src={HomeSection501}
                alt='Section-5 Image'
                className='img-fluid'
              />
            </Col>
            <Col lg='6'>
              <SectionTitle
                title='API integration with backend payment processors.'
                className='mb-4 mt-4'
              />
              <SectionSubTitle title="Apartment invoices are much simpler than regular sales or purchase invoices. SparkAPT can integrate with your current broker's backend payment processor for a one-and-done invoice process to ensure more efficient invoice tracking." />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5 bg-silver'>
        <Container>
          <Row className='flex-md-row-reverse'>
            <Col lg='6'>
              <img
                src={HomeSection502}
                alt='Section-5 Image'
                className='img-fluid'
              />
            </Col>
            <Col lg='6'>
              <SectionTitle
                title='Send guest cards to apartment communities without creating PDFs.'
                className='mb-4 mt-4'
              />
              <SectionSubTitle title='Save time by automatically sending guest cards to more than one community at a time, without worrying about tracking down their email or wondering if the latest email you have is the correct one.' />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='py-5 bg-blue-bright'>
        <Container>
          <Row className='d-flex justify-content-center text-white text-center mb-5'>
            <Col lg='8' xl='5'>
              <SectionTitle title='Ready to get Started?' className='mb-4' />
              <h2 className='d-none d-lg-block'>
                Create an account now as a licensed agent or broker to begin
                using SparkAPT. You can also contact us for a custom package for
                your team.
              </h2>
              <SectionSubTitle
                className='d-lg-none'
                title='Create an account now as a licensed agent or broker to begin using SparkAPT. You can also contact us for a custom package for your team.'
              />
            </Col>
          </Row>

          <Row>
            <Col lg='6' className='px-xl-5 mb-4 mb-lg-0'>
              <Card>
                <CardBody className='text-center py-lg-5'>
                  <CardTitle tag='h1' className='mb-4 mb-md-5'>
                    Brokers
                  </CardTitle>
                  <ul className='text-left'>
                    <li>
                      <h4>
                        Applicant lead tracking from your current integrations.
                      </h4>
                    </li>
                    <li>
                      <h4>
                        Custom tagging for properties you have relationships
                        with.
                      </h4>
                    </li>
                    <li>
                      <h4>Create and send guest cards automatically.</h4>
                    </li>
                  </ul>
                  <NavLink to='/signup'>
                    <Button color='green' size='jumbo' className='mt-4 mt-md-5'>
                      Try for Free
                    </Button>
                  </NavLink>
                </CardBody>
              </Card>
            </Col>
            <Col lg='6' className='px-xl-5'>
              <Card>
                <CardBody className='text-center py-lg-5'>
                  <CardTitle tag='h1' className='mb-4 mb-md-5'>
                    Agents
                  </CardTitle>
                  <ul className='text-left'>
                    <li>
                      <h4>Find pricing and availability in seconds.</h4>
                    </li>
                    <li>
                      <h4>
                        Send data to clients and partners within your team.
                      </h4>
                    </li>
                    <li>
                      <h4>Allow clients to schedule tours within the app..</h4>
                    </li>
                  </ul>
                  <NavLink to='/signup'>
                    <Button color='green' size='jumbo' className='mt-4 mt-md-5'>
                      Try for Free
                    </Button>
                  </NavLink>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

      <Footer className='bg-silver' />
    </>
  );
}
