import React from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle
} from 'reactstrap';

export default function TutorialList(props) {
  const videos = [
    {
      url: 'https://www.loom.com/share/3b91ae41a75c4cb2b48f2f30d16e8eaa?sid=af4622ab-4b16-4792-ae93-121f92c8582c',
      caption: 'Logging In',
    },
    {
      url: 'https://www.loom.com/share/6d1b8d3737674d9db9272e43aa26073b?sid=0dd93f3d-3621-4cc3-ab1b-f9b4bb7aa70d',
      caption: 'Searching for Properties',
    },
    {
      url: 'https://www.loom.com/share/ceb53bcec5af48bc835dd1807c602de4?sid=02819b42-1bbe-4dc6-a04e-132cadde7454',
      caption: 'Clients / CRM and Search',
    },
    {
      url: 'https://www.loom.com/share/0f25f6dca7824dd383748451c0396111?sid=f37a7915-df3b-4eed-96ba-e09d8bc8bd58',
      caption: 'Properties Recommendation Lists',
    },
    {
      url: 'https://www.loom.com/share/d9857007109a47c4a1c0390fc8540f3d?sid=4f9f2480-1d91-4d3e-a234-a41f2c961988',
      caption: 'View Client List Feedback',
    },
    {
      url: 'https://www.loom.com/share/6c06d6ade8a94d7480c83882f7396a1a?sid=1c93e069-f42f-484a-976d-2d4812d92ca6',
      caption: 'Send and View Guest Cards',
    },
    {
      url: 'https://www.loom.com/share/2f6550c1d23540a7b7ca82b076f790e7?sid=fa4d30b7-2c06-4c63-a50d-cca5a8bec8c6',
      caption: 'Leads and Personal Inquiry Link',
    },
    {
      url: 'https://www.loom.com/share/27574ac763fe473ebd01e18a534c827c?sid=549f2d3e-fa89-4f30-8cef-da47154d3c44',
      caption: 'Settings, Personalization and Contact Us',
    }
  ];

  return (
    <Container>
      <h1 className="text-center my-4 pt-4">Tutorial Videos</h1>
      <Row>
        {videos.map((video, index) => (
          <Col sm="12" md="6" lg="4" key={index} className="mb-4">
            <Card>
              <CardBody>
                <a href={video.url} target="_blank" rel="noopener noreferrer">
                  <CardTitle tag="h5">{video.caption}</CardTitle>
                </a>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
