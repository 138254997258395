import React from 'react'

import NavBar from '../NewLanding/components/newNavbar';
import Footer from '../NewLanding/components/newFooter';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import DivisionTitle from '../NewLanding/components/divisionTitle';

export default function Terms() {
  return (<>
    <NavBar />

    <Container fluid className='py-5em mt-5em'>
        <Container className='custom-container'>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                title="Terms and Conditions"
              />
            </Col>
          </Row>
        </Container>
      </Container>

    <Container fluid className="bg-blue-shade py-5">
      <Container>
        <Row>
          <Col md={{ size: '10', offset: '1' }} lg={{ size: '8', offset: '2' }}>
            <h3>Agreement between User and <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a></h3>
            <p>Welcome to <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a>. The site, operated by SparkLocation LLC DBA SparkAPT ("Spark"), consists of various web pages. Your use of <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> is subject to the following terms, conditions, and notices (collectively, the "Terms"). By accessing or using the Site, you agree to abide by these Terms. Please review them carefully and retain a copy for your records.</p>

            <h3>Service Description:</h3>
            <p>SparkAPT.com is a software as a service (SAAS), namely, hosting software for use connecting licensed real estate agents, multifamily apartment communities, as well as private owners on MLS listings to automate viewing and sorting of pricing, availability, commissions, and other variables necessary for real estate agents to help their clients find a home or apartment to rent or buy, as well as to connect with multifamily communities to automate how information is shared between and among licensed agents, multifamily apartment communities, or private owners.</p>

            <h3>Client Information and Data Privacy</h3>
            <p><u>Confidentiality</u>: We prioritize the security of your client information. Any data you input into our SaaS product, including client details, leads, and proprietary information, remains exclusively yours. We commit not to claim ownership, access, or use this information for any purpose other than to deliver the agreed-upon services, except as mandated by law or in response to a legitimate legal request.</p>
            <p><u>Data Usage</u>: The information and leads you provide are intended solely for your use as a real estate agent using our software. Access to this information by our team is strictly limited to what is necessary to offer you our services and support, as detailed in our service agreement.</p>
            <p><u>Data Security</u>: We utilize industry-standard security measures to protect your data from unauthorized access, disclosure, alteration, and destruction. However, we must acknowledge that no method of transmission over the internet or electronic storage is completely secure. While we are committed to protecting your data, we cannot ensure or warrant its absolute security.</p>
            <p><u>Limitation of Liability</u>: Our liability for any data loss, data breach, or unauthorized data access is fully disclaimed to the extent allowed by law. By using our services, you acknowledge that you do so at your own risk with respect to the security and privacy of your data.</p>
            <p><u>Data Ownership</u>: You retain full ownership of all the data you input into our SaaS product. We assert no rights over your data.</p>
            <p><u>Indemnification</u>: You agree to defend, indemnify, and hold harmless our company, its officers, directors, employees, and agents from any claims, damages, or expenses, including attorney fees, arising from your misuse of our services or your breach of these terms and conditions.</p>

            <h3>Privacy</h3>
            <p>Your use of <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> is subject to Spark's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>

            <h3>Electronic Communications</h3>
            <p>Visiting <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> or sending emails to Spark constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>

						<h3>Text Messages/Notifications</h3>
						<p>By signing up and creating an account on SparkAPT.com, you agree to receive text message reminders and notifications from us. These messages will be automated and will only pertain to the reminders and notifications that you have selected and configured in your user profile.</p>
						<p>You may adjust your text message notification settings at any time by accessing your user profile and making the necessary changes in the notification preferences section. If you decide to opt-out of text message notifications entirely, you can do so by disabling the text message option in your profile settings. Standard messaging rates and data charges may apply based on your mobile carrier and plan. SparkAPT.com is not responsible for any charges incurred by you as a result of receiving these text messages.</p>
						<p>We value your privacy and will handle your personal information in accordance with our Privacy Policy, which you can review at <a href="https://www.sparkapt.com/privacy-policy" rel="noreferrer" target="_blank">www.sparkapt.com/privacy-policy</a>.</p>

            <h3>Your Account</h3>
            <p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that Spark is not responsible for third party access to your account that results from theft or misappropriation of your account. Spark and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>

            <h3>Children Under Thirteen</h3>
            <p>Spark does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> only with permission of a parent or guardian.</p>

            <h3>Cancellation/Refund Policy</h3>
            <p>There are no refunds. You can cancel your subscription by visiting your profile settings. Find your name in the top right of Spark, click, "My Subscription", then select, "Cancel." You may also email "Cancel" with your name and account email to <a href="mailto:data@sparkapt.com">data@sparkapt.com</a>.</p>

            <h3>Links to Third Party Sites/Third Party Services</h3>
            <p><a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of Spark and Spark is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. Spark is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Spark of the site or any association with its operators.</p>
            <p>Certain services made available via <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> are delivered by third party sites and organizations. By using any product, service or functionality originating from the <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> domain, you hereby acknowledge and consent that Spark may share such information and data with any third party with whom Spark has a contractual relationship to provide the requested product, service or functionality on behalf of <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> users and customers.</p>

            <h3>No Unlawful or Prohibited Use/Intellectual Property</h3>
            <p>You are granted a non-exclusive, non-transferable, revocable license to access and use <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Spark that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
            <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of Spark or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>
            <p>You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. Spark content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of Spark and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of Spark or our licensors except as expressly authorized by these Terms.</p>

            <h3>Third Party Accounts</h3>
            <p>You will be able to connect your Spark account to third party accounts. By connecting your Spark account to your third party account, you acknowledge and agree that you are consenting to the continuous release of information about you to others (in accordance with your privacy settings on those third party sites). If you do not want information about you to be shared in this manner, do not use this feature.</p>

            <h3>International Users</h3>
            <p>The Service is controlled, operated and administered by Spark from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Spark Content accessed through <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>

            <h3>Indemnification</h3>
            <p>You agree to indemnify, defend and hold harmless Spark, its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any terms of this Agreement or your violation of any rights of a third party, or your violation of any applicable laws, rules or regulations. Spark reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Spark in asserting any available defenses.</p>

            <h3>Arbitration</h3>
            <p>In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms and Conditions.</p>

            <h3>Liability Disclaimer</h3>
            <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. SPARKLOCATION LLC DBA SPARKAPT AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
            <p>SPARKLOCATION LLC DBA SPARKAPT AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. SPARKLOCATION LLC DBA SPARKAPT AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
            <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SPARKLOCATION LLC DBA SPARKAPT AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF SPARKLOCATION LLC DBA SPARKAPT OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

            <h3>Termination/Access Restriction</h3>
            <p>Spark reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice. To the maximum extent permitted by law, this agreement is governed by the laws of the State of Florida and you hereby consent to the exclusive jurisdiction and venue of courts in Florida in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
            <p>You agree that no joint venture, partnership, employment, or agency relationship exists between you and Spark as a result of this agreement or use of the Site. Spark's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of Spark's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Spark with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
            <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Spark with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Spark with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

            <h3>Changes to Terms</h3>
            <p>Spark reserves the right, in its sole discretion, to change the Terms under which <a href="https://www.sparkapt.com" rel="noreferrer" target="_blank">www.sparkapt.com</a> is offered. The most current version of the Terms will supersede all previous versions. Spark encourages you to periodically review the Terms to stay informed of our updates.</p>

            <h3>Contact Us</h3>
            <p>Spark welcomes your questions or comments regarding the Terms:</p>

            <p>
              SparkLocation LLC <br/>
              1000 Brickell Ave Suite 715 <br/>
              Miami, Florida 33131
            </p>

            <p>
              Email Address: <br/>
              <a href="mailto:data@sparkapt.com">data@sparkapt.com.</a>
            </p>

            <p>
              Telephone number: <br/>
              <a href="tel:737-900-6175">737-900-6175</a>
            </p>

            <p><strong>Effective as of February 21, 2024</strong></p>

          </Col>
        </Row>
      </Container>
    </Container>

    <Footer />
  </>)
}
