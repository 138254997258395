import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@assets'
export default function AgentDetailsPage({ agent }) {
	// const [agent, setAgent] = useState(null)
	// const { data: agentDetails, loading: loadingAgent } = useQuery(AGENT_DETAILS)

	// useEffect(() => {
	// 	if (agentDetails?.myProfile){
	// 		setAgent(agentDetails.myProfile);
	// 	}
	// }, [agentDetails])

	return (
		<>
			<Container className='mt-5'>
				{agent ?
					<Row>
						{/* <Col md='12' className='mt-md-3'>
							<h3>Agent Profile</h3>
						</Col> */}
						<Col md='3' className='agent-profile-container mb-3'>
							<Row>
								{agent.avatarUrl &&
									<Col md='12' className='mt-md-3'>
										<img
											alt="agent profile image"
											src={agent.avatarUrl}
											top
											width="100%"
										/>
									</Col>}
								{agent.logoUrl &&
									<Col md='12' className='mt-md-3'>
										<img
											alt="agent logo image"
											src={agent.logoUrl}
											top
											width="100%"
										/>
									</Col>}
								{agent.personalWebsite &&
								<Col md='12' className='mt-3'>
									<Icon icon='globe' size='lg' color='SlateBlue' />&nbsp;
									<a href={agent.personalWebsite} target='_blank'>Personal Website</a>
								</Col>}
								{agent.facebook &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'facebook']} size='lg' color='blue'/>&nbsp;
										<a href={agent.facebook} target='_blank'>Facebook</a>
									</Col>}
								{agent.twitter &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'twitter']} size='lg' color='DodgerBlue'/>&nbsp;
										<a href={agent.twitter} target='_blank'>Twitter</a>
									</Col>}
								{agent.instagram &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'instagram']} size='lg' color='red'/>&nbsp;
										<a href={agent.instagram} target='_blank'>Instagram</a>
									</Col>}
								{agent.whatsapp &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'whatsapp']} size='lg' color='green'/>&nbsp;
										<a href={agent.whatsapp} target='_blank'>Whatsapp</a>
									</Col>}
								{agent.linkedin &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'linkedin']} size='lg' color='DodgerBlue'/>&nbsp;
										<a href={agent.linkedin} target='_blank'>LinkedIn</a>
									</Col>}
								{agent.youtube &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'youtube']} size='lg' color='red'/>&nbsp;
										<a href={agent.youtube} target='_blank'>YouTube</a>
									</Col>}
								{agent.tiktok &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'tiktok']} size='lg' />&nbsp;
										<a href={agent.tiktok} target='_blank'>TikTok</a>
									</Col>}
								{agent.pinterest &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'pinterest']} size='lg' color='red'/>&nbsp;
										<a href={agent.pinterest} target='_blank'>Pinterest</a>
									</Col>}
								{agent.wechat &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'weixin']} size='lg' color='green'/>&nbsp;
										<a href={agent.wechat} target='_blank'>WeChat</a>
									</Col>}
								{agent.calendly &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fa', 'calendar-alt']} size='lg' color='SlateBlue'/>&nbsp;
										<a href={agent.calendly} target='_blank'>Calendly</a>
									</Col>}
								{agent.googlePage &&
									<Col md='6' className='mt-3'>
										<FontAwesomeIcon icon={['fab', 'google']} size='lg' color='Orange'/>&nbsp;
										<a href={agent.googlePage} target='_blank'>Google Page</a>
									</Col>}
							</Row>
						</Col>
						<Col md='9' className='edit-popup'>
							<Row>
								<Col md='12' className='mt-md-1'>
									<h3>{agent.name}</h3>
								</Col>
								<Col md='12' className='mt-md-2'>
									<h6><Icon icon='envelope' />&nbsp;<a href={`mailto:${agent.email}`}>{agent.email}</a></h6>
								</Col>
								{agent.phoneNo && <Col md='12' className='mt-md-1'>
									<h6><Icon icon='phone' />&nbsp;<a href={`tel:${agent.phoneNo}`}>{agent.phoneNo}</a></h6>
								</Col>}
								<Col md='12' className='mt-5'>
									<h4>About</h4>
								</Col>
								<Col md='12'>
									<p style={{fontSize: '1rem', whiteSpace: 'pre-wrap'}}>
										{agent.aboutMe}
									</p>
								</Col>
							</Row>
						</Col>
					</Row> : null}
			</Container>
		</>
	)
}
