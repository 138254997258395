export default function propertyTypes(floorPlans) {
  var studio = false;
  if (floorPlans) {
    if (floorPlans.length > 0) {
      floorPlans.map((fp) => {
        if (fp.bed === 'studio') {
          studio = true;
        } else if (fp.bed === 'convertible') {
          studio = true;
        } else if (fp.bed === 'penthouse') {
          studio = true;
        }
      });
      const totalBedRooms = [];
      floorPlans.map((fp) => {
        totalBedRooms.push(fp.bed ? fp.bed.replace(/[^\d.]/g, '') : 0);
      });
      if (totalBedRooms.length > 0) {
        var minValue = Math.min(...totalBedRooms);
        var maxValue = Math.max(...totalBedRooms);
      }
      if (studio === true) {
        if (maxValue === 0) {
          return 0;
        }
        return 0 + '-' + maxValue;
      } else {
        return minValue === maxValue ? minValue : minValue + '-' + maxValue;
      }
    } else {
      return '-';
    }
  } else {
    return '-';
  }
}
