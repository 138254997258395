import moment from "moment";

export const ClientStatuses = [
	{value: "lead", label: "Lead"},
	{value: "list_sent", label: "List Sent"},
	{value: "list_viewed", label: "List Viewed"},
	{value: "follow_up", label: "Follow Up"},
	{value: "scheduling_tours", label: "Scheduling Tours"},
	{value: "ready_to_tour", label: "Ready To Tour"},
  {value: "done_touring", label: "Done Touring"},
	{value: "applied", label: "Applied"},
	{value: "signed_lease", label: "Signed Lease"},
	{value: "invoice_sent", label: "Invoice Sent"},
	{value: "invoice_paid", label: "Invoice Paid"}, 
	{value: "backlog", label: "Backlog"}, 
	{value: "lost", label: "Lost"},
	{value: "leased_pending_move_in", label: "Leased - Pending Move In"},
	{value: "moved_in", label: "Moved In"}
]

export const creditScores = [
	{value: "650+", label: "650+"},
	{value: "600-649", label: "600-649"},
	{value: "550-599", label: "550-599"},
	{value: "500-549", label: "500-549"},
	{value: "300-499", label: "300-499"},
	{value: "N/A", label: "N/A"}
]

export const leadSources = [
	{value: "Sphere of Influence", label: "Sphere of Influence"},
	{value: "Facebook", label: "Facebook"},
	{value: "Instagram", label: "Instagram"},
	{value: "TikTok", label: "TikTok"},
	{value: "Repeat Client", label: "Repeat Client"},
	{value: "referral", label: "Referral"},
	{value: "paid_ad", label: "Paid Ad"},
	{value: "Other", label: "Other"}
]

export function formateBudget(minRent, maxRent) {
	if (minRent && maxRent){
		return `Between $${minRent} - $${maxRent}`
	} else if(minRent && !maxRent){
		return `Above $${minRent}`
	} else if(!minRent && maxRent){
		return `Below $${maxRent}`
	} else {
		return null
	}
}

export function formatMoveIn(availFrom, availTo){
	let result = null
	if (availFrom && !availTo) {
		result = `After ${moment(availFrom).format('L')}`
	} else if (!availFrom && availTo){
		result = `Before ${moment(availTo).format('L')}`
	} else if (availFrom && availTo) {
		result = `${moment(availFrom).format('L')} - ${moment(availTo).format('L')}`
	}
	return result													
}