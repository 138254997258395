import React, { useState } from 'react';
import _ from 'lodash/fp';
import {
  Container,
  Row,
  Col,
  Button,
  Input,
	Table
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MY_PROFILE, UPDATE_NOTIFICATION_SETTINGS } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from '../Spinner';

export default function NotificationSetting() {

	const [sentListOpenEmail, setSentListOpenEmail] = useState(true);
	const [sentListOpenSms, setSentListOpenSms] = useState(true);
	const [sentListRatingEmail, setSentListRatingEmail] = useState(true);
	const [sentListRatingSms, setSentListRatingSms] = useState(true);

	const {loading: loadingUser} = useQuery(MY_PROFILE, {
		onCompleted: (data) => {
			setSentListOpenEmail(data.myProfile.sentListOpenEmail)
			setSentListOpenSms(data.myProfile.sentListOpenSms)
			setSentListRatingEmail(data.myProfile.sentListRatingEmail)
			setSentListRatingSms(data.myProfile.sentListRatingSms)
		},
	});

	const [updateNotificationSettings] = useMutation(UPDATE_NOTIFICATION_SETTINGS, {
		onCompleted: (data) => {
			if (data.updateNotificationSettings.error){
				toast(data.updateNotificationSettings.message, { type: 'error' });
			} else {
				toast(data.updateNotificationSettings.message, { type: 'success' });
			}
		},
		refetchQueries: [MY_PROFILE]
	});

  const mutationCall = () => {
		updateNotificationSettings({
			variables: {
				sentListOpenEmail: sentListOpenEmail,
				sentListOpenSms: sentListOpenSms,
				sentListRatingEmail: sentListRatingEmail,
				sentListRatingSms: sentListRatingSms
			}
		});
  };

  return (
    <>
      <Container className='p-4 text-center'>
        {loadingUser && <Spinner />}
        <Row>
          <Col md='12' className='mt-md-3'>
            <h3>Notification Settings</h3>
          </Col>

          <Col md='2' className='mt-md-3'>
          </Col>

          <Col md='8' className='mt-md-3 notification-table'>
						<Table borderless>
							<thead>
								<tr>
									<th>
									</th>
									<th>
										Email
									</th>
									<th>
										Text Message
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td align='left'>
										Notify me when the client opens their recommendations
									</td>
									<td className='pl-4'>
										<Input
											id="sentListOpenEmail"
											type="checkbox"
											className='ml-1 d-block'
											value={sentListOpenEmail}
											checked={sentListOpenEmail}
											onChange={() => { setSentListOpenEmail(!sentListOpenEmail)}}
										/>
									</td>
									<td className='pl-4'>
										<Input
											id="sentListOpenSms"
											type="checkbox"
											className='ml-4 d-block'
											value={sentListOpenSms}
											checked={sentListOpenSms}
											onChange={() => { setSentListOpenSms(!sentListOpenSms)}}
										/>
									</td>
								</tr>
								<tr>
									<td align='left'>
										Notify me when the client rates or comments on their recommendations
									</td>
									<td className='pl-4'>
										<Input
											id="sentListRatingEmail"
											type="checkbox"
											className='ml-1 d-block'
											value={sentListRatingEmail}
											checked={sentListRatingEmail}
											onChange={() => { setSentListRatingEmail(!sentListRatingEmail)}}
										/>
									</td>
									<td className='pl-4'>
										<Input
											id="sentListRatingSms"
											type="checkbox"
											className='ml-4 d-block'
											value={sentListRatingSms}
											checked={sentListRatingSms}
											onChange={() => { setSentListRatingSms(!sentListRatingSms)}}
										/>
									</td>
								</tr>
							</tbody>
						</Table>
          </Col>

					<Col md='2' className='mt-md-3'>
          </Col>

					<Col md='12' className='text-center mt-4'>
						<Button color='primary' className='button-dashboard' onClick={mutationCall}>
							Save
						</Button>
          </Col>

        </Row>
      </Container>
    </>
  );
}
