import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

export default function ImageModal(props) {
  /* useEffect(() => {
    console.log('useEffect');
    function onKeyup(e) {
      if (e.key === key) {
        console.log(key, 'key');
      }
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, []); */

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        centered
        className='modal-dialog modal-xl text-center'
        fullscreen={'xl'}
        /* keyboard={false}
        container={document.getElementById('root')}
        toggle={(e) => {
          props.setIsOpen(!props.isOpen);
          console.log(e, 'e togglle');
          e.stopPropagation();
        }}
        onKeyUp={(e) => console.log(e, 'e onKeyUp')} */
      >
        <ModalHeader toggle={props.parentRef}>{props.planName}</ModalHeader>
        <ModalBody className='inputs-icons'>
          <img className='center' src={props.imgSrc} />
          <br />
          <Button
            color='green'
            className='button-dashboard mt-4'
            onClick={() => props.setIsOpen(!props.isOpen)}
          >
            Close
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
}
