import React from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import { Icon } from '@assets';
import Select from 'react-select';
const Pagination = ({ paginationInfo, setPaginationInfo, setShouldSearch }) => {
  const onNextPage = () => {
    if (paginationInfo.currentPage !== paginationInfo.totalPage) {
      //setCurrentPage(currentPage + 1);
      setPaginationInfo({
        ...paginationInfo,
        currentPage: paginationInfo.currentPage + 1,
        isNextPage: true,
      });
      //setIsNextPage(true);
    }
  };
  const onPrevPage = () => {
    if (paginationInfo.currentPage > 1) {
      setPaginationInfo({
        ...paginationInfo,
        currentPage: paginationInfo.currentPage - 1,
        isPreviousPage: true,
      });
      //setCurrentPage(currentPage - 1);
      //setIsPreviousPage(true);
    }
  };

  const pageSizeOptions = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
  ];

  return (
    <Row className='d-flex align-items-center mt-4'>
      <Col md='6 text-left'>
        <FormGroup className='mb-3 mb-md-0'>
          <Label
            for='appartment'
            className='mb-0 w-auto d-inline-block mr-md-3'
          >
            Showing Entries
          </Label>
          <Select
            name='PageSize'
            defaultValue={pageSizeOptions[0]}
            options={pageSizeOptions}
            onChange={(e) => {
              //setPageSize(Number(e.value));
              setPaginationInfo({
                ...paginationInfo,
                pageSize: Number(e.value),
                isNextPage: true,
                currentPage: 1,
              });
              setShouldSearch(true);
            }}
            className='basic-multi-select text-left'
            classNamePrefix='default-select'
            menuPlacement={'auto'}
          />
        </FormGroup>
      </Col>
      <div className='justify-content-center'>
        <ul className='pagination'>
          {/* <li onClick={() => gotoPage(0)} disabled={!pageinfo?.hasPreviousPage}>
              <a>
                <Icon icon='chevron-left' />
                <Icon icon='chevron-left' />
              </a>
            </li> */}
          <li onClick={() => onPrevPage()}>
            <a>
              <Icon icon='chevron-left' />
            </a>
          </li>
          <span className='d-flex align-items-center px-3'>
            Page &nbsp; <strong>{paginationInfo.currentPage}</strong> &nbsp; of
            &nbsp;
            <strong>{paginationInfo.totalPage}</strong>
          </span>
          <li onClick={() => onNextPage()}>
            <a>
              <Icon icon='chevron-right' />
            </a>
          </li>
          {/* <li onClick={() => gotoPage(pageCount - 1)} disabled={!pageinfo?.canNextPage}>
              <a>
                <Icon icon='chevron-right' />
                <Icon icon='chevron-right' />
              </a>
            </li> */}
        </ul>
      </div>
    </Row>
  );
};

export default Pagination;
