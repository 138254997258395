import React, {useState, useEffect} from 'react';
import config from '@config';
import { MY_PROFILE } from '@api';
import Spinner from '../Spinner';
import { useQuery } from "@apollo/client";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
} from 'reactstrap';

export default function ReferFriend() {
  const [user, setUser] = useState([]);

  const {
    data: profileData,
    loading: ProfileLoading,
  } = useQuery(MY_PROFILE, {
    onCompleted: (data) => {
      if (data.myProfile) {
        // setuser(data.myProfile);
      } else {
        toast('Profile not found', { type: 'error' });
      }
    },
    onError: (error) => {
      console.log(error + '>>>>> ERROR >>>>>');
      toast('Profile not found', { type: 'error' });
    },
  });

  useEffect(() => {
    if (profileData) {
			setUser(profileData.myProfile);
		}
  }, [profileData]);
  
  return (
    <>
      <Container className='py-5 text-center'>
      {ProfileLoading && <Spinner />}
      <Row>
        <Col md='12'>
          <h2>Receive one week free!</h2>
          <h4>Refer a friend using the link below.</h4>
          <h4>After they're subscribed for 30 days, 1 week free will be added to your account.</h4>
          <InputGroup className="justify-content-center pt-4">
            <span style={{ paddingTop: '7px', fontSize: "medium"}}> {config.url.WEB_DOMAIN}signup?code={user.slug}</span>
          </InputGroup>
          <Button color='primary' className='button-dashboard mt-5' onClick={() => navigator.clipboard.writeText(`${config.url.WEB_DOMAIN}signup?code=${user.slug}`)}>
            Copy Referral Link
          </Button>
        </Col>
      </Row>
      </Container>
    </>
  )
}
