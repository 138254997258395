import React, { useState, useEffect, useRef, useMemo } from 'react';
// import { createPortal } from 'react-dom';
import { unitTypes, ToggleButton } from '@sparklib';
// import DatePicker from 'react-datepicker';
import { Icon } from '@assets';
import DataGrid from 'react-data-grid'; // TextEditor, // SelectColumn,
import CreatableSelect from 'react-select/creatable';
import { TextEditor, DateEditor } from '../gridEditors';
import {
  rowKeyGetter,
  getComparator,
  // inputStopPropagation,
  selectStopPropagation,
} from '../gridHelpers';
import { CurrencyFormatter, DateFormatter } from '../gridFormatters';

export default function UnitGrid({
  // propertyName,
  units,
  setUnits,
  floorPlans,
  addons,
  setIsChanged,
  // isCellSelected,
}) {
  // const gridRef = useRef(null);
  const [sortColumns, setSortColumns] = useState([]);
  const bathTypes = ['Select', '1', '1.5', '2', '2.5', '3'];

  const unitColumns = [
    // SelectColumn,
    // // id
    // {
    //   key: 'id',
    //   name: 'ID',
    //   cellClass: 'text-center',
    //   width: 90,
    //   minWidth: 90,
    // },
    // delete button
    {
      key: 'delete-button',
      name: '',
      minWidth: 30,
      width: 30,
      frozen: true,
      // isLastFrozenColumn: true,
      cellClass: 'text-center p-0',
      formatter({ row }) {
        if (row.type === 'NEW') {
          return (
            <>
              <Icon icon='plus' />
            </>
          );
        } else if (row.type === 'CREATE') {
          return (
            <>
              <div
                key={`del${row.id}`}
                className='w-100 text-center cursor-pointer'
                onClick={() => {
                  onUnitRowDelete(row);
                  // onDeleteClick();
                }}
              >
                <Icon icon='times' />
              </div>
            </>
          );
        }
        return (
          <>
            <div
              key={`del${row.id}`}
              className='w-100 text-center cursor-pointer'
              onClick={() => {
                onUnitRowDelete(row);
                // onDeleteClick();
              }}
            >
              <Icon icon='trash' />
            </div>
          </>
        );
      },
    },
    // is available checbox
    {
      key: 'isAvailable',
      name: 'Available?',
      minWidth: 120,
      width: 120,
      cellClass: 'text-center p-0',
      formatter({ row, onRowChange }) {
        if (row.type === 'NEW') {
          return <></>;
        }
        return (
          <div className='d-flex align-items-center w-100 h-100 px-4'>
            <ToggleButton
              selected={row.isAvailable || false}
              firstOption={true}
              secondOption={false}
              // width='50px'
              rounded
              toggleSelected={() => {
                onRowChange({ ...row, isAvailable: !row.isAvailable }, true);
              }}
            />
          </div>
        );
      },
    },

    // floor plan
    {
      key: 'floorPlan',
      name: 'Floor Plan',
      width: 160,
      resizable: true,
      sortable: true,
      headerClass: 'text-left',
      formatter: ({ row }) => (
        <>
          {
            floorPlans.find((floorPlan) => floorPlan.id === row.floorPlanId)
              ?.name
          }
        </>
      ),
      editor: ({ row, onRowChange }) => (
        <select
          autoFocus
          value={row.floorPlanId}
          onChange={(e) => {
            // console.log(e.target.value);
            onRowChange(
              {
                ...row,
                floorPlanId: floorPlans.find(
                  (floorPlan) => floorPlan.id === e.target.value
                )?.id,
                floorPlan: floorPlans.find(
                  (floorPlan) => floorPlan.id === e.target.value
                ),
              },
              true
            );
          }}
          onKeyDown={selectStopPropagation}
        >
          {[
            <option value='-1' key={'select'}>
              {'Select'}
            </option>,
            ...floorPlans
              .filter(
                (floorPlan) =>
                  floorPlan.type !== 'NEW' &&
                  floorPlan.type !== 'DELETED' &&
                  Number(floorPlan.id) > 0
              )
              .sort(function(a, b){
                let x = a.name.toLowerCase();
                let y = b.name.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
                return 0;
              })
              .map((floorPlan) => (
                <option value={floorPlan.id} key={floorPlan.id}>
                  {floorPlan.name}
                </option>
              )),
          ]}
        </select>
      ),
      editorOptions: {
        editOnClick: true,
      },
    },
    // APT #
    {
      key: 'aptNo',
      name: 'Apt #',
      // headerRenderer: () => <div className='text-left'>Type</div>,
      width: 100,
      minWidth: 100,
      cellClass: 'text-center',
      resizable: true,
      sortable: true,
      editor: TextEditor,
    },
    // Price
    {
      key: 'price',
      name: 'Price',
      // headerRenderer: () => <div className='text-right'>Price</div>,
      cellClass: 'text-right',
      width: 100,
      resizable: true,
      sortable: true,
      editor: TextEditor,
      formatter({ row }) {
        return row.price ? (
          <CurrencyFormatter value={Number(row.price)} />
        ) : (
          <></>
        );
      },
    },
    // Move In
    {
      key: 'moveIn',
      name: 'Move In',
      // headerRenderer: () => <div className='text-center'>Available</div>,
      // cellClass: '',
      width: 120,
      resizable: true,
      sortable: true,
      // editor: ({ row, onRowChange }) => (
      // <input type='date' value={(Date.parse(row.moveIn)).toLocaleDateString('en-US')} onChange={(e) => onRowChange({ ...row, moveIn: e.target.value})} />
      // <><div id="date-popup" />
      // <DatePicker
      //   className='form-control'
      //   selected={Date.parse(row.moveIn)}
      //   onChange={(date) => onRowChange({ ...row, moveIn: date})}
      //   minDate={new Date()}
      //   dateFormat='M/d/yyyy'
      //   // withPortal
      //   //  portalId="root"

      //    popperContainer={({children}) => createPortal(children, document.getElementById('date-popup'))}

      //   //  popperPlacement="top-end"
      //   //  popperModifiers={[
      //   //    {
      //   //      name: "offset",
      //   //      options: {
      //   //        offset: [5, 10],
      //   //      },
      //   //    },
      //   //    {
      //   //      name: "preventOverflow",
      //   //      options: {
      //   //        rootBoundary: "viewport",
      //   //        tether: false,
      //   //        altAxis: true,
      //   //      },
      //   //    },
      //   //   ]}
      // />
      // </>
      // ),
      editor: DateEditor,
      formatter({ row, onRowChange }) {
        return Date.parse(row.moveIn) ? (
          <DateFormatter date={Date.parse(row.moveIn)} />
        ) : (
          <></>
        );
      },
      // formatter({ row, onRowChange }) {
      //   return Date.parse(row.moveIn) ? (
      //     <DateFormatter date={Date.parse(row.moveIn)} />
      //   ) : (
      //     <></>
      //   );
      // },
      editorOptions: {
        editOnClick: true,
        // renderFormatter: true
      },
    },
    // bed
    {
      key: 'bed',
      name: 'Bed',
      width: 100,
      sortable: true,
      cellClass: 'text-center',
      formatter: ({ row }) => (
        <>
          {floorPlans.find((floorPlan) => floorPlan.id === row.floorPlanId)
            ? unitTypes[
                floorPlans.find((floorPlan) => floorPlan.id === row.floorPlanId)
                  .bed
              ]
            : ''}
        </>
      ),
    },
    // bath
    {
      key: 'bath',
      name: 'Bath',
      cellClass: 'text-center',
      width: 60,
      sortable: true,
      formatter: ({ row }) => (
        <>
          {floorPlans.find((floorPlan) => floorPlan.id === row.floorPlanId)
            ? floorPlans.find((floorPlan) => floorPlan.id === row.floorPlanId)
                .bath
            : ''}
        </>
      ),
    },
    // Size
    {
      key: 'size',
      name: 'Size',
      // headerRenderer: () => <div className='text-right'>Size</div>,
      cellClass: 'text-right',
      width: 80,
      resizable: true,
      sortable: true,
      editor: TextEditor,
    },
    // addon list
    {
      key: 'addonItems',
      name: 'Unit Features',
      // width: 250,
      resizable: true,
      headerRenderer: () => <div className='text-left'>Unit Addons</div>,
      // formatter: ({ row, onRowChange }) => <small>{(row.addonItems?.map((addon) => { return addon }).join(', '))}</small>,
      formatter: ({ row, onRowChange }) =>
        row.addonItems && row.addonItems?.length < 2 ? (
          <>{row.addonItems[0]}</>
        ) : (
          <>
            {row.addonItems[0]} <small>+{row.addonItems?.length - 1}</small>
          </>
        ),
      editor: ({ row, onRowChange }) => (
        <CreatableSelect
          autoFocus
          name='Addons'
          isMulti
          value={row.addonItems?.map((addon) =>
            JSON.parse(JSON.stringify({ value: addon, label: addon }))
          )}
          defaultMenuIsOpen
          // options={[]}
          options={
            typeof addons !== 'undefined'
              ? Object.values(addons).map((addon) =>
                  JSON.parse(
                    JSON.stringify({ value: addon.name, label: addon.name })
                  )
                )
              : []
          }
          // options={(() => {
          //   try {
          //     return typeof addons !== 'undefined'
          //       ? Object.values(addons).map((addon) => {
          //           console.log(addon);
          //           return JSON.parse(
          //             JSON.stringify(
          //               `{"value":"${addon.name}", "label":"${addon.name}"}`
          //             )
          //           );
          //         })
          //       : [];
          //   } catch (error) {
          //     console.log('Error happened here!');
          //     console.error(error);
          //     return [];
          //   }
          // })()}
          onChange={(e) => {
            // console.log(e);
            onRowChange(
              {
                ...row,
                // addonList: e.map((addon) => addon.label),
                addonItems: e.map((addon) => addon.label),
              },
              true
            );
            // row.addonList = e.map((addon) => addon.label)
          }}
          // onChange={(e) => addFilter({ e, key: "selectedNeighborhood" })}
          className='grid-multi-select w-100'
          classNamePrefix='default-select'
          placeholder='Addons'
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          isSearchable
          menuPosition={'absolute'}
          menuPlacement={'auto'}
          menuShouldScrollIntoView={true}
        />
      ),
      editorOptions: {
        editOnClick: true,
      },
    },

    // {
    //   key: 'features',
    //   name: 'Features (To be removed)',
    //   // width: 350,
    //   minWidth: 200,
    //   resizable: true,
    //   headerRenderer: () => <div className='text-left'>Features</div>,
    //   editor: TextEditor,
    // },
  ];

  const sortedUnits = useMemo(() => {
    let newUnits = units.filter((unit) => unit.type === 'NEW');
    let tmpUnits = units.filter(
      (unit) => unit.type !== 'DELETED' && unit.type !== 'NEW'
    );

    const sortedUnits = [...tmpUnits];

    if (sortColumns.length === 0) return [...sortedUnits, ...newUnits];

    sortedUnits.sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
    return [...sortedUnits, ...newUnits];
  }, [units, sortColumns]);

  function getRowType(rowtype) {
    switch (rowtype) {
      case 'NEW':
      case 'CREATE':
        return 'CREATE';
      case 'UNCHANGED':
      case 'CHANGED':
      default:
        return 'CHANGED';
    }
  }

  function onUnitRowDelete(row) {
    if (row.type === 'CREATE') {
      setUnits(units.filter((unit) => unit.id !== row.id));
    } else {
      const rowPosition = units.findIndex((unit) => unit.id === row.id);
      const copiedUnits = _.cloneDeep(units);
      copiedUnits.splice(rowPosition, 1, {
        ...row,
        type: 'DELETED',
      });
      setUnits(copiedUnits);
      setIsChanged(true);
    }
  }

  function onUnitsRowsChange(rows, { indexes, column }) {
    setIsChanged(true);
    const row = rows[indexes[0]];
    // console.log(row, '--- CHANGED THIS UNIT', column, '---- at column');
    const copiedUnits = _.cloneDeep(units);

    const rowPosition = units.findIndex((unit) => unit.id === row.id);
    const addNewRow = row.type === 'NEW';
    // console.log(addNewRow, '---- addNewRow');
    const rowType = getRowType(row.type);

    copiedUnits.splice(rowPosition, 1, {
      ...row,
      type: rowType,
    });
    addNewRow
      ? setUnits([
          ...copiedUnits,
          {
            type: 'NEW',
            id: (row.id - 1).toString(),
            isAvailable: false,
            addonItems: [],
          },
        ])
      : setUnits(copiedUnits);
  }

  useEffect(() => {
    console.log(units, '---- UNITS CHANGED');
  }, [units]);

  // useEffect(() => {
  //   if (!isCellSelected) return;
  //   gridRef.current?.element
  //     ?.querySelector('[tabindex="0"]')
  //     ?.focus({ preventScroll: true });
  // }, [isCellSelected]);

  function onKeyDown(event) {
    if (event.isDefaultPrevented()) {
      event.stopPropagation();
    }
  }

  return (
    <>
      <div onKeyDown={onKeyDown} id='unit-grid'>
        <DataGrid
          // ref={gridRef}
          rows={sortedUnits}
          columns={unitColumns}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowsChange={onUnitsRowsChange}
          rowKeyGetter={rowKeyGetter}
          style={{ height: '64vh' }}
          rowHeight={() => 35}
          cellNavigationMode='NONE'
          enableCellAutoFocus={false}
          rowClass={(row) => (row.type === 'NEW' ? 'new-row' : undefined)}
        />
      </div>
    </>
  );
}
