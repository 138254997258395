const unitTypes = {
  studio: 'Studio',
  convertible: 'Convertible',
  bedroom1: '1 Bed',
  bedroom2: '2 Bed',
  bedroom3: '3 Bed',
  bedroom4: '4 Bed',
  /* penthouse: 'Penthouse', */
};
export default Object.freeze(unitTypes);
