import React from 'react';
import { unitTypes } from '@sparklib';
import { Row, Col, Label } from 'reactstrap';
export default function BedBath({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  const bathroomOptions = ['1', '1.5', '2', '2.5', '3'];

  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'bedbath' && 'hidden'
      }`}
    >
      <div className='filter-box' style={style}>
        <h6>Bed Size</h6>
        <Row>
          {Object.keys(unitTypes).map((typecode, index) => (
            <Col sm='6' key={index}>
              <Label className=''>
                <input
                  className='custom-checkbox d-inline-block w-auto mr-1'
                  type='checkbox'
                  name='bed'
                  value={typecode}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addFilter({
                        e: [...getFilter('bed'), typecode],
                        key: 'bed',
                      });
                    } else {
                      addFilter({
                        e: getFilter('bed').filter((a) => a !== typecode),
                        key: 'bed',
                      });
                    }
                  }}
                  checked={
                    getFilter('bed').find((a) => a === typecode) || false
                  }
                />{' '}
                {unitTypes[typecode]}
              </Label>
            </Col>
          ))}
          </Row>
          <h6>Bath Size</h6>
          <Row>
            {bathroomOptions.map((bathSize, index) => (
              <Col sm='6' key={index}>
                <Label className=''>
                  <input
                    className=' d-inline-block w-auto mr-1'
                    type='radio'
                    name='bath'
                    value={`{"value":"${bathSize}", "label":"${bathSize}"}`}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addFilter({
                          e,
                          key: 'bath',
                        });
                      }
                    }}
                    checked={
                      getFilter('bath') ===
                      `{"value":"${bathSize}", "label":"${bathSize}"}` //? getFilter('city') : city.id[0]
                    } /* {
                      getFilter('bath').find((a) => a === bathSize) || false
                    } */
                  />{' '}
                  {bathSize}+ Bath
                </Label>
              </Col>
            ))}
          </Row>
      </div>
    </div>
  );
}
