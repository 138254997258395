import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AgentListTable from './AgentListTable';
import AgentShow from './AgentShow';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
	Spinner
} from 'reactstrap';
import Loader from '../../Spinner';
import SidebarRight from '../../SidebarRight';
import { toast } from 'react-toastify';
import { Icon } from '@assets';
import config from '@config';
import { phoneNumberFormat, ToggleButton  } from '@sparklib';
import {
  ALL_AGENTS,
	DEACTIVATE_AGENTS,
	REACTIVATE_AGENTS,
	DELETE_AGENTS,
	BROKER
} from '@api';

export default function AgentList(props){
	const [broker, setBroker] = useState(null);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
	const [allAgents, setAllAgents] = useState([]);
	const [showInactive, setShowInactive] = useState(false);
	const [deleteWarningModal, setDeleteWarningModal] = useState(false);
	const [inactiveWarningModal, setInactiveWarningModal] = useState(false);
	const [showInviteAgentModal, setShowInviteAgentModal] = useState(false);
	const [isProgress, setIsProgress] = useState(false);

	const history = useHistory();
  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);

	const {data: agentsData, loading: loadingAgents} = useQuery(ALL_AGENTS);
	const {data: brokerData} = useQuery(BROKER, {
		onCompleted: (data) => {
			setBroker(data.broker);
		}
	})

	const [deactivateAgents] = useMutation(DEACTIVATE_AGENTS, {
		refetchQueries: [ALL_AGENTS],
		onCompleted: (data) => {
			if (data.deactivateAgents.errors && data.deactivateAgents.errors.length > 0){
				toast(data.deactivateAgents.message, { type: 'error' });
			} else {
				toast(data.deactivateAgents.message, { type: 'success' });
			}
			setIsProgress(false);
			setInactiveWarningModal(false);
			setIsCheckedId([]);
		}
	});

	const [reactivateAgents] = useMutation(REACTIVATE_AGENTS, {
		refetchQueries: [ALL_AGENTS],
		onCompleted: (data) => {
			if (data.reactivateAgents.errors && data.reactivateAgents.errors.length > 0){
				toast(data.reactivateAgents.message, { type: 'error' });
			} else {
				toast(data.reactivateAgents.message, { type: 'success' });
			}
		}
	});

	const [deleteAgents] = useMutation(DELETE_AGENTS, {
		refetchQueries: [ALL_AGENTS],
		onCompleted: (data) => {
			if (data.deleteAgents.errors && data.deleteAgents.errors.length > 0){
				toast(data.deleteAgents.message, { type: 'error' });
			} else {
				toast(data.deleteAgents.message, { type: 'success' });
			}
			setIsProgress(false);
			setDeleteWarningModal(false);
			setIsCheckedId([]);
		}
	});

	function handleDeactivateAgents(ids){
		var agentIds = Array.isArray(ids) ? ids : [Number(ids)];
		deactivateAgents({
			variables: {
				ids: agentIds
			}
		})
	}

	function handleReactivateAgents(ids){
		var agentIds = Array.isArray(ids) ? ids : [Number(ids)];
		reactivateAgents({
			variables: {
				ids: agentIds
			}
		})
	}

	function handleDeleteAgents(ids){
		var agentIds = Array.isArray(ids) ? ids : [Number(ids)];
		deleteAgents({
			variables: {
				ids: agentIds
			}
		})
	}

	useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

	useEffect(() => {
		if (agentsData) {
			var filteredAgents = []
			if (showInactive) filteredAgents = agentsData.allAgents.edges.filter(a => !a.node.approved);
			else filteredAgents = agentsData.allAgents.edges.filter(a => a.node.approved);
			setAllAgents(filteredAgents);
		}
	}, [showInactive, agentsData]);

	useEffect(() => {
    !sidebarState && history.push(`/agents`);
  }, [sidebarState, operation]);

	// useEffect(() => {
	// 	if (agentsData?.allAgents.edges?.length > 0) setAllAgents(agentsData.allAgents.edges.filter(a => a.node.approved));
	// }, [agentsData]);

	const columns = React.useMemo(
    () => [
			{
				Header: 'Name',
				accessor: 'node.name',
			},
			{
				Header: 'Email',
				accessor: 'node.email',
			},
			{
				Header: 'Phone',
				accessor: 'node.phoneNo',
			},
			{
				Header: 'License No',
				accessor: 'node.licenseNo',
			},
			{
				Header: 'Brokerage Name',
				accessor: 'node.brokerageName',
			},
			{
				Header: 'Status',
				accessor: 'node.approved',
				Cell: ({ value, row }) => (
          <div className='client-select-stage'>
            <span
              className={
                value === true ? 'p-2 badge-success' : 'p-2 badge-danger'
              }
            >
              {value ? "Active" : "Inactive"}
            </span>
          </div>
        ),
			},
			{
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <UncontrolledButtonDropdown>
							<DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
									onClick={() => {
										setSidebarState(true);
										history.push(`/agents/show/${value}`);
									}
									}
                >
                  View
                </DropdownItem>
                <DropdownItem divider />
								{
									row.original.node.approved ? 
										<DropdownItem
											onClick={() => toggleInactiveWarningModal(Number(value))}
										>
											Inactivate
										</DropdownItem> :
										<DropdownItem
											onClick={() => handleReactivateAgents(value)}
										>
											Reactivate
										</DropdownItem>
								}
                <DropdownItem divider />
                <DropdownItem
									onClick={() => toggleDeleteWarningModal(Number(value))}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
		]);

		function toggleInactiveWarningModal(id=null){
			if (id){
				setIsCheckedId([id]);
			}
			setInactiveWarningModal(!inactiveWarningModal);
		}

		function toggleDeleteWarningModal(id=null){
			if (id){
				setIsCheckedId([id]);
			}
			setDeleteWarningModal(!deleteWarningModal);
		}

		function toggleInviteAgentModal(){
			setShowInviteAgentModal(!showInviteAgentModal);
		}

	return (
		<>
		{loadingAgents && <Loader />}

			<SidebarRight
				sidebarState={sidebarState}
				sidebarWidth='600px'
				sidebarToggle={() => {
					setSidebarState(!sidebarState);
				}}
				sidebarContent={
					<AgentShow
						new={operation}
						sidebarToggle={() => {
							setSidebarState(!sidebarState);
						}}
						broker={broker}
						{...props}
					/>
				}
			/>
			<InviteAgent isShow={showInviteAgentModal} toggleModel={toggleInviteAgentModal} brokerId={broker?.idCode}/>
			{ deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} toggle={toggleDeleteWarningModal}>
					<ModalHeader toggle={toggleDeleteWarningModal}>Delete Confirmation</ModalHeader>
					<ModalBody>
						Are you sure you want to delete {isCheckedId.length} agent
						{isCheckedId.length > 1 ? 's' : ''}?
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							disabled={isProgress}
							onClick={() => handleDeleteAgents(isCheckedId)}
						>
							Yes
						</Button>
						<Button color='success' onClick={toggleDeleteWarningModal}>
							No
						</Button>
					</ModalFooter>
				</Modal>
			}
			{ inactiveWarningModal && 
				<Modal isOpen={inactiveWarningModal} toggle={toggleInactiveWarningModal} centered>
					<ModalHeader toggle={toggleInactiveWarningModal}>Inactivate Confirmation</ModalHeader>
					<ModalBody>
						Are you sure you want to inactivate {isCheckedId.length} agent
						{isCheckedId.length > 1 ? 's' : ''}?
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							disabled={isProgress}
							onClick={() => handleDeactivateAgents(isCheckedId)}
						>
							Yes
						</Button>
						<Button color='success' onClick={toggleInactiveWarningModal}>
							No
						</Button>
					</ModalFooter>
				</Modal>
			}

			<Row>
				<Col md='12'>
					{
						agentsData?.allAgents.edges.length > 0 ? 
						<AgentListTable 
							columns={columns}
							data={allAgents}
							filterable
							setSidebarState={setSidebarState}
							changeIsChecked={(isChecked) => setIsChecked(isChecked)}
							// sidebarContent={}
							rightAddons={
								<>
									<Button
										color='red'
										onClick={() => toggleDeleteWarningModal()}
										disabled={isCheckedId.length > 0 ? false : true}
										className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
									>
										<Icon icon='trash-alt' className='mr-2' />
										Delete
									</Button>
									{	showInactive ? 
										<Button
											color='red'
											onClick={() => handleReactivateAgents(isCheckedId)}
											disabled={isCheckedId.length > 0 ? false : true}
											className='button-dashboard float-right mr-sm-3 mt-3 mt-sm-0'
										>
											<Icon icon='user-check' className='mr-2' />
											Reactivate
										</Button> :
										<Button
											color='red'
											onClick={() => toggleInactiveWarningModal()}
											disabled={isCheckedId.length > 0 ? false : true}
											className='button-dashboard float-right mr-sm-3 mt-3 mt-sm-0'
										>
											<Icon icon='user-lock' className='mr-2' />
											Inactivate
										</Button>
									}
									<Button
										color='blue'
										onClick={() => setShowInviteAgentModal(true)}
										className='button-dashboard float-left mr-sm-5 mt-3 mt-sm-0'
									>
										<Icon icon='plus' className='mr-2' />
										Invite Agents
									</Button>
								</>
              }
							leftAddons={
								<div className='properties-toggle-switch d-inline-block ml-3'>
									<ToggleButton
										selected={showInactive}
										firstOption='Inactive'
										secondOption='Active'
										width='120px'
										rounded
										toggleSelected={() => {
											setShowInactive(!showInactive);
										}}
									/>
								</div>
							}
						/> :
						<div className='text-center' style={{ margin: '35vh 0' }}>
							<h4>Oh... looks like you haven't added any agents yet!</h4>
							<Button
								onClick={() => toggleInviteAgentModal()}
								color='success'
								className='button-dashboard mt-2'
							>
								Invite Agents{' '}
								<Icon icon='chevron-right' className='ml-2' />
							</Button>
						</div>
					}
				</Col>
			</Row>
		</>
	);
}

function InviteAgent(props){
	let url = config.url.WEB_DOMAIN + 'agent-sign-up/?b=' + props.brokerId;
	return (
		<>
			<Modal
				isOpen={props.isShow}
				toggle={props.toggleModel}
				centered
				className='change-password-modal text-center'
			>
        <ModalHeader toggle={props.toggleModel}>Invite Agents</ModalHeader>
				<ModalBody className='inputs-icons'>
					<div className='invite-agent-modal'>
						<h6>Send the following url to your agents to singup in SparkAPT</h6>
						{props.brokerId === undefined ?
						<Spinner
							color="primary"
							type="grow"
						>
							Loading...
						</Spinner> : 
						<ClipboardCopy copyText={url} />}
					</div>
					<br />
				</ModalBody>
			</Modal>
		</>
	);
}

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
	// if (document.getElementById("signup-url")){
	// 	document.getElementById("signup-url").focus();
	// }

  return (
    <>
			<div >
      	<input type="text" value={copyText} readOnly className='copy-clipboard mt-3' id='signup-url' onClick={handleCopyClick} />
      	<button onClick={handleCopyClick} className='copy-clipboard-button'>
        	<span>{isCopied ? 'Copied!' : 'Copy'}</span>
      	</button>
    	</div>
    </>
  );
}