// import React from 'react';

// export const textEditorClassname = `rdg-text-editor ${textEditor}`;
export const textEditorClassname = `rdg-text-editor`;
import {
  escapeStopPropagation,
} from './gridHelpers';

function autoFocusAndSelect(input) {
  input?.focus();
  input?.select();
}

export function TextEditor({
  row,
  column,
  onRowChange,
  onClose
}) {
  return (
    <input
      className={textEditorClassname}
      ref={autoFocusAndSelect}
      value={row[column.key]}
      onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
      onBlur={() => onClose(true)}
    />
  );
}

export const datePickerClassname = `rdg-date-picker`;

export function DateEditor({
  row,
  column,
  onRowChange,
  onClose
}) {
  return (
    <input
      type='date'
      className={datePickerClassname}
      placeholder="mm-dd-yyyy"
      ref={autoFocusAndSelect}
      value={row[column.key]}
      // onKeyDown={escapeStopPropagation}
      onKeyDown={(e) => {(e.key === 'Escape') ? e.stopPropagation() : e}}
      onChange={(event) => onRowChange({ ...row, [column.key]: event.target.value })}
      onBlur={() => onClose(true)}
    />
  );
}