import React, { useEffect, useState } from 'react';
import { PROPERTY_POLICIES } from '@api';
import { useQuery } from '@apollo/client';
import { Row, Col, Label, InputGroup, InputGroupText, Input } from 'reactstrap';
import Nouislider from 'nouislider-react';
import { Icon } from '@assets';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

export default function Policies({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  const policies = useQuery(PROPERTY_POLICIES);
  const { data: policiesData } = policies;
  const [startDate, setStartDate] = useState(new Date());
  const numberFormatter = new Intl.NumberFormat('en-US');
  let yearArray = [];
  for (var i = 1900; i < new Date().getFullYear(); i = i + 10) {
    yearArray.push(i);
  }
  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'policies' && 'hidden'
      }`}
    >
      <div className='filter-box' style={style}>
        <h6 style={{ display: 'inline-block' }}>Property</h6>
        <Row className='mt-2'>
          <Col md='6' className='inputs-icons-right'>
            <Label>Minimum Escort</Label>
            <InputGroup>
              <Input
                type='number'
                placeholder='Escort'
                name='escort'
                value={getFilter('escort') || ''}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '') {
                    addFilter({ e });
                  }
                }}
                min='0'
              />
              <InputGroupText>
                <Icon size='sm' icon='percent' />
              </InputGroupText>
            </InputGroup>
          </Col>
          <Col md='6' className='inputs-icons-right mt-md-0 mt-3'>
            <Label>Minimum Send</Label>
            <InputGroup>
              <Input
                type='number'
                placeholder='Send'
                name='minSend'
                value={getFilter('minSend') || ''}
                onChange={(e) => {
                  if (e.target.value >= 0 || e.target.value === '') {
                    addFilter({ e });
                  }
                }}
                min='0'
              />
              <InputGroupText>
                <Icon size='sm' icon='percent' />
              </InputGroupText>
            </InputGroup>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md='12' className='inputs-icons'>
            <Label>Flat Fee</Label>
            <InputGroup>
              <InputGroupText>
                <Icon icon='dollar-sign' />
              </InputGroupText>
              <Input
                type='text'
                placeholder='Flat Fee'
                name='bonus'
                value={
                  getFilter('bonus')
                    ? numberFormatter.format(getFilter('bonus'))
                    : ''
                }
                onChange={(e) => {
                  var bonusValue = Number(e.target.value?.replace(/,/g, ''));
                  if (bonusValue >= 0 || bonusValue === '') {
                    addFilter({
                      e: {
                        target: {
                          value: bonusValue,
                          name: 'bonus',
                        },
                      },
                    });
                  }
                }}
                min='0'
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col md='6'>
            <Label>
              Built After -{' '}
              {getFilter('yearFrom') ? getFilter('yearFrom') : 'Any'}
            </Label>
            <div className='px-2 py-2 mb-1 w-100'>
              <Nouislider
                connect='lower'
                tooltips={true}
                animate={true}
                start={getFilter('yearFrom') || new Date().getFullYear()}
                behaviour='tap'
                range={{
                  min: 1980,
                  max: new Date().getFullYear(),
                }}
                /* pips={{
                      mode: 'values',
                      values: yearArray,
                      density: 10,
                    }} */
                onChange={(e) => {
                  addFilter({
                    e: {
                      target: {
                        name: 'yearFrom',
                        value:
                          Number(e[0]) === new Date().getFullYear() ? '' : e[0],
                      },
                    },
                  });
                }}
                format={{
                  to: function (value) {
                    return value === undefined ? '' : value.toFixed(0);
                  },
                  from: Number,
                }}
              />
            </div>
          </Col>
          <Col md='6'>
            <Label>
              Renovated After -{' '}
              {getFilter('renovatedFrom') ? getFilter('renovatedFrom') : 'Any'}
            </Label>
            <div className='px-2 py-2 mb-1 w-100'>
              <Nouislider
                connect='lower'
                tooltips={true}
                animate={true}
                start={getFilter('renovatedFrom') || new Date().getFullYear()}
                behaviour='tap'
                range={{
                  min: 1980,
                  max: new Date().getFullYear(),
                }}
                onChange={(e) => {
                  addFilter({
                    e: {
                      target: {
                        name: 'renovatedFrom',
                        value:
                          Number(e[0]) === new Date().getFullYear() ? '' : e[0],
                      },
                    },
                  });
                }}
                format={{
                  to: function (value) {
                    return value === undefined ? '' : value.toFixed(0);
                  },
                  from: Number,
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col md='12'>
            <Label>Policies</Label>
            <Select
              name='policies'
              isMulti
              value={getFilter('policies')}
              options={
                typeof policiesData !== 'undefined'
                  ? Object.values(policiesData.propertyPolicies).map((policy) =>
                      JSON.parse(
                        `{"value":"${policy.name}", "label":"${policy.name}"}`
                      )
                    )
                  : []
              }
              onChange={(e) => addFilter({ e, key: 'policies' })}
              className='basic-multi-select w-100'
              classNamePrefix='default-select'
              placeholder='Policies'
              menuPlacement={'auto'}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
