import React, {
  useRef,
  useLayoutEffect as useOriginalLayoutEffect,
  useContext,
} from 'react';
import { FilterContext } from './gridHelpers';
import { SortableHeaderCell } from 'react-data-grid';

const useLayoutEffect =
  typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef(isSelected) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!isSelected) return;
    ref.current?.focus({ preventScroll: true });
  }, [isSelected]);

  return {
    ref,
    tabIndex: isSelected ? 0 : -1,
  };
}

export default function FilterRenderer({
  isCellSelected,
  column,
  sortDirection,
  onSort,
  priority,
  children,
  headerClassName = 'align-left',
  headerRowHeight = 45,
}) {
  // console.log(props);

  const filters = useContext(FilterContext);
  const { ref, tabIndex } = useFocusRef(isCellSelected);
  const reduceLineHeightBy = filters.enabled ? 10 : 0;
  const hideFilterClassName = filters.enabled ? '' : 'hide-filters';
  return (
    <>
      <>
        <div
          className={headerClassName}
          style={{
            transition: '0.2s',
            lineHeight: headerRowHeight - reduceLineHeightBy + 'px',
          }}
        >
          <SortableHeaderCell
            sortDirection={sortDirection}
            onSort={onSort}
            priority={priority}
            // isCellSelected={isCellSelected}
          >
            {column.name}
          </SortableHeaderCell>
        </div>
        <div className={'filters ' + hideFilterClassName}>
          {children({ ref, tabIndex, filters })}
        </div>
      </>
    </>
  );
}
