import React, { useEffect, useRef, useState } from 'react'
import {
	FormGroup,
	Label,
	InputGroupText,
	Input,
	Form,
	Row,
	Col,
	InputGroup,
	Button,
	Alert
} from 'reactstrap';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import _ from 'lodash/fp';
import { CREATE_EMAIL_TEMPLATE, UPDATE_EMAIL_TEMPLATE, SEND_EMAIL, EMAIL_TEMPLATES, CLIENTS, ALL_PROPERTIES, DELETE_EMAIL_TEMPLATE } from '@api'
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import 'suneditor/dist/css/suneditor.min.css'
import suneditor from 'suneditor'
import plugins from 'suneditor/src/plugins'
import { format } from 'date-fns-tz'

const appointmentTypesValue = [
  { value: 'Appointment Type', label: 'Appointment Type', isDisabled: 'true' },
  { value: 'inperson', label: 'In-Person with Broker' },
  { value: 'without_broker', label: 'Send without Broker' },
  { value: 'virtual', label: 'Virtual Tour' },
  { value: 'self_guided', label: 'Self-Guided' },
];

export default function EmailTemplateForm({ currentTemplate, resetTemplate }) {
	const editorRef = useRef(null)
	const [inProgress, setInProgress] = useState(false)
	const [emailTemplates, setEmailTemplates] = useState([])
	const defaultTemplate = {
		name: '',
		subject: '',
		sendTo: '',
		ccTo: '',
		bccTo: '',
		body: ''
	}
	const [emailTemplate, setEmailTemplate] = useState(currentTemplate || defaultTemplate)
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState();
	const [selectedClient, setSelectedClient] = useState();
	const { data: clientsList, loading: loadingClients } = useQuery(CLIENTS);
	const [selectedProperty, setSelectedProperty] = useState();
	const { data: propertiesList, loading: loadingProperties } = useQuery(ALL_PROPERTIES);
	const [appointmentType, setAppointmentType] = useState();
	const [tourDate, setTourDate] = useState(new Date());
	
	useEffect(() => {
		setEmailTemplate(currentTemplate || defaultTemplate)
	}, [currentTemplate])

	useEffect(() => {
		if (editorRef.current) {
			editorRef.current.setContents(emailTemplate?.body || "")
		}
	}, [emailTemplate])

	const {refetch: refetchTemplates} = useQuery(EMAIL_TEMPLATES, {
		onCompleted: (data) => {
			setEmailTemplates(data.emailTemplates)
		},
		nextFetchPolicy: 'cache-and-network'
	})

	const [createEmailTemplate] = useMutation(CREATE_EMAIL_TEMPLATE, {
		onCompleted: (data) => {
			if (data.createEmailTemplate.errors){
				toast(data.createEmailTemplate.message, { type: 'error' })
			} else {
				toast(data.createEmailTemplate.message, { type: 'success' })
				setEmailTemplate(defaultTemplate)
				editorRef.current.setContents("")
				// refetchTemplates()
			}
			setInProgress(false)
		},
		refetchQueries: [EMAIL_TEMPLATES],
		onError: (e) => {
      console.log(e);
    },
	})

	const [sendEmail] = useMutation(SEND_EMAIL, {
		onCompleted: (data) => {
			if (data.sendEmail.errors){
				toast(data.sendEmail.message, { type: 'error' })
			} else {
				toast(data.sendEmail.message, { type: 'success' })
				// setEmailTemplate(defaultTemplate)
				// editorRef.current.setContents("")
			}
			setInProgress(false)
		},
		onError: (e) => {
      console.log(e);
    },
	})

	const [updateEmailTemplate] = useMutation(UPDATE_EMAIL_TEMPLATE, {
		onCompleted: (data) => {
			if (data.updateEmailTemplate.errors){
				toast(data.updateEmailTemplate.message, { type: 'error' })
			} else {
				toast(data.updateEmailTemplate.message, { type: 'success' })
				// refetchTemplates()
			}
			setInProgress(false)
		},
		refetchQueries: [EMAIL_TEMPLATES],
		onError: (e) => {
      console.log(e);
    },
	})

	const [deleteEmailTemplate] = useMutation(DELETE_EMAIL_TEMPLATE, {
    onCompleted: (data) => {
      if (data.deleteEmailTemplate.message) {
        toast(data.deleteEmailTemplate.message, { type: 'success' });
      }
      if (data.deleteEmailTemplate.errors && data.deleteEmailTemplate.errors.length > 0) {
        data.deleteEmailTemplate.errors.map((err) => toast(err, { type: 'error' }));
      }
    },
		refetchQueries: [EMAIL_TEMPLATES],
    onError: (e) => {
      console.log(e);
    },
  });

  const handleDeleteEmailTemplate = (emailTemplateId) => {
    deleteEmailTemplate({
      variables: {
        id: parseInt(emailTemplateId),
      }
    })
  };

	// Custom option component for select
  const CustomOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>{props.data.label}</span>
          {props.data.userId && (
            <Button
              color="danger"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
								const confirmed = window.confirm("Are you sure you want to delete this email template?");
								if (confirmed) {
									handleDeleteEmailTemplate(props.data.value);
								}
              }}
            >
              Delete
            </Button>
          )}
        </div>
      </components.Option>
    );
  };

	function changeDetails(event){
		if (!event.target){
			setEmailTemplate((prev) => ({...prev, body: event})) 
			return
		}
		const { name, value } = event.target
		setEmailTemplate((prev) => ({...prev, [name]: value}))
	}

	const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });


	const onSubmit = async (data, e) => {
    e.preventDefault();
		if (!emailTemplate.body) {
			toast("Enter valid email body", { type: 'error' });
			return 
		}
		if (!emailTemplate.userId) {
			createEmailTemplate({
				variables: emailTemplate
			})
		} else {
			updateEmailTemplate({
				variables: {
					...emailTemplate,
					id: Number(emailTemplate.id)
				}
			})
		}
		setInProgress(true)
  };

	function handleEmailSend(){
		let newDate = tourDate ? format(tourDate, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: 'UTC' }) : null;
		sendEmail({
			variables: {
				...emailTemplate,
				clientId: Number(selectedClient.value),
				propertyId: Number(selectedProperty.value),
				appointmentType: appointmentType?.value,
				tourAt: newDate
			}
		})
		setInProgress(true)
	}

	useEffect(() => {
		editorRef.current = suneditor.create('rich_text_editor', {
			plugins: plugins,
			defaultStyle: 'width: 100%; min-height: 200px',
			buttonList: [
					['undo', 'redo'],
					['font', 'fontSize', 'formatBlock'],
					['paragraphStyle', 'blockquote'],
					['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
					['fontColor', 'hiliteColor', 'textStyle'],
					['removeFormat'],
					'/',
					['outdent', 'indent'],
					['align', 'horizontalRule', 'list', 'lineHeight'],
					['table', 'link'],
			]
		})

		// editorRef.current.onChange = function (contents, core) {
		// 	changeDetails(contents)
		// }

		editorRef.current.onBlur = function (e, core) {
			changeDetails(core.getContents());
    }

	}, [])
	
	return (
		<>
			<div className="p-4">
				<Form className='form' onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md='12'>
							<FormGroup>
                <Label for='emailTemplates'>Select Template</Label>
                { emailTemplates ? (
                  <InputGroup>
                    <Select
                      closeMenuOnSelect={true}
                      options={emailTemplates.map((template) =>
                        JSON.parse(
                          `{"value":"${template.id}", "label":"${template.name}", "userId":${template.userId}}`
                        )
                      )}
                      isMulti={false}
                      placeholder='Create New Template'
                      onChange={(selectedEmailTemplate) => {
                        setSelectedEmailTemplate(selectedEmailTemplate);
                        if (selectedEmailTemplate?.value) {
                          var selected_email_template = emailTemplates.filter((emailTemplate) => emailTemplate.id === selectedEmailTemplate?.value);
                          setEmailTemplate({...selected_email_template[0]});
                        }
                        else {
                        	setEmailTemplate(defaultTemplate);
                        }
                        if (selectedEmailTemplate?.label) {
                        	if (selectedEmailTemplate?.label == "Guest Card - With Appointment") {
                        		document.getElementById("appointment-type").style.display = "block";
                        		document.getElementById("appointment-date-time").style.display = "block";
														setTourDate(new Date());
                        	}
                        	else {
                        		document.getElementById("appointment-type").style.display = "none";
                        		document.getElementById("appointment-date-time").style.display = "none";
														setTourDate(null);
                        	}
                        }
                      }}
                      value={selectedEmailTemplate}
                      className='multi-select-client w-100'
                      classNamePrefix='default-select'
											isDisabled={false}
											isClearable={true}
											components={{ Option: CustomOption }}
                    />
                  </InputGroup>
                ) : <Input value={selectedEmailTemplate?.label} disabled={true}/>
								}
              </FormGroup>
						</Col>
						<Col md='12'>
							<FormGroup className='inputs-icons'>
								<Label for='name' >Template</Label>
								<InputGroup>
									<Input
										type='text'
										name='name'
										onChange={changeDetails}
										value={emailTemplate.name}
										autoComplete='off'
										innerRef={register({
											required: true,
										})}
									/>
								</InputGroup>
								{_.get('name.type', errors) === 'required' && (
									<p className='input-error'>* Template Name required.</p>
								)}
							</FormGroup>
						</Col>
						<Col md='12'>
							<FormGroup className='inputs-icons'>
								<Label for='name' >Subject</Label>
								<InputGroup>
									<Input
										type='text'
										name='subject'
										onChange={changeDetails}
										value={emailTemplate.subject}
										autoComplete='off'
										innerRef={register({
											required: true,
										})}
									/>
								</InputGroup>
								{_.get('subject.type', errors) === 'required' && (
									<p className='input-error'>* Email subject required.</p>
								)}
							</FormGroup>
						</Col>
						<Col md='6'>
							<FormGroup>
                <Label for='clients'>Clients</Label>
                { clientsList ? (
                  <InputGroup>
                    <Select
                      closeMenuOnSelect={true}
                      options={clientsList.clients.edges.map((client) =>
                        JSON.parse(
                          `{"value":"${client.node.id}", "label":"${client.node.name.replace(/['"]/g, '\\"')}"}`
                        )
                      )}
                      isMulti={false}
                      placeholder='Select Client'
                      onChange={(selectedClient) => {
                        setSelectedClient(selectedClient);
                        // if (selectedClient?.value) {
                        //   var selected_client = clientsList.clients.edges.filter((client) => client.node.id === selectedClient?.value);
                        //   if (selected_client && selected_client[0] && selected_client[0].node) {
                        //   	if (selected_client[0].node.email) {
                        //   		emailTemplate.ccTo = selected_client[0].node.email;
                        //   	}
                        //   	if (selected_client[0].node.secondaryEmail) {
                        //   		emailTemplate.bccTo = selected_client[0].node.secondaryEmail;
                        //   	}
                        //   }
                        // }
                      }}
                      value={selectedClient}
                      className='multi-select-client w-100'
                      classNamePrefix='default-select'
                    />
                  </InputGroup>
                ) : <Input value={selectedClient?.label} disabled={true}/>
								}
              </FormGroup>
						</Col>
						<Col md='6'>
							<FormGroup>
                <Label for='properties'>Communities</Label>
                { propertiesList ? (
                  <InputGroup>
                    <Select
                      closeMenuOnSelect={true}
                      options={propertiesList.allProperties.edges.map((property) =>
                        JSON.parse(
                          `{"value":"${property.node.id}", "label":"${property.node.name.replace(/['"]/g, '\\"')}"}`
                        )
                      )}
                      isMulti={false}
                      placeholder='Select Community'
                      onChange={(selectedProperty) => {
                        setSelectedProperty(selectedProperty);
                        if (selectedProperty?.value) {
                          var selected_property = propertiesList.allProperties.edges.filter((property) => property.node.id === selectedProperty?.value);
                          if (selected_property && selected_property[0] && selected_property[0].node) {
                          	if (selected_property[0].node.email) {
                          		emailTemplate.sendTo = selected_property[0].node.email;
                          	}
                          	if (selected_property[0].node.secondaryEmail) {
                          		emailTemplate.ccTo = selected_property[0].node.secondaryEmail;
                          	}
														else {
															emailTemplate.ccTo = localStorage.getItem('user_email');
														}
                          	if (selected_property[0].node.otherEmail) {
                          		emailTemplate.bccTo = selected_property[0].node.otherEmail;
                          	}
                          }
                        }
                      }}
                      value={selectedProperty}
                      className='multi-select-client w-100'
                      classNamePrefix='default-select'
                    />
                  </InputGroup>
                ) : <Input value={selectedProperty?.label} disabled={true}/>
								}
              </FormGroup>
						</Col>
						<Col md='4'>
							<FormGroup className='inputs-icons'>
								<Label for='sendTo' >To</Label>
								<InputGroup>
									<Input
										type='text'
										name='sendTo'
										onChange={changeDetails}
										value={emailTemplate.sendTo}
										autoComplete='off'
									/>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col md='4'>
							<FormGroup className='inputs-icons'>
								<Label for='ccTo' >CC</Label>
								<InputGroup>
									<Input
										type='text'
										name='ccTo'
										onChange={changeDetails}
										value={emailTemplate.ccTo}
										autoComplete='off'
									/>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col md='4'>
							<FormGroup className='inputs-icons'>
								<Label for='bccTo' >BCC</Label>
								<InputGroup>
									<Input
										type='text'
										name='bccTo'
										onChange={changeDetails}
										value={emailTemplate.bccTo}
										autoComplete='off'
									/>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col md="12">
							<FormGroup className='inputs-icons'>
								<Label for='body'>Body</Label>
								<InputGroup>
									<textarea id="rich_text_editor" width='100%'>{emailTemplate.body}</textarea>
								</InputGroup>
							</FormGroup>
						</Col>
						<Col md='6' id='appointment-type' style={{display: "none"}}>
							<FormGroup>
                <Label for='appoinmentType'>Appointment Type</Label>
                <InputGroup>
                  <Select
                    name='appoinmentType'
                    isMulti={false}
                    value={appointmentType}
                    options={appointmentTypesValue}
                    onChange={(e) => setAppointmentType(e)}
                    className='multi-select-client w-100'
                    classNamePrefix='default-select'
                    placeholder='Select Appointment Type'
                  />
                </InputGroup>
              </FormGroup>
						</Col>
						<Col md='6' id='appointment-date-time' style={{display: "none"}}>
							<FormGroup>
                <Label for='tourDate'>Tour Date & Time</Label>
                <DatePicker
                  wrapperClassName='r-date-time'
                  className='form-control r-date-time'
                  showTimeSelect
									minDate={new Date()}
                  selected={tourDate}
                  onChange={(date) => setTourDate(date)}
                  timeFormat='p'
                  timeIntervals={15}
									autoComplete='off'
                  locale='pt-BR'
                  dateFormat='MMMM d, yyyy p'
                />
              </FormGroup>
						</Col>
						<Col md="12">
						  <Alert color="info">
                **Fields such as [property_name] and [client_name] are filled after the email is sent.
						  </Alert>
						</Col>
						<Col md='12' className='text-center mt-5'>
							<Button 
								color='primary' 
								className='button-dashboard'
								onClick={handleEmailSend}
								disabled={inProgress}
							>
								Send Email
							</Button>
							{emailTemplate.userId ? 
								<Button 
									color='primary' 
									className='button-dashboard ml-4'
									disabled={inProgress}
								>
									Save
								</Button>
							:
								<Button 
									color='primary' 
									className='button-dashboard ml-4'
									disabled={inProgress}
								>
									Create
								</Button>
							}
							<Button
								className='button-dashboard ml-4'
								onClick={resetTemplate}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Form>
			</div>
		</>
	)
}
