const prod = {
  url: {
    WEB_DOMAIN: 'https://sparql.compli.tech/',
    DOMAIN_URL: 'https://sparkgql.compli.tech/',
    REACT_APP_BACKEND_BASE_URL: 'https://sparkgql.compli.tech/',
    REACT_APP_STRIPE_KEY:
      'pk_test_51IEUJMK4AkswaJ3TY6iRPeBEuryv7Uw7dQ2wM0ZcMptloAhWPN1Sx39CIWYECz1cfsY1KUGjXCb9wUZfeZ68HT8K004OnGxIQQ',
    ACTION_CABLE_URL: "wss://sparkgql.compli.tech/cable"
  },
  ga_id: 'UA-226941692-1',
  CAPTCHA_SITE_KEY: "6LcGL6YmAAAAAFXcx1xMZn77U7Q-yYS_PS3gpRzE"
};

const dev = {
  url: {
    WEB_DOMAIN: 'http://localhost:3000/',
    DOMAIN_URL: 'https://sparkgql.compli.tech/',
    REACT_APP_BACKEND_BASE_URL: 'https://sparkgql.compli.tech',
    // REACT_APP_BACKEND_BASE_URL: 'http://localhost:3002/',
    REACT_APP_STRIPE_KEY:
      'pk_test_51IEUJMK4AkswaJ3TY6iRPeBEuryv7Uw7dQ2wM0ZcMptloAhWPN1Sx39CIWYECz1cfsY1KUGjXCb9wUZfeZ68HT8K004OnGxIQQ',
    ACTION_CABLE_URL: "ws://localhost:3002/cable"
  },
  ga_id: 'UA-226941692-1',
  CAPTCHA_SITE_KEY: "6LcGL6YmAAAAAFXcx1xMZn77U7Q-yYS_PS3gpRzE"
};

export default process.env.NODE_ENV === 'development' ? dev : prod;

