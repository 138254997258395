import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  FormGroup,
  Button,
  Input,
  Card,
  CardHeader,
  CardBody,
  Form,
  InputGroup,
  Row,
  Col
} from 'reactstrap';
import _ from 'lodash/fp';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { SEND_FEEDBACK } from '@api';
import { useMutation } from '@apollo/client';
import { Icon } from '@assets';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '@sparklib';

export default function FeedbackForm(props) {
  const [feedbackFiles, setFeedbackFiles] = useState([]);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [sendFeedback, { loading, error }] = useMutation(SEND_FEEDBACK, {
    context: { hasUpload: true },
    onCompleted: (data) => {
      if (data.sendFeedback.errors && data.sendFeedback.errors.length > 0) {
        toast(data.sendFeedback.message, { type: 'error' });
      } else {
        toast(data.sendFeedback.message, { type: 'success' });
        props.parentRef();
        document.getElementById('title').value = '';
        document.getElementById('description').value = '';
        setFeedbackFiles([]);
      }
    },
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const onSubmit = (data, e) => {
    sendFeedback({
      variables: {
        title: data.title,
        description: data.description,
        feedbackFiles: feedbackFiles
      },
    });
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    feedbackFiles &&
      feedbackFiles.map((feedbackFile) => URL.revokeObjectURL(feedbackFile.preview));
  }, [feedbackFiles]);

  return (
    <>
      <div
        className={
          props.displayModal ? 'feedback-modal open' : 'feedback-modal'
        }
      >
        <Card className='theme-card'>
          <CardHeader onClick={props.parentRef}>
            <small>
              <div className='d-flex align-items-center justify-content-between'>
                Send us your feedback{' '}
                <Icon icon='times' className='cursor-pointer' />
              </div>
            </small>
          </CardHeader>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <div className='mb-3'>
                Need help? Have a suggestion? <br />
                Drop us a line.
              </div>

              <FormGroup>
                <Input
                  type='text'
                  innerRef={register({
                    required: true,
                  })}
                  placeholder='Subject'
                  name='title'
                  id='title'
                />
                {_.get('title.type', errors) === 'required' && (
                  <p className='input-error'>* required.</p>
                )}
              </FormGroup>
              <FormGroup>
                <Input
                  type='textarea'
                  placeholder='Description'
                  id='description'
                  name='description'
                  rows='5'
                  innerRef={register({
                    required: true,
                  })}
                />
                {_.get('description.type', errors) === 'required' && (
                  <p className='input-error'>* required.</p>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup>
                  {(feedbackFiles) && 
                    feedbackFiles.map((feedbackFile, index) => (
                      <>
                        <Col md='2' key={index}>
                          <img
                            src={
                              feedbackFile ? feedbackFile.preview : ''
                            }
                            alt={feedbackFile.name}
                            className='thumb mr-2'
                          />
                          <Icon
                            icon='times'
                            key={index}
                            className='remove-item my-profile'
                            onClick={() => {
                              setFeedbackFiles(
                                feedbackFiles.filter((feedbackFile, fbf) => fbf !== index)
                              );
                            }}
                          />
                        </Col>
                      </>
                    )
                  )}
                  <Dropzone
                    name='feedbackFilesDropZone'
                    accept='image/jpeg, image/png, image/jpg, *.pdf'
                    multiple={true}
                    onDrop={(acceptedFiles) => {
                      acceptedFiles.length > 0 &&
                        setFeedbackFiles((feedbackFiles) => [
                          ...feedbackFiles,
                          ...acceptedFiles.map((file) =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                            })
                          ),
                        ]);
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    }) => {
                      const style = useMemo(
                        () => ({
                          ...baseStyle,
                          ...(isDragActive ? activeStyle : {}),
                          ...(isDragAccept ? acceptStyle : {}),
                          ...(isDragReject ? rejectStyle : {}),
                        }),
                        [isDragActive, isDragReject, isDragAccept]
                      );
                      return (
                        <span className='flex-grow'>
                          <div {...getRootProps({ style })}>
                            <input {...getInputProps()} />
                            <div className='text-center w-100'>
                              Click to choose files or drag them here.<br />
                              <small>
                                <em>
                                  JPEG, JPG, PNG or PDF files supported.<br/>
                                  Maximum file sizes of 10 MB.
                                </em>
                              </small>
                            </div>
                          </div>
                        </span>
                      );
                    }}
                  </Dropzone>
                </InputGroup>
              </FormGroup>
              <Button color='green' className='button-dashboard float-right'>
                Submit
              </Button>
            </CardBody>
          </Form>
        </Card>
      </div>

      <div onClick={props.parentRef} className='feedback-btn'>
        <Icon icon='comments' />
      </div>
    </>
  );
}
