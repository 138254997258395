import React, { useState } from 'react';
import { Icon } from '@assets';
import { Collapse, Button, CardBody, Card, Col, Row } from 'reactstrap';
import ClientRating from './../../Recommendation/ClientRating';

export default function CustomCollapse({
  item,
  isAvailableCount,
  currencyFormatter,
  pastDate,
  isSelected,
  selectedProperty,
  property,
  reportId,
  reportCode,
  reportProperties,
  pageName
}) {
  const [collapsed, setCollapsed] = useState(true);
  const toggleMoreInfo = () => setCollapsed(!collapsed);

  function unitCount(item) {
    let count = '';
    if (pageName === 'showProperty') {
      count =
        isAvailableCount(item.units).length === 1
          ? ' 1 PRICE '
          : isAvailableCount(item.units).length + ' PRICES ';
    } else {
      count =
        isAvailableCount(item.units).length === 1
          ? ' 1 PRICE '
          : isAvailableCount(item.units).length + ' PRICES ';
    }
    return count;
  }

  return (
    <div>
			{unitCount(item).length > 0 &&
				<Button
					style={{
						backgroundColor: 'white',
						border: 'none',
						color: 'black',
						paddingTop: '0px',
						outline: 'none',
						boxShadow: 'none',
					}}
					className='mb-2 mt-0 pt-0'
					onClick={toggleMoreInfo}
				>
					{collapsed ? <>HIDE </> : <>SHOW </>}
					{unitCount(item)}
					{collapsed ? (
						<>
							{' '}
							<Icon icon='chevron-up' />
						</>
					) : (
						<>
							{' '}
							<Icon icon='chevron-down' />
						</>
					)}
				</Button>
			}
      <Collapse className='' isOpen={collapsed}>
        {pageName === 'Report' &&
          item.units.map(
            (data) =>
              data.isAvailable === true && (
							<Col md='12'>
								<Row className='unit-box'>
                  <Col md='12' className='px-0 pl-1 position-relative'>
                    <Card className='text-left border-0 bg-transparent' key={data.id}>
                      <CardBody>
                        <span>
                          <strong>
                            {' '}
                            {data.price > 0
                              ? '$' + currencyFormatter.format(data.price)
                              : '-'}
                          </strong>
                        </span>
                        <span className='mx-2'>·</span>
                        <span>
                          {Number(data.aptNo) > 0 ? '#' + data.aptNo : data.aptNo}
                        </span>
                        <span className='mx-2'>·</span>
                        <span>
                          {data.size > 0 ? (
                            <>
                              {currencyFormatter.format(data.size)} sqft
                              <sup>2</sup>
                            </>
                          ) : (
                            '-'
                          )}
                        </span>
                        <span className='mx-2'>·</span>
                        <span>
                          {data.moveIn ? pastDate(data.moveIn, true) : ''}
                        </span>
                      </CardBody>
                    </Card>
                    <ClientRating
                      reportId={reportId}
                      reportCode={reportCode}
                      propertyId={property.id}
                      floorPlanId={item.id}
                      typeDetailId={data.id}
                      rating={
                        reportProperties.find(
                          (rp) =>
                            Number(rp.floorPlanId) === Number(item.id) &&
                            rp.typeDetailId === Number(data.id)
                        )?.clientRating
                      }
                      notes={
                        reportProperties.find(
                          (rp) =>
                            Number(rp.floorPlanId) === Number(item.id) &&
                            rp.typeDetailId === Number(data.id)
                        )?.clientNotes
                      }
                    />
                  </Col>
                </Row>
              </Col>
              )
          )}
        {pageName === 'showProperty' &&
          item.units.map(
            (data) =>
              data.isAvailable === true && (
                <Card className='text-left unit-box px-2' key={data.id}>
                  <CardBody>
                    <span>
                      <strong>
                        {' '}
                        {data.price > 0
                          ? '$' + currencyFormatter.format(data.price)
                          : '-'}
                      </strong>
                    </span>
                    <span className='mx-2'>·</span>
                    <span>
                      {Number(data.aptNo) > 0 ? '#' + data.aptNo : data.aptNo}
                    </span>
                    <span className='mx-2'>·</span>
                    <span>
                      {data.size > 0 ? (
                        <>
                          {currencyFormatter.format(data.size)} sqft
                          <sup>2</sup>
                        </>
                      ) : (
                        '-'
                      )}
                    </span>
                    <span className='mx-2'>·</span>
                    <span>
                      {data.moveIn ? pastDate(data.moveIn, true) : ''}
                    </span>
                    <span
                      className='float-right'
                      onClick={(e) => {
                        selectedProperty(property.id, item.id, data.id);
                      }}
                    >
                      {isSelected(property.id, item.id, data.id) === true ? (
                        <Icon
                          icon='heart'
                          className='text-right cursor-pointer'
                          size='lg'
                        />
                      ) : (
                        <Icon
                          size='lg'
                          className='cursor-pointer'
                          icon={['far', 'heart']}
                        />
                      )}
                    </span>
                  </CardBody>
                </Card>
              )
          )}
      </Collapse>
    </div>
  );
}
