import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Icon } from '@assets';
import { Row, Col, Button } from 'reactstrap';
import _ from 'lodash';
import MapPropertiesList from './MapPropertiesList';
import { propertyTypes, bathTypes } from '@sparklib';
import PropertyPagination from '../List/PropertyPagination';
import Gmap from './Gmap';

export default function Map(props) {
  const [currentBounds, setCurrentBounds] = useState(null);
  const [hoverPropertyId, setHoverPropertyId] = useState(null);
  const history = useHistory();
  const numberFormatter = new Intl.NumberFormat('en-US');
  let allProperties = _.uniqBy(
    [...props.selectedPropertyArray, ...props.properties],
    'node.id'
  );

  function getPriceValue(typeDetails) {
    const totalPrice = [];
    typeDetails.map((price) => {
      if (price.price && price.price > 0) {
        totalPrice.push(price.price);
      }
    });
    if (totalPrice.length > 0) {
      var minValue = Math.min(...totalPrice);
      var maxValue = Math.max(...totalPrice);
      return '$' + numberFormatter.format(minValue) + '+';
      /* return (
        <>
          {currencyFormatter.format(minValue)}-
          {currencyFormatter.format(maxValue)}
        </>
      ); */
    } else {
      return '-';
    }
  }

  const setIds = (ids) => {
    props.changeCheckedProperty(ids);
  };

  return (
    <>
      <Row className="fixed-position-box">
        <Col md='6'>
          {allProperties.length > 0 ? (
            <>
              <Row className='py-3'>
                {allProperties.map((property, i) => (
                  <Col
                    md='6'
                    key={property.node.id}
                    onMouseMove={() => setHoverPropertyId(property.node.id)}
                    //onMouseEnter={() => hoverProperty(property.node.id)}
                    onMouseOut={() => setHoverPropertyId(null)}
                    style={
                      props.selectedPropertyArray.find(
                        (p) => p.node.id === property.node.id
                      ) && { backgroundColor: 'white' }
                    }
                    onClick={() => {
                      props.setSidebarState(true);
                      props.setProperty(property.node);
                      history.push(`/${props.path}/${property.node.city.slug}/${property.node.neighborhood.slug}/${property.node.slug}`);
                    }}
                  >
                    <MapPropertiesList
                      property={property.node}
                      selectedBedSize={propertyTypes(property.node.floorPlans)}
                      heart={
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            props.toggleSelection(property.node);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {props.selectedPropertyArray.find(
                            (p) => p.node.id === property.node.id
                          ) ? (
                            <Icon icon='heart' />
                          ) : (
                            <Icon icon={['far', 'heart']} />
                          )}
                        </div>
                      }
                      imageOnly={true}
                    />
                  </Col>
                ))}
              </Row>
              <Row className='my-3'>
                <Col md='12' className='mx-3'>
                  <PropertyPagination
                    paginationInfo={props.paginationInfo}
                    setPaginationInfo={props.setPaginationInfo}
                    setShouldSearch={props.setShouldSearch}
                  />
                </Col>
              </Row>
            </>
          ) : (
            <div
              className='d-flex justify-content-center'
              style={{ marginTop: '50%' }}
            >
              <h4>We found no matches. Please try adjusting your filters.</h4>
            </div>
          )}
        </Col>
        <Col md='6'>
          <div className='property-report-map'>
            <Gmap
              // googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyD-Vwu6LSsJscOPVKsSDvVse9NFlA3U7d4'
              googleMapURL='https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC1BgEh0QjPcEiFz8GDw_L87UYf__IYXTM'
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100vh` }} />}
              propertyIds={setIds}
              getFilter={props.getFilter}
              setFilter={props.setFilter}
              setShouldMapSearch={props.setShouldMapSearch}
              allPropertylatlongs={props.mapMarkers}
              setCurrentBounds={setCurrentBounds}
              currentBounds={currentBounds}
              hoverPropertyId={hoverPropertyId}
              setSidebarState={props.setSidebarState}
              setProperty={props.setProperty}
              path={props.path}
              activeTab={props.activeTab}
              toggleSelection={props.toggleSelection}
              selectedPropertyArray={props.selectedPropertyArray}
              setMapLoaded={props.setMapLoaded}
              mapLoaded={props.mapLoaded}
              properties={props.properties}
              setSelectedPropertiesFromMap={props.setSelectedPropertiesFromMap}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
