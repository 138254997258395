import React, { useEffect, useState } from 'react';
import { UNIT_ADDONS } from '@api';
import { Row, Col, Label } from 'reactstrap';
import { useQuery } from '@apollo/client';
import Nouislider from 'nouislider-react';

export default function UnitFeatures({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  // const unitFeatures = useQuery(UNIT_ADDONS);
  // const { data: unitFeaturesData } = unitFeatures;
  const numberFormatter = new Intl.NumberFormat('en-US');
  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'unitFeatures' && 'hidden'
      }`}
    >
      <div className='filter-box'>
        <Row>
          <Col md='12' className='mb-4'>
            <h6 className='mr-1' style={{ display: 'inline-block' }}>
              Square Feet -
            </h6>
            {getFilter('sqFt') === '' ? (
              <>Any</>
            ) : (
              <>
                Larger than {numberFormatter.format(getFilter('sqFt'))} sqft
                {/* <sup>2</sup> */}
              </>
            )}
            <div className='px-2 py-2 mt-2 w-100'>
              <Nouislider
                connect='lower'
                tooltips={true}
                // animate={true}
                start={[getFilter('sqFt')]}
                behaviour='tap'
                range={{
                  min: [0, 100],
                  '5%': [100, 100],
                  '15%': [400, 50],
                  '75%': [1300, 100],
                  '90%': [2000, 500],
                  max: [5000],
                }}
                /* pips={{
                  mode: 'values',
                  values: [200, 500, 700, 1000, 1500, 5000],
                  density: 10,
                }} */
                onChange={(e) =>
                  addFilter({
                    e: {
                      target: { name: 'sqFt', value: e[0] < 100 ? '' : e[0] },
                    },
                  })
                }
                format={{
                  to: function (value) {
                    return value === undefined ? '' : value.toFixed(0);
                  },
                  from: Number,

                  // // 'to' the formatted value. Receives a number.
                  // to: function (value) {
                  //   return Math.round(value);
                  // },
                  // // 'from' the formatted value.
                  // // Receives a string, should return a number.
                  // from: function (value) {
                  //   // return Number(value.replace(',-', ''));
                  //   return Math.round(value);
                  // }
                }}
              />
            </div>
          </Col>
          {/* <Col md='12'>
            <h6>Unit Features</h6>
          </Col>
          {unitFeaturesData?.unitAddons?.map((addon, index) => (
            <Col md='6' key={index}>
              <div key={addon.name}>
                <Label>
                  <input
                    type='checkbox'
                    className='custom-checkbox d-inline-block w-auto mr-2'
                    value={addon.name}
                    onChange={(e) => {
                      if (e.target.checked) {
                        addFilter({
                          e: [
                            ...getFilter('unitFeatures'),
                            { label: addon.name },
                          ],
                          key: 'unitFeatures',
                        });
                      } else {
                        addFilter({
                          e: getFilter('unitFeatures').filter(
                            (a) => a.label !== addon.name
                          ),
                          key: 'unitFeatures',
                        });
                      }
                    }}
                    checked={
                      getFilter('unitFeatures').find(
                        (a) => a.label === addon.name
                      ) || false
                    }
                    name={addon.name}
                  />
                  {addon.name}
                </Label>
              </div>
            </Col>
          ))} */}
        </Row>
      </div>
    </div>
  );
}
