import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import ReportMap from './ReportMap';
import Spinner from '../../components/Spinner';
import { GET_REPORT } from '@api';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import Select from 'react-select';
import PropertyGridBox from './PropertyGridBox';
import SidebarRight from '../../components/SidebarRight';
import PropertyReportContent from './PropertyReportContent';
import { Icon } from '@assets';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Label,
  TabPane,
  FormGroup,
} from 'reactstrap';
import AgentDetailsPage from './AgentDetailsPage';

export default function PropertyReportDetail(props) {
  const [clientName, setClientName] = useState('');
  const [sidebarWidth, setSidebarWidth] = useState('950px');
  const [message, setMessage] = useState('');
  const [latLong, setLatLong] = useState([]);
  const [properties, setProperties] = useState([]);
  const [selectedBedSize, setSelectedBedSize] = useState({
    value: 'All',
    label: 'All',
  });
  const [selectedSort, setSelectedSort] = useState({
    value: 'none',
    label: 'None',
  });
  const [isError, setIsError] = useState(false);
  const [agentEmail, setAgentEmail] = useState('');
  const [agent, setAgent] = useState();
	const [report, setReport] = useState(null);
  const [agentPopup, setAgentPopup] = useState(false);
  const [activeTab, setActiveTab] = useState('list');
  const [activeMainTab, setActiveMainTab] = useState('report');
  const [propertyToShow, setPropertyToShow] = useState(null);
  const [propertyArray, setPropertyArray] = useState([]);
	const [agentSelectedDetails, setAgentSelectedDetails] = useState([]);
	// const [dislikeItems, setDislikeItems] = useState(null);
  const sortingOptions = [
    { value: 'none', label: 'None' },
    { value: 'yearBuiltLow', label: 'Year Built (Low)' },
    { value: 'yearBuiltHigh', label: 'Year Built (High)' },
    { value: 'rentLow', label: 'Rent (Low)' },
    { value: 'rentHigh', label: 'Rent (High)' },
    { value: 'sizeLow', label: 'Size (Low)' },
    { value: 'sizeHigh', label: 'Size (High)' },
    { value: 'rating', label: 'Rating' },
  ];

  let code_obj = queryString.parse(props.location.search);

  const {
    data: reportData,
    loading: reportLoading,
  } = useQuery(GET_REPORT, {
    variables: { code: code_obj.code },
    onCompleted: (data) => {
      if (data.errors) {
        setIsError(true);
      } else {
        setAgent(data.report.user);
				setReport(data.report);
				if (data.report.isShow){
					if (localStorage.getItem(code_obj.code)) {
						checkReminderPopup(code_obj.code);
					} else {
						setReminderPopup(code_obj.code);
					}
				}

      }
			let agentSelectedDetails = data.report.reportProperties.filter(e => e.isSelectedByAgent === true);
			setAgentSelectedDetails(agentSelectedDetails);
    },
    onError: (e) => {
      setIsError(true);
      console.log(e);
    },
  });

  function setReminderPopup(code) {
    if (code) {
      var dt = new Date();
      dt.setHours(dt.getHours() + 48);
      localStorage.setItem(code, dt);
      setAgentPopup(true);
    }
  }

  function checkReminderPopup(code) {
    if (new Date(localStorage.getItem(code)) < new Date()) {
      setAgentPopup(true);
      localStorage.removeItem(code);
      var dt = new Date();
      dt.setHours(dt.getHours() + 48);
      localStorage.setItem(code, new Date());
    }
  }

	function filteredFloorPlans(property){
		if (!property.floorPlans) return [];
		let selectedFloorPlans = agentSelectedDetails.map(e => e.floorPlanId)
		let selectedProperty = agentSelectedDetails.filter(e => e.floorPlanId === null && e.propertyId !== null).map(e => e.propertyId)

		if (selectedProperty.includes(parseInt(property.id))){
			return property.floorPlans
		}

		var newFloorPlans = property.floorPlans.filter(fp =>
			selectedFloorPlans.includes(parseInt(fp.id))
		)
		return newFloorPlans
	}

  useEffect(() => {
    if (typeof reportData !== 'undefined') {
      setClientName(reportData.report?.clientName ? reportData.report?.clientName : '');
      setMessage(reportData.report.message);
      setAgentEmail(reportData.report.agentEmail);
      setProperties(reportData.report.properties);
      let latlongsarray = [];
      reportData.report.properties.forEach((element) => {
        if (element.lat && element.long) {
          latlongsarray.push({
            lat: element.lat,
            long: element.long,
            id: parseInt(element.id),
            property: element,
          });
        }
      });

      setLatLong(latlongsarray);
      let propertyListArray = [];
      reportData.report.properties.map(function (property, index) {
					propertyListArray.push({
						...property,
						floorPlans: property.floorPlans
						});
					}
			);
      setPropertyArray(propertyListArray);
      // Preload all bigger images for property overlay
      reportData.report.properties.forEach((reportProperty) => {
        reportProperty.imageUrls.forEach(
          (imageUrl) => (new Image().src = imageUrl)
        );
      });
    }
  }, [reportData]);

  const onReminderPopup = () => {
    setAgentPopup(!agentPopup);
  };

  const showPropertyDetail = (property) => {
    setPropertyToShow((p) => ({
			...property,
			floorPlans: filteredFloorPlans(property)
		}));
    setSidebarState(!sidebarState);
  };

  function getTypeDetailsValue(propertiesArray, valueFor) {
    var finalProperty = propertiesArray.map((property, i) => {
      const totalPrice = [];
      if (property.floorPlans.length > 0) {
        var o = Object.assign({}, property);
        property.floorPlans.map((floor) => {
          floor.units?.map((unit) => {
            if (unit.isAvailable === true) {
              if (valueFor === 'price') {
                if (unit.price && unit.price > 0) {
                  totalPrice.push(unit.price);
                }
              } else {
                if (unit.size && unit.size > 0) {
                  totalPrice.push(unit.size);
                }
              }
            }
          });
        });
        if (totalPrice.length > 0) {
          o.minValue = Math.min(...totalPrice);
          o.maxValue = Math.max(...totalPrice);
          return o;
        } else {
          o.minValue = 0;
          o.maxValue = 0;
          return o;
        }
      }
    });
    return finalProperty;
  }

  function RenderPropertiesToShow() {
    let filterArray = [];

    if (selectedSort.value !== 'None') {
      if (
        selectedSort.value == 'yearBuiltLow' ||
        selectedSort.value == 'yearBuiltHigh'
      ) {
        propertyArray.sort(function (a, b) {
          if (selectedSort.value == 'yearBuiltLow')
            return a.builtYear - b.builtYear;
          else return b.builtYear - a.builtYear;
        });
        filterArray = propertyArray;
      } else if (
        selectedSort.value == 'rentLow' ||
        selectedSort.value == 'rentHigh'
      ) {
        var priceProperty = getTypeDetailsValue(propertyArray, 'price');
        priceProperty.sort(function (a, b) {
          if (selectedSort.value == 'rentLow') {
            return a.minValue - b.minValue;
          } else {
            return b.maxValue - a.maxValue;
          }
        });
        filterArray = priceProperty;
      } else if (
        selectedSort.value == 'sizeLow' ||
        selectedSort.value == 'sizeHigh'
      ) {
        var priceProperty = getTypeDetailsValue(propertyArray, 'size');
        priceProperty.sort(function (a, b) {
          if (selectedSort.value == 'sizeLow') return a.minValue - b.minValue;
          else return b.maxValue - a.maxValue;
        });
        filterArray = priceProperty;
      } else {
        propertyArray.sort(function (a, b) {
          return parseFloat(b.googleRating) - parseFloat(a.googleRating);
        });
        filterArray = propertyArray;
      }
    } else {
      filterArray = propertyArray;
    }
    const propertiesToShow = filterArray.map((property) => {
      return (
        <PropertyGridBox
          reportId={reportData?.report?.id}
          reportCode={reportData?.report?.reportCode}
          hidePropertyName={reportData?.report?.hidePropertyName}
          property={property}
          rating={
            (reportData?.report?.reportProperties).find(
              (rp) =>
                rp.propertyId === Number(property.id) &&
                rp.floorPlanId === null &&
                rp.typeDetailId === null
            )?.clientRating
          }
          notes={
            (reportData?.report?.reportProperties).find(
              (rp) =>
                Number(rp.propertyId) === Number(property.id) &&
                rp.floorPlanId === null &&
                rp.typeDetailId === null
            )?.clientNotes
          }
          selectedBedSize={selectedBedSize.label}
          handleClick={() => {
            showPropertyDetail(property);
          }}
          key={Number(property.id)}
        />
      );
    });
    return filterArray.length === 0 ? (
      <div className='text-center my-65 w-100'>
        <h5>
          No properties having&nbsp;<strong>{selectedBedSize.label}</strong>
          &nbsp;units.
        </h5>
      </div>
    ) : (
      propertiesToShow
    );
  }

  let { id } = useParams();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [sidebarState, setSidebarState] = useState(id ? true : false);
  const [showInSidebar, setShowInSidebar] = useState('');

  useEffect(() => {
    //console.log(props.path);
    if (sidebarState === true) {
      if (!id) {
        setSidebarWidth('1250px');
        // setShowInSidebar(showSendList);
        // history.push(`/` + props.path);
      } else {
        setSidebarWidth('1250px');
        if (property != null) {
          setShowInSidebar(showProperty);
        } else {
          setSidebarState(false);
          // history.push(`/` + props.path);
        }
      }
    } else {
      setShowInSidebar('');
      setSidebarWidth('1250px');
      // history.push(`/` + props.path);
    }
  }, [sidebarState]);

  return (
    <>
      {isError ? (
        <h4 className='text-center pt-5'>Recommendations is not available</h4>
      ) : (
        <>
          {reportLoading && <Spinner />}
          <SidebarRight
            sidebarHeight='100%'
            sidebarState={sidebarState}
            sidebarWidth={sidebarWidth}
            sidebarToggle={() => {
              setPropertyToShow(null);
              setSidebarState(!sidebarState);
            }}
            sidebarContent={
              propertyToShow && (
                <PropertyReportContent
                  property={propertyToShow}
                  selectedBedSize={selectedBedSize}
                  setSelectedBedSize={(label) => setSelectedBedSize(label)}
                  clientName={clientName}
                  agentEmail={agentEmail}
                  selectedSort={selectedSort}
                  sidebarState={sidebarState}
                  reportId={reportData?.report?.id}
                  reportCode={reportData?.report?.reportCode}
                  hidePropertyName={reportData?.report?.hidePropertyName}
                  reportProperties={(reportData?.report?.reportProperties).filter(
                    (rp) => Number(rp.propertyId) === Number(propertyToShow.id)
                  )}
									// selectedFloorPlans={selectedFloorPlans}
									// selectedFpUnits={selectedFpUnits}
									agentSelectedDetails={agentSelectedDetails}
                />
              )
            }
            sidebarButton={false}
          />
          <Modal isOpen={agentPopup} toggle={onReminderPopup}>
            <ModalBody>
              {/* Please remember to put <strong>{agentName}</strong> on the
              application when you apply! */}
							<h>
								Hi {clientName},<br/><br/>

								I'm excited to share a hand-picked list of properties that align with your preferences!<br/><br/>

								Rest assured, you don't need to worry about contacting the buildings directly - I'll be your dedicated point of contact for everything.<br/><br/>

                This is how I earn my commission from the building, so please reach out to me for any questions, scheduling tours, applying for an apartment, or to discuss your preferences further.<br/><br/>

								I look forward to working together to find your dream home. 🏡<br/><br/>
								{agent?.name}<br/>
								{agent?.brokerageName}
							</h>
            </ModalBody>
            <ModalFooter>
              <Button color='success' onClick={onReminderPopup}>
                Ok
              </Button>
            </ModalFooter>
          </Modal>
          <Container fluid className='p-3 p-lg-4 mb-4'>
						<Col md='12' className='table-tabs lg mt-3 mt-md-3 d-flex justify-content-center'>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeMainTab === 'report',
										})}
										onClick={() => setActiveMainTab("report")}
									>
										<Icon icon='building' />{' '}
										<span className='d-none d-md-inline-block ml-2'>
											Properties
										</span>
									</NavLink>
								</NavItem>

								<NavItem>
									<NavLink
										className={classnames({
											active: activeMainTab === 'agentProfile',
										})}
										onClick={() => setActiveMainTab("agentProfile")}
									>
										<Icon icon='user' />{' '}
										<span className='d-none d-md-inline-block ml-2'>
											Agent Profile
										</span>
									</NavLink>
								</NavItem>
							</Nav>
						</Col>
						<TabContent activeTab={activeMainTab} className='w-100'>
							<TabPane tabId='report'>
							{ report?.isShow ?
								<>
									<Row className='mt-4'>
										<Col md='9' className='property-report-header'>
											<h3>
												<strong>Hello, {clientName}</strong>
											</h3>
											<h5>
												<strong>Your Agent is: {agent?.name}</strong>
											</h5>
											<p>{message}</p>
										</Col>
										<Col
											md='3'
											className='property-report-header text-right d-none d-md-block'
										>

										</Col>
									</Row>
									<Row>
										<Col md='12' className='table-tabs lg mt-3 mt-md-3'>
											<Row>
												<Col md='6' className='mb-0'>
													<Nav tabs>
														<NavItem>
															<NavLink
																className={classnames({
																	active: activeTab === 'list',
																})}
																onClick={() => {
																	toggle('list');
																}}
															>
																<Icon icon='list' />{' '}
																<span className='d-none d-md-inline-block ml-2'>
																	List
																</span>
															</NavLink>
														</NavItem>

														<NavItem>
															<NavLink
																className={classnames({
																	active: activeTab === 'map',
																})}
																onClick={() => {
																	toggle('map');
																}}
															>
																<Icon icon='map-marked-alt' />{' '}
																<span className='d-none d-md-inline-block ml-2'>
																	Map
																</span>
															</NavLink>
														</NavItem>
													</Nav>
												</Col>
												<Col md='6'>
													<div className='d-flex flex-row-reverse'>
														<div className='mt-2'>
															<FormGroup row className='m-0'>
																<Label className='text-right mr-0' md={3}>
																	Sort
																</Label>
																<Col md={9} className='m-0'>
																	<Select
																		styles={{
																			container: () => ({ minWidth: '200px' }),
																		}}
																		//width='270px'
																		name='selectBedSize'
																		value={selectedSort}
																		options={sortingOptions.map((sort) =>
																			JSON.parse(
																				`{"value":"${sort.value}", "label":"${sort.label}"}`
																			)
																		)}
																		isSearchable={false}
																		onChange={(e) => setSelectedSort(e)}
																		className='basic-multi-select bedsize text-left relative-select rounded select-lg'
																		classNamePrefix='default-select'
																		placeholder='Select Bed Size'
																	/>
																</Col>
															</FormGroup>
														</div>
													</div>
												</Col>
											</Row>
											<TabContent activeTab={activeTab} className='w-100'>
												<TabPane tabId='list'>
													<Row>
														<Col md='12'>
															<Card body>
																{properties.length > 0 ? (
																	<Row>
																		<RenderPropertiesToShow />
																	</Row>
																) : (
																	<Row>
																		<Col md={{ size: '6', offset: '3' }}>
																		</Col>
																	</Row>
																)}
															</Card>
														</Col>
													</Row>
												</TabPane>

												<TabPane tabId='map'>
													<Row>
														<Col sm='12'>
															<Card body className='p-0 overflow-hidden'>
																<div
																	className='property-report-map'
																	style={{ height: '80vh' }}
																>
																	{latLong.length > 0 ? (
																		<ReportMap
																			latlongs={latLong}
																			is_show={true}
																			height='80vh'
																			showPropertyDetail={showPropertyDetail}
																			path={props.path}
																			reportData={reportData}
																			activeTab={activeTab}
																		/>
																	) : (
																		<div
																			className='text-center'
																			style={{ margin: '35vh 0' }}
																		>
																			<h5>Map location unavailable</h5>
																		</div>
																	)}
																</div>
															</Card>
														</Col>
													</Row>
												</TabPane>
											</TabContent> 
										</Col>
									</Row>
								</> : 
								<div style={{ height: '60vh' }} className='mt-5'>
									<h5>Hello {clientName},<br/><br/>
										Please contact your agent to reactivate your list of recommendations. Thank you!</h5>
								</div>	
							}
							</TabPane>
							<TabPane tabId='agentProfile'>
								<AgentDetailsPage agent={agent} />
							</TabPane>
						</TabContent>
          </Container>
					{activeMainTab === "report" && <div className='container-fluid mt-4 mb-4 text-center'>
						<p className='fw-lighter fst-italic'><i>The list of properties and all information on this page, including but not limited to pricing, availability, fees, amenities, and photos, is subject to change and errors. For accurate and up-to-date details, it is advised to consult with your real estate agent and address any questions you may have.</i></p>
					</div>}
        </>
      )}
    </>
  );
}
