import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import ReportMap from './ReportMap';
import { Icon } from '@assets';

export default function ReportDetail(props) {
  const history = useHistory();
  const {
    id,
    name,
    address,
    imageSmallUrls,
    typeDetails,
    photoGalleryLink,
    bedroomNo,
    neighborhood,
    detailShow,
    floorPlanLink,
    lat,
    long,
  } = props.location.details;
  const [map_view, setMapView] = useState(false);
  return (
    <>
      <Container className='property-report-detail'>
        <Row>
          <Col md='12' className='px-0'>
            <div className='property-report-detail-image'>
              <img
                src={
                  imageSmallUrls
                    ? imageSmallUrls.length === 0
                      ? 'https://cdn.archilovers.com/projects/57d7daff-e586-4a1b-83b3-8d5bf0f8e070.jpg'
                      : imageSmallUrls[0]
                    : 'https://cdn.archilovers.com/projects/57d7daff-e586-4a1b-83b3-8d5bf0f8e070.jpg'
                }
                alt={name}
              />
              <div className='property-report-banner-description'>
                <Row className='h-100'>
                  <Col md='12'>
                    <Row>
                      <Col xs='6'>
                        <Button
                          onClick={() => history.goBack()}
                          color='link'
                          className='back-btn'
                        >
                          <Icon icon='chevron-left' />
                        </Button>
                      </Col>
                      <Col xs='6' className='text-right'>
                        {/* <Button color="link" className="bookmark-btn"><Icon icon="bookmark" /></Button> */}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    md='12'
                    className='d-flex align-items-end justify-content-center'
                  >
                    <Row className='w-100 bg-semi-transparent py-3'>
                      <Col md='12'>
                        <Row>
                          <Col xs='10'>
                            <h2>{name}</h2>
                            {typeDetails.map(
                              (
                                {
                                  propertyTypeName,
                                  size,
                                  floorPlan,
                                  price,
                                  available,
                                },
                                index
                              ) => {
                                if (propertyTypeName.includes(bedroomNo)) {
                                  return (
                                    <p>
                                      ${price}{' '}
                                      <small>Available {available}</small>,{' '}
                                      <small>{size} sq.ft.</small>{' '}
                                    </p>
                                  );
                                }
                              }
                            )}
                            <p>
                              <small>{address}</small>
                            </p>
                          </Col>
                          <Col xs='2'>
                            <div className='map-icon'>
                              <a onClick={() => setMapView(!map_view)}>
                                <Icon
                                  icon={
                                    map_view ? 'arrow-left' : 'location-arrow'
                                  }
                                />
                              </a>
                            </div>
                          </Col>
                        </Row>
                        <hr className='border-light' />
                        <ul className='list-unstyled mb-0'>
                          <li>
                            <Icon icon='bed' /> {bedroomNo} Bed
                          </li>
                          <li>
                            <Icon icon='bath' /> {bedroomNo} Bath
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {map_view ? (
        <ReportMap
          property_ids={[id]}
          latlongs={[{ id: id, lat: lat, long: long }]}
        />
      ) : (
        <Container>
          <Row>
            <Col md='12' className='text-right mb-4 py-3'>
              <a
                target={photoGalleryLink === '' ? '' : '_blank'}
                href={
                  photoGalleryLink === ''
                    ? 'javascript:void(0)'
                    : photoGalleryLink
                }
              >
                Click here for Gallery <Icon icon='arrow-right' />
              </a>
            </Col>

            <Col md='12' className='mb-4 text-center'>
              <a
                target={floorPlanLink === '' ? '' : '_blank'}
                href={
                  floorPlanLink === '' ? 'javascript:void(0)' : floorPlanLink
                }
              >
                <Button className='jumbo-btn green'>Floor Plans</Button>
              </a>
            </Col>
            <Col md='12' className='mb-4 text-center'>
              <Button
                onClick={() => history.goBack()}
                className='jumbo-btn red'
              >
                Go Back
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
