import React from 'react';
import { Link } from 'react-router-dom';

import {
  CardGroup,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
} from 'reactstrap';

export default function PricingPlanCard(props) {
  return (
    <>
      <Card className={'pricing-card ' + props.color}>
        <CardBody>
          <CardTitle tag='h3' className={`${props.highlight}`}>
            {props.planName}
          </CardTitle>

          <CardSubtitle
            tag='h5'
            className='d-flex align-items-center'
            style={{ minHeight: '115px' }}
          >
            <div className='d-block text-center w-100'>
              {props.original_amount > 0 &&
                props.amount &&
                props.amount < props.original_amount && (
                  <div>
                    <h6 className='mt-0'>
                      $
                      <s>
                        {props.original_amount}
                        {/* {props.interval === 'year'
                          ? props.original_amount / 12
                          : props.original_amount} */}
                      </s>
                    </h6>
                  </div>
                )}
              <small>$</small>
              {props.amount}
              <small>/{props.interval === 'year' ? 'year' : 'month'}</small>
              {/* {props.interval === 'year' ? props.amount / 12 : props.amount}
              <small>/month</small> */}

              {/* {props.interval === 'year' && (
                <p className='mb-0'>${props.amount} Billed Annually</p>
              )} */}
              {props.interval === 'year' && (
                <p className='mb-0'>Save ${120} annually</p>
              )}
            </div>
          </CardSubtitle>
          <CardText>{props.cityAccess} Market Access</CardText>
          {!props.hideTrial && <CardText>3 days trial</CardText>}
          {!props.hideButton && (
            <CardText className='py-0 pb-4 px-4'>
              <Link
                to={`/signup?plan=${props.planName}&interval=${props.interval}`}
              >
                <Button color={props.color} className='btn button-dashboard'>
                  Try for Free
                </Button>
              </Link>
            </CardText>
          )}
        </CardBody>
      </Card>
    </>
  );
}
