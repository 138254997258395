import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Spinner from '../../Spinner';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import {
  TOUR_REQUEST_PDF,
  TOUR_REQUEST_RESEND,
  TOUR_REQUEST_HISTORY,
  TOUR_REQUEST_HISTORY_MULTI_DELETE,
	UPDATE_TOUR_REQUEST
} from '@api';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import RTable from '../../RTable';
import GuestCard from './GuestCard';
import SidebarRight from '../../SidebarRight';
import Select from 'react-select';
import { Icon } from '@assets';
const appoinmentTypes = {
  inperson: 'In-Person with Broker',
  without_broker: 'Send without Broker',
  virtual: 'Virtual Tour',
  self_guided: 'Self-Guided',
};

const statusTypes = {
  sent_not_confirmed: 'Sent - Not Confirmed',
  resent_not_confirmed: 'Resent - Not Confirmed',
  confirmed: 'Confirmed'
};

const guestCardStatuses = [
  { value: 'sent_not_confirmed', label: 'Sent - Not Confirmed' },
  { value: 'resent_not_confirmed', label: 'Resent - Not Confirmed' },
  { value: 'confirmed', label: 'Confirmed' },
];

export default function GuestHistory(props) {
  const [guestCards, setGuestCards] = useState([]);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [resendMailId, setResendMailId] = useState(null);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [resendModal, setResendModal] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [guestCardList, setGuestCardList] = useState('loading');
	const [editGuestCard, setEditGuestCard] = useState(null);
  const {
    data: guestCardsData,
    loading: guestLoading,
    error: guestError,
    refetch: guestRefetch,
  } = useQuery(TOUR_REQUEST_HISTORY, {
    onCompleted: (data) => {},
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  useEffect(() => {
    if (refetch === true) {
      guestRefetch();
      setRefetch(false);
    }
  }, [refetch]);

  const [
    guestCardPDF,
    {
      data: guestCardPDFData,
      loading: guestCardPDFLoading,
      error: guestCardPDFError,
    },
  ] = useMutation(TOUR_REQUEST_PDF, {
    onCompleted: (data) => {
      data.tourRequestPdf.guestPdfUrl &&
        window.open(data.tourRequestPdf.guestPdfUrl, '_blank')?.focus();
      toast(
        () => (
          <a href={data.tourRequestPdf.guestPdfUrl} target='_blank' download>
            Click here to download PDF
          </a>
        ),
        { type: 'success' }
      );
    },
    onError: (e) => {
      console.log(e);
      toast(e, { type: 'error' });
    },
  });

	const [updateTourRequest] = useMutation(UPDATE_TOUR_REQUEST, {
		onCompleted: (data) => {
			if (
				data.updateTourRequest.errors &&
				data.updateTourRequest.errors.length > 0
			) {
				data.updateTourRequest.errors.map((err) =>
					toast(err, { type: 'error' })
				);
			} else {
				toast(data.updateTourRequest.message, { type: 'success' });
			}
		},
		onError: (e) => {
			console.log(e, 'updated client ON ERROR');
		},
	})

	const updateStatus = (guestCard, value) => {
		updateTourRequest({
			variables: {
				...guestCard,
				id: Number(guestCard.id),
				status: value,
				emailGuestCard: false
			}
		})
	}

  const [guestResendMail, { data: guestResendData }] = useMutation(
    TOUR_REQUEST_RESEND,
    {
      onCompleted: (data) => {
        if (data.tourRequestResend.errors === null) {
          setResendModal(!resendModal);
          toast(data.tourRequestResend.message, { type: 'success' });
          setIsProgress(false);
					setRefetch(true);
        } else {
          toast(data.tourRequestResend.message, { type: 'error' });
        }
      },
      onError: (e) => {
        console.log(e);
        toast(e, { type: 'error' });
      }
    }
  );

  const addNewguestHistory = () => {
    setSidebarState(true);
  };

  const downloadGuestCardPDF = (id = null) => {
    guestCardPDF({ variables: { id: parseInt(id) } });
  };

  const resendMail = (id = null) => {
    setResendMailId(parseInt(id));
    setResendModal(true);
  };

  const handleResendMail = async () => {
    setIsProgress(true);
    await guestResendMail({ variables: { id: resendMailId } });
  };

  const onResendPopup = () => {
    if (resendMailId) {
      setResendModal(!resendModal);
    }
  };

  const [guestMultiDelete] = useMutation(TOUR_REQUEST_HISTORY_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.deleteTourRequests.message, { type: 'success' });
      setIsProgress(false);
    },
    refetchQueries: [TOUR_REQUEST_HISTORY],
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  const handleDeleteAll = async () => {
    setIsProgress(true);
    await guestMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

	function handleEdit(id){
		setEditGuestCard(id);
	}

  useEffect(() => {
    if (guestCardsData) {
      if (guestCardsData.tourRequests.length === 0) {
        setGuestCardList('noData');
      } else {
        setGuestCards(guestCardsData.tourRequests.edges);
        setGuestCardList('data');
      }
    }
  }, [guestCardsData]);

	const currencyFormatter = new Intl.NumberFormat('en-US', {
    /* style: 'currency', */
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: 'Client',
        accessor: 'node.client.name',
        Cell: ({ value, row }) => (
          <div>
            <Avatar
              name={value ? value : '-'}
              className='mr-2 text-white'
              round={true}
              size='30'
            />{' '}
            {value ? value : '-'}
          </div>
        ),
      },
			{
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    downloadGuestCardPDF(value);
                    // setSidebarState(true);
                    // history.push(`/clients/edit/${value}`);
                  }}
                >
                  Download PDF
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => resendMail(value)}>
                  Resend Email
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleEdit(value)}>
                  Edit Guest Card
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleSingleDelete(value)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
      {
        Header: 'Email',
        accessor: 'node.client.email',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Phone',
        accessor: 'node.client.phone',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Community',
        accessor: 'node.property.name',
        Cell: ({ value, row }) => (
          <>
            {value ? (
              row.original.node.property.webLink ? (
                <a
                  href={row.original.node.property.webLink}
                  target='_blank'
                >
                  {value}
                </a>
              ) : (
                value
              )
            ) : (
              '-'
            )}
          </>
        ),
      },
      {
        Header: 'Budget',
        accessor: 'node.client.minRent',
        Cell: ({ value, row }) => ( 
          <div>
            {
              <div>
                {value && row.original.node.client.maxRent ? (
                  <div>
                    {value && '$' + currencyFormatter.format(value)} -{' '}
                    {'$' + currencyFormatter.format(row.original.node.client.maxRent)}{' '}
                  </div>
                ) : value ? (
                  <>{'$' + value + ' & Above'}</>
                ) : row.original.node.client.maxRent ? (
                  <>
                    {'Below ' +
                      '$' +
                      currencyFormatter.format(row.original.node.client.maxRent)}
                  </>
                ) : (
                  '-'
                )}
              </div>
            }
          </div>
        ),
      },
      {
        Header: 'M/I Date',
        accessor: 'node.client.availFrom',
				width: 150,
        Cell: ({ value, row }) => (
          <div>
            {value ? (
              <div>
								{
									value && !row.original.node.client.availTo ? `After ${moment(value).format('L')}` :
									!value && row.original.node.client.availTo ? `Before ${moment(row.original.node.client.availTo).format('L')}` :
									`${moment(value).format('L')} - ${moment(row.original.node.client.availTo).format('L')}`
								}
              </div>
            ) : (
              '-'
            )}
          </div>
        ),
      },
      {
        Header: 'Type',
        accessor: 'node.appointmentType',
        Cell: ({ value, row }) => (
          <div>{value ? appoinmentTypes[value] : '-'}</div>
        ),
      },
      {
        Header: 'Time',
        accessor: 'node.tourAtInLocalTime',
        Cell: ({ value }) => (
          <div>
						{value.split(" ")[0]}<br/>
						{value.split(" ").slice(1, 10).join(" ")}
					</div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'node.status',
				width: 250,
        Cell: ({ value, row}) => (
          // <div>{statusTypes[value]}</div>
					<>
						<Select
							name='status'
							isMulti={false}
							value={guestCardStatuses.find(s => s.value === value)}
							options={guestCardStatuses}
							onChange={(e) => updateStatus(row.original.node, e.value)}
							className='multi-select-client w-100'
							classNamePrefix='default-select'
							placeholder='Select Appointment Type'
						/>
					</>
        ),
      },
    ],
    []
  );
  const history = useHistory();
  const [sidebarState, setSidebarState] = useState(false);

  useEffect(() => {
    sidebarState
      ? history.push(`/guest-cards/send`)
      : history.push(`/guest-cards`);
  }, [sidebarState]);

  return (
    <>
      {guestLoading && <Spinner />}

      <SidebarRight
        sidebarState={sidebarState}
        sidebarToggle={() => {
          setSidebarState(!sidebarState);
        }}
        sidebarWidth='950px'
        sidebarContent={
          <GuestCard
            sidebarToggle={() => {
              setSidebarState(!sidebarState);
            }}
						sidebarState={sidebarState}
						editGuestCard={editGuestCard}
						setEditGuestCard={setEditGuestCard}
            shouldRefetch={() => setRefetch(true)}
            {...props}
          />
        }
      />
      <Modal isOpen={resendModal} toggle={onResendPopup}>
        <ModalHeader toggle={onResendPopup}>Confirm Resend</ModalHeader>
        <ModalBody>Are you sure you want to resend Guest Card?</ModalBody>
        <ModalFooter>
          <Button
            color='success'
            disabled={isProgress}
            onClick={handleResendMail}
          >
            Yes
          </Button>
          <Button color='primary' onClick={onResendPopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} guest histor
          {isCheckedId.length > 1 ? 'ies' : 'y'}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Row className='mt-md-4'>
        <Col md='12'>
          {guestCards.length > 0 ? (
            <RTable
              columns={columns}
              data={guestCards}
              filterable
              addons={
                <Button
                  color='red'
                  onClick={onAllDeletePopup}
                  disabled={isCheckedId.length > 0 ? false : true}
                  className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
                >
                  <Icon icon='trash-alt' className='mr-2' />
                  Delete
                </Button>
              }
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
							bottomSpace={true}
            />
          ) : (
            <>
              {guestCards.length === 0 ? (
                <div className='text-center' style={{ margin: '35vh 0' }}>
                  <h4>You haven't sent any guest cards so far.</h4>
                  <Button
                    onClick={addNewguestHistory}
                    color='success'
                    className='button-dashboard mt-2'
                  >
                    Send a guest card now{' '}
                    <Icon icon='chevron-right' className='ml-2' />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
