import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import Avatar from 'react-avatar';
import '../../assets/styles/Dashboard.css';
import AssistantProfile from './AssistantProfile';
import UpdatePassword from '../updatePassword';
import AssistantHome from './AssistantHome';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import DashboardResponsiveMenu from '../DashboardResponsiveMenu';
import { isLoggedIn } from '@sparklib';
import { Properties } from '@property';
import { LogoIcon, Icon } from '@assets';
import { useMutation } from '@apollo/client';
import { SIGN_OUT_AS } from '../../api/mutations';

export default function AssistantDashboard(props) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [display_sidebar, setSidebar] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const toggleSidebar = () => setSidebar(!display_sidebar);

  const [isResponsiveMenuOpen, setIsResponsiveMenuOpen] = useState(false);

  const [displayModal, setModal] = useState(false);

  const toggleModal = () => setModal(!displayModal);

	const [signoutAs] = useMutation(SIGN_OUT_AS);

  const isLogin = () => {
    if (
      localStorage.getItem('auth_token') === null ||
      localStorage.getItem('user_type') !== 'va'
    ) {
      history.push({
        pathname: '/login',
        state: { message: 'Please login to continue..' },
      });
      return;
    }
  };

  const backToAdmin = async () => {
		await signoutAs();
    const restoreLocalStorage = JSON.parse(
      localStorage.getItem('original_localStorage')
    );
    localStorage.clear();
    Object.keys(restoreLocalStorage).forEach((item) =>
      localStorage.setItem(item, restoreLocalStorage[item])
    );
    window.location.href = '/users';
  };

  useEffect(() => {
    if (isLoggedIn('va') !== true) {
      history.push({
        pathname: '/login',
        state: { message: 'Please login to continue..' },
      });
    }
    return () => {};
  }, []);

  const renderSwitch = () => {
    switch (props.path) {
      case 'profile':
        return <AssistantProfile />;
      case 'assistant-properties':
        return <Properties {...props} />
      default:
        return <AssistantHome />;
    }
  };

  return (
    <>
      <Container fluid className='admin-header fixed-top d-none d-lg-block'>
        <Row>
          <Col md='12'>
            <Navbar dark expand='lg'>
              <NavbarBrand
                to='/admin-dashboard'
                onClick={toggleSidebar}
                className='navbar-logo'
              >
                {/* <span className='brand-name'>SparkAPT</span> */}
                <span className='brand-name'>
                  <img
                    src={LogoIcon}
                    alt='Logo Icon'
                    className='img-fluid dashboard-logo'
                  />
                </span>
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />

              <Collapse isOpen={isOpen} navbar>
                <Nav navbar className='pl-lg-5'>
                  <NavItem className='py-2'>
                    <NavLink to='/assistant-dashboard'>
                      Manage Properties
                    </NavLink>
                  </NavItem>

                  <NavItem className='py-2'>
                    <NavLink to='/assistant-properties'>
                      Properties
                    </NavLink>
                  </NavItem>
                </Nav>

                <Nav
                  className='ml-auto d-flex navbar-nav flex-row justify-content-between'
                  navbar
                >
                  <UncontrolledDropdown nav inNavbar className='ml-md-3'>
                    <DropdownToggle nav caret className='text-white'>
                      <Avatar
                        name={localStorage.getItem('user_name')}
                        className='mr-2 text-dark'
                        round={true}
                        size='40'
                        color='white'
                      />
                      {localStorage.getItem('user_name')}
                    </DropdownToggle>

                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink to='/assistant-profile'>
                          <Icon icon='user-edit' className='mr-3 fa-w-20' />
                          My Profile
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='#' onClick={toggleModal}>
                          <Icon icon='unlock-alt' className='mr-3 fa-w-20' />
                          Change Password
                        </NavLink>
                      </DropdownItem>
                      {localStorage.getItem('original_localStorage') ? (
                        <DropdownItem>
                          <NavLink to='#' onClick={backToAdmin}>
                            <Icon icon='arrow-left' className='mr-3' />
                            Back To Admin
                          </NavLink>
                        </DropdownItem>
                      ) : (
                        ''
                      )}

                      <DropdownItem>
                        <NavLink to='/logout'>
                          <Icon icon='sign-out-alt' className='mr-3 fa-w-20' />
                          Logout
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>

      {/* Reponsive Navbar */}
      <DashboardResponsiveMenu
        toggleResponsiveModel={() => {
          setIsResponsiveMenuOpen(!isResponsiveMenuOpen);
        }}
        isMenu={isResponsiveMenuOpen}
        content={
          <>
            <Nav navbar className='v-center'>
              <NavItem className='py-2'>
                <NavLink to='/assistant-dashboard'>Manage Properties</NavLink>
              </NavItem>
            </Nav>

            <Nav navbar className='v-center'>
              <NavItem className='py-2'>
                <NavLink to='/assistant-properties'>Properties</NavLink>
              </NavItem>
            </Nav>

            <div className='user-profile-bottom'>
              <Row>
                <Col>
                  <NavLink to='/assistant-profile'>
                    <Icon icon='user' className='color-blue' />
                    <p>Profile</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='#' onClick={toggleModal}>
                    <Icon icon='unlock-alt' className='color-yellow' />
                    <p>Change Password</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='/logout'>
                    <Icon icon='sign-out-alt' className='color-red' />
                    <p>Logout</p>
                  </NavLink>
                </Col>
              </Row>
            </div>
          </>
        }
      />
      {/* Reponsive Navbar */}

      <Container className='property-data my-65 remove-mb' fluid>
        <UpdatePassword
          parentRef={toggleModal.bind(this)}
          displayModal={displayModal}
          token={localStorage.getItem('assistant_token')}
        />
        {renderSwitch()}
      </Container>
    </>
  );
}
