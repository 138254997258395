import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Collapse,
	Spinner,
	Alert
} from 'reactstrap';
import { unitTypes, ImageModal, pastDate } from '@sparklib';
import { REQUEST_TOUR, GET_REPORT_PROPERTY } from '@api';
import { useMutation, useQuery } from '@apollo/client';
import Select from 'react-select';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import { Icon } from '@assets';
import Slider from '../../components/Slider';
import SmallMap from './SmallMap';
import ClientRating from './ClientRating';
import TabList from './../Property/Properties/TabList';

export default function PropertyReportContent({
  selectedBedSize,
  setSelectedBedSize,
  reportProperties,
  reportId,
  reportCode,
  hidePropertyName,
  bath,
  agentEmail,
  clientName,
  property,
  selectedSort,
  // selectedFpUnits,
  // selectedFloorPlans,
	agentSelectedDetails
}) {
  toast.configure();
  const [isOpen, setIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [planName, setPlanName] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [currentIconId, setCurrentIconId] = useState(null);
  const requestTourBar = useRef(null);
  const [newHeight, setNewHeight] = useState({});
  const [collapsed, setCollapsed] = useState([]);
  const [propertyWithFloorPlans, setPropertyWithFloorPlans] = useState(null);

	const {data: reportProperty, loading: loadingRP} = useQuery(GET_REPORT_PROPERTY, {
		variables: { reportCode: reportCode, propertyId: parseInt(property.id)},
		onCompleted: (data) => {
			var finalProperty = finalFilteredProperty(data.findReportProperty)
			setPropertyWithFloorPlans(finalProperty)
		}
	})

	function finalFilteredProperty(property) {
		var selectedDetailsForThisProperty = agentSelectedDetails.filter(d => d.propertyId === Number(property.id));
		if (selectedDetailsForThisProperty.length === 1 && selectedDetailsForThisProperty[0]?.floorPlanId === null) return property; // means agent is selected a whole property
		else {
			var filteredFloorPlans = [];
			var selectedWholeFPIds = selectedDetailsForThisProperty.filter(d => d.typeDetailId === null).map(d => d.floorPlanId);
			var selectedUnitIds = selectedDetailsForThisProperty.filter(d => d.typeDetailId !== null).map(d => d.typeDetailId);
			property.floorPlans.forEach(floorPlan => {
				if (selectedWholeFPIds.includes(Number(floorPlan.id))){
					filteredFloorPlans.push(floorPlan);
				} else {
					var filteredUnits = []
					floorPlan.units.forEach(u => {
						if (selectedUnitIds.includes(Number(u.id))) filteredUnits.push(u);
					})
					if (filteredUnits.length > 0) filteredFloorPlans.push({...floorPlan, units: filteredUnits})
				}
			});
			return {...property, floorPlans: filteredFloorPlans}
		}
	}

  const populateCollapsed = (index, length) => {
    let boolArray = [];
    while (length < index) {
      boolArray.push(false);
      length++;
    }
    return boolArray;
  };

  const toggleMoreInfo = (index) => {
    setCollapsed((collapsed) =>
      index >= collapsed.length
        ? [...collapsed, ...populateCollapsed(index, collapsed.length), true]
        : collapsed.map((c, i) => (i === index ? !c : c))
    );
  };

  function getMinFloorPrice(minFloorPrice) {
    let filter_arr = _.filter(minFloorPrice, function (floor) {
      return floor.price > 0 && floor.isAvailable;
    });
    if (filter_arr.length > 0) {
      var allPrice = [];
      filter_arr.forEach((element) => {
        allPrice.push(element.price);
      });

      let minval = _.minBy(filter_arr, 'price');
      if (allPrice.every((val, i, arr) => val === arr[0])) {
        return '$' + currencyFormatter.format(minval.price);
      } else {
        return '$' + currencyFormatter.format(minval.price) + '+';
      }
    } else {
      return '-';
    }
  }

  function toggleImage(img, planName) {
    //stopPropagation();
    setIsOpen(!isOpen);
    setImgSrc(img);
    setPlanName(planName);
  }

  function groupFloorPlansArray(floorPlans, key) {
    return floorPlans.reduce(function (groupedFloorPlans, floorPlan) {
      (groupedFloorPlans[floorPlan['bed']] =
        groupedFloorPlans[floorPlan['bed']] || []).push(floorPlan);
      return groupedFloorPlans;
    }, {});
  }

  let groupedFloorPlans = property
    ? groupFloorPlansArray(property.floorPlans, 'bed')
    : [];

  useEffect(() => {
    groupedFloorPlans = property
      ? groupFloorPlansArray(property.floorPlans, 'bed')
      : [];
  }, [property]);

  function isAvailableCount(data) {
    let availableData = [];
    data.map(function (record) {
      if (record.isAvailable === true) {
        availableData.push(record);
      }
    });
    return availableData;
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    /* style: 'currency', */
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  useEffect(() => {
    setNewHeight({ marginBottom: requestTourBar.current?.offsetHeight });
  }, []);

  const [requestTour, { data, loading, error }] = useMutation(REQUEST_TOUR, {
    onCompleted: (data) => {
      if (
        data.reportRequestTour.errors &&
        data.reportRequestTour.errors.length > 0
      ) {
        data.reportRequestTour.errors.map((err) =>
          toast(err, { type: 'error' })
        );
      } else {
        toast(data.reportRequestTour.message, { type: 'success' });
      }
    },
    onError: (e) => {
      console.log(e);
      toast(e.message, { type: 'error' });
    },
  });

  const submitRequest = () => {
    requestTour({
      variables: {
        startDate,
        agentEmail,
        name,
        clientName,
      },
    });
  };

  function RenderUnitToShow(unit) {
    let unitArray = [];
    unitArray = unit;
    if (selectedSort.value == 'rentLow' || selectedSort.value == 'rentHigh') {
      unitArray = unitArray.slice().sort(function (a, b) {
        if (selectedSort.value == 'rentLow') {
          return a.price - b.price;
        } else {
          return b.price - a.price;
        }
      });
    } else if (
      selectedSort.value == 'sizeLow' ||
      selectedSort.value == 'sizeHigh'
    ) {
      unitArray = unitArray.slice().sort(function (a, b) {
        if (selectedSort.value == 'sizeLow') return a.size - b.size;
        else return b.size - a.size;
      });
    }
    return unitArray;
  }
  function getTypeDetailsValue(valueFor) {
    var minValue = 0;
    var maxValue = 0;

    let finalProperty = [];
    if (property.floorPlans.length > 0) {
      finalProperty = property.floorPlans.map((floor, index) => {
        const totalPrice = [];
        var o = Object.assign({}, floor);
        floor.units.map((unit) => {
          if (unit.isAvailable === true && unit.price > 0) {
            if (valueFor === 'price') {
              if (unit.price && unit.price > 0) {
                totalPrice.push(unit.price);
              }
            } else {
              if (unit.size && unit.size > 0) {
                totalPrice.push(unit.size);
              }
            }
          }
        });
        if (totalPrice.length > 0) {
          o.minValue = Math.min(...totalPrice);
          o.maxValue = Math.max(...totalPrice);
          return o;
        } else {
          o.minValue = 0;
          o.maxValue = 0;
          return o;
        }
      });
    }

    return finalProperty;
  }
  function RenderFloorPlanToShow(floorPlans) {
    let filterArray = [];
    var priceProperty = [];
    if (selectedSort.value == 'rentLow' || selectedSort.value == 'rentHigh') {
      priceProperty = getTypeDetailsValue('price');
      priceProperty.sort(function (a, b) {
        if (selectedSort.value == 'rentLow') {
          return a.minValue - b.minValue;
        } else {
          return b.maxValue - a.maxValue;
        }
      });
      filterArray = priceProperty;
    } else if (
      selectedSort.value == 'sizeLow' ||
      selectedSort.value == 'sizeHigh'
    ) {
      var priceProperty = getTypeDetailsValue('size');
      priceProperty.sort(function (a, b) {
        if (selectedSort.value == 'sizeLow') return a.minValue - b.minValue;
        else return b.maxValue - a.maxValue;
      });
      filterArray = priceProperty;
    } else {
      filterArray = floorPlans;
    }
    return filterArray;
  }

  const propertyImages = property.imageUrls?.map((image, index) => ({
    key: index,
    src: image,
    altText: name,
  }));

  function selectedIcon(propertyId, floorPlanId = null, unitId = null) {
    setCurrentIconId(propertyId + '' + floorPlanId);
  }

	function OrderFloorPlansToShow(floorPlans){
		var newFloorPlans = floorPlans;
		if (floorPlans?.length > 0){
			newFloorPlans = floorPlans.sort((a, b) => {
				if (a.units.filter(u => u.isAvailable).length - b.units.filter(u => u.isAvailable).length  > 0) return -1;
				else return 1;
			})
		}
		return newFloorPlans;
	}

	function validPropertySpecials(str){
		var invalidSpecials = ["no current specials" , "no specials"];
		var isValid = true;
		invalidSpecials.forEach((s) => {
			if (str.toLowerCase().includes(s)) {
				isValid = false
			}
		});
		return isValid;
	}

  return (
    <>
      <div className='edit-popup p-3 p-lg-4'>
        <Row>
          <Col lg='8' className='mb-3 mb-lg-0'>
            <div className='property-report-image'>
              <Slider items={propertyImages} className='rounded-medium' isLazyLoad={false}/>
              { hidePropertyName ?
                <>
                </> :
                <>
                  <div className='property-report-image-overlay'>
                    <div className='d-flex justify-content-between'>
                      <div>
                        <h5 className='mb-0 mb-sm-2 mb-md-0'>{property.name}</h5>
                      </div>
                      <div>
                        <p className='mb-md-0'>
                          <Icon icon='map-marker-alt' className='mr-2 text-white' />
                          {property.address}
                        </p>
                      </div>
                    </div>
                    {/* <Row className=' d-flex '>
                      <span className='d-flex align-items-start'>
                        <h5 className='mb-0 mb-sm-2 mb-md-0'>{property.name}</h5>
                      </span>
                      <span className='d-flex align-items-center'>
                        <p className='mb-md-0'>
                          <Icon icon='map-marker-alt' className='mr-2 text-white' />
                          {property.address}
                        </p>
                      </span>
                    </Row> */}
                    {/* <Col
                        md='6'
                        className='d-none d-sm-flex d-md-flex align-items-start'
                      ></Col>
                      <Col
                        md='6'
                        className='d-md-flex align-items-end d-none d-sm-block'
                      >
                        <p className='mb-md-0'>
                          <Icon icon='map-marker-alt' className='mr-2 text-white' />
                          {property.address}
                        </p>

                      </Col> */}
                  </div>
                </>
              }
            </div>
          </Col>

          <Col lg='4'>
            <Card className='theme-card h-100'>
              <CardHeader>
                <span className='d-none d-sm-inline-block'>More Details</span>

              </CardHeader>
              <CardBody>
                <Row>
                  {(property.webLink && !hidePropertyName) && (
                    <Col md='6'>
                      <p>
                        <Icon icon='globe' className='mr-2' />
                        <a href={property.webLink} target='_blank'>
                          Website
                        </a>
                      </p>
                    </Col>
                  )}
                  {(property.photoGalleryLink && !hidePropertyName) && (
                    <Col md='6'>
                      <p>
                        <Icon icon='image' className='mr-2' />
                        <a href={property.photoGalleryLink} target='_blank'>
                          Photo Gallery
                        </a>
                      </p>
                    </Col>
                  )}
                  {(property.floorPlanLink && !hidePropertyName) && (
                    <Col md='6'>
                      <p>
                        <Icon icon='home' className='mr-2' />
                        <a href={property.floorPlanLink} target='_blank'>
                          Floor Plan
                        </a>
                      </p>
                    </Col>
                  )}
                  {(property.googleReviewLink && !hidePropertyName) && (
                    <Col md='6'>
                      <p>
                        <Icon icon='star' className='mr-2' />
                        <a href={property.googleReviewLink} target='_blank'>
                          Google Reviews
                        </a>
                      </p>
                    </Col>
                  )}
                  {(property.virtualTourLink && !hidePropertyName) && (
                    <Col md='6'>
                      <p>
                        <Icon icon='home' className='mr-2' />
                        <a href={property.virtualTourLink} target='_blank'>
                          Virtual Tours
                        </a>
                      </p>
                    </Col>
                  )}
                  {(property.feesSheetUrl && !hidePropertyName) && (
                    <Col md='6'>
                      <p>
                        <Icon icon='dollar-sign' className='mr-2' />
                        <a href={property.feesSheetUrl} target='_blank'>
                          Fee Sheet
                        </a>
                      </p>
                    </Col>
                  )}
                </Row>
                <Row>
                  {property.builtYear && (
                    <Col>
                      <p>
                        <Icon icon='calendar-alt' /> Built Year:{' '}
                        {property.builtYear}
                      </p>
                    </Col>
                  )}
                  {property.renovated && (
                    <Col>
                      <p>
                        <Icon icon='calendar-alt' />
                        Renovated: {property.renovated}
                      </p>
                    </Col>
                  )}
                </Row>
                {property.addonItems.length > 0 && (
                  <Row>
                    <Col>
                      <p>
                        <Icon icon='dumbbell' />
                        {/* <i class='fa-sharp fa-solid fa-dumbbell'></i> */}
                        Amenities: {property.addonItems.join(', ')}
                      </p>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg='8'>

						{
							property.specials?.length > 5 && validPropertySpecials(property.specials) ?
							<Row className=' mt-3 mb-3'>
								<Col>
									<div className='specials-box d-flex'>
                    <Icon icon='tag' className='mr-2 mt-2' />
                    {property.specials}
									</div>
								</Col>
							</Row> : <Row className='mt-4'></Row>
						}

            <Row className='d-md-none d-flex align-items-center py-1 bg-white'>
              <Col md='12'>
                <Select
                  name='selectBedSize'
                  value={selectedBedSize}
                  options={[{ value: 'All', label: 'All' }].concat(
                    Object.keys(unitTypes).map((typecode) =>
                      JSON.parse(
                        `{"value":"${typecode}", "label":"${unitTypes[typecode]}"}`
                      )
                    )
                  )}
                  isSearchable={false}
                  onChange={(e) => setSelectedBedSize(e)}
                  className='basic-multi-select w-100 bedsize text-left relative-select rounded select-lg'
                  classNamePrefix='default-select'
                  placeholder='Select Bed Size'
                />
              </Col>
            </Row>
            {/* <div className="fixed-bottom bg-silver d-md-none px-3" ref={requestTourBar}>
              <Row className="d-flex align-items-center py-3">
                <Col md="12">
                  <p className='mb-2'>Request a Tour</p>
                  <div className='tour-request-inputs'>
                    <span className='w-auto d-inline-block'>
                      <DatePicker
                        selected={Date.parse(startDate)}
                        onChange={(date) => setStartDate(date)}
                        minDate={new Date()}
                        dateFormat='MMMM d, yyyy'
                      />
                    </span>
                    <span className='w-auto d-inline-block'>
                      <TimePicker
                        onChange={setTime}
                        value={time}
                        clearIcon={''}
                        clockIcon={''}
                        format={'h:mm a'}
                      />
                    </span>
                  </div>
                  <Button
                    color='primary'
                    className='button-dashboard'
                    onClick={submitRequest}
                  >
                    <Icon icon='paper-plane' className='mr-lg-2' />
                    <span className='d-none d-lg-inline-block'>
                      Request{' '}
                      <span className='d-none d-xl-inline-block'>Tour</span>
                    </span>
                  </Button>
                </Col>
              </Row>
            </div> */}

            <>
							{
								propertyWithFloorPlans ?
								<TabList
									property={propertyWithFloorPlans}
									selectedProperties={[]}
									setSelectedProperties={[]}
									reportId={reportId}
									reportCode={reportCode}
									reportProperties={reportProperties}
									pageName='Report'
									// selectedFpUnits={selectedFpUnits}
									// selectedFloorPlans={selectedFloorPlans}
									cleintReportView={true}
								></TabList> :
								<div align='center'>
									<Spinner
										color="primary"
										style={{
											height: '3rem',
											width: '3rem'
										}}
										type="grow"
									>
										Loading...
									</Spinner>
								</div>
							}
              <ImageModal
                parentRef={toggleImage.bind(this)}
                isOpen={isOpen}
                imgSrc={imgSrc}
                planName={planName}
                setIsOpen={setIsOpen}
              />
            </>
          </Col>

          {(property.lat && property.long && propertyWithFloorPlans && !hidePropertyName) && (
            <Col lg='4'>
              <Card className='theme-card mt-3'>
                <CardBody className='p-0' style={{ height: '350px' }}>
                  <SmallMap
                    property_ids={[property.id]}
                    height='350px'
                    showTooltip={false}
                    latlongs={[
                      {
                        lat: property.lat ? property.lat : '',
                        long: property.long ? property.long : '',
                        id: property.id,
                        property: property,
                      },
                    ]}
                    property={propertyWithFloorPlans}
                    is_show={false}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                  />
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </div>

      {/* <div
        className='sticky-bottom bg-silver d-md-none px-3'
        ref={requestTourBar}
      >
        <Row className='d-flex align-items-center py-3'>
          <Col md='12'>
            <p className='mb-2'>Request a Tour</p>
          </Col>
          <Col md='12' className='d-flex align-items-center'>
            <div className='tour-request-inputs'>
              <span className='w-auto d-inline-block'>
                <DatePicker
                  selected={Date.parse(startDate)}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  // minTime={setHours(setMinutes(new Date(), 0), 17)}
                  // maxTime={setHours(setMinutes(new Date(), 30), 20)}
                  dateFormat='MMMM d, yyyy h:mm aa'
                />
              </span>
            </div>
            <Button
              disabled={loading ? true : false}
              color='primary'
              className='button-dashboard tour-request-button'
              onClick={submitRequest}
            >
              <Icon icon='paper-plane' />{' '}
              <span className='ml-2 hide-mobile'>
                {!loading
                  ? () => (
                      <>
                        Request <span className='hide-lg-mobile'>Tour</span>
                      </>
                    )
                  : 'Sending...'}
              </span>
            </Button>
          </Col>
        </Row>
      </div> */}
    </>
  );
}
