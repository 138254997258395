import React, { useEffect, useState } from 'react';

export const TrippleToggleButton = (props) => {
	const {
		values, 
		setSelected,
		selected
	} = props;

	const options = [
		{
			title: values[0],
			value: values[0],
			pos: "left"
		},
		{
			title: values[1],
			value: values[1],
			pos: "center"
		},
		{
			title: values[2],
			value: values[2],
			pos: "right"
		}
	];

	const [selectOption, setSelectedOption] = useState(options.filter(e => e.value.toLowerCase() === selected.toLowerCase())[0]);
	const [animation, setAnimation] = useState(null);

	function getSwitchAnimation(value) {
		if (value.pos === "center" && selectOption.pos === "left") {
			setAnimation("left-to-center");
		} else if (value === "right" && selectOption.pos === "center") {
			setAnimation("center-to-right");
		} else if (value === "center" && selectOption.pos === "right") {
			setAnimation("right-to-center");
		} else if (value === "left" && selectOption.pos === "center") {
			setAnimation("center-to-left");
		} else if (value === "right" && selectOption.pos === "left") {
			setAnimation("left-to-right");
		} else if (value === "left" && selectOption.pos === "right") {
			setAnimation("right-to-left");
		}
		setSelectedOption(options.filter(e => e.pos === value)[0]);
	};

	useEffect(() => {
		setSelected(selectOption.value);
	}, [selectOption]);

	useEffect(() => {
		setSelectedOption(options.filter(e => e.value === selected)[0]);
	}, [selected]);

	return (
		<div className="tripple-toggle-container">
			<div
				className={`switch ${animation} ${selectOption.pos}-position`}
			></div>
			<input
				checked={selectOption.pos === "left"}
				onChange={(e) => getSwitchAnimation(e.target.value)}
				name="map-switch"
				id="left"
				type="radio"
				value="left"
			/>
			<label
				className={`left-label ${
					selectOption.pos === "left" && "black-font"
				}`}
				htmlFor="left"
			>
				<p>{options[0].title}</p>
			</label>

			<input
				onChange={(e) => getSwitchAnimation(e.target.value)}
				checked={selectOption.pos === "center"}
				name="map-switch"
				id="center"
				type="radio"
				value="center"
			/>
			<label
				className={`center-label ${
					selectOption.pos === "center" && "black-font"
				}`}
				htmlFor="center"
			>
				<p>{options[1].title}</p>
			</label>

			<input
				onChange={(e) => getSwitchAnimation(e.target.value)}
				checked={selectOption.pos === "right"}
				name="map-switch"
				id="right"
				type="radio"
				value="right"
			/>
			<label
				className={`right-label ${
					selectOption.pos === "right" && "black-font"
				}`}
				htmlFor="right"
			>
				<p>{options[2].title}</p>
			</label>
		</div>
	);
}