import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Badge } from 'reactstrap';
import { Icon } from '@assets';
import { unitTypes, formateBudget, formatMoveIn, FilterMenu } from '@sparklib';
import _ from 'lodash';

function KanbanView({ clients, updateClientStatus, showClient, handleSingleDelete }) {
  const columnsFromBackend = {
    ['lead']: {
      name: 'Lead',
      status: 'lead',
      items: filterClients('lead'),
      color: 'primary',
    },
    ['list_sent']: {
      name: 'List Sent',
      status: 'list_sent',
      items: filterClients('list_sent'),
      color: 'success',
    },
    ['list_viewed']: {
      name: 'List Viewed',
      status: 'list_viewed',
      items: filterClients('list_viewed'),
      color: 'danger',
    },
    ['follow_up']: {
      name: 'Follow Up',
      status: 'follow_up',
      items: filterClients('follow_up'),
      color: 'secondary',
    },
    ['scheduling_tours']: {
      name: 'Scheduling Tours',
      status: 'scheduling_tours',
      items: filterClients('scheduling_tours'),
      color: 'warning',
    },
    ['ready_to_tour']: {
      name: 'Ready to Tour',
      status: 'ready_to_tour',
      items: filterClients('ready_to_tour'),
      color: 'info',
    },
    ['done_touring']: {
      name: 'Done Touring',
      status: 'done_touring',
      items: filterClients('done_touring'),
      color: 'primary',
    },
    ['applied']: {
      name: 'Applied',
      status: 'applied',
      items: filterClients('applied'),
      color: 'danger',
    },
    ['signed_lease']: {
      name: 'Signed Lease',
      status: 'signed_lease',
      items: filterClients('signed_lease'),
      color: 'secondary',
    },
    ['invoice_sent']: {
      name: 'Invoice Sent',
      status: 'invoice_sent',
      items: filterClients('invoice_sent'),
      color: 'success',
    },
    ['invoice_paid']: {
      name: 'Invoice Paid',
      status: 'invoice_paid',
      items: filterClients('invoice_paid'),
      color: 'warning',
    },
    ['backlog']: {
      name: 'Backlog',
      status: 'backlog',
      items: filterClients('backlog'),
      color: 'secondary',
    },
    ['lost']: {
      name: 'Lost',
      status: 'lost',
      items: filterClients('lost'),
      color: 'danger',
    },
    ['leased_pending_move_in']: {
      name: 'Leased - Pending Move In',
      status: 'leased_pending_move_in',
      items: filterClients('leased_pending_move_in'),
      color: 'secondary',
    },
    ['moved_in']: {
      name: 'Moved In',
      status: 'moved_in',
      items: filterClients('moved_in'),
      color: 'secondary',
    },
  };

  const statusOptions = Object.keys(columnsFromBackend).map((e) => ({
    label: columnsFromBackend[e].name,
    value: e,
  }));

  function filterClients(status) {
    return clients.filter((client) => client.node.currentStatus === status);
  }

  const [columns, setColumns] = useState(columnsFromBackend);
  const [selectedStatuses, setSelectedStatuses] = useState(
    localStorage.getItem('kanban statuses')
      ? JSON.parse(localStorage.getItem('kanban statuses'))
      : statusOptions
  );

  useEffect(() => {
    let updatedColumns = {};
    Object.keys(columns).forEach((col) => {
      updatedColumns[col] = { ...columns[col], items: filterClients(col) };
    });
    setColumns(updatedColumns);
  }, [clients]);

  function handleStatusChange(status) {
    let finalStatuses = selectedStatuses;
    let selectedStatus = selectedStatuses.find((s) => s.value === status.value);
    if (selectedStatus) {
      finalStatuses = finalStatuses.filter((s) => s.value !== status.value);
    } else {
      finalStatuses.push(status);
    }
    setSelectedStatuses([...finalStatuses]);
    localStorage.setItem('kanban statuses', JSON.stringify(finalStatuses));
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      updateClientStatus(draggableId, destination.droppableId);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  function filterColumns(columns) {
    const selectedCols = selectedStatuses.map((s) => s.value);
    let filteredColumns = {};
    Object.keys(columns).forEach((col) => {
      if (selectedCols.includes(col)) {
        filteredColumns[col] = columns[col];
      }
    });
    return filteredColumns;
  }

  useEffect(() => {
    localStorage.setItem('kanban_view', true);
    return () => {
      localStorage.removeItem('kanban_view');
    };
  }, []);

  return (
    <>
      <FilterMenu
        options={statusOptions}
        selectedOptions={selectedStatuses}
        onClickToggle={handleStatusChange}
        name='Customize Columns'
        styles={{
          float: 'right',
          position: 'absolute',
          top: '-40px',
          right: '60px',
        }}
      />
      <div className='d-flex pb-5 kanban-container'>
        <DragDropContext
          onDragEnd={(result) =>
            onDragEnd(result, filterColumns(columns), setColumns)
          }
        >
          {Object.entries(filterColumns(columns)).map(
            ([columnId, column], index) => {
              return (
                <div className='d-flex mt-2' key={columnId}>
                  <div className='m-2' style={{ position: 'relative' }}>
                    <h5>
                      <Badge color={column.color} pill>
                        {column.items.length}
                      </Badge>
                      &nbsp;
                      {column.name}
                    </h5>
                    <div className='mt-2'>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{
                                background: snapshot.isDraggingOver
                                  ? '#DCE4F5'
                                  : '#F5F7FC',
                                paddingBottom: 10,
                                width: 290,
                                height: column.items.length === 0 && 100,
                                // height: "100vh",
                                // overflowY: "auto",
                                borderRadius: '5px',
                              }}
                            >
                              {column.items.map((item, index) => {
                                return (
                                  <Draggable
                                    key={item.node.id}
                                    draggableId={item.node.id}
                                    index={index}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className='overflow-y-auto rounded-3'
                                          style={{
                                            userSelect: 'none',
                                            padding: 2,
                                            margin: '6px 6px 8px 6px',
                                            // maxHeight: "500px",
                                            backgroundColor: snapshot.isDragging
                                              ? '#263B4A'
                                              : '#5077CF',
                                            color: 'white',
                                            borderRadius: '5px',
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <ClientCard
                                            client={item.node}
                                            showClient={showClient}
                                            handleSingleDelete={handleSingleDelete}
                                          />
                                        </div>
                                      );
                                    }}
                                  </Draggable>
                                );
                              })}
                              {provided.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </DragDropContext>
      </div>
    </>
  );
}

function ClientCard({ client, showClient, handleSingleDelete }) {
  function getBedType(array) {
    return array.map((e) => unitTypes[e]).join(', ');
  }

  return (
    <div
      className='p-3 container cursor-pointer'
      onClick={() => showClient(client.id)}
    >
      <h5>
        <strong>{client.name}</strong>
        <Icon
          icon='trash-alt'
          className='float-right'
          onClick={(e) => {
            e.stopPropagation();
            handleSingleDelete(client.id);
          }}
        />
      </h5>
      <table className='table-sm table-borderless'>
        <tbody>
          <tr>
            <td>
              <Icon icon='envelope' />
            </td>
            <td>{client.email}</td>
          </tr>
          {client.phone && (
            <tr>
              <td>
                <Icon icon='phone-alt' />
              </td>
              <td className='text-end'>{client.phone}</td>
            </tr>
          )}
          {formateBudget(client.minRent, client.maxRent) && (
            <tr>
              <td>
                <Icon icon='dollar-sign' />
              </td>
              <td>{formateBudget(client.minRent, client.maxRent)}</td>
            </tr>
          )}
          {formatMoveIn(client.availFrom, client.availTo) && (
            <tr>
              <td>
                <Icon icon='calendar-alt' />
              </td>
              <td>{formatMoveIn(client.availFrom, client.availTo)}</td>
            </tr>
          )}
          {client.bedroomArray && (
            <tr>
              <td>
                <Icon icon='bullseye' />
              </td>
              <td>{getBedType(client.bedroomArray)}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default KanbanView;

// const columnsFromBackend = {
// 	["Lead"]: {
// 		name: "Lead",
// 		status: "Lead",
// 		items: clients,
// 		color: "primary",
// 	},
// 	["Recommendations Sent"]: {
// 		name: "Recommendations Sent",
// 		status: "Recommendations Sent",
// 		items: [],
// 		color: "success",
// 	},
// 	["Recommendations Reviewed"]: {
// 		name: "Recommendations Reviewed",
// 		status: "Recommendations Reviewed",
// 		items: [],
// 		color: "danger",
// 	},
// 	["Follow Up"]: {
// 		name: "Follow Up",
// 		status: "Follow Up",
// 		items: [],
// 		color: "secondary",
// 	},
// 	["Finalize Favorites"]: {
// 		name: "Finalize Favorites",
// 		status: "Finalize Favorites",
// 		items: [],
// 		color: "warning",
// 	},
// 	["Scheduling Tours"]: {
// 		name: "Scheduling Tours",
// 		status: "Scheduling Tours",
// 		items: [],
// 		color: "info",
// 	},
// 	["Ready to Tour"]: {
// 		name: "Ready to Tour",
// 		status: "Ready to Tour",
// 		items: [],
// 		color: "primary",
// 	},
// 	["Done Touring"]: {
// 		name: "Done Touring",
// 		status: "Done Touring",
// 		items: [],
// 		color: "danger",
// 	},
// 	["Applied"]: {
// 		name: "Applied",
// 		status: "Applied",
// 		items: [],
// 		color: "secondary",
// 	},
// 	["Signed Lease"]: {
// 		name: "Signed Lease",
// 		status: "Signed Lease",
// 		items: [],
// 		color: "success",
// 	},
// 	["Invoice Paid"]: {
// 		name: "Invoice Paid",
// 		status: "Invoice Paid",
// 		items: [],
// 		color: "warning",
// 	},
// 	["Backlog"]: {
// 		name: "Backlog",
// 		status: "Backlog",
// 		items: [],
// 		color: "info",
// 	},
// 	["Lost"]: {
// 		name: "Lost",
// 		status: "Lost",
// 		items: [],
// 		color: "primary",
// 	},
// };
