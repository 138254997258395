import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Button } from 'reactstrap';
import { Icon } from '@assets';
import SubscriptionForm from './SubscriptionForm';
import { useLazyQuery } from '@apollo/client';
import { AGENT_CITY_ACCESS } from '@api';
import { forEach } from 'lodash';
import AgentCityAccessForm from '../../../components/user/agents/AgentCityAccessForm';

function SubscriptionStatus({ subscription }) {
  let status = subscription.active ? 'Active' : 'Expired';
  return subscription.isTrial ? ` (${status} Trial)` : ` (${status})`;
}

export default function Subscriptions({
  subscriptions,
  setSubscriptions,
  userId,
	user
}) {
  const [isSubscription, setIsSubscription] = useState(false);
  const [isHoverd, setIsHoverd] = useState(null);
  const [currentSubscription, setCurrentSubscription] = useState(null);
	const [agentSubscriptionCities, setAgentSubscriptionCities] = useState([]);
  const newForm = () => {
    setIsSubscription(!isSubscription);
  };

  useEffect(() => {
    setIsSubscription(false);
  }, [userId]);
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

	const [agentCityAccess] = useLazyQuery(AGENT_CITY_ACCESS, {
		fetchPolicy: 'network-only',
		onCompleted: (data, variables) => {
			setAgentSubscriptionCities(e => [...e, {...variables, cities: [...data.agentCityAccess]}])
		}
	});

	useEffect(() => {
		async function fetchData() {
			if (user.isAgent && subscriptions?.length > 0) {
				let index = 0;
				const interval = setInterval(async () => {
					const sub = subscriptions[index];
					try {
						await agentCityAccess({
							variables: {
								subscriptionId: Number(sub.id),
								agentId: user.id,
							},
						});
					} catch (error) {
						console.log(error); // handle the error here
					}
	
					index++;
					if (index >= subscriptions.length) {
						clearInterval(interval);
					}
				}, 1000);
			}
		}
	
		fetchData();
	}, [user]);

  const handleMouseEnter = (index) => {
    setIsHoverd(index);
  };

  const handleMouseLeave = (index) => {
    setIsHoverd('');
  };

  const editSubscrption = (subscription) => {
    setIsSubscription(true);
    setCurrentSubscription(subscription);
  };

  return (
    <>
			{ !user.isAgent ?
			<>
				<Row>
					<Col>
						<Label for='subscriptions'>Subscriptions</Label>
						{isSubscription != true && (
							<Button
								type='button'
								onClick={newForm}
								className='ml-2 btn btn-sm btn-success'
							>
								<Icon icon='plus' />
							</Button>
						)}
						{isSubscription === true ? (
							<SubscriptionForm
								user={user}
								setSubscriptions={setSubscriptions}
								subscriptions={subscriptions}
								newForm={() => {
									setIsSubscription(!isSubscription);
									setCurrentSubscription(null);
								}}
								currentSubscription={currentSubscription}
							/>
						) : null}
					</Col>
				</Row>
				<Row>
					{subscriptions && subscriptions.length > 0 ? (
						subscriptions.map((subscription) => {
							return (
								<Col md='6' className='mt-2' key={subscription.id}>
									<div
										className='info-panel py-2 px-3'
										key={subscription.id}
										onMouseEnter={() => handleMouseEnter(subscription.id)}
										onMouseLeave={() => handleMouseLeave(subscription.id)}
									>
										<div>
											<Row>
												<Col md='9'>
													<div className='text-left'>
														<strong>{subscription.plan.name}</strong> (
														{subscription.plan.interval})
														{subscription.stripeSubscriptionId ? (
															<Icon
																icon={['fab', 'cc-stripe']}
																className='ml-1'
															/>
														) : null}
														<SubscriptionStatus subscription={subscription} />
													</div>
												</Col>
												<Col md='3'>
													{isHoverd === subscription.id && (
														<>
															<div
																className='text-right'
																style={{ cursor: 'pointer' }}
																onClick={() => {
																	editSubscrption(subscription);
																}}
															>
																<Icon className='text-right ml-1' icon='edit' />
															</div>
														</>
													)}
												</Col>
											</Row>
										</div>
										<div>
											{subscription.currentStartDatetime &&
												subscription.currentEndDatetime && (
													<>
														{formatDate(subscription.currentStartDatetime)} -{' '}
														{formatDate(subscription.currentEndDatetime)}
													</>
												)}
										</div>
										<div>
											{subscription.cities.length > 0
												? `${subscription.cities
														?.map((city) => city.name)
														.join(', ')}`
												: 'No Market Access'}
										</div>
									</div>
								</Col>
							);
						})
					) : (
						<Col md='12'>
							<div className='text-center info-panel py-4'>
								User has no subscriptions
							</div>
						</Col>
					)}
				</Row>
			</> : 
			<>
				<Row>
					<Col>
						{/* <Label for='subscriptions'>Broker Subscriptions</Label> */}
						<AgentCityAccessForm agent={user} subscriptions={subscriptions} />
					</Col>
				</Row>
			</>
		  }
    </>
  );
}
