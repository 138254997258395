export default function filterFloorPlans(p, getFilter){

	let filteredUnits = [];
  let filteredFloorPlans = [];
  let finalFloorPlans = [];
  const bathFilter = Number(JSON.parse(getFilter('bath'))?.value);

  filteredFloorPlans = p.floorPlans.filter((fp) => {
    const bedOK =
      getFilter('bed') && getFilter('bed').length > 0
        ? getFilter('bed').includes(fp.bed)
        : true;
    const bathOK = bathFilter > 0 ? Number(fp.bath) >= bathFilter : true;
    return bedOK && bathOK;
  });

  finalFloorPlans = filteredFloorPlans.filter((fp) => {
		return (
			fp.isAvailable &&
			(getFilter('minPrice') ? fp.rentMin && fp.rentMin >= getFilter('minPrice') : true) &&
			(getFilter('maxPrice') ? fp.rentMax && (fp.rentMax <= getFilter('maxPrice') || (fp.rentMax >= getFilter('maxPrice') && fp.rentMin <= getFilter('maxPrice'))) : true) &&
			// (getFilter('maxPrice') ? fp.rentMax && fp.rentMax <= getFilter('maxPrice') : true) &&   // Old filter
			(getFilter('sqFt') ? fp.sqftMin && fp.sqftMin >= getFilter('sqFt') : true) &&
			// (getFilter('availFrom') && !getFilter('availTo') && u.moveIn
			// 			? new Date(u.moveIn) >= new Date(getFilter('availFrom'))
			// 			: true) &&
			// 		(getFilter('availFrom') && getFilter('availTo') && u.moveIn
			// 			? new Date(u.moveIn) <= new Date(getFilter('availTo'))
			// 			: true) &&
			// (getFilter('availFrom') && getFilter('availTo') && fp.availableFrom && fp.availableTo
			// 	? new Date(fp.availableFrom) >= new Date(getFilter('availFrom'))
			// 	: true) &&
			(getFilter('availFrom') && fp.availableFrom
				? new Date(fp.availableFrom) >= new Date(getFilter('availFrom')) || 
					(new Date(fp.availableFrom) <= new Date(getFilter('availFrom')) && new Date(fp.availableTo) >= new Date(getFilter('availFrom')))
				: true) &&
			(getFilter('availTo') && fp.availableTo
				? new Date(fp.availableTo) <= new Date(getFilter('availTo')) || 
					(new Date(fp.availableFrom) <= new Date(getFilter('availTo')) && new Date(fp.availableTo) >= new Date(getFilter('availTo')) )
				: true)
		);
  });

	
	if (getFilter('availFrom') || getFilter('availTo') || getFilter('minPrice') || getFilter('maxPrice')){
		let availFrom = getFilter('availFrom');
		let availTo = getFilter('availTo');
		let rentMax = getFilter('maxPrice');
		let rentMin = getFilter('minPrice');
		let floorPlans = [];
		finalFloorPlans.forEach((fp) => {
			let finalUnits = fp.units
			finalUnits = finalUnits?.filter(u => u.isAvailable);
			finalUnits = finalUnits?.filter((u) => {
				if (availFrom && availTo){
					if (new Date(availFrom) < new Date()) {
						return (new Date(u.moveIn) <= new Date(availTo));
					} else {
						return (new Date(u.moveIn) >= new Date(availFrom) && new Date(u.moveIn) <= new Date(availTo))
					}
				} else if (availFrom){
					if (new Date(availFrom) < new Date()) {
						return (new Date(u.moveIn) <= new Date(availFrom));
					} else {
						return (new Date(u.moveIn) >= new Date(availFrom));
					}
				} else if (availTo){
					// if (new Date(availTo) < new Date()) {
					// 	return (new Date(u.moveIn) <= new Date(availFrom));
					// } else {
						return (new Date(u.moveIn) <= new Date(availTo));
					// }
				}
				return true;
			});
			finalUnits = finalUnits?.filter((u) => {
				if (rentMin || rentMax) {
					if (!u.price) return false;
					if (rentMin && rentMax){
						return (u.price >= rentMin && u.price <= rentMax);
					} else if (rentMax){
						return (u.price <= rentMax)
					} else if (rentMin){
						return (u.price >= rentMin)
					}
				}
				return true;
			});
			if (finalUnits?.length > 0) {
				floorPlans.push({...fp, units: finalUnits});
			}
		});
		finalFloorPlans = floorPlans;
	}

  return { ...p, floorPlans: finalFloorPlans };
}