import React, { useRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import queryString from 'query-string';
import { NavLink, useHistory } from 'react-router-dom';
import NavBar from '../components/NavBar';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import { PASSWORD_RESET } from '@api';
import { useMutation } from '@apollo/client';

export default function ResetPassword(props) {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const history = useHistory();
  const password = useRef({});
  password.current = watch('new_password', '');
  const [password_token, setPasswordToken] = useState('');

  const isLogin = () => {
    if (localStorage.getItem('auth_token')) {
      history.push('/');
    }
  };
  const [resetPassword] = useMutation(PASSWORD_RESET, {
    onCompleted: (data) => {
      if (data.resetPassword.errors && data.resetPassword.errors.length > 0) {
        data.resetPassword.errors.map((err) => toast(err, { type: 'error' }));
      } else {
        toast(data.resetPassword.message, { type: 'success' });
        history.push({
          pathname: '/login',
        });
      }
    },
    onError: (e) => {
      console.log(e, 'updated profile ON ERROR');
    },
  });

  useEffect(() => {
    if (props.location.search) {
      let token = queryString.parse(props.location.search);
      if (token.reset_token) {
        setPasswordToken(token.reset_token);
      } else {
        history.push({
          pathname: '/login',
          state: { message: 'You are not Authorize User!' },
        });
      }
    } else {
      history.push({
        pathname: '/login',
        state: { message: 'You are not Authorize User!' },
      });
    }
    isLogin();
    return () => {};
  }, []);

  const onSubmit = (data, e) => {
    resetPassword({
      variables: {
        resetToken: password_token,
        newPassword: data.new_password,
      },
    });
  };

  return (
    <>
      <NavBar />
      <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Reset Password</h1>
        </Container>
      </Container>

      <Container fluid className='py-4 text-center'>
        <Container>
          <Row className='px-lg-5'>
            <Col md={{ size: 4, offset: 4 }} className='inputFields'>
              <Form className='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Input
                    type='password'
                    placeholder='New Password'
                    name='new_password'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      minLength: 6,
                    })}
                  />
                  {_.get('new_password.type', errors) === 'required' && (
                    <p className='input-error'>* Password required</p>
                  )}
                  {_.get('new_password.type', errors) === 'minLength' && (
                    <p className='input-error'>
                      * Password should be minimum 6 letters
                    </p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='password'
                    placeholder='Confirm Password'
                    name='confirm_password'
                    autoComplete='off'
                    innerRef={register({
                      validate: (value) =>
                        value === password.current ||
                        '* The passwords do not match',
                    })}
                  />
                  {errors.confirm_password && (
                    <p className='input-error'>
                      {errors.confirm_password.message}
                    </p>
                  )}
                </FormGroup>

                <Button color='green' size='lg'>
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>

          <Row className='pt-5 privacy-policy'>
            <Col md={{ size: 4, offset: 4 }}>
              <p className='mb-0'>
                <small>By Continuing you agree to accept our</small>
              </p>
              <p className='mb-5'>
                <small>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink> &{' '}
                  <NavLink to='/terms-of-service'>Terms of Service.</NavLink>
                </small>
              </p>
              <hr className='border-light' />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
