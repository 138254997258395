import PropTypes from 'prop-types';
import React from 'react';

export const ToggleButton = (props) => {
  const {
    selected,
    toggleSelected,
    firstOption,
    secondOption,
    width,
    rounded,
  } = props;
  const containerBorderRadius = rounded
    ? { width: width || '100%', borderRadius: '36px' }
    : { width: width || '100%' };
  const buttonBorderRadius = rounded ? { borderRadius: '20px' } : undefined;
  return (
    <div
      className='toggle-container'
      style={containerBorderRadius}
      onClick={toggleSelected}
    >
      <div
        className={`toggle-button ${selected ? '' : 'toggle-disabled'}`}
        style={buttonBorderRadius}
      >
        {selected ? firstOption : secondOption}
      </div>
      {/* {firstOption} {secondOption} */}
    </div>
  );
};

ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};
