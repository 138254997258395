import React from 'react';
import { PROPERTY_ADDONS } from '@api';
import { useQuery } from '@apollo/client';
import { Row, Col, Label } from 'reactstrap';
import { defaultAmenities } from '@sparklib';

export default function Amenities({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  const amenities = useQuery(PROPERTY_ADDONS);
  const { data: amenitiesData } = amenities;
  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'amenities' && 'hidden'
      }`}
    >
      <div className='filter-box' style={style}>
        <h6>Amenities</h6>
        <Row>
          {/* {amenitiesData?.propertyAddons?.map((addon) => ( */}
          {defaultAmenities.map((addon) => (
            <Col md='6' key={addon.name}>
              <Label style={{ fontSize: '0.9rem' }}>
                <input
                  type='checkbox'
                  className='custom-checkbox d-inline-block w-auto mr-2'
                  value={addon.name}
                  onChange={(e) => {
                    if (e.target.checked) {
                      addFilter({
                        e: [...getFilter('amenities'), { label: addon.name }],
                        key: 'amenities',
                      });
                    } else {
                      addFilter({
                        e: getFilter('amenities').filter(
                          (a) => a.label !== addon.name
                        ),
                        key: 'amenities',
                      });
                    }
                  }}
                  checked={
                    getFilter('amenities').find(
                      (a) => a.label === addon.name
                    ) || false
                  }
                  name={addon.name}
                />
                {addon.name}
              </Label>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
