import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import Avatar from 'react-avatar';
import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  Badge,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { toast } from 'react-toastify';
import {
  SEARCH_USERS,
  APPROVE_DECLINE_USER,
  SIGN_IN_AS,
  USER_MULTI_DELETE,
	ALL_BROKERS
} from '@api';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import RTable from '../../components/RTable';
import { ToggleButton } from '@sparklib';
import { Icon } from '@assets';
import SidebarRight from '../../components/SidebarRight';
import UserForm from './UserForm';
import { DropDown } from '../../components/shared/sparkLib/DropDown';

export default function Users({ userData, setUserData, ...props }) {
  const [userList, setUserList] = useState('loading');
  const history = useHistory();
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
  const [isProgress, setIsProgress] = useState(false);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  // const { loading, data: userData } = useQuery(SEARCH_USERS);
	const [showActiveUsers, setShowActiveUsers] = useState(true);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [selectedUserType, setSelectedUserType] = useState('All');
	const [showBrokers, setShowBrokers] = useState(false);
	const [allBrokers, setAllBrokers] = useState(null);
	const [selectedBroker, setSelectedBroker] = useState(null);

  const [approveDeclineUser] = useMutation(APPROVE_DECLINE_USER, {
    onCompleted: (data) => {
      if (
        data.userApproveDecline.errors &&
        data.userApproveDecline.errors.length > 0
      ) {
        toast(data.userApproveDecline.message, { type: 'error' });
      } else {
        toast(data.userApproveDecline.message, { type: 'success' });
      }
    },
    refetchQueries: [SEARCH_USERS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const [loginAsUser] = useMutation(SIGN_IN_AS, {
    onCompleted: (data) => {
      if (data.signinAs.errors && data.signinAs.errors.length > 0) {
        toast(data.signinAs.message, { type: 'error' });
      } else {
        toast(data.signinAs.message, { type: 'success' });
        const currentLocalStorage = { ...localStorage };
        localStorage.clear();
        localStorage.setItem(
          'original_localStorage',
          JSON.stringify(currentLocalStorage)
        );

        localStorage.setItem('auth_token', data.signinAs.authenticationToken);
        localStorage.setItem('user_name', data.signinAs.user.name);
        localStorage.setItem('user_email', data.signinAs.user.email);
        localStorage.setItem('default_property_view', data.signinAs.user.defaultPropertyView);
        if (data.signinAs.user.defaultCityId) {
          localStorage.setItem('default_city_id', data.signinAs.user.defaultCityId);
        }
        if (data.signinAs.user.isVa === true) {
          localStorage.setItem('user_type', 'va');
        } else {
					if (data.signinAs.user.isBroker){
						localStorage.setItem('is_broker', 'true');
					}else if (data.signinAs.user.isAgent){
						localStorage.setItem('is_agent', 'true');
					}
          localStorage.setItem('user_type', 'user');
          localStorage.setItem('subscription_popup', true);
          if (data.signinAs.user.subscriptions.length > 0) {
            let hasActiveSubscription = false;
            let hasTrialSubscription = false;
            data.signinAs.user.subscriptions.map((subscription) => {
              if (
                subscription.isTrial === true &&
                subscription.active === true &&
                new Date(subscription.currentEndDatetime) > new Date()
              ) {
                hasTrialSubscription = true;
                localStorage.setItem(
                  'trial_end',
                  subscription.currentEndDatetime
                );
                localStorage.removeItem('subscription_popup');
              } else if (
                subscription.active === true &&
                new Date(subscription.currentEndDatetime) > new Date()
              ) {
                hasActiveSubscription = true;
                localStorage.removeItem('subscription_popup');
              }
            });
            if (hasActiveSubscription) {
              localStorage.removeItem('trial_end');
            }
          }
        }
        window.location.href = data.signinAs.user.isVa
          ? '/assistant-dashboard'
          : '/properties';
      }
    },
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const [userMultiDelete] = useMutation(USER_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.userMultiDelete.message, { type: 'success' });
      setIsProgress(false);
      setRefetch(true);
    },
    refetchQueries: [SEARCH_USERS],
    onError: (e) => {
      console.log(e);
    },
  });

	const [fetchBrokers, {data: allBrokersData}] = useLazyQuery(ALL_BROKERS);

	useEffect(() => {
		if (allBrokersData?.allBrokers?.length > 0){
			setAllBrokers(Object.values(allBrokersData.allBrokers).map((broker) => JSON.parse(`{"name":"${broker.name}", "id":"${broker.id}"}`)));
		}
	}, [allBrokersData]);

  const handleDeleteAll = async () => {
    setIsProgress(true);
    await userMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

  useEffect(() => {
    if (userData) {
      if (userData.length === 0) {
        setUserList('noData');
      } else {
				setFilteredUsers(filterUsers(userData));
        setUserList('data');
      }
    }
  }, [userData, showActiveUsers]);

  const approveUser = (id, status) => {
    approveDeclineUser({
      variables: { userId: parseInt(id), approve: status },
    });
  };

  const loginUser = (id) => {
    loginAsUser({
      variables: { userId: parseInt(id) },
    });
  };

  const userRole = (row) => {
    return row.isAdmin === true
      ? 'Admin'
      : row.isVa === true
      ? 'VA'
			: row.isBroker === true
			? 'Manager'
			: row.isAgent === true
			? 'Agent'
      : 'User';
  };

	function filterUsers(users){
		var newUsers = users.filter(u => {
			if (showActiveUsers){
				if (u.approved === true) return true;
				else return false;
			} else return true
		})
		return newUsers.map(u => {
			return (
				{
					...u,
					newStatus: (u.approved === true ? 'Active' : 'Inactive'),
					activityStatus: (u.isOnline ? "online" : "offline"),
          cities: [...new Set(u.subscriptions?.flatMap(sub => sub.cities?.map(city => city.name) ?? []) ?? [])],
					// newStatus: (u.approved === true ? 'Active' : 'Inactive').concat(u.status && userRole(u) === 'User'
					// ? ` - ${u.status}`
					// : ''),
				}
			)
		});
	}

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ value, row }) => (
          <div>
            <Avatar
              name={value ? value : '-'}
              className='mr-2 text-white'
              round={true}
              size='30'
            />{' '}
            {value ? value : '-'}
          </div>
        ),
      },
      {
        Header: 'Online',
        accessor: 'activityStatus',
        Cell: ({ value, row }) => <Icon icon="circle" color={value === "online" ? "green" : "#C9C9CB" } />,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Phone',
        accessor: 'phoneNo',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'License Number',
        accessor: 'licenseNo',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      /* {
        Header: "City",
        accessor: "city.name",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      }, */
      {
        Header: 'User Role',
        accessor: userRole,
        Cell: ({ value }) => <div>{value}</div>,
      },
      {
        Header: 'Subscriptions',
        accessor: 'subscriptions',
        Cell: ({ value, row }) => (
          <>
            {value
              ? value.map((subscription, index) => {
                  return (
                    <div
                      key={index}
                      className={subscription.active ? '' : 'text-muted'}
                    >
                      {subscription.plan.name} ({subscription.plan.interval})
                      {subscription.stripeSubscriptionId ? (
                        <Icon icon={['fab', 'cc-stripe']} className='ml-1' />
                      ) : null}
                      <br />
                    </div>
                  );
                })
              : // : row.original.isTrial === true
                // ? 'Trial'
                '-'}
          </>
        ),
      },
      {
        Header: 'Cities',
        accessor: 'cities',
        Cell: ({ value, row }) => (
          <>
            {value
              ? value.map((city, index) => {
                  return (
                    <div
                      key={index}
                    >
                      {city}
                    </div>
                  );
                })
              : '-'}
          </>
        ),
      },
      {
        Header: 'Status',
        accessor: 'newStatus',
        Cell: ({ value, row }) => (
          <div className='client-select-stage'>
            <Badge
              className={
                value === "Active" ? 'p-2 badge-success' : 'p-2 badge-danger'
              }
            >
              {value}
            </Badge>
          </div>
        ),
      },
      {
        Header: 'Affiliate',
        accessor: 'affiliateCode',
        Cell: ({ value, row }) => (
          <div className='client-select-stage'>
            {value}
          </div>
        ),
      },
      // {
      //   Header: 'Approved?',
      //   accessor: 'status',
      //   Cell: ({ value, row }) => (
      //     <div className='client-select-stage'>
      //       <Badge
      //         className={
      //           row.original.approved === true
      //             ? 'p-2 badge-success'
      //             : 'p-2 badge-danger'
      //         }
      //       >
      //         {value || 'Not Applicable'}
      //       </Badge>
      //     </div>
      //   ),
      // },
      {
        Header: 'Action',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSidebarState(true);
                    history.push(`/users/edit/${value}`);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => loginUser(value)}>
                  Login
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleSingleDelete(value)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );

  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);
  useEffect(() => {
    // check !id and operation is edit
    sidebarState ? !id && history.push(`/users/new`) : history.push(`/users`);
  }, [sidebarState, operation]);

	useEffect(() => {
		if (selectedUserType === 'Agent'){
			setShowBrokers(true);
			fetchBrokers();
		}else {
			setShowBrokers(false);
			// setSelectedBroker(null);
		}
		if (userData?.length > 0){
			var newFilteredUsers = filterUsers(userData).filter(user => {
				if (selectedUserType === 'All') return true;
				else if (selectedUserType === 'Admin' && user.isAdmin) return true;
				else if (selectedUserType === 'Manager' && user.isBroker) return true;
				else if (selectedUserType === 'Agent' && user.isAgent) return true;
				else if (selectedUserType === 'VA' && user.isVa) return true;
				else if (selectedUserType === 'User' && user.isUser) return true;
				else return false;
			});
			setFilteredUsers(newFilteredUsers);
		}
	}, [selectedUserType, showActiveUsers, userData]);

	useEffect(() => {
		if (selectedBroker && userData?.length > 0){
			var newFilteredUsers = filterUsers(userData).filter(user => Number(selectedBroker.id) == user.brokerId && user.isAgent);
			setFilteredUsers(newFilteredUsers);
		}
	}, [selectedBroker, showActiveUsers]);

  return (
    <>
      {userData?.length === 0 && <Spinner />}
      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} user
          {isCheckedId.length > 1 ? 's' : ''}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <SidebarRight
        sidebarState={sidebarState}
        sidebarToggle={() => {
          setSidebarState(!sidebarState);
        }}
        sidebarWidth='650px'
        sidebarContent={
          <UserForm
            operation={operation}
            sidebarToggle={() => {
              setSidebarState(!sidebarState);
            }}
						setUserData={setUserData}
            {...props}
          />
        }
      />
      <Row className='mt-md-2'>
        <Col md='12'>
          {userData.length > 0 ? (
            <RTable
              columns={columns}
              data={filteredUsers}
              filterable
              addons={
								<>
									<Button
										color='red'
										onClick={onAllDeletePopup}
										disabled={isCheckedId.length > 0 ? false : true}
										className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
									>
										<Icon icon='trash-alt' className='mr-2' />
										Delete
									</Button>
								</>

              }
							leftAddons={
								<>
									<div className='px-0 d-inline-flex align-items-center properties-toggle-switch inline-block ml-3'>
										<ToggleButton
											selected={showActiveUsers}
											firstOption='Active Users'
											secondOption='All Users'
											width='160px'
											rounded
											toggleSelected={() => {
												setShowActiveUsers(!showActiveUsers);
											}}
										/>
									</div>
									<div className='px-0 d-inline-flex align-items-center properties-toggle-switch inline-block ml-3'>
										<DropDown
											options={['All', 'Admin', 'Agent', 'Manager', 'User', 'VA']}
											setSelectedOption={setSelectedUserType}
											selectedOption={selectedUserType}
										/>
									</div>
									{(showBrokers && allBrokers?.length > 0) &&
										<div className='px-0 d-inline-flex align-items-center properties-toggle-switch inline-block ml-3'>
											<DropDown
												options={allBrokers}
												setSelectedOption={setSelectedBroker}
												selectedOption={selectedBroker}
												cssClasses="btn button-dashboard"
											/>
										</div>
									}
								</>
							}
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
            />
          ) : null }
        </Col>
      </Row>
    </>
  );
}
