// import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { SIGN_OUT } from '../api/mutations';
import { toast } from 'react-toastify';

export default function Logout() {
  const client = useApolloClient();
  const history = useHistory();

	const [singout] = useMutation(SIGN_OUT, {
		onCompleted: (data) => {
			history.push("/");
			// client.clearStore().then(() => {
			// 	history.replace({
			// 		pathname: '/',
			// 		state: { message: 'Signed out successfully.', type: 'success' },
			// 	});
			// });
			// toast("Session expired", { type: "error", limit: 1 });
		}
	});

	if (localStorage.getItem("auth_token") || localStorage.getItem("user_type")){
		singout({
			variables: {}
		});
		localStorage.clear();
	} else {
		history.push("/");
	}

  // localStorage.removeItem('admin_token');
  // localStorage.removeItem('auth_token');
  // localStorage.removeItem('user_token');
  // localStorage.removeItem('assistant_token');
  // localStorage.removeItem('user_type');
  // localStorage.removeItem('user_name');
  // localStorage.removeItem('filter_token');

  return null;
};
