import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { GoogleMap, useJsApiLoader, OverlayView } from '@react-google-maps/api';
import PlaceMarker from '../Property/Properties/Map/PlaceMarker';

const noPoi = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];
const defaultMapOptions = {
  fullscreenControl: false,
  styles: noPoi,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  preserveViewport: true,
  suppressMarkers: true,
  zoomControl: false,
  zoom: 14,
};

function SmallMap(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [latlongs, setLatlongs] = useState([]);
  const [map, setMap] = useState(null);
  const [isViewed, setIsViewed] = useState([]);
  useEffect(() => {
    if (props.latlongs.length > 0 && !_.isEqual(props.latlongs, latlongs)) {
      setLatlongs(props.latlongs);
    }
  }, [props.latlongs]);
  const containerStyle = {
    height: props.height,
  };
  const center = {
    lat: props?.latlongs[0].lat,
    lng: props?.latlongs[0].long,
  };

  const loadHandler = (map) => {
    setMap(map);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    // googleMapsApiKey: 'AIzaSyD-Vwu6LSsJscOPVKsSDvVse9NFlA3U7d4',
    googleMapsApiKey: 'AIzaSyC1BgEh0QjPcEiFz8GDw_L87UYf__IYXTM',
  });

  function displayMarkers() {
    if (props.latlongs.length > 0 && map) {
      return props.latlongs.map((item, i) => {
        return (
          <OverlayView
            position={{ lat: item?.lat, lng: item?.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            key={i}
          >
            <PlaceMarker
              tooltip={item?.title}
              property={props?.property}
              showTooltip={showTooltip}
              setShowTooltip={setShowTooltip}
              currentId={currentId}
              setCurrentId={setCurrentId}
              isViewed={isViewed}
              setIsViewed={setIsViewed}
              setSidebarState={props.setSidebarState}
              setProperty={props.setProperty}
              path={props.path}
              is_show={props.is_show}
              showPropertyDetail={props.showPropertyDetail}
            />
          </OverlayView>
        );
      });
    }
  }

  return (
    <>
      {props.latlongs && isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={loadHandler}
          id='google-map-small'
          onClick={() => {
            setCurrentId(null);
          }}
          center={center}
          onUnmount={onUnmount}
          options={defaultMapOptions}
        >
          {displayMarkers()}
        </GoogleMap>
      )}
    </>
  );
}
export default React.memo(SmallMap);
