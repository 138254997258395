import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import DashboardWidget from './../DashboardWidget';
import { GET_CLIENT_DASHBOARD } from '@api';
import { useQuery } from '@apollo/client';
import Spinner from '../Spinner';

export default function UserHome(props) {
  const {
    data: userDashboardData,
    loading: userDashboardLoading,
    error: userDashboardError,
  } = useQuery(GET_CLIENT_DASHBOARD, {
    onCompleted: (data) => {
      // console.log(data,"data");
    },
    onError: (e) => {
      console.log(e);
      // toast(e, { type: "error" });
    },
  });
  const [widgetList, setWidgetList] = useState('loading');
  const formatTimeStamp = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Intl.DateTimeFormat('default', options).format(
      Date.parse(dateString)
    );
  };

  return (
    <>
      {userDashboardLoading && <Spinner />}
      <Row className='mt-md-3 py-3'>
        <Col sm='6' lg='3'>
          <DashboardWidget
            color='red' //red, green, blue, yellow, purple, orange skyblue, pink
            link='/clients'
            number={userDashboardData && userDashboardData.clients.totalCount}
            name='Total Clients'
            icon='user-alt'
          />
        </Col>
        <Col sm='6' lg='3'>
          <DashboardWidget
            link='/my-recommendations'
            color='purple'
            number={userDashboardData && userDashboardData.reports.totalCount}
            name='Total Recommendations'
            icon='clipboard-list'
          />
        </Col>
        <Col sm='6' lg='3'>
          <DashboardWidget
            link='/guest-cards'
            color='green'
            number={userDashboardData && userDashboardData.guests.totalCount}
            name='Total Guest Cards'
            icon='address-card'
          />
        </Col>
        <Col sm='6' lg='3'>
          <DashboardWidget
            link='/user-subscriptions'
            color='orange'
            number={
              userDashboardData &&
              userDashboardData.subscriptions.edges.length > 0
                ? formatTimeStamp(
                    userDashboardData.subscriptions.edges[0].node
                      .currentEndDatetime
                  )
                : 'Trial Account'
            }
            name={
              userDashboardData?.subscriptions?.edges?.length > 0
                ? 'Next invoice on'
                : 'Click to subscribe'
            }
            icon='receipt'
          />
        </Col>
      </Row>
    </>
  );
}
