
import { gql } from '@apollo/client';

export const NOTIFICATION_SUBSCRIPTION = gql`
	subscription notificationSubscription {
		notificationSubscription {
			message
			notifications {
				id
				title
				description
				notificationType
				isRead
				updatedAt
				createdAt
			}
		}
	}
`;

export const USERS_SUBSCRIPTION = gql`
	subscription usersSubscription {
		usersSubscription {
			message
			user {
				id
				name
				email
				phoneNo
				licenseNo
				isVa
				subscriptions {
					stripeSubscriptionId
					isTrial
					active
					plan {
						name
						interval
					}
				}
				isAdmin
				isBroker
				isAgent
				isUser
				brokerId
				approved
				city {
					name
				}
				affiliateCode
				isOnline
			}
			users {
				id
				name
				email
				phoneNo
				licenseNo
				isVa
				subscriptions {
					stripeSubscriptionId
					isTrial
					active
					plan {
						name
						interval
					}
          cities {
            id
            name
          }
				}
				isAdmin
				isBroker
				isAgent
				isUser
				brokerId
				approved
				city {
					name
				}
				affiliateCode
				isOnline
			}
		}
	}
`;

export const PROPERTIES_SUBSCRIPTIONS = gql`
	subscription propertiesSubscription (
    $cityId: Int
    $minRent: Int
    $maxRent: Int
    $availFrom: ValidString
    $availTo: ValidString
    $builtYear: ValidString
    $yearTo: ValidString
    $neighborhoodIds: [Int!]
    $escort: ValidString
    $sendEscort: ValidString
    $zip: ValidString
    $sqFeet: ValidString
    $bedroom: [ValidString!]
    $bathroom: [ValidString!]
    $search: ValidString
    $addons: [ValidString!]
    $policies: [ValidString!]
    $unitFeatures: [ValidString!]
    $bonus: ValidString
    $rating: ValidString
    $after: String
    $before: String
    $first: Int
    $last: Int
    $orderBy: [OrderBy!]
    $phone: ValidString
    $email: ValidString
    $address: ValidString
    $renovated: ValidString
    $managementCompany: ValidString
  ) {
    propertiesSubscription(
      cityId: $cityId
      minRent: $minRent
      maxRent: $maxRent
      availFrom: $availFrom
      availTo: $availTo
      builtYear: $builtYear
      yearTo: $yearTo
      neighborhoodIds: $neighborhoodIds
      escort: $escort
      sendEscort: $sendEscort
      zip: $zip
      sqFeet: $sqFeet
      bedroom: $bedroom
      bathroom: $bathroom
      search: $search
      addons: $addons
      policies: $policies
      unitFeatures: $unitFeatures
      bonus: $bonus
      rating: $rating
      after: $after
      before: $before
      first: $first
      last: $last
      orderBy: $orderBy
      onlyAvailable: false
      phone: $phone
      email: $email
      address: $address
      renovated: $renovated
      managementCompany: $managementCompany
    ) {
			edges {
				node {
          city {
            id
            name
          }
          id
          name
          email
          address
          phone
          units
          renovated
          builtYear
          googleMap
          googleRating
          imageThumbUrls
          imageUrls
          managementCompany
          managementConfirmation
          addonItems
          policyItems
          lat
          long
          googleReviewLink
          neighborhood {
            id
            name
          }
          webLink
          specials
          escort
          sendEscort
          commissionNote
          bonus
          updatedAt
          additionalNote
          schedulingNote
          virtualTourLink
          feesSheetUrl
					specialsUpdatedAt
					virtualTours
					parkingNote
					invoicingNote
          specialsAndCommissionPriority
          manualPricingFrequency
          floorPlans {
            id
            name
            bed
            bath
            plan2d
            unitFeatures
            rentMin
            rentMax
						sqft
            sqftMin
            updatedAt
            isAvailable
						availableFrom
						availableTo
            units {
              id
              aptNo
              size
              price
              addonItems
              isAvailable
              moveIn
              updatedAt
            }
          }
				}
			}
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
		totalCount
		}
	}
`;