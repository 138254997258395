import moment from "moment";

export default function filterUnits(p, getFilter) {
  let filteredUnits = [];
  let filteredFloorPlans = [];
  let finalFloorPlans = [];
	let filteredAvailFrom = getFilter('availFrom') ? convertUTCToLocal(getFilter('availFrom')).slice(0,10) : null;
	let filteredAvailTo = getFilter('availTo') ? convertUTCToLocal(getFilter('availTo')).slice(0,10) : null;
	let minSqft = getFilter('sqFt').length > 0 ? Number(getFilter('sqFt')) : null
  const bathFilter = Number(JSON.parse(getFilter('bath'))?.value);

  filteredFloorPlans = p.floorPlans.filter((fp) => {
    const bedOK =
      getFilter('bed') && getFilter('bed').length > 0
        ? getFilter('bed').includes(fp.bed)
        : true;
    const bathOK = bathFilter > 0 ? Number(fp.bath) >= bathFilter : true;
    return bedOK && bathOK;
  });
	var onlyAvailableFloorPlans = [];
	filteredFloorPlans.forEach((fp) => {
		if (!fp.isAvailable) return;
		var availableUnits = []
		if (fp.units?.length > 0){
			availableUnits = fp.units.filter((u) => u.isAvailable);
		}
		onlyAvailableFloorPlans.push({...fp, units: availableUnits})
	});
	
	var floorPlans = [];
  finalFloorPlans = onlyAvailableFloorPlans.forEach((fp) => {
		if (fp?.units?.length > 0) {
			filteredUnits = fp.units.filter((u) => {
				return (
					// u.isAvailable &&
					(getFilter('minPrice') ? u.price >= getFilter('minPrice') : true) &&
					(getFilter('maxPrice') ? u.price <= getFilter('maxPrice') : true) &&
					(minSqft ? u.size >= minSqft : true) &&
					/*      (getFilter('availFrom') && !getFilter('availTo') && u.moveIn
								? new Date(u.moveIn) >= new Date(getFilter('availFrom'))
								: true) &&
							(getFilter('availFrom') && getFilter('availTo') && u.moveIn
								? new Date(u.moveIn) <= new Date(getFilter('availTo'))
								: true) */
					(filteredAvailFrom && u.moveIn
						? new Date(filteredAvailFrom) <= new Date() ? new Date(u.moveIn) <= new Date(filteredAvailTo) :
						new Date(u.moveIn) >= new Date(filteredAvailFrom)
						: true) &&
					(filteredAvailTo && u.moveIn
						? new Date(u.moveIn) <= new Date(filteredAvailTo)
						: true)
				);
			});
			if (filteredUnits?.length > 0) floorPlans.push({ ...fp, units: filteredUnits });
		} 
		if(fp.units.length === 0 && fp.isAvailable) {
			if (
				// fp.isAvailable &&
				(getFilter('minPrice') ? fp.rentMin && fp.rentMin >= getFilter('minPrice') : true) &&
				(getFilter('maxPrice') ? fp.rentMax && (fp.rentMax <= getFilter('maxPrice') || (fp.rentMax >= getFilter('maxPrice') && fp.rentMin <= getFilter('maxPrice'))) : true) &&
				(minSqft ? (fp.sqftMin > 0 && fp.sqftMax > 0 ? (fp.sqftMin >= minSqft || fp.sqftMax >= minSqft) : fp.sqftMin ? fp.sqftMin >= minSqft : false) : true) 
				&&
				(filteredAvailFrom && fp.availableFrom
					? new Date(filteredAvailFrom) <= new Date() 
					? true :
					new Date(fp.availableFrom) >= new Date(filteredAvailFrom) ? true : false
					: true) &&

					(filteredAvailTo && fp.availableTo
					? new Date(fp.availableTo) <= new Date(filteredAvailTo) || 
						(new Date(fp.availableFrom) <= new Date(filteredAvailTo) && new Date(fp.availableTo) >= new Date(filteredAvailTo) )
					: true)
			){
				if (fp.availableTo && fp.availableFrom && fp.availableTo === fp.availableFrom) {
					floorPlans.push({...fp, units: []});
				}
			};
		}
  });

  return { ...p, floorPlans: floorPlans };
}

function convertUTCToLocal(date) {
	if (!date) return null;
	var utcMoment = moment.utc(date);
	var localMoment = utcMoment.local();
	var localDatetimeString = localMoment.format();
	return localDatetimeString;
}

// (filteredAvailFrom && fp.availableFrom
// 	? new Date(filteredAvailFrom) <= new Date() 
// 	? true :
// 	new Date(fp.availableFrom) >= new Date(filteredAvailFrom) || 
// 		new Date(fp.availableFrom) <= new Date(filteredAvailFrom)
// 	: true) &&

// 	(filteredAvailTo && fp.availableTo
// 	? new Date(fp.availableTo) <= new Date(filteredAvailTo) || 
// 		(new Date(fp.availableFrom) <= new Date(filteredAvailTo) && new Date(fp.availableTo) >= new Date(filteredAvailTo) )
// 	: true)