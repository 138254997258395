import React, { useState, useEffect, useMemo } from 'react';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '@sparklib';
import Dropzone from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Row,
  Button,
  Col,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Icon } from '@assets';
import { PROPERTIES, UPDATE_ATTACHMENT } from '@api';
import { useMutation } from '@apollo/client';
export default function SiteMapEditor({ showEditor, setShowEditor, reloadProperties }){

	const [attachment, setAttachment] = useState();
	const [deleteFile, setDeleteFile] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

	const [updateAttachment, {}] = useMutation(UPDATE_ATTACHMENT, {
		context: { hasUpload: true },
		refetchQueries: [PROPERTIES],
		onCompleted: (data) => {
			if (data.updateAttachment.errors === null){
				resetEditor();
				toast("Site Map Updated", { type: 'success' });
			} else {
				toast("Opps! Something went wrong", { type: 'error' });
			}
			reloadProperties();
		},
		onError: (e) => {
      toast(e, { type: 'error' });
      console.log(e);
    },
	});

	function resetEditor(){
		setShowEditor({show: false, property: null});
		setAttachment(null);
		setDeleteFile(false);
	}

	const onSaveAttachment = (data, e) => {
		if (attachment && deleteFile){
			updateAttachment({
				variables: {
					id: Number(showEditor.property.id),
					name: 'site map',
					attachable: attachment[0],
					isRemove: deleteFile
				}
			})
		} else if (attachment) {
			updateAttachment({
				variables: {
					id: Number(showEditor.property.id),
					name: 'site map',
					attachable: attachment[0]
				}
			})
		} else if (deleteFile) {
			updateAttachment({
				variables: {
					id: Number(showEditor.property.id),
					name: 'site map',
					isRemove: deleteFile
				}
			})
		} else toast("No Changes Found!", { type: 'error' });
	};

  const onError = (errors, e) => console.log(errors, e);

	function fileName(){
		if (attachment){
			return attachment[0].path.replace(/(.{10})..+/, "$1…")
		} else if(showEditor.property?.siteMapUrl && !deleteFile){
			return <a href={showEditor.property.siteMapUrl} target='_blank'>{showEditor.property.siteMapUrl.split('/').pop().split('?')[0].split('#')[0].replaceAll("%20", " ").replace(/(.{10})..+/, "$1…")}</a>
		} else return null
	}

	return (
		<Modal
			className='modal-dialog-centered'
			isOpen={showEditor.show}
			toggle={(event) => {
				if (event.key === 'Escape') {
					attachment(null);
					setShowEditor(false);
				}
			}}
		>
			<Form className='form' onSubmit={handleSubmit(onSaveAttachment, onError)}>
				<ModalHeader>Update Site Map For {showEditor.property?.name}</ModalHeader>
				<ModalBody>
					<Row>
						{
							fileName() && 
							<Col md='4' className='mt-3'>
								<div className='fees-sheet-box'>
									<p>{fileName()}</p>
								</div>
								{
									!deleteFile && showEditor.property?.siteMapUrl && !attachment && 
									<Icon
									icon='times'
									className='remove-item'
									onClick={() => setDeleteFile(true)}
									/>
								}
							</Col>
						}
						<Col className='mt-3'>
							<Dropzone
								name='feesSheetDropZone'
								// accept='image/jpeg, image/png'
								maxFiles={1}
								multiple={false}
								onDrop={(acceptedFile) => {
									setAttachment(acceptedFile);
								}}
							>
								{({
									getRootProps,
									getInputProps,
									isDragActive,
									isDragAccept,
									isDragReject,
									acceptedFile,
								}) => {
									const style = useMemo(
										() => ({
											...baseStyle,
											...(isDragActive ? activeStyle : {}),
											...(isDragAccept ? acceptStyle : {}),
											...(isDragReject ? rejectStyle : {}),
										}),
										[isDragActive, isDragReject, isDragAccept]
									);
									return (
										<span className='flex-grow'>
											<div {...getRootProps({ style })}>
												<input
													ref={register({})}
													name='images'
													{...getInputProps()}
												/>
												<div className='text-center w-100'>
													Drag 'n drop the site map here,
													<br />
													or click to select the site map
													<br />
													<small>
														<em>(any type of file supported)</em>
													</small>
												</div>
											</div>
										</span>
									);
								}}
							</Dropzone>
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button
						color='blue'
						// onClick={(e) => {
						//   onSaveImages(e);
						//   setShowPhotoEditor({ show: false, property: null });
						//   setPropertyPhotos([]);
						// }}
						type='submit'
					>
						Save File
					</Button>
					<Button onClick={resetEditor}>Cancel</Button>
				</ModalFooter>
			</Form>
		</Modal>
	)

}