import React, { useEffect, useState } from 'react';
import { Col, Row, InputGroup, InputGroupText, Input } from 'reactstrap';
import Nouislider from 'nouislider-react';
import { Icon } from '@assets';

export default function Price({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  const numberFormatter = new Intl.NumberFormat('en-US');
  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'price' && 'hidden'
      }`}
    >
      <div className='filter-box' style={style}>
        <Row>
          <Col md='12' className='mb-3'>
            <h6 className='mr-1' style={{ display: 'inline-block' }}>
              Price
            </h6>
            {!getFilter('minPrice') && !getFilter('maxPrice') && '- Any'}
            <div className='px-2 py-2 mt-2 mb-1 w-100'>
              <Nouislider
                connect={true}
                tooltips={true}
                animate={true}
                // handleAttributes={[
                //   { class: 'noUi-handle-upper', 'aria-label': 'upper' },
                //   { class: 'noUi-handle-lower', 'aria-label': 'lower' },
                // ]}
                start={[
                  getFilter('minPrice') || 0,
                  getFilter('maxPrice') || 10000,
                ]}
                behaviour='tap'
                range={{
                  min: [0, 100],
                  '5%': [100, 100],
                  '50%': [2600, 200],
                  '75%': [5000, 500],
                  // '90%': [9500, 500],
                  max: [10000],
                }}
                /* pips={{
                  mode: 'values',
                  values: [500, 1500, 3000, 5000, 7500, 10000],
                  density: 10,
                }} */
                onChange={(e) => {
                  // (e) => console.log(e)
                  addFilter({
                    e: {
                      target: {
                        name: 'minPrice',
                        value: e[0] < 100 ? '' : e[0],
                      },
                    },
                  });
                  addFilter({
                    e: {
                      target: {
                        name: 'maxPrice',
                        value: e[1] > 9500 ? '' : e[1],
                      },
                    },
                  });
                }}
                format={{
                  to: function (value) {
                    return value === undefined ? '' : value.toFixed(0);
                  },
                  from: Number,

                  // // 'to' the formatted value. Receives a number.
                  // to: function (value) {
                  //   return Math.round(value);
                  // },
                  // // 'from' the formatted value.
                  // // Receives a string, should return a number.
                  // from: function (value) {
                  //   // return Number(value.replace(',-', ''));
                  //   return Math.round(value);
                  // }
                }}
              />
            </div>
          </Col>
          <Col xs='6' className='inputs-icons'>
            <InputGroup>
              <InputGroupText>
                <Icon icon='dollar-sign' />
              </InputGroupText>
              <Input
                type='text'
                placeholder='Min Price'
                min='0'
                name='minPrice'
                // style={{ width: '45%' }}
                /* value={currencyFormatter
                  .format(getFilter('minPrice'))
                  .replace(/\D00$/, '')} */
                value={
                  getFilter('minPrice')
                    ? numberFormatter.format(getFilter('minPrice'))
                    : ''
                }
                onChange={(e) => {
                  var minPriceValue = Number(e.target.value?.replace(/,/g, ''));
                  if (minPriceValue >= 0 || minPriceValue === '') {
                    addFilter({
                      e: {
                        target: {
                          value: minPriceValue,
                          name: 'minPrice',
                        },
                      },
                    });
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col xs='6' className='inputs-icons'>
            <InputGroup>
              <InputGroupText>
                <Icon icon='dollar-sign' />
              </InputGroupText>
              <Input
                type='text'
                min='0'
                placeholder='Max Price'
                name='maxPrice'
                // style={{ width: '45%' }}
                value={
                  getFilter('maxPrice')
                    ? numberFormatter.format(getFilter('maxPrice'))
                    : ''
                }
                onChange={(e) => {
                  var maxPriceValue = Number(e.target.value?.replace(/,/g, ''));
                  if (maxPriceValue >= 0 || maxPriceValue === '') {
                    addFilter({
                      e: {
                        target: {
                          value: maxPriceValue,
                          name: 'maxPrice',
                        },
                      },
                    });
                  }
                }}
              />
            </InputGroup>
          </Col>
        </Row>
      </div>
    </div>
  );
}
