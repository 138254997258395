import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PROPERTY } from '@api';
import { Col } from 'reactstrap';
import SliderForMap from '../../../../components/SliderForMap';
import { filterFloorPlans } from '@sparklib';
import { Icon } from '@assets';
import ClientRating from '../../../Recommendation/ClientRating';

const MarkerInfo = (props) => {
  const { loading, data: propertyData } = useQuery(PROPERTY, {
    variables: {
      id: String(props.property.slug),
    },
    skip: !props.property.id || (props.property.id && props.property.imageUrls),
    onCompleted: (data) => {
      props.setProperty(filterFloorPlans(data.property, props.getFilter));
    },
  });
  const history = useHistory();
  const [propertyImages, setPropertyImages] = useState([]);

  useEffect(() => {
    if (props.property.imageUrls) {
      setPropertyImages(
        props.property.imageUrls?.map((image, index) => ({
          key: index,
          src: image,
          altText: props.property.name,
        }))
      );
    } else {
      setPropertyImages(
        propertyData?.property.imageUrls?.map((image, index) => ({
          key: index,
          src: image,
          altText: props.property.name,
        }))
      );
    }
  }, [props.property.imageUrls, propertyData]);
  return (
    <>
      <div
        className='balloon scrollWindow'
        onClick={() => {
          if (props.setSidebarState) {
            props.setSidebarState(true);
            history.push(`/${props.path}/${props.property.city.slug}/${props.property.neighborhood.slug}/${props.property.slug}`);
          }
          props.showPropertyDetail && props.showPropertyDetail(props.property);
        }}
      >
        <Col md='12' className='p-0' style={{ width: '274px !important' }}>
          <div className='card-img sliderWidth position-relative'>
            {loading ? (
              <div className='no-image d-flex align-items-center text-center'>
                Loading...
              </div>
            ) : (
              <>
                <SliderForMap items={propertyImages} className='' />
              </>
            )}
          </div>
          <div className='pt-2 pl-3 pb-3 pr-3'>
            <div className='infobox-title'>{props.property.name}</div>
            <div className='infobox-text'>
              {props.property.neighborhood?.name}
            </div>
          </div>
					<div>
						{props.reportId && (
							<div className='map-property-card'>
								<div
									onClick={(e) => {
										props.setCurrentId(null);
										e.stopPropagation();
									}}
									style={{ cursor: 'pointer' }}
								>
									<Icon icon='times' />
								</div>
								<div className='pl-0'>
									<ClientRating
										reportId={props.reportId}
										reportCode={props.reportCode}
										propertyId={props.property.id}
										rating={props.rating}
										notes={props.notes}
										isReportMap={props.isReportMap}
										onClick={(e) => {
											e.stopPropagation();
										}}
									/>
								</div>
							</div>
						)}
						{props.selectedPropertyArray && (
							<div className='map-property-card'>
								<div
									onClick={(e) => {
										props.setCurrentId(null);
										e.stopPropagation();
									}}
									style={{ cursor: 'pointer' }}
								>
									<Icon icon='times' />
								</div>
								{
									<div
										onClick={(e) => {
											e.stopPropagation();
											props.toggleSelection(
												propertyData
													? propertyData.property
													: props.property
											);
										}}
										style={{ cursor: 'pointer' }}
									>
										{props.selectedPropertyArray.find(
											(p) => p.node.id === props.property.id
										) ? (
											<Icon icon='heart' />
										) : (
											<Icon icon={['far', 'heart']} />
										)}
									</div>
								}
							</div>
						)}
					</div>
        </Col>
      </div>
    </>
  );
};

export default MarkerInfo;
