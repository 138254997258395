import React, { useEffect } from 'react';
import { Table, Row, Col, FormGroup, Label } from 'reactstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from 'react-table';

import Select from 'react-select';

import { Icon } from '@assets';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type='checkbox'
          className='custom-checkbox'
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className='search-bar d-inline-block inline-block'>
      <FormGroup className='mb-0 w-auto d-inline-block'>
        <Label for='appartment' className='mb-0 w-auto d-inline-block mr-2'>
          <Icon icon='search' />
        </Label>
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder='Search Property'
        />
      </FormGroup>
    </div>
  );
}

export default function RTable({ columns, data, addons, changeIsChecked, leftAddons=null }) {
  var totalRecord = data.length;
  const pageSizeOptions = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
    { value: totalRecord.toString(), label: 'All' },
  ];
  const defaultColumn = React.useMemo(
    () => ({
      width: 'auto',
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    rows,
    prepareRow,
    state: { globalFilter, selectedRowIds },
  } = useTable(
    { columns, data },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        changeIsChecked
          ? [
              // Let's make a column for selection
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <IndeterminateCheckbox
                    {...getToggleAllRowsSelectedProps()}
                  />
                ),
                Cell: ({ row }) => (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                ),
                width: 20,
              },
              ...columns,
            ]
          : columns
      );
    }
  );

  useEffect(() => {
    if (changeIsChecked) {
      changeIsChecked(selectedFlatRows.map(r => Number(r.original.id)));
    }
  }, [selectedFlatRows.length]);

  return (
    <>
      <Row className='my-4 d-flex align-items-center'>
        {/*
          <Col sm='3'>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </Col>
        */}

        <Col sm='4'>
          {leftAddons}
        </Col>

        <Col sm='8' className='text-lg-right'>
          {addons}
        </Col>
      </Row>

      <Table
        responsive
        className='client-list text-left min-w-900'
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    },
                    column.getSortByToggleProps(),
                  ])}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <>
                          &nbsp; <Icon icon='sort-down' size='lg' />
                        </>
                      ) : (
                        <>
                          &nbsp; <Icon icon='sort-up' size='lg' />
                        </>
                      )
                    ) : column.canSort ? (
                      <>
                        &nbsp; <Icon icon='sort' size='lg' />
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} onClick={(e) => e.stopPropagation()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} onClick={(e) => e.stopPropagation()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}
