import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../Spinner';
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
	Badge,
} from 'reactstrap';
import { NOTIFICATIONS, NOTIFICATION_MULTI_DELETE } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import RTable from '../../RTable';
import SidebarRight from '../../SidebarRight';
import AddNotification from './AddNotification';
import EditNotification from './EditNotification';
import { Icon } from '@assets';

export default function Notification(props) {
  const history = useHistory();
  const [notificationList, setNotificationList] = useState('loading');
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [notifications, setNotification] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const {
    data: notificationsData,
    loading: notificationLoading,
    error: notificationError,
    refetch: notificationRefetch,
  } = useQuery(NOTIFICATIONS, {
    onCompleted: (data) => {
      setNotification(data.notifications.edges);
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const addNotification = () => {
    setSidebarState(true);
  };

  useEffect(() => {
    if (notificationsData) {
      if (notificationsData.notifications.totalCount === 0) {
        setNotificationList('noData');
      } else {
        setNotificationList('data');
        setNotification(notificationsData.notifications.edges);
      }
    }
  }, [notificationsData]);

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

  useEffect(() => {
    if (refetch === true) {
      notificationRefetch();
      setRefetch(false);
    }
  }, [refetch]);

  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);
  useEffect(() => {
    sidebarState
      ? !id && history.push(`/notifications/new`)
      : history.push(`/notifications`);
  }, [sidebarState, operation]);

  const [notificationMultiDelete] = useMutation(NOTIFICATION_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.notificationMultiDelete.message, { type: 'success' });
      setIsProgress(false);
    },
    refetchQueries: [NOTIFICATIONS],
    onError: (e) => {
      console.log(e);
    },
  });

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  const handleDeleteAll = async () => {
    setIsProgress(true);
    await notificationMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'node.title',
      },
      {
        Header: 'Description',
        accessor: 'node.description',
      },
      {
        Header: 'Type',
        accessor: 'node.notificationType',
				Cell: ({value}) => <h5><Badge color={value} pill>{_.capitalize(value)}</Badge></h5>
      },
      {
        Header: 'Date',
        accessor: 'node.createdAt',
        Cell: ({ value, row }) => <div>{formatDate(value)}</div>,
      },
      {
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSidebarState(true);
                    history.push(`/notifications/edit/${value}`);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => handleSingleDelete(value)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {notificationLoading && <Spinner />}
      {operation === 'edit' ? (
        <SidebarRight
          sidebarWidth='350px'
          sidebarState={sidebarState}
          sidebarToggle={() => {
            setSidebarState(!sidebarState);
          }}
          sidebarContent={
            <EditNotification
              sidebarToggle={() => {
                setSidebarState(!sidebarState);
              }}
              shouldRefetch={() => setRefetch(true)}
              {...props}
            />
          }
        />
      ) : (
        <SidebarRight
          sidebarWidth='350px'
          sidebarState={sidebarState}
          sidebarToggle={() => {
            setSidebarState(!sidebarState);
          }}
          sidebarContent={
            <AddNotification
              sidebarToggle={() => {
                setSidebarState(!sidebarState);
              }}
              shouldRefetch={() => setRefetch(true)}
              {...props}
            />
          }
        />
      )}
      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} notification
          {isCheckedId.length > 1 ? 's' : ''}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Row className='mt-md-4'>
        <Col md='12'>
          {notificationList === 'data' ? (
            <RTable
              columns={columns}
              data={notifications}
              filterable
              addons={
                <Button
                  color='red'
                  onClick={onAllDeletePopup}
                  disabled={isCheckedId.length > 0 ? false : true}
                  className='mr-5 button-dashboard float-right'
                >
                  <Icon icon='trash-alt' className='mr-2' />
                  Delete
                </Button>
              }
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
            />
          ) : (
            <>
              {notificationList === 'noData' ? (
                <div className='text-center' style={{ margin: '35vh 0' }}>
                  <h4>You haven't sent any notifications.</h4>
                  <Button
                    onClick={addNotification}
                    color='success'
                    className='button-dashboard mt-2'
                  >
                    Send a notification{' '}
                    <Icon icon='chevron-right' className='ml-2' />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
