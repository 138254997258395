import React, { useState, useEffect } from 'react';
import MarkerInfo from './MarkerInfo';

const PlaceMarker = ({
  tooltip,
  property,
  currentId,
  showTooltip,
  setCurrentId,
  setShowTooltip,
  setIsViewed,
  isViewed,
  currentHoverId,
  setSidebarState,
  setProperty,
  path,
  is_show,
  showPropertyDetail,
  getFilter,
  toggleSelection,
  selectedPropertyArray,
  reportId,
  reportCode,
  rating,
  notes,
  isReportMap,
}) => {
  const result = isViewed?.find((isViewedId) => isViewedId === property.id);
  const handleClick = (e) => {
    e.stopPropagation();
    if (is_show) {
      setShowTooltip(true);
    }
    //console.log(property.id, 'property.id');
    setCurrentId(property.id);
    if (result === undefined) {
      setIsViewed([...isViewed, property.id]);
    }
  };

  function checkViewedProperty(id) {
    if (result) {
      return true;
    }
  }
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    /* style: 'currency', */
    currency: 'USD',
    maximumFractionDigits: 0,
  });

  function getMinFloorPrice(floorPlans) {
    let filter_arr = floorPlans?.filter(fp => fp.isAvailable && fp.rentMin && fp.rentMin > 0);
    if (filter_arr?.length > 0) {
      var allPrice = [];
      filter_arr.forEach((element) => {
        allPrice.push(element.rentMin);
      });

      let minval = _.minBy(filter_arr, 'rentMin');
      if (allPrice.every((val, i, arr) => val === arr[0])) {
        return '$' + currencyFormatter.format(minval.rentMin);
      } else {
        return '$' + currencyFormatter.format(minval.rentMin) + '+';
      }
    } else {
      return '-';
    }
  }
  return (
    <>
      <div>
        <div
          className={`map-price-marker ${
            //showTooltip &&
            (currentId === property?.id || currentHoverId === property?.id) &&
            'map-price-marker-active'
          } ${
            (currentId !== property?.id &&
              checkViewedProperty(property?.id)) === true && 'visitedIcon'
          }`}
          onClick={(e) => handleClick(e)}
        >
          <span className='circleText map-price-box' title={tooltip}>
            {getMinFloorPrice(property.floorPlans)}
          </span>
        </div>
        {showTooltip && currentId === property.id && (
          <MarkerInfo
            property={property}
            setSidebarState={setSidebarState}
            setProperty={setProperty}
            path={path}
            showPropertyDetail={showPropertyDetail}
            getFilter={getFilter}
            toggleSelection={toggleSelection}
            selectedPropertyArray={selectedPropertyArray}
            setCurrentId={setCurrentId}
            reportId={reportId}
            reportCode={reportCode}
            rating={rating}
            notes={notes}
            isReportMap={isReportMap}
          />
        )}
      </div>
    </>
  );
};

export default PlaceMarker;
