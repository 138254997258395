import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ALL_CITIES, NEIGHBORHOODS, PUBLIC_USER, CREATE_CLIENT_INQUIRY } from '@api'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
	Label,
	InputGroup,
	InputGroupText,
	Card,
	CardBody
} from 'reactstrap';
import { Icon } from '@assets';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import _ from 'lodash/fp'
import __ from 'lodash'
import Nouislider from 'nouislider-react';
import DatePicker from 'react-datepicker';
import { unitTypes, defaultAmenities } from '@sparklib';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { justFomatePhoneNo } from '../components/shared/sparkLib/phoneNumberFormatter'
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from '@config';
import AgentDetailsPage from '../modules/Recommendation/AgentDetailsPage';
import FormWizard from "react-form-wizard-component";
import 'react-form-wizard-component/dist/style.css';
import '../assets/styles/Inquiry.css';

export default function NewInquiry(props) {
	const { agentName } = useParams()
	const history = useHistory()
	const numberFormatter = new Intl.NumberFormat('en-US');
	const [formErrors, setFormErrors] = useState({
		city: false,
		neighborhoods: false,
		moveIn: false,
		bed: false,
		budget: false,
	});

	const initialData = {
		email: '',
    phone: '',
    firstName: '',
    lastName: '',
		neighborhoods: [],
    neighborhood: '',
    availFrom: null,
    availTo: null,
    minRent: null,
    maxRent: null,
    bed: [],
    city: { label: '', value: '' },
	}
	const [captchaToken, setCaptchaToken] = useState(null);
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [progress, setProgress] = useState(false)
	const [data, setData] = useState(initialData)
	const [agent, setAgent] = useState(null)
	const [neighborhoodData, setNeighborhoodData] = useState([])

	const { data: citiesData } = useQuery(ALL_CITIES, {
		variables: { urlName: agentName},
    onError: (e) => {
      console.log(e, 'error');
    },
  });

	useQuery(PUBLIC_USER, {
		variables: { urlName: agentName},
		onCompleted: (data) => {
			if (data && data.publicUser) setAgent(data.publicUser)
			else {
				history.push("/")
				toast.error("Agent not found")
			}
		}
	});

	const [createClientInquiry] = useMutation(CREATE_CLIENT_INQUIRY, {
		onCompleted: (data) => {
			var result = data.createClientInquiry
			if (result?.clientInquiry) {
				toast.success(result.message)
				setFormSubmitted(true)
				window.scrollTo(0,0)
			}
			else if (result?.message) toast.error(result.message)
			else toast.error("Oops, something went wrong!")
			setProgress(false)
		}
	})

	const [fetchNeighborhoods] = useLazyQuery(NEIGHBORHOODS, {
		onCompleted: (data) => {
			setNeighborhoodData(data.neighborhoods)
		}
  });

	const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

	function onSubmit(values) {
		if (isError()){
			toast.error("Please provide required information")
		} else {
			setProgress(true)
			handleClientInquiry()
		}
	}

	const changeData = ({ e, key = null }) => {
    if (e.target) {
      const { name, value } = e.target;
      setData((data) => ({ ...data, [name]: value || '' }));
    } else {
      e.constructor === Array
        ? setData((data) => ({ ...data, [key]: e }))
        : setData((data) => ({ ...data, [key]: e }))
    }
  };

	function isError(){
		const dataKeys = Object.keys(data)
		const errors = formErrors;
		dataKeys.forEach(key => {
			if (formErrors.hasOwnProperty(key)){
				if (__.isEqual(data[key], initialData[key])){
					errors[key] = true;
				} else {
					errors[key] = false
				}
			} 
		})
		errors.budget = data.minRent || data.maxRent ? false : true;
		errors.moveIn = data.availFrom && data.availTo ? false : true;
		setFormErrors(errors)
		return Object.values(errors).includes(true);
	}

	const onChange = (dates) => {
    const [start, end] = dates;
    changeData({
      e: {
        target: { name: 'availFrom', value: start },
      },
    });
    changeData({
      e: {
        target: { name: 'availTo', value: end },
      },
    });
  };

	useEffect(() => {
		let cityId = data?.city?.value 
		if (Number(cityId) > 0){
			fetchNeighborhoods({
				variables: {
					cityId: Number(cityId)
				}
			})
		}
	}, [data.city])

	function filteredPhoneNo(phoneNo){
		let phone = data.phone?.match(/\d+/g)?.join("")?.substr(-10)
		if (phone){
			return justFomatePhoneNo(phone)
		}
		return ''
	}

	function handleClientInquiry(){
		let phoneNo = filteredPhoneNo(data.phone)
		createClientInquiry({
			variables: {
				urlName: agentName, 
				firstName: data.firstName, 
				lastName: data.lastName, 
				email: data.email, 
				phone: phoneNo, 
				cityId: Number(data.city.value),
				minRent: Number(data.minRent), 
				maxRent: Number(data.maxRent), 
				availFrom: data.availFrom, 
				availTo: data.availTo || null, 
				bedroom: data.bed, 
				neighborhoodIds: data.neighborhoods.map(n => Number(n.value)), 
				neighborhood: data.neighborhood,
			}
		});
	}

	// useEffect(() => {
	// 	const inputElement = document.getElementById('phoneNumber');
	// 	if (inputElement != null) {
	// 		inputElement.addEventListener('keydown',enforceFormat);
	// 		inputElement.addEventListener('keyup',formatToPhone);
	// 	} 
	// }, [])

	const handleComplete = () => {
    // console.log("Form completed!");
  };

  const handleTabChange = ({prevIndex, nextIndex}) => {
    // console.log("prevIndex", prevIndex);
    // console.log("nextIndex", nextIndex);
  };

	const customTitleTemplate = () => {
    return (
      <>
        <h2
					className='text-primary'
        >
          Apartment Inquiry
        </h2>
				{agent && <h6>Your agent is {agent.name}</h6>}
      </>
    );
  };

	const checkValidateMarketTab = () => {
    if (data.city.value === "") {
      return false;
    }
    return true;
  };

	const marketErrorMessages = () => {
    toast.error("Please select market");
  };

	const checkValidateNeighborhoodTab = () => {
    if (data.neighborhoods.length == 0) {
      return false;
    }
    return true;
  };

	const neighborhoodErrorMessages = () => {
    toast.error("Neighborhood is required");
  };

	const checkValidateBedTab = () => {
    if (data.bed.length === 0) {
      return false;
    }
    return true;
  };

	const bedErrorMessages = () => {
    toast.error("Please select bedroom");
  };

	const checkValidateRentTab = () => {
    if (data.minRent || data.maxRent) {
      return true;
    }
    return false;
  };

	const rentErrorMessages = () => {
    toast.error("Budget is required");
  };

	const checkValidateMoveInTab = () => {
    if (data.availFrom && data.availTo) {
      return true;
    }
    return false;
  };

	const moveInErrorMessages = () => {
    toast.error("Move-in Date Range required");
  };

  return (
    <>
			<Container fluid className='my-3'>
				<Row>
					<Col md='6'>
						<GoogleReCaptchaProvider reCaptchaKey={config.CAPTCHA_SITE_KEY}>
							{ !formSubmitted ?
								<>
									<Card>
										<FormWizard
											shape="circle"
											stepSize="md"
											color="#007bff"
											onComplete={handleSubmit(onSubmit)}
											onTabChange={handleTabChange}
											title={customTitleTemplate()}
											finishButtonText="Submit"
											// finishButtonTemplate={(handleComplete) => (
											// 	<ReCaptcha progress={progress} setToken={setCaptchaToken} />
											// )}
										>
											<FormWizard.TabContent title="Market Details" icon="fa fa-city">
												<Row className='justify-content-md-center mt-3'>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='city'>Select Market</Label>
															<Select
																name='city'
																options={
																	typeof citiesData !== 'undefined'
																		? Object.values(citiesData.allCities).map((city) =>
																				JSON.parse(
																					`{"value":${city.id}, "label":"${city.name}"}`
																				)
																			)
																		: []
																}
																value={data.city}
																onChange={(e) => {
																	setData({ ...data, neighborhoods: [] });
																	changeData({ e, key: 'city' });
																}}
																className='w-100'
																classNamePrefix='default-select'
																id='city'
																isSearchable={true}											
															/>
														</FormGroup>
														{formErrors.city && (
															<p className='input-error'>* Market required.</p>
														)}
													</Col>
												</Row>
											</FormWizard.TabContent>
											<FormWizard.TabContent title="Neighborhood Details" icon="fa fa-building" isValid={checkValidateMarketTab()} validationError={marketErrorMessages}>
												<Row className='justify-content-md-center mt-3'>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='neighborhoods'>Neighborhoods</Label>
															<Select
																name='neighborhoods'
																isMulti
																value={data.neighborhoods}
																options={
																	neighborhoodData.length > 0
																		? neighborhoodData.map(
																				(neighborhood) =>
																					JSON.parse(
																						`{"value":"${neighborhood.id}", "label":"${neighborhood.name}"}`
																					)
																			)
																		: []
																}
																onChange={(e) =>
																	changeData({ e, key: 'neighborhoods' })
																}
																className='basic-multi-select w-100'
																classNamePrefix='default-select'
																placeholder='Neighborhood'
																menuPlacement={'auto'}
																styles={{
																	menuPortal: (base) => ({
																		...base,
																		zIndex: 9999,
																	}),
																}}
																menuPortalTarget={document.body}
															/>
														</FormGroup>
														{formErrors.neighborhoods && (
																<p className='input-error'>* Neighborhood required.</p>
														)}
													</Col>
												</Row>
												<Row className='justify-content-md-center'>
													<Col md='auto'>
														<FormGroup>
															<Label for='neighborhood'>Additional Neighborhood Info</Label>
															<InputGroup>
																<Input
																	type='text'
																	name='neighborhood'
																	value={data.neighborhood}
																	onChange={(e) => changeData({ e })}
																/>
															</InputGroup>
														</FormGroup>
													</Col>
												</Row>
											</FormWizard.TabContent>
											<FormWizard.TabContent title="Bedroom Details" icon="fa fa-bed" isValid={checkValidateNeighborhoodTab()} validationError={neighborhoodErrorMessages}>
												<Row className='justify-content-md-center mt-3'>
													<Col md='auto'>
														<Card className='mt-2 text-left rounded-small'>
															<CardBody className='px-4 required'>
																<label htmlFor=''>Bedroom</label>
																<Row className='mb-2'>
																	{Object.keys(unitTypes).map((typecode, index) => (
																		<Col md='4' key={index}>
																			<Label className=''>
																				<input
																					className='custom-checkbox d-inline-block w-auto mr-1'
																					type='checkbox'
																					name='bed'
																					value={typecode}
																					onChange={(e) => {
																						if (e.target.checked) {
																							changeData({
																								e: [...(data.bed || []), typecode],
																								key: 'bed',
																							});
																						} else {
																							changeData({
																								e: data.bed.filter(
																									(a) => a !== typecode
																								),
																								key: 'bed',
																							});
																						}
																					}}
																					checked={
																						data.bed?.find((a) => a === typecode) ||
																						false
																					}
																				/>{' '}
																				{unitTypes[typecode]}
																			</Label>
																		</Col>
																	))}
																</Row>
															</CardBody>
														</Card>
														{formErrors.bed && (
															<p className='input-error'>* Bed required.</p>
														)}
													</Col>
												</Row>
											</FormWizard.TabContent>
											<FormWizard.TabContent title="Price Details" icon="fa fa-dollar-sign" isValid={checkValidateBedTab()} validationError={bedErrorMessages}>
												<Row className='justify-content-md-center mt-3'>
													<Col md='auto'>
														<Card className='mt-2 text-left rounded-small'>
															<CardBody className='px-4 py-3 required'>
																{/* <CardTitle>Card title</CardTitle> */}
																<label htmlFor=''>Price</label>
																<div className='px-2 py-2 mt-2 mb-1 w-100'>
																	<Nouislider
																		connect={true}
																		tooltips={true}
																		animate={true}
																		start={[
																			0, 10000
																		]}
																		behaviour='tap'
																		range={{
																			min: [0, 100],
																			'5%': [100, 100],
																			'50%': [2600, 200],
																			'75%': [5000, 500],
																			// '90%': [9500, 500],
																			max: [10000],
																		}}
																		onChange={(e) => {
																			changeData({
																				e: {
																					target: {
																						name: 'minRent',
																						value: e[0] < 100 ? '' : e[0],
																					},
																				},
																			});
																			changeData({
																				e: {
																					target: {
																						name: 'maxRent',
																						value: e[1] > 9500 ? '' : e[1],
																					},
																				},
																			});
																		}}
																		format={{
																			to: function (value) {
																				return value === undefined
																					? ''
																					: value.toFixed(0);
																			},
																			from: Number,
																		}}
																	/>
																</div>
																<Row className='mt-2'>
																	<Col md='6'>
																		<InputGroup>
																			<InputGroupText>
																				<Icon icon='dollar-sign' />
																			</InputGroupText>
																			<Input
																				type='text'
																				placeholder='Min Price'
																				min='0'
																				name='minRent'
																				value={
																					data.minRent
																						? numberFormatter.format(data.minRent)
																						: ''
																				}
																				onChange={(e) => {
																					var minPriceValue = Number(
																						e.target.value?.replace(/,/g, '')
																					);
																					if (
																						minPriceValue >= 0 ||
																						minPriceValue === ''
																					) {
																						changeData({
																							e: {
																								target: {
																									value: minPriceValue,
																									name: 'minRent',
																								},
																							},
																						});
																					}
																				}}
																			/>
																		</InputGroup>
																	</Col>
																	<Col md='6'>
																		<InputGroup>
																			<InputGroupText>
																				<Icon icon='dollar-sign' />
																			</InputGroupText>
																			<Input
																				type='text'
																				min='0'
																				placeholder='Max Price'
																				name='maxRent'
																				value={
																					data.maxRent
																						? numberFormatter.format(data.maxRent)
																						: ''
																				}
																				onChange={(e) => {
																					var maxPriceValue = Number(
																						e.target.value?.replace(/,/g, '')
																					);
																					if (
																						maxPriceValue >= 0 ||
																						maxPriceValue === ''
																					) {
																						changeData({
																							e: {
																								target: {
																									value: maxPriceValue,
																									name: 'maxRent',
																								},
																							},
																						});
																					}
																				}}
																			/>
																		</InputGroup>
																	</Col>
																</Row>
															</CardBody>
														</Card>
														{formErrors.budget && (
															<p className='input-error'>* Budget required.</p>
														)}
													</Col>
												</Row>
											</FormWizard.TabContent>
											<FormWizard.TabContent title="Move In Date Details" icon="fa fa-calendar" isValid={checkValidateRentTab()} validationError={rentErrorMessages}>
												<Row className='justify-content-md-center mt-3'>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='moveInDate'>Move-in Date</Label>
															<Controller
																name='moveInDate'
																control={control}
																render={() => (
																	<DatePicker
																		className='form-control'
																		selectsRange
																		selected={null}
																		startDate={data.availFrom}
																		endDate={data.availTo}
																		placeholderText='Select Earliest - Latest Move Date Range'
																		value={new Date()}
																		onChange={onChange}
																		minDate={new Date()}
																		dateFormat='MMMM d, yyyy'
																		autoComplete='off'
																		popperPlacement="top"
																	/>
																)}
															/>
														</FormGroup>
														{formErrors.moveIn && (
															<p className='input-error'>* Move-in Date Range required.</p>
														)}
													</Col>
												</Row>
											</FormWizard.TabContent>
											<FormWizard.TabContent title="Personal Details" icon="fa fa-user" isValid={checkValidateMoveInTab()} validationError={moveInErrorMessages}>
												<Row className='justify-content-md-center mt-3'>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='firstName'>First Name</Label>
															<InputGroup>
																<InputGroupText>
																	<Icon icon='user' />
																</InputGroupText>
																<Input
																	type='text'
																	name='firstName'
																	value={data.firstName}
																	onChange={(e) => changeData({ e })}
																	innerRef={register({
																		required: true,
																	})}
																/>
															</InputGroup>
															{_.get('firstName.type', errors) === 'required' && (
																<p className='input-error'>* First Name required.</p>
															)}
														</FormGroup>
													</Col>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='lastName'>Last Name</Label>
															<InputGroup>
																<InputGroupText>
																	<Icon icon='user' />
																</InputGroupText>
																<Input
																	type='text'
																	defaultValue={data.lastName}
																	name='lastName'
																	autoComplete='off'
																	onChange={(e) => changeData({ e })}
																	innerRef={register({
																		required: true,
																	})}
																/>
															</InputGroup>
															{_.get('lastName.type', errors) === 'required' && (
																<p className='input-error'>* Last Name required.</p>
															)}
														</FormGroup>
													</Col>
												</Row>
												<Row className='justify-content-md-center'>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='clientEmail'>Email</Label>
															<InputGroup>
																<InputGroupText>
																	<Icon icon='envelope' />
																</InputGroupText>
																<Input
																	type='text'
																	name='email'
																	onChange={(e) => changeData({ e })}
																	value={data.email}
																	innerRef={register({
																		required: true,
																		pattern:
																			/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
																	})}
																/>
															</InputGroup>
															{_.get('email.type', errors) === 'required' && (
																<p className='input-error'>* Email required.</p>
															)}
															{_.get('email.type', errors) === 'pattern' && (
																<p className='input-error'>* Invalid Email.</p>
															)}
														</FormGroup>
													</Col>
													<Col md='auto'>
														<FormGroup className='required'>
															<Label for='clientPhone'>Phone</Label>
															<InputGroup>
																<InputGroupText>
																	<Icon icon='phone-alt' />
																</InputGroupText>
																<Input
																	id="phoneNumber"
																	type='text'
																	name='phone'
																	value={data.phone}
																	onChange={(e) => {changeData({ e })}}
																	autoComplete='off'
																	innerRef={register({
																		required: true,
																		pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
																	})}
																/>
															</InputGroup>
															{_.get('phone.type', errors) === 'required' && (
																<p className='input-error'>* Phone required.</p>
															)}
															{(_.get('phone.type', errors) === 'pattern') && (
																<p className='input-error'>* Invalid Phone Number.</p>
															)}
														</FormGroup>
													</Col>
												</Row>
											</FormWizard.TabContent>
										</FormWizard>
										<style>{`
										@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
										`}</style>
									</Card>
								</>
							:
								<div className='text-center'>
									<h2 className='text-primary'>Thank you for completing the form.</h2>
									<p>We will be in touch with you soon!.</p>
								</div>
							}
						</GoogleReCaptchaProvider>
					</Col>
					<Col md='6'>
						{agent && <AgentDetailsPage agent={agent}/>}
					</Col>
				</Row>
			</Container>
    </>
  );
}

const ReCaptcha = ({ progress, setToken}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('client_inquiry');
		setToken(token)
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
	return (
		<>
			<div class="wizard-footer-right" style={{backgroundColor: 'rgb(0, 123, 255)', borderColor: 'rgb(0, 123, 255)', borderRadius: '4px'}}>
				<button class="wizard-btn" type="submit" disabled={progress}>{ progress ? "Submitting.." : "Submit" }</button>
			</div>
		</>
	)
};
