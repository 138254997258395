import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
  Col,
	List
} from 'reactstrap';
import { Icon } from '@assets';
import { unitTypes, formatMoveIn } from '@sparklib';
import moment from 'moment/moment';
import { CLIENT_INQUIRY } from '@api'
import { useQuery } from '@apollo/client';

export default function ClientInquiryShow() {
	const { id } = useParams()
	const [client, setClient] = useState(null)

	useQuery(CLIENT_INQUIRY, {
		skip: !id,
		variables: { id: Number(id) },
		onCompleted: (data) => {
			setClient(data.clientInquiry)
		}
	})

	function arrayToString(array){
		let str = ""
		array.forEach(e => str += ` ${e},`)
		return str.slice(0, -1)
	}

	function getBedType(array){
		let str = ""
		array?.forEach(e => str += ` ${unitTypes[e]},`)
		return str.slice(0, -1)
	}

	function getBudget(minRent, maxRent) {
		if (minRent && maxRent){
			return `Between $${minRent} - $${maxRent}`
		} else if(minRent && !maxRent){
			return `Above $${minRent}`
		} else if(!minRent && maxRent){
			return `Below $${maxRent}`
		} else {
			return "-"
		}
	}

	return (
		<>
			{client && 
				<div className='p-4 client-show'>
					<Row>
						<Col md='12' className='d-md-flex align-items-center mb-1'>
							<h3 className='d-inline-block mb-md-0 mr-4'>
								{client.name}<small>{client.status ? ` (${client.status})`: null}</small>
							</h3>
						</Col>
						<Col lg='12'>
							<p
								className='p-2'
								style={{
									background: 'var(--silver)',
									borderRadius: 'var(--rounded-small)',
								}}
							>
								<Icon icon='clipboard'/> Note: {client.notes?.length > 0 ? client.notes : "-"}
							</p>
						</Col>
					</Row>
					<Row>
						<Col md='12' className='mt-md-3'>
							<Row className='mb-4 mt-3'>
								<Col md='12' className='mb-2'>
									<h4>Basic Info</h4>
								</Col>
								<Col lg='3'>
									<p>
										<Icon icon='phone' /> <a href={`tel:${client.phone}`}>{client.phone}</a>
									</p>
								</Col>
								<Col lg='3'>
									<p>
										<Icon icon='paper-plane' />
										<a href={`mailto:${client.email}`}>{client.email}</a>
									</p>
									{client.secondaryEmail && 
										<p>
											<Icon icon='paper-plane' />
											<a href={`mailto:${client.secondaryEmail}`}>{client.secondaryEmail}</a>
										</p>
									}
									{client.otherEmail && 
										<p>
											<Icon icon='paper-plane' />
											<a href={`mailto:${client.otherEmail}`}>{client.otherEmail}</a>&nbsp;
										</p>
									}
								</Col>

								<Col lg='3'>
									<p>
										<Icon icon='map-marker-alt' /> {client.city.name}
									</p>
								</Col>

								{client.creditScore && 
									<Col lg='3'>
										<p>
											<Icon icon='credit-card' /> 
											<strong>Credit Score:</strong>&nbsp;
											{client.creditScore}
										</p>
									</Col>}
								{client.petNumber > 0 ?
										<Col lg='3'>
											<p>
												<Icon icon='dog' /> {client.petNumber}{client.petTypeArray?.length > 0 && arrayToString(client.petTypeArray)}
											</p>
										</Col>
									: null
								}
								{client.noOfParking ?
										<Col lg='3'>
											<p> 
												<Icon icon='car' /> 
												<strong>Parking:</strong>&nbsp;{client.noOfParking ? client.noOfParking : "-" }
											</p>
										</Col>
									: null
								}
								{client.leaseEndDate ?
										<Col lg='3'>
											<p> 
												<Icon icon='calendar-times' /> 
												<strong>Lease End:</strong>&nbsp;{moment(client.leaseEndDate).format('L')}
											</p>
										</Col>
									: null
								}
								{client.backgroundArray.length > 0 ?
										<Col lg='3'>
											<p> 
												<Icon icon='user' /> 
												<strong>Background:</strong>&nbsp;{arrayToString(client.backgroundArray)}
											</p>
										</Col>
									: null
								}
								{client.fromWhereYouKnowUs ?
										<Col lg='4'>
											<p> 
												<Icon icon='question' /> 
												<strong>Where did you hear about us?</strong>&nbsp;{client.fromWhereYouKnowUs}
											</p>
										</Col>
									: null
								}
							</Row>
							<Row>
								<Col md='12' className='mb-2'>
									<h4>Preferences</h4>
								</Col>

								{client.leaseTerm && 
								<Col lg='4'>
									<p>
										<Icon icon='hands-helping' /><strong>Lease Term:</strong>&nbsp;{client.leaseTerm} Months
									</p>
								</Col>}

								<Col md='4'>
									<p>
										<Icon icon='bed' />
										{getBedType(client.bedroomArray)}&nbsp;&nbsp;&nbsp;
										{client.bathroom && <>
											<Icon icon='bath' />
											{client.bathroom}+ Bath
										</>}
									</p>
								</Col>

								<Col md='4'>
									<p><Icon icon='dollar-sign' /><strong>Budget:</strong>&nbsp;
										{
											getBudget(client.minRent, client.maxRent)
										}
									</p>
								</Col>

								<Col md='4'>
									
									<p><Icon icon='home' /> <strong>Square Feet:</strong>&nbsp;
										{client.sqFeet === undefined ||
											client.sqFeet === '' ||
											client.sqFeet === 0 ||
											client.sqFeet === null ? (
											"Any"
										) : (
			
											`Larger than ${client.sqFeet} sqft`
										)}
									</p>
								</Col>
	
								<Col lg='4'>
									<p>
										<Icon icon="truck-moving" />
										<strong>Move-in Date:</strong>&nbsp;
										{formatMoveIn(client.availFrom, client.availTo)}
									</p>
								</Col>

								{client.neighborhoodArray?.length > 0 && 
									<Col md='4'>
										
										<p><Icon icon='map-marker' /><strong>Neighborhoods</strong></p>
										<List>
											{client.neighborhoodArray.map(n => <li key={n}>{n}</li>)}
										</List>
									</Col>}

								{client.unitFeaturesArray?.length > 0 && 
									<Col md='4'>
										
										<p><Icon icon='gem' /><strong>Units Features</strong></p>
										<List>
											{client.unitFeaturesArray.map(n => <li key={n}>{n}</li>)}
										</List>
									</Col>}

								{client.addonsArray?.length > 0 && 
									<Col md='4'>
										
										<p><Icon icon='gem' /><strong>Amenities</strong></p>
										<List>
											{client.addonsArray.map(n => <li key={n}>{n}</li>)}
										</List>
									</Col>}

								{client.parkingPreferencesArray?.length > 0 && 
									<Col md='4'>
										
										<p><Icon icon='parking' /><strong>Parking Preferences</strong></p>
										<List>
											{client.parkingPreferencesArray.map(n => <li key={n}>{n}</li>)}
										</List>
									</Col>}

							</Row>
						</Col>	
					</Row>
				</div>
			}
		</>
	)
}
