import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { 
	Row, 
	Col, 
	FormGroup, 
	Label, 
	Form,
	Button
} from 'reactstrap';
import Select from 'react-select';
import { AGENT, AGENT_CITY_ACCESS, MANAGE_AGENT_CITY_ACCESS } from '@api';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function AgentCityAccessForm({agent, subscriptions}) {
	const activeSubscriptions = subscriptions?.filter(sub => sub.active);
	if (activeSubscriptions?.length === 0) return <p>No Active Subscription Found For This Agent's Manager</p>;
	const [selectedSubscription, setSelectedSubscription] = useState({value: activeSubscriptions[0].id, label: activeSubscriptions[0].plan.name});
	const [subscriptionCities, setSubscriptionCities] = useState([]);
	const [selectedCitiesForAgent, setSelectedCitiesForAgent] = useState([]);
	const [progress, setProgress] = useState(false);
	const [maxCities, setMaxCities] = useState(activeSubscriptions[0].plan.maxCities);
	const history = useHistory();
  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

	const [manageAgentCityAccess] = useMutation(MANAGE_AGENT_CITY_ACCESS, {
		onCompleted: (data) => {
			setProgress(false);
			if (data.manageAgentCityAccess.errors){
				toast(data.manageAgentCityAccess.message, { type: 'error' });
			}else{
				toast(data.manageAgentCityAccess.message, { type: 'success' });
				setSelectedCitiesForAgent(data.manageAgentCityAccess.cities.map(c => ({value: c.id, label: c.name})));
			}
		}
	})

	const [agentCityAccess] = useLazyQuery(AGENT_CITY_ACCESS, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setSelectedCitiesForAgent(data.agentCityAccess.map(c => ({value: c.id, label: c.name})));
		}
	});

	function fetchAgentCityAccess(){
		agentCityAccess({
			variables: {
				// brokerId: Number(broker.id),
				agentId: Number(agent.id),
				subscriptionId: Number(selectedSubscription.value)
			}
		});
	}

	useEffect(() => {
		if (selectedSubscription){
			changeSubscriptionCities();
			fetchAgentCityAccess();
		}
	}, [selectedSubscription, agent]);

	function changeSubscriptionCities(){
		if (activeSubscriptions?.length > 0){
			var brokerSub = activeSubscriptions.filter(sub => Number(sub.id) === Number(selectedSubscription.value))[0];
			setSubscriptionCities(brokerSub.cities);
		}
	}

	const updateAgentData = async (data, e) => {
		let subId = Number(selectedSubscription.value);
		let agentCityIds = selectedCitiesForAgent.map(c => Number(c.value));
		setProgress(true);
		manageAgentCityAccess({
			variables: {
				agentId: Number(agent.id),
				subscriptionId: Number(subId),
				cityIds: agentCityIds
			}
		})
	}

	return (
		<>
			{
				agent && activeSubscriptions?.length > 0 ? 
				<Row>
					<Col md='12'>
						<Form
							className='form'
							onSubmit={handleSubmit(updateAgentData)}
						>
							<Row>
								<Col lg='8'>
										<Label for='city'>Manager Subscription</Label>
									<FormGroup className='text-left'>
										<Select
											value={selectedSubscription}
											name='brokerSubscription'
											classNamePrefix='default-select'
											isMulti={false}
											// isClearable={true}
											placeholder={<>Select Subscription</>}
											options={
												activeSubscriptions?.length > 0
													? Object.values(activeSubscriptions).map(
															(sub) =>
																JSON.parse(
																	`{"value":${sub.id}, "label":"${sub.plan.name}"}`
																)
														)
													: []
											}
											onChange={(selectedSub) => {
												setSelectedSubscription(selectedSub)
											}}
											className='w-100'
										/>
										{/* {cityError && (
											<p className='input-error'>City required</p>
										)} */}
									</FormGroup>
								</Col>
								{
									subscriptionCities?.length > 0 && 
									<Col md='8'>
										<Label for='city'>Market Access</Label>
										<FormGroup className='text-left'>
											<Select
												value={maxCities === 1 ? selectedCitiesForAgent[0] : selectedCitiesForAgent}
												name='agentCities'
												classNamePrefix='default-select'
												isMulti={maxCities !== 1}
												isClearable={maxCities > 1}
												placeholder={<>Select Market To Give Access</>}
												options={
													typeof subscriptionCities !== 'undefined'
														? Object.values(subscriptionCities).map(
																(city) =>
																	JSON.parse(
																		`{"value":${city.id}, "label":"${city.name}"}`
																	)
															)
														: []
												}
												// onChange={(selectedCity) => {
												// 	// maxCities === 1 ||
												// 	// selectedCity.length <= maxCities ||
												// 	// maxCities === -1
												// 		setSelectedCitiesForAgent(selectedCity)
												// 		// : null;
												// }}
												onChange={(selectedCity) => {
													maxCities === 1
														? setSelectedCitiesForAgent([selectedCity])
														: selectedCity.length <= maxCities ||
															maxCities === -1
														? setSelectedCitiesForAgent(selectedCity)
														: null;
												}}
												className='w-100'
											/>
											{/* {cityError && (
												<p className='input-error'>City required</p>
											)} */}
										</FormGroup>
									</Col>
								}
								<Col md='8'>
									<Button 
										color='blue' 
										className="btn-rounded mt-3"
										size='lg' 
										disabled={progress} 
									>
										Save
									</Button>
								</Col>
							</Row>
						</Form>
					</Col>
				</Row> :
				"Loading..."
			}
		</>
	);
}