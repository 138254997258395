import { without } from 'lodash';
import React, { useState } from 'react'

import { NavLink } from 'react-router-dom';

import {
  Container,
  Button,
  Row,
  Col
} from 'reactstrap';

import { NewHeadLogo } from '@assets';

export default function Footer(props) {

  return (<>
    <Container fluid className={'footer ' + props.className}>
      <Container className="custom-container">
        <Row className="mt-5em mb-5">
          <Col lg="6">
            <NavLink to="/"><img src={NewHeadLogo} alt="logo" className="img-fluid footer-branding" /></NavLink>
            <p className="mt-4 mb-lg-0">Find apartment specials, commissions and<br/>more in seconds.</p>
          </Col>

          <Col lg="6" className="text-lg-right">
            <h5 className="mb-4">The easiest way to find and share<br/>apartment data</h5>
            <NavLink to='/signup' className="text-decoration-none">
                <Button color='red' size='md' className='btn-rounded px-lg-5'>
                Start 3-Day Trial
                </Button>
            </NavLink>
          </Col>

          {/* <Col md="12" className="my-5">
            <NavLink to="/signup"><Button size="lg" color="blue" className="hover-yellow">JOIN</Button></NavLink>
          </Col> */}
        </Row>

        <Row className="copyright-section mb-5">
          <Col lg="12">
            <hr className="w-100 mb-4" />
          </Col>
          <Col lg="6" className="mb-4 mb-lg-0">
            {/* <a href="https://www.termsfeed.com/live/b929e886-28b9-4284-bd1d-2e179cec8c5e" target="_blank">Terms of Service</a> */}
            <NavLink to="/terms-of-service">Terms of Service</NavLink>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/contact-us">Contact</NavLink>
            {/* <NavLink to="/security">Security</NavLink> */}
          </Col>

          <Col lg="6" className="text-lg-right">
            <p>© {new Date().getFullYear()} SparkAPT All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </Container>
  </>)
}
