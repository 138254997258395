import React from "react";
import {
  Media,
} from "reactstrap";

import { Icon } from "@assets";
import { NavLink } from "react-router-dom";

export default function DashboardWidget(props) {
  return (<>
    <NavLink to={props.link || '/'} className={props.color + " dashboard-widget"}>
      <Media className="align-items-center">
        <Media body>
          <h4>{props.number}</h4>
          <h6>{props.name}</h6>
        </Media>
        <Media right>
          <Icon icon={props.icon} />
        </Media>
      </Media>
    </NavLink>
  </>);
}
