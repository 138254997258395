import React from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import NavBar from '../NewLanding/components/newNavbar';
import DivisionTitle, { DivisionSubTitle } from '../NewLanding/components/divisionTitle';
import Footer from '../NewLanding/components/newFooter';
import { CONTACTINQUIRY_CREATE } from '@api';
import { useMutation } from '@apollo/client';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
toast.configure();
export default function ContactUs() {
  const [createInquiry, { data, loading, error }] = useMutation(
    CONTACTINQUIRY_CREATE,
    {
      onCompleted: (data) => {
        if (
          data.contactinquiryCreate.errors &&
          data.contactinquiryCreate.errors.length > 0
        ) {
          data.contactinquiryCreate.errors.map((err) =>
            toast(err, { type: 'error' })
          );
        } else {
          toast(data.contactinquiryCreate.message, { type: 'success' });
        }
      },
    }
  );

  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    await createInquiry({
      variables: {
        email: data.email,
        phone: data.phone,
        inquiryReason: data.inquiry_reason,
        message: data.message,
      },
    });
    e.target.reset();
  };

  return (
    <>
      <NavBar />
      {/* <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Drop us a line</h1>
        </Container>
      </Container> */}

      <Container fluid className='py-5em mt-5em'>
        <Container className='custom-container'>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                className='mb-5'
                title={<>Drop us a <span className="highlighted-text">line</span></>}
              />
              <DivisionSubTitle title={<>
                  Whether you have a project to discuss, are interested in joining our team, or<br/>
                  just want to shower us in praise (we like that), get in touch! We’d love to<br/>
                  hear from you. Use the contact form below</>} />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='bg-blue-shade py-5em'>
        <Container>
          <Row>
            <Col md={{ size: 6, offset: 3 }} className='inputFields text-center'>
              <Form className='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroup className='mb-4'>
                  <Input
                    type='text'
                    placeholder='Your Email*'
                    name='email'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                  {_.get('email.type', errors) === 'required' && (
                    <p className='input-error'>* Email required.</p>
                  )}
                  {_.get('email.type', errors) === 'pattern' && (
                    <p className='input-error'>* Invalid Email.</p>
                  )}
                </FormGroup>

                <FormGroup className='mb-4'>
                  <Input
                    type='text'
                    placeholder='Your Phone Number'
                    name='phone'
                    autoComplete='off'
                    innerRef={register({
                      maxLength: 11,
                      minLength: 8,
                    })}
                  />
                  {_.get('phone.type', errors) === 'maxLength' && (
                    <p className='input-error'>
                      * Max Length should be 11 digit.
                    </p>
                  )}
                  {_.get('phone.type', errors) === 'minLength' && (
                    <p className='input-error'>* Min Length should be 8 digit.</p>
                  )}
                </FormGroup>

                <FormGroup className='mb-4'>
                  <Input
                    type='text'
                    placeholder='Reason for inquiry*'
                    name='inquiry_reason'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('inquiry_reason.type', errors) === 'required' && (
                    <p className='input-error'>
                      * Please write your inquiry concern.
                    </p>
                  )}
                </FormGroup>

                <FormGroup className='mb-4'>
                  <Input
                    type='textarea'
                    placeholder='Your Message*'
                    name='message'
                    autoComplete='off'
                    rows='5'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('message.type', errors) === 'required' && (
                    <p className='input-error'>* Please describe your query.</p>
                  )}
                </FormGroup>

                <Button color='green' size='lg' className="btn-rounded">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
