import React, { useState, useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '@sparklib';
import { useForm } from 'react-hook-form';
import {
  Row,
  Button,
  Col,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Icon } from '@assets';
import { PROPERTIES, PROPERTY_UPDATE_PHOTOS } from '@api';
import { useMutation } from '@apollo/client';

export default function PropertyPhotoEditor({
  showPhotoEditor,
  setShowPhotoEditor,
}) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const [propertyPhotos, setPropertyPhotos] = useState([]);
  const [imageIdsToRemove, setImageIdsToRemove] = useState([]);

  const [propertyUpdatePhotos] = useMutation(PROPERTY_UPDATE_PHOTOS, {
    context: { hasUpload: true },
    onCompleted: (data) => {
      // toast(data.propertyUpdatePhotos.message, { type: 'success' });
      // setIsProgress(false);
    },
    refetchQueries: [PROPERTIES],
    onError: (e) => {
      toast(e, { type: 'error' });
      console.log(e);
    },
  });

  const resetPhotoEditor = () => {
    setShowPhotoEditor({ show: false, property: null });
    setImageIdsToRemove([]);
    setPropertyPhotos([]);
  };

  const onSaveImages = (data, e) => {
    console.log(data, ' --- data ', e, ' --- e');
    console.log(propertyPhotos, ' --- propertyPhotos');
    propertyUpdatePhotos({
      variables: {
        id: Number(showPhotoEditor.property?.id),
        images: propertyPhotos,
        imageIdsToRemove: imageIdsToRemove,
      },
    });
    resetPhotoEditor();
  };

  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    propertyPhotos &&
      propertyPhotos.map((photo) => URL.revokeObjectURL(photo.preview));
    console.log(propertyPhotos, 'propertyPhotos');
  }, [propertyPhotos]);

  return (
    <Modal
      className='modal-dialog-centered big-modal'
      key={showPhotoEditor.property?.id}
      isOpen={showPhotoEditor.show}
      toggle={(event) => {
        if (event.key === 'Escape') {
          setPropertyPhotos([]);
          setShowPhotoEditor({ show: false, property: null });
        }
      }}
    >
      <Form className='form' onSubmit={handleSubmit(onSaveImages, onError)}>
        <ModalHeader>{showPhotoEditor.property?.name}</ModalHeader>
        <ModalBody>
          <Row className='photos'>
            {showPhotoEditor.property?.imageUrls?.length > 0 ||
            propertyPhotos?.length > 0 ? (
              <>
                {showPhotoEditor.property?.imageUrls.map((img, index) =>
                  imageIdsToRemove.find(
                    (id) => id === showPhotoEditor.property?.imageIds[index]
                  ) === undefined ? (
                    <Col md='2' key={index}>
                      <img
                        src={img}
                        className='p-0 m-0 mb-4 w-100'
                        alt={showPhotoEditor.property?.name}
                      />
                      <Icon
                        icon='times'
                        className='remove-item'
                        onClick={() =>
                          setImageIdsToRemove((imageIdsToRemove) => [
                            ...imageIdsToRemove,
                            showPhotoEditor.property?.imageIds[index],
                          ])
                        }
                      />
                    </Col>
                  ) : (
                    ''
                  )
                )}

                {propertyPhotos.map((photo, index) => (
                  <Col md='2' key={index}>
                    <img
                      src={photo.preview}
                      className='p-0 m-0 mb-4 w-100'
                      alt={showPhotoEditor.property?.name}
                    />
                    <Icon
                      icon='times'
                      key={index}
                      className='remove-item'
                      onClick={() => {
                        setPropertyPhotos(
                          propertyPhotos.filter((photo, pos) => pos !== index)
                        );
                        console.log(
                          propertyPhotos,
                          ' ---- propertyPhotos after delete'
                        );
                      }}
                    />
                  </Col>
                ))}
              </>
            ) : (
              <>
                <Col md='12 m-4'>
                  <small>No Image</small>
                </Col>
              </>
            )}
          </Row>
          <Row>
            <Col md='12' className='mt-3'>
              {/* <input
                type='hidden'
                name='imageIdsToRemove'
                value={imageIdsToRemove}
              /> */}

              <Dropzone
                name='propertyImagesDropZone'
                accept='image/jpeg, image/png'
                // maxFiles={1}
                multiple={true}
                onDrop={(acceptedFiles) => {
                  acceptedFiles.length > 0 &&
                    setPropertyPhotos((propertyPhotos) => [
                      ...propertyPhotos,
                      ...acceptedFiles.map((file) =>
                        Object.assign(file, {
                          preview: URL.createObjectURL(file),
                        })
                      ),
                    ]);
                }}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                  acceptedFiles,
                }) => {
                  const style = useMemo(
                    () => ({
                      ...baseStyle,
                      ...(isDragActive ? activeStyle : {}),
                      ...(isDragAccept ? acceptStyle : {}),
                      ...(isDragReject ? rejectStyle : {}),
                    }),
                    [isDragActive, isDragReject, isDragAccept]
                  );
                  return (
                    <span className='flex-grow'>
                      <div {...getRootProps({ style })}>
                        <input
                          ref={register({})}
                          name='images'
                          {...getInputProps()}
                        />
                        <div className='text-center w-100'>
                          Drag 'n drop the photos here,
                          <br />
                          or click to select the photos
                          <br />
                          <small>
                            <em>(only jpeg or png files supported)</em>
                          </small>
                        </div>
                      </div>
                    </span>
                  );
                }}
              </Dropzone>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='blue'
            // onClick={(e) => {
            //   onSaveImages(e);
            //   setShowPhotoEditor({ show: false, property: null });
            //   setPropertyPhotos([]);
            // }}
            type='submit'
          >
            Save Images
          </Button>
          <Button onClick={resetPhotoEditor}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
