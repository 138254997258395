import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MY_PROFILE, UPDATE_PROFILE } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from '../Spinner';
import config from '@config';
import { Icon } from '@assets';

export default function AssistantProfile() {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const history = useHistory();
  const [user, setuser] = useState([]);

  const {
    data: profileData,
    loading: ProfileLoading,
    refetch: profileRefetch,
  } = useQuery(MY_PROFILE, {
    onCompleted: (data) => {
      if (data.myProfile) {
        //setuser(data.myProfile);
      } else {
        toast('Profile not found', { type: 'error' });
      }
    },

    onError: (error) => {
      console.log(error + '>>>>> ERROR >>>>>');
      toast('Profile not found', { type: 'error' });
    },
  });
  const [userProfileUpdate, { data, loading, error }] = useMutation(
    UPDATE_PROFILE,
    {
      onCompleted: (data) => {
        if (
          data.userProfileUpdate.errors &&
          data.userProfileUpdate.errors.length > 0
        ) {
          data.userProfileUpdate.errors.map((err) =>
            toast(err, { type: 'error' })
          );
        } else {
          profileRefetch();
          toast(data.userProfileUpdate.message, { type: 'success' });
          history.push({
            pathname: 'assistant-dashboard',
          });
        }
      },
      onError: (e) => {
        console.log(e, 'updated profile ON ERROR');
      },
    }
  );
  useEffect(() => {
    profileData && setuser(profileData.myProfile);
  }, [profileData]);
  const onSubmit = (data, e) => {
    userProfileUpdate({
      variables: {
        email: data.email,
        phoneNo: data.phoneNo,
        firstName: data.firstName,
        lastName: data.lastName,
      },
    });
  };

  function changeUserDetail(e) {
    const { name, value } = e.target;
    setuser({ ...user, [name]: value });
  }

  return (
    <>
      <Container className='py-5 text-center'>
        {ProfileLoading && <Spinner />}
        <Row>
          <Col md='12'>
            <h2>User Profile</h2>
          </Col>

          <Col
            lg={{ size: 6, offset: 3 }}
            md={{ size: 8, offset: 2 }}
            className='text-left mt-3 mt-md-5 inputs-icons'
          >
            <Form className='form' onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Label for='firstName'>First Name</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='user' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='firstName'
                    onChange={changeUserDetail}
                    defaultValue={user.firstName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>
                {_.get('firstName.type', errors) === 'required' && (
                  <p className='input-error'>* First Name required.</p>
                )}
              </FormGroup>
              <FormGroup>
                <Label for='lastName'>Last Name</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='user' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='lastName'
                    onChange={changeUserDetail}
                    defaultValue={user.lastName}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>

                {_.get('lastName.type', errors) === 'required' && (
                  <p className='input-error'>* Last Name required.</p>
                )}
              </FormGroup>
              <FormGroup>
                <Label for='clientEmail'>Email</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='envelope' />
                  </InputGroupText>
                  <Input
                    type='text'
                    placeholder='Email'
                    name='email'
                    onChange={changeUserDetail}
                    defaultValue={user.email}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                </InputGroup>
                {_.get('email.type', errors) === 'required' && (
                  <p className='input-error'>* Email required.</p>
                )}
                {_.get('email.type', errors) === 'pattern' && (
                  <p className='input-error'>* Invalid Email.</p>
                )}
              </FormGroup>
              <FormGroup>
                <Label for='clientPhone'>Phone</Label>
                <InputGroup>
                  <InputGroupText>
                    <Icon icon='phone' />
                  </InputGroupText>
                  <Input
                    type='text'
                    name='phoneNo'
                    onChange={changeUserDetail}
                    defaultValue={user.phoneNo}
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                </InputGroup>

                {_.get('phoneNo.type', errors) === 'required' && (
                  <p className='input-error'>* Phone required.</p>
                )}
              </FormGroup>
              <FormGroup className='inputs-icons'>
                <Label for='referralUrl'>Referral Link</Label>
                <InputGroup>
                  <span style={{ paddingTop: '7px', fontSize: "medium"}}> {config.url.WEB_DOMAIN}signup?code={user.slug}</span>
                  <Icon icon='copy' size="lg" color="blue" className="ml-2 mt-2 cursor-pointer" onClick={() => navigator.clipboard.writeText(`${config.url.WEB_DOMAIN}signup?code=${user.slug}`)}/>
                </InputGroup>
              </FormGroup>
              <Row>
                <Col md='12' className='text-center'>
                  <Button color='primary' className='button-dashboard'>
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
