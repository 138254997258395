import React, { useEffect, useState, useRef } from 'react';

import { Spinner } from 'reactstrap';
import _ from 'lodash';
/* import { neighborhoodsJson } from './neighborhoodsJson'; */
import NewMap from './NewMap';
const noPoi = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

function Gmap(props) {
  const [shapes, setShapes] = useState({});
  const [isClear, setIsClear] = useState(false);
  const [currentHoverId, setCurrentHoverId] = useState();
  const [latlongs, setLatlongs] = useState([]);
  const [propertiesLatlongs, setPropertiesLatlongs] = useState([]);
  const [currentId, setCurrentId] = useState();
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  /* const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD-Vwu6LSsJscOPVKsSDvVse9NFlA3U7d4',
    libraries: ['drawing'],
  }); */

  /* useEffect(() => {
    var inside = [];
    Object.values(shapes).map((shape) => {
      var arr = shape.getPath().getArray();
      var polygon_latlng = arr.map((a) => [a.lat(), a.lng()]);
      //var inside = props.latlongs.map((s) => s.id);
      props.allPropertylatlongs.map((item, i) => {
        var temp = [item?.lat, item?.long];
        var is_inside = pointInPolygon(temp, polygon_latlng);
        if (is_inside === true) {
          is_inside && inside.push(item.id);
        }
      });
    });
    if (Object.values(shapes).length > 0) {
      props.propertyIds(inside);
      props.setCurrentBounds(mapRef.current.getBounds());
    }
  }, [shapes]); */

  useEffect(() => {
    //setLatlongs(props.allPropertylatlongs);
    //setLatlongs([]);
    if (props.allPropertylatlongs.length > 0) {
      let letLngValue = [];
      props.allPropertylatlongs.forEach(function (element, i) {
        var right_lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
        var right_long =
          /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
        var validLat =
          !element.node?.lat || Number(element.node?.lat) === 0
            ? undefined
            : right_lat.test(element.node.lat);
        var validLon =
          !element.node?.long || Number(element.node?.long) === 0
            ? undefined
            : right_long.test(element.node.long);

        if (validLat && validLon) {
          let allUnits = [];
          let allFPs = [];
          element.node.floorPlans.forEach((fp) => {
						if (fp.isAvailable && fp.rentMin > 0 || fp.rentMax > 0){
							allFPs.push(fp);
						}
          });
					if (allFPs?.length > 0){
						letLngValue.push({
							...element.node,
							floorPlans: allFPs,
						});
					}
        }
      });
      //console.log(latlongs, 'value add latlongs');
      if (!_.isEqual(letLngValue, latlongs)) {
        // props.setMapLoaded(false);
        setLatlongs(letLngValue);
      }
    } else {
      props.setMapLoaded(true);
    }
    //console.log(props.allPropertylatlongs, 'props.allPropertylatlongs');
  }, [props.allPropertylatlongs]);

  useEffect(() => {

    if (props.properties.length > 0) {
      let letLngValue = [];
      props.properties.map(function (element, i) {
        var right_lat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
        var right_long =
          /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
        var validLat =
          !element.node?.lat || Number(element.node?.lat) === 0
            ? undefined
            : right_lat.test(element.node.lat);
        var validLon =
          !element.node?.long || Number(element.node?.long) === 0
            ? undefined
            : right_long.test(element.node.long);

        if (validLat && validLon) {
          let allUnits = [];
          element.node.floorPlans.forEach((fp) => {
            allUnits.push(...fp.units);
          });
          letLngValue.push({
            ...element.node,
            typeDetails: allUnits,
          });
        }
      });
      //console.log(latlongs, 'value add latlongs');
      if (!_.isEqual(letLngValue, propertiesLatlongs)) {
        props.setMapLoaded(false);
        setPropertiesLatlongs(letLngValue);
      }
    } else {
      props.setMapLoaded(true);
    }
  }, [props.properties]);

  const getCoorinates = (e) => {
    setShapes((shapes) => ({ ...shapes, [e.id]: e }));
    e.addListener('mouseup', function (event) {
      setShapes((shapes) => ({ ...shapes, [e.id]: e }));
    });
  };

  function handleOverlayComplete(e) {
    this.setDrawingMode(null);
    //this.setOptions({ drawingControlOptions: { drawingModes: [] } });
  }

  useEffect(() => {
    clearMap();
  }, [isClear]);

  const clearMap = () => {
    //shapes.forEach((shape) => shape.setMap(null));
    //setShapes([]);
    //localStorage.removeItem('property_ids');
  };

  function mouseOut(e) {
    const result = props
      .getFilter('neighborhoods')
      .find(({ value }) => Number(value) === this.id);
    if (result) {
      this.setOptions({ fillColor: 'red' });
    } else {
      this.setOptions({ fillColor: 'transparent' });
    }
  }
  function mouseOver(e) {
    const result = props
      .getFilter('neighborhoods')
      .find(({ value }) => Number(value) === this.id);
    if (result) {
      this.setOptions({ fillColor: 'red' });
    } else {
      this.setOptions({ fillColor: 'blue' });
    }
  }

  function checkValueExist(id, label) {
    if (props.getFilter('neighborhoods').length > 0) {
      const result = props
        .getFilter('neighborhoods')
        .find(({ value }) => Number(value) === id);
      if (result === undefined) {
        props.setFilter({
          e: [...props.getFilter('neighborhoods'), { value: id, label: label }],
          key: 'neighborhoods',
        });
      } else {
        const neighborhoodsArray = props
          .getFilter('neighborhoods')
          .filter(function (obj) {
            return Number(obj.value) !== id;
          });
        props.setFilter({
          e: {
            target: {
              name: 'neighborhoods',
              value: neighborhoodsArray,
            },
          },
        });
      }
    } else {
      props.setFilter({
        e: [...props.getFilter('neighborhoods'), { value: id, label: label }],
        key: 'neighborhoods',
      });
    }
    props.setShouldMapSearch(true);
  }

  function colorCheck(id) {
    return props
      .getFilter('neighborhoods')
      .find(({ value }) => Number(value) === id)
      ? 'red'
      : 'transparent';
  }

  /* useEffect(() => {
    if (latlongs.length > 0) {
      if (map) {
        let mapLatLong = [];
        latlongs.forEach((element) => {
          if (element.lat && element.long) {
            mapLatLong.push(element.lat);
          }
        });

        const bounds = new window.google.maps.LatLngBounds();
        latlongs.map((model) => {
          if (model.lat && model.long) {
            bounds.extend(
              new window.google.maps.LatLng(
                Number(model.lat),
                Number(model.long)
              )
            );
          }
        });
        map.fitBounds(bounds);
        console.log('lat long call');
      }
    } else {
      console.log('not lat long found');
    }
  }, [latlongs]); */

  /* useEffect(() => {
    setCurrentHoverId(props.hoverPropertyId);
  }, [props.hoverPropertyId]); */

  const onLoad = React.useCallback(function callback(map) {
    //if (latlongs.length > 0) {
    /*       const bounds = new window.google.maps.LatLngBounds();
      latlongs.map((model) => {
        if (model.lat && model.long) {
          bounds.extend(
            new window.google.maps.LatLng(Number(model.lat), Number(model.long))
          );
        }
      });
      map?.fitBounds(bounds);
 */
    //setMap(map);
    // }
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  /* useEffect(() => {
    console.log(latlongs, 'latlongslatlongs');
  }, [latlongs]); */
  return (
    <>
      <NewMap
        latlongs={latlongs}
				setLatlongs={setLatlongs}
        hoverPropertyId={props.hoverPropertyId}
        setSidebarState={props.setSidebarState}
        setProperty={props.setProperty}
        path={props.path}
        getFilter={props.getFilter}
        selectedPropertyArray={props.selectedPropertyArray}
        toggleSelection={props.toggleSelection}
        activeTab={props.activeTab}
        setMapLoaded={props.setMapLoaded}
        mapLoaded={props.mapLoaded}
        initialLatlongs={propertiesLatlongs}
				setInitialLatlongs={setPropertiesLatlongs}
				setSelectedPropertiesFromMap={props.setSelectedPropertiesFromMap}
      />

      {/* <Button
          //disabled={isInEditMode}
          onClick={() => setIsDrawingMode(!isDrawingMode)}
        >
          {isDrawingMode ? 'Cancel' : 'Draw'}
        </Button> */}
    </>
  );
}

export default Gmap;
