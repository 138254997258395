import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import {useParams } from 'react-router-dom';
import Spinner from '../../Spinner';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Icon } from '@assets';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { CITIES, CLIENTS, CITY_PROPERTIES, CREATE_INVOICE, INVOICE, UPDATE_INVOICE, INVOICE_NUMBER } from '@api';

export default function InvoiceForm(props) {

	const [properties, setProperties] = useState();
	const [selectedProperty, setSelectedProperty] = useState();
	const [propertyError, setPropertyError] = useState(false);
	const [selectedCity, setSelectedCity] = useState({ label: '', value: '' });
	const [selectedClient, setSelectedClient] = useState();
	const [selectClientError, setSelectClientError] = useState(false);
	const [moveIn, setMoveIn] = useState(new Date());
	const [leaseTerm, setLeaseTerm] = useState();
	const [unitNumber, setUnitNumber] = useState(null);
	const [commission, setCommission] = useState(null);
	const [leaseAmount, setLeaseAmount] = useState();
	const [isSubmit, setIsSubmit] = useState(false);
	const [invoiceStatus, setInvoiceStatus] = useState(null);
	const [leaseStartDate, setLeaseStartDate] = useState(null);
	const [invoiceNumber, setInvoiceNumber] = useState();
	const [isPercentCommission, setIsPercentCommission] = useState(true);
	const [concession, setConcession] = useState();
	const [progress, setProgress] = useState(false);
	const [sendToEmail, setSendToEmail] = useState("");
	const [propertyEmail, setPropertyEmail] = useState("");
	const [mls, setMls] = useState(null);
	const [leasingAgentName, setLeasingAgentName] = useState(null);
	const [referralAgentName, setReferralAgentName] = useState(null);
	const [referralAgentEmail, setReferralAgentEmail] = useState(null);
	const [referralCommission, setReferralCommission] = useState(null);
	const [isPercentReferralCommission, setIsPercentReferralCommission] = useState(true);
	const [checkNumber, setCheckNumber] = useState(null);
	const [checkDate, setCheckDate] = useState(null);
	const [notes, setNotes] = useState(null);
	const { register, handleSubmit, errors, control, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

	function resetVariables() {
		setSelectedProperty(null);
		setPropertyError(false);
		setSelectedClient(null);
		setSelectClientError(false);
		setMoveIn(new Date());
		setLeaseTerm(null);
		setUnitNumber(null);
		setCommission(null);
		setLeaseAmount(null);
		setLeaseStartDate(new Date());
		setInvoiceNumber(invoiceNumberData ? invoiceNumberData.nextInvoiceNumber : null);
		setIsPercentCommission(true);
		setConcession(null);
		setSendToEmail("");
		setMls(null);
		setLeasingAgentName(null);
		setReferralAgentName(null);
		setReferralAgentEmail(null);
		setReferralCommission(null);
		setIsPercentReferralCommission(true);
		setCheckNumber(null);
		setCheckDate(null);
		setNotes(null);
		document.getElementById('invoiceForm').reset();
	}

	// resetVariables();

	const { id: invoiceId } = useParams();
	const { data: invoiceData} = useQuery(INVOICE, {
		variables: {id: parseInt(invoiceId)},
		skip: !invoiceId,
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			setSelectedCity({value: data.invoice.property.city.id, label: data.invoice.property.city.name});
			setSelectedProperty({value: data.invoice.property.id, label: data.invoice.property.name});
			setSelectedClient({value: data.invoice.client.id, label: data.invoice.client.name});
			setMoveIn(new Date(data.invoice.moveIn));
			setLeaseTerm(data.invoice.leaseTerm);
			setUnitNumber(data.invoice.unitNumber);
			setCommission(data.invoice.commission);
			setLeaseStartDate(new Date(data.invoice.leaseStartDate));
			setInvoiceNumber(data.invoice.invoiceNumber);
			setLeaseAmount(data.invoice.leaseAmount);
			setInvoiceStatus(data.invoice.status);
			setConcession(data.invoice.concession);
			setIsPercentCommission(data.invoice.isPercentCommission);
			setSendToEmail(data.invoice.sendTo ? data.invoice.sendTo : data.invoice.property.email);
			setPropertyEmail(data.invoice.property.email);
			setMls(data.invoice.mls);
			setLeasingAgentName(data.invoice.leasingAgentName);
			setReferralAgentName(data.invoice.referralAgentName);
			setReferralAgentEmail(data.invoice.referralAgentEmail);
			setReferralCommission(data.invoice.referralCommission);
			setIsPercentReferralCommission(data.invoice.isPercentReferralCommission);
			setCheckNumber(data.invoice.checkNumber);
			setCheckDate(data.invoice.checkDate);
			setNotes(data.invoice.notes);
		}
	})

	const {data: clientsList, loading: loadingClients} = useQuery(CLIENTS)
	const {data: invoiceNumberData} = useQuery(INVOICE_NUMBER, {
		skip: props.new !== 'new',
		onCompleted: (data) => {
			setInvoiceNumber(data.nextInvoiceNumber);
		}
	})

	const { data: citiesData } = useQuery(CITIES, {
    onCompleted: (data) => {
      if (data.cities.length > 0) {
        setSelectedCity({
          label: data.cities[0].name,
          value: data.cities[0].id,
        });
      }
    },
    onError: (e) => {
      console.log(e, 'error');
    },
  });

	const [createInvoice, {data, loading, error}] = useMutation(CREATE_INVOICE, {
		onCompleted: (data) => {

			if (data.createInvoice.errors && data.createInvoice.errors.length > 0) {
				data.createInvoice.errors.map((err) => toast(err, { type: 'error' }));
				setProgress(false);
			} else {
				toast(data.createInvoice.message, { type: 'success' });
				reset();
				resetVariables();
				setProgress(false);
				props.sidebarToggle();
				props.shouldRefetch(true);
			}
		},
		onError: (e) => {
			console.log(e, 'Create Invoice ON ERROR');
		},
		refetchQueries: [INVOICE_NUMBER]
		}
	)

	const [updateInvoice, {}] = useMutation(UPDATE_INVOICE, {
		onCompleted: (data) => {

			if (data.updateInvoice.errors && data.updateInvoice.errors.length > 0) {
				data.updateInvoice.errors.map((err) => toast(err, { type: 'error' }));
				setProgress(false);
			} else {
				toast(data.updateInvoice.message, { type: 'success' });
				reset();
				resetVariables();
				setProgress(false);
				props.sidebarToggle();
				props.shouldRefetch(true);
			}
		},
		onError: (e) => {
			console.log(e, 'Update Invoice ON ERROR');
		}
		}
	)

  if (citiesData && selectedCity.label === '') {
    setSelectedCity({
      label: citiesData.cities[0].name,
      value: citiesData.cities[0].id,
    });
  }

	const [
    propertiesQuery,
    {
      data: cityProperties,
    },
  ] = useLazyQuery(CITY_PROPERTIES, {
    variables: {
      cityId: selectedCity.value ? parseInt(selectedCity.value) : '',
    },
  });

  const getProperties = () => {
    propertiesQuery({
      variables: {
        cityId: selectedCity.value ? parseInt(selectedCity.value) : null,
      },
    });
  };

	useEffect(() => {
    if (selectedCity.value !== '') {
      setProperties();
      getProperties();
    }
  }, [citiesData, selectedCity]);

	const handleOnChangeCity = (selectedOption) => {
		setProperties({value: null, label: ""});
    setSelectedCity(selectedOption);
  };

  const handleOnChangeProperty = (selectedOption) => {
    setSelectedProperty(selectedOption);
  };

	useEffect(() => {
		resetVariables();
		document.getElementById('invoiceForm').reset();
  }, [props.new]);


	const onSubmit = async (data, e) => {

		if (selectedClient === undefined) {
			setSelectClientError(true);
			return false;
		} 
		
		if (selectedProperty === undefined) {
			setPropertyError(true);
			return false;
		}
		setProgress(true);
		if (props.new === 'new') {
			createInvoice({
				variables: {
					propertyId: Number(selectedProperty.value), 
					clientId: Number(selectedClient.value), 
					moveIn: moveIn, 
					unitNumber: unitNumber, 
					leaseTerm: Number(leaseTerm), 
					leaseStartDate: leaseStartDate, 
					leaseAmount: Number(leaseAmount), 
					invoiceNumber: String(invoiceNumber), 
					commission: parseFloat(commission),
					status: isSubmit ? "sent" : "drafted",
					isPercentCommission: isPercentCommission,
					concession: concession,
					mls: mls,
					leasingAgentName: leasingAgentName,
					referralAgentName: referralAgentName,
					referralAgentEmail: referralAgentEmail,
					referralCommission: parseFloat(referralCommission),
					isPercentReferralCommission: isPercentReferralCommission,
					checkNumber: checkNumber,
					checkDate: checkDate,
					notes: notes
				}
			});
		} else {
			updateInvoice({
				variables: {
					id: parseInt(invoiceId),
					propertyId: Number(selectedProperty.value), 
					clientId: Number(selectedClient.value), 
					moveIn: moveIn, 
					unitNumber: unitNumber, 
					leaseTerm: Number(leaseTerm), 
					leaseStartDate: leaseStartDate, 
					leaseAmount: Number(leaseAmount), 
					invoiceNumber: String(invoiceNumber), 
					commission: parseFloat(commission),
					isSubmit: isSubmit,
					concession: concession,
					isPercentCommission: isPercentCommission,
					sendTo: sendToEmail === propertyEmail ? null : sendToEmail,
					mls: mls,
					leasingAgentName: leasingAgentName,
					referralAgentName: referralAgentName,
					referralAgentEmail: referralAgentEmail,
					referralCommission: parseFloat(referralCommission),
					isPercentReferralCommission: isPercentReferralCommission,
					checkNumber: checkNumber,
					checkDate: checkDate,
					notes: notes
				}
			});
		}
  };

	useEffect(() => {
		resetVariables();
	}, [props.sidebarState])

	return (
		<>
			<div className="p-4">
				<Row>
					<Col md='12'>
						<h4>{props.new === 'new' ? 'New Invoice' : 'Update Invoice'}</h4>
          </Col>

					<Col md='12' className='mt-md-3'>
						<Form id='invoiceForm' onSubmit={handleSubmit(onSubmit)}>
							<Row>
								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='invoiceNumber'>Invoice ID</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='file-alt' />
											</InputGroupText>
											<Input
												type='text'
												name='invoiceNumber'
												value={invoiceNumber}
												autoComplete='off'
												onChange={(e) => setInvoiceNumber(e.target.value)}
												innerRef={register({
													required: true,
													maxLength: 25
												})}
											/>
										</InputGroup>
										{_.get('invoiceNumber.type', errors) === 'required' && (
											<p className='input-error'>* Invoice ID required.</p>
										)}
										{_.get('invoiceNumber.type', errors) === 'maxLength' && (
											<p className='input-error'>* Invoice ID must be less than 25 digit long.</p>
										)}
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup>
										<Label for='clientSelection'>Client</Label>
										<InputGroup>
										{clientsList ?
											<Select
												closeMenuOnSelect={true}
												value={selectedClient}
												options={clientsList.clients.edges.map(
													(client) =>
														JSON.parse(
															`{"value":"${client.node.id}", "label":"${client.node.name}"}`
														)
												)}
												onChange={(selectedClient) => {
													setSelectedClient(selectedClient);
													}
												}
												isMulti={false}
												placeholder='Select Client'
												className='multi-select-client w-100'
												classNamePrefix='default-select'
											/> : "No Client Found" }
										</InputGroup>
										{ selectClientError && (
                      <p className='input-error'>Please select client</p>
                    )}
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup>
										<Label for='propery'>Market</Label>
										<InputGroup>
											<Select
												closeMenuOnSelect={true}
                        options={
                          typeof citiesData !== 'undefined'
                            ? Object.values(citiesData.cities).map((city) =>
                                JSON.parse(
                                  `{"value":${city.id}, "label":"${city.name}"}`
                                )
                              )
                            : []
                        }
                        value={selectedCity.label ? selectedCity : []}
                        onChange={handleOnChangeCity}
												isMulti={false}
												placeholder='Select Market'
												className='multi-select-client w-100'
												classNamePrefix='default-select'
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup>
										<Label for='propery'>Where Did They Lease?</Label>
										<InputGroup>
											<Select
												closeMenuOnSelect={true}
                        options={cityProperties?.cityProperties?.map(
                          (property) =>
                            JSON.parse(
                              `{"value":"${property.id}", "label":"${property.name.replace(/[\n\r\t]/g," ")}"}`
                            )
                        )}
                        onChange={(property) => {
													handleOnChangeProperty(property)
                        }}
                        value={selectedProperty}
												isMulti={false}
												placeholder='Select Property'
												className='multi-select-client w-100'
												classNamePrefix='default-select'
											/>
										</InputGroup>
										{ propertyError && (
                      <p className='input-error'>Please select property</p>
                    )}
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='commission'>Unit Number</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='building' />
											</InputGroupText>
											<Input
												type='text'
												name='unitNumber'
												value={unitNumber}
												autoComplete='off'
												onChange={(e) => setUnitNumber(e.target.value)}
												innerRef={register({
													required: true,
												})}
											/>
										</InputGroup>
										{_.get('unitNumber.type', errors) === 'required' && (
                        <p className='input-error'>* Unit Number required.</p>
                      )}
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup>
										<Label for='moveIn'>Move-In Date</Label>
										<InputGroup>
											<DatePicker
												wrapperClassName='r-date-time'
												className='form-control r-date-time'
												selected={moveIn}
												onChange={(date) => setMoveIn(date)}
												dateFormat='MMMM d, yyyy'
											/>
										</InputGroup>
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='leaseTerm'>Lease Term (Months)</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='hands-helping' />
											</InputGroupText>
											<Input
												type='number'
												name='leaseTerm'
												value={leaseTerm}
												autoComplete='off'
												onChange={(e) => setLeaseTerm(e.target.value)}
												innerRef={register({
													required: true,
												})}
											/>
										</InputGroup>
										{_.get('leaseTerm.type', errors) === 'required' && (
                        <p className='input-error'>* Lease Term required.</p>
                      )}
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='leaseAmount'>Rent (Per Month)</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='dollar-sign' />
											</InputGroupText>
											<Input
												type='number'
												name='leaseAmount'
												value={leaseAmount}
												autoComplete='off'
												onChange={(e) => setLeaseAmount(e.target.value)}
												innerRef={register({
													required: true,
												})}
											/>
										</InputGroup>
										{_.get('leaseAmount.type', errors) === 'required' && (
                        <p className='input-error'>* Lease Amount required.</p>
                    )}
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup>
										<Label for='commission'>Commission</Label>
										<Row>
											<Col xs="8" className='pr-1'>
												<Input
													type='text'
													name='commission'
													value={commission}
													autoComplete='off'
													onChange={(e) => setCommission(e.target.value)}
													innerRef={register({
														required: true,
													})}
												/>
											</Col>
											<Col xs="4" className='pl-1'>
												<Select
													closeMenuOnSelect={true}
													options={
														[{
															"value": true,
															"label": "%"
														}, 
														{
															"value": false,
															"label": "$"
														}]
													}
													onChange={(commissionType) => {
														setIsPercentCommission(commissionType.value)
													}}
													value={isPercentCommission ? {"value": true, "label": "%"} : {"value": false, "label": "$"}}
													isMulti={false}
													placeholder='Select Type'
													className='multi-select-client w-100'
													classNamePrefix='default-select'
												/>
											</Col>
										</Row>
										{_.get('commission.type', errors) === 'required' && (
                        <p className='input-error'>* Commission required.</p>
                      )}
									</FormGroup>
								</Col>

								<Col md='8'>
									<FormGroup>
										<Label for='commission'>Concessions</Label>
											<Input
												type='text'
												id="concessionField"
												name='commission'
												value={concession}
												autoComplete='off'
												onChange={(e) => setConcession(e.target.value)}
												// disabled={true}
											/>
									</FormGroup>
								</Col>

								<Col md='4'>
									<FormGroup>
										<Label for='commission'>Invoice Total</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='money-bill-wave' />
											</InputGroupText>
											<Input
												type='text'
												name='totalCommission'
												value={isPercentCommission ? `  $${ leaseAmount && commission ? (commission*leaseAmount/100) : 0 }` : `  $${commission === null ? 0 : commission}`}
												autoComplete='off'
												// onChange={(e) => setCommission(e.target.value)}
												disabled={true}
											/>
										</InputGroup>
									</FormGroup>
								</Col>

								{ props.new !== 'new' ?
									<Col md='8'>
										<FormGroup>
											<Label for='sendToEmail'>Send To</Label>
												<Input
													type='text'
													id="sendToEmail"
													name='sendToEmail'
													value={sendToEmail}
													autoComplete='off'
													onChange={(e) => setSendToEmail(e.target.value)}
													innerRef={register({
														pattern:
															/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
													})}
												/>
												{_.get('sendToEmail.type', errors) === 'pattern' && (
													<p className='input-error'>* Invalid Email.</p>
												)}
										</FormGroup>
									</Col> : null
								}
							</Row>

							<Row>
								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='mls'>MLS #</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='list-ol' />
											</InputGroupText>
											<Input
												type='text'
												name='mls'
												value={mls}
												autoComplete='off'
												onChange={(e) => setMls(e.target.value)}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='leasingAgentName'>Leasing Agent Name</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='user' />
											</InputGroupText>
											<Input
												type='text'
												name='leasingAgentName'
												value={leasingAgentName}
												autoComplete='off'
												onChange={(e) => setLeasingAgentName(e.target.value)}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='referralAgentName'>Referral Agent Name</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='user' />
											</InputGroupText>
											<Input
												type='text'
												name='referralAgentName'
												value={referralAgentName}
												autoComplete='off'
												onChange={(e) => setReferralAgentName(e.target.value)}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='referralAgentEmail'>Referral Agent Email</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='envelope' />
											</InputGroupText>
											<Input
												type='email'
												name='referralAgentEmail'
												value={referralAgentEmail}
												autoComplete='off'
												onChange={(e) => setReferralAgentEmail(e.target.value)}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup>
										<Label for='referralCommission'>Referral Commission</Label>
										<Row>
											<Col xs="8" className='pr-1'>
												<Input
													type='text'
													name='referralCommission'
													value={referralCommission}
													autoComplete='off'
													onChange={(e) => setReferralCommission(e.target.value)}
												/>
											</Col>
											<Col xs="4" className='pl-1'>
												<Select
													closeMenuOnSelect={true}
													options={
														[{
															"value": true,
															"label": "%"
														}, 
														{
															"value": false,
															"label": "$"
														}]
													}
													onChange={(referralCommissionType) => {
														setIsPercentReferralCommission(referralCommissionType.value)
													}}
													value={setIsPercentReferralCommission ? {"value": true, "label": "%"} : {"value": false, "label": "$"}}
													isMulti={false}
													placeholder='Select Type'
													className='multi-select-client w-100'
													classNamePrefix='default-select'
												/>
											</Col>
										</Row>
									</FormGroup>
								</Col>
							</Row>

							<Row>
								<Col md='4'>
									<FormGroup className='inputs-icons'>
										<Label for='checkNumber'>Check Number</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='money-check' />
											</InputGroupText>
											<Input
												type='text'
												name='checkNumber'
												value={checkNumber}
												autoComplete='off'
												onChange={(e) => setCheckNumber(e.target.value)}
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup>
										<Label for='checkDate'>Check Date</Label>
										<InputGroup>
											<DatePicker
												wrapperClassName='r-date-time'
												className='form-control r-date-time'
												selected={checkDate}
												onChange={(date) => setCheckDate(date)}
												dateFormat='MMMM d, yyyy'
											/>
										</InputGroup>
									</FormGroup>
								</Col>
								<Col md='4'>
									<FormGroup>
                    <Label for='notes'>Notes</Label>
                    <InputGroup size='lg'>
                      <InputGroupText>
                        <Icon icon='clipboard' />
                      </InputGroupText>
                      <Input
                        type='text'
                        name='notes'
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        autoComplete='off'
                      />
                    </InputGroup>
                  </FormGroup>
								</Col>
							</Row>

							<Row>
                <Col md='12' className='mt-md-2'>
                  <Button
                    color='primary'
										name="saveBtn"
										onMouseDown={() => setIsSubmit(false)}
                    disabled={progress}
                    className='button-dashboard mr-3'
                  >
                    Save
                  </Button>
                  
									<Button
										color='primary'
										name="saveAndSendBtn"
										onMouseDown={() => setIsSubmit(true)}
										disabled={progress}
										className='button-dashboard mr-3'
									>
										Save & Send
									</Button>
									
                  <Button
                    className='button-dashboard mr-3'
                    onClick={() => props.sidebarToggle()}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
						</Form>
					</Col>

				</Row>
			</div>

		</>
	)
}
