import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
export default function MoveIn({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  const onChange = (dates) => {
    const [start, end] = dates;
    addFilter({
      e: {
        target: { name: 'availFrom', value: start },
      },
    });
    addFilter({
      e: {
        target: { name: 'availTo', value: end },
      },
    });
    /* setStartDate(start);
    setEndDate(end); */
  };
  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'moveInDate' && 'hidden'
      }`}
    >
      <div className='filter-box' style={style}>
        <h6>Earliest - Latest Move Date Range</h6>
        <Row>
          <Col md='12' className='text-center'>
            <DatePicker
              selected={getFilter('availFrom') || new Date()}
              startDate={getFilter('availFrom')}
              endDate={getFilter('availTo')}
              selectsRange
              inline
              onChange={onChange}
              /* monthsShown={2} */
              minDate={new Date()}
              calendarClassName='filter-bar-calendar no-border'
              disabledKeyboardNavigation
              showDisabledMonthNavigation
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
