import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Row,
	Col,
	Button,
	UncontrolledTooltip
} from 'reactstrap';
import { UPDATE_REPORT } from '@api'
import { Collapse, unitTypes, pastDate, SimpleToggleButton } from '@sparklib';
import { Icon } from '@assets';
import SimpleRTable from './SimpleRTable';
import config from '@config';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

export default function ClientPropertyReport({ report, reportProperties, handlePropertyDelete, refetchClientReports, searchMatchingProperties, client }){
	const history = useHistory();
	const [isShow, setIsShow] = useState(report.isShow);
	const [reportHidePropertyName, setReportHidePropertyName] = useState(report.hidePropertyName);
	const [isChecked, setIsChecked] = useState([]);
	const [finalReportProperties, setFinalReportProperties] = useState(reportProperties);

	const [updateClientReport] = useMutation(UPDATE_REPORT, {
		onCompleted: (data) => {
			if (data.updateReport.errors) {
				toast(data.updateReport.message, { type: 'error' });
			} else {
				toast(data.updateReport.message, { type: 'success' });
				refetchClientReports()
			}
		}
	})

	useEffect(() => {
		let reportPropertiesSelectedByAgent = reportProperties.filter(e => e.clientRating === "no_rating");
		let reportPropertiesSelectedByClient = reportProperties.filter(e => e.clientRating !== "no_rating");
	
		let finalReportProperties = sortReportProperties(reportPropertiesSelectedByClient).concat(sortReportProperties(reportPropertiesSelectedByAgent));
		setFinalReportProperties(finalReportProperties);
	
	}, [reportProperties]);

	useEffect(() => {
		if (isShow !== report.isShow){
			updateClientReport({
				variables: {
					id: Number(report.id),
					isShow: isShow,
				}
			})
		}
	}, [isShow])

	useEffect(() => {
		if (reportHidePropertyName !== report.hidePropertyName){
			updateClientReport({
				variables: {
					id: Number(report.id),
					hidePropertyName: reportHidePropertyName,
				}
			})
		}
	}, [reportHidePropertyName])

	const currencyFormatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 0,
  });

	function toolTip(txt, id){
		return (
			<>
				<span
					href="#"
					id={'tooltip-'+id}
					style={{
						color: 'black',
					}}
				>
					<Icon icon='clipboard' />
				</span>
				<UncontrolledTooltip
					placement="top"
					target={'tooltip-'+id}
					style={{
						color: 'white',
						backgroundColor: 'black',
						fontWeight: 'bold'
					}}
				>
					{txt}
				</UncontrolledTooltip>
			</>
		)
	}

	const columns = React.useMemo(
    () => [
      {
        Header: 'Client Feedback',
        accessor: 'clientNotes',
        Cell: ({ value, row }) => (
          <>
						{getIcon(row.original)}{value ? <>&nbsp;&nbsp;{toolTip(value, row.original.id)}</> : null}
          </>
        ),
				width: 30,
				disableSortBy: true
      },
      {
        Header: 'Property',
        accessor: 'property.name',
        Cell: ({ value, row }) => (
					<>
						{row.original.property.webLink ? (
							<a
							href={row.original.property.webLink}
							target='_blank'
							>
								{value}
							</a>
						) : value }
						{row.original.property.phone &&
							<>
								<br/>
								<Icon icon='phone-alt' />{' '}
								<a href={'tel:' + row.original.property.phone} target='_blank'>
									{row.original.property.phone}
								</a>
							</>
						}
						{row.original.property.managementCompany &&
							<>
								<br/>
								<Icon icon='building' />{' '}
								{row.original.property.managementCompany}
							</>
						}
					</>
        ),
      },
			{
        Header: 'Floor Plan',
        accessor: 'floorPlan',
        Cell: ({ value, row }) => (
          value && <div>
						{value?.name}<span className='mx-2'>·</span>{unitTypes[value?.bed]}<span className='mx-2'>·</span>{value?.bath} Bath
          </div>
        ),
				disableSortBy: true
      },
      {
        Header: 'Unit',
        accessor: 'typeDetail',
        Cell: ({ value, row }) => (
					value && <div>						
						{/* {(value.size && value?.price && value.moveIn) && <>#{value.aptNo}<span className='mx-2'>·</span>{currencyFormatter.format(value?.size)} Sqft<sup>2</sup><span className='mx-2'>·</span>
						${currencyFormatter.format(value?.price)}<span className='mx-2'>·</span>{pastDate(value?.moveIn, true)}</>
						} */}
						{value.aptNo && <>#{value.aptNo}<span className='mx-2'>·</span></>}
						{value?.size && <>{currencyFormatter.format(value?.size)} Sqft<sup>2</sup><span className='mx-2'>·</span></>}
						{value?.price && <>${currencyFormatter.format(value?.price)}<span className='mx-2'>·</span></>}
						{value?.moveIn && <>{pastDate(value?.moveIn, true)}</>}
					</div>
				),
				disableSortBy: true
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value, row }) => (
					<div
						onClick={() => handlePropertyDelete(value, report.id)}
						style={{cursor:"pointer", color: "red"}}
					>
						<Icon icon='trash'/>
					</div> 
        ),
				width: 10,
				disableSortBy: true
      }
    ]
  );

	return (
		<>
			<Collapse title={<a target='_blank' onClick={(e) => e.stopPropagation()} href={config.url.WEB_DOMAIN + 'recommendations?code=' + report.reportCode}>{report.title}</a>} open={true} className={'mt-3 mb-2 client-popup'}>
				<Row>
					<Col md='12 mb-3'>
						<SimpleRTable
							data={finalReportProperties}
							columns={columns}
							changeIsChecked={(isChecked) => setIsChecked(isChecked)}
							addons={
								<>
									<div className=' mr-5 inline-block pt-1'>
										<p style={{ display: 'inline-block', marginRight: "5px"}}>Temporarily Deactivate List</p>&nbsp;
										<SimpleToggleButton
											selected={!isShow}
											firstOption='&nbsp;'
											secondOption='&nbsp;'
											width='65px'
											rounded
											toggleSelected={() => {
												setIsShow(!isShow);
											}}
										/>
									</div>
									<div className=' mr-5 inline-block pt-1'>
										<p style={{ display: 'inline-block', marginRight: "5px"}}>Hide Property Names</p>&nbsp;
										<SimpleToggleButton
											selected={reportHidePropertyName}
											firstOption='&nbsp;'
											secondOption='&nbsp;'
											width='65px'
											rounded
											toggleSelected={() => {
												setReportHidePropertyName(!reportHidePropertyName);
											}}
										/>
									</div>
								</>
							}
							leftAddons={
								<>
									<Button
										color='success'
										className='btn btn-rounded'
										onClick={() => searchMatchingProperties(client)}
									>
										Search and Add More
									</Button>
									<Button
										color='red'
										onClick={() => handlePropertyDelete(isChecked, report.id)}
										disabled={isChecked.length > 0 ? false : true}
										className='button-dashboard float-right mt-3 mt-sm-0'
									>
										<Icon icon='trash-alt' className='mr-2' />
										Delete
									</Button>
								</>
							}
						/>
					</Col>
				</Row>
			</Collapse>
		</>
	)
}

function sortReportProperties(reportPropertiesArray){

	let sortedByProperyName = [...reportPropertiesArray].sort((a,b) => {
		if (a.property.name > b.property.name) {
			return 1;
		} else if (a.property.name < b.property.name){
			return -1;
		} else return 0;
	})

	let filteredReportProperties = [...sortedByProperyName].sort((a,b) => {
		if (a.property.name === b.property.name){
			if (a.floorPlan === null && b.floorPlan !== null){
				return -1;
			} else if (a.floorPlan !== null && b.floorPlan === null){
				return 1;
			} else if (a.floorPlan === null && b.floorPlan === null){
				return 0;
			} else {
				if (a.floorPlan.name === b.floorPlan.name){
					if (a.typeDetail === null && b.typeDetail !== null){
						return -1;
					} else if (a.typeDetail !== null && b.typeDetail === null){
						return 1;
					}
				}
				else if (a.floorPlan?.name > b.floorPlan?.name){
					return 1;
				} else {
					return -1;
				}
			}
		} else {
			if (a.property.name > b.property.name) {
				return 1;
			} else {
				return -1;
			}
		}
	})

	return filteredReportProperties
}

function getIcon(reaction){
	const ratingIcons = {
		dislike: 'thumbs-down',
		like: 'thumbs-up',
		love: 'heart',
	};
	if (reaction) {;
	return (
		ratingIcons[reaction.clientRating] ?
			<Icon
				icon={ratingIcons[reaction.clientRating]}
				style={{ color: reaction.clientRating === "like" ? 'var(--theme-green)' : 'var(--theme-red)' }}
				className='text-right ml-3'
				size='lg'
			/> : null
	)}
	else {
		return null
	}
}