import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { NOTIFY_MISSING_PROPERTY_PHOTOS } from '@api';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

// const default_items = [
//   {
//     src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
//     altText: 'Slide 1',
//     caption: 'Slide 1'
//   },
//   {
//     src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
//     altText: 'Slide 2',
//     caption: 'Slide 2'
//   },
//   {
//     src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
//     altText: 'Slide 3',
//     caption: 'Slide 3'
//   }
// ];

export default function Slider({ items = [], isLazyLoad = true, propertyId, ...props }) {
  const [notifyMissingPropertyPhotos] = useMutation(NOTIFY_MISSING_PROPERTY_PHOTOS, {
    skip: !propertyId,
    onCompleted: (data) => {
      if (data.notifyMissingPropertyPhotos.message) {
        toast(data.notifyMissingPropertyPhotos.message, { type: 'success' });
      }
      if (data.notifyMissingPropertyPhotos.errors && data.notifyMissingPropertyPhotos.errors.length > 0) {
        data.notifyMissingPropertyPhotos.errors.map((err) => toast(err, { type: 'error' }));
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleNotifyMissingPropertyPhotos = (propertyId) => {
    notifyMissingPropertyPhotos({
      variables: {
        propertyId: parseInt(propertyId),
      }
    })
  };

  if (items.length === 0) {
    return (
      <div className='d-flex align-items-center text-center no-image'>
        No image found.{' '}
        {propertyId ? (
          <>
            Let our team know by clicking<a href="javascript:void(0)" className="ml-1" onClick={(e) => handleNotifyMissingPropertyPhotos(propertyId)}>here</a>.
          </>
        ) : (<></>)
        }
      </div>
    );
  } else if (items.length === 1) {
    return <img src={items[0].src} alt={items[0].altText} {...props} />;
  }

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
				{
					isLazyLoad ? 
					<LazyLoadImage 
						className='d-block w-100' 
						effect="blur"
						src={item.src} 
						alt={item.altText} 
						// threshold={500}
						delayTime={0}
					/> : 
					<img
						className='d-block w-100' 
						// effect="blur"
						src={item.src} 
						alt={item.altText} 
					/> 
				}


        <CarouselCaption
          captionText={item.caption || ''}
          captionHeader={item.caption || ''}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
			pause={props.noAnimation ? false : 'hover'}
      {...props}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
      </div>
      {slides}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CarouselControl
          direction='prev'
          directionText='Previous'
          onClickHandler={previous}
        />
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CarouselControl
          direction='next'
          directionText='Next'
          onClickHandler={next}
        />
      </div>
    </Carousel>
  );
}
