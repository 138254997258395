import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { RequestSentBanner } from '@assets';

const RequestSent = (props) => {
  const { reportCode } = props.location.details;

  const history = useHistory();

  const goBack = () => {
    window.location = '/recommendations?code=' + reportCode;
  };

  return (
    <>
      <Container className='text-center'>
        <Row>
          <Col
            md={{ size: '10', offset: '1' }}
            lg={{ size: '8', offset: '2' }}
            className='my-5'
          >
            <img
              src={RequestSentBanner}
              alt='Request Sent Banner'
              className='img-fluid'
            />
          </Col>

          <Col md='12'>
            <h2>Tour Request Sent</h2>
          </Col>

          <Col md='12' className='mt-5 text-center'>
            <Button
              color='primary'
              className='button-dashboard'
              onClick={goBack}
            >
              Back to Home Page
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RequestSent;
