import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import NavBar from '../NewLanding/components/newNavbar';
import { FORGET_PASSWORD } from '@api';
import { useMutation } from '@apollo/client';
import DivisionTitle, { DivisionSubTitle } from '../NewLanding/components/divisionTitle';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

export default function ForgotPassword() {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [forgotPassword, { loading, error }] = useMutation(FORGET_PASSWORD, {
    onCompleted: (data) => {
      if (data.forgotPassword.errors && data.forgotPassword.errors.length > 0) {
        data.forgotPassword.errors.map((err) => toast(err, { type: 'error' }));
      } else {
        toast(data.forgotPassword.message, { type: 'success' });
        document.getElementById('email').value = '';
        setProgress(false);
      }
    },
    onError: (e) => {
      console.log(e, 'error');
    },
  });
  const [progress, setProgress] = useState(false);

  const onSubmit = (data, e) => {
    setProgress(true);
    forgotPassword({
      variables: {
        email: data.email,
      },
    });
  };

  return (
    <>
      <NavBar />

      {/* <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Forgot Password?</h1>
        </Container>
      </Container> */}

      <Container fluid className='py-5em mt-5em text-center'>
        <Container>
          <Row className='px-lg-5'>
            <Col md='12'>
              <DivisionTitle
                className="mb-4"
                title="Forgot Password?"
              />
              <DivisionSubTitle
                className="mb-5"
                title="Enter your email and we will send you instructions."
              />
            </Col>
            <Col md={{ size: 4, offset: 4 }} className='inputFields'>
              <Form className='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Email'
                    id='email'
                    name='email'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                  {_.get('email.type', errors) === 'required' && (
                    <p className='input-error'>* Email required.</p>
                  )}
                  {_.get('email.type', errors) === 'pattern' && (
                    <p className='input-error'>* Invalid Email.</p>
                  )}
                </FormGroup>

                <Button color='green' size='lg' className="btn-rounded">
                  {progress ? 'Progress' : 'Send Instructions'}
                </Button>
                <p className='mt-3'>
                  <small>
                    Back to <NavLink to='/login'>Log in</NavLink>
                  </small>
                </p>
              </Form>
            </Col>
          </Row>

          <Row className='pt-5 privacy-policy'>
            <Col md={{ size: 4, offset: 4 }}>
              <p className='mb-0'>
                <small>By Continuing you agree to accept our</small>
              </p>
              <p className='mb-5'>
                <small>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink> &{' '}
                  <NavLink to='/terms-of-service'>Terms of Service.</NavLink>
                </small>
              </p>
              <hr className='border-light' />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
