import { Spinner } from '@assets';

const Loader = () => {
  return (
    <div className='modal-backdrop fade show'>
      <img
        src={Spinner}
        alt='spinner'
        className='loader'
        style={{ width: 'auto', height: '3.5rem' }}
      />
    </div>
  );
};

export default Loader;
