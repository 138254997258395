import React, { useEffect } from 'react';
import { Table, Row, Col, FormGroup, Label } from 'reactstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from 'react-table';

import Select from 'react-select';
import { Icon } from '@assets';
import { useHistory, useParams } from 'react-router-dom';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type='checkbox'
          className='custom-checkbox'
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className='search-bar d-inline-block inline-block'>
      <FormGroup className='mb-0 w-auto d-inline-block'>
        <Label for='appartment' className='mb-0 w-auto d-inline-block mr-2'>
          <Icon icon='search' />
        </Label>
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder='Search by keywords'
        />
      </FormGroup>
    </div>
  );
}

export default function ClientListTable({ columns, data, addons, changeIsChecked, setSidebarState, leftAddons=null }) {
  var totalRecord = data.length;
  const pageSizeOptions = [
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '75', label: '75' },
    { value: '100', label: '100' },
    { value: totalRecord.toString(), label: 'All' },
  ];
  const defaultColumn = React.useMemo(
    () => ({
      width: 'auto',
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    rows,
    state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    prepareRow,
  } = useTable(
    { columns, data, defaultColumn, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        changeIsChecked
          ? [
              // Let's make a column for selection
              {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps }) => (
                  <IndeterminateCheckbox
                    {...getToggleAllPageRowsSelectedProps()}
                  />
                ),
                Cell: ({ row }) => (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={(e) => e.stopPropagation()}/>
                ),
                width: 50,
                maxWidth: 50,
              },
              ...columns,
            ]
          : columns
      );
    }
  );

  useEffect(() => {
    if (changeIsChecked) {
      changeIsChecked(selectedFlatRows);
    }
  }, [selectedFlatRows]);

	const history = useHistory();

  return (
    <>
      <Row className='mb-4 d-flex align-items-center'>
        <Col sm='6'>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
					{leftAddons}
        </Col>

        <Col sm='6' className='text-lg-right'>
          {addons}
        </Col>
      </Row>

      <Table
        responsive
        className='client-list text-left min-w-900'
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        maxWidth: column.maxWidth,
                      },
                    },
                    column.getSortByToggleProps(),
                  ])}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <>
                          &nbsp; <Icon icon='sort-down' size='lg' />
                        </>
                      ) : (
                        <>
                          &nbsp; <Icon icon='sort-up' size='lg' />
                        </>
                      )
                    ) : column.canSort ? (
                      <>
                        &nbsp; <Icon icon='sort' size='lg' />
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
							onClick={() => {
								setSidebarState(true);
								history.push(`/clients/show/${row.original.node.id}`)
							}}
							>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
					{page.length <= 2 ? <tr><div className='spacer-div'></div></tr> : null}
        </tbody>
      </Table>

      <Row className='d-flex align-items-center mt-4'>
        <Col md='6'>
          {/*  Showing first {pageSize} results of {rows.length} rows */}
          <FormGroup className='mb-3 mb-md-0'>
            <Label
              for='appartment'
              className='mb-0 w-auto d-inline-block mr-2 mr-md-3'
            >
              Showing Entries
            </Label>
            <Select
              name='PageSize'
              defaultValue={pageSizeOptions[0]}
              options={pageSizeOptions}
              onChange={(e) => {
                setPageSize(Number(e.value));
              }}
              className='basic-multi-select text-left'
              classNamePrefix='default-select'
              menuPlacement={'auto'}
            />
          </FormGroup>
        </Col>

        <div className='justify-content-center'>
          <ul className='pagination'>
            <li onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              <a>
                {/* <Icon icon="angle-double-left" size="lg" /> */}
                <Icon icon='chevron-left' />
                <Icon icon='chevron-left' />
              </a>
            </li>
            <li onClick={() => previousPage()} disabled={!canPreviousPage}>
              <a>
                {/* <Icon icon="angle-left" size="lg" /> */}
                <Icon icon='chevron-left' />
              </a>
            </li>
            <span className='d-flex align-items-center px-3'>
              Page &nbsp; <strong>{pageIndex + 1}</strong> &nbsp; of &nbsp;
              <strong>{pageOptions.length}</strong>
            </span>
            <li onClick={() => nextPage()} disabled={!canNextPage}>
              <a>
                {/* <Icon icon="angle-right" size="lg" /> */}
                <Icon icon='chevron-right' />
              </a>
            </li>
            <li onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <a>
                {/* <Icon icon="angle-double-right" size="lg" /> */}
                <Icon icon='chevron-right' />
                <Icon icon='chevron-right' />
              </a>
            </li>
          </ul>
        </div>
      </Row>
    </>
  );
}
