import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { Icon } from '@assets';
import { SUBSCRIPTIONS } from '@api';
import { useQuery } from '@apollo/client';
import Spinner from '../Spinner';
/* import Select from "react-select"; */
import RTable from '../RTable';

export default function Subscription() {
  const [subscriptionList, setSubscriptionList] = useState('loading');
  const [subscription, setSubscription] = useState([]);
  const {
    refetch,
    data: subscriptionsData,
    loading,
    error,
  } = useQuery(SUBSCRIPTIONS);

  useEffect(() => {
    //subscriptionsData && setSubscription(subscriptionsData.subscriptions.edges);
    if (subscriptionsData) {
      if (subscriptionsData.subscriptions.totalCount === 0) {
        setSubscriptionList('noData');
      } else {
        setSubscriptionList('data');
        setSubscription(subscriptionsData.subscriptions.edges);
      }
    }
  }, [subscriptionsData]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'node.user.name',
      },
      {
        Header: 'Email',
        accessor: 'node.user.email',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Plan',
        accessor: 'node.plan',
        Cell: ({ value, row }) => <div>{value ? value.name : '-'}</div>,
      },
      {
        Header: 'Stripe Subscription ID',
        accessor: 'node.stripeSubscriptionId',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Amount',
        accessor: 'node.plan.amount',
        Cell: ({ value, row }) => (
          <div>
            {value
              ? '$' +
                row.original.node.plan.amount +
                '/' +
                row.original.node.plan.interval
              : '-'}
          </div>
        ),
      },
      {
        Header: 'Subscription Period',
        accessor: 'node.currentEndDatetime',
        Cell: ({ value, row }) => (
          <div>
            {value
              ? formatDate(row.original.node.currentStartDatetime) +
                '-' +
                formatDate(row.original.node.currentEndDatetime)
              : '-'}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'node.active',
        Cell: ({ value, row }) => (
          <div className='client-select-stage'>
            <span
              className={
                value === true ? 'p-2 badge-success' : 'p-2 badge-danger'
              }
            >
              {row.original.node.status}
            </span>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {loading && <Spinner />}
      <Row className='mt-md-2'>
        <Col md='12'>
          {subscriptionList === 'data' ? (
            <RTable columns={columns} data={subscription} filterable />
          ) : (
            <>
              {subscriptionList === 'noData' ? (
                <h2 className='text-center mt-5'>Subscription not found</h2>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
