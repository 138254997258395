import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { Row, Col, FormGroup, Label, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Iframe } from 'reactstrap';
import '../../../../assets/styles/Filterbar.css';
import moment from 'moment';
import CityNeighborhoodFilter from './filters/CityNeighborhood';
import PriceFilter from './filters/Price';
import MoveInFilter from './filters/MoveIn';
import BedBathFilter from './filters/BedBath';
import AmenitiesFilter from './filters/Amenities';
import PoliciesFilter from './filters/Policies';
import UnitFeaturesFilter from './filters/UnitFeatures';

import { useQuery } from '@apollo/client';

import { unitTypes } from '@sparklib';
import { Icon, filterIcon } from '@assets';

export default function FilterBar ({
  filters,
  getFilter,
  setFilter: addFilter,
  changeFilters,
  shouldSearch,
  setShouldSearch,
  setShouldMapSearch,
  shouldMapSearch,
}) {

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [activeFilterPill, setActiveFilterPill] = useState(null);
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const numberFormatter = new Intl.NumberFormat('en-US');

  // const { loading: propertyLoading } = useQuery(PROPERTIES, {
  //   variables: {
  //     cityId: selctedCity.value ? parseInt(selctedCity.value) : null,
  //     search: filters.searchText ? filters.searchText : null,
  //     minRent: filters.minPrice ? parseInt(filters.minPrice) : null,
  //     maxRent: filters.maxPrice ? parseInt(filters.maxPrice) : null,
  //     availFrom: filters.availFrom ? filters.availFrom : null,
  //     availTo: filters.availTo ? filters.availTo : null,
  //     yearFrom: filters.fromYear ? filters.fromYear : null,
  //     yearTo: filters.toYear ? filters.toYear : null,
  //     sqFeet: filters.sqFt ? filters.sqFt : null,
  //     escort: filters.commisson ? filters.commisson : null,
  //     sendEscort: filters.minSend ? filters.minSend : null,
  //     zip: filters.zip ? filters.zip : null,
  //     bedroom:
  //       filters.seletedBedSize.length > 0
  //         ? filters.seletedBedSize.map((opt) => opt.label)
  //         : null,

  //     bathroom:
  //       filters.seletedBathRoom.length > 0
  //         ? filters.seletedBathRoom.map((opt) => opt.label)
  //         : null,
  //     pet: filters.selectedPet ? filters.selectedPet : null,
  //     neighborhoodIds:
  //       filters.selectedNeighborhood.length > 0
  //         ? filters.selectedNeighborhood.map((opt) => Number(opt.value))
  //         : null,
  //     addons:
  //       filters.addons.length > 0
  //         ? filters.addons.map((opt) => opt.label)
  //         : null,
  //     policies:
  //       filters.policies.length > 0
  //         ? filters.policies.map((opt) => opt.label)
  //         : null,
  //   },
  //   skip: !selctedCity.value || sidebarOpen,
  //   onCompleted: (data) => {
  //     props.changeProperty(data.properties.edges);
  //   },
  //   onError: (e) => {
  //     console.log(e, 'error');
  //   },
  // });

  useEffect(() => {
    if (!activeFilterPill) {
      setShouldSearch(true);
      setShouldMapSearch(true);
      var mapMarkersResetBtn = document.getElementById('reset-map-markers-btn');
      if (mapMarkersResetBtn) mapMarkersResetBtn.click();
    }
  }, [activeFilterPill]);

  return (
    <>
      <div
        className='py-3 border-bottom fixed-top filter-bar-container d-sm-none d-md-block'
        style={{ top: '65px', zIndex: 10 }}
      >
        <div className='text-center px-0'>
          {/* Removed from line above -- style={{overflowX: 'auto', overflowY: 'hidden'}}> */}
          <div className={`filter-bar ${activeFilterPill && 'active'}`}>
            {/* Search Field */}
            <div className='search-bar'>
              <FormGroup className='mb-0 d-inline-flex flex-grow-1 align-items-center'>
                <Label for='search' className='mb-0 w-auto d-inline-flex mr-1'>
                  <Icon icon='search' />
                </Label>
                <DebounceInput
                  type='search'
                  autoComplete='off'
                  id='search'
                  name='search'
                  placeholder='Search by anything'
                  debounceTimeout={1000}
                  value={getFilter('search')}
                  onChange={(e) => {
                    addFilter({ e });
                    setShouldSearch(true);
                    setActiveFilterPill(null);
                    setShouldMapSearch(true);
                  }}
                />
              </FormGroup>
            </div>

            <div
              className='applied-filters filters d-flex text-left align-items-center'
              // style={{ overflow: 'scroll', maxWidth: '72vw' }}
            >
              <FilterPill
                filterValue={JSON.parse(getFilter('city'))?.label}
                filterLabel='Neighborhood'
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: ['city', 'neighborhoods', 'zipCodes'],
                  });
                }}
                active={activeFilterPill === 'cityAndNeighborhoods'}
                onClick={() =>
                  activeFilterPill !== 'cityAndNeighborhoods'
                    ? setActiveFilterPill('cityAndNeighborhoods')
                    : setActiveFilterPill(null)
                }
              >
                <CityNeighborhoodFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                />
              </FilterPill>

              <FilterPill
                filterValue={
                  getFilter('minPrice') && getFilter('maxPrice')
                    ? currencyFormatter.format(getFilter('minPrice')) +
                      '-' +
                      currencyFormatter.format(getFilter('maxPrice'))
                    : getFilter('minPrice')
                    ? currencyFormatter.format(getFilter('minPrice')) +
                      ' & above'
                    : getFilter('maxPrice')
                    ? 'Below ' + currencyFormatter.format(getFilter('maxPrice'))
                    : ''
                }
                filterLabel='Price'
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: ['minPrice', 'maxPrice'],
                  });
                }}
                active={activeFilterPill === 'price'}
                onClick={() =>
                  activeFilterPill !== 'price'
                    ? setActiveFilterPill('price')
                    : setActiveFilterPill(null)
                }
              >
                <PriceFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                />
              </FilterPill>

              <FilterPill
                filterValue={
                  getFilter('bed').length > 0 &&
                  JSON.parse(getFilter('bath'))?.label
                    ? unitTypes[getFilter('bed')[0]] +
                      '-' +
                      JSON.parse(getFilter('bath'))?.label +
                      '+ Bath'
                    : getFilter('bed').length > 0
                    ? unitTypes[getFilter('bed')[0]]
                    : JSON.parse(getFilter('bath'))?.label
                    ? JSON.parse(getFilter('bath'))?.label + '+ Bath'
                    : ''
                }
                filterLabel='Bed / Bath'
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: ['bed', 'bath'],
                  });
                }}
                active={activeFilterPill === 'bedbath'}
                onClick={() =>
                  activeFilterPill !== 'bedbath'
                    ? setActiveFilterPill('bedbath')
                    : setActiveFilterPill(null)
                }
              >
                <BedBathFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                />
              </FilterPill>

              <FilterPill
                filterValue={
                  getFilter('availFrom') && getFilter('availTo')
                    ? new Date(getFilter('availFrom')) <= new Date()
                      ? 'Before ' + moment(getFilter('availTo')).format('MMM D')
                      : moment(getFilter('availFrom')).format('MMM D') ===
                        moment(getFilter('availTo')).format('MMM D')
                      ? 'Available on ' +
                        moment(getFilter('availFrom')).format('MMM D')
                      : moment(getFilter('availFrom')).format('MMM D') +
                        '-' +
                        moment(getFilter('availTo')).format('MMM D')
                    : getFilter('availFrom')
                    ? moment(getFilter('availFrom')).format('MMM D') +
                      ' & after'
                    : getFilter('availTo')
                    ? 'Before ' + moment(getFilter('availTo')).format('MMM D')
                    : ''
                }
                filterLabel='Move-in Date'
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: ['availFrom', 'availTo'],
                  });
                }}
                active={activeFilterPill === 'moveInDate'}
                onClick={() =>
                  activeFilterPill !== 'moveInDate'
                    ? setActiveFilterPill('moveInDate')
                    : setActiveFilterPill(null)
                }
              >
                <MoveInFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                />
              </FilterPill>

              {/* <div
                style={{ borderRight: '1px solid #ddd', height: '32px' }}
              ></div> */}

              <FilterPill
                filterValue={[
                  getFilter('sqFt')
                    ? `${numberFormatter.format(getFilter('sqFt'))} sqft+`
                    : '',
                  // getFilter('sqFt') && getFilter('unitFeatures').length > 0
                  //   ? ' - '
                  //   : '',
                  // getFilter('unitFeatures').length > 1
                  //   ? getFilter('unitFeatures')[0].label +
                  //     ` +${getFilter('unitFeatures').length - 1}`
                  //   : getFilter('unitFeatures').length === 1
                  //   ? getFilter('unitFeatures')[0].label
                  //   : '',
                  // !getFilter('sqFt') &&
                  //   getFilter('unitFeatures').length === 0 &&
                  //   ''
                  // ),
                ].join('')}
                filterLabel='Unit Size'
                // isMulti={true}
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: ['unitFeatures', 'sqFt'],
                  });
                }}
                active={activeFilterPill === 'unitFeatures'}
                onClick={() =>
                  activeFilterPill !== 'unitFeatures'
                    ? setActiveFilterPill('unitFeatures')
                    : setActiveFilterPill(null)
                }
              >
                <UnitFeaturesFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                  style={{ marginLeft: '-280px' }}
                />
              </FilterPill>

              <FilterPill
                filterValue={
                  getFilter('amenities').length > 1
                    ? getFilter('amenities')[0].label +
                      ` +${getFilter('amenities').length - 1}`
                    : getFilter('amenities').length === 1
                    ? getFilter('amenities')[0].label
                    : ''
                }
                filterLabel='Amenities'
                // isMulti={true}
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: 'amenities',
                  });
                }}
                active={activeFilterPill === 'amenities'}
                onClick={() =>
                  activeFilterPill !== 'amenities'
                    ? setActiveFilterPill('amenities')
                    : setActiveFilterPill(null)
                }
              >
                <AmenitiesFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                  style={{
                    marginLeft: '-257px',
                  }}
                />
              </FilterPill>

              <FilterPill
                filterValue={
                  getFilter('policies').length === 0
                    ? getFilter('escort') > 0
                      ? 'Escort ' + getFilter('escort') + '%'
                      : getFilter('minSend') > 0
                      ? 'Send ' + getFilter('minSend') + '%'
                      : getFilter('bonus') > 0
                      ? 'Flat Fee ' +
                        currencyFormatter.format(getFilter('bonus'))
                      : getFilter('yearFrom')
                      ? 'Built after ' + getFilter('yearFrom')
                      : getFilter('renovatedFrom')
                      ? 'Renovated after ' + getFilter('renovatedFrom')
                      : getFilter('googleRating') > 0
                      ? 'Google Rating ' +
                        getFilter('googleRating') +
                        (getFilter('googleRating') === 5 ? '' : '+')
                      : ''
                    : getFilter('policies').length > 1
                    ? getFilter('policies')[0].label +
                      ` +${getFilter('policies').length - 1}`
                    : getFilter('policies').length === 1
                    ? getFilter('policies')[0].label
                    : ''
                }
                filterLabel='Property & Policies'
                // isMulti={true}
                resetFilter={() => {
                  changeFilters({
                    type: 'reset',
                    name: [
                      'policies',
                      'escort',
                      'minSend',
                      'bonus',
                      'yearFrom',
                      'renovatedFrom',
                      'googleRating',
                    ],
                  });
                }}
                active={activeFilterPill === 'policies'}
                onClick={() =>
                  activeFilterPill !== 'policies'
                    ? setActiveFilterPill('policies')
                    : setActiveFilterPill(null)
                }
              >
                <PoliciesFilter
                  filters={filters}
                  getFilter={getFilter}
                  addFilter={addFilter}
                  activeFilterPill={activeFilterPill}
                  style={{
                    marginLeft: '-255px',
                  }}
                />
              </FilterPill>
            </div>
            <div className='search-button'>
              {getFilter('search') ||
              JSON.parse(getFilter('city'))?.value ||
              getFilter('neighborhoods').length > 0 ||
              getFilter('zipCodes').length > 0 ||
              getFilter('minPrice') ||
              getFilter('maxPrice') ||
              getFilter('bed').length > 0 ||
              JSON.parse(getFilter('bath'))?.value ||
              getFilter('availFrom') ||
              getFilter('availTo') ||
              getFilter('sqFt') ||
              getFilter('unitFeatures').length > 0 ||
              getFilter('amenities').length > 0 ||
              getFilter('escort') > 0 ||
              getFilter('minSend') > 0 ||
              getFilter('bonus') > 0 ||
              getFilter('yearFrom') ||
              getFilter('renovatedFrom') ||
              getFilter('googleRating') ||
              getFilter('policies').length > 0 ? (
                <Button
                  className='h-100 w-100 btn btn-danger'
                  onClick={() => {
                    // changeFilters(noFilters);
                    setActiveFilterPill(null);
                    !shouldSearch && setShouldSearch(true);
                    !shouldMapSearch && setShouldMapSearch(true);
                    changeFilters({
                      type: 'reset',
                      name: [
                        'policies',
                        'escort',
                        'minSend',
                        'bonus',
                        'yearFrom',
                        'renovatedFrom',
                        'googleRating',
                        'yearFrom',
                        'yearTo',
                        'amenities',
                        'unitFeatures',
                        'sqFt',
                        'availFrom',
                        'availTo',
                        'bed',
                        'bath',
                        'minPrice',
                        'maxPrice',
                        'neighborhoods',
                        'zipCodes',
                        'search',
                      ],
                    });
                  }}
                  // disabled={shouldSearch}
                >
                  {/* <Icon icon='times' className='' /> */}
                  Clear Filters
                </Button>
              ) : (
                <Button
                  className='h-100 w-100 btn btn-blue'
                  onClick={() => {
                    // changeFilters(noFilters);
                    setActiveFilterPill(null);
                    !shouldSearch && setShouldSearch(true);
                    !shouldMapSearch && setShouldMapSearch(true);
                  }}
                  // disabled={shouldSearch}
                >
                  <Icon icon='search' className='' />
                </Button>
              )}
            </div>
          </div>
          {/* </Col> */}
        </div>
      </div>

      <div
        className='py-3 border-bottom fixed-top filter-bar-container d-md-none d-sm-block'
        style={{ top: '65px', zIndex: 10 }}
      >
        <div className='text-center px-0 d-flex'>
          <div className={`filter-bar ${activeFilterPill && 'active'} mr-3`}>
            <div className='search-bar'>
              <FormGroup className='mb-0 d-inline-flex flex-grow-1 align-items-center'>
                <Label for='search' className='mb-0 w-auto d-inline-flex mr-1'>
                  <Icon icon='search' />
                </Label>
                <DebounceInput
                  type='search'
                  autoComplete='off'
                  id='search'
                  name='search'
                  placeholder='Search by anything'
                  debounceTimeout={1000}
                  value={getFilter('search')}
                  onChange={(e) => {
                    addFilter({ e });
                    setShouldSearch(true);
                    setActiveFilterPill(null);
                    setShouldMapSearch(true);
                  }}
                />
              </FormGroup>
            </div>

            <div className='search-button'>
              {getFilter('search') ||
              JSON.parse(getFilter('city'))?.value ||
              getFilter('neighborhoods').length > 0 ||
              getFilter('zipCodes').length > 0 ||
              getFilter('minPrice') ||
              getFilter('maxPrice') ||
              getFilter('bed').length > 0 ||
              JSON.parse(getFilter('bath'))?.value ||
              getFilter('availFrom') ||
              getFilter('availTo') ||
              getFilter('sqFt') ||
              getFilter('unitFeatures').length > 0 ||
              getFilter('amenities').length > 0 ||
              getFilter('escort') > 0 ||
              getFilter('minSend') > 0 ||
              getFilter('bonus') > 0 ||
              getFilter('yearFrom') ||
              getFilter('renovatedFrom') ||
              getFilter('googleRating') ||
              getFilter('policies').length > 0 ? (
                <Button
                  className='h-100 w-100 btn btn-danger'
                  onClick={() => {
                    // changeFilters(noFilters);
                    setActiveFilterPill(null);
                    !shouldSearch && setShouldSearch(true);
                    !shouldMapSearch && setShouldMapSearch(true);
                    changeFilters({
                      type: 'reset',
                      name: [
                        'policies',
                        'escort',
                        'minSend',
                        'bonus',
                        'yearFrom',
                        'renovatedFrom',
                        'googleRating',
                        'yearFrom',
                        'yearTo',
                        'amenities',
                        'unitFeatures',
                        'sqFt',
                        'availFrom',
                        'availTo',
                        'bed',
                        'bath',
                        'minPrice',
                        'maxPrice',
                        'neighborhoods',
                        'zipCodes',
                        'search',
                      ],
                    });
                  }}
                  // disabled={shouldSearch}
                >
                  {/* <Icon icon='times' className='' /> */}
                  Clear Filters
                </Button>
              ) : (
                <Button
                  className='h-100 w-100 btn btn-blue'
                  onClick={() => {
                    // changeFilters(noFilters);
                    setActiveFilterPill(null);
                    !shouldSearch && setShouldSearch(true);
                    !shouldMapSearch && setShouldMapSearch(true);
                  }}
                  // disabled={shouldSearch}
                >
                  <Icon icon='search' className='' />
                </Button>
              )}
            </div>
          </div>

          <div>
            <div className="filterIcon" onClick={toggle}>
              {/* <img src={filterIcon} alt='Filter Icon' style={{width: '26px', height: '38px'}} /> */}
              <Icon icon='sliders-h' style={{width: '26px', height: '38px'}} />
            </div>
            <div className="filterModal">
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="modal-fullscreen modal-dialog-centered"
                zIndex={'1500'}
                style={{margin: '1.5rem', maxWidth: '100%'}}
                >
                <ModalHeader toggle={toggle} className="font-weight-bold">All Filters</ModalHeader>
                <ModalBody className='bg-white pb-0'>
                  <FilterPill
                    filterValue={JSON.parse(getFilter('city'))?.label}
                    filterLabel='Neighborhood'
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: ['city', 'neighborhoods', 'zipCodes'],
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'cityAndNeighborhoods'
                    //     ? setActiveFilterPill('cityAndNeighborhoods')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <CityNeighborhoodFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'cityAndNeighborhoods'}
                    />
                  </FilterPill>

                  <FilterPill
                    filterValue={
                      getFilter('minPrice') && getFilter('maxPrice')
                        ? currencyFormatter.format(getFilter('minPrice')) +
                          '-' +
                          currencyFormatter.format(getFilter('maxPrice'))
                        : getFilter('minPrice')
                        ? currencyFormatter.format(getFilter('minPrice')) +
                          ' & above'
                        : getFilter('maxPrice')
                        ? 'Below ' + currencyFormatter.format(getFilter('maxPrice'))
                        : ''
                    }
                    filterLabel='Price'
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: ['minPrice', 'maxPrice'],
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'price'
                    //     ? setActiveFilterPill('price')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <PriceFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'price'}
                    />
                  </FilterPill>

                  <FilterPill
                    filterValue={
                      getFilter('bed').length > 0 &&
                      JSON.parse(getFilter('bath'))?.label
                        ? unitTypes[getFilter('bed')[0]] +
                          '-' +
                          JSON.parse(getFilter('bath'))?.label +
                          '+ Bath'
                        : getFilter('bed').length > 0
                        ? unitTypes[getFilter('bed')[0]]
                        : JSON.parse(getFilter('bath'))?.label
                        ? JSON.parse(getFilter('bath'))?.label + '+ Bath'
                        : ''
                    }
                    filterLabel='Bed / Bath'
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: ['bed', 'bath'],
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'bedbath'
                    //     ? setActiveFilterPill('bedbath')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <BedBathFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'bedbath'}
                    />
                  </FilterPill>

                  <FilterPill
                    filterValue={
                      getFilter('availFrom') && getFilter('availTo')
                        ? new Date(getFilter('availFrom')) <= new Date()
                          ? 'Before ' + moment(getFilter('availTo')).format('MMM D')
                          : moment(getFilter('availFrom')).format('MMM D') ===
                            moment(getFilter('availTo')).format('MMM D')
                          ? 'Available on ' +
                            moment(getFilter('availFrom')).format('MMM D')
                          : moment(getFilter('availFrom')).format('MMM D') +
                            '-' +
                            moment(getFilter('availTo')).format('MMM D')
                        : getFilter('availFrom')
                        ? moment(getFilter('availFrom')).format('MMM D') +
                          ' & after'
                        : getFilter('availTo')
                        ? 'Before ' + moment(getFilter('availTo')).format('MMM D')
                        : ''
                    }
                    filterLabel='Move-in Date'
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: ['availFrom', 'availTo'],
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'moveInDate'
                    //     ? setActiveFilterPill('moveInDate')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <MoveInFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'moveInDate'}
                    />
                  </FilterPill>

                  {/* <div
                    style={{ borderRight: '1px solid #ddd', height: '32px' }}
                  ></div> */}

                  <FilterPill
                    filterValue={[
                      getFilter('sqFt')
                        ? `${numberFormatter.format(getFilter('sqFt'))} sqft+`
                        : '',
                      // getFilter('sqFt') && getFilter('unitFeatures').length > 0
                      //   ? ' - '
                      //   : '',
                      // getFilter('unitFeatures').length > 1
                      //   ? getFilter('unitFeatures')[0].label +
                      //     ` +${getFilter('unitFeatures').length - 1}`
                      //   : getFilter('unitFeatures').length === 1
                      //   ? getFilter('unitFeatures')[0].label
                      //   : '',
                      // !getFilter('sqFt') &&
                      //   getFilter('unitFeatures').length === 0 &&
                      //   ''
                      // ),
                    ].join('')}
                    filterLabel='Unit Size'
                    // isMulti={true}
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: ['unitFeatures', 'sqFt'],
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'unitFeatures'
                    //     ? setActiveFilterPill('unitFeatures')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <UnitFeaturesFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'unitFeatures'}
                      style={{ marginLeft: '-280px' }}
                    />
                  </FilterPill>

                  <FilterPill
                    filterValue={
                      getFilter('amenities').length > 1
                        ? getFilter('amenities')[0].label +
                          ` +${getFilter('amenities').length - 1}`
                        : getFilter('amenities').length === 1
                        ? getFilter('amenities')[0].label
                        : ''
                    }
                    filterLabel='Amenities'
                    // isMulti={true}
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: 'amenities',
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'amenities'
                    //     ? setActiveFilterPill('amenities')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <AmenitiesFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'amenities'}
                      style={{
                        marginLeft: '-257px',
                      }}
                    />
                  </FilterPill>

                  <FilterPill
                    filterValue={
                      getFilter('policies').length === 0
                        ? getFilter('escort') > 0
                          ? 'Escort ' + getFilter('escort') + '%'
                          : getFilter('minSend') > 0
                          ? 'Send ' + getFilter('minSend') + '%'
                          : getFilter('bonus') > 0
                          ? 'Flat Fee ' +
                            currencyFormatter.format(getFilter('bonus'))
                          : getFilter('yearFrom')
                          ? 'Built after ' + getFilter('yearFrom')
                          : getFilter('renovatedFrom')
                          ? 'Renovated after ' + getFilter('renovatedFrom')
                          : getFilter('googleRating') > 0
                          ? 'Google Rating ' +
                            getFilter('googleRating') +
                            (getFilter('googleRating') === 5 ? '' : '+')
                          : ''
                        : getFilter('policies').length > 1
                        ? getFilter('policies')[0].label +
                          ` +${getFilter('policies').length - 1}`
                        : getFilter('policies').length === 1
                        ? getFilter('policies')[0].label
                        : ''
                    }
                    filterLabel='Property & Policies'
                    // isMulti={true}
                    resetFilter={() => {
                      changeFilters({
                        type: 'reset',
                        name: [
                          'policies',
                          'escort',
                          'minSend',
                          'bonus',
                          'yearFrom',
                          'renovatedFrom',
                          'googleRating',
                        ],
                      });
                    }}
                    active={true}
                    // onClick={() =>
                    //   activeFilterPill !== 'policies'
                    //     ? setActiveFilterPill('policies')
                    //     : setActiveFilterPill(null)
                    // }
                  >
                    <PoliciesFilter
                      filters={filters}
                      getFilter={getFilter}
                      addFilter={addFilter}
                      activeFilterPill={'policies'}
                      style={{
                        marginLeft: '-255px',
                      }}
                    />
                  </FilterPill>
                </ModalBody>
                <ModalFooter className='bg-white modal-footer-sticky justify-content-between'>
                  <Button color="primary"
                    onClick={() => {
                      setActiveFilterPill(null);
                      !shouldSearch && setShouldSearch(true);
                      !shouldMapSearch && setShouldMapSearch(true);
                      toggle();
                    }}
                  >
                    Search
                  </Button>
                  <Button color="secondary"
                    onClick={() => {
                      setActiveFilterPill(null);
                      !shouldSearch && setShouldSearch(true);
                      !shouldMapSearch && setShouldMapSearch(true);
                      changeFilters({
                        type: 'reset',
                        name: [
                          'policies',
                          'escort',
                          'minSend',
                          'bonus',
                          'yearFrom',
                          'renovatedFrom',
                          'googleRating',
                          'yearFrom',
                          'yearTo',
                          'amenities',
                          'unitFeatures',
                          'sqFt',
                          'availFrom',
                          'availTo',
                          'bed',
                          'bath',
                          'minPrice',
                          'maxPrice',
                          'neighborhoods',
                          'zipCodes',
                          'search',
                        ],
                      });
                      // toggle();
                    }}
                  >
                    Clear
                  </Button>
                  <Button color="danger" onClick={toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>

      {activeFilterPill && (
        <div
          className='backdrop-small'
          onClick={() => {
            setActiveFilterPill(null);
          }}
        ></div>
      )}
    </>
  );
}

function FilterPill({
  onClick,
  resetFilter,
  filterLabel,
  filterValue,
  children,
  active,
}) {
  return (
    <div
      className={`bg-pill justify-content-between ${
        active && 'active'
      }`}
    >
      <div
        className={`d-flex flex-column position-relative justify-content-center h-100 w-100 bg-pill-header ${
          filterValue && active && ' clear'
        }`}
        onClick={onClick}
      >
        <div className='text-left text-nowrap mt-2'>
          <h6 className='font-weight-bold'>{filterLabel}</h6>
        </div>
        {/* {filterValue && ( */}
        <div className='align-items-center text-muted text-truncate filter-value'>
          <h6 className='font-weight-bold'>{filterValue || <div style={{ paddingLeft: '1px' }}>—</div>}</h6>
        </div>
        {/* )} */}
      </div>

      <div
        onClick={resetFilter}
        className={`clear-filter-button position-relative ${
          !(filterValue && active) ? ' hidden' : ''
        }`}
      >
        {/* <Icon icon='times' /> */}
      </div>

      <div>{children}</div>
      <hr />
    </div>
  );
}
