import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { 
	Row, 
	Col
} from 'reactstrap';
import { EMAIL_TEMPLATE_HISTORY } from '@api';
import { useParams } from 'react-router-dom';

export default function EmailTemplateShow(){
	const { id: emailTemplateHistoryId } = useParams();
	const [emailData, setEmailData] = useState(null);
	
	// const convertHtmlToPlainText = (html) => {
	// 	const tempElement = document.createElement('div');
	// 	tempElement.innerHTML = html;
	// 	return tempElement.textContent || tempElement.innerText || '';
	// };

	useQuery(EMAIL_TEMPLATE_HISTORY, {
		skip: !emailTemplateHistoryId, 
		variables: { id: Number(emailTemplateHistoryId) },
		onCompleted: (data) => {
			setEmailData(data.emailTemplateHistory)
		}
	});

	return (
		<div className='p-4 edit-popup'>
		  {
				emailData ?
				<>
				<Row>
				<Col md='12' className='mt-md-3'>
					<Row className='mb-4 mt-3'>
						<Col md='12' className='mb-4'>
							<h4>{emailData.subject}</h4>
						</Col>
				  </Row>
				</Col>
				</Row>
					<Row className='px-5'>
					<Col md='12'>
						<Row>
							<Col md='3'>
								<strong>Appointment type:</strong>
							</Col>
							<Col md='9'>
								{emailData.appointmentType}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>Time:</strong>
							</Col>
							<Col md='9'>
								{emailData.tourAt}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>Sent at:</strong>
							</Col>
							<Col md='9'>
								{emailData.tourAt}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>CC To:</strong>
							</Col>
							<Col md='9'>
								{emailData.ccTo}
							</Col>
						</Row>
						<Row>
							<Col md='3'>
								<strong>BCC To:</strong>
							</Col>
							<Col md='9'>
								{emailData.bccTo}
							</Col>
						</Row>
						{/* <Row>
							<Col md='3'>
								<strong>Email Body:</strong>
							</Col>
							<Col md='9'>
								{convertHtmlToPlainText(emailData.body)}
							</Col>
						</Row> */}
					</Col>
					</Row>
					<Row className='px-5 mt-5'>
					<Col md='12'>
						<Row>
							<Col md='6'>
								<strong>PROPERTY:</strong><br/>
								{emailData.property.name}<br/>
								{emailData.property.escort}<br/>
								{emailData.property.sendEscort}<br/>
								{emailData.property.bonus}<br/>
							</Col>
							<Col md='6'>
							<strong>AGENT:</strong><br/>
							{emailData.user.name}<br/>
							{emailData.user.phoneNo}<br/>
							{emailData.user.email}<br/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row className='px-5 mt-5'>
					<Col md='12'>
						<Row>
							<Col md='6'>
								<strong>CLIENT DETAILS:</strong><br/>
								<Row>
									<Col md='6'>
										<strong>Name:</strong>
									</Col>
									<Col md='6'>
									{emailData.client.name}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Email:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.email}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Contact:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.phone}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Bedroom:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.bedroomArray}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Bathroom:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.bathroom}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Move In Date:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.moveInDate}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Budget:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.budget}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Pet:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.petNumber}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>Parking no:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.noOfParking}
									</Col>
								</Row>
								<Row>
									<Col md='6'>
										<strong>notes:</strong>
									</Col>
									<Col md='6'>
										{emailData.client.notes}
									</Col>
								</Row>
							</Col>
					  </Row>
					</Col>
				</Row>
					</>
				: <div className='m-5'><h2>Loading Email Template Data...</h2></div>
      }
		</div>
	);
}