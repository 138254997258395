import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import Select from 'react-select';
import Spinner from '../../components/Spinner';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import {
  ALL_STRIPE_PLANS,
  PLAN,
  PLAN_CREATE,
  PLAN_UPDATE,
  PLANS,
  CITIES,
} from '@api';
import { useQuery, useMutation } from '@apollo/client';
import { ToggleButton } from '@sparklib';
import { Icon } from '@assets';

export default function PlanForm(props) {
  const { sidebarToggle, operation } = props;
  const history = useHistory();
  const { id: planId } = useParams();
  const [progress, setProgress] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [intervalError, setIntervalError] = useState(false);
  const [isLinkToStripePlan, setIsLinkToStripePlan] = useState(false);
  const [form_errors, setErrors] = useState([]);
  const newPlan = {
    id: null,
    name: '',
    amount: 0.0,
    interval: '',
    canTrial: false,
    isVisible: true,
    city: null,
    stripePlanId: null,
  };
  const [plan, setPlan] = useState(newPlan);
  const [cityList, setCityList] = useState({ label: '', value: '' });
  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    setPlan((plan) => {
      return {
        ...plan,
        stripePlanId: null,
      };
    });
  }, [isLinkToStripePlan]);

  const [planCreate, { createLoading }] = useMutation(PLAN_CREATE, {
    onCompleted: (data) => {
      if (data.planCreate.errors && data.planCreate.errors.length > 0) {
        setErrors(data.planCreate.errors);
      } else {
        toast(data.planCreate.message, { type: 'success' });
        sidebarToggle();
        setErrors('');
        history.push(`/plans`);
      }
    },
    refetchQueries: [PLANS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const [planUpdate, { updateLoading }] = useMutation(PLAN_UPDATE, {
    onCompleted: (data) => {
      if (data.planUpdate.errors && data.planUpdate.errors.length > 0) {
        setErrors(data.planUpdate.errors);
        toast(data.planUpdate.message, { type: 'error' });
      } else {
        toast(data.planUpdate.message, { type: 'success' });
        sidebarToggle();
        setErrors('');
        history.push(`/plans`);
      }
    },
    refetchQueries: [PLANS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  useQuery(PLAN, {
    variables: { id: parseInt(planId) },
    skip: !planId || operation === 'new',
    onCompleted: (data) => {
      clearErrors();
      data.plan.stripePlanId
        ? setIsLinkToStripePlan(true)
        : setIsLinkToStripePlan(false);
      setPlan({
        stripePlanId: data.plan.stripePlanId,
        id: Number(data.plan.id),
        name: data.plan.name,
        amount: parseFloat(data.plan.amount),
        interval: data.plan.interval,
        canTrial: data.plan.canTrial,
        isVisible: data.plan.isVisible,
        city: data.plan.maxCities && {
          label: data.plan.maxCities === -1 ? 'All' : data.plan.maxCities,
          value: data.plan.maxCities,
        },
      });
    },
    onError: (error) => {
      toast('Plan not found', { type: 'error' });
      sidebarToggle();
    },
  });

  const { data: stripePlanData } = useQuery(ALL_STRIPE_PLANS, {
		// onCompleted: (data) => {
		// 	var plans = data.stripePlans.map(sp => JSON.parse(`{"value": "${sp.id}", "label": "${getStripePlan(sp)}"}`));
		// 	setAllPlans(plans);
		// }
	});
  /* const { data: statesData } = useQuery(STATES); */
  const { data: cityData } = useQuery(CITIES);
  useQuery(PLANS);

  useEffect(() => {
    var cityOption = [];
    cityOption.push({ label: 'All', value: '-1' });
    if (cityData) {
      cityData.cities.map((city, index) => {
        cityOption.push({
          value: '' + (index + 1) + '',
          label: '' + (index + 1) + '',
        });
      });
    }
    setCityList(cityOption);
  }, [cityData]);

  useEffect(() => {
    setProgress(createLoading || updateLoading ? true : false);
  }, [createLoading, updateLoading]);

  useEffect(() => {
    if (operation === 'new') {
      clearErrors();
      setPlan(newPlan);
    }
  }, [operation]);

  useEffect(() => {
    if (stripePlanData) {
      const selectedStripePlan = stripePlanData.stripePlans.find(
        (stripePlan) => plan.stripePlanId === stripePlan.id
      );
      if (selectedStripePlan) {
        setPlan((plan) => {
          return {
            ...plan,
            amount: selectedStripePlan.amount / 100,
            name: selectedStripePlan.product.name,
            interval: selectedStripePlan.interval,
						label: getStripePlan(selectedStripePlan)
          };
        });
      }
    }
  }, [plan.stripePlanId]);

  useEffect(() => {
    if (plan.city) {
      setCityError(false);
    }
  }, [plan.city]);

  useEffect(() => {
    if (plan.interval) {
      setIntervalError(false);
    }
  }, [plan.interval]);

  const changePlan = (label, value) =>
    setPlan((plan) => {
      return { ...plan, [label]: value };
    });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (!plan.interval) {
      setIntervalError(true);
    }
    if (plan.city === null) {
      setCityError(true);
    }
    if (!plan.interval || plan.city === null) {
      return false;
    }

    if (operation === 'new') {
      await planCreate({
        variables: {
          ...plan,
          maxCities: Number(plan.city.value),
          amount: parseFloat(plan.amount),
        },
      });
    } else {
      await planUpdate({
        variables: {
          ...plan,
          maxCities: Number(plan.city.value),
          amount: parseFloat(plan.amount),
        },
      });
    }
  };

	function getStripePlan(stripePlan){
		if (stripePlan?.tiers?.length > 0){
			return stripePlan
			? stripePlan.product.name +
				' (Starts At $' +
				stripePlan.tiers[0].unit_amount / 100 +
				' / ' +
				stripePlan.interval +
				')'
			: '-'
		}else {
			return stripePlan
			? stripePlan.product.name +
				' ($' +
				stripePlan.amount / 100 +
				' / ' +
				stripePlan.interval +
				')'
			: '-'
		}
	}

	useEffect(() => {
		setErrors([]);
	}, [sidebarToggle])

  return (
    <>
      {progress && <Spinner />}

      <div className='p-4'>
        <Row>
          <Col md='12'>
            <h4>{operation === 'new' ? 'Create' : 'Edit'} Plan</h4>
          </Col>
          <Col md='12'>
            {form_errors.length > 0 ? (
              <Col>
                {form_errors.map((err, index) => (
                  <Col className='bg-danger mt-2 text-white' key={index}>
                    {err}
                  </Col>
                ))}
              </Col>
            ) : null}
          </Col>
          <Col md='12' className='mt-md-3 inputs-icons'>
            <Row>
              <Col md='12'>
                <Form onSubmit={handleSubmit(onSubmit)} id='create-plan-form'>
                  <Row>
                    <Col md='6' className='mb-3'>
                      <Label className='w-100 mb-0 mr-3'>
                        Linked to Stripe Plan
                      </Label>
                      <ToggleButton
                        selected={isLinkToStripePlan}
                        firstOption='Yes'
                        secondOption='No'
                        width='85px'
                        rounded
                        toggleSelected={() => {
                          setIsLinkToStripePlan(!isLinkToStripePlan);
                        }}
                      />
                    </Col>
                    <Col md='3' className='mb-3'>
                      <Label className='w-100 mb-0 mr-3'>Can trial</Label>
                      <ToggleButton
                        selected={plan.canTrial}
                        firstOption='Yes'
                        secondOption='No'
                        width='85px'
                        rounded
                        toggleSelected={() => {
                          changePlan('canTrial', !plan.canTrial);
                        }}
                      />
                    </Col>
                    <Col md='3' className='mb-3'>
                      <Label className='w-100 mb-0 mr-3'>Is visible</Label>

                      <ToggleButton
                        selected={plan.isVisible}
                        firstOption='Yes'
                        secondOption='No'
                        width='85px'
                        rounded
                        toggleSelected={() => {
                          changePlan('isVisible', !plan.isVisible);
                        }}
                      />
                    </Col>
                    {isLinkToStripePlan && (
                      <Col md='12'>
                        <FormGroup>
                          <Label for='stripe plan'>Select Stripe Plan</Label>
                          <Select
                            name='stripePlanId'
                            value={
                              plan.stripePlanId
                                ? {
                                    value: `${plan.stripePlanId}`,
                                    label: `${plan.label}`,
                                  }
                                : null
                            }
                            options={
                              typeof stripePlanData !== 'undefined'
                                ? Object.values(stripePlanData.stripePlans).map(
                                    (stripePlan) =>
                                      JSON.parse(
                                        `{"value":"${
                                          stripePlan.id
                                        }", "label":"${
                                          getStripePlan(stripePlan)
                                        }"}`
                                      )
                                  )
                                : []
                            }
                            onChange={(e) =>
                              changePlan('stripePlanId', e.value)
                            }
                            className='basic-multi-select w-100 bedsize'
                            classNamePrefix='default-select'
                            placeholder='Stripe Plan'
                          />
                        </FormGroup>
                      </Col>
                    )}
                    <Col md='12'>
                      <FormGroup>
                        <Label for='name'>Name</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='clipboard-check' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='name'
                            autoComplete='name'
                            readOnly={isLinkToStripePlan}
                            value={plan.name}
                            onChange={(e) =>
                              changePlan(e.target.name, e.target.value)
                            }
                            innerRef={register({
                              required: true,
                            })}
                          />
                        </InputGroup>
                        {_.get('name.type', errors) === 'required' && (
                          <p className='input-error'>* Name required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='amount'>Amount</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='dollar-sign' />
                          </InputGroupText>
                          <Input
                            type='number'
                            name='amount'
                            autoComplete='amount'
                            readOnly={isLinkToStripePlan}
                            value={plan.amount}
                            onChange={(e) =>
                              changePlan(e.target.name, e.target.value)
                            }
                            innerRef={register({
                              required: true,
                            })}
                          />
                        </InputGroup>
                        {_.get('amount.type', errors) === 'required' && (
                          <p className='input-error'>* Amount required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='interval'>Interval</Label>
                        <Select
                          name='interval'
                          isDisabled={isLinkToStripePlan}
                          value={{
                            value: `${plan.interval}`,
                            label: `${
                              plan.interval === 'month'
                                ? 'Monthly'
                                : plan.interval === 'year'
                                ? 'Yearly'
                                : ''
                            }`,
                          }}
                          options={[
                            { value: 'month', label: 'Monthly' },
                            { value: 'year', label: 'Yearly' },
                          ]}
                          onChange={(e) => changePlan('interval', e.value)}
                          className='basic-multi-select w-100 bedsize'
                          classNamePrefix='default-select'
                          placeholder='Interval'
                        />
                        {intervalError === true && (
                          <p className='input-error'>* Interval required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='city'>City</Label>
                        <Select
                          name='city'
                          value={plan.city}
                          onChange={(e) => changePlan('city', e)}
                          options={cityList}
                          className='basic-multi-select w-100 bedsize'
                          classNamePrefix='default-select'
                          placeholder='Select Market'
                        />
                        {cityError === true && (
                          <p className='input-error'>* Market required.</p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md='12' className='mt-md-2'>
                      <Button
                        color='primary'
                        className='button-dashboard'
                        disabled={progress}
                      >
                        {operation === 'new' ? 'Create' : 'Update'} Plan
                      </Button>
                      <Button
                        className='button-dashboard ml-2'
                        onClick={() => sidebarToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
