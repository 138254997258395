import React, { useState, useEffect } from 'react';
import {Col, Button} from 'reactstrap';
import { Icon } from '@assets';

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  const scrollToTop = () => {
    const container = document.getElementById('sidebar-right-id');

    container.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <Col md='12'>
    <div className="d-block d-sm-none">
    <Button
      color="primary"
      className='button-dashboard mt-3 float-right'
      onClick={scrollToTop}
    >
      {'Return to Details'}&nbsp;
        <Icon icon='arrow-up' />
    </Button>
    </div>
    </Col>
  );
};

export default ScrollToTopButton;
