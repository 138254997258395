import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { NavLink, useHistory } from 'react-router-dom';
import { SIGN_IN, SEND_OTP, VALIDATE_OTP } from '@api';
import { useMutation } from '@apollo/client';
import Loader from '../components/Spinner';
import NavBar from '../NewLanding/components/newNavbar';
import OtpInput from 'react-otp-input'

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
	Modal,
	ModalBody,
	ModalHeader,
	Spinner
} from 'reactstrap';
import DivisionTitle, { DivisionSubTitle } from '../NewLanding/components/divisionTitle';

toast.configure();

export default function Login(props) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [progress, setProgress] = useState(false);
  const history = useHistory();
  const [signInUser, { data: signinData, loading, error }] = useMutation(
    SIGN_IN,
    {
      onCompleted: (signinData) => {
        if (
          !signinData.signin.errors &&
          signinData.signin.authenticationToken !== null
        ) {
          saveCredentials(signinData);
          redirectToDashboard();
        } else {
          signinData.signin.errors.map((err) => toast(err, { type: 'error' }));
        }
      },
      onError: (e) => {
        console.log(e);
        toast(e, { type: 'error' });
      },
    }
  );
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
	const [showOtpModal, setShowOtpModal] = useState(false);
	const [otp, setOtp] = useState('');

	useEffect(() => {
		if (showOtpModal) {
			setTimeout(() => {
				document.getElementById("otpInput")?.focus()
			}, 1000)
		}
	}, [showOtpModal])

	const [sendOtp] = useMutation(SEND_OTP, {
		onCompleted: (data) => {
			if (data.sendOtp.errors?.length > 0){
				toast(data.sendOtp.message, { type: 'error' });
			} else if (!data.sendOtp.errors && data.sendOtp.requireOtpConfirmation) {
				toast(data.sendOtp.message, { type: 'success' });
				setShowOtpModal(true);
			} else if (!data.sendOtp.errors && data.sendOtp.requireOtpConfirmation === false){
		    loginUser();
			}
		}
	});

	const [validateOtp] = useMutation(VALIDATE_OTP, {
		onCompleted: (data) => {
			if (data.validateOtp.errors?.length > 0){
				toast(data.validateOtp.message, { type: 'error' });
			} else {
				setShowOtpModal(false);
				loginUser();
			}
			setOtp('');
			setProgress(false);
		}
	});

  const isLogin = () => {
    if (props.location.state) {
      toast(props.location.state.message, {
        type: props.location.state.type || 'error',
      });
    }

    if (localStorage.getItem('auth_token') !== null) {
      redirectToDashboard();
    }
  };

  useEffect(() => {
    isLogin();
  }, []);

  const redirectToDashboard = (role = localStorage.getItem('user_type')) => {
    role = role && localStorage.getItem('user_type');
    switch (role) {
      case 'admin': {
        history.push({
          pathname: '/admin-dashboard',
        });
        break;
      }
      case 'user': {
        history.push({
          pathname: '/properties',
        });
        break;
      }
      case 'va': {
        history.push({
          pathname: '/assistant-dashboard',
        });
        break;
      }
      default: {
        history.push({
          pathname: '/logout',
        });
        break;
      }
    }
  };

  const saveCredentials = (signinData) => {
		localStorage.clear();
    localStorage.setItem('auth_token', signinData.signin.authenticationToken);
    localStorage.setItem('user_name', signinData.signin.user.name);
    localStorage.setItem('user_email', signinData.signin.user.email);
    localStorage.setItem('default_property_view', signinData.signin.user.defaultPropertyView);
    if (signinData.signin.user.defaultCityId) {
      localStorage.setItem('default_city_id', signinData.signin.user.defaultCityId);
    }
    if (signinData.signin.user.isAdmin === true) {
      //TODO: Remove the line below storing admin_token in local storage
      console.log('ADMIN LOGGED IN');
      localStorage.setItem('user_type', 'admin');
    } else {
			if (signinData.signin.user.isBroker === true) {
				localStorage.setItem('is_broker', 'true');
			} else if (signinData.signin.user.isAgent === true) {
				localStorage.setItem('is_agent', 'true');
			}
      if (signinData.signin.user.isVa === true) {
        //TODO: Remove the line below storing assistant_token in local storage
        console.log('VA LOGGED IN');
        localStorage.setItem('user_type', 'va');
      } else {
        //TODO: Remove the line below storing user_token in local storage
        localStorage.setItem('subscription_popup', true);

        if (signinData.signin.user.subscriptions.length > 0) {
          let hasActiveSubscription = false;
          let hasTrialSubscription = false;
          signinData.signin.user.subscriptions.map((subscription) => {
            if (
              subscription.isTrial === true &&
              subscription.active === true &&
              new Date(subscription.currentEndDatetime) > new Date()
            ) {
              hasTrialSubscription = true;
              localStorage.setItem(
                'trial_end',
                subscription.currentEndDatetime
              );
              localStorage.removeItem('subscription_popup');
            } else if (
              subscription.active === true &&
              new Date(subscription.currentEndDatetime) > new Date()
            ) {
              hasActiveSubscription = true;
              localStorage.removeItem('subscription_popup');
            }
          });
          if (hasActiveSubscription) {
            localStorage.removeItem('trial_end');
          }
        }
        localStorage.setItem('user_type', 'user');
      }
    }
  };

  const loginUser = async () => {
    setProgress(true);
    await signInUser({
      variables: {
        email: email,
        password: password,
      },
    });
    setProgress(false);
  };

	function handleOtpSend(){
		sendOtp({
			variables: {
				email: email,
			}
		})
		document.getElementById("otpInput")?.focus()
	}

  const onSubmit = async (data, e) => {
    e.preventDefault();
		handleOtpSend();
    // loginUser();
  };

	const handleOtpValidation = () => {
		if (otp?.length === 6){
			validateOtp({
				variables: {
					email: email,
					otp: otp
				}
			});
			setProgress(true);
		} else {
			toast("Enter Valid OTP", { type: 'error' });
		}
	};

	useEffect(() => {
		if (otp?.length === 6) {
			handleOtpValidation();
		}
	}, [otp])

  return (
    <>
      {progress ? <Loader /> : null}
      <NavBar />
      {/* <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Login</h1>
        </Container>
      </Container> */}

			{ showOtpModal && 
				<Modal
					isOpen={true}
					// toggle={() => setShowOtpModal(!showOtpModal)}
					centered
					className='payment-method text-center'
				>
					<ModalHeader toggle={() => setShowOtpModal(!showOtpModal)}>Two-factor Authentication</ModalHeader>
					<ModalBody className='inputs-icons'>
						<p>We sent you a 6-digit OTP to verify your identity</p>
						{/* <OtpInput
							value={otp}
							onChange={setOtp}
							numInputs={6}
							shouldAutoFocus={true}
							renderSeparator={<span>&nbsp;</span>}
							renderInput={(props) => <input {...props} />}
							containerStyle='user-otp-input'
							inputStyle='otp-input'
						/> */}
						<input 
							id="otpInput"
							className='otp-input-field'
							value={otp}
							maxlength="6"
							size="17"
							onChange={e => setOtp(e.target.value)}
							autoComplete="one-time-code"
						/>
						<div className='text-primary mt-3'> <a onClick={handleOtpSend}>Resend OTP</a> </div>
					</ModalBody>
					{ progress &&
						<center>
							<Spinner
								color="primary"
								type="grow"
							>
								Loading...
							</Spinner>
						</center>
					}
					{/* <div>
						<Button color='green' className='button-dashboard mb-3 mt-2 w-25' disabled={progress} onClick={handleOtpValidation}>
							Verify
						</Button>
					</div> */}
				</Modal>
			}

      <Container fluid className='py-5em mt-5em text-center'>
        <Container>
          <Row className='px-lg-5'>
            <Col md='12'>
              <DivisionTitle
                className="mb-4"
                title={<>Let's <span className="highlighted-text">get started.</span></>}
              />
              <DivisionSubTitle
                className="mb-5"
                title="Getting leases is about to become much easier."
              />
              <p>
                <small>
                  Don't have an account? <NavLink to='/signup'>Sign Up</NavLink>
                </small>
              </p>
            </Col>
						{ localStorage.getItem('session_expired') === "true" && 
							<Col md={{ size: 4, offset: 4 }}>
							<p className='error-msg'>Your session has expired. Please login again.</p>
						</Col>}
						{ localStorage.getItem('api_key_expired') === "true" && 
							<Col md={{ size: 4, offset: 4 }}>
							<p className='error-msg'>Your Spark API key has been revoked. Please contact to admin.</p>
						</Col>}
            <Col md={{ size: 4, offset: 4 }} className='inputFields'>
              <Form className='form' onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Email'
                    name='email'
                    autoComplete='off'
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                  {_.get('email.type', errors) === 'required' && (
                    <p className='input-error'>* Email required.</p>
                  )}
                  {_.get('email.type', errors) === 'pattern' && (
                    <p className='input-error'>* Invalid Email.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='password'
                    placeholder='Password'
                    name='password'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  {_.get('password.type', errors) === 'required' && (
                    <p className='input-error'>* Password required</p>
                  )}
                </FormGroup>

                <Button color='green' size='lg' className="btn-rounded">
                  {progress ? 'Progress' : 'Login'}
                </Button>
                <p className='mt-3'>
                  <small>
                    <NavLink to='/forgot-password'>Trouble logging in?</NavLink>
                  </small>
                </p>
              </Form>
            </Col>
          </Row>

          <Row className='pt-5 privacy-policy'>
            <Col md={{ size: 4, offset: 4 }}>
              <p className='mb-0'>
                <small>By Continuing you agree to accept our</small>
              </p>
              <p className='mb-5'>
                <small>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink> &{' '}
                  <NavLink to='/terms-of-service'>Terms of Service.</NavLink>
                </small>
              </p>
              <hr className='border-light' />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
