import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { notificationTypes, getNotificationColor } from '../../shared/sparkLib/notificationTypes';
import 'react-toastify/dist/ReactToastify.css';

import { useMutation } from '@apollo/client';
import { CREATE_NOTIFICATION } from '@api';

export default function AddNotification(props) {
  const history = useHistory();
  const [form_errors, setErrors] = useState([]);
	const [notificationType, setNotificationType] = useState('primary');
  const [createNotification] = useMutation(CREATE_NOTIFICATION, {
    onCompleted: (data) => {
      if (
        data.notificationCreate.errors &&
        data.notificationCreate.errors.length > 0
      ) {
        setErrors(data.notificationCreate.errors);
      } else {
        toast('Notification created successfully', { type: 'success' });
        props.sidebarToggle();
        props.shouldRefetch(true);
        history.push(`/notifications`);
        document.getElementById('addNotification').reset();
      }
    },
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    createNotification({
      variables: {
        title: data.title,
        description: data.description,
        notificationType: notificationType,
      },
    });
  };

	function handleNotificationType(e) {
		setNotificationType(e.target.value);
	}

  return (
    <>
      <Container className='p-4'>
        <Row>
          <Col md='12'>
            <h4>New Notification</h4>
          </Col>
          <Col md='12'>
            {form_errors.length > 0 ? (
              <Col>
                {form_errors.map((err) => (
                  <Col className='bg-danger mt-2 text-white'>{err}</Col>
                ))}
              </Col>
            ) : null}
          </Col>
          <Col md='12' className='mt-md-3'>
            <Form id='addNotification' onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg='12'>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='firstName'>Title</Label>
                        <Input
                          type='text'
                          placeholder='Title'
                          name='title'
                          autoComplete='off'
                          innerRef={register({
                            required: true,
                          })}
                        />
                        {_.get('title.type', errors) === 'required' && (
                          <p className='input-error'>* Title required.</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
									<Row>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='notificationType'>Notification Type</Label>
                        <select value={notificationType} class="custom-select notification-type-select" onChange={handleNotificationType} style={{backgroundColor: getNotificationColor(notificationType)}}>
													{notificationTypes.map((notification) => {
														return (
															<option value={notification.notificationType} style={{backgroundColor: notification.color}}>{_.capitalize(notification.notificationType)}</option>
														)
													})}
												</select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='lastName'>Description</Label>
                        <Input
                          type='textarea'
                          placeholder='Description'
                          name='description'
                          autoComplete='off'
                          innerRef={register({
                            required: true,
                          })}
                          style={{ height: '120px' }}
                        />
                        {_.get('description.type', errors) === 'required' && (
                          <p className='input-error'>* Description required.</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                <Col lg='12' className='mt-md-4'>
                  <Row>
                    <Col xs='12' className='text-left'>
                      <Button color='primary' className='button-dashboard'>
                        Create
                      </Button>
                      <Button
                        color='primary'
                        className='button-dashboard ml-2'
                        onClick={() => props.sidebarToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
