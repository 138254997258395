import React from 'react';
import { Col, NavLink } from 'reactstrap';
import { Icon } from '@assets';
import Slider from '../../components/Slider';
import ClientRating from './ClientRating';

export default function PropertyGridBox({
  selectedBedSize,
  property: { imageUrls, name, neighborhood, city, id },
  reportId,
  reportCode,
  hidePropertyName,
  handleClick,
  rating,
  notes,
}) {
  const propertyImages = imageUrls?.map((image, index) => ({
    key: index,
    src: image,
    // width: '100%',
    altText: name,
  }));

  return (
    <>
      <Col sm='6' lg='4' xl='3' className='mb-4'>
        <NavLink onClick={() => handleClick()} className='property-card p-0'>
          <div className='card-img'>
            <Slider items={propertyImages} className='rounded-medium' />
          </div>
          <h4>
            { hidePropertyName ?
              <>
              </> :
              <>
                {name}
              </>
            }
						<span className='float-right' onClick={(e) => e.stopPropagation()}>
              <small>
								<ClientRating
									reportId={reportId}
									reportCode={reportCode}
									propertyId={id}
									rating={rating}
									notes={notes}
									isSocialMediaType={true}
								/>
              </small>
            </span>
          </h4>
          <p>
            {neighborhood.name}, {city.name}
						<span className='float-right'>
              <small>
                <Icon icon='bed' className='mx-2' />
                {selectedBedSize}
              </small>
            </span>
          </p>
        </NavLink>
      </Col>
    </>
  );
}
