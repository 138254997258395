
export const notificationTypes = [
	{
		value: 0,
		notificationType: "primary",
		color: "#E7E7FF"
	},
	{
		value: 1,
		notificationType: "secondary",
		color: "#E4E4E6"
	},
	{
		value: 2,
		notificationType: "success",
		color: "#EFFFDC"
	},
	{
		value: 3,
		notificationType: "danger",
		color: "#FFE8E8"
	},
	{
		value: 4,
		notificationType: "warning",
		color: "#FDF7DB"
	},
	{
		value: 5,
		notificationType: "info",
		color: "#E8FEFF"
	},
];

export function getNotificationColor(notificationType) {
	const notification = notificationTypes.find(n => n.notificationType === notificationType);
	return notification ? notification.color : "#FFFFFF";
}