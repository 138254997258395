import React, { useState, useEffect } from "react";
import Spinner from "../../Spinner";
import Avatar from 'react-avatar';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { LEADS, LEADS_MULTI_DELETE } from "@api";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import RTable from "../../RTable";

import { Icon } from '@assets';

export default function Leads() {
  const [leadList, setLeadList] = useState("loading");
  const [leads, setLeads] = useState([]);
  const {
    data: leadsData,
    loading,
    error,
  } = useQuery(LEADS, {
    variables: {},
  });
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    //leadsData && setLeads(leadsData.leads.edges);
    if(leadsData) {
      if(leadsData.leads.totalCount === 0) {
        setLeadList("noData");
      } else {
        setLeadList("data");
        setLeads(leadsData.leads.edges);
      }
    }
  }, [leadsData]);

  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  const [leadsMultiDelete] = useMutation(LEADS_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.leadMultiDelete.message, { type: "success" });
      setIsProgress(false);
    },
    refetchQueries: [LEADS],
    onError: (e) => {
      console.log(e);
    },
  });
  const handleDeleteAll = async () => {
    setIsProgress(true);
    await leadsMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  if (error) {
    //return error.graphQLErrors.map((error) => error + ', ');
  }
  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "node.name",
        Cell: ({ value, row }) => (
          <div>
            <Avatar
              name={value ? value : "-"}
              className="mr-2 text-white"
              round={true}
              size="30"
            />{" "}
            {value ? value : "-"}
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "node.email",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Phone",
        accessor: "node.phone",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Market",
        accessor: "node.city",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Budget",
        accessor: "node.budget",
        Cell: ({ value, row }) => <div>{value ? "$" + value : "-"}</div>,
      },
      {
        Header: "Reach",
        accessor: "node.reachOut",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Move In",
        accessor: "node.moveIn",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Beds/Baths",
        accessor: "node.bedrooms",
        Cell: ({ value, row }) => (
          <div>{value ? value + "/" + row.original.node.bathrooms : "-"}</div>
        ),
      },
      {
        Header: "Others",
        accessor: "node.importantToYou",
        Cell: ({ value, row }) => (
          <div>
            {value && value.length > 0 ? value.map((item) => item + ", ") : "-"}
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "node.id",
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon="bars" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleSingleDelete(value)}>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <>
      {loading && <Spinner />}

      {/* <Row>
        <Col md="12" className="d-flex justify-content-end mt-3 mt-md-5">
          <Button color="red" className="button-dashboard ml-3 rounded">
            <Icon icon="trash-alt" />
          </Button>
          <Button color="green" className="button-dashboard ml-3">
            <Icon icon="file-alt" /> Download as CSV
          </Button>
          <Button color="primary" className="button-dashboard ml-3">
            <Icon icon="file-pdf" /> Export as PDF
          </Button>
        </Col>
      </Row> */}
      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} lead
          {isCheckedId.length > 1 ? "s" : ""}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color="success" onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Row className="mt-md-4">
        <Col md="12">
          {leadList === "data" ? (
            <RTable
              columns={columns}
              data={leads}
              filterable
              addons={
                <Button
                  color="red"
                  onClick={onAllDeletePopup}
                  disabled={isCheckedId.length > 0 ? false : true}
                  className="button-dashboard float-right"
                >
                  <Icon icon="trash-alt" className="mr-2" />
                  Delete
                </Button>
              }
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
            />
          ) : (
            <>
              {leadList === "noData" ? (
                <h2 className="text-center mt-5">Leads not found</h2>
              ) : null}
            </>
          )}
          {/* <Table
            // borderless
            responsive
            className='client-list text-left min-w-900'
          >
            <thead>
              <tr>
                <th className="text-left">
                  <input
                    type="checkbox"
                    className="mr-2"
                    id="select-all"
                  />
                  NAME
                </th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>Budget</th>
                <th>Reach</th>
                <th>Move In</th>
                <th>Beds/Baths</th>
                <th>Other</th>
              </tr>
            </thead>
            <tbody>
              {data && data.leads.edges
                .slice(offset, per_page + offset)
                .map(
                  (
                    {
                      node: {
                        id,
                        name,
                        email,
                        phone,
                        budget,
                        city,
                        reachOut,
                        moveIn,
                        bedrooms,
                        bathrooms,
                        comment,
                        importantToYou,
                      },
                    },
                    i
                  ) => (
                    <tr key={id}>
                      <td>
                        <input
                          type="checkbox"
                          className="mr-2"
                        />
                        <Avatar
                          name={name}
                          className='mr-2'
                          round={true}
                          size='30'
                        />
                        {name}
                      </td>
                      <td>{email}</td>
                      <td>{phone}</td>
                      <td>{city}</td>
                      <td>${budget}</td>
                      <td>{reachOut}</td>
                      <td>{moveIn}</td>
                      <td>
                        {bedrooms}/{bathrooms}
                      </td>
                      <td>
                        {importantToYou !== null
                          ? importantToYou.map((item) => item + ', ')
                          : ''}
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
          {page_count > 1 ? (
            <ReactPaginate
              previousLabel={<Icon icon="chevron-left" />}
              nextLabel={<Icon icon="chevron-right" />}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={page_count}
              marginPagesDisplayed={3}
              pageRangeDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              forcePage={selected_page}
            />
          ) : null} */}
        </Col>
      </Row>
    </>
  );
}
