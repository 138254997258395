import React, { useState, useEffect } from 'react';
import {
  SUBSCRIPTIONS,
  SUBSCRIPTION_DELETE,
  MY_PROFILE,
  REQUEST_CANCEL_SUBSCRIPTION,
} from '@api';
import config from '@config';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Label,
  FormGroup,
  Input,
  Form,
} from 'reactstrap';
import Spinner from '../Spinner';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './payment/PaymentForm';
import { Icon } from '@assets';

export default function Subscription() {
  const [data, setData] = useState('loading');
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [planName, setPlanName] = useState('');
  const [isSubscriptionBtn, setIsSubscriptionBtn] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [requestToCancel, setRequestToCancel] = useState(false);
  const stripePromise = loadStripe(config.url.REACT_APP_STRIPE_KEY);
  const { data: subscriptionsData, loading, error } = useQuery(SUBSCRIPTIONS);
  const [trialPopup, setTrialPopup] = useState(false);
  const [cancelReasons, setCancelReasons] = useState([]);

  useEffect(() => {
    // check !id and operation is edit
    if (subscriptionsData) {
      if (subscriptionsData.subscriptions.totalCount === 0) {
        setData('noData');
      } else {
        setData('data');
      }
      setIsSubscriptionBtn(true);
      subscriptionsData &&
        subscriptionsData.subscriptions.edges.map((subscription) => {
          if (subscription.node.active === true) {
            setIsSubscriptionBtn(false);
          }
        });
    }
  }, [subscriptionsData]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const [subscriptionDelete] = useMutation(SUBSCRIPTION_DELETE, {
    onCompleted: (data) => {
      if (
        data.subscriptionDelete.errors &&
        data.subscriptionDelete.errors.length > 0
      ) {
        toast(data.subscriptionDelete.message, { type: 'error' });
      } else {
        setAllDeleteModal(false);
        toast(data.subscriptionDelete.message, {
          type: 'success',
          autoClose: false,
        });
        setIsProgress(false);
        setCancelReasons([]);
      }
    },
    refetchQueries: [SUBSCRIPTIONS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const [requestCancelSubscription] = useMutation(REQUEST_CANCEL_SUBSCRIPTION, {
    onCompleted: (data) => {
      if (
        data.requestCancelSubscription.errors &&
        data.requestCancelSubscription.errors.length > 0
      ) {
        toast(data.requestCancelSubscription.message, { type: 'error' });
      } else {
        setRequestToCancel(false);
        toast(data.requestCancelSubscription.message, { type: 'success' });
        setIsProgress(false);
        setCancelReasons([]);
      }
    },
    refetchQueries: [SUBSCRIPTIONS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const { data: userData } = useQuery(MY_PROFILE);

  const handleSingleDelete = (id, plan) => {
    setPlanName(plan);
    setSubscriptionId(id);
    setAllDeleteModal(!allDeleteModal);
  };

  const handleCancelRequest = (id, plan) => {
    setPlanName(plan);
    setSubscriptionId(id);
    setRequestToCancel(!allDeleteModal);
  };

  const cancelSubscription = async () => {
    if (cancelReasons.length > 0) {
      await subscriptionDelete({
        variables: {
          id: Number(subscriptionId),
          cancelReason: JSON.stringify(cancelReasons),
        },
      });
    } else {
      toast('Please provide reason why you want to cancel subscription', {
        type: 'error',
      });
    }
  };

  const callRequestCancelSubscription = async () => {
    if (cancelReasons.length > 0) {
      await requestCancelSubscription({
        variables: {
          subscriptionId: Number(subscriptionId),
          cancelReason: JSON.stringify(cancelReasons),
        },
      });
    } else {
      toast('Please provide reason why you want to cancel subscription', {
        type: 'error',
      });
    }
  };

  function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  function getStripePlan(stripePlan) {
    var plan = stripePlan.plan;

    if (plan?.stripePlan?.tiers?.length > 0) {
      var temp = -1;
      var planPrice = plan.stripePlan.tiers.map((tier) => {
        return (
          <>
            {temp == -1 ? 'For The First' : 'For The Next'} &nbsp;
            {temp + 1} - {(temp = tier.up_to ? tier.up_to : <>&#8734;</>)}
            &nbsp;&nbsp; ${tier.unit_amount / 100}
            <br />
          </>
        );
      });
      return <>{planPrice}</>;
    } else {
      return ' $ ' + plan.amount + ' / ' + plan.interval;
    }
  }

  function getUpcomingInvoiceData() {
    if (userData?.myProfile?.upcomingInvoice) {
      var data = userData.myProfile.upcomingInvoice;
      if (data.amount_due > 0 && data.next_payment_date) {
        return (
          <Badge className='upcoming-invoice-badge'>
            Next Invoice: ${data.amount_due} on{' '}
            {formatDate(data.next_payment_date)}
          </Badge>
        );
      }
    } else return null;
  }

  function toggleAllDeleteModal() {
    setAllDeleteModal(!allDeleteModal);
    setCancelReasons([]);
  }

  function toggleCancelRequestModal() {
    setRequestToCancel(!requestToCancel);
    setCancelReasons([]);
  }

  return (
    <>
      <Row className='pt-3 pt-md-5 mb-4'>
        <Col md='6'>
          <h3>My Subscriptions</h3>
        </Col>
        {/* <Col md='6' align='right'>
          <h4>{getUpcomingInvoiceData()}</h4>
        </Col> */}
      </Row>

      <Modal isOpen={allDeleteModal} toggle={toggleAllDeleteModal}>
        <ModalHeader toggle={toggleAllDeleteModal}>
          Cancel Confirmation
        </ModalHeader>
        <ModalBody>
          Your subscription and access to SparkAPT will end at the end of
          current billing cycle. Are you sure you want to cancel {planName}{' '}
          subscription?
          <CancelReason setCancelReasons={setCancelReasons} />
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={cancelSubscription}
          >
            Yes, Cancel My Subscription
          </Button>
          <Button onClick={toggleAllDeleteModal}>No</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={requestToCancel} toggle={toggleCancelRequestModal}>
        <ModalHeader toggle={toggleCancelRequestModal}>
          Request Confirmation
        </ModalHeader>
        <ModalBody>
          Are you sure you want to request the {planName} subscription
          cancellation?
          <CancelReason setCancelReasons={setCancelReasons} />
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={callRequestCancelSubscription}
          >
            Yes, Cancel My Subscription
          </Button>
          <Button onClick={toggleCancelRequestModal}>No</Button>
        </ModalFooter>
      </Modal>

      {trialPopup === true && (
        <>
          <Elements stripe={stripePromise}>
            <PaymentForm hideTrialPopup={() => setTrialPopup(false)} />
          </Elements>
        </>
      )}
      {loading && <Spinner />}
      <Row className='md-5'>
        <Col lg='12'>
          {/* Plan Details */}
          <Row className='apartment-table'>
            <Col sm='12'>
              {data === 'noData' ? (
                <>
                  <div className='text-center' style={{ margin: '20vh 0' }}>
                    <h4>Oh... you don't have any active subscription!</h4>
                    <Button
                      onClick={() => setTrialPopup(true)}
                      color='success'
                      className='button-dashboard mt-2'
                    >
                      Subscribe now{' '}
                      <Icon icon='chevron-right' className='ml-2' />
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  {data === 'data' ? (
                    <>
                      {isSubscriptionBtn === true && (
                        <Button
                          onClick={() => setTrialPopup(true)}
                          color='success'
                          className='button-dashboard mt-1 mb-2'
                          style={{ float: 'right' }}
                        >
                          Subscribe now{' '}
                          <Icon icon='chevron-right' className='ml-2' />
                        </Button>
                      )}
                      <Table
                        // borderless
                        responsive
                        className='client-list'
                      >
                        <thead>
                          <tr>
                            <th>Plan</th>
                            <th>Amount</th>
                            <th>Subscription Period</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <>
                            {subscriptionsData &&
                              subscriptionsData.subscriptions.edges.map(
                                (
                                  {
                                    node: {
                                      id,
                                      stripeSubscriptionId,
                                      plan: { name: plan },
                                      plan: { amount },
                                      plan: { interval },
                                      user,
                                      status,
                                      active,
                                      currentStartDatetime,
                                      currentEndDatetime,
                                    },
                                  },
                                  i
                                ) => (
                                  <tr key={id}>
                                    <td>{plan}</td>
                                    <td>
                                      {getStripePlan(
                                        subscriptionsData.subscriptions.edges[i]
                                          ?.node
                                      )}
                                    </td>
                                    <td>
                                      {formatDate(currentStartDatetime)} -{' '}
                                      {formatDate(currentEndDatetime)}
                                    </td>
                                    <td>
                                      <button
                                        disabled={true}
                                        className={
                                          status === 'Active' || active
                                            ? 'btn btn-success'
                                            : 'btn btn-danger'
                                        }
                                      >
                                        {capitalizeFirstLetter(status)}
                                      </button>
                                    </td>
                                    <td>
                                      {status?.toLowerCase() === 'active' &&
                                      active ? (
                                        user.isUser ? (
                                          <button
                                            className='btn btn-danger'
                                            onClick={() =>
                                              handleSingleDelete(id, plan)
                                            }
                                          >
                                            Cancel
                                          </button>
                                        ) : (
                                          <button
                                            className='btn btn-danger'
                                            onClick={() =>
                                              handleCancelRequest(id, plan)
                                            }
                                          >
                                            Request To Cancel
                                          </button>
                                        )
                                      ) : (
                                        '-'
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                          </>
                        </tbody>
                      </Table>
                    </>
                  ) : null}
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

function CancelReason({ setCancelReasons }) {
  function handleReason() {
    const cancelForm = document.getElementById('cancelReasonForm');
    const cancelReasons = [];
    if (cancelForm) {
      for (let i = 0; i < cancelForm.elements.length; i++) {
        const element = cancelForm.elements[i];
        if (element.type === 'checkbox' && element.checked) {
          cancelReasons.push(element.value);
        }
        if (element.type === 'textarea' && element.value.length > 0) {
          cancelReasons.push(element.value);
        }
      }
    }
    setCancelReasons(cancelReasons);
  }
  return (
    <div className='mt-2'>
      If so, let us know what we can improve.
      <Form id='cancelReasonForm' className='mt-2'>
        <FormGroup check>
          <Input
            id='reason1'
            name='reason1'
            type='checkbox'
            value='Spark data is not up to date as per my expectations'
            onChange={handleReason}
          />{' '}
          <Label check htmlFor='reason1'>
            Spark data is not up to date as per my expectations
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id='reason2'
            name='reason2'
            type='checkbox'
            value='Spark data is not correct'
            onChange={handleReason}
          />{' '}
          <Label check htmlFor='reason2'>
            Spark data is not correct
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id='reason3'
            name='reason3'
            type='checkbox'
            value='Spark is not mobile friendly'
            onChange={handleReason}
          />{' '}
          <Label check htmlFor='reason3'>
            Spark is not mobile friendly
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id='reason4'
            name='reason4'
            type='checkbox'
            value='Price is expensive compared to the value Spark provides'
            onChange={handleReason}
          />{' '}
          <Label check htmlFor='reason4'>
            Price is expensive compared to the value Spark provides
          </Label>
        </FormGroup>
        <FormGroup check>
          <Input
            id='reason5'
            name='reason5'
            type='checkbox'
            value='No longer needed'
            onChange={handleReason}
          />{' '}
          <Label check htmlFor='reason5'>
            No longer needed
          </Label>
        </FormGroup>
        <FormGroup>
          <Input
            id='otherReason'
            name='text'
            type='textarea'
            placeholder='Other Reason'
            className='mt-1'
            onChange={handleReason}
          />
        </FormGroup>
      </Form>
    </div>
  );
}
