import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import Spinner from '../../components/Spinner';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { CITY, CITY_CREATE, CITY_UPDATE, CITIES, STATES } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import { ToggleButton } from '@sparklib';
import CreatableSelect from 'react-select/creatable';
import { Icon } from '@assets';

const US_TIMEZONES = [
	{
		id: 1,
		name: "Alaska",
	},
	{
		id: 2,
		name: "Central Time (US & Canada)",
	},
	{
		id: 3,
		name: "Eastern Time (US & Canada)",
	},
	{
		id: 4,
		name: "Hawaii",
	},
	{
		id: 5,
		name: "Mountain Time (US & Canada)",
	},
	{
		id: 6,
		name: "Pacific Time (US & Canada)",
	},
];

export default function CityForm(props) {
  const { sidebarToggle, operation } = props;
  const history = useHistory();
  const { id: cityId } = useParams();
  const [progress, setProgress] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [timeZoneError, setTimeZoneError] = useState(false);
  const [form_errors, setErrors] = useState([]);
  const newCity = {
    id: null,
    name: '',
    stateName: null,
    isVisible: true,
		timeZone: null
  };
  const [city, setCity] = useState(newCity);
  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: 'onChange',
  });

  const { data: stateData } = useQuery(STATES);
  useQuery(CITIES);
  const [cityCreate, { createLoading }] = useMutation(CITY_CREATE, {
    onCompleted: (data) => {
      if (data.cityCreate.errors && data.cityCreate.errors.length > 0) {
        setErrors(data.cityCreate.errors);
      } else {
        toast(data.cityCreate.message, { type: 'success' });
        sidebarToggle();
        setErrors('');
        history.push(`/cities`);
      }
    },
    refetchQueries: [CITIES, STATES],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const [cityUpdate, { updateLoading }] = useMutation(CITY_UPDATE, {
    onCompleted: (data) => {
      if (data.cityUpdate.errors && data.cityUpdate.errors.length > 0) {
        setErrors(data.cityUpdate.errors);
        toast(data.cityUpdate.message, { type: 'error' });
      } else {
        toast(data.cityUpdate.message, { type: 'success' });
        sidebarToggle();
        setErrors('');
        history.push(`/cities`);
      }
    },
    refetchQueries: [CITIES, STATES],
    onError: (e) => {
      console.log(e, 'error');
    },
  });
  useQuery(CITY, {
    variables: { id: parseInt(cityId) },
    skip: !cityId || operation === 'new',
    onCompleted: (data) => {
      clearErrors();
      setCity({
        id: Number(data.city.id),
        name: data.city.name || '',
        stateName: data.city.state.name && {
          label: data.city.state.name,
          value: data.city.state.name,
        },
        isVisible: data.city.isVisible,
        timeZone: data.city.timeZone && {
					label: data.city.timeZone,
					value: data.city.timeZone
				},
      });
    },
    onError: (error) => {
      toast('City not found', { type: 'error' });
      sidebarToggle();
    },
  });

  useEffect(() => {
    setProgress(createLoading || updateLoading ? true : false);
  }, [createLoading, updateLoading]);

  useEffect(() => {
    if (operation === 'new') {
      clearErrors();
      setCity(newCity);
    }
  }, [operation]);

  useEffect(() => {
    if (city.stateName) {
      setStateError(false);
    }

		if (city.timeZone){
			setTimeZoneError(false);
		}
  }, [city.stateName, city.timeZone]);

  const changeCity = (label, value) =>
    setCity((city) => {
      return { ...city, [label]: value };
    });
  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (city.stateName === null) {
      setStateError(true);
      return false;
    } else if (city.timeZone === null){
			setTimeZoneError(true);
      return false;
		}
    if (operation === 'new') {
      await cityCreate({
        variables: {
          ...city,
          stateName: city.stateName.label,
          timeZone: city.timeZone.label
        },
      });
    } else {
      await cityUpdate({
        variables: {
          ...city,
          stateName: city.stateName.label,
          timeZone: city.timeZone.label
        },
      });
    }
  };

  return (
    <>
      {progress && <Spinner />}

      <div className='p-4'>
        <Row>
          <Col md='12'>
            <h4>{operation === 'new' ? 'Create' : 'Edit'} Market</h4>
          </Col>
          <Col md='12'>
            {form_errors.length > 0 ? (
              <Col>
                {form_errors.map((err, index) => (
                  <Col className='bg-danger mt-2 text-white' key={index}>
                    {err}
                  </Col>
                ))}
              </Col>
            ) : null}
          </Col>
          <Col md='12' className='mt-md-3 inputs-icons'>
            <Row>
              <Col md='12'>
                <Form onSubmit={handleSubmit(onSubmit)} id='create-plan-form'>
                  <Row>
                    <Col md='6' className='mb-3'>
                      <Label className='w-100 mb-0 mr-3'>Market Visible</Label>
                      <ToggleButton
                        selected={city.isVisible}
                        firstOption='Yes'
                        secondOption='No'
                        width='85px'
                        rounded
                        toggleSelected={() => {
                          changeCity('isVisible', !city.isVisible);
                        }}
                      />
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='name'>Name</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='clipboard-check' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='name'
                            autoComplete='name'
                            value={city.name}
                            onChange={(e) =>
                              changeCity(e.target.name, e.target.value)
                            }
                            innerRef={register({
                              required: true,
                            })}
                          />
                        </InputGroup>
                        {_.get('name.type', errors) === 'required' && (
                          <p className='input-error'>* Name required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='12'>
                      <FormGroup>
                        <Label for='stateName'>Select State</Label>
                        <CreatableSelect
                          name='stateName'
                          value={city.stateName}
                          options={
                            typeof stateData !== 'undefined'
                              ? Object.values(stateData.states).map((state) =>
                                  JSON.parse(
                                    `{"value":"${state.name}", "label":"${state.name}"}`
                                  )
                                )
                              : []
                          }
                          onChange={(e) =>
                            changeCity('stateName', {
                              value: e.value,
                              label: e.value,
                            })
                          }
                          className='basic-multi-select w-100 bedsize'
                          classNamePrefix='select'
                          placeholder='Select State'
                        />
                        {stateError === true && (
                          <p className='input-error'>* State required.</p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md='12'>
                      <FormGroup>
                        <Label for='timeZone'>Select Time Zone</Label>
                        <CreatableSelect
                          name='timeZone'
                          value={city.timeZone}
                          options={
                            US_TIMEZONES.map((tz) =>
                                  JSON.parse(
                                    `{"value": "${tz.name}", "label": "${tz.name}"}`
                                  )
                                )
                          }
                          onChange={(e) =>
                            changeCity('timeZone', {
                              value: e.value,
                              label: e.value,
                            })
                          }
                          className='basic-multi-select w-100 bedsize'
                          classNamePrefix='select'
                          placeholder='Select Time Zone'
                        />
                        {timeZoneError === true && (
                          <p className='input-error'>* Time zone required.</p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md='12' className='mt-md-2'>
                      <Button
                        color='primary'
                        className='button-dashboard'
                        disabled={progress}
                      >
                        {operation === 'new' ? 'Create' : 'Update'} Market
                      </Button>
                      <Button
                        className='button-dashboard ml-2'
                        onClick={() => sidebarToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
