import React, { useRef } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
	Row,
	Col,
	Label,
	Container
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UPDATE_PASSWORD } from '@api';
import { useMutation } from '@apollo/client';

import { Icon } from '@assets';

export default function UpdatePassword({ showForm=false, ...props}) {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const password = useRef({});
  password.current = watch('new_password', '');
  const [updatePassword, { data, loading, error }] = useMutation(
    UPDATE_PASSWORD,
    {
      onCompleted: (data) => {
        if (
          data.updatePassword.errors &&
          data.updatePassword.errors.length > 0
        ) {
          data.updatePassword.errors.map((err) =>
            toast(err, { type: 'error' })
          );
        } else {
          toast(data.updatePassword.message, { type: 'success' });
          props.parentRef();
        }
      },
      onError: (e) => {
        console.log(e, 'updated passsword ON ERROR');
      },
    }
  );

  const onSubmit = (data, e) => {
    updatePassword({
      variables: {
        newPassword: data.new_password,
        oldPassword: data.old_password,
      },
    });
  };

  return (
    <>
      <Modal
        isOpen={props.displayModal}
        toggle={props.parentRef}
        centered
        className='change-password-modal text-center'
      >
        <Form className='form' onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={props.parentRef}>Change Password</ModalHeader>
          <ModalBody className='inputs-icons'>
            <FormGroup>
              <InputGroup>
                <InputGroupText>
                  <Icon icon='eye' />
                </InputGroupText>
                <Input
                  type='password'
                  placeholder='Old Password'
                  name='old_password'
                  autoComplete='off'
                  innerRef={register({
                    required: true,
                    minLength: 6,
                  })}
                />
              </InputGroup>
              {_.get('old_password.type', errors) === 'required' && (
                <p className='input-error'>* Please enter current password</p>
              )}
              {_.get('old_password.type', errors) === 'minLength' && (
                <p className='input-error'>
                  * Current password should be of minimum 6 letters
                </p>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup>
                <InputGroupText>
                  <Icon icon='unlock' />
                </InputGroupText>
                <Input
                  type='password'
                  placeholder='New Password'
                  name='new_password'
                  autoComplete='off'
                  innerRef={register({
                    required: true,
                    minLength: 6,
                  })}
                />
              </InputGroup>
              {_.get('new_password.type', errors) === 'required' && (
                <p className='input-error'>* Please enter new password</p>
              )}
              {_.get('new_password.type', errors) === 'minLength' && (
                <p className='input-error'>
                  * New password should be of minimum 6 letters
                </p>
              )}
            </FormGroup>

            <FormGroup>
              <InputGroup>
                <InputGroupText>
                  <Icon icon='lock' />
                </InputGroupText>
                <Input
                  type='password'
                  placeholder='Confirm Password'
                  name='confirm_password'
                  autoComplete='off'
                  innerRef={register({
                    validate: (value) =>
                      value === password.current ||
                      '* New password and Confirm password do not match',
                  })}
                />
              </InputGroup>
              {errors.confirm_password && (
                <p className='input-error'>{errors.confirm_password.message}</p>
              )}
            </FormGroup>

            <Button color='green' className='button-dashboard'>
              Update
            </Button>
          </ModalBody>
        </Form>
      </Modal>
			{
				showForm && 
					<div className='text-center mt-5 border-left pl-4'>
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Row className='mt-2'>
								<Col md='12' className='mb-3 text-left'>
									<h3>Change Password</h3>
								</Col>
								<Col md='12' className='text-left'>
									<FormGroup className='inputs-icons'>
										<Label for='firstName' >Old Password</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='eye' />
											</InputGroupText>
											<Input
												type='password'
												// placeholder='Old Password'
												name='old_password'
												autoComplete='off'
												innerRef={register({
													required: true,
													minLength: 6,
												})}
											/>
										</InputGroup>
										{_.get('old_password.type', errors) === 'required' && (
											<p className='input-error'>* Please enter current password</p>
										)}
										{_.get('old_password.type', errors) === 'minLength' && (
											<p className='input-error'>
												* Current password should be of minimum 6 letters
											</p>
										)}
									</FormGroup>
								</Col>
								<Col md='12' className='text-left'>
									<FormGroup className='inputs-icons'>
										<Label for='firstName' >New Password</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='unlock' />
											</InputGroupText>
											<Input
												type='password'
												// placeholder='New Password'
												name='new_password'
												autoComplete='off'
												innerRef={register({
													required: true,
													minLength: 6,
												})}
											/>
										</InputGroup>
										{_.get('new_password.type', errors) === 'required' && (
											<p className='input-error'>* Please enter new password</p>
										)}
										{_.get('new_password.type', errors) === 'minLength' && (
											<p className='input-error'>
												* New password should be of minimum 6 letters
											</p>
										)}
									</FormGroup>
								</Col>
								<Col md='12' className='text-left'>
									<FormGroup className='inputs-icons'>
										<Label for='firstName' >Confirm Password</Label>
										<InputGroup>
											<InputGroupText>
												<Icon icon='lock' />
											</InputGroupText>
											<Input
												type='password'
												// placeholder='Confirm Password'
												name='confirm_password'
												autoComplete='off'
												innerRef={register({
													validate: (value) =>
														value === password.current ||
														'* New password and Confirm password do not match',
												})}
											/>
										</InputGroup>
										{errors.confirm_password && (
											<p className='input-error'>{errors.confirm_password.message}</p>
										)}
									</FormGroup>
								</Col>
								<Col md='12'>
									<Button color='green' className='button-dashboard mt-2'>
										Update Password
									</Button>
								</Col>
							</Row>
						</Form>
				  </div>
			}
    </>
  );
}
