import React, { useState, useEffect } from 'react';
import { Row, Col, Spinner } from 'reactstrap';
import DashboardWidget from './../DashboardWidget';
import { ADMIN_SUMMARY } from '@api';
import { useQuery } from '@apollo/client';
import Loader from '../Spinner';
import Icon from '../CustomIcon';

export default function AdminMainDashboard({ userData }) {
  const [widgetList, setWidgetList] = useState('loading');
  const [onlineUsersCount, setOnlineUsersCount] = useState(0);
  const {
    data: adminDashboardData,
    loading: adminDashboardLoading,
  } = useQuery(ADMIN_SUMMARY, {
    onError: (e) => {
      console.log(e);
    },
  });
  useEffect(() => {
    if (adminDashboardData?.adminSummery) {
			setWidgetList('data');
    }
		else {
			setWidgetList('noData');
		}
  }, [adminDashboardData]);

	useEffect(() => {
		if (userData.length > 0) {
			var onlineUsers = userData.filter(user => user.isOnline);
			setOnlineUsersCount(onlineUsers.length);
		}
	}, [userData]);

  return (
    <>
      {adminDashboardLoading && <Loader />}
      {widgetList === 'data' ? (
        <Row className='mt-md-3 py-3'>
          <Col sm='6' lg='3'>
            <DashboardWidget
              color='blue' //red, green, blue, yellow, purple, orange, skyblue, pink
              link='/admin-properties'
              number={
                adminDashboardData && adminDashboardData.adminSummery.totalProperties
              }
              name='Total Properties'
              icon='user-alt'
            />
          </Col>
          <Col sm='6' lg='3'>
            <DashboardWidget
              color='red'
              link='/users'
              number={adminDashboardData && adminDashboardData.adminSummery.totalUsers}
              name='Total Users'
              icon='user-alt'
            />
          </Col>
          <Col sm='6' lg='3'>
            <DashboardWidget
              link='/subscriptions'
              color='purple'
              number={
                adminDashboardData &&
                adminDashboardData.adminSummery.totalSubscriptions
              }
              name='Total Subscriptions'
              icon='clipboard-list'
            />
          </Col>
          <Col sm='6' lg='3'>
            <DashboardWidget
              link='/leads'
              color='green'
              number={adminDashboardData && adminDashboardData.adminSummery.totalLeads}
              name='Total Leads'
              icon='address-card'
            />
          </Col>
          <Col sm='6' lg='3'>
            <DashboardWidget
              link='/inquiries'
              color='orange'
              number={
                adminDashboardData &&
                adminDashboardData.adminSummery.totalInquiries
              }
              name='Total Inquiries'
              icon='receipt'
            />
          </Col>
          <Col sm='6' lg='3'>
            <DashboardWidget
              link='/notifications'
              color='yellow'
              number={
                adminDashboardData &&
                adminDashboardData.adminSummery.totalNotifications
              }
              name='Notifications'
              icon='receipt'
            />
          </Col>
          <Col sm='6' lg='3'>
            <DashboardWidget
							link='/users'
              color='red'
              number={
                onlineUsersCount
              }
              name='Online Users'
              icon='eye'
            />
          </Col>

          {/* <Col sm='6' lg='3'>
            <div>
              <Icon icon='info' size={32} color='#f78259' />
              <span>Info Icon</span>
            </div>
            <div>
              <Icon icon='warning' />
              <span>Warning Icon</span>
            </div>
          </Col> */}
        </Row>
      ) : (
        <>
          {widgetList === 'noData' ? (
						<Col className='md-4 mt-5'>
							<div align='center'>
								<Spinner
									color="primary"
									style={{
										height: '3rem',
										width: '3rem'
									}}
									type="grow"
									>
									Loading...
								</Spinner>
							</div>
						</Col>
          ) : null}
        </>
      )}
    </>
  );
}
