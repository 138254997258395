import React, { useState, useEffect, useReducer } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  CardBody,
  Card,
	Badge
} from 'reactstrap';
import Spinner from '../../Spinner';
import DatePicker from 'react-datepicker';
import getMonth from "date-fns/getMonth";
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';
import Nouislider from 'nouislider-react';
import 'react-datepicker/dist/react-datepicker.css';
import {
  CITIES,
  CREATE_CLIENT,
  // CLIENTS,
  UPDATE_CLIENT,
  CLIENT,
  NEIGHBORHOODS,
  PROPERTY_POLICIES,
  PROPERTY_ADDONS,
  UNIT_ADDONS,
  CITY_PROPERTIES
} from '@api';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Icon } from '@assets';
import { unitTypes, Collapse, defaultAmenities, ClientStatuses, creditScores, leadSources } from '@sparklib';
import { enforceFormat, formatToPhone, justFomatePhoneNo } from '../../shared/sparkLib/phoneNumberFormatter';
import ClientShow from './ClientShow';

export default function ClientForm(props) {
  const history = useHistory();
  const bathroomOptions = ['1', '1.5', '2', '2.5', '3'];
  const petOptions = ['Cats', 'Dogs', 'ESA'];
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const clientValue = {
    email: '',
    secondaryEmail: '',
    otherEmail: '',
    phone: '',
    firstName: '',
    lastName: '',
    notes: '',
    budget: '',
    neighborhoods: [],
    currentStatus: ClientStatuses[0],
    unitFeatures: [],
    availFrom: null,
    availTo: null,
    sqFeet: '',
    policies: [],
    amenities: [],
    googleRating: 0,
    minRent: null,
    maxRent: null,
    bed: [],
    bath: {},
    nextFollow: null, // new Date(),
    leaseEnd: null, // new Date(),
    city: { label: '', value: '' },
		roommates: [],
    leasedProperty: { label: '', value: '' },
    movingInspiration: '',
    bio: '',
    searchStatus: '',
    currentResidence: '',
    occupation: '',
    creditScore: null,
    income: '',
    miscellaneous: '',
    leadSource: null,
    leadSourceDetails: '',
    birthday: null,
    dealProperty: { label: '', value: '' },
    unitNumber: '',
    mls: '',
    leaseSigned: null,
    leaseStartDate: null,
    dealLeaseEndDate: null,
    leaseTerm: '',
    grossRent: null,
    concession: '',
    isPercentBuildingCommission: true,
    buildingCommission: '',
    netRent: null,
    invoiceTotal: null,
    dealCity: { label: '', value: '' }
  };
	const defaultRoommate = {
		firstName: '',
		lastName: '',
		email: '',
		phone: ''
	}
	const roommatesReducer = (state, action) => {
		switch (action.type) {
			case 'add':
				if (state.length >= 10){
					toast.error("You can add upto 10 roommates")
					return state
				}
				return [...state, {...action.value, index: state.at(-1) ? state.at(-1).index + 1 : 0 }]
			case 'set':
				return action.values.map((e, index) => { return ({...e, index: index })})
			case 'remove':
				return state.filter(r => r.index !== action.index)
			case 'reset':
				return []
			default:
				return state
		}
	}

	const [roommates, handleRoommates] = useReducer(roommatesReducer, []);
	const [roommateData, setRoommateData] = useState(defaultRoommate)
	const [error, setError] = useState({fN: false, email: false})
  const [client, setClient] = useState(clientValue);
  const [cityError, setCityError] = useState(false);
  const [moveInError, setMoveInError] = useState(false);
  const inputValue =
    moment(`${client.availFrom}`).format('MM/DD/YYYY') +
    ' - ' +
    moment(`${client.availTo}`).format('MM/DD/YYYY');
  const [isLoading, setIsLoading] = useState(false);
  const [form_errors, setErrors] = useState([]);
  const [communities, setCommunities] = useState();
  const [selectedCity, setSelectedCity] = useState({ label: '', value: '' });
  const [selectedDealCity, setSelectedDealCity] = useState({ label: '', value: '' });
  const policies = useQuery(PROPERTY_POLICIES);
  const { data: policiesData } = policies;
  const amenities = useQuery(PROPERTY_ADDONS);
  const { data: amenitiesData } = amenities;
  const unitFeatures = useQuery(UNIT_ADDONS);
  const { data: unitFeaturesData } = unitFeatures;
  const numberFormatter = new Intl.NumberFormat('en-US');

  // useQuery(CLIENTS);
  const { data: citiesData } = useQuery(CITIES, {
    onCompleted: (e) => {
			setClient((client) => ({ ...client, city: { label: e.cities[0]?.name, value: e.cities[0]?.id}, dealCity: { label: e.cities[0]?.name, value: e.cities[0]?.id} }));
      if (e.cities.length > 0) {
        setSelectedCity({
          label: e.cities[0]?.name,
          value: e.cities[0]?.id,
        });
        setSelectedDealCity({
          label: e.cities[0]?.name,
          value: e.cities[0]?.id,
        });
      }
		},
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  if (citiesData && selectedCity.label === '') {
    setSelectedCity({
      label: citiesData.cities[0].name,
      value: citiesData.cities[0].id,
    });
  }

  if (citiesData && selectedDealCity?.label === '') {
    setSelectedDealCity({
      label: citiesData.cities[0].name,
      value: citiesData.cities[0].id,
    });
  }

  const [
    guestCommunitiesQuery,
    {
      data: cityProperties
    },
  ] = useLazyQuery(CITY_PROPERTIES, {
    variables: {
      cityId: selectedCity.value ? parseInt(selectedCity.value) : '',
    },
  });

  const [
    dealCommunitiesQuery,
    {
      data: dealCityProperties
    },
  ] = useLazyQuery(CITY_PROPERTIES, {
    variables: {
      cityId: selectedDealCity.value ? parseInt(selectedDealCity.value) : '',
    },
  });

  const guestCommunities = () => {
    guestCommunitiesQuery({
      variables: {
        cityId: selectedCity.value ? parseInt(selectedCity.value) : null,
      },
    });
  };

  const dealCommunities = () => {
    dealCommunitiesQuery({
      variables: {
        cityId: selectedDealCity.value ? parseInt(selectedDealCity.value) : null,
      },
    });
  };

  useEffect(() => {
    if (selectedCity.value !== '') {
      setCommunities();
      guestCommunities();
    }
  }, [citiesData, selectedCity]);

  useEffect(() => {
    if (selectedDealCity.value !== '') {
      dealCommunities();
    }
  }, [citiesData, selectedDealCity]);

  const handleOnChangeCity = (selectedOption) => {
    setSelectedCity(selectedOption);
  };
  const handleOnChangeDealCity = (selectedOption) => {
    setSelectedDealCity(selectedOption);
  };
  const handleOnCommunities = (selectedOption) => {
    setCommunities(selectedOption);
  };

  const { data: neighborhoodData } = useQuery(NEIGHBORHOODS, {
    variables: { cityId: client.city?.value && Number(client.city?.value) },
    skip: !client.city?.value,
  });

  const { id: clientId } = useParams();
  const { data: clientData } = useQuery(CLIENT, {
    variables: { id: Number(clientId) },
    skip: !clientId,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setClient({
        ...data.client,
        availFrom: data.client.availFrom
          ? new Date(data.client.availFrom)
          : null,
        availTo: data.client.availTo ? new Date(data.client.availTo) : null,
        nextFollow: data.client.nextFollowUp
          ? new Date(data.client.nextFollowUp)
          : null,
        leaseEnd: data.client.leaseEndDate
          ? new Date(data.client.leaseEndDate)
          : null,
        bed: data.client.bedroomArray,
        bath: data.client.bathroom
          ? `{"value":"${data.client.bathroom}", "label":"${data.client.bathroom}"}`
          : {},
        city: data.client.city
          ? { label: data.client.city?.name, value: data.client.city?.id }
          : {},
        currentStatus: data.client.currentStatus
          ? ClientStatuses.find(s => s.value === data.client.currentStatus)
          : ClientStatuses[0],
        googleRating: data.client.rating ? data.client.rating : 0,
				petNumber: data.client.petNumber ? data.client.petNumber : 0,
				petType: data.client.petType ? data.client.petType : "-",
				noOfParking: data.client.noOfParking ? data.client.noOfParking : null,
        leasedProperty: data.client.leasedProperty ? { label: data.client.leasedProperty?.name, value: data.client.leasedProperty?.id } : {},
        movingInspiration: data.client.movingInspiration,
        bio: data.client.bio,
        searchStatus: data.client.searchStatus,
        currentResidence: data.client.currentResidence,
        occupation: data.client.occupation,
        creditScore: data.client.creditScore ? creditScores.find(cs => cs.value === data.client.creditScore) : {},
        income: data.client.income,
        miscellaneous: data.client.miscellaneous,
        leadSource: data.client.leadSource ? leadSources.find(ls => ls.value === data.client.leadSource) : {},
        leadSourceDetails: data.client.leadSourceDetails,
        birthday: data.client.birthday ? new Date(data.client.birthday) : null,
        //neighborhoods: getNeighborhoodData(data.client.neighborhoodIdsArray),
        dealProperty: data.client.deal?.property ? { label: data.client.deal?.property?.name, value: data.client.deal?.property?.id } : {},
        unitNumber: data.client.deal?.unitNumber,
        mls: data.client.deal?.mls,
        leaseSigned: data.client.deal?.leaseSigned ? new Date(data.client.deal?.leaseSigned) : null,
        leaseStartDate: data.client.deal?.leaseStartDate ? new Date(data.client.deal?.leaseStartDate) : null,
        dealLeaseEndDate: data.client.deal?.leaseEndDate ? new Date(data.client.deal?.leaseEndDate) : null,
        leaseTerm: data.client.deal?.leaseTerm,
        grossRent: data.client.deal?.grossRent,
        concession: data.client.deal?.concession,
        isPercentBuildingCommission: data.client.deal?.isPercentBuildingCommission ? data.client.deal?.isPercentBuildingCommission : true,
        buildingCommission: data.client.deal?.buildingCommission,
        netRent: data.client.deal?.netRent,
        invoiceTotal: data.client.deal?.invoiceTotal,
        dealCity: data.client.deal?.city ? { label: data.client.deal?.city?.name, value: data.client.deal?.city?.id } : {}
      });
			handleRoommates({ type: 'set', values: data.client?.roommates?.length > 0 ? data.client.roommates : [] });
    },
    onError: (error) => {
      console.log(error + '>>>>> ERROR >>>>>');
      toast('Client not found', { type: 'error' });
      //props.sidebarToggle();
    },
  });

  useEffect(() => {
    if (props.new === 'new') {
      clearInput();
    }
  }, [props.new]);

  const changeClientDetail = ({ e, key = null }) => {
    if (e.target) {
      const { name, value } = e.target;
      setClient((client) => ({ ...client, [name]: value || '' }));
      if (e.target.value == "leased_pending_move_in" && key == "currentStatus") {
        document.getElementById("propertyDropdown").style.display = "block";
      }
      else {
        document.getElementById("propertyDropdown").style.display = "none";
      }
    } else {
      e.constructor === Array
        ? setClient((client) => ({ ...client, [key]: e }))
        : setClient((client) => ({ ...client, [key]: e }));
      if (e.value == "leased_pending_move_in" && key == "currentStatus") {
        document.getElementById("propertyDropdown").style.display = "block";
      }
      else {
        document.getElementById("propertyDropdown").style.display = "none";
      }
    }
  };

  function clearInput() {
    setClient((client) => ({ ...clientValue, city: client.city}));
    setErrors([]);
  }

  useEffect(() => {
    let neighborhoodListItems = [];
    if (neighborhoodData && clientData) {
      clientData.client.neighborhoodIdsArray?.map((id) => {
        let n = neighborhoodData.neighborhoods.find(
          (neighborhood) => Number(neighborhood.id) === Number(id)
        );
        if (n) {
          neighborhoodListItems.push({ label: n.name, value: n.id });
        }
      });

      setClient((client) => ({
        ...client,
        neighborhoods: neighborhoodListItems,
      }));
    } else {
      setClient((client) => ({ ...client, neighborhoods: [] }));
    }
  }, [neighborhoodData, clientData]);

  useEffect(() => {
    if (policiesData && clientData) {
      let policyListItems = [];
      clientData.client.policiesArray?.map((name) => {
        let p = policiesData.propertyPolicies.find(
          (policy) => policy.name === name
        );
        if (p) {
          policyListItems.push({ label: p.name, value: p.name });
        }
      });
      if (policyListItems.length > 0) {
        setClient((client) => ({ ...client, policies: policyListItems }));
      }
    }
  }, [policiesData, clientData]);

  useEffect(() => {
    if (amenitiesData && clientData) {
      let addonsListItems = [];
      clientData.client.addonsArray?.map((name) => {
        let a = amenitiesData.propertyAddons.find(
          (addon) => addon.name === name
        );
        if (a) {
          addonsListItems.push({ label: a.name, value: a.name });
        }
      });
      if (addonsListItems.length > 0) {
        setClient((client) => ({ ...client, amenities: addonsListItems }));
      }
    }
  }, [amenitiesData, clientData]);

  useEffect(() => {
    if (unitFeaturesData && clientData) {
      let unitFeatureListItems = [];
      clientData.client.unitFeaturesArray?.map((name) => {
        let uf = unitFeaturesData.unitAddons.find(
          (feature) => feature.name === name
        );
        if (uf) {
          unitFeatureListItems.push({ label: uf.name, value: uf.name });
        }
      });
      if (unitFeatureListItems.length > 0) {
        setClient((client) => ({
          ...client,
          unitFeatures: unitFeatureListItems,
        }));
      }
    }
  }, [unitFeaturesData, clientData]);

  const [clientCreate, { loading }] = useMutation(CREATE_CLIENT, {
    onCompleted: (data) => {
			props.refetchClients()
      setIsLoading(false);
      if (data.clientCreate.errors && data.clientCreate.errors.length > 0) {
        setErrors(data.clientCreate.errors);
      } else if (data.clientCreate.warning && data.clientCreate.warning.length > 0) {
        toast(data.clientCreate.message, { type: 'success' });
        toast(data.clientCreate.warning, { type: 'info', autoClose: false, hideProgressBar: true });
				props.sidebarToggle();
        document.getElementById('addClient').reset();
        document.getElementById('dealClient').reset();
        clearInput();
        history.push(`/clients`);
			} else {
        toast(data.clientCreate.message, { type: 'success' });
        props.sidebarToggle();
        document.getElementById('addClient').reset();
        document.getElementById('dealClient').reset();
        clearInput();
        history.push(`/clients`);
      }
    },
    // refetchQueries: [CLIENTS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });
  const [clientUpdate, { data, loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_CLIENT, {
      onCompleted: (data) => {
				props.refetchClients()
        setIsLoading(false);
        if (data.clientUpdate.errors && data.clientUpdate.errors.length > 0) {
          data.clientUpdate.errors.map((err) => toast(err, { type: 'error' }));
        } else {
          toast(data.clientUpdate.message, { type: 'success' });
          props.sidebarToggle();
          document.getElementById('addClient').reset();
          document.getElementById('dealClient').reset();
          clearInput();
          history.push(`/clients`);
        }
      },
      // refetchQueries: [CLIENTS, 'clients'],
      onError: (e) => {
        console.log(e, 'updated client ON ERROR');
      },
    });

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (client.availTo) {
      setMoveInError(false);
    }
  }, [client.availTo]);
  function queryVariables(data) {
    return {
      firstName: client.firstName,
      lastName: client.lastName,
      email: client.email,
      secondaryEmail: client.secondaryEmail,
      otherEmail: client.otherEmail,
      phone: client.phone ? justFomatePhoneNo(client.phone) : null,
      notes: client.notes ? client.notes : null,
      cityId: parseInt(client.city?.value),
      currentStatus: client.currentStatus ? client.currentStatus?.value : 'lead',
      leaseEndDate: client.leaseEnd ? client.leaseEnd : null,
      nextFollowUp: client.nextFollow ? client.nextFollow : null,
      minRent: client.minRent ? Number(client.minRent) : null,
      maxRent: client.maxRent ? Number(client.maxRent) : null,
      availFrom: client.availFrom ? client.availFrom : null,
      availTo: client.availTo ? client.availTo : null,
      sqFeet: client.sqFeet ? Number(client.sqFeet) : null,
      bedroom: client.bed ? client.bed : null,
      bathroom: _.isEmpty(client.bath)
        ? null
        : parseFloat(JSON.parse(client.bath)?.value),
      neighborhoods:
        client.neighborhoods && client.neighborhoods.length > 0
          ? client.neighborhoods.map((opt) => Number(opt.value))
          : [],
      addons:
        client.amenities && client.amenities.length > 0
          ? client.amenities.map((opt) => opt.value)
          : [],
      policies:
        client.policies && client.policies.length > 0
          ? client.policies.map((opt) => opt.value)
          : [],
      unitFeatures:
        client.unitFeatures && client.unitFeatures.length > 0
          ? client.unitFeatures.map((opt) => opt.value)
          : [],
      rating: client.googleRating ? client.googleRating : null,
			petNumber: client.petNumber ? Number(client.petNumber) : 0,
			petType: client.petType ? client.petType : "-",
			noOfParking: client.noOfParking ? client.noOfParking : null,
      leasedPropertyId: client.leasedProperty ? parseInt(client.leasedProperty?.value) : null,
			roommates: filterRoommates(roommates),
      movingInspiration: client.movingInspiration,
      bio: client.bio,
      searchStatus: client.searchStatus,
      currentResidence: client.currentResidence,
      occupation: client.occupation,
      creditScore: client.creditScore ? client.creditScore?.value : null,
      income: client.income ? Number(client.income) : null,
      miscellaneous: client.miscellaneous,
      leadSource: client.leadSource ? client.leadSource?.value : null,
      leadSourceDetails: client.leadSourceDetails,
      birthday: client.birthday ? client.birthday : null,
      dealPropertyId: client.dealProperty ? parseInt(client.dealProperty?.value) : null,
      unitNumber: client.unitNumber,
      mls: client.mls,
      leaseSigned: client.leaseSigned ? client.leaseSigned : null,
      leaseStartDate: client.leaseStartDate ? client.leaseStartDate : null,
      dealLeaseEndDate: client.dealLeaseEndDate ? client.dealLeaseEndDate : null,
      leaseTerm: client.leaseTerm ? Number(client.leaseTerm) : null,
      grossRent: client.grossRent ? Number(client.grossRent) : null,
      concession: client.concession,
      isPercentBuildingCommission: client.isPercentBuildingCommission,
      buildingCommission: client.buildingCommission ? Number(client.buildingCommission) : null,
      netRent: client.netRent ? Number(client.netRent) : null,
      invoiceTotal: client.invoiceTotal ? Number(client.invoiceTotal) : null,
      dealCityId: client.dealCity ? parseInt(client.dealCity?.value) : null
    };
  }

	function filterRoommates(roommates) {
		return roommates.map(r => {
			delete r.index;
			delete r.__typename;
			const roommate = {id: r.id ? Number(r.id) : null, first_name: r.firstName, last_name: r.lastName, email: r.email, phone: r.phone}
			return roommate
		})
	}

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (!client.availTo && client.availFrom) {
      setMoveInError(true);
      return false;
    }
    if (!client.city.value) {
      setCityError(true);
      return false;
    }
    setIsLoading(true);
    if (props.new === 'new') {
      await clientCreate({
        variables: queryVariables(data),
      });
    } else {
      await clientUpdate({
        variables: {
          ...queryVariables(data),
          ...(clientId && {
            id: Number(clientId),
          }),
        },
      });
    }
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    changeClientDetail({
      e: {
        target: { name: 'availFrom', value: start },
      },
    });
    changeClientDetail({
      e: {
        target: { name: 'availTo', value: end },
      },
    });
  };
  if (loading || updateLoading) {
    <div>
      <Spinner />
    </div>;
  }

	const onAddRoomate = () => {
		let err = {}
		if (!roommateData.firstName) err["fN"] = true
		if (!roommateData.email) err["email"] = true
		if (err["fN"] || err["email"]) {
			setError(err)
			return
		} else setError({fN: false, email: false})
		const filteredData = {...roommateData, phone: roommateData.phone ? justFomatePhoneNo(roommateData.phone) : null}
		handleRoommates({ type: 'add', value: filteredData })
		setRoommateData(defaultRoommate)
	}

	function removeRoommate(index){
		handleRoommates({ type: 'remove', index: index })
	}

	function changeRoommateData(e){
		const { value, name } = e.target
		setRoommateData({...roommateData, [name]: value})
	}

	useEffect(() => {
		const inputElement = document.getElementById('phoneNumber');
		const roommatePhone = document.getElementById('rPhone');
		if (inputElement != null) {
			inputElement.addEventListener('keydown',enforceFormat);
			inputElement.addEventListener('keyup',formatToPhone);
		}
		if (roommatePhone) {
			roommatePhone.addEventListener('keydown',enforceFormat);
			roommatePhone.addEventListener('keyup',formatToPhone);
		}
		if (clientId && client.phone && client.phone.length < 16){
			var formatedPhoneNo = justFomatePhoneNo(client.phone);
			document.getElementById('phoneNumber').value = formatedPhoneNo;
			if (formatedPhoneNo?.length === 16) setClient((client) => ({ ...client, phone: formatedPhoneNo }));
		}
	}, [])

  return (
    <>
      <div className='p-4'>
        <Row>
          <Col md='12'>
            <h4>{props.new === 'new' ? 'New Client' : 'Client Details'}</h4>
          </Col>
          <Col md='12'>
            {form_errors.length > 0 ? (
              <Col>
                {form_errors.map((err) => (
                  <Col className='bg-danger mt-2 text-white'>{err}</Col>
                ))}
              </Col>
            ) : null}
          </Col>
          <Col md='12' className='mt-md-3 inputs-icons'>
            <Form onSubmit={handleSubmit(onSubmit)} id='addClient'>
              <Collapse title={'Basic Info'} open={true}>
                <Row>
                  <Col md='4'>
                    <FormGroup>
                      <Label for='firstName'>First Name</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Icon icon='user' />
                        </InputGroupText>
                        <Input
                          type='text'
                          name='firstName'
                          value={client.firstName}
                          autoComplete='off'
                          onChange={(e) => changeClientDetail({ e })}
                          innerRef={register({
                            required: true,
                          })}
                        />
                      </InputGroup>
                      {_.get('firstName.type', errors) === 'required' && (
                        <p className='input-error'>* First Name required.</p>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md='4'>
                    <FormGroup>
                      <Label for='lastName'>Last Name</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Icon icon='user' />
                        </InputGroupText>
                        <Input
                          type='text'
                          defaultValue={client.lastName}
                          name='lastName'
                          autoComplete='off'
                          onChange={(e) => changeClientDetail({ e })}
                          innerRef={register({
                            required: true,
                          })}
                        />
                      </InputGroup>
                      {_.get('lastName.type', errors) === 'required' && (
                        <p className='input-error'>* Last Name required.</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup>
                      <Label for='clientPhone'>Phone</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Icon icon='phone-alt' />
                        </InputGroupText>
                        <Input
													id="phoneNumber"
                          type='text'
                          name='phone'
                          defaultValue={client.phone ? justFomatePhoneNo(client.phone) : ''}
                          onChange={(e) => {
                            if (e.target.value?.length <= 16) {
                              var formatedPhoneNo = justFomatePhoneNo(e.target.value);
                              if (formatedPhoneNo?.length === 16) {
                                setClient((client) => ({ ...client, phone: formatedPhoneNo }));
                              }
                              // changeClientDetail({ e });
                            }
                          }}
                          autoComplete='off'
													innerRef={register({
														required: true,
														minLength: 16,
														maxLength: 16,
													})}
                        />
                      </InputGroup>
                      {_.get('phone.type', errors) === 'required' && (
                        <p className='input-error'>* Phone required.</p>
                      )}
											{(_.get('phone.type', errors) === 'minLength' || _.get('phone.type', errors) === 'maxLength') && (
												<p className='input-error'>* Invalid Phone Number.</p>
											)}
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup>
                      <Label for='clientEmail'>Email</Label>
                      <InputGroup>
                        <InputGroupText>
                          <Icon icon='envelope' />
                        </InputGroupText>
                        <Input
                          type='text'
                          name='email'
                          autoComplete='off'
                          onChange={(e) => changeClientDetail({ e })}
                          defaultValue={client.email}
                          innerRef={register({
                            required: true,
                            pattern:
                              /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                          })}
                        />
                      </InputGroup>
                      {_.get('email.type', errors) === 'required' && (
                        <p className='input-error'>* Email required.</p>
                      )}
                      {_.get('email.type', errors) === 'pattern' && (
                        <p className='input-error'>* Invalid Email.</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md='4'>
                    <FormGroup>
                      <Label for='city'>Select Market</Label>
                      <InputGroup>
                        <Select
                          name='city'
                          options={
                            typeof citiesData !== 'undefined'
                              ? Object.values(citiesData.cities).map((city) =>
                                  JSON.parse(
                                    `{"value":${city.id}, "label":"${city.name}"}`
                                  )
                                )
                              : []
                          }
                          value={client.city}
                          onChange={(e) => {
                            setClient({ ...client, neighborhoods: [] });
                            changeClientDetail({ e, key: 'city' });
                            handleOnChangeCity(e);
                          }}
                          className='w-100'
                          classNamePrefix='default-select'
                          id='city'
                          isSearchable={true}
                        />
                      </InputGroup>
                    </FormGroup>
                    {cityError && (
                      <p className='input-error'>Please select market</p>
                    )}
                  </Col>
                  <Col lg='4'>
                    <FormGroup>
                      <Label for='clientNotes'>Select Status </Label>
                      <InputGroup>
                        <Select
                          name='status'
                          options={ClientStatuses}
                          className='w-100'
                          classNamePrefix='default-select'
                          id='selectedStatus'
                          isSearchable={true}
                          onChange={(e) =>
                            changeClientDetail({
                              e,
                              key: 'currentStatus',
                            })
                          }
                          value={client.currentStatus}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg='4' id='propertyDropdown' style={{ display: 'none' }}>
                    <FormGroup>
                      <Label for='leasedProperty'>Where did they lease?</Label>
                      <InputGroup>
                        <Select
                          name='leasedProperty'
                          closeMenuOnSelect={true}
                          options={cityProperties?.cityProperties?.map(
                            (property) =>
                              JSON.parse(
                                `{"value":"${
                                  property.id
                                }", "label":"${property.name.replace(
                                  /[\n\r\t]/g,
                                  ' '
                                )}"}`
                              )
                          )}
                          isMulti={false}
                          placeholder='Select Community'
                          onChange={(communities) =>
                            handleOnCommunities(communities)
                          }
                          value={communities}
                          className='multi-select-client w-100'
                          classNamePrefix='default-select'
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup>
                      <Label for='leadSource'>Lead Source</Label>
                      <Select
                        name='leadSource'
                        options={leadSources}
                        className='w-100'
                        classNamePrefix='default-select'
                        id='leadSource'
                        isSearchable={true}
                        placeholder='Select One'
                        onChange={(e) =>
                          changeClientDetail({
                            e,
                            key: 'leadSource',
                          })
                        }
                        value={client.leadSource}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup>
                      <Label for='leadSourceDetails'>Lead Source Details</Label>
                      <InputGroup>
                        <Input
                          type='text'
                          name='leadSourceDetails'
                          value={client.leadSourceDetails}
                          onChange={(e) => changeClientDetail({ e })}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup>
                      <Label for='birthday'>Birthday</Label>
                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                              {"<"}
                            </button> */}
                            {/* <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) => changeYear(value)}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select> */}
                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                              {">"}
                            </button> */}
                          </div>
                        )}
                        className='form-control'
                        selected={client.birthday ? client.birthday : null}
                        onChange={(date) =>
                          changeClientDetail({
                            e: {
                              target: { name: 'birthday', value: date },
                            },
                          })
                        }
                        disabledKeyboardNavigation
                        placeholderText='Birthday'
                        dateFormat='MMMM d'
                        name='birthday'
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup>
                      <Label for='createdAt'>Created At</Label>
                      <DatePicker
                        className='form-control'
                        selected={client.createdAt ? new Date(client.createdAt) : new Date()}
                        disabledKeyboardNavigation
                        placeholderText='Created At'
                        dateFormat='MMMM d, yyyy'
                        name='createdAt'
                        disabled
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                  <Col lg='4'>
                    <FormGroup>
                      <Label for='updatedAt'>Last Modified</Label>
                      <DatePicker
                        className='form-control'
                        selected={client.updatedAt ? new Date(client.updatedAt) : new Date()}
                        disabledKeyboardNavigation
                        placeholderText='Last Modified'
                        dateFormat='MMMM d, yyyy'
                        name='updatedAt'
                        disabled
                        readOnly
                      />
                    </FormGroup>
                  </Col>
									{/*<Col lg='4'>
										<FormGroup>
											<Label for='PetNumber'>No. of Pet</Label>
											<InputGroup>
                        <InputGroupText>
													<Icon icon='dog' />
                        </InputGroupText>
                        <Input
                          type='text'
                          defaultValue={client.petNumber}
                          name='petNumber'
                          autoComplete='off'
                          onChange={(e) => changeClientDetail({ e })}
                          // innerRef={register({
                          //   required: true,
                          // })}
                        />
                      </InputGroup>
										</FormGroup>
                  </Col>*/}
                </Row>
              </Collapse>
              <Collapse className='mt-3' title={'Roommates'}>
								<Row>
									{roommates.map(roommate => <RoommateInfo roommate={roommate} removeRoommate={removeRoommate} />)}
								</Row>
								<Row className='my-2'>
									<Col md='3'>
										<Input
											type='text'
											id="rFN"
											placeholder='First Name'
											name="firstName"
											value={roommateData.firstName}
											autoComplete='off'
											onChange={changeRoommateData}
                      className="mb-3"
										/>
										{error?.fN && (
											<p className='input-error'>* First Name required.</p>
										)}
									</Col>
									<Col md='3'>
										<Input
											type='text'
											id="rLN"
											value={roommateData.lastName}
											placeholder='Last Name'
											name="lastName"
											autoComplete='off'
											onChange={changeRoommateData}
                      className="mb-3"
										/>
									</Col>
									<Col md='3' >
										<Input
											type='text'
											id="rEmail"
											value={roommateData.email}
											placeholder='Email'
											name="email"
											autoComplete='off'
											onChange={changeRoommateData}
                      className="mb-3"
										/>
										{error?.email && (
											<p className='input-error'>* Email required.</p>
										)}
									</Col>
									<Col md='2' >
										<Input
											type='text'
											value={roommateData.phone}
											id="rPhone"
											placeholder='Phone'
											name="phone"
											autoComplete='off'
											onChange={changeRoommateData}
                      className="mb-3"
										/>
									</Col>
									<Col md='1'>
										<Button
											color="success"
											onClick={onAddRoomate}
										>
											Add
										</Button>
									</Col>
								</Row>
              </Collapse>
              <Collapse className='mt-3' title={'Detailed Information (private notes for agent only)'}>
                <Row>
                  <Col md='12'>
                    <FormGroup>
                      <Label for='movingInspiration'>Moving Inspiration</Label>
                      <Input
                        type='text'
                        name='movingInspiration'
                        value={client.movingInspiration}
                        placeholder='E.g. change of scenery, moving in together, new job…'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <FormGroup>
                      <Label for='bio'>Bio</Label>
                      <Input
                        type='textarea'
                        name='bio'
                        value={client.bio}
                        placeholder='E.g. has a dog named Bella, loves rock climbing, and her favorite karaoke song is…'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <FormGroup>
                      <Label for='searchStatus'>Search Status</Label>
                      <Input
                        type='text'
                        name='searchStatus'
                        value={client.searchStatus}
                        placeholder='E.g. just started her search, not working with other brokers'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <FormGroup>
                      <Label for='currentResidence'>Current Residence</Label>
                      <Input
                        type='text'
                        name='currentResidence'
                        value={client.currentResidence}
                        placeholder='E.g. AMLI 900'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label for='occupation'>Occupation</Label>
                      <Input
                        type='text'
                        name='occupation'
                        value={client.occupation}
                        placeholder='E.g. salesforce developer'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <FormGroup>
                      <Label for='creditScore'>Credit Score</Label>
                      <Select
                        name='creditScore'
                        options={creditScores}
                        className='w-100'
                        classNamePrefix='default-select'
                        id='creditScore'
                        isSearchable={true}
                        placeholder='Select One'
                        onChange={(e) =>
                          changeClientDetail({
                            e,
                            key: 'creditScore',
                          })
                        }
                        value={client.creditScore}
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label for='income'>Income</Label>
                      <Input
                        type='text'
                        name='income'
                        value={client.income}
                        placeholder='E.g. $250,000'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='12'>
                    <FormGroup>
                      <Label for='miscellaneous'>Miscellaneous</Label>
                      <Input
                        type='textarea'
                        name='miscellaneous'
                        value={client.miscellaneous}
                        placeholder='Anything else you want to take notes on!'
                        onChange={(e) => changeClientDetail({ e })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <Collapse className='mt-3' title={'Filters (Preferences)'}>
                <Row>
                  <Col md='6'>
                    <Row>
                      <Col md='6'>
                        <FormGroup>
                          <Label for='availFrom'>Earliest Move In</Label>
                          <DatePicker
                            className='form-control'
                            selected={client.availFrom ? client.availFrom : null}
                            onChange={(date) =>
                              changeClientDetail({
                                e: {
                                  target: {
                                    name: 'availFrom',
                                    value: date,
                                  },
                                },
                              })
                            }
                            disabledKeyboardNavigation
                            placeholderText='Earliest Move In'
                            dateFormat='MMMM d, yyyy'
                            name='availFrom'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </FormGroup>
                        {moveInError && (
                          <p className='input-error'>Provide both earliest and latest date.</p>
                        )}
                      </Col>
                      <Col md='6'>
                        <FormGroup>
                          <Label for='availTo'>Latest Move In</Label>
                          <DatePicker
                            className='form-control'
                            selected={client.availTo ? client.availTo : null}
                            onChange={(date) =>
                              changeClientDetail({
                                e: {
                                  target: {
                                    name: 'availTo',
                                    value: date,
                                  },
                                },
                              })
                            }
                            disabledKeyboardNavigation
                            placeholderText='Latest Move In'
                            dateFormat='MMMM d, yyyy'
                            name='availTo'
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </FormGroup>
                        {moveInError && (
                          <p className='input-error'>Provide both earliest and latest date.</p>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md='12'>
                        <Card className='mt-2 text-left rounded-small'>
                          <CardBody className='p-3'>
                            {/* <CardTitle>Card title</CardTitle> */}
                            <label htmlFor=''>Price</label>
                            <div className='px-2 py-2 mt-2 mb-1 w-100'>
                              <Nouislider
                                connect={true}
                                tooltips={true}
                                animate={true}
                                start={[
                                  client.minRent || 0,
                                  client.maxRent || 10000,
                                ]}
                                behaviour='tap'
                                range={{
                                  min: [0, 100],
                                  '5%': [100, 100],
                                  '50%': [2600, 200],
                                  '75%': [5000, 500],
                                  // '90%': [9500, 500],
                                  max: [10000],
                                }}
                                onChange={(e) => {
                                  changeClientDetail({
                                    e: {
                                      target: {
                                        name: 'minRent',
                                        value: e[0] < 100 ? '' : e[0],
                                      },
                                    },
                                  });
                                  changeClientDetail({
                                    e: {
                                      target: {
                                        name: 'maxRent',
                                        value: e[1] > 9500 ? '' : e[1],
                                      },
                                    },
                                  });
                                }}
                                format={{
                                  to: function (value) {
                                    return value === undefined
                                      ? ''
                                      : value.toFixed(0);
                                  },
                                  from: Number,
                                }}
                              />
                            </div>
                            <Row className='mt-2'>
                              <Col md='6'>
                                <InputGroup className='mb-3'>
                                  <InputGroupText>
                                    <Icon icon='dollar-sign' />
                                  </InputGroupText>
                                  <Input
                                    type='text'
                                    placeholder='Min Price'
                                    min='0'
                                    name='minRent'
                                    value={
                                      client.minRent
                                        ? numberFormatter.format(client.minRent)
                                        : ''
                                    }
                                    onChange={(e) => {
                                      var minPriceValue = Number(
                                        e.target.value?.replace(/,/g, '')
                                      );
                                      if (
                                        minPriceValue >= 0 ||
                                        minPriceValue === ''
                                      ) {
                                        changeClientDetail({
                                          e: {
                                            target: {
                                              value: minPriceValue,
                                              name: 'minRent',
                                            },
                                          },
                                        });
                                      }
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                              <Col md='6'>
                                <InputGroup>
                                  <InputGroupText>
                                    <Icon icon='dollar-sign' />
                                  </InputGroupText>
                                  <Input
                                    type='text'
                                    min='0'
                                    placeholder='Max Price'
                                    name='maxRent'
                                    value={
                                      client.maxRent
                                        ? numberFormatter.format(client.maxRent)
                                        : ''
                                    }
                                    onChange={(e) => {
                                      var maxPriceValue = Number(
                                        e.target.value?.replace(/,/g, '')
                                      );
                                      if (
                                        maxPriceValue >= 0 ||
                                        maxPriceValue === ''
                                      ) {
                                        changeClientDetail({
                                          e: {
                                            target: {
                                              value: maxPriceValue,
                                              name: 'maxRent',
                                            },
                                          },
                                        });
                                      }
                                    }}
                                  />
                                </InputGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md='6'>
                    <Row>
                      <Col md='4'>
                        <FormGroup tag="fieldset">
                          <legend>
                            Bedrooms
                          </legend>
                          {Object.keys(unitTypes).map((typecode, index) => (
                            <FormGroup check key={index}>
                              <Input
                                name="bed"
                                type="checkbox"
                                className='custom-checkbox mt-0'
                                value={typecode}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    changeClientDetail({
                                      e: [...(client.bed || []), typecode],
                                      key: 'bed',
                                    });
                                  } else {
                                    changeClientDetail({
                                      e: client.bed.filter(
                                        (a) => a !== typecode
                                      ),
                                      key: 'bed',
                                    });
                                  }
                                }}
                                checked={
                                  client.bed?.find((a) => a === typecode) ||
                                  false
                                }
                              />
                              {' '}
                              <Label check>
                                {unitTypes[typecode]}
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                      </Col>
                      <Col md='4'>
                        <FormGroup tag="fieldset">
                          <legend>
                            Bathrooms
                          </legend>
                          {bathroomOptions.map((bathSize, index) => (
                            <FormGroup check key={index}>
                              <Input
                                name="bath"
                                type="radio"
                                className=''
                                value={`{"value":"${bathSize}", "label":"${bathSize}"}`}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    changeClientDetail({
                                      e,
                                      key: 'bath',
                                    });
                                  }
                                }}
                                checked={
                                  client?.bath ===
                                  `{"value":"${bathSize}", "label":"${bathSize}"}`
                                }
                              />
                              {' '}
                              <Label check>
                                {bathSize}+ Bath
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                      </Col>
                      <Col md='4'>
                        <FormGroup tag="fieldset">
                          <legend>
                            Pets
                          </legend>
                          {petOptions.map((petOption, index) => (
                            <FormGroup check key={index}>
                              <Input
                                name="petType"
                                type="radio"
                                className=''
                                value={petOption}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    changeClientDetail({
                                      e,
                                      key: 'petType',
                                    });
                                  }
                                }}
                                checked={
                                  client?.petType === petOption || false
                                }
                              />
                              {' '}
                              <Label check>
                                {petOption}
                              </Label>
                            </FormGroup>
                          ))}
                        </FormGroup>
                        <FormGroup>
                          <Label for='NoOfParking'>No. of Parking</Label>
                          <InputGroup>
                            <InputGroupText>
                              <Icon icon='parking' />
                            </InputGroupText>
                            <Input
                              type='text'
                              defaultValue={client.noOfParking}
                              name='noOfParking'
                              onChange={(e) => changeClientDetail({ e })}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row  className='mt-3'>
                  <Col md='6'>
                    <h6 className='mr-1' style={{ display: 'inline-block' }}>
                      Square Feet -
                    </h6>
                    {client.sqFeet === undefined ||
                    client.sqFeet === '' ||
                    client.sqFeet === null ? (
                      <>Any</>
                    ) : (
                      <>
                        Larger than {numberFormatter.format(client.sqFeet)} sqft
                        {/* <sup>2</sup> */}
                      </>
                    )}
                    <div className='px-2 py-2 mt-2 w-100'>
                      <Nouislider
                        connect='lower'
                        tooltips={true}
                        // animate={true}
                        start={[client.sqFeet]}
                        behaviour='tap'
                        range={{
                          min: [0, 100],
                          '5%': [100, 100],
                          '15%': [400, 50],
                          '75%': [1300, 100],
                          '90%': [2000, 500],
                          max: [5000],
                        }}
                        onChange={(e) =>
                          changeClientDetail({
                            e: {
                              target: {
                                name: 'sqFeet',
                                value: e[0] < 100 ? '' : e[0],
                              },
                            },
                          })
                        }
                        format={{
                          to: function (value) {
                            return value === undefined ? '' : value.toFixed(0);
                          },
                          from: Number,
                        }}
                      />
                    </div>
                  </Col>
                  <Col md='6'>
                    <Label for='clientNotes'>Neighborhoods</Label>
                    <Select
                      name='Neighborhood'
                      isMulti
                      value={client?.neighborhoods}
                      //value={getFilter('neighborhoods')}
                      options={
                        typeof neighborhoodData !== 'undefined'
                          ? Object.values(neighborhoodData.neighborhoods).map(
                              (neighborhood) =>
                                JSON.parse(
                                  `{"value":"${neighborhood.id}", "label":"${neighborhood.name}"}`
                                )
                            )
                          : []
                      }
                      onChange={(e) =>
                        changeClientDetail({ e, key: 'neighborhoods' })
                      }
                      className='basic-multi-select w-100'
                      classNamePrefix='default-select'
                      placeholder='Neighborhood'
                      menuPlacement={'auto'}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md='6'>
                    <Row>
                      {/*<Col md='6'>
                        <Label>
                          Google Rating -{' '}
                          {client.googleRating === 0 ||
                          client.googleRating === undefined
                            ? ''
                            : client.googleRating}{' '}
                          {client.googleRating === 5
                            ? ''
                            : client.googleRating != 0 &&
                              client.googleRating !== undefined
                            ? 'and above'
                            : 'Any'}
                        </Label>
                        <div className='px-2 py-1 mt-2 mb-1 w-100'>
                          <Nouislider
                            connect='lower'
                            tooltips={true}
                            animate={true}
                            step={0.1}
                            start={[client.googleRating || 0]}
                            behaviour='tap'
                            range={{
                              min: 0,
                              max: 5,
                            }}
                            onChange={(e) => {
                              changeClientDetail({
                                e: {
                                  target: {
                                    name: 'googleRating',
                                    value: e[0] < 0 ? '' : e[0],
                                  },
                                },
                              });
                            }}
                            format={{
                              to: function (value) {
                                return value === undefined
                                  ? ''
                                  : value === 5.0
                                  ? value
                                  : value === 0.0
                                  ? value
                                  : value.toFixed(1);
                              },
                              from: Number,
                            }}
                          />
                        </div>
                      </Col>*/}
                      <Col md='12'>
                        <Label for='clientPolicies'>Policies</Label>
                        <Select
                          name='policies'
                          isMulti
                          value={client?.policies}
                          //value={getFilter('neighborhoods')}
                          options={
                            typeof policiesData !== 'undefined'
                              ? Object.values(policiesData.propertyPolicies).map(
                                  (policies) =>
                                    JSON.parse(
                                      `{"value":"${policies.name}", "label":"${policies.name}"}`
                                    )
                                )
                              : []
                          }
                          onChange={(e) =>
                            changeClientDetail({ e, key: 'policies' })
                          }
                          className='basic-multi-select w-100'
                          classNamePrefix='default-select'
                          placeholder='Policies'
                          menuPlacement={'auto'}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          menuPortalTarget={document.body}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md='6'>
                    <Label for='clientAmenities'>Amenities</Label>
                    <Select
                      name='amenities'
                      isMulti
                      value={client?.amenities}
                      //value={getFilter('neighborhoods')}
                      options={Object.values(defaultAmenities).map(
                        (amenities) =>
                          JSON.parse(
                            `{"value":"${amenities.name}", "label":"${amenities.name}"}`
                          )
                      )}
                      /* options={
                        typeof amenitiesData !== 'undefined'
                          ? Object.values(amenitiesData.propertyAddons).map(
                              (amenities) =>
                                JSON.parse(
                                  `{"value":"${amenities.name}", "label":"${amenities.name}"}`
                                )
                            )
                          : []
                      } */
                      onChange={(e) =>
                        changeClientDetail({ e, key: 'amenities' })
                      }
                      className='basic-multi-select w-100'
                      classNamePrefix='default-select'
                      placeholder='Amenities'
                      menuPlacement={'auto'}
                      styles={{
                        menuPortal: (base) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      menuPortalTarget={document.body}
                    />
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md='12'>
                    <FormGroup>
                      <Label for='clientNotes'>Guest Card Notes (visible to leasing offices within sent Guest Cards)</Label>
                      <InputGroup>
                        <Input
                          type='textarea'
                          name='notes'
                          innerRef={register({})}
                          value={client.notes}
                          onChange={(e) => changeClientDetail({ e })}
                          autoComplete='off'
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md='6'>
                    <FormGroup>
                      <Label for='budget'>Next Follow Up</Label>
                      <DatePicker
                        className='form-control'
                        selected={client.nextFollow ? client.nextFollow : null}
                        onChange={(date) =>
                          changeClientDetail({
                            e: {
                              target: {
                                name: 'nextFollow',
                                value: date,
                              },
                            },
                          })
                        }
                        disabledKeyboardNavigation
                        placeholderText='Next Follow Up'
                        minDate={new Date()}
                        dateFormat='MMMM d, yyyy'
                        name='nextFollowUp'
												showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </FormGroup>
                  </Col>
                  <Col md='6'>
                    <FormGroup>
                      <Label for='budget'>Lease End Date</Label>
                      <DatePicker
                        className='form-control'
                        selected={client.leaseEnd ? client.leaseEnd : null}
                        onChange={(date) =>
                          changeClientDetail({
                            e: {
                              target: { name: 'leaseEnd', value: date },
                            },
                          })
                        }
                        disabledKeyboardNavigation
                        placeholderText='Lease End Date'
                        minDate={new Date()}
                        dateFormat='MMMM d, yyyy'
                        name='leaseEndDate'
												showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
              <Col lg='12' className='mt-md-3'>
                <Button
                  color='primary'
                  className='button-dashboard my-3 mr-3'
                  disabled={isLoading}
                >
                  Save and Return to Clients Page
                </Button>
                <Button
                  className='button-dashboard'
                  onClick={() => props.sidebarToggle()}
                >
                  Cancel
                </Button>
              </Col>
            </Form>
          </Col>
        </Row>
				{ (props.new !== 'new' && client) &&
					<ClientShow
						client={{...client, city: { id: client.city?.value, name: client.city?.label}}}
						searchMatchingProperties={props.searchMatchingProperties}
					/>
				}
        { (props.new !== 'new' && client) &&
          <Row className='mt-3'>
            <Col md='12'>
              <Form onSubmit={handleSubmit(onSubmit)} id='dealClient'>
                <Collapse title={'Deals'} open={true}>
                  <Row>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='city'>Select Market</Label>
                        <InputGroup>
                          <Select
                            name='dealCity'
                            options={
                              typeof citiesData !== 'undefined'
                                ? Object.values(citiesData.cities).map((city) =>
                                    JSON.parse(
                                      `{"value":${city.id}, "label":"${city.name}"}`
                                    )
                                  )
                                : []
                            }
                            value={client.dealCity}
                            onChange={(e) => {
                              changeClientDetail({ e, key: 'dealCity' });
                              handleOnChangeDealCity(e);
                            }}
                            className='w-100'
                            classNamePrefix='default-select'
                            id='dealCity'
                            isSearchable={true}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='dealProperty'>Property</Label>
                        <InputGroup>
                          <Select
                            name='dealProperty'
                            closeMenuOnSelect={true}
                            options={dealCityProperties?.cityProperties?.map(
                              (property) =>
                                JSON.parse(
                                  `{"value":"${
                                    property.id
                                  }", "label":"${property.name.replace(
                                    /[\n\r\t]/g,
                                    ' '
                                  )}"}`
                                )
                            )}
                            isMulti={false}
                            placeholder='Select Property'
                            onChange={(e) => {
                              changeClientDetail({ e, key: 'dealProperty' });
                            }}
                            value={client.dealProperty}
                            className='multi-select-client w-100'
                            classNamePrefix='default-select'
                            innerRef={register({
                              required: false,
                            })}
                          />
                        </InputGroup>
                        {_.get('dealProperty.type', errors) === 'required' && (
                          <p className='input-error'>* Property is required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='2'>
                      <FormGroup>
                        <Label for='unitNumber'>Unit Number</Label>
                        <InputGroup>
                          <Input
                            type='text'
                            name='unitNumber'
                            placeholder='Unit Number'
                            value={client.unitNumber}
                            onChange={(e) => changeClientDetail({ e })}
                            innerRef={register({
                              required: false,
                            })}
                          />
                        </InputGroup>
                        {_.get('unitNumber.type', errors) === 'required' && (
                          <p className='input-error'>* Unit number is required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='2'>
                      <FormGroup>
                        <Label for='mls'>Invoice ID</Label>
                        <InputGroup>
                          <Input
                            type='text'
                            name='mls'
                            placeholder='Invoice ID'
                            value={client.mls}
                            onChange={(e) => changeClientDetail({ e })}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='leaseSigned'>Lease Signed</Label>
                        <DatePicker
                          className='form-control'
                          selected={client.leaseSigned ? client.leaseSigned : null}
                          onChange={(date) =>
                            changeClientDetail({
                              e: {
                                target: { name: 'leaseSigned', value: date },
                              },
                            })
                          }
                          disabledKeyboardNavigation
                          placeholderText='Lease Signed'
                          dateFormat='MMMM d, yyyy'
                          name='leaseSigned'
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='leaseStartDate'>Lease Start Date</Label>
                        <DatePicker
                          className='form-control'
                          selected={client.leaseStartDate ? client.leaseStartDate : null}
                          onChange={(date) =>
                            changeClientDetail({
                              e: {
                                target: { name: 'leaseStartDate', value: date },
                              },
                            })
                          }
                          disabledKeyboardNavigation
                          placeholderText='Lease Start Date'
                          dateFormat='MMMM d, yyyy'
                          name='leaseStartDate'
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='leaseTerm'>Lease Term (months)</Label>
                        <InputGroup>
                          <Input
                            type='number'
                            name='leaseTerm'
                            placeholder='Lease Term (months)'
                            value={client.leaseTerm}
                            onChange={(e) => changeClientDetail({ e })}
                            innerRef={register({
                              required: false,
                            })}
                          />
                        </InputGroup>
                        {_.get('leaseTerm.type', errors) === 'required' && (
                          <p className='input-error'>* Lease term is required.</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='dealLeaseEndDate'>Lease End Date</Label>
                        <DatePicker
                          className='form-control'
                          selected={client.dealLeaseEndDate ? client.dealLeaseEndDate : null}
                          onChange={(date) =>
                            changeClientDetail({
                              e: {
                                target: { name: 'dealLeaseEndDate', value: date },
                              },
                            })
                          }
                          disabledKeyboardNavigation
                          placeholderText='Lease End Date'
                          dateFormat='MMMM d, yyyy'
                          name='dealLeaseEndDate'
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='grossRent'>Gross Rent</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='dollar-sign' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='grossRent'
                            placeholder='Gross Rent'
                            value={
                              client.grossRent
                                ? numberFormatter.format(client.grossRent)
                                : ''
                            }
                            onChange={(e) => {
                              var grossRentValue = Number(
                                e.target.value?.replace(/,/g, '')
                              );
                              if (
                                grossRentValue >= 0 ||
                                grossRentValue === ''
                              ) {
                                changeClientDetail({
                                  e: {
                                    target: {
                                      value: grossRentValue,
                                      name: 'grossRent',
                                    },
                                  },
                                });
                              }
                            }}
                            innerRef={register({
                              required: false,
                            })}
                          />
                        </InputGroup>
                        {_.get('grossRent.type', errors) === 'required' && (
                          <p className='input-error'>* Gross rent is required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='buildingCommission'>Building Commission</Label>
                        <Row>
                          <Col xs="8" className='pr-1'>
                            <Input
                              type='text'
                              name='buildingCommission'
                              placeholder='Building Commission'
                              value={client.buildingCommission}
                              onChange={(e) => changeClientDetail({ e })}
                            />
                          </Col>
                          <Col xs="4" className='pl-1'>
                            <Select
                              closeMenuOnSelect={true}
                              options={
                                [{
                                  "value": true,
                                  "label": "%"
                                },
                                {
                                  "value": false,
                                  "label": "$"
                                }]
                              }
                              onChange={(e) => {
                                changeClientDetail({ e, key: 'isPercentBuildingCommission' });
                              }}
                              value={client.isPercentBuildingCommission ? {"value": true, "label": "%"} : {"value": false, "label": "$"}}
                              isMulti={false}
                              placeholder='Select Building Commission Type'
                              className='multi-select-client w-100'
                              classNamePrefix='default-select'
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='concession'>Concessions</Label>
                        <InputGroup>
                          <Input
                            type='text'
                            name='concession'
                            placeholder='Concessions'
                            value={client.concession}
                            onChange={(e) => changeClientDetail({ e })}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='netRent'>Net Rent</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='dollar-sign' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='netRent'
                            placeholder='Net Rent'
                            value={
                              client.netRent
                                ? numberFormatter.format(client.netRent)
                                : ''
                            }
                            onChange={(e) => {
                              var netRentValue = Number(
                                e.target.value?.replace(/,/g, '')
                              );
                              if (
                                netRentValue >= 0 ||
                                netRentValue === ''
                              ) {
                                changeClientDetail({
                                  e: {
                                    target: {
                                      value: netRentValue,
                                      name: 'netRent',
                                    },
                                  },
                                });
                              }
                            }}
                            innerRef={register({
                              required: false,
                            })}
                          />
                        </InputGroup>
                        {_.get('netRent.type', errors) === 'required' && (
                          <p className='input-error'>* Net rent is required.</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md='4'>
                      <FormGroup>
                        <Label for='invoiceTotal'>Invoice Total</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='dollar-sign' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='invoiceTotal'
                            placeholder='Invoice Total'
                            value={
                              client.invoiceTotal
                                ? numberFormatter.format(client.invoiceTotal)
                                : ''
                            }
                            onChange={(e) => {
                              var invoiceTotalValue = Number(
                                e.target.value?.replace(/,/g, '')
                              );
                              if (
                                invoiceTotalValue >= 0 ||
                                invoiceTotalValue === ''
                              ) {
                                changeClientDetail({
                                  e: {
                                    target: {
                                      value: invoiceTotalValue,
                                      name: 'invoiceTotal',
                                    },
                                  },
                                });
                              }
                            }}
                            innerRef={register({
                              required: false,
                            })}
                          />
                        </InputGroup>
                        {_.get('invoiceTotal.type', errors) === 'required' && (
                          <p className='input-error'>* Invoice total is required.</p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='12'>
                      <Button
                        color='primary'
                        className='button-dashboard mr-3'
                        disabled={isLoading}
                      >
                        Update
                      </Button>
                      <Button
                        className='button-dashboard'
                        onClick={() => props.sidebarToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Collapse>
              </Form>
            </Col>
          </Row>
        }
      </div>
    </>
  );
}

function RoommateInfo({ roommate, removeRoommate }) {
	return (
		<Col md="6">
			<div
				className='info-panel py-2 px-3 mb-2'
				key={roommate.index}
			>
				<Row>
					<Col md='11'>
						<div >
							<h6><Badge color="primary" className='mt-2'>{roommate.firstName} {roommate.lastName}</Badge> <Badge>{roommate.email}</Badge> {roommate.phone ? <Badge>{roommate.phone}</Badge> : null}</h6>
						</div>
					</Col>
					<Col md='1'>
						<>
							<div
								className='text-right mt-2'
								style={{ cursor: 'pointer' }}
								onClick={() => {
									removeRoommate(roommate.index)
								}}
							>
								<Icon className='text-right ml-1' icon='trash' color="red" />
							</div>
						</>
					</Col>
				</Row>
			</div>
		</Col>
	)
}
