import React, { useState, useEffect } from 'react';
import { Icon } from '@assets';
import {
  Row,
  Col,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Label,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
} from 'reactstrap';
import { SAVE_CLIENT_RATING_NOTES } from '@api';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_REPORT } from '../../api/queries';
const ratingIcons = {
  dislike: 'thumbs-down',
	no_rating: 'ellipsis-v',
  // no_rating: ['far', 'heart'],
  like: 'thumbs-up',
  love: 'heart',
};

const Rating = ({ ratingName, rating, setRating, setIsSocialBtnClick = null }) => {
  return (
    <span
      onClick={() =>
				{
					if (setIsSocialBtnClick) setIsSocialBtnClick(true);
					setRating(rating === ratingName ? 'no_rating' : ratingName);
				}
      }
    >
			{
				setIsSocialBtnClick ? 
				<Icon
					icon={ratingIcons[ratingName]}
					style={{ color: rating === ratingName && (ratingName === "love" ? 'var(--theme-red)' : ratingName === "like" || "dislike" ? 'var(--theme-yellow)' : null )}}
					className='text-right ml-3'
					size='lg'
				/> :
				<Icon
					icon={ratingIcons[ratingName]}
					style={{ color: rating == ratingName && 'var(--theme-blue)' }}
					className='text-right ml-3'
					size='lg'
				/>
			}
    </span>
  );
};

export default function ClientRating({
  reportId,
  reportCode,
  propertyId,
  floorPlanId,
  typeDetailId,
  rating: propRating,
  notes: propNotes,
  isReportMap,
	isSocialMediaType = false
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(propRating || 'no_rating');
  const [notes, setNotes] = useState(propNotes);
  const [isSocialBtnClick, setIsSocialBtnClick] = useState(false);
  const [openNote, setOpenNote] = useState(false);

  const [saveClientRatingNotes, { data, loading, error }] = useMutation(
    SAVE_CLIENT_RATING_NOTES,
    {
      onCompleted: (data) => {
        if (
          data.reportSaveClientRatingNotes.errors &&
          data.reportSaveClientRatingNotes.errors.length > 0
        ) {
          data.reportSaveClientRatingNotes.errors.map((err) =>
            toast(err, { type: 'error' })
          );
        } else {
          console.log('ON COMPLETED ELSE');
        }
      },
      update: (cache, data) => {
        if (!data.data.reportSaveClientRatingNotes.errors) {
          const savedReportProperty =
            data.data.reportSaveClientRatingNotes.reportProperty;
          const reportData = cache.readQuery({
            query: GET_REPORT,
            variables: { code: reportCode },
          });
					if (reportData){
						let updatedReport = {
							report: {
								...reportData.report,
								reportProperties: [
									...reportData.report.reportProperties.filter(
										(rp) =>
											!(
												rp.propertyId === savedReportProperty.propertyId &&
												rp.floorPlanId === savedReportProperty.floorPlanId &&
												rp.typeDetailId === savedReportProperty.typeDetailId
											)
									),
									savedReportProperty,
								],
							},
						};
						console.log(updatedReport, 'UPDATE CACHE');
						cache.writeQuery({
							query: GET_REPORT,
							variables: { code: reportCode },
							data: updatedReport,
						});
					}
        }
      },

      // update: (cache, data) => {
      //   // if (!createError && !updateError && !deleteError) {
      //   // console.log(data.data.unitMultiCreate, '---- new units');
      //   const { properties } = cache.readQuery({ query: PROPERTIES });
      //   const propertyId = showUnitsManager.property.id;
      //   // console.log(currentPropertyUnitsForCacheUpdate(), 'currentPropertyUnitsForCacheUpdate');
      //   const mergedUnits = [
      //     ...currentPropertyUnitsForCacheUpdate(),
      //     ...data.data.unitMultiCreate.units,
      //   ];
      //   // console.log(mergedUnits, '---- NEW UNITS');
      //   const updatedProperties = properties.edges.map((property) =>
      //     property.node.id === propertyId
      //       ? {
      //           ...property,
      //           node: { ...property.node, typeDetails: mergedUnits },
      //         }
      //       : property
      //   );
      //   const updatedPropertiesConnection = {
      //     ...properties,
      //     edges: updatedProperties,
      //   };
      //   // console.log(updatedPropertiesConnection, '----- UPDATED PROPERTIES');
      //   cache.writeQuery({
      //     query: PROPERTIES,
      //     data: { properties: updatedPropertiesConnection },
      //   });
      //   // }
      // },
      onError: (e) => {
        console.log(e);
        toast(e.message, { type: 'error' });
      },
    }
  );

  useEffect(() => {
    if ((isReportMap && isOpen) || isSocialBtnClick) {
      saveClientRatingNotes({
        variables: {
          reportId: Number(reportId),
          propertyId: Number(propertyId),
          floorPlanId: floorPlanId ? Number(floorPlanId) : null,
          typeDetailId: typeDetailId ? Number(typeDetailId) : null,
          clientRating: rating,
          clientNotes: notes,
        },
      });
    } 

		if (isSocialBtnClick) setIsSocialBtnClick(false); // For social media type buttons (on sent report)

  }, [rating]);

  const handleClientRating = () => {
    if (isOpen || isSocialMediaType) {
			console.log("Inside the onClick={handleClientRating} ")
      saveClientRatingNotes({
        variables: {
          reportId: Number(reportId),
          propertyId: Number(propertyId),
          floorPlanId: floorPlanId ? Number(floorPlanId) : null,
          typeDetailId: typeDetailId ? Number(typeDetailId) : null,
          clientRating: rating,
          clientNotes: notes,
        },
      });
			if (!isSocialMediaType) setIsOpen(!isOpen);
    }
  };
	
  const toggle = () => {
    setIsOpen(!isOpen);
		if (isOpen) {
			setRating(propRating || 'no_rating');
			setNotes(propNotes);
		}
  };

	useEffect(() => {
		if (!openNote) {
			setNotes(propNotes);
		}
	}, [openNote]);

  return (
    <>
			{
				!isSocialMediaType ?
				<div className='client-rating-notes-icon'>
					<Button
						style={{
							backgroundColor: 'white',
							border: 'none',
							color: 'black',
							padding: '0px',
							width: '100%',
							height: '100%',
						}}
						id={`PopoverLegacy${propertyId}${floorPlanId}${typeDetailId}`}
						type='button'
						onClick={(e) => e.stopPropagation()}
					>
						<Icon icon={ratingIcons[rating]} />
					</Button>
					<Popover
						isOpen={isOpen}
						toggle={toggle}
						placement='bottom'
						target={`PopoverLegacy${propertyId}${floorPlanId}${typeDetailId}`}
						trigger='legacy'
						onClick={(e) => e.stopPropagation()}
						className='theme-popover'
						style={{ position: 'relative' }}
					>
						<PopoverHeader>Review</PopoverHeader>
						<PopoverBody>
							<>
								<Row>
									<Rating rating={rating} setRating={setRating} ratingName='love'  />
									<Rating rating={rating} setRating={setRating} ratingName='like' />
									<Rating
										rating={rating}
										setRating={setRating}
										ratingName='dislike'
									/>
								</Row>
								<Row className='mt-3'>
									<Col md='12'>
										<FormGroup>
											<Label for='clientNotes'>Notes</Label>
											<InputGroup>
												<InputGroupText>
													<Icon icon='clipboard' />
												</InputGroupText>
												<Input
													type='textarea'
													name='notes'
													rows={7}
													value={notes}
													onChange={(e) => setNotes(e.target.value)}
													autoComplete='off'
												/>
											</InputGroup>
										</FormGroup>
										<Button onClick={handleClientRating} className='sm'>
											Submit
										</Button>
									</Col>
								</Row>
							</>
						</PopoverBody>
					</Popover>
				</div> : 
				<div>
					<Rating rating={rating} setRating={setRating} ratingName='love' setIsSocialBtnClick={setIsSocialBtnClick} />
					<Rating rating={rating} setRating={setRating} ratingName='like' setIsSocialBtnClick={setIsSocialBtnClick} />
					<Rating
						rating={rating}
						setRating={setRating}
						ratingName='dislike'
						setIsSocialBtnClick={setIsSocialBtnClick}
					/>
					
					<Icon 
						icon='comment' 
						className='text-right ml-3'
						size='lg'
						onClick={() => setOpenNote(!openNote)}
						id={`PopoverLegacy${propertyId}${floorPlanId}${typeDetailId}`}
						style={{ color: notes?.length > 1 ? 'var(--theme-blue)' : 'black' }}
					/>
					{
						<Popover
							isOpen={openNote}
							toggle={() => setOpenNote(!openNote)}
							target={`PopoverLegacy${propertyId}${floorPlanId}${typeDetailId}`}
							trigger='legacy'
							onClick={(e) => e.stopPropagation()}
							className='theme-popover'
						>
							<PopoverHeader align='center'>
								<strong> Notes </strong>
							</PopoverHeader>
							<PopoverBody>
								<Input
									type='textarea'
									name='notes'
									rows={7}
									value={notes}
									onChange={(e) => setNotes(e.target.value)}
									autoComplete='off'
								/>
								<Button onClick={handleClientRating} className='sm mt-2'>
									Submit
								</Button>
							</PopoverBody>
						</Popover>
					}
				</div>
			}

    </>
  );
}
