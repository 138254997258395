import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  Container,
} from 'reactstrap';
import Select, { components } from 'react-select';
import DatePicker from 'react-datepicker';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { CITIES, CREATE_TOUR_REQUEST, CLIENTS, CITY_PROPERTIES, FIND_TOUR_REQUEST, UPDATE_TOUR_REQUEST, NOTIFY_PROPERTY_MISSING_EMAILS } from '@api';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { format } from 'date-fns-tz'

const appointmentTypesValue = [
  { value: 'Appointment Type', label: 'Appointment Type', isDisabled: 'true' },
  { value: 'inperson', label: 'In-Person with Broker' },
  { value: 'without_broker', label: 'Send without Broker' },
  { value: 'virtual', label: 'Virtual Tour' },
  { value: 'self_guided', label: 'Self-Guided' },
];

const guestCardStatuses = [
  { value: 'sent_not_confirmed', label: 'Sent - Not Confirmed' },
  { value: 'resent_not_confirmed', label: 'Resent - Not Confirmed' },
  { value: 'confirmed', label: 'Confirmed' },
];

export default function GuestCard(props) {
  const [communities, setCommunities] = useState();
  const [appointmentType, setAppointmentType] = useState();
  const [progress, setProgress] = useState(false);
  const [communitieyError, setCommunitieyError] = useState(false);
  const [selectedCity, setSelectedCity] = useState({ label: '', value: '' });
  const [selectedStatus, setSelectedStatus] = useState(guestCardStatuses[0]);
  const [tourDate, setTourDate] = useState(new Date());
  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [selectedClient, setSelectedClient] = useState();
  const [clientError, setClientError] = useState();
  const [appointmentTypeError, setAppointmentTypeError] = useState(false);
  const { data: clientsList, loading: loadingClients } = useQuery(CLIENTS, {
		skip: props.editGuestCard ? true : false
	});
	const [ findTourRequest, { data: tourRequestData }] = useLazyQuery(FIND_TOUR_REQUEST, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			props.sidebarToggle();
			var tr = data.findTourRequest;
			setSelectedClient({label: tr.client.name, value: tr.client.id});
			setSelectedCity({label: tr.city.name, value: tr.city.id});
			setAppointmentType(appointmentTypesValue.filter(a => a.value === tr.appointmentType)[0]);
			setSelectedStatus(guestCardStatuses.filter(s => s.value === tr.status)[0]);
			setTourDate(new Date(new Date(tr.tourAt).toLocaleString("en-US", {timeZone: tr.city.jsTimeZone})));
			setCommunities({ label: tr.property.name, value: tr.property.id })
		}
	});

	function resetVariables() {
		setCommunities();
		setAppointmentType();
		setProgress(false);
		setCommunitieyError(false);
		setTourDate(new Date());
		setSelectedClient();
		setAppointmentTypeError(false);
		setSelectedCity({ label: '', value: '' });
		setSelectedStatus(guestCardStatuses[0])
	}

  const { data: citiesData } = useQuery(CITIES, {
    onCompleted: (data) => {
      if (data.cities.length > 0) {
        setSelectedCity({
          label: data.cities[0].name,
          value: data.cities[0].id,
        });
      }
    },
		skip: props.editGuestCard ? true : false,
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  if (citiesData && selectedCity.label === '') {
    setSelectedCity({
      label: citiesData.cities[0].name,
      value: citiesData.cities[0].id,
    });
  }

  const [
    guestCommunitiesQuery,
    {
      data: cityProperties
    },
  ] = useLazyQuery(CITY_PROPERTIES, {
    variables: {
      cityId: selectedCity.value ? parseInt(selectedCity.value) : '',
    },
  });
  const guestCommunities = () => {
    guestCommunitiesQuery({
      variables: {
        cityId: selectedCity.value ? parseInt(selectedCity.value) : null,
      },
    });
  };

  useEffect(() => {
    if (selectedCity.value !== '') {
      setCommunities();
      guestCommunities();
    }
  }, [citiesData, selectedCity]);

	useEffect(() => {
		if (props.editGuestCard){
			findTourRequest({
				variables: {
					id: Number(props.editGuestCard)
				}
			});
		} else resetVariables();
	}, [props.editGuestCard]);

  const handleOnChangeCity = (selectedOption) => {
    setSelectedCity(selectedOption);
  };
  const handleOnCommunities = (selectedOption) => {
    setCommunities(selectedOption);
  };
  useEffect(() => {
    if (communities != undefined) {
      setCommunitieyError(false);
    }
  }, [communities]);

  useEffect(() => {
    if (selectedClient != null) {
      setClientError(false);
    }
  }, [selectedClient]);

  useEffect(() => {
    if (appointmentType?.value === null) {
      setAppointmentTypeError(true);
    } else {
      setAppointmentTypeError(false);
    }
  }, [appointmentType]);

	useEffect(() => {
		if (props.sidebarState === false){
			// resetVariables();
			props.setEditGuestCard(false);
		}
	}, [props.sidebarState])

  const [createTourRequest] = useMutation(
    CREATE_TOUR_REQUEST,
    {
      onCompleted: (data) => {
        setProgress(false);
        if (
          data.createTourRequest.errors &&
          data.createTourRequest.errors.length > 0
        ) {
          data.createTourRequest.errors.map((err) =>
            toast(err, { type: 'error' })
          );
        } else {
          toast(data.createTourRequest.message, { type: 'success' });
          reset();
          //Reset bathroom and bedroom
          // setMoveInDate(new Date());
          setCommunities(null);
          props.sidebarToggle();
          props.shouldRefetch(true);
					// resetVariables();
        }
      },
      onError: (e) => {
        console.log(e, 'updated client ON ERROR');
      },
    }
  );

	const [updateTourRequest] = useMutation(UPDATE_TOUR_REQUEST, {
		onCompleted: (data) => {
			setProgress(false);
			if (
				data.updateTourRequest.errors &&
				data.updateTourRequest.errors.length > 0
			) {
				data.updateTourRequest.errors.map((err) =>
					toast(err, { type: 'error' })
				);
			} else {
				toast(data.updateTourRequest.message, { type: 'success' });
				reset();
				//Reset bathroom and bedroom
				// setMoveInDate(new Date());
				props.sidebarToggle();
				props.shouldRefetch(true);
				resetVariables();
			}
		},
		onError: (e) => {
			console.log(e, 'updated client ON ERROR');
		},
	})

  const onSubmit = (data, e) => {
    if (selectedClient === undefined) {
      setClientError(true);
      return false;
    }
    if (appointmentType?.value === undefined) {
      setAppointmentTypeError(true);
      return false;
    }
    if (!props.editGuestCard && !communities) {
      setCommunitieyError(true);
      return false;
    }

		if (!props.editGuestCard){
			let cityTimeZone = citiesData.cities.filter(c => parseInt(c.id) === parseInt(selectedCity.value))[0]?.jsTimeZone;
			let newDate = format(tourDate, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: cityTimeZone });

			if (cityTimeZone?.length > 0){
				setProgress(true);
				createTourRequest({
					variables: {
						propertyId: Number(communities.value),
						cityId: Number(selectedCity.value),
						clientId: Number(selectedClient.value),
						appointmentType: appointmentType?.value,
						tourAt: newDate,
						status: selectedStatus?.value || guestCardStatuses[0].value,
					},
				});
			} else toast("Oops, something went wrong", { type: 'error' });
		} else {
			updateTourRequest({
				variables: {
					id: Number(props.editGuestCard),
					tourAt: format(tourDate, 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: tourRequestData.findTourRequest.city.jsTimeZone}),
					appointmentType: appointmentType?.value,
					status: selectedStatus?.value || guestCardStatuses[0].value
				}
			})
		}
  };

  const [notifyPropertyMissingEmails] = useMutation(NOTIFY_PROPERTY_MISSING_EMAILS, {
    onCompleted: (data) => {
      if (data.notifyPropertyMissingEmails.message) {
        toast(data.notifyPropertyMissingEmails.message, { type: 'success' });
      }
      if (data.notifyPropertyMissingEmails.errors && data.notifyPropertyMissingEmails.errors.length > 0) {
        data.notifyPropertyMissingEmails.errors.map((err) => toast(err, { type: 'error' }));
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleNotifyPropertyMissingEmails = (propertyId) => {
    notifyPropertyMissingEmails({
      variables: {
        propertyId: parseInt(propertyId),
      }
    })
  };

  // Custom option component for select
  const CustomOption = props => {
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <span>{props.data.label}</span>
          {props.isDisabled && (
            <Button
              color="primary"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                handleNotifyPropertyMissingEmails(props.data.value);
              }}
            >
              No Email, Notify Team
            </Button>
          )}
        </div>
      </components.Option>
    );
  };

  return (
    <>
      <Container className='p-4'>
        <Row>
          <Col md='12'>
            <h4>Send Guest Card</h4>
          </Col>

          <Col md='12' className='mt-md-3 inputs-icons'>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col lg='6'>
                  <FormGroup>
                    <Label for='clientNotes'>Clients</Label>
                    { !props.editGuestCard && clientsList ? (
                      <InputGroup>
                        <Select
                          closeMenuOnSelect={true}
                          options={clientsList.clients.edges.map((client) =>
                            JSON.parse(
                              `{"value":"${client.node.id}", "label":"${client.node.name.replace(/['"]/g, '\\"')}"}`
                            )
                          )}
                          isMulti={false}
                          placeholder='Select Client'
                          onChange={(selectedClient) => {
                            setSelectedClient(selectedClient);
                          }}
                          value={selectedClient}
                          className='multi-select-client w-100'
                          classNamePrefix='default-select'
													isDisabled={props.editGuestCard ? true : false}
                        />
                      </InputGroup>
                    ) : <Input value={selectedClient?.label} disabled={true}/>
										}
                    {clientError ? (
                      <p className='input-error'>
                        * Client Selection required.
                      </p>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col lg='6'>
                  <FormGroup>
                    <Label for='appoinment_type'>Appointment Type</Label>
                    <InputGroup>
                      <Select
                        name='appoinment_type'
                        isMulti={false}
                        value={appointmentType}
                        options={appointmentTypesValue}
                        onChange={(e) => setAppointmentType(e)}
                        className='multi-select-client w-100'
                        classNamePrefix='default-select'
                        placeholder='Select Appointment Type'
                      />
                      {appointmentTypeError && (
                        <p className='input-error'>
                          * Appointment Type required.
                        </p>
                      )}
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg='6'>
                  <FormGroup>
                    <Label for='clientNotes'>Market</Label>
                    <InputGroup>
											{ !props.editGuestCard ?
												<Select
													name='cityId'
													options={
														typeof citiesData !== 'undefined'
															? Object.values(citiesData.cities).map((city) =>
																	JSON.parse(
																		`{"value":${city.id}, "label":"${city.name}"}`
																	)
																)
															: []
													}
													value={selectedCity.label ? selectedCity : []}
													onChange={handleOnChangeCity}
													classNamePrefix='default-select'
													id='selectedCity'
													isSearchable={true}
													className='w-100'
												/> : 
												<Input value={selectedCity.label} className='multi-select-client w-100' disabled={true}/>
											}
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col lg='6'>
                  <FormGroup>
                    <Label for='clientNotes'>Community</Label>
                    <InputGroup>
											{ !props.editGuestCard ?
												<Select
													closeMenuOnSelect={true}
													options={cityProperties?.cityProperties?.map(
														(property) =>
															JSON.parse(
																`{"value":"${
																	property.id
																}", "label":"${property.name.replace(
																	/[\n\r\t]/g,
																	' '
																)}", "isDisabled":${!property.email}}`
															)
													)}
													isMulti={false}
													placeholder='Select Communities'
													onChange={(communities) =>
														handleOnCommunities(communities)
													}
													value={communities}
													className='multi-select-client w-100'
													classNamePrefix='default-select'
                          components={{ Option: CustomOption }}
												/> :
												<Input value={communities?.label} disabled={true} className='multi-select-client w-100' />
											}	
                    </InputGroup>
                    {!props.editGuestCard && communitieyError ? (
                      <p className='input-error'>* Community required.</p>
                    ) : null}
                  </FormGroup>
                </Col>
								<Col lg='3'>
                  <FormGroup>
                    <Label for='clientPhone'>Tour Date</Label>

                    <DatePicker
                      /* popperContainer={({ children }) =>
                        createPortal(
                          children,
                          document.getElementById('date-popup')
                        )
                      } */
                      wrapperClassName='r-date-time'
                      className='form-control r-date-time'
											minDate={new Date()}
                      selected={tourDate}
                      onChange={(date) => setTourDate(date)}
											autoComplete='off'
                      //dateFormat='Pp'
                      locale='pt-BR'
                      dateFormat='MMMM d, yyyy'
                      //timeFormat='p'
                    />
                  </FormGroup>
                </Col>
                <Col lg='3'>
                  <FormGroup>
                    <Label for='clientPhone'>Tour Time</Label>
                    <DatePicker
                      wrapperClassName='r-date-time'
                      className='form-control r-date-time'
                      selected={tourDate}
                      onChange={(date) => setTourDate(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                  </FormGroup>
                </Col>
								{/* <Col lg='6'>
                  <FormGroup>
                    <Label for='status'>Status</Label>
                    <InputGroup>
                      <Select
                        name='status'
                        isMulti={false}
                        value={selectedStatus}
                        options={guestCardStatuses}
                        onChange={(e) => setSelectedStatus(e)}
                        className='multi-select-client w-100'
                        classNamePrefix='default-select'
                        placeholder='Select Appointment Type'
                      />
                    </InputGroup>
                  </FormGroup>
                </Col> */}
              </Row>
              <Row>
                <Col md='12' className='mt-md-2'>
                  <Button
                    color='primary'
                    disabled={progress}
                    className='button-dashboard'
                  >
                    {progress ? 'Progress' : 'Send'}
                  </Button>
                  <Button
                    className='button-dashboard ml-2'
                    onClick={() => props.sidebarToggle()}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}
