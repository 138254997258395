import React from "react";
import { NavLink } from "reactstrap";
import { Icon } from "@assets";
import Slider from "../../../../components/Slider";

export default function MapPropertiesList({
  selectedBedSize,
  property: { imageUrls, name, address, sendEscort, escort, specials, neighborhood, bonus },
  handleClick,
  imageOnly,
  heart,
}) {
  const propertyImages = imageUrls?.map((image, index) => ({
    key: index,
    src: image,
    // width: '100%',
    altText: name,
  }));

  return (
    <>
      <div className="mb-4">
        <div className="property-card position-relative p-0">
        <div className="like-btn">{heart}</div>
          <div className="card-img position-relative">
            {imageOnly === true ? (
              <Slider items={propertyImages} className="rounded-medium" noAnimation={true}/>
            ) : (
              ""
            )}
            <div className='d-flex justify-content-between position-absolute property-report-image-overlay' style={{bottom: '0'}}>
              <div>
                <h6 className='mb-0'>{address}</h6>
              </div>
            </div>
          </div>
          <h4 className='mb-0'>{name}</h4>
          {neighborhood && (
            <p>{neighborhood.name}</p>
          )}
          <p>
            <span className="float-left">
              Send: {sendEscort ? sendEscort+'%' : '-'} | Escort: {escort ? escort+'%' : '-'} | Flat Fee: {bonus ? '$'+bonus : '-'}
            </span>
          </p>
          {specials && (
            <h6 style={{clear: 'both'}}>{specials}</h6>
          )}
        </div>
      </div>
    </>
  );
}
