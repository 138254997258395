import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
	Table
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import SidebarRight from '../../components/SidebarRight';
import { PLANS } from '@api';
import { Icon } from '@assets';
import { useQuery } from '@apollo/client';
import Spinner from '../Spinner';
import RTable from '../../components/RTable';
import PlanForm from './PlanForm';
export default function Plans(props) {
  const { data: plansData, loading: planLoading, error } = useQuery(PLANS);
  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);
  useEffect(() => {
    // check !id and operation is edit
    sidebarState ? !id && history.push(`/plan/new`) : history.push(`/plans`);
  }, [sidebarState, operation]);

	function getStripePlan(row, stripePlan){

		if (stripePlan?.tiers?.length > 0){
			var str = stripePlan.product.name +
				' (Starts At $' +
				stripePlan.tiers[0].unit_amount / 100 +
				' / ' +
				stripePlan.interval +
				')';
			var temp = -1;
			var planPrice = stripePlan.tiers.map(tier => {
					return (
						<>
							{temp == -1 ? "For The First " : "For The Next"} &nbsp;
							{temp + 1} - {temp = tier.up_to ? tier.up_to : <>&#8734;</>}&nbsp;&nbsp;
							${tier.unit_amount/100}
							<br/>
						</>
					)
				})
			return <>{str}<br/><br/>{planPrice}</>;
		}else {
			return row
			? row.name +
				' ($' +
				row.amount +
				' / ' +
				row.interval +
				')'
			: '-'
		}
	}

	function getPlanPrice(row){
		if (row.stripePlan?.tiers){
			return 'Starts At $' + row.stripePlan?.tiers[0].unit_amount/100
		}else return row.amount ? '$' + row.amount : '-'
	}

  const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Plan Name',
        accessor: 'name',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value, row }) => <div>{getPlanPrice(row.original)}</div>,
      },
      {
        Header: 'Stripe Plan',
        accessor: 'stripePlan',
        Cell: ({ value, row }) => (
          <div>
            {getStripePlan(row.original,value)}
          </div>
        ),
      },
      {
        Header: 'Interval',
        accessor: 'interval',
        Cell: ({ value, row }) => (
          <div>{value ? (value === 'month' ? 'Monthly' : 'Yearly') : '-'}</div>
        ),
      },
      {
        Header: 'Market',
        accessor: 'maxCities',
        Cell: ({ value, row }) => (
          <div>{value === -1 ? 'All' : value ? value : '-'}</div>
        ),
      },
      {
        Header: 'Is visible',
        accessor: 'isVisible',
        Cell: ({ value }) => <div>{value === true ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Can trial',
        accessor: 'canTrial',
        Cell: ({ value }) => <div>{value === true ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Action',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSidebarState(true);
                    history.push(`/plan/edit/${value}`);
                  }}
                >
                  Edit
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      <Row className='pt-3 pt-md-5'>
        <Col md='12'>
          <h2>Plans list</h2>
        </Col>
      </Row>
      <SidebarRight
        sidebarState={sidebarState}
        sidebarToggle={() => {
          setSidebarState(!sidebarState);
        }}
        sidebarWidth='550px'
        sidebarContent={
          <PlanForm
            operation={operation}
            sidebarToggle={() => {
              setSidebarState(!sidebarState);
            }}
            {...props}
          />
        }
      />
      {/* <Row className="mt-md-4 mt-4"> */}
      {planLoading && <Spinner />}
      <Row className='mt-md-2'>
        <Col md='12' className=''>
          {plansData?.plans.length > 0 ? (
            <RTable
              columns={columns}
              data={plansData?.plans}
              filterable
              /* addons={
              <Button
                color='green'
                className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
                onClick={() => {
                  setSidebarState(true);
                  history.push(`/users/edit/${value}`);
                }}
              >
                <Icon icon='plus' className='mr-2' />
                Add
              </Button>
            } */
            />
          ) : (
            <>
              {plansData?.plans.length === 0 ? (
                <h2 className='text-center mt-5'>Plan not found</h2>
              ) : null}
            </>
          )}
          {/* <Row>
			<Col md="6">
				<div className="month-plan pricing-plan-box">
					<h2>Monthly</h2>
					<span className="big-text">$59.<sup>99</sup></span>
					<Button color="primary">Choose Plan</Button>
				</div>
			</Col>

			<Col md="6">
				<div className="year-plan pricing-plan-box">
					<h2>Yearly</h2>
					<span className="big-text">$599</span>
					<Button color="green">Choose Plan</Button>
				</div>
			</Col>
		</Row> */}

          {/* <Table borderless responsive className="client-list text-left min-w-900">
			<thead>
				<tr>
					<th>Name</th>
					<th>Amount</th>
					<th>Stripe Plan Id</th>
				</tr>
			</thead>
			<tbody>
				{
					plans.map(({ id, amount, interval, name, stripe_plan_id }, i) => (
						<tr key={id} >
							<td>{name}</td>
							<td>${amount}/{interval}</td>
							<td>{stripe_plan_id}</td>
						</tr>
					))
				}
			</tbody>
		</Table> */}
        </Col>
      </Row>
      {/* </Row> */}
    </>
  );
}
