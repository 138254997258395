import React, { useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';

import { Icon } from '@assets';

export default function SidebarRight({
  sidebarState,
  sidebarToggle,
  sidebarContent,
  sidebarWidth,
  sidebarHeight,
  sidebarButton = true,
}) {
  let sidebarRightStyle = {};
  // sidebarHeight && (sidebarRightStyle = {...sidebarRightStyle, height: sidebarHeight + '!important' });
  // console.log({ ...sidebarRightStyle, height: sidebarHeight + ' !important' }, '==== sidebar style');

  sidebarHeight &&
    (sidebarRightStyle = { ...sidebarRightStyle, height: sidebarHeight });
  sidebarWidth &&
    (sidebarRightStyle =
      sidebarState === true
        ? {
            ...sidebarRightStyle,
            right: 0,
            width: sidebarWidth,
            maxWidth: '100%',
          }
        : {
            ...sidebarRightStyle,
            right: '-' + sidebarWidth,
            width: sidebarWidth,
            maxWidth: '100%',
          });
  // console.log(sidebarRightStyle, '==== sidebar style before ');

  useEffect(() => {
    if (sidebarState === false) {
      return;
    }
    function closeSidebar(event) {
      if (event.key === 'Escape') {
        // sidebarToggle();
        sidebarToggle();
      }
    }
    document.addEventListener('keydown', closeSidebar);
    return () => window.removeEventListener('keydown', closeSidebar);
  }, [sidebarState]);

  return (
    <>
      <div
        className={
          sidebarState === true ? 'sidebar-right md open' : 'sidebar-right md'
        }
        id='sidebar-right-id'
        style={sidebarRightStyle}
      >
        {/* <Row>
          <Col md="12">{sidebarContent}</Col>
        </Row> */}
        {sidebarContent}
      </div>

      <Button
        onClick={sidebarToggle}
        style={
          sidebarWidth &&
          (sidebarState === true ? { right: sidebarWidth } : { right: 0 })
        }
        className={
          sidebarState === true
            ? 'sidebar-right-button right-positioned'
            : sidebarButton === true
            ? 'sidebar-right-button'
            : 'sidebar-right-button hidden'
        }
      >
        <Icon
          icon='plus'
          size='lg'
          className={
            sidebarState === true ? 'color-white d-none' : 'color-white'
          }
        />
        <Icon
          icon='times'
          size='lg'
          className={
            sidebarState === true
              ? 'color-white d-inline-block'
              : 'color-white d-none'
          }
        />
      </Button>

      <div
        className={sidebarState === true ? 'backdrop' : ''}
        onClick={sidebarToggle}
      ></div>
    </>
  );
}
