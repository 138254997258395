import React from 'react';
import { 
	UncontrolledButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap';

export const DropDown = (props) => {
	const {
		options, 
		selectedOption=null, 
		setSelectedOption, 
		cssClasses="btn button-dashboard btn-blue"
	} = props;
	
	if (selectedOption){
		setSelectedOption(selectedOption);
	}else {
		setSelectedOption(options[0]);
	}
	return (
		<UncontrolledButtonDropdown>
			<DropdownToggle caret className={cssClasses}>
				{typeof selectedOption == 'object' ? selectedOption?.name : selectedOption}
			</DropdownToggle>
			<DropdownMenu>
				{options.map((option, i) => (
					<DropdownItem
						key={i}
						onClick={() => {
							setSelectedOption(option);
						}}
					>
						{typeof option == 'object' ? option?.name : option}
					</DropdownItem>
				))}
			</DropdownMenu>
		</UncontrolledButtonDropdown>
	)
}