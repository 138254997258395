import React, { useState, useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { baseStyle, activeStyle, acceptStyle, rejectStyle } from '@sparklib';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  Row,
  Button,
  Col,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Icon } from '@assets';
import { UPDATE_FLOORPLAN_IMG } from '@api';
import { useMutation } from '@apollo/client';

export default function FloorPlanPhotoEditor({
  fpPhotoEditor,
  setFpPhotoEditor,
	refeshFloorPlans,
	propertyDetails
}) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const [floorPlanPhoto, setFloorPlanPhoto] = useState(null);
  const [removeFpPhoto, setRemoveFpPhoto] = useState(false);
  const [deleteFpPhoto, setDeleteFpPhoto] = useState(false);

  const [updateFloorplanImage] = useMutation(UPDATE_FLOORPLAN_IMG, {
    context: { hasUpload: true },
		// refetchQueries: [PROPERTIES, PROPERTY_ADDONS, PROPERTY_POLICIES],
    onCompleted: (data) => {
			if (data.floorPlanImgUpdate.errors.length === 0){
				toast(data.floorPlanImgUpdate.message, { type: 'success' });
			} else toast(data.floorPlanImgUpdate.message, { type: 'error' });
			refeshFloorPlans();
    },
    onError: (e) => {
      toast(e, { type: 'error' });
    },
  });

  const resetPhotoEditor = () => {
    setFpPhotoEditor({ show: false, floorPlan: null });
    setFloorPlanPhoto(null);
    setRemoveFpPhoto(false);
		setDeleteFpPhoto(false);
  };

  const onSaveImages = (data, e) => {
		if ((floorPlanPhoto && deleteFpPhoto) || floorPlanPhoto){
			updateFloorplanImage({
				variables: {
					id: Number(fpPhotoEditor.floorPlan.id),
					floorPlanImg: floorPlanPhoto,
					removeImg: deleteFpPhoto
				}
			})
		} else if (deleteFpPhoto){
			updateFloorplanImage({
				variables: {
					id: Number(fpPhotoEditor.floorPlan.id),
					removeImg: deleteFpPhoto
				}
			})
		} else {
      toast("No Changes Found", { type: 'error' });
		}
    resetPhotoEditor();
  };

  const onError = (errors, e) => console.log(errors, e);

	function imagePreview(){
		if (floorPlanPhoto){
			return <img src={URL.createObjectURL(floorPlanPhoto)} className='floorplan-photo-editor' />
		} else if (fpPhotoEditor.floorPlan.plan2d && !deleteFpPhoto){
			return <img src={fpPhotoEditor.floorPlan.plan2d} className='floorplan-photo-editor'/>
		} else return null
	}

  return (
    <Modal
      className='modal-dialog-centered'
      key={fpPhotoEditor.floorPlan?.id}
      isOpen={fpPhotoEditor.show}
      toggle={(event) => {
        if (event.key === 'Escape') {
          resetPhotoEditor();
        }
      }}
    >
      <Form className='form' onSubmit={handleSubmit(onSaveImages, onError)}>
        <ModalHeader>{fpPhotoEditor.floorPlan?.name}</ModalHeader>
        <ModalBody>
          <Row className='photos'>
						<Col md='12' align='center'>
							{ imagePreview() }
						</Col>
						{ fpPhotoEditor.floorPlan?.plan2d && !deleteFpPhoto && !floorPlanPhoto && 
							<Icon
								size='1x'
								icon='times'
								className='remove-item'
								onClick={() => setDeleteFpPhoto(true)}
							/>
						}
          </Row>
          <Row>
            <Col md='12' className='mt-3'>
              <Dropzone
                name='propertyImagesDropZone'
                accept='image/jpeg, image/png'
                maxFiles={1}
                multiple={false}
                onDrop={(acceptedFile) => {
                  setFloorPlanPhoto(acceptedFile[0])
                }}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                  acceptedFiles,
                }) => {
                  const style = useMemo(
                    () => ({
                      ...baseStyle,
                      ...(isDragActive ? activeStyle : {}),
                      ...(isDragAccept ? acceptStyle : {}),
                      ...(isDragReject ? rejectStyle : {}),
                    }),
                    [isDragActive, isDragReject, isDragAccept]
                  );
                  return (
                    <span className='flex-grow'>
                      <div {...getRootProps({ style })}>
                        <input
                          ref={register({})}
                          name='images'
                          {...getInputProps()}
                        />
                        <div className='text-center w-100'>
                          Drag 'n drop the photos here,
                          <br />
                          or click to select the photos
                          <br />
                          <small>
                            <em>(only jpeg or png files supported)</em>
                          </small>
                        </div>
                      </div>
                    </span>
                  );
                }}
              </Dropzone>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color='blue'
            type='submit'
          >
            Save Images
          </Button>
          <Button onClick={resetPhotoEditor}>Cancel</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
