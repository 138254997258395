import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  CardBody,
  CardTitle,
  Card,
  Collapse as BSCollapse,
} from 'reactstrap';

export const Collapse = (props) => {
  const { title, className, open } = props;
  const [isOpen, setIsOpen] = useState(open === undefined ? true : open);
  return (
    <div className={className}>
      <Card>
        <CardBody className='px-4 py-2'>
          <CardTitle
            color='primary'
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className=' mb-0'
            style={{ cursor: 'pointer' }}
          >
            <Row>
              <Col md='6'>
                <h5 className='text-left mt-3'>{title}</h5>
              </Col>
              <Col md='6'>
                <h2 className='text-right'>{isOpen ? '-' : '+'}</h2>
              </Col>
            </Row>
          </CardTitle>
          <BSCollapse isOpen={isOpen} className='mt-3'>
            {props.children}
          </BSCollapse>
        </CardBody>
      </Card>
    </div>
  );
};
