import React, { useState, useEffect, useRef } from 'react';
import {
  GoogleMap,
  OverlayView,
  DrawingManager,
  LoadScriptNext,
} from '@react-google-maps/api';
import { Button, Spinner } from 'reactstrap';
import PlaceMarker from './PlaceMarker';
import Delayed from './Delayed';

const mapLibraries = ['drawing', 'places'];

const NewMap = ({
  latlongs,
  setLatlongs,
  hoverPropertyId,
  setSidebarState,
  setProperty,
  path,
  getFilter,
  selectedPropertyArray,
  toggleSelection,
  activeTab,
  setMapLoaded,
  mapLoaded,
  initialLatlongs,
  setSelectedPropertiesFromMap,
}) => {
  const [isViewed, setIsViewed] = useState([]);
  const [currentId, setCurrentId] = useState();
  const [currentHoverId, setCurrentHoverId] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [map, setMap] = useState(null);
  const [polygons, setPolygons] = useState([]);
  const [latLongInsidePolygon, setLatLongInsidePolygon] = useState([]);
  const polygonRef = useRef(null);
  // const [mapMarkerBatch, setMapMarkerBatch] = useState(0);
  //const mapRef = useRef(null);
  const [markersVisible, setMarkersVisible] = useState(true);

  const containerStyle = {
    //width: '400px',
    height: '100vh',
  };
  // const [bounds, setBounds] = useState<LatLngBounds | null>(null);
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyD-Vwu6LSsJscOPVKsSDvVse9NFlA3U7d4',
  // });

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);
  const noPoi = [
    {
      featureType: 'poi',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      elementType: 'labels.icon',
      stylers: [{ visibility: 'off' }],
    },
  ];

  const defaultMapOptions = {
    zoomControlOptions: {
      position: window.google?.maps.ControlPosition.RIGHT_CENTER,
    },
    fullscreenControl: false,
    styles: noPoi,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    preserveViewport: true,
    suppressMarkers: true,
  };

  useEffect(() => {
    setCurrentHoverId(hoverPropertyId);
  }, [hoverPropertyId]);

  function displayMarkers(LatLongs) {
    if (LatLongs.length > 0) {
      return LatLongs.map((item, i) => {
        return (
          <OverlayView
            key={i}
            position={{ lat: item?.lat, lng: item?.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            <PlaceMarker
              tooltip={item?.title}
              property={item}
              currentId={currentId}
              showTooltip={showTooltip}
              setShowTooltip={setShowTooltip}
              setCurrentId={setCurrentId}
              isViewed={isViewed}
              setIsViewed={setIsViewed}
              currentHoverId={currentHoverId}
              setSidebarState={setSidebarState}
              setProperty={setProperty}
              path={path}
              is_show={true}
              getFilter={getFilter}
              selectedPropertyArray={selectedPropertyArray}
              toggleSelection={toggleSelection}
            />
          </OverlayView>
        );
      });
    }
  }

  const fitBounds = (map) => {
    if (map && initialLatlongs && initialLatlongs.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      initialLatlongs.map((model) => {
        if (model.lat && model.long) {
          let pos = { lat: model.lat, lng: model.long };
          bounds.extend(pos);
        }
      });
      map.fitBounds(bounds);
      setMapLoaded(true);
    }
  };

  useEffect(() => {
    if (initialLatlongs.length > 0 && map && activeTab === 'Map') {
      fitBounds(map);
    } else {
      setMapLoaded(true);
    }
  }, [initialLatlongs, map, activeTab]);

  const loadHandler = (map) => {
    setMap(map);
  };

  const checkIfLatLngInPolygon = (lat, lng, polygon) => {
    if (!polygon) return false;
    const path = polygon.getPath();
    let inside = false;
    let x = lat,
      y = lng;
    for (let i = 0, j = path.getLength() - 1; i < path.getLength(); j = i++) {
      let xi = path.getAt(i).lat(),
        yi = path.getAt(i).lng();
      let xj = path.getAt(j).lat(),
        yj = path.getAt(j).lng();
      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  };

  function arrangeMapMarkers() {
    if (polygons.length > 0 && latlongs) {
      var boundedLatLng = [];
      polygons.forEach((polygon) => {
        var temp = latlongs.filter((e) =>
          checkIfLatLngInPolygon(e.lat, e.long, polygon)
        );
        boundedLatLng = _.uniq(boundedLatLng.concat(temp));
      });
      setLatLongInsidePolygon(boundedLatLng);
      setIsDrawingMode(false);
    }
  }

  useEffect(() => {
    if (polygons.length > 0) {
      arrangeMapMarkers();
    }
  }, [polygons]);

  // function queryAfterDrage(){
  // 	setSelectedPropertiesFromMap({ids: latLongInsidePolygon.map(p => Number(p.id)), shouldQuery: false});
  // 	if (isWait) setIsWait(false);
  // }

  // const useDebounce = debounce(() => queryAfterDrage());

  useEffect(() => {
    if (
      latLongInsidePolygon.length > 0 &&
      latLongInsidePolygon.length < latlongs.length &&
      polygons.length > 0
    ) {
      setSelectedPropertiesFromMap(
        latLongInsidePolygon.map((p) => Number(p.id))
      );
    }
  }, [latLongInsidePolygon]);

  const handlePolygonComplete = (event) => {
    // if (event.type !== google.maps.drawing.OverlayType.POLYGON) {
    //   return;
    // }
    // if (polygonRef.current) {
    //   google.maps.event.clearInstanceListeners(polygonRef.current);
    //   polygonRef.current.setMap(null);
    // }
    polygonRef.current = event.overlay;
    setPolygons((polygons) => [...polygons, polygonRef.current]);
  };

  const handleDrawingComplete = () => {
    setMarkersVisible(true);
  };

  const removeAllPolygons = () => {
    polygons.forEach((p) => p.setMap(null));
    // google.maps.event.clearInstanceListeners(polygon);
    setPolygons([]);
    setLatLongInsidePolygon([]);
    setSelectedPropertiesFromMap([]);
  };

  eventListerFunction();
  // Listen to polygon drag and edit events
  function eventListerFunction() {
    polygons.forEach(function (polygon) {
      var path = polygon.getPath();

      google.maps.event.addListener(path, 'set_at', function () {
        updatePolygon(polygon, path, true);
      });
      google.maps.event.addListener(path, 'insert_at', function () {
        updatePolygon(polygon, path);
      });
      google.maps.event.addListener(polygon, 'dragend', function () {
        updatePolygon(polygon, path);
      });
    });
  }

  // Function to update polygon
  function updatePolygon(polygon, path, wait = false) {
    var wait = wait;
    var newPaths = [];

    // Get the new paths of the polygon
    for (var i = 0; i < path.getLength(); i++) {
      var latLng = path.getAt(i);
      newPaths.push(latLng);
    }

    // Find the index of the polygon in the polygons
    var index = -1;
    for (var i = 0; i < polygons.length; i++) {
      if (polygons[i] === polygon) {
        index = i;
        break;
      }
    }

    polygons[index].setPath(newPaths);
    arrangeMapMarkers(wait);
  }

  return (
    <div className='gmap-container'>
      {initialLatlongs.length > 0 && (
        <Button
          className='gmap-button'
          //disabled={isInEditMode}
          onClick={() => {
            setIsDrawingMode(!isDrawingMode);
            setMarkersVisible(false);
          }}
        >
          {isDrawingMode ? 'Stop' : 'Draw'}
        </Button>
      )}
      {polygons.length > 0 && (
        <Button
          className='gmap-reset-button'
          id='reset-map-markers-btn'
          //disabled={isInEditMode}
          onClick={removeAllPolygons}
        >
          Erase
        </Button>
      )}
      {/* { isLoaded &&  */}
      <LoadScriptNext
        id='google-map-script'
        // googleMapsApiKey='AIzaSyD-Vwu6LSsJscOPVKsSDvVse9NFlA3U7d4'
        googleMapsApiKey='AIzaSyC1BgEh0QjPcEiFz8GDw_L87UYf__IYXTM'
        language='en'
        region='EN'
        libraries={mapLibraries}
        // loadingElement={
        // 	<div className='text-center' style={{ margin: '35vh 0' }}>
        // 		<Spinner type='grow' color='secondary'></Spinner>
        // 	</div>
        // }
      >
        {initialLatlongs.length === 0 && latLongInsidePolygon.length === 0 && (
          <div
            className='text-center'
            style={{
              //position: '',
              zIndex: 1001,
              height: '100%',
              width: '100%',
            }}
          >
            <div
              style={{
                margin: '35vh 0',
              }}
            >
              <h4>No map markers to show</h4>
            </div>
          </div>
        )}
        {mapLoaded === false && (
          <div
            className='text-center'
            style={{
              position: 'absolute',
              zIndex: 100,
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(0,0,0,0.2)',
            }}
          >
            <div
              style={{
                margin: '35vh 0',
              }}
            >
              <Spinner type='grow' color='secondary'></Spinner>
            </div>
          </div>
        )}

        {(initialLatlongs.length > 0 || latLongInsidePolygon.length > 0) && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            // Do stuff on map initial laod
            onLoad={loadHandler}
            id='google-map-script'
            onUnmount={onUnmount}
            onClick={() => setCurrentId(null)}
            options={defaultMapOptions}
          >
            {/* <div className='text-center' style={{ margin: '35vh 0' }}>
								<Spinner type='grow' color='secondary'></Spinner>
							</div> */}
            <>
              {/* Child components, such as markers, info windows, etc. */}
              {isDrawingMode && (
                <DrawingManager
                  drawingMode={'polygon'}
                  options={{
                    drawingControl: true,
                    draggable: false,
                    drawingControlOptions: {
                      //style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                      drawingModes: ['polygon'],
                      position: 2,
                      //onDragEnd={(event) => console.log(event.latLng.toString())}
                    },
                    polygonOptions: {
                      id: Math.floor(Math.random() * 1000 + 1),
                      fillColor: '#199ee0',
                      fillOpacity: 0.2,
                      strokeWeight: 2,
                      strokeColor: '#113460',
                      clickable: true,
                      editable: true,
                      draggable: false,
                      geodesic: false,
                      visible: true,
                      zIndex: 10000,
                    },
                  }}
                  // onOverlayComplete={handleOverlayComplete}
                  onOverlayComplete={(event) => {
                    handlePolygonComplete(event);
                    // setPolygon(event.overlay);
                  }}
                  onPolygonComplete={handleDrawingComplete}
                />
              )}
              {markersVisible && latLongInsidePolygon.length === 0 &&
                displayMarkers(
                  latlongs.length > 0 ? latlongs : initialLatlongs
                )}
              {markersVisible && activeTab === 'Map' && latLongInsidePolygon.length > 0 && (
                <Delayed waitBeforeShow={1000}>
                  {' '}
                  {displayMarkers(latLongInsidePolygon)}{' '}
                </Delayed>
              )}
            </>
          </GoogleMap>
        )}
      </LoadScriptNext>
      {/* } */}
    </div>
  );
};

export default React.memo(NewMap);
//export default NewMap;
