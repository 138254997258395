import React, { useState, useEffect } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../Spinner';
import moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import EmailTemplateShow from './EmailTemplateShow';
import { EMAIL_TEMPLATE_HISTORIES, DELETE_EMAIL_TEMPLATE_HISTORIES } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import RTable from '../../RTable';
import EmailTemplateForm from './EmailTemplateForm';
import EmailTemplateHistoryTable from './EmailTemplateHistoryTable';
import SidebarRight from '../../SidebarRight';
import { Icon } from '@assets';
import Select from 'react-select';

const appoinmentTypes = {
  inperson: 'In-Person with Broker',
  without_broker: 'Send without Broker',
  virtual: 'Virtual Tour',
  self_guided: 'Self-Guided',
};

export default function EmailTemplateHistory(props) {
  const [emailTemplateHistories, setEmailTemplateHistories] = useState([]);
  const [isChecked, setIsChecked] = useState([]);
	const [isCheckedId, setIsCheckedId] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [emailTemplateHistoryList, setEmailTemplateHistoryList] = useState('loading');
  const [currentTemplate, setCurrentTemplate] = useState(null);
	const { data: emailTemplateHistoriesData, loading: emailTemplateHistoriesLoading } = useQuery(EMAIL_TEMPLATE_HISTORIES);

  const history = useHistory();
  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);

  const [deleteEmailTemplateHistories] = useMutation(DELETE_EMAIL_TEMPLATE_HISTORIES, {
		refetchQueries: [EMAIL_TEMPLATE_HISTORIES],
		onCompleted: (data) => {
			if (data.deleteEmailTemplateHistories.errors && data.deleteEmailTemplateHistories.errors.length > 0){
				toast(data.deleteEmailTemplateHistories.message, { type: 'error' });
			} else {
				toast(data.deleteEmailTemplateHistories.message, { type: 'success' });
			}
			setIsProgress(false);
			setDeleteWarningModal(false);
			setIsCheckedId([]);
		}
	});

  function handleDeleteEmailTemplateHistory(ids){
		var emailHistoryIds = Array.isArray(ids) ? ids : [Number(ids)];
		deleteEmailTemplateHistories({
			variables: {
				ids: emailHistoryIds
			}
		})
	}


	useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

  useEffect(() => {
    if (refetch === true) {
      emailTemplateHistoriesRefetch();
      setRefetch(false);
    }
  }, [refetch]);

	useEffect(() => {
		if (emailTemplateHistoriesData) {
			if (emailTemplateHistoriesData.emailTemplateHistories.length === 0) {
				setEmailTemplateHistories('noData')
			} else {
				setEmailTemplateHistories(emailTemplateHistoriesData.emailTemplateHistories.edges);
			}
		}
	}, [emailTemplateHistoriesData])

  useEffect(() => {
    !sidebarState && history.push(`/emails`);
  }, [sidebarState, operation]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Sent At',
        accessor: 'node.createdAt',
        Cell: ({ value, row }) => <div>{value ? moment(value).format('LLL') : '-'}</div>,
      },
      {
        Header: 'Client',
        accessor: 'node.client.name',
        Cell: ({ value, row }) => (
          <div>
            <Avatar
              name={value ? value : '-'}
              className='mr-2 text-white'
              round={true}
              size='30'
            />{' '}
            {value ? value : '-'}
          </div>
        ),
      },
      {
        Header: 'Community',
        accessor: 'node.property.name',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Subject',
        accessor: 'node.subject',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Type',
        accessor: 'node.appointmentType',
        Cell: ({ value, row }) => (
          <div>{value ? appoinmentTypes[value] : '-'}</div>
        ),
      },
      {
        Header: 'Time',
        accessor: 'node.tourAt',
        Cell: ({ value }) => (
          <div>
						{value ? moment(value).format('LLL') : '-'}
					</div>
        ),
      },
      {
        Header: 'To',
        accessor: 'node.sendTo',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'CC',
        accessor: 'node.ccTo',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'BCC',
        accessor: 'node.bccTo',
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <div onClick={(e) => e.stopPropagation()}>
            <UncontrolledButtonDropdown>
							<DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
									onClick={() => {
										setSidebarState(true);
										history.push(`/emails/show/${value}`);
									}
									}
                >
                  View
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
									onClick={() => toggleDeleteWarningModal(Number(value))}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );

  function toggleDeleteWarningModal(id=null){
    if (id){
      setIsCheckedId([id]);
    }
    setDeleteWarningModal(!deleteWarningModal);
  }

  return (
    <>
		  { emailTemplateHistoriesLoading && <Spinner /> }

			{operation === "edit" ?
				<SidebarRight
					sidebarState={sidebarState}
					sidebarToggle={() => {
						setSidebarState(!sidebarState);
					}}
					sidebarWidth='950px'
					sidebarContent={
						<EmailTemplateForm
							currentTemplate={currentTemplate}
							resetTemplate={() => setCurrentTemplate(null)}
						/>
					}
				/> : operation === "show" ? 
				<SidebarRight
					sidebarState={sidebarState}
					sidebarToggle={() => {
						setSidebarState(!sidebarState);
					}}
					sidebarWidth='950px'
					sidebarContent={
						<EmailTemplateShow
            new={operation}
						sidebarToggle={() => {
							setSidebarState(!sidebarState);
						}}
						/>
					}
				/>
				 : 
				<SidebarRight
					sidebarState={sidebarState}
					sidebarToggle={() => {
						setSidebarState(!sidebarState);
					}}
					sidebarWidth='950px'
					sidebarContent={
						<EmailTemplateForm
							currentTemplate={currentTemplate}
							resetTemplate={() => setCurrentTemplate(null)}
						/>
					}
				/>
			}

      { deleteWarningModal &&
				<Modal isOpen={deleteWarningModal} toggle={toggleDeleteWarningModal}>
					<ModalHeader toggle={toggleDeleteWarningModal}>Delete Confirmation</ModalHeader>
					<ModalBody>
						Are you sure you want to delete {isCheckedId.length} email template history
						{isCheckedId.length > 1 ? 's' : ''}?
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							disabled={isProgress}
							onClick={() => handleDeleteEmailTemplateHistory(isCheckedId)}
						>
							Yes
						</Button>
						<Button color='success' onClick={toggleDeleteWarningModal}>
							No
						</Button>
					</ModalFooter>
				</Modal>
			}

      <Row className='mt-md-4'>
        <Col md='12'>
          {emailTemplateHistories.length > 0 ? (
            <EmailTemplateHistoryTable
              columns={columns}
              data={emailTemplateHistories}
              filterable
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
              // changeIsChecked={false}
							setSidebarState={setSidebarState}
              rightAddons={
								<>
									<Button
										color='red'
										onClick={() => toggleDeleteWarningModal()}
										disabled={isCheckedId.length > 0 ? false : true}
										className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
									>
										<Icon icon='trash-alt' className='mr-2' />
										Delete
									</Button>
                </>
              }
            />
          ) : (
            <>
              {emailTemplateHistories.length === 0 ? (
                <div className='text-center' style={{ margin: '35vh 0' }}>
                  <h4>You haven't sent any emails.</h4>
                  <Button
                    color='success'
                    className='button-dashboard mt-2'
                    onClick={() => setSidebarState(true)}
                  >
                    Create and send an email now{' '}
                    <Icon icon='chevron-right' className='ml-2' />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </Col>
      </Row>
    </>
  );
}