import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from '../NewLanding/components/newNavbar';
import Select from 'react-select';
import { Icon } from '@assets';
import { AGENT_SIGNUP, GET_BROKER } from '@api';
import { useMutation, useLazyQuery } from '@apollo/client';
import DivisionTitle, { DivisionSubTitle } from '../NewLanding/components/divisionTitle';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
	Label
} from 'reactstrap';

export default function AgentSignUp() {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const history = useHistory();
  const [broker, setBroker] = useState(null);
  const [progress, setProgress] = useState(false);
	const [error, setError] = useState(null);
	const [termsCheck, setTermsCheck] = useState(false);
	const [phoneNoError, setPhoneNoError] = useState(false);
	const [licenseCheck, setLicenseCheck] = useState(false);
	const [selectedCities, setSelectedCities] = useState(null);
	const [cities, setCitites] = useState(null);
	const [maxCities, setMaxCities] = useState(null);

	window.addEventListener("load", loadBroker);
	function loadBroker() {
		let urlParams = new URLSearchParams(window.location.search);
		let broker_code = urlParams.get('b');
		if (broker_code){
			getBroker({
				variables: {
					brokerCode: broker_code
				}
			})
		}else {
			toast("Invalid URL! You can't signup", { type: "error" });
		}
	};

	const [signupAgent] = useMutation(AGENT_SIGNUP, {
		onCompleted: (data) => {
			setProgress(false);
			if (data.agentSingup.errors){
				toast(data.agentSingup.message, { type: "error" });
			} else {
				toast(data.agentSingup.message, { type: "success" });
				history.push({
          pathname: '/login',
        });
			}
		}
	})

	const [getBroker] = useLazyQuery(GET_BROKER, {
		onCompleted: (data) => {
			if (data.getBroker.error){
				toast(data.getBroker.error, { type: "error" });
			} else {
				setBroker(data.getBroker);
				var _activeSubscriptions = data.getBroker.subscriptions.filter(s => s.active);
				setCitites(_activeSubscriptions[0].cities);
				setMaxCities(_activeSubscriptions[0].plan.maxCities)
			}
		}
	});

  const handleForm = async (data, e) => {
		if (data.phoneNumber === "(555) 555 - 5555"){
			setPhoneNoError(true)
			toast("Are you trying to trick us? We need a real number.", { type: 'error' })
			return
		}

		if (!selectedCities || selectedCities?.length === 0){
			toast(`Please select upto ${maxCities} markets`, { type: 'error' })
			return
		}

		let city_ids = selectedCities.map(c => c.value);

		signupAgent({
			variables: {
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				password: data.password,
				phoneNo: data.phoneNumber,
				licenseNo: data.licenseNo,
				brokerId: Number(broker.id),
				cityIds: city_ids
			}
		})
		setProgress(true);
	};


  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const isLogin = () => {
    if (localStorage.getItem('auth_token')) {
      history.push({
        pathname: '/login',
      });
    }
  };

	const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
	};

	const isModifierKey = (event) => {
			const key = event.keyCode;
			return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
					(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
					(key > 36 && key < 41) || // Allow left, up, right, down
					(
							(event.ctrlKey === true || event.metaKey === true) &&
							(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
					)
	};

	const enforceFormat = (event) => {
			if(!isNumericInput(event) && !isModifierKey(event)){
					event.preventDefault();
			}
	};

	const formatToPhone = (event) => {
			if(isModifierKey(event)) {return;}

			const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
			const areaCode = input.substring(0,3);
			const middle = input.substring(3,6);
			const last = input.substring(6,10);

			if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
			else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
			else if(input.length > 0){event.target.value = `(${areaCode}`;}
	};

	const inputElement = document.getElementById('phoneNumber');
	if (inputElement != null) {
		inputElement.addEventListener('keydown',enforceFormat);
		inputElement.addEventListener('keyup',formatToPhone);
	}

  useEffect(() => {
    isLogin();
    return () => {};
  }, []);


  return (
    <>
      <NavBar />
      {/* <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Sign up</h1>
        </Container>
      </Container> */}
      <Container fluid className='py-5em mt-5em text-center'>
        <Container>
          <Row className='px-lg-5 mb-'>
            <Col md='12'>
              <DivisionTitle
                className="mb-4"
                title={<>Let's <span className="highlighted-text">get started.</span></>}
              />
              {/* <DivisionSubTitle
                className="mb-5"
                title="Getting leases is about to become much easier."
              /> */}
							{ broker && 
								<p>
									{broker.brokerageName} is your manager.
								</p>
							}
            </Col>
            <Col md={{ size: 4, offset: 4 }} className='inputFields'>
              <Form className='form' onSubmit={handleSubmit(handleForm)}>
                <FormGroup>
                  <Input
                    type='text'
                    placeholder='First Name'
                    name='firstName'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('firstName.type', errors) === 'required' && (
                    <p className='input-error'>* First Name required.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Last Name'
                    name='lastName'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('lastName.type', errors) === 'required' && (
                    <p className='input-error'>* Last Name required.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
										id='phoneNumber'
                    type='text'
                    placeholder='Phone Number'
                    name='phoneNumber'
                    autoComplete='off'
										// maxlength="16" 
										// onInput={(e.value) = phoneFormat(e.value)}
                    innerRef={register({
                      required: true,
											minLength: 16,
											maxLength: 16,
                    })}
                  />
                  {_.get('phoneNumber.type', errors) === 'required' && (
                    <p className='input-error'>* Phone Number required.</p>
                  )}
                  {(_.get('phoneNumber.type', errors) === 'minLength' || _.get('phoneNumber.type', errors) === 'maxLength') && (
                    <p className='input-error'>* Invalid Phone Number.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='text'
                    placeholder='Email'
                    name='email'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                      pattern: /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                    })}
                  />
                  {_.get('email.type', errors) === 'required' && (
                    <p className='input-error'>* Email required.</p>
                  )}
                  {_.get('email.type', errors) === 'pattern' && (
                    <p className='input-error'>* Invalid Email.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <Input
                    type='text'
                    placeholder='License Number'
                    name='licenseNo'
                    autoComplete='off'
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {_.get('licenseNo.type', errors) === 'required' && (
                    <p className='input-error'>* License required.</p>
                  )}
                </FormGroup>

                <FormGroup>
                  <InputGroup>
                    <InputGroupText>
                      <Icon
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        icon='eye'
                      />
                    </InputGroupText>
                    <Input
                      type={values.showPassword ? 'text' : 'password'}
                      onChange={handlePasswordChange('password')}
                      value={values.password || ''}
                      placeholder='Password'
                      name='password'
                      autoComplete='off'
                      innerRef={register({
                        required: true,
                        minLength: 6,
                      })}
                    />
                  </InputGroup>
                  {_.get('password.type', errors) === 'required' && (
                    <p className='input-error'>* Password required</p>
                  )}
                  {_.get('password.type', errors) === 'minLength' && (
                    <p className='input-error'>
                      * Password should be minimum 6 letters
                    </p>
                  )}
                </FormGroup>
								{ (cities && maxCities) &&
									<FormGroup className='text-left'>
										<Select
											value={
												maxCities === 1 && selectedCities
													? selectedCities[0]
													: selectedCities
											}
											name='city'
											classNamePrefix='default-select'
											isMulti={maxCities !== 1}
											isClearable={maxCities > 1}
											placeholder='Select Market'
											options={
												cities.length > 0 
													? Object.values(cities).map(
															(city) =>
																JSON.parse(
																	`{"value":${city.id}, "label":"${city.name}"}`
																)
														)
													: []
											}
											onChange={(selectedCity) => {
												maxCities === 1
													? setSelectedCities([selectedCity])
													: selectedCity.length <= maxCities ||
														maxCities === -1
													? setSelectedCities(selectedCity)
													: null;
											}}
											className='w-100'
										/>
									</FormGroup>
                }
								<FormGroup 
									check
									className='text-left mb-3'>
									<Input type="checkbox" checked={true} disabled={true} />
									<Label check className='terms-check'>
										Two-factor authentication enabled, so make sure you have entered correct email and phone number.
									</Label>
								</FormGroup>
								<FormGroup 
									check
									className='text-left mb-3'>
									<Input type="checkbox" checked={licenseCheck} value={licenseCheck} onChange = {() => {setLicenseCheck(!licenseCheck)}}/>
									<Label check className='terms-check' onClick={() => {setLicenseCheck(!licenseCheck)}}>
										I am a licensed agent and am using information from Spark for real estate services. I understand if my license number is found to be incorrect or expired, my account will be cancelled immediately.
									</Label>
								</FormGroup>
								<FormGroup
									check
									className='text-left mb-3'
								>
									<Input type="checkbox" checked={termsCheck} value={termsCheck} onChange = {() => {setTermsCheck(!termsCheck)}}/>
									<Label check className='terms-check' onClick={() => {setTermsCheck(!termsCheck)}}>
										I have read and agreed to <NavLink to='/terms-of-service' className='highlighted-text'>Terms</NavLink>.
									</Label>
									
								</FormGroup>

                <Button color='green' size='lg' disabled={progress || !termsCheck || !licenseCheck} className="btn-rounded">
                  {progress ? 'Progress' : 'Sign Up As Agent'} 
                </Button>
              </Form>
            </Col>
          </Row>

          <Row className='pt-5 privacy-policy'>
            <Col md={{ size: 4, offset: 4 }}>
              <p className='mb-0'>
                <small>By Continuing you agree to accept our</small>
              </p>
              <p className='mb-5'>
                <small>
                  <NavLink to='/privacy-policy'>Privacy Policy</NavLink> &{' '}
                  <NavLink to='/terms-of-service'>Terms of Service.</NavLink>
                </small>
              </p>
              <hr className='border-light' />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}