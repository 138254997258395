import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ALL_CITIES, NEIGHBORHOODS, PUBLIC_USER, CREATE_CLIENT_INQUIRY } from '@api'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
	Label,
	InputGroup,
	InputGroupText,
	Card,
	CardBody
} from 'reactstrap';
import { Icon } from '@assets';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import _ from 'lodash/fp'
import __ from 'lodash'
import Nouislider from 'nouislider-react';
import DatePicker from 'react-datepicker';
import { unitTypes, defaultAmenities } from '@sparklib';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { justFomatePhoneNo } from '../components/shared/sparkLib/phoneNumberFormatter'
import { useGoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from '@config';
import AgentDetailsPage from '../modules/Recommendation/AgentDetailsPage';

export default function Inquiry(props) {
	const { agentName } = useParams()
	const history = useHistory()
	const numberFormatter = new Intl.NumberFormat('en-US');
	const [formErrors, setFormErrors] = useState({
		city: false,
		neighborhoods: false,
		moveIn: false,
		bed: false,
		bath: false,
		budget: false,
		background: false,
		creditScore: false,
		// sqFeet: false
	});

	const initialData = {
		email: '',
    phone: '',
    firstName: '',
    lastName: '',
		fromWhereYouKnowUs: '',
    notes: '',
    budget: '',
    neighborhoods: [],
    unitFeatures: [],
    availFrom: null,
    availTo: null,
    sqFeet: '',
    amenities: [],
    googleRating: 0,
    minRent: null,
    maxRent: null,
    bed: [],
    bath: null,
    leaseEndDate: null, // new Date(),
    city: { label: '', value: '' },
		leaseTerm: null,
		petNumber: null,
		noOfParking: null,
		parkingPreferences: [],
		petTypes: [],
		background: [],
		creditScore: null
	}
	const [captchaToken, setCaptchaToken] = useState(null);
	const [formSubmitted, setFormSubmitted] = useState(false)
	const [progress, setProgress] = useState(false)
	const [data, setData] = useState(initialData)
	const [agent, setAgent] = useState(null)
	const [neighborhoodData, setNeighborhoodData] = useState([])

	const { data: citiesData } = useQuery(ALL_CITIES, {
		variables: { urlName: agentName},
    onError: (e) => {
      console.log(e, 'error');
    },
  });

	useQuery(PUBLIC_USER, {
		variables: { urlName: agentName},
		onCompleted: (data) => {
			if (data && data.publicUser) setAgent(data.publicUser)
			else {
				history.push("/")
				toast.error("Agent not found")
			}
		}
	});

	const [createClientInquiry] = useMutation(CREATE_CLIENT_INQUIRY, {
		onCompleted: (data) => {
			var result = data.createClientInquiry
			if (result?.clientInquiry) {
				toast.success(result.message)
				setFormSubmitted(true)
				window.scrollTo(0,0)
			}
			else if (result?.message) toast.error(result.message)
			else toast.error("Oops, something went wrong!")
			setProgress(false)
		}
	})

	const [fetchNeighborhoods] = useLazyQuery(NEIGHBORHOODS, {
		onCompleted: (data) => {
			setNeighborhoodData(data.neighborhoods)
		}
  });

	const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

	const bathroomOptions = ['1', '1.5', '2', '2.5', '3'];

	function onSubmit(values) {
		if (isError()){
			toast.error("Please provide required information")
		} else if (captchaToken?.length > 0) {
			setProgress(true)
			handleClientInquiry()
		} else {
			toast.error("Please complete the captcha")
		}
	}

	const changeData = ({ e, key = null }) => {
    if (e.target) {
      const { name, value } = e.target;
      setData((data) => ({ ...data, [name]: value || '' }));
    } else {
      e.constructor === Array
        ? setData((data) => ({ ...data, [key]: e }))
        : setData((data) => ({ ...data, [key]: e }))
    }
  };

	function isError(){
		const dataKeys = Object.keys(data)
		const errors = formErrors;
		dataKeys.forEach(key => {
			if (formErrors.hasOwnProperty(key)){
				if (__.isEqual(data[key], initialData[key])){
					errors[key] = true;
				} else {
					errors[key] = false
				}
			} 
		})
		errors.budget = data.minRent || data.maxRent ? false : true;
		errors.moveIn = data.availFrom && data.availTo ? false : true;
		setFormErrors(errors)
		return Object.values(errors).includes(true);
	}

	const onChange = (dates) => {
    const [start, end] = dates;
    changeData({
      e: {
        target: { name: 'availFrom', value: start },
      },
    });
    changeData({
      e: {
        target: { name: 'availTo', value: end },
      },
    });
  };

	useEffect(() => {
		let cityId = data?.city?.value 
		if (Number(cityId) > 0){
			fetchNeighborhoods({
				variables: {
					cityId: Number(cityId)
				}
			})
		}
	}, [data.city])

	function filteredPhoneNo(phoneNo){
		let phone = data.phone?.match(/\d+/g)?.join("")?.substr(-10)
		if (phone){
			return justFomatePhoneNo(phone)
		}
		return ''
	}

	function handleClientInquiry(){
		let phoneNo = filteredPhoneNo(data.phone)
		createClientInquiry({
			variables: {
				urlName: agentName, 
				firstName: data.firstName, 
				lastName: data.lastName, 
				moveInDate: data.moveInDate, 
				email: data.email, 
				phone: phoneNo, 
				notes: data.notes, 
				budget: data.budget, 
				cityId: Number(data.city.value),
				leaseEndDate: data.leaseEndDate, 
				minRent: Number(data.minRent), 
				maxRent: Number(data.maxRent), 
				availFrom: data.availFrom, 
				availTo: data.availTo || null, 
				yearFrom: Number(data.yearFrom), 
				sqFeet: Number(data.sqFeet), 
				bedroom: data.bed, 
				bathroom: Number(data.bath), 
				neighborhoodIds: data.neighborhoods.map(n => Number(n.value)), 
				addons: data.amenities, 
				// policies: data.policies, 
				unitFeatures: data.unitFeatures,
				rating: data.googleRating, 
				petNumber: Number(data.petNumber), 
				petType: data.petTypes.map(p => p.value), 
				noOfParking: data.noOfParking, 
				fromWhereYouKnowUs: data.fromWhereYouKnowUs, 
				leaseTerm: Number(data.leaseTerm), 
				parkingPreferences: data.parkingPreferences.map(p => p.value), 
				creditScore: data.creditScore, 
				background: data.background
			}
		});
	}

	// useEffect(() => {
	// 	const inputElement = document.getElementById('phoneNumber');
	// 	if (inputElement != null) {
	// 		inputElement.addEventListener('keydown',enforceFormat);
	// 		inputElement.addEventListener('keyup',formatToPhone);
	// 	} 
	// }, [])

  return (
    <>
			<GoogleReCaptchaProvider reCaptchaKey={config.CAPTCHA_SITE_KEY}>
			{ !formSubmitted ?
				<Container fluid className='text-left my-5'>
					<Col md={{ size: 8, offset: 2 }}>
						<Row>
							<Col md='12' mt='5' className='text-center'>
								<h2 className='text-primary mb-3'>Apartment Inquiry</h2>
								{ agent && <h6>Your agent is {agent.name}</h6>}
							</Col>
							<Col md='12'>
								{/* {form_errors.length > 0 ? (
									<Col>
										{form_errors.map((err) => (
											<Col className='bg-danger mt-2 text-white'>{err}</Col>
										))}
									</Col>
								) : null} */}
							</Col>

							<Col md='12' className='mt-md-3 inputs-icons mt-4'>
								<Form onSubmit={handleSubmit(onSubmit)} id='clientInquiryForm'>
									{/* <Collapse title={'Basic Info'} open={true}> */}
										<Row>
											<Col md='12' className='mb-4 border-bottom'>
												<h4 className='text-primary'>Contact Information</h4>
											</Col>
											<Col md='4'>
												<FormGroup className='required'>
													<Label for='firstName'>First Name</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='user' />
														</InputGroupText>
														<Input
															type='text'
															name='firstName'
															value={data.firstName}
															autoComplete='off'
															onChange={(e) => changeData({ e })}
															innerRef={register({
																required: true,
															})}
														/>
													</InputGroup>
													{_.get('firstName.type', errors) === 'required' && (
														<p className='input-error'>* First Name required.</p>
													)}
												</FormGroup>
											</Col>

											<Col md='4'>
												<FormGroup className='required'>
													<Label for='lastName'>Last Name</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='user' />
														</InputGroupText>
														<Input
															type='text'
															defaultValue={data.lastName}
															name='lastName'
															autoComplete='off'
															onChange={(e) => changeData({ e })}
															innerRef={register({
																required: true,
															})}
														/>
													</InputGroup>
													{_.get('lastName.type', errors) === 'required' && (
														<p className='input-error'>* Last Name required.</p>
													)}
												</FormGroup>
											</Col>
											<Col md='4'>
												<FormGroup className='required'>
													<Label for='clientPhone'>Phone</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='phone-alt' />
														</InputGroupText>
														<Input
															id="phoneNumber"
															type='text'
															name='phone'
															value={data.phone}
															onChange={(e) => {changeData({ e })}}
															autoComplete='off'
															innerRef={register({
																required: true,
																pattern: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
															})}
														/>
													</InputGroup>
													{_.get('phone.type', errors) === 'required' && (
														<p className='input-error'>* Phone required.</p>
													)}
													{(_.get('phone.type', errors) === 'pattern') && (
														<p className='input-error'>* Invalid Phone Number.</p>
													)}
												</FormGroup>
											</Col>
											<Col md='4'>
												<FormGroup className='required'>
													<Label for='clientEmail'>Email</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='envelope' />
														</InputGroupText>
														<Input
															type='text'
															name='email'
															autoComplete='off'
															onChange={(e) => changeData({ e })}
															value={data.email}
															innerRef={register({
																required: true,
																pattern:
																	/^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
															})}
														/>
													</InputGroup>
													{_.get('email.type', errors) === 'required' && (
														<p className='input-error'>* Email required.</p>
													)}
													{_.get('email.type', errors) === 'pattern' && (
														<p className='input-error'>* Invalid Email.</p>
													)}
												</FormGroup>
											</Col>
									
											<Col lg='4'>
												<FormGroup className='required'>
													<Label for='fromWhereYouKnowUs'>Where did you hear about us?</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='question' />
														</InputGroupText>
														<Input
															type='text'
															defaultValue={data.fromWhereYouKnowUs}
															name='fromWhereYouKnowUs'
															autoComplete='off'
															onChange={(e) => changeData({ e })}
															innerRef={register({
																required: true,
															})}
														/>
													</InputGroup>
													{_.get('fromWhereYouKnowUs.type', errors) === 'required' && (
														<p className='input-error'>* Required.</p>
													)}
												</FormGroup>
											</Col>
										</Row>
									{/* </Collapse> */}
									{/* <Collapse className='mt-3' title={'Filters (Preferences)'}> */}
										<Row>
											<Col md='12' className='my-4 border-bottom text-primary'>
												<h4>Preferences</h4>
											</Col>
											<Col md='4'>
												<FormGroup className='required'>
													<Label for='city'>Select Market</Label>
													<Select
														name='city'
														options={
															typeof citiesData !== 'undefined'
																? Object.values(citiesData.allCities).map((city) =>
																		JSON.parse(
																			`{"value":${city.id}, "label":"${city.name}"}`
																		)
																	)
																: []
														}
														value={data.city}
														onChange={(e) => {
															setData({ ...data, neighborhoods: [] });
															changeData({ e, key: 'city' });
														}}
														className='w-100'
														classNamePrefix='default-select'
														id='city'
														isSearchable={true}
													/>
												</FormGroup>
												{formErrors.city && (
														<p className='input-error'>* Market required.</p>
												)}
											</Col>
											<Col md='4'>
												<FormGroup className='required'>
													<Label for='clientNotes'>Neighborhoods</Label>
													<Select
														name='Neighborhood'
														isMulti
														value={data.neighborhoods}
														// value={getFilter('neighborhoods')}
														options={
															neighborhoodData.length > 0
																? neighborhoodData.map(
																		(neighborhood) =>
																			JSON.parse(
																				`{"value":"${neighborhood.id}", "label":"${neighborhood.name}"}`
																			)
																	)
																: []
														}
														onChange={(e) =>
															changeData({ e, key: 'neighborhoods' })
														}
														className='basic-multi-select w-100'
														classNamePrefix='default-select'
														placeholder='Neighborhood'
														menuPlacement={'auto'}
														styles={{
															menuPortal: (base) => ({
																...base,
																zIndex: 9999,
															}),
														}}
														menuPortalTarget={document.body}
													/>
												</FormGroup>
												{formErrors.neighborhoods && (
														<p className='input-error'>* Neighborhood required.</p>
												)}
											</Col>
											<Col md='4'>
												<FormGroup className='required'>
													<Label for='moveinDate'>Move-in Date</Label>
													<Controller
														name='moveInDate'
														control={control}
														render={() => (
															<DatePicker
																className='form-control'
																selectsRange
																selected={null}
																startDate={data.availFrom}
																endDate={data.availTo}
																placeholderText='Select Earliest - Latest Move Date Range'
																value={new Date()}
																onChange={onChange}
																minDate={new Date()}
																dateFormat='MMMM d, yyyy'
																autoComplete='off'
															/>
														)}
													/>
												</FormGroup>
												{formErrors.moveIn && (
														<p className='input-error'>* Move-in Date Range required.</p>
												)}
											</Col>
											<Col md='4'>
												<FormGroup>
													<Label for='budget'>Lease End Date</Label>
													<DatePicker
														className='form-control'
														selected={data.leaseEndDate}
														onChange={(date) =>
															changeData({
																e: {
																	target: { name: 'leaseEndDate', value: date },
																},
															})
														}
														disabledKeyboardNavigation
														placeholderText='Lease End Date'
														minDate={new Date()}
														dateFormat='MMMM d, yyyy'
														name='leaseEndDate'
														autoComplete='off'
													/>
												</FormGroup>
											</Col>

											<Col md='4'>
												<FormGroup className='required'>
													<Label for='leaseTerm'>Lease Term (no. of months)</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='home' />
														</InputGroupText>
														<Input
															type='number'
															defaultValue={data.leaseTerm}
															name='leaseTerm'
															autoComplete='off'
															onChange={(e) => changeData({ e })}
															innerRef={register({
																required: true,
															})}
														/>
													</InputGroup>
													{_.get('leaseTerm.type', errors) === 'required' && (
														<p className='input-error'>* Lease term required.</p>
													)}
												</FormGroup>
											</Col>
											<Col md='4'>
												<Label for='PetNumber'>No. of Pet</Label>
												<InputGroup>
													<InputGroupText>
														<Icon icon='dog' />
													</InputGroupText>
													<Input
														type='number'
														defaultValue={data.petNumber}
														name='petNumber'
														autoComplete='off'
														onChange={(e) => changeData({ e })}
													/>
												</InputGroup>
											</Col>
											<Col md='4'>
												<FormGroup>
													<Label for='PetType'>Pet Type</Label>
													<Select
														name='PetType'
														options={
															Object.values(petTypes).map((petType) =>
																	JSON.parse(
																		`{"value":"${petType}", "label":"${petType}"}`
																	)
																)
														}
														value={data.petTypes}
														onChange={(e) => {
															changeData({ e, key: 'petTypes' });
														}}
														isMulti
														className='w-100'
														classNamePrefix='default-select'
														id='PetTypes'
														isSearchable={true}
													/>
												</FormGroup>
											</Col>
											<Col md='4'>
												<FormGroup>
													<Label for='NoOfParking'>No. of Parking</Label>
													<InputGroup>
														<InputGroupText>
															<Icon icon='parking' />
														</InputGroupText>
														<Input
															type='number'
															defaultValue={data.noOfParking}
															name='noOfParking'
															autoComplete='off'
															onChange={(e) => changeData({ e })}
														/>
													</InputGroup>
												</FormGroup>
											</Col>
											<Col md='4'>
												<FormGroup>
													<Label for='PetType'>Where are you open to parking?</Label>
													<Select
														name='parkingPreferences'
														options={
															Object.values(parkingPreferences).map((parking) =>
																	JSON.parse(
																		`{"value":"${parking}", "label":"${parking}"}`
																	)
																)
														}
														value={data.parkingPreferences}
														onChange={(e) => {
															changeData({ e, key: 'parkingPreferences' });
														}}
														isMulti
														className='w-100'
														classNamePrefix='default-select'
														id='parkingPreferences'
														isSearchable={true}
													/>
												</FormGroup>
											</Col>
										</Row>

										<Row className='mt-3'>
											<Col md='6'>
												<h6 className='mr-1' style={{ display: 'inline-block' }}>
													Square Feet -
												</h6>
												{data.sqFeet === undefined ||
												data.sqFeet === '' ||
												data.sqFeet === null ? (
													<>Any</>
												) : (
													<>
														Larger than {numberFormatter.format(data.sqFeet)} sqft
														{/* <sup>2</sup> */}
													</>
												)}
												<div className='px-2 py-2 mt-2 w-100'>
													<Nouislider
														connect='lower'
														tooltips={true}
														// animate={true}
														start={[0]}
														behaviour='tap'
														range={{
															min: [0, 100],
															'5%': [100, 100],
															'15%': [400, 50],
															'75%': [1300, 100],
															'90%': [2000, 500],
															max: [5000],
														}}
														onChange={(e) =>
															changeData({
																e: {
																	target: {
																		name: 'sqFeet',
																		value: e[0] < 100 ? '' : e[0],
																	},
																},
															})
														}
														format={{
															to: function (value) {
																return value === undefined ? '' : value.toFixed(0);
															},
															from: Number,
														}}
													/>
												</div>
												{formErrors.sqFeet && (
														<p className='input-error'>* SqFeet required.</p>
												)}
											</Col>
											{/*<Col md='6'>
												<Label>
													Google Rating - {' '}
													{data.googleRating === 0 ||
													data.googleRating === undefined
														? ''
														: data.googleRating}{' '}
													{data.googleRating === 5
														? ''
														: data.googleRating != 0 &&
															data.googleRating !== undefined
														? 'and above'
														: 'Any'}
												</Label>
												<div className='px-2 py-1 mt-2 mb-1 w-100'>
													<Nouislider
														connect='lower'
														tooltips={true}
														animate={true}
														step={0.1}
														start={[0]}
														behaviour='tap'
														range={{
															min: 0,
															max: 5,
														}}
														onChange={(e) => {
															changeData({
																e: {
																	target: {
																		name: 'googleRating',
																		value: e[0] < 0 ? '' : e[0],
																	},
																},
															});
														}}
														format={{
															to: function (value) {
																return value === undefined
																	? ''
																	: value === 5.0
																	? value
																	: value === 0.0
																	? value
																	: value.toFixed(1);
															},
															from: Number,
														}}
													/>
												</div>
											</Col>*/}
										</Row>

										<Row className='mt-md-3'>
											<Col md='6'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4 py-3 required'>
														{/* <CardTitle>Card title</CardTitle> */}
														<label htmlFor=''>Price</label>
														<div className='px-2 py-2 mt-2 mb-1 w-100'>
															<Nouislider
																connect={true}
																tooltips={true}
																animate={true}
																start={[
																	0, 10000
																]}
																behaviour='tap'
																range={{
																	min: [0, 100],
																	'5%': [100, 100],
																	'50%': [2600, 200],
																	'75%': [5000, 500],
																	// '90%': [9500, 500],
																	max: [10000],
																}}
																onChange={(e) => {
																	changeData({
																		e: {
																			target: {
																				name: 'minRent',
																				value: e[0] < 100 ? '' : e[0],
																			},
																		},
																	});
																	changeData({
																		e: {
																			target: {
																				name: 'maxRent',
																				value: e[1] > 9500 ? '' : e[1],
																			},
																		},
																	});
																}}
																format={{
																	to: function (value) {
																		return value === undefined
																			? ''
																			: value.toFixed(0);
																	},
																	from: Number,
																}}
															/>
														</div>
														<Row className='mt-2'>
															<Col md='6'>
																<InputGroup>
																	<InputGroupText>
																		<Icon icon='dollar-sign' />
																	</InputGroupText>
																	<Input
																		type='text'
																		placeholder='Min Price'
																		min='0'
																		name='minRent'
																		value={
																			data.minRent
																				? numberFormatter.format(data.minRent)
																				: ''
																		}
																		onChange={(e) => {
																			var minPriceValue = Number(
																				e.target.value?.replace(/,/g, '')
																			);
																			if (
																				minPriceValue >= 0 ||
																				minPriceValue === ''
																			) {
																				changeData({
																					e: {
																						target: {
																							value: minPriceValue,
																							name: 'minRent',
																						},
																					},
																				});
																			}
																		}}
																	/>
																</InputGroup>
															</Col>
															<Col md='6'>
																<InputGroup>
																	<InputGroupText>
																		<Icon icon='dollar-sign' />
																	</InputGroupText>
																	<Input
																		type='text'
																		min='0'
																		placeholder='Max Price'
																		name='maxRent'
																		value={
																			data.maxRent
																				? numberFormatter.format(data.maxRent)
																				: ''
																		}
																		onChange={(e) => {
																			var maxPriceValue = Number(
																				e.target.value?.replace(/,/g, '')
																			);
																			if (
																				maxPriceValue >= 0 ||
																				maxPriceValue === ''
																			) {
																				changeData({
																					e: {
																						target: {
																							value: maxPriceValue,
																							name: 'maxRent',
																						},
																					},
																				});
																			}
																		}}
																	/>
																</InputGroup>
															</Col>
														</Row>
													</CardBody>
												</Card>
												{formErrors.budget && (
													<p className='input-error'>* Budget required.</p>
												)}
											</Col>
											<Col md='6'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4 required'>
														<label htmlFor=''>Bedroom</label>
														<Row className='mb-2'>
															{Object.keys(unitTypes).map((typecode, index) => (
																<Col md='4' key={index}>
																	<Label className=''>
																		<input
																			className='custom-checkbox d-inline-block w-auto mr-1'
																			type='checkbox'
																			name='bed'
																			value={typecode}
																			onChange={(e) => {
																				if (e.target.checked) {
																					changeData({
																						e: [...(data.bed || []), typecode],
																						key: 'bed',
																					});
																				} else {
																					changeData({
																						e: data.bed.filter(
																							(a) => a !== typecode
																						),
																						key: 'bed',
																					});
																				}
																			}}
																			checked={
																				data.bed?.find((a) => a === typecode) ||
																				false
																			}
																		/>{' '}
																		{unitTypes[typecode]}
																	</Label>
																</Col>
															))}
														</Row>
													</CardBody>
												</Card>
												{formErrors.bed && (
													<p className='input-error'>* Bed required.</p>
												)}	
											</Col>
										</Row>

										<Row>
											<Col md='6'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4 required'>
														<label htmlFor=''>Bathroom</label>
														<Row>
															{bathroomOptions.map((bathSize, index) => (
																<Col md='4' key={index}>
																	<Label className=''>
																		<input
																			className=' d-inline-block w-auto mr-1'
																			type='radio'
																			name='bath'
																			value={bathSize}
																			onChange={(e) => {
																				if (e.target.checked) {
																					changeData({
																						e,
																						key: 'bath',
																					});
																				}
																			}}
																			checked={
																				data?.bath === bathSize ? true : false
																			}
																		/>{' '}
																		{bathSize}+ Bath
																	</Label>
																</Col>
															))}
														</Row>
													</CardBody>
												</Card>
												{formErrors.bath && (
													<p className='input-error'>* Bath required.</p>
												)}
											</Col>
											<Col md='6'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4'>
														<FormGroup>
															<Label for='clientNotes'>Notes</Label>
															<InputGroup size='lg'>
																<InputGroupText>
																	<Icon icon='clipboard' />
																</InputGroupText>
																<Input
																	type='text'
																	name='notes'
																	innerRef={register({})}
																	value={data.notes}
																	onChange={(e) => changeData({ e })}
																	autoComplete='off'
																/>
															</InputGroup>
														</FormGroup>
													</CardBody>
												</Card>
											</Col>
										<Row>

										</Row>
											<Col md='12' className='my-4 border-bottom text-primary'>
												<h4>Unit Features & Building Amenities</h4>
											</Col>
											<Col md='12'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4'>
														<label htmlFor=''>Units Features</label>
														<Row className='mb-2'>
															{unitFeatures.map((feature, index) => (
																<Col md='3' key={index}>
																	<Label className=''>
																		<input
																			className='w-auto mr-1'
																			type='checkbox'
																			name='units feature'
																			value={feature}
																			onChange={(e) => {
																				if (e.target.checked) {
																					changeData({
																						e: [...(data.unitFeatures || []), feature],
																						key: 'unitFeatures',
																					});
																				} else {
																					changeData({
																						e: data.unitFeatures.filter(
																							(a) => a !== feature
																						),
																						key: 'unitFeatures',
																					});
																				}
																			}}
																			checked={
																				data.unitFeatures?.find((a) => a === feature) ||
																				false
																			}
																		/>{' '}
																		{feature}
																	</Label>
																</Col>
															))}
														</Row>
													</CardBody>
												</Card>
											</Col>
											<Col md='12'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4'>
														<label htmlFor=''>Building Amenities</label>
														<Row className='mb-2'>
															{BuildingAmenities.map((amenity, index) => (
																<Col md='3' key={index}>
																	<Label className=''>
																		<input
																			className='w-auto mr-1'
																			type='checkbox'
																			name='amenity'
																			value={amenity}
																			onChange={(e) => {
																				if (e.target.checked) {
																					changeData({
																						e: [...(data.amenities || []), amenity],
																						key: 'amenities',
																					});
																				} else {
																					changeData({
																						e: data.amenities.filter(
																							(a) => a !== amenity
																						),
																						key: 'amenities',
																					});
																				}
																			}}
																			checked={
																				data.amenities?.find((a) => a === amenity) ? true : false
																			}
																		/>{' '}
																		{amenity}
																	</Label>
																</Col>
															))}
														</Row>
													</CardBody>
												</Card>
											</Col>
										</Row>

										<Row>
											<Col md='12' className='my-4 border-bottom text-primary'>
												<h4>Credit Score & Background Verification</h4>
											</Col>
											<Col md='6'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4 required'>
														<label htmlFor=''>Credit Score</label>
														<Row className='mb-2'>
															{creditScores.map((creditScore, index) => (
																<Col md='6' key={index}>
																	<Label className=''>
																		<input
																			className='w-auto mr-1'
																			type='radio'
																			name='creditScore'
																			value={creditScore}
																			onChange={(e) => {
																				if (e.target.checked) {
																					changeData({
																						e: creditScore,
																						key: 'creditScore',
																					});
																				}
																			}}
																			checked={
																				data.creditScore === creditScore ? true : false
																			}
																		/>{' '}
																		{creditScore}
																	</Label>
																</Col>
															))}
														</Row>
													</CardBody>
												</Card>
												{formErrors.creditScore && (
													<p className='input-error'>* Credit Score required.</p>
												)}
											</Col>
											<Col md='6'>
												<Card className='mt-2 text-left rounded-small'>
													<CardBody className='px-4 required'>
														<label htmlFor=''>Background</label>
														<Row className='mb-2'>
															{backGrounds.map((background, index) => (
																<Col md='6' key={index}>
																	<Label className=''>
																		<input
																			className='w-auto mr-1'
																			type='checkbox'
																			name='background'
																			value={background}
																			onChange={(e) => {
																				if (e.target.checked) {
																					changeData({
																						e: [...(data.background || []), background],
																						key: 'background',
																					});
																				} else {
																					changeData({
																						e: data.background.filter(b => b != e.target.value),
																						key: 'background'
																					})
																				}
																			}}
																			checked={
																				data.background?.find((a) => a === background) ||
																				false
																			}
																		/>{' '}
																		{background}
																	</Label>
																</Col>
															))}
														</Row>
													</CardBody>
												</Card>
												{formErrors.background && (
													<p className='input-error'>* Background required.</p>
												)}
											</Col>
										</Row>
									{/* </Collapse> */}

									<Col lg='12' className='mt-md-3 text-center'>
											{/* <GoogleReCaptcha
												onVerify={handleCaptchaVerification}
												refreshReCaptcha={false}
											/> */}
										<ReCaptcha progress={progress} setToken={setCaptchaToken} />
										{/* <Button
											color='primary'
											className='button-dashboard mr-3 mt-3'
											disabled={progress}
										>
											{ progress ? "Submitting.." : "Submit" }
										</Button> */}
									</Col>
								</Form>
							</Col>
						</Row>
					</Col>
				</Container> 
				:
				<Container fluid className='text-left my-5'>
					<Col md={{ size: 8, offset: 2 }}>
						{agent && <Row>
							<Col md='12' mt='5' className='text-center'>
								<h2 className='text-primary'>Thank you for completing the form.</h2>
								<p>We will be in touch with you soon!.</p>
							</Col>
							<Col md='12'>
								<AgentDetailsPage agent={agent}/>
							</Col>
						</Row>}
					</Col>
				</Container>
			}
			</GoogleReCaptchaProvider>
    </>
  );
}

const ReCaptcha = ({ progress, setToken}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('client_inquiry');
		setToken(token)
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  // return <button onClick={handleReCaptchaVerify}>Verify recaptcha</button>;
	return (
		<Button
			color='primary'
			className='button-dashboard mr-3 mt-3'
			disabled={progress}
		>
			{ progress ? "Submitting.." : "Submit" }
		</Button>
	)
};

const creditScores = [
	"I don't have a credit score",
	"300-499",
	"500-549",
	"550-599",
	"600-649",
	"650+"
];

const backGrounds = [
	"None",
	"Active Rental Debt",
	"Bankruptcy",
	"Eviction",
	"Felony",
	"Misdemeanor",
	"Unpaid Broken Lease"
];

const parkingPreferences = [
	"On-site parking",
	"Uncovered parking",
	"Street parking",
	"No parking needed",
];

const petTypes = [
	"Cats",
	"Dogs",
	"ESA"
]

const unitFeatures = [
	"Balcony / Terrace",
	"Bath Tub",
	"Den",
	"Dishwasher",
	"Dual Vanity",
	"Floor-to-Ceiling Windows",
	"Gas Stove",
	"Hardwood Floor",
	"In-unit Laundry",
	"Kitchen Island",
	"Walk-in Closet",
	"Walk-in Shower",
]

const BuildingAmenities = defaultAmenities.map(a => a.name)
