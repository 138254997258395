import React, {
  useEffect,
  useRef,
  useLayoutEffect as useOriginalLayoutEffect,
} from 'react';
import { Icon } from '@assets';

const useLayoutEffect =
  typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef(isSelected) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    if (!isSelected) return;
    ref.current?.focus({ preventScroll: true });
  }, [isSelected]);

  return {
    ref,
    tabIndex: isSelected ? 0 : -1,
  };
}

// export const datetimeFormatter = new Intl.DateTimeFormat(navigator.language);
// export const dateFormatter = new Intl.DateTimeFormat(navigator.language);
// export const currencyFormatter = new Intl.NumberFormat(navigator.language, {
export const datetimeFormatter = new Intl.DateTimeFormat('en-US');
export const dateFormatter = new Intl.DateTimeFormat('en-US');
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'usd',
  maximumFractionDigits: 0,
});

export function TimestampFormatter({ timestamp }) {
  return <>{datetimeFormatter.format(timestamp)}</>;
}

export function DateFormatter({ date }) {
  // console.log(dateFormatter.format(date));
  return <>{dateFormatter.format(date)}</>;
}

export function CurrencyFormatter({ value }) {
  return <>{currencyFormatter.format(value)}</>;
}

export function CellExpanderFormatter({
  isCellSelected,
  expanded,
  onCellExpand,
}) {
  const { ref, tabIndex } = useFocusRef(isCellSelected);

  function handleKeyDown(e) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onCellExpand();
    }
  }

  return (
    <div
      className='cursor-pointer'
      onClick={onCellExpand}
      onKeyDown={handleKeyDown}
    >
      <span className='no-outline' ref={ref} tabIndex={tabIndex}>
        {/* {expanded ? '\u25BC' : '\u25B6'} */}
        {/* {expanded ? <Icon icon="chevron-down" /> : <Icon icon="chevron-right" />} */}
        {/* {expanded ? <Icon icon="chevron-circle-down" /> : <Icon icon="chevron-circle-right" />} */}
        {expanded ? (
          <Icon icon='caret-down' size='lg' />
        ) : (
          <Icon icon='caret-right' size='lg' />
        )}
      </span>
    </div>
  );
}
