import React, { useEffect, useState } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { RecoilRoot } from 'recoil';

// import ReactGA from 'react-ga';

// import DynamicScrollToTop from './components/scrollTop';

import config, { apolloClient, allRoutes } from '@config';

function App() {
	const [apiCall, setApiCall] = useState(false);

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

	function isLoggedIn() {
		if (localStorage.getItem('auth_token') || localStorage.getItem('user_type')) return true;
		else return false;
	}

	async function checkSession(){
		const response = await fetch(`${config.url.REACT_APP_BACKEND_BASE_URL}check_session`, {
			method: 'POST',
			headers: {'Content-Type': 'application/json'},
			credentials: "include",
		});
		let result = await response.json();
		if (result?.is_valid === false){
				localStorage.clear();
				localStorage.setItem("session_expired", true);
				window.location.replace("/login");
		}
	}

	addEventListener("DOMContentLoaded", async function() {
		if (!apiCall && isLoggedIn()){
			try {
				await checkSession();
			}catch(err){
				console.log(err);
			}
		}
	});

	addEventListener("visibilitychange", async function(){
		if (document.hidden === false && !apiCall && isLoggedIn()){
			try {
				await checkSession();
			}catch(err){
				console.log(err);
			}
		} else {
			setApiCall(false);
		}
	});

	// temporary Added this code to logout user so that they can login using session cookies
	if (localStorage.getItem('auth_token') && localStorage.getItem('auth_token') !== "12345"){
		localStorage.clear();
		window.location.replace("/login");
	}

  return (
    <div className='App'>
			<RecoilRoot>
				<ApolloProvider client={apolloClient}>
					<BrowserRouter>
						{/* <DynamicScrollToTop /> */}
						{allRoutes}
					</BrowserRouter>
				</ApolloProvider>
			</RecoilRoot>
    </div>
  );
}

export default App;
