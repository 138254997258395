import React from 'react'

import NavBar from '../components/NavBar';
import Footer from '../components/footer';

import { NavLink } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import SectionTitle, { SectionSubTitle } from '../components/sectionTitle';

import {
  SparkFeature,
  FeaturesSection2,
  FeaturesSection3,
  FeaturesSection4
} from '@assets'

export default function Features() {
  return (<>
    <NavBar />
    <Container fluid className="py-5 text-center banner banner-small">
      <Container className="my-5">
        <h1>Features</h1>
      </Container>
    </Container>

    <Container fluid className="py-4 py-lg-5">
      <Container>
        <Row className="d-flex align-items-center flex-lg-row-reverse">
          <Col lg="6" xl="7" className="text-center mb-3 mb-md-0">
            <img src={SparkFeature} alt="Database Banner" className="img-fluid" />
          </Col>
          <Col lg="6" xl="5" className="text-center text-lg-left mt-4 mt-lg-0">
            <SectionTitle title="A simpler way to data." className="mb-4" />
            <SectionSubTitle title="Search for properties" />
            <SectionSubTitle title="based on your clients" />
            <SectionSubTitle title="specific parameters" />
            
            <Button color="green" size="jumbo" className="mt-4"><NavLink to="/signup">Begin Free Trial</NavLink></Button>
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="py-4 py-lg-5">
      <Container>
        <Row className="d-flex align-items-center">
          <Col lg="6" className="text-center mb-3 mb-md-0">
            <img src={FeaturesSection2} alt="FeaturesSection2" className="img-fluid" />
          </Col>
          <Col lg="6" className="text-center text-lg-left mt-4 mt-lg-0">
            <SectionTitle title="Apartment lists in 60 seconds." className="mb-4" />
            <SectionSubTitle title="Send a list matching your client's" />
            <SectionSubTitle title="preferences including specific date available," />
            <SectionSubTitle title="accurate pricing and up-to-date specials." />
            
            <Button color="green" size="jumbo" className="mt-4"><NavLink to="/signup">Begin Free Trial</NavLink></Button>
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="py-4 py-lg-5">
      <Container>
        <Row className="d-flex align-items-center flex-lg-row-reverse">
          <Col lg="6" className="text-center mb-3 mb-md-0">
            <img src={FeaturesSection3} alt="FeaturesSection3" className="img-fluid" />
          </Col>
          <Col lg="6" className="text-center text-lg-left mt-4 mt-lg-0">
            <SectionTitle title="Details" className="mb-4" />
            <SectionSubTitle title="Allow clients to view important" />
            <SectionSubTitle title="details about the options." />
            
            <Button color="green" size="jumbo" className="mt-4"><NavLink to="/signup">Begin Free Trial</NavLink></Button>
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="py-4 py-lg-5">
      <Container>
        <Row className="d-flex align-items-center">
          <Col lg="6" className="text-center mb-3 mb-md-0">
            <img src={FeaturesSection4} alt="FeaturesSection4" className="img-fluid" />
          </Col>
          <Col lg="6" className="text-center text-lg-left mt-4 mt-lg-0">
            <SectionTitle title="Clients schedule tours without leaving the app" className="mb-4" />
            <SectionSubTitle title="Simplify your tours without" />
            <SectionSubTitle title="reaching out." />
            
            <Button color="green" size="jumbo" className="mt-4"><NavLink to="/signup">Begin Free Trial</NavLink></Button>
          </Col>
        </Row>
      </Container>
    </Container>


    <Footer />
  </>)
}
