export const isNumericInput = (event) => {
	const key = event.keyCode;
	return ((key >= 48 && key <= 57) || // Allow number line
			(key >= 96 && key <= 105) // Allow number pad
	);
};

export const isModifierKey = (event) => {
	const key = event.keyCode;
	return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
			(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
			(key > 36 && key < 41) || // Allow left, up, right, down
			(
					(event.ctrlKey === true || event.metaKey === true) &&
					(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
			)
};

export const enforceFormat = (event) => {
	if(!isNumericInput(event) && !isModifierKey(event)){
			event.preventDefault();
	}
};

export const formatToPhone = (event) => {
		if(isModifierKey(event)) {return;}

		var str = event.target.value.trim();
		if (str.startsWith('+1')) str = str.slice(2);
		if (str.startsWith('1')) str = str.slice(1);
		str = str.replaceAll(/\D/g, '');
		const input = str.substring(0,10); // First ten digits of input only
		const areaCode = input.substring(0,3);
		const middle = input.substring(3,6);
		const last = input.substring(6,10);

		if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
		else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
		else if(input.length > 0){event.target.value = `(${areaCode}`;}
};

export const justFomatePhoneNo = (str) => {
	str = str.trim();
	if (str.startsWith('+1')) str = str.slice(2);
	if (str.startsWith('1')) str = str.slice(1);
	str = str.replaceAll(/\D/g, '');
	var input = str.substring(0,10); // First ten digits of input only
	var areaCode = input.substring(0,3);
	var middle = input.substring(3,6);
	var last = input.substring(6,10);
	return `(${areaCode}) ${middle} - ${last}`;
}
