import React from 'react'

function DivisionTitle(props){
  return(
    <h1 className={[props.color + ' division-title ' + props.className || ' ']}>{props.title}</h1>
  );
}

function DivisionSubTitle(props){
  return(
    <h2 className={[props.color + ' division-subtitle ' + props.className]}>{props.title}</h2>
  );
}

export default DivisionTitle;

export {DivisionSubTitle};