import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { CONTACTINQUIRIES, INQUIRIES_MULTI_DELETE } from "@api";
import { useQuery, useMutation } from "@apollo/client";
import Spinner from "../Spinner";
import RTable from "../RTable";

import { Icon } from '@assets';

export default function Inquiries() {
  const [inquiriesList, setInquiriesList] = useState("loading");
  const [inquiriesData, setInquiriesData] = useState([]);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const [allDeleteModal, setAllDeleteModal] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const {
    data: contactInquiriesData,
    loading,
    error,
  } = useQuery(CONTACTINQUIRIES, {
    onCompleted: (data) => {
      if (!data.contactinquiries.errors) {
        /* setInquiriesData(data ? data.contactinquiries.edges : []);
        setTotalRowCount(data.contactinquiries.totalCount);
        // var count = inquiriesData
        //   ? Math.ceil(inquiriesData.contactinquiries.totalCount / per_page)
        //   : 0;
        setPageCount(Math.ceil(data.contactinquiries.totalCount / per_page));
        const currentPageRows = data.contactinquiries.edges.slice(
          offset,
          per_page + offset
        );
        setcurrentPageRows(currentPageRows); */
      } else {
        data.contactinquiries.errors.map((err) => console.log(err));
      }
    },
    onError: (e) => {
      console.log(e);
      // toast(e, { type: "error" });
    },
  });

  const [inquiriesMultiDelete] = useMutation(INQUIRIES_MULTI_DELETE, {
    onCompleted: (data) => {
      setAllDeleteModal(!allDeleteModal);
      toast(data.contactinquiryMultiDelete.message, { type: "success" });
    },
    refetchQueries: [CONTACTINQUIRIES],
    onError: (e) => {
      console.log(e);
    },
  });
  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);
  const handleDeleteAll = async () => {
    await inquiriesMultiDelete({
      variables: {
        ids: isCheckedId,
      },
    });
  };

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  useEffect(() => {
    /* contactInquiriesData &&
      setInquiriesData(contactInquiriesData.contactinquiries.edges); */
    if (contactInquiriesData) {
      if (contactInquiriesData.contactinquiries.totalCount === 0) {
        setInquiriesList("noData");
      } else {
        setInquiriesList("data");
        setInquiriesData(contactInquiriesData.contactinquiries.edges);
      }
    }
  }, [contactInquiriesData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "node.email",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Phone",
        accessor: "node.phone",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Inquiry Reason",
        accessor: "node.inquiryReason",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Comment",
        accessor: "node.message",
        Cell: ({ value, row }) => <div>{value ? value : "-"}</div>,
      },
      {
        Header: "Received On",
        accessor: "node.createdAt",
        Cell: ({ value, row }) => (
          <div>{value ? formatTimeStamp(value) : "-"}</div>
        ),
      },
    ],
    []
  );

  const formatTimeStamp = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    // const options = { year: "numeric", month: "short", day: "numeric", hour: "" };
    // return new Date(dateString).toLocaleDateString(undefined, options);
    return new Intl.DateTimeFormat("default", options).format(
      Date.parse(dateString)
    );
  };

  return (
    <>
      {loading && <Spinner />}
      <Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} inquir
          {isCheckedId.length > 1 ? "ies" : "y"}?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDeleteAll}>
            Yes
          </Button>
          <Button color="success" onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
      <Row className="mt-md-4">
        <Col md="12">
          {inquiriesList === "data" ? (
            <RTable
              columns={columns}
              data={inquiriesData}
              filterable
              addons={
                <Button
                  color="red"
                  onClick={onAllDeletePopup}
                  disabled={isCheckedId.length > 0 ? false : true}
                  className="button-dashboard float-right"
                >
                  <Icon icon="trash-alt" className="mr-2" />
                  Delete
                </Button>
              }
              changeIsChecked={(isChecked) => setIsChecked(isChecked)}
            />
          ) : (
            <>
              {inquiriesList === "noData" ? (
                <h2 className="text-center mt-5">Inquiries not found</h2>
              ) : null}
            </>
          )}
          {/* <Table
            // borderless
            responsive
            className="client-list text-left min-w-900"
          >
            <thead>
              <tr>
                <th>Email</th>
                <th>Phone</th>
                <th>Inquiry Reason</th>
                <th>Comment</th>
                <th>Received On</th>
              </tr>
            </thead>
            <tbody>
              {currentPageRows &&
                currentPageRows.map(
                  (
                    {
                      node: {
                        id,
                        email,
                        phone,
                        inquiryReason,
                        message,
                        createdAt,
                      },
                    },
                    i
                  ) => (
                    <tr key={id}>
                      <td>{email}</td>
                      <td>{phone}</td>
                      <td>{inquiryReason}</td>
                      <td>{message}</td>
                      <td>{formatTimeStamp(createdAt)}</td>
                    </tr>
                  )
                )}
            </tbody>
          </Table> */}
        </Col>
      </Row>
    </>
  );
}
