import React, { useState } from 'react'
import { 
	TabPane,
	TabContent,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink
} from 'reactstrap'
import classnames from 'classnames';
import Subscription from './Subscription';
import UserProfile from './UserProfile';
import NotificationList from './NotificationList';
import NotificationSetting from './NotificationSetting';
import PaymentMethod from './payment/PaymentMethod';
import UpdatePassword from '../updatePassword';
import ReferFriend from './ReferFriend';
import PropertiesPageSettings from './PropertiesPageSettings';

export default function Settings() {
	const Tabs = [
		{
			tabId: 'myProfile',
			name: 'My Profile'
		},
		{
			tabId: 'propertiesPageSettings',
			name: 'Properties Page Settings'
		},
		{
			tabId: 'notifications',
			name: 'Notifications'
		},
		{
			tabId: 'referFriend',
			name: 'Refer a Friend'
		}
	];

	if (localStorage.getItem('is_agent') !== 'true'){
		Tabs.push({
			tabId: 'subscriptions',
			name: 'Subscriptions'
		},)
	} 

	const [activeTab, setActiveTab] = useState('myProfile')
	return (
		<Row >
      <Col md='12' className='table-tabs mt-3' >
				{/* <Row> */}
					{/* <Col            
						lg={{ size: 6, offset: 3 }}
            md={{ size: 8, offset: 2 }}
            className='text-left mt-3 mt-md-3'
					> */}
					<div className='d-flex justify-content-center'>
						<Nav tabs>
							{
								Tabs.map(tab => {
									return (
										<NavItem>
											<NavLink
												className={classnames({
													active: activeTab === tab.tabId,
												})}
												onClick={() => {
													setActiveTab(tab.tabId);
												}}
											>
												{tab.name}
											</NavLink>
										</NavItem>
									)
								})
							}
						</Nav>
					</div>
					{/* </Col> */}
				{/* </Row> */}
				
				<TabContent activeTab={activeTab}>

					<TabPane tabId='myProfile'> 
						<Row className='px-lg-5'>
							<Col md='9' className='pr-2'>
								<UserProfile/>
							</Col>
							<Col md='3'>
								<UpdatePassword showForm={true} />
							</Col>
						</Row>
					</TabPane>

					<TabPane tabId='subscriptions'>
						<Row className='px-lg-5'>
							<Col md='8'>
								<Subscription />
							</Col>
							<Col md='4' className='mt-5'>
								<PaymentMethod />
							</Col>
						</Row>
					</TabPane>

					<TabPane tabId='notifications'>
						<Row className='px-lg-5'>
							<Col md='12'>
								<NotificationSetting />
							</Col>
						</Row>
					</TabPane>

					<TabPane tabId='referFriend'>
						<Row className='px-lg-5'>
							<Col md='12'>
								<ReferFriend />
							</Col>
						</Row>
					</TabPane>

					<TabPane tabId='propertiesPageSettings'>
						<Row className='px-lg-5'>
							<Col md='12'>
								<PropertiesPageSettings />
							</Col>
						</Row>
					</TabPane>

				</TabContent>
			</Col>
		</Row>
	)
}
