import { atom } from 'recoil'

export const propertiesFilterAtom = atom({
	key: 'propertiesFilter',
	default: [
    {
      name: 'search',
      label: 'Search',
      value: '',
    },
    {
      name: 'city',
      label: 'City',
      value: '{}',
    },
    {
      name: 'neighborhoods',
      label: 'Neighborhoods',
      value: [],
    },
    {
      name: 'zipCodes',
      label: 'Zip Codes',
      value: [],
    },
    {
      name: 'minPrice',
      label: 'Min. Price',
      value: null,
    },
    {
      name: 'maxPrice',
      label: 'Max. Price',
      value: '',
    },
    {
      name: 'bed',
      label: 'Bedroom Size',
      value: [],
    },
    {
      name: 'bath',
      label: 'Bathrooms',
      value: '{}',
    },
    {
      name: 'sqFt',
      label: 'Size',
      value: '',
    },
    {
      name: 'unitFeatures',
      label: 'Unit Features',
      value: [],
    },
    {
      name: 'availFrom',
      label: 'Available From',
      value: null,
    },
    {
      name: 'yearFrom',
      label: 'Year Built From',
      value: null,
    },
    {
      name: 'yearTo',
      label: 'Year Built To',
      value: null,
    },
    {
      name: 'availTo',
      label: 'Available To',
      value: null,
    },
    {
      name: 'bonus',
      label: 'Bonus',
      value: null,
    },
    {
      name: 'minSend',
      label: 'Send Escort',
      value: null,
    },
    {
      name: 'escort',
      label: 'Escort',
      value: null,
    },
    {
      name: 'amenities',
      label: 'Amenities',
      value: [],
    },
    {
      name: 'policies',
      label: 'Policies',
      value: [],
    },
    {
      name: 'googleRating',
      label: 'Google Rating',
      value: null,
    },
    {
      name: 'propertyIds',
      label: 'Property Ids',
      value: null,
    },
    {
      name: 'renovatedFrom',
      label: 'Renovated After',
      value: null,
    },
  ]
})

export const allPropertiesAtom = atom({
  key: 'allProperties',
  default: [], 
});

export const selectedPropertiesAtom = atom({
  key: 'selectedProperties',
  default: [], 
});

export const selectedPropertyArrayAtom = atom({
  key: 'selectedPropertyArray',
  default: [], 
});

export const propertiesPaginationInfoAtom = atom({
  key: 'propertiesPaginationInfo',
  default: {
    isFirstRequest: true,
    currentPage: 0,
    isNextPage: false,
    isPreviousPage: false,
    pageSize: 10,
    totalPage: null,
    hasNextPage: false,
    hasPreviousPage: false,
  }, 
});

export const propertiesPageInfoAtom = atom({
  key: 'propertiesPageInfo',
  default: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  }, 
});