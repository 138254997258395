import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useHistory, useParams } from 'react-router-dom';
import SidebarRight from '../../components/SidebarRight';
import { CITIES, CITY_DELETE, STATES } from '@api';
import { Icon } from '@assets';
import { useQuery, useMutation } from '@apollo/client';
import Spinner from '../Spinner';
import RTable from '../../components/RTable';
import CityForm from './CityForm';
import { toast } from 'react-toastify';

export default function Cities(props) {
  const { data: cityData, loading: cityLoading, error } = useQuery(CITIES);
  let { id, operation } = useParams();
  const [sidebarState, setSidebarState] = useState(operation ? true : false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [cityId, setCityId] = useState(null);
  const [cityName, setCityName] = useState(null);
  useEffect(() => {
    sidebarState ? !id && history.push(`/city/new`) : history.push(`/cities`);
  }, [sidebarState, operation]);
  function neighborhood(value) {
    return (
      <Badge
        pill
        className='ml-2 p-2 mt-1'
        style={{ backgroundColor: 'var(--gray-500)' }}
      >
        {value}
      </Badge>
    );
  }
  const handleSingleDelete = (id, name) => {
    setDeleteModal(true);
    setIsProgress(false);
    setCityName(name);
    setCityId(id);
  };
  const deleteCity = async () => {
    setIsProgress(true);
    await citySingleDelete({
      variables: {
        ids: Number(cityId),
      },
    });
    setIsProgress(!isProgress);
  };
  useQuery(STATES);
  const [citySingleDelete] = useMutation(CITY_DELETE, {
    onCompleted: (data) => {
      setDeleteModal(!deleteModal);
      toast(data.cityMultiDelete.message, { type: 'success' });
    },
    refetchQueries: [CITIES, STATES],
    onError: (e) => {
      console.log(e);
    },
  });
  const history = useHistory();
  const columns = React.useMemo(
    () => [
      {
        Header: 'Market Name',
        accessor: 'name',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'State',
        accessor: 'state.name',
        maxWidth: 100,
        minWidth: 100,
        width: 100,
        Cell: ({ value, row }) => <div>{value ? value : '-'}</div>,
      },
      {
        Header: 'Neighborhoods',
        accessor: 'neighborhoods',
        maxWidth: 250,
        minWidth: 250,
        width: 250,
        Cell: ({ value }) => (
          <div>
            {value && value.length > 0
              ? value.map((neighborhoods) => neighborhood(neighborhoods.name))
              : '-'}
          </div>
        ),
      },
      {
        Header: 'Can Visible',
        accessor: 'isVisible',
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: ({ value }) => <div>{value === true ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Time Zone',
        accessor: 'timeZone',
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: ({ value, row }) => <div>{value}<br/>{row.original.jsTimeZone}</div>,
      },
      {
        Header: 'Action',
        accessor: 'id',
        disableSortBy: true,
        maxWidth: 50,
        minWidth: 50,
        width: 50,
        Cell: ({ value, row }) => (
          <div>
            <UncontrolledButtonDropdown>
              <DropdownToggle caret>
                <Icon icon='bars' />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSidebarState(true);
                    history.push(`/city/edit/${value}`);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => handleSingleDelete(value, row.original.name)}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
    ],
    []
  );
  return (
    <>
      <Row className='pt-3 pt-md-5'>
        <Col md='12'>
          <h2>Markets list</h2>
        </Col>
      </Row>
      <SidebarRight
        sidebarState={sidebarState}
        sidebarToggle={() => {
          setSidebarState(!sidebarState);
        }}
        sidebarWidth='400px'
        sidebarContent={
          <CityForm
            operation={operation}
            sidebarToggle={() => {
              setSidebarState(!sidebarState);
            }}
            {...props}
          />
        }
      />
      {/* <Row className="mt-md-4 mt-4"> */}
      {cityLoading && <Spinner />}
      <Modal isOpen={deleteModal} toggle={() => setDeleteModal(!deleteModal)}>
        <ModalHeader toggle={() => setDeleteModal(!deleteModal)}>
          Cancel Confirmation
        </ModalHeader>
        <ModalBody>Are you sure you want to cancel {cityName} Market?</ModalBody>
        <ModalFooter>
          <Button color='danger' disabled={isProgress} onClick={deleteCity}>
            Yes
          </Button>
          <Button onClick={() => setDeleteModal(!deleteModal)}>No</Button>
        </ModalFooter>
      </Modal>
      <Row className='mt-md-2'>
        <Col md='12' className=''>
          {cityData?.cities.length > 0 ? (
            <RTable
              columns={columns}
              data={cityData?.cities}
              filterable
              /* addons={
              <Button
                color='green'
                className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
                onClick={() => {
                  setSidebarState(true);
                  history.push(`/users/edit/${value}`);
                }}
              >
                <Icon icon='plus' className='mr-2' />
                Add
              </Button>
            } */
            />
          ) : (
            <>
              {cityData?.cities.length === 0 ? (
                <h2 className='text-center mt-5'>Market not found</h2>
              ) : null}
            </>
          )}
          {/* <Row>
			<Col md="6">
				<div className="month-plan pricing-plan-box">
					<h2>Monthly</h2>
					<span className="big-text">$59.<sup>99</sup></span>
					<Button color="primary">Choose Plan</Button>
				</div>
			</Col>

			<Col md="6">
				<div className="year-plan pricing-plan-box">
					<h2>Yearly</h2>
					<span className="big-text">$599</span>
					<Button color="green">Choose Plan</Button>
				</div>
			</Col>
		</Row> */}

          {/* <Table borderless responsive className="client-list text-left min-w-900">
			<thead>
				<tr>
					<th>Name</th>
					<th>Amount</th>
					<th>Stripe Plan Id</th>
				</tr>
			</thead>
			<tbody>
				{
					plans.map(({ id, amount, interval, name, stripe_plan_id }, i) => (
						<tr key={id} >
							<td>{name}</td>
							<td>${amount}/{interval}</td>
							<td>{stripe_plan_id}</td>
						</tr>
					))
				}
			</tbody>
		</Table> */}
        </Col>
      </Row>
      {/* </Row> */}
    </>
  );
}
