import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupText,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from 'reactstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import {
  PLANS,
  ALL_CITIES,
  UPDATE_USER_SUBSCRIPTION,
  SUBSCRIPTION_CREATE_DIRECT,
  SEARCH_USERS,
	SUBSCRIPTION_DELETE
} from '@api';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { ToggleButton } from '@sparklib';
import { Icon } from '@assets';

export default function SubscriptionForm(props) {
  const [progress, setProgress] = useState(false);
  const [planError, setPlanError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const { id: userId } = useParams();
  const [form_errors, setErrors] = useState(null);
  const [maxCities, setMaxCities] = useState(null);
  function dateWithMonthsDelay(day) {
    const date = new Date();
    date.setDate(date.getDate() + day);
    return date;
  }
  const newSubscription = {
    isTrial: false,
    id: null,
    userId: userId,
    planId: null,
    currentStartDatetime: new Date(),
    currentEndDatetime: dateWithMonthsDelay(3),
    active: true,
    name: null,
    interval: null,
    amount: null,
    coupon: '',
  };

  const [subscriptionData, setSubscriptionData] = useState(newSubscription);
  const { data: planData } = useQuery(PLANS);
  const { data: allCityData } = useQuery(ALL_CITIES);
  const [selectedCities, setSelectedCities] = useState([]);

  const changePlan = (label, value) =>
    setSubscriptionData((subscriptionData) => {
      return { ...subscriptionData, [label]: value };
    });

  const [subscriptionCreateDirect] = useMutation(SUBSCRIPTION_CREATE_DIRECT, {
    onCompleted: (data) => {
      setProgress(false);
      if (
        data.subscriptionCreateDirect.errors &&
        data.subscriptionCreateDirect.errors.length > 0
      ) {
        setErrors(data.subscriptionCreateDirect.message);
      } else {
        props.setSubscriptions([
          ...props.subscriptions,
          data.subscriptionCreateDirect.subscription,
        ]);
        toast(data.subscriptionCreateDirect.message, { type: 'success' });
        setErrors(null);
        setSubscriptionData(newSubscription);
        props.newForm();
      }
    },
    refetchQueries: [SEARCH_USERS],
    onError: (e) => {
      setProgress(false);
      console.log(e, 'error');
    },
  });

  useLazyQuery(SEARCH_USERS);

  const [subscriptionUpdate] = useMutation(UPDATE_USER_SUBSCRIPTION, {
    onCompleted: (data) => {
      setProgress(false);
      if (
        data.subscriptionUpdate.errors &&
        data.subscriptionUpdate.errors.length > 0
      ) {
        setErrors(data.subscriptionUpdate.message);
      } else {
        toast(data.subscriptionUpdate.message, { type: 'success' });
        setErrors(null);
        props.newForm();
        let updatedData = [...props.subscriptions];
        setSubscriptionData(newSubscription);
        let currentIndex = null;
        updatedData.find((data, index) => {
          if (data.id === String(subscriptionData.id)) {
            currentIndex = index;
            return index;
          }
        });
        updatedData.splice(
          currentIndex,
          1,
          data.subscriptionUpdate.subscription
        );
        props.setSubscriptions(updatedData);
      }
    },
    refetchQueries: [SEARCH_USERS],
    onError: (e) => {
      setProgress(false);
      console.log(e, 'error');
    },
  });

	const [showModal, setShowModal] = useState(false);

	const [subscriptionDelete] = useMutation(SUBSCRIPTION_DELETE, {
    onCompleted: (data) => {
      if (
        data.subscriptionDelete.errors &&
        data.subscriptionDelete.errors.length > 0
      ) {
        setShowModal(false);
        toast(data.subscriptionDelete.message, { type: 'error' });
        setProgress(false);
      } else {
        setShowModal(false);
        toast(data.subscriptionDelete.message, { type: 'success' });
        setProgress(false);

				props.newForm();
        let updatedData = [...props.subscriptions];
        setSubscriptionData(newSubscription);
        let currentIndex = null;
        updatedData.find((data, index) => {
          if (data.id === String(subscriptionData.id)) {
            currentIndex = index;
            return index;
          }
        });
        updatedData.splice(
          currentIndex,
          1,
          data.subscriptionDelete.subscription
        );
        props.setSubscriptions(updatedData);
      }
    },
    // refetchQueries: [USER],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

	const cancelSubscription = async () => {
		setProgress(true);
    await subscriptionDelete({
      variables: {
        id: Number(subscriptionData.id),
      },
    });
  };

  useEffect(() => {
    if (subscriptionData.planId) {
      setPlanError(false);
    }
  }, [subscriptionData.planId]);
  useEffect(() => {
    if (selectedCities.length > 0) {
      setCityError(false);
    }
  }, [selectedCities]);

  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const onSubmitSubscriptiion = async (data, e) => {
    if (!subscriptionData.planId) {
      setPlanError(true);
    }
    if (selectedCities.length === 0) {
      setCityError(true);
    }

    if (!subscriptionData.planId || selectedCities.length === 0) {
      return false;
    }
    setProgress(true);
    if (!props.currentSubscription) {
      //delete subscriptionData.coupon;
      await subscriptionCreateDirect({
        variables: {
          ...subscriptionData,
          cityIds: selectedCities.map((city) => Number(city.value)),
          planId: Number(subscriptionData.planId),
          userId: Number(subscriptionData.userId),
          currentStartDatetime: new Date(subscriptionData.currentStartDatetime),
          currentEndDatetime: new Date(subscriptionData.currentEndDatetime),
        },
      });
    } else {
      await subscriptionUpdate({
        variables: {
          ...subscriptionData,
          cityIds: selectedCities.map((city) => Number(city.value)),
          planId: Number(subscriptionData.planId),
          userId: Number(subscriptionData.userId),
          id: Number(subscriptionData.id),
          currentStartDatetime: new Date(subscriptionData.currentStartDatetime),
          currentEndDatetime: new Date(subscriptionData.currentEndDatetime),
        },
      });
    }
  };

  useEffect(() => {
    if (planData) {
      const selectedPlan = planData.plans.find(
        (plan) => subscriptionData.planId === plan.id
      );
      if (selectedPlan) {
        setMaxCities(selectedPlan.maxCities);
        setSubscriptionData((subscriptionData) => {
          return {
            ...subscriptionData,
            amount: selectedPlan.amount,
            name: selectedPlan.name,
            interval: selectedPlan.interval,
						label: getStripePlan(selectedPlan)
          };
        });
      }
    }
		// console.log(planData, "-- planData");
  }, [subscriptionData.planId]);

  useEffect(() => {
    if (props.currentSubscription) {
      let selectedCity = props.currentSubscription.cities.map((city) => ({
        value: city.id,
        label: city.name,
      }));
      setSelectedCities(selectedCity);
      setMaxCities(props.currentSubscription.plan.maxCities);
      setSubscriptionData({
        ...subscriptionData,
        name: props.currentSubscription.plan.name,
        interval: props.currentSubscription.plan.interval,
        amount: props.currentSubscription.plan.amount,
        planId: props.currentSubscription.plan.id,
        active: props.currentSubscription.active,
        id: props.currentSubscription.id,
        currentStartDatetime: props.currentSubscription.currentStartDatetime,
        currentEndDatetime: props.currentSubscription.currentEndDatetime,
        isTrial: props.currentSubscription.isTrial,
      });
    }
  }, [props.currentSubscription]);

	function getStripePlan(plan){
		if (plan.stripePlan?.tiers?.length > 0){
			return plan.name +
				' (Starts At $' +
				plan.stripePlan.tiers[0].unit_amount / 100 +
				' / ' +
				plan.stripePlan.interval +
				')'
		}else {
			return plan.name +
				' ($' +
				plan.amount +
				' / ' +
				plan.interval +
				')'
		}
	}

  return (
    <>
      <div className='pl-2 pr-2 pb-4'>
        <Row>
					<Modal
						isOpen={showModal}
						toggle={() => setShowModal(!showModal)}
					>
						<ModalHeader toggle={() => setShowModal(!showModal)}>
							Cancel Confirmation
						</ModalHeader>
						<ModalBody>
							Are you sure you want to cancel <strong>{subscriptionData.name} Subscription?</strong>
						</ModalBody>
						<ModalFooter>
							<Button
								color='danger'
								onClick={cancelSubscription}
							>
								Yes
							</Button>
							<Button onClick={() => setShowModal(!showModal)}>No</Button>
						</ModalFooter>
					</Modal>
          <Col md='12'>
            {form_errors ? (
              <Col>
                <Col className='bg-danger mt-2 text-white'>{form_errors}</Col>
              </Col>
            ) : null}
          </Col>
          <Col md='12' className='mt-md-3 inputs-icons'>
            <Row>
              <Col md='12' className='panel-tabs'>
                <Form
                  onSubmit={handleSubmit(onSubmitSubscriptiion)}
                  id='create-user-form'
                >
                  <Row>
                    <Col lg='6'>
                      <FormGroup>
                        <Label for='planId'>Select Plan</Label>
                        <Select
                          name='PlanId'
                          value={
                            subscriptionData.planId
                              ? {
                                  value: `${subscriptionData.planId}`,
                                  label: `${subscriptionData.label}`,
                                }
                              : null
                          }
                          options={
                            typeof planData !== 'undefined'
                              ? Object.values(planData.plans).map((plan) =>
                                  JSON.parse(
                                    `{"value":"${plan.id}", "label":"${getStripePlan(plan)}"}`
                                  )
                                )
                              : []
                          }
                          onChange={(e) => changePlan('planId', e.value)}
                          className='basic-multi-select w-100 bedsize'
                          classNamePrefix='default-select'
                          placeholder='Stripe Plan'
													isDisabled={props.currentSubscription}
                        />
                        {planError && (
                          <p className='input-error'>Plan required</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg='6'>
                      <FormGroup className='text-left'>
                        <Label for='city'>Select Market</Label>
                        <Select
                          value={selectedCities}
                          name='city'
                          classNamePrefix='default-select'
                          isMulti={true}
                          isClearable={true}
                          placeholder={<>Select Market</>}
                          options={
                            typeof allCityData !== 'undefined'
                              ? Object.values(allCityData.allCities).map(
                                  (city) =>
                                    JSON.parse(
                                      `{"value":${city.id}, "label":"${city.name}"}`
                                    )
                                )
                              : []
                          }
                          onChange={(selectedCity) => {
                            maxCities === 1 ||
                            selectedCity.length <= maxCities ||
                            maxCities === -1
                              ? setSelectedCities(selectedCity)
                              : null;
                          }}
                          className='w-100'
                        />
                        {cityError && (
                          <p className='input-error'>City required</p>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg='6'>
                      <FormGroup>
                        <Label for='currentStartDatetime'>Start Date</Label>
                        <InputGroup>
                          <DatePicker
                            placeholderText='From'
                            className='form-control'
                            selected={Date.parse(
                              subscriptionData.currentStartDatetime
                            )}
                            onChange={(e) =>
                              changePlan(
                                'currentStartDatetime',
                                e.toLocaleDateString('en-US')
                              )
                            }
														readOnly={props.currentSubscription}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg='6'>
                      <FormGroup>
                        <Label for='currentEndDatetime'>End Date</Label>
                        <InputGroup>
                          <DatePicker
                            placeholderText='From'
                            className='form-control'
                            selected={Date.parse(
                              subscriptionData.currentEndDatetime
                            )}
                            onChange={(e) =>
                              changePlan(
                                'currentEndDatetime',
                                e.toLocaleDateString('en-US')
                              )
                            }
														readOnly={props.currentSubscription}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col lg='6' className='mb-3 d-flex align-items-center'>
                      <Label className='mb-0 mr-3'>Active</Label>
                      <Button color="primary" size="sm" disabled>
												{subscriptionData.active.toString().charAt(0).toUpperCase() + subscriptionData.active.toString().slice(1)}
      								</Button>
                    </Col>
                    <Col lg='6' className='mb-3 d-flex align-items-center'>
                      <Label className='mb-0 mr-3'>Is Trial</Label>
											<Button color="primary" size="sm" disabled>
												{subscriptionData.isTrial.toString().charAt(0).toUpperCase() + subscriptionData.isTrial.toString().slice(1)}
      								</Button>
                    </Col>
                    {props.currentSubscription?.stripeSubscriptionId && (
                      <Col lg='6'>
                        <FormGroup>
                          <Label for='licenseNo'>Coupon Code</Label>
                          <InputGroup>
                            <InputGroupText>
                              <Icon icon='credit-card' />
                            </InputGroupText>
                            <Input
                              name='coupon'
                              value={subscriptionData.coupon}
                              onChange={(e) =>
                                changePlan('coupon', e.target.value)
                              }
                              placeholder='Coupon code'
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    )}
                    <Col lg='12' className='mt-1'>
                      <Button
                        color='primary'
                        className='button-dashboard btn-sm'
                        disabled={progress}
                      >
                        {!props.currentSubscription ? 'Create ' : 'Update '}{' '}
                        Subscription
                      </Button>
											{
												props.currentSubscription && subscriptionData.active === true ?
												<Button
													color='danger'
													className='button-dashboard btn-sm ml-2'
													onClick={() => setShowModal(!showModal)}
												>
													Cancel Subscription
												</Button> : null
											}
                      <Button
                        className='button-dashboard ml-2 btn-sm'
                        onClick={() => props.newForm()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
