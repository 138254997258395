export default function bathTypes(floorPlans) {
  if (floorPlans?.length > 0) {
    const totalBathRooms = [];
    floorPlans.forEach((fp) => totalBathRooms.push(fp.bath));
    if (totalBathRooms.length > 0) {
      var minValue = Math.min(...totalBathRooms);
      var maxValue = Math.max(...totalBathRooms);
    }
    if (maxValue > 0) {
      return minValue === maxValue ? minValue : minValue + '-' + maxValue;
    } else {
      return '-';
    }
  } else {
    return '-';
  }
}
