import React, { useState, useEffect, useRef } from 'react';
import { Button, Table } from 'reactstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useRowSelect,
} from 'react-table';
import PropertyPagination from './PropertyPagination';
import ShowHideColumns from './ShowHideColumns';

import { useHistory, useParams } from 'react-router-dom';
import { Icon } from '@assets';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { setDefaultLocale } from 'react-datepicker';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type='checkbox'
          className='custom-checkbox'
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

export default function PropertyTable({
  columns,
  data,
  path,
  setSidebarState,
  setProperty,
  paginationInfo,
  setPaginationInfo,
  setShouldSearch,
  setOrderBy,
  selectedPropertyArray,
}) {


  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);


  // --- on scroll stick to top ---
  // // handle scroll event
  // const handleScroll = (elTopOffset, elHeight) => {
  //   // if (window.pageYOffset > (elTopOffset + elHeight)) {
  //   if (window.pageYOffset > (elTopOffset + elHeight)) {
  //     setSticky({ isSticky: true, offset: elHeight });
  //   } else {
  //     setSticky({ isSticky: false, offset: 0 });
  //   }
  // };

  // // add/remove scroll event listener
  // useEffect(() => {
  //   var header = headerRef.current.getBoundingClientRect();
  //   const handleScrollEvent = () => {
  //     handleScroll(header.top, header.height)
  //   }

  //   window.addEventListener('scroll', handleScrollEvent);

  //   return () => {
  //     window.removeEventListener('scroll', handleScrollEvent);
  //   };
  // }, []);


  const [propertyAllColumns, setPropertyAllColumns] = useState([]);
  const [propertyVisibleColumn, setPropertyVisibleColumn] = useState([]);
	const [showSetViewBtn, setShowSetViewBtn] = useState(false);
  const history = useHistory();
  const defaultColumn = React.useMemo(
    () => ({
      width: 'auto',
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    allColumns,
    visibleColumns,
    setHiddenColumns,

    prepareRow,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualSortBy: true,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        hiddenColumns: ['specials'],
      },
      autoResetPage: false,
      autoResetHiddenColumns: false,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
			hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  useEffect(() => {
    setOrderBy(sortBy);
    setShouldSearch(true);
    setPaginationInfo({ ...paginationInfo, isFirstRequest: true });
    /* onSort({ sortBy, pageIndex, pageSize });
    fetchData({ pageIndex, pageSize }); */
  }, [sortBy]);

  useEffect(() => {
    const optionss = allColumns
      .filter((column) => column.Header && typeof column.Header === "string")
      .map((column) => ({
        value: column.id,
        label: column.Header,
        visible: true,
      }));
    setPropertyAllColumns(optionss);
  }, [allColumns]);

  useEffect(() => {
    let visibleOptions = [];
    visibleColumns.map((column) => {
      if (column.Header.length > 0) {
        visibleOptions.push(column.id);
      }
    });
    setPropertyVisibleColumn(visibleOptions);
  }, [visibleColumns]);

  const updateHiddenColumns = (selectShowColumn) => {
    setHiddenColumns((hiddenColumns) => {
      return hiddenColumns.find((column) => column === selectShowColumn.value)
        ? hiddenColumns.filter((column) => column !== selectShowColumn.value)
        : [...hiddenColumns, selectShowColumn.value];
    });
		setShowSetViewBtn(true);
  };

	function setDefaultVisibleColumns(){
		localStorage.setItem("visible_columns", JSON.stringify(propertyVisibleColumn));
		setShowSetViewBtn(false);
	}

	useEffect(() => {
		var defaultVisibleCols = JSON.parse(localStorage.getItem("visible_columns"));

		if (defaultVisibleCols?.length > 0){
			var columnsToRemove = [];
			columns.forEach(c => {
				if (c.id && !defaultVisibleCols.includes(c.id)) columnsToRemove.push(c.id);
			})
			setHiddenColumns(columnsToRemove);
		}
	}, [columns]);

  return (
    <>
      {/* {allColumns.map((column) => (
        <div key={column.id}>
          <label>
            <input type='checkbox' {...column.getToggleHiddenProps()} />{' '}
            {column.Header}
          </label>
        </div>
      ))} */}

			{	showSetViewBtn ?
				<Button
					color='green'
					onClick={setDefaultVisibleColumns}
					className='default-cols btn button-dashboard'
				>
					Save View
				</Button> : null
			}
			<ShowHideColumns
				options={propertyAllColumns}
				onChangeCallback={(response) => updateHiddenColumns(response)}
				visibleColumns={propertyVisibleColumn}
			/>

      <Table
        responsive
        /* className="client-list text-left min-w-900" */
        className='min-w-900 text-left table-fixed'
        {...getTableProps()}
      >
      {/* --- on scroll stick to top ---
      <thead className={`${sticky.isSticky ? 'sticky_top' : ''}`} ref={headerRef}> */}
        <thead>
          {headerGroups.map((headerGroup, j) => (
            <tr key={j} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: column.className,
                      style: {
                        // minWidth: column.minWidth,
                        // width: column.width,
                        // maxWidth: column.maxWidth,
                        textAlign: column.textAlign,
                      },
                    },
                    column.getSortByToggleProps(),
                  ])}
                  //onClick={() => }
                  key={i}
                >
                  {column.render('Header')}

                  <span>
                    {/* {column.Header === 'Community Name' ? 'A' : 'b'} */}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <>
                          &nbsp; <Icon icon='sort-down' size='lg' />
                        </>
                      ) : (
                        <>
                          &nbsp; <Icon icon='sort-up' size='lg' />
                        </>
                      )
                    ) : column.canSort ? (
                      <>
                        &nbsp; <Icon icon='sort' size='lg' />
                      </>
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            if (!row.original.highlight) {
              let isSelected = selectedPropertyArray.find(
                (property) =>
                  Number(property.node.id) === Number(row.original.node.id)
              );

              if (isSelected) {
                return null;
              }
            }
            prepareRow(row);
            let bgColor = row.original.highlight ? 'var(--silver)' : '';
            return (
              <tr
                style={{ cursor: 'pointer', backgroundColor: bgColor }}
                {...row.getRowProps()}
                onClick={() => {
                  setSidebarState(true);
                  setProperty(row.original.node);
                  history.push(`/${path}/${row.original.node.city.slug}/${row.original.node.neighborhood.slug}/${row.original.node.slug}`);
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>

      <PropertyPagination
        paginationInfo={paginationInfo}
        setPaginationInfo={setPaginationInfo}
        setShouldSearch={setShouldSearch}
      />
    </>
  );
}
