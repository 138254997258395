import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import {
  GoogleMap,
  OverlayView,
  LoadScriptNext,
  DrawingManager,
} from '@react-google-maps/api';
import { Spinner, Button } from 'reactstrap';
import PlaceMarker from './../Property/Properties/Map/PlaceMarker';

const noPoi = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];
const defaultMapOptions = {
  fullscreenControl: false,
  styles: noPoi,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  preserveViewport: true,
  suppressMarkers: true,
  zoomControl: false,
};

function ReportMap(props) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentId, setCurrentId] = useState();
  const [latlongs, setLatlongs] = useState([]);
  const [map, setMap] = useState(null);
  const [isViewed, setIsViewed] = useState([]);
  const [isDrawingMode, setIsDrawingMode] = useState(false);
  const [polygons, setPolygons] = useState([]);
  const [latLongInsidePolygon, setLatLongInsidePolygon] = useState(
    props.latlongs
  );
  const polygonRef = useRef(null);

  useEffect(() => {
    if (props.latlongs.length > 0 && !_.isEqual(props.latlongs, latlongs)) {
      setLatlongs(props.latlongs);
    }
  }, [props.latlongs]);

  const containerStyle = {
    height: props.height,
  };

  const fitBounds = (map) => {
    if (map && props.latlongs && props.latlongs.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      props.latlongs.map((model) => {
        if (model.lat && model.long) {
          let pos = { lat: model.lat, lng: model.long };
          bounds.extend(pos);
        }
      });
      map.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (latlongs.length > 0 && map && props.activeTab === 'map') {
      fitBounds(map);
    }
  }, [latlongs, map, props.activeTab]);

  const loadHandler = (map) => {
    // Store a reference to the google map instance in state
    // Fit map bounds to contain all markers
    setMap(map);
    fitBounds(map);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  function arrangeMapMarkers() {
    if (polygons.length > 0 && latlongs) {
      var boundedLatLng = [];
      polygons.forEach((polygon) => {
        var temp = latlongs.filter((e) =>
          checkIfLatLngInPolygon(e.lat, e.long, polygon)
        );
        boundedLatLng = _.uniq(boundedLatLng.concat(temp));
      });
      // if (latlongs.length === latLongInsidePolygon?.length){
      setLatLongInsidePolygon(boundedLatLng);
      // } else{
      // 	setLatLongInsidePolygon((latLongInsidePolygon) => _.uniq(boundedLatLng.concat(latLongInsidePolygon)));
      // }
      setIsDrawingMode(false);
    }
  }

  const checkIfLatLngInPolygon = (lat, lng, polygon) => {
    if (!polygon) return false;
    const path = polygon.getPath();
    let inside = false;
    let x = lat,
      y = lng;
    for (let i = 0, j = path.getLength() - 1; i < path.getLength(); j = i++) {
      let xi = path.getAt(i).lat(),
        yi = path.getAt(i).lng();
      let xj = path.getAt(j).lat(),
        yj = path.getAt(j).lng();
      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
    return inside;
  };

  eventListerFunction();
  // Listen to polygon drag and edit events
  function eventListerFunction() {
    polygons.forEach(function (polygon) {
      var path = polygon.getPath();

      google.maps.event.addListener(path, 'set_at', function () {
        updatePolygon(polygon, path, true);
      });
      google.maps.event.addListener(path, 'insert_at', function () {
        updatePolygon(polygon, path);
      });
      google.maps.event.addListener(polygon, 'dragend', function () {
        updatePolygon(polygon, path);
      });
    });
  }

  function updatePolygon(polygon, path, wait = false) {
    var newPaths = [];

    // Get the new paths of the polygon
    for (var i = 0; i < path.getLength(); i++) {
      var latLng = path.getAt(i);
      newPaths.push(latLng);
    }

    // Find the index of the polygon in the polygons
    var index = -1;
    for (var i = 0; i < polygons.length; i++) {
      if (polygons[i] === polygon) {
        index = i;
        break;
      }
    }

    polygons[index].setPath(newPaths);
    if (wait) {
      setTimeout(() => {
        arrangeMapMarkers();
      }, 3000);
    } else {
      arrangeMapMarkers();
    }
  }

  useEffect(() => {
    if (polygons.length > 0) {
      arrangeMapMarkers();
    }
  }, [polygons]);

  const handlePolygonComplete = (event) => {
    // if (event.type !== google.maps.drawing.OverlayType.POLYGON) {
    //   return;
    // }
    // if (polygonRef.current) {
    //   google.maps.event.clearInstanceListeners(polygonRef.current);
    //   polygonRef.current.setMap(null);
    // }
    polygonRef.current = event.overlay;
    setPolygons((polygons) => [...polygons, polygonRef.current]);
  };

  function displayMarkers() {
    if (latLongInsidePolygon.length > 0 && map) {
      return latLongInsidePolygon.map((item, i) => {
        return (
          <OverlayView
            position={{ lat: item?.lat, lng: item?.long }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            key={i}
          >
            <PlaceMarker
              reportCode={props.reportData?.report?.reportCode}
              reportId={props.reportData?.report?.id}
              reportProperties={props.reportProperties}
              rating={
                (props.reportData?.report?.reportProperties).find(
                  (rp) =>
                    rp.propertyId === Number(item?.property.id) &&
                    rp.floorPlanId === null &&
                    rp.typeDetailId === null
                )?.clientRating
              }
              notes={
                (props.reportData?.report?.reportProperties).find(
                  (rp) =>
                    Number(rp.propertyId) === Number(item?.property.id) &&
                    rp.floorPlanId === null &&
                    rp.typeDetailId === null
                )?.clientNotes
              }
              tooltip={item?.title}
              property={item?.property}
              showTooltip={showTooltip}
              setShowTooltip={setShowTooltip}
              currentId={currentId}
              setCurrentId={setCurrentId}
              isViewed={isViewed}
              setIsViewed={setIsViewed}
              setSidebarState={props.setSidebarState}
              setProperty={props.setProperty}
              path={props.path}
              is_show={props.is_show}
              showPropertyDetail={props.showPropertyDetail}
              isReportMap={true}
            />
          </OverlayView>
        );
      });
    }
  }

  return (
    <div className='gmap-container'>
      {/* {
				<Button
					className='gmap-button'
					onClick={() => setIsDrawingMode(!isDrawingMode)}
				>
						{isDrawingMode ? 'Stop' : 'Draw'}
				</Button>
			} */}
      <LoadScriptNext
        id='script-loader'
        // googleMapsApiKey={'AIzaSyD-Vwu6LSsJscOPVKsSDvVse9NFlA3U7d4'}
        googleMapsApiKey={'AIzaSyC1BgEh0QjPcEiFz8GDw_L87UYf__IYXTM'}
        language='en'
        region='EN'
        libraries={['drawing', 'places']}
        loadingElement={
          <div className='text-center' style={{ margin: '35vh 0' }}>
            <Spinner type='grow' color='secondary'></Spinner>
          </div>
        }
      >
        {props.latlongs && (
          <GoogleMap
            mapContainerStyle={containerStyle}
            onLoad={loadHandler}
            id='google-map-script'
            onClick={() => {
              setCurrentId(null);
            }}
            onUnmount={onUnmount}
            options={defaultMapOptions}
          >
            {isDrawingMode && (
              <DrawingManager
                drawingMode={'polygon'}
                options={{
                  drawingControl: true,
                  draggable: true,
                  drawingControlOptions: {
                    //style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    drawingModes: ['polygon'],
                    position: 2,
                    //onDragEnd={(event) => console.log(event.latLng.toString())}
                  },
                  polygonOptions: {
                    id: Math.floor(Math.random() * 1000 + 1),
                    fillColor: '#199ee0',
                    fillOpacity: 0.2,
                    strokeWeight: 2,
                    strokeColor: '#113460',
                    clickable: false,
                    editable: true,
                    draggable: true,
                    geodesic: false,
                    visible: true,
                    zIndex: 10000,
                  },
                }}
                // onOverlayComplete={handleOverlayComplete}
                onOverlayComplete={(event) => {
                  handlePolygonComplete(event);
                }}
              />
            )}
            {displayMarkers()}
          </GoogleMap>
        )}
      </LoadScriptNext>
    </div>
  );
}
export default React.memo(ReportMap);
