import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledCollapse,
  CardBody,
  Button,
  Card,
} from 'reactstrap';
import { Icon } from '@assets';
import { unitTypes, pastDate, ImageModal } from '@sparklib';
import classnames from 'classnames';
import CustomCollapse from './CustomCollapse';
import ClientRating from './../../Recommendation/ClientRating';
export default function TabList({
  property,
  selectedProperties,
  setSelectedProperties,
  reportId,
  reportCode,
  reportProperties,
  pageName,
  selectedFpUnits,
  selectedFloorPlans,
	cleintReportView=false
}) {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    /* style: 'currency', */
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const [planName, setPlanName] = useState(null);
  const [name, setName] = useState('gboxs');
  const [showUnavailableFloorPlans, setShowUnavailableFloorPlans] =
    useState(false);

  let groupedFloorPlans = property
    ? groupFloorPlansArray(property.floorPlans, 'bed')
    : [];

  let defaultTab = Object.keys(unitTypes).find((type) =>
    Object.keys(groupedFloorPlans).find((groupedType) => type === groupedType)
      ? true
      : false
  );

  const [activeTab, setActiveTab] = useState(unitTypes[defaultTab] || '');

  const toggle = (tab) => {
    setShowUnavailableFloorPlans(false);
    if (activeTab !== tab) setActiveTab(tab);
  };

  function isAvailableCount(data) {
    let availableData = [];
    data.map(function (record) {
      if (record.isAvailable === true) {
        availableData.push(record);
      }
    });
    return availableData;
  }

  function toggleImage(img, planName) {
    setIsOpen(!isOpen);
    setImgSrc(img);
    setPlanName(planName);
  }

  function getFloorPlanPrice(minPrice, maxPrice) {
    if (minPrice && maxPrice && minPrice === maxPrice) return `$${minPrice}`;
   	else if (minPrice < maxPrice) return `$${minPrice}+`;
    else return 'Call For Rent';
    
  }

  function getFilteredFloorPlans(floorPlansArray, condition) {
    if (condition === 'UnAvailable') {
      return floorPlansArray?.filter(
        (fp) => fp.units.length == 0 && fp.isAvailable === false
      ).sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
			});
    } else {
      let availableFPWithUnits = floorPlansArray?.filter(
        (fp) => fp.units.length > 0 && fp.isAvailable === true
      );
      let availableFPWithoutUnits = floorPlansArray?.filter(
        (fp) => fp.units.length === 0 && fp.isAvailable === true
      );
      let joinFloorPlans = availableFPWithUnits?.concat(availableFPWithoutUnits);
			return joinFloorPlans?.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
			});
    }
  }

  function getMinFloorPrice(minFloorPrice) {
    let filter_arr = _.filter(minFloorPrice, function (floor) {
      return floor.price > 0 && floor.isAvailable;
    });
    if (filter_arr.length > 0) {
      var allPrice = [];
      filter_arr.forEach((element) => {
        allPrice.push(element.price);
      });

      let minval = _.minBy(filter_arr, 'price');
      if (allPrice.every((val, i, arr) => val === arr[0])) {
        return '$' + currencyFormatter.format(minval.price);
      } else {
        return '$' + currencyFormatter.format(minval.price) + '+';
      }
    } else {
      return 'Call For Rent';
    }
  }

  function isSelected(propertyId, item, unitId = null) {
    let response = false;
    let propertyCheck = selectedProperties.filter(
      (property) => Number(property.floorPlanId) === Number(item)
    );
    if (propertyCheck) {
      propertyCheck.filter((p) => {
        if (unitId && Number(p.typeDetailId) === Number(unitId)) {
          response = true;
        } else if (Number(p.floorPlanId) === Number(item) && unitId === null) {
          response = true;
        }
      });
    }
    return response;
  }

  function selectedProperty(propertyId, floorPlanId = null, unitId = null) {
    let newArray = [...selectedProperties];
    const property = {};
    property.propertyId = Number(propertyId);
    property.floorPlanId = Number(floorPlanId);
    property.typeDetailId = unitId ? Number(unitId) : null;

    if (unitId) {
      // If unitID hash present, remove
      // else, add hash.... and remove floorplan, remove property
      let checkNewArray = newArray.find(
        (property) => Number(property.typeDetailId) === Number(unitId)
      );

      if (checkNewArray === undefined) {
        newArray.push(property);
        newArray = newArray.filter((property) => {
          if (
            (Number(property.floorPlanId) === Number(floorPlanId) &&
              property.typeDetailId === null) ||
            (Number(property.propertyId) === Number(propertyId) &&
              Number(property.floorPlanId) === Number(floorPlanId) &&
              property.typeDetailId === null)
          ) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        let newArrayValue = newArray.filter(
          (property) => Number(property.typeDetailId) != Number(unitId)
        );
        newArray = newArrayValue;
      }
    } else if (floorPlanId) {
      // if floorplanID hash present, remove floorplan hash
      // else, add floorPlanID hash and remove property hash
      let checkNewArray = newArray.find(
        (property) => Number(property.floorPlanId) === Number(floorPlanId)
      );

      if (checkNewArray === undefined) {
        newArray.push(property);
        newArray = newArray.filter((property) => {
          if (
            Number(property.propertyId) === Number(propertyId) &&
            property.floorPlanId === null &&
            property.typeDetailId === null
          ) {
            return false;
          } else {
            return true;
          }
        });
      } else {
        let newArrayValue = newArray.filter(
          (property) => Number(property.floorPlanId) != Number(floorPlanId)
        );
        newArray = newArrayValue;
      }
    } else {
      // if propertyid hash present, remove all property, floorplan, typedetail hash
      // else add propretyId hash
      let checkNewArray = newArray.find(
        (property) => Number(property.propertyId) === Number(propertyId)
      );

      if (checkNewArray === undefined) {
        newArray.push(property);
      } else {
        let newArrayValue = newArray.filter(
          (property) => Number(property.propertyId) != Number(propertyId)
        );
        newArray = newArrayValue;
      }
    }

    setSelectedProperties(newArray);
  }

  function toggleShowUnavailableFloorPlans() {
    setShowUnavailableFloorPlans(!showUnavailableFloorPlans);
  }

  function groupFloorPlansArray(floorPlans, key) {
    return floorPlans.reduce(function (groupedFloorPlans, floorPlan) {
      (groupedFloorPlans[floorPlan['bed']] =
        groupedFloorPlans[floorPlan['bed']] || []).push(floorPlan);
      return groupedFloorPlans;
    }, {});
  }

  return (
    <Row>
      <Col md='12' className='table-tabs'>
        <Nav tabs>
          {Object.keys(unitTypes).map((type) => (
            <NavItem key={type}>
              <NavLink
                disabled={!groupedFloorPlans[type]?.length > 0}
                className={classnames({
                  active: activeTab === unitTypes[type],
                })}
                onClick={() => {
                  toggle(unitTypes[type]);
                }}
              >
                {unitTypes[type].toUpperCase()}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTab}>
          {Object.keys(unitTypes).map((type) => (
            <TabPane tabId={unitTypes[type]} key={type}>
              <Row>
                <Col
                  md='12'
                  className='apartment-table text-center mb-4 borderless'
                >
                  <Row>
                    {getFilteredFloorPlans(groupedFloorPlans[type])?.map(
                      (item, index) => (
                        <Col md='6' key={index} className='mt-2'>
                          <Card className='rounded-medium mb-2'>
                            <CardBody className='p-0'>
                              {/* <Row> */}
                                <Col md='12'>
                                  <Row>
                                    <Col
                                      md={
                                        item.plan2d || item.virtualTourLink
                                          ? '9'
                                          : '12'
                                      }
                                    >
                                      <div className='px-2 py-3'>
                                        <h4 className='text-left'>
																					<div className=' color-green'>
																					{item.name.match(/^AUTO.*/) ? (
																						''
																					) : (
																						<>
																							<span>{item.name}</span>

																							{/* <span className='mx-2'>·</span> */}
																						</>
																					)}
																					</div>
                                        </h4>
                                        <div className='text-left'>
																					<h5>
																						{/* {item.isAvailable === true &&
																						item.units.length === 0 ? (
																							<b className=''>
																								{getFloorPlanPrice(
																									item.rentMin,
																									item.rentMax
																								)}
																							</b>
																						) : (
																							<b className=''>
																								{getMinFloorPrice(item.units)}
																							</b>
																						)} */}
																						<b className=''>
																							{getFloorPlanPrice(item.rentMin, item.rentMax)}
																						</b>
																						&nbsp;
																						<small>{item.units?.filter(u => u.isAvailable).length === 0 && pastDate(item.availableFrom, true)}</small>
																						<br/>
																						<small>{unitTypes[type]}</small>
																						&nbsp;
																						<small>{Number(item.bath) !== 0 && `${item.bath} Bath`}</small>
																						&nbsp;
																						{(Number(item.sqftMin) !== 0) &&
																							<>
																								<small>
																									{currencyFormatter.format(
																										Number(item.sqftMin)
																									)}{' '}
																									sqft
																									<sup>2</sup>{' '}
																								</small>
																							</>
																						}
																					</h5>
                                        </div>
                                        {/* <hr className='mt-2 mb-0' /> */}
                                      </div>
                                    </Col>
                                    {/* {(item.plan2d || item.virtualTourLink) && ( */}
																			{item.plan2d && (
                                      <Col md='3' className='p-0'>
                                        <div
                                          style={{
                                            height: '120px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {item.plan2d && (
                                            <img
                                              className='plan-image'
                                              id={`togglerImage${item.id}`}
                                              src={item.plan2d}
                                              onClick={(e) =>
                                                toggleImage(
                                                  item.plan2d,
                                                  item.name
                                                )
                                              }
                                            />
                                          )}
                                          {/* {item.virtualTourLink && (
                                            <>
                                              <Icon icon='video' />
                                              <a
                                                href={item.virtualTourLink}
                                                target='_blank'
                                              >
                                                {' '}
                                                Virtual Tour
                                              </a>
                                            </>
                                          )} */}
                                        </div>
                                      </Col>
                                    )}
                                  </Row>
                                  {pageName === 'showProperty' ? (
                                    <div
                                      onClick={(e) => {
                                        selectedProperty(property.id, item.id);
                                      }}
                                      className='client-rating-notes-icon'
                                    >
                                      {isSelected(property.id, item.id) ===
                                      true ? (
                                        <Icon
                                          icon='heart'
                                          className='text-right'
                                          size='lg'
                                        />
                                      ) : (
                                        <Icon
                                          size='lg'
                                          icon={['far', 'heart']}
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <ClientRating
                                      reportId={reportId}
                                      reportCode={reportCode}
                                      propertyId={property.id}
                                      floorPlanId={item.id}
                                      rating={
                                        reportProperties.find(
                                          (rp) =>
                                            Number(rp.floorPlanId) ===
                                              Number(item.id) &&
                                            rp.typeDetailId === null
                                        )?.clientRating
                                      }
                                      notes={
                                        reportProperties.find(
                                          (rp) =>
                                            Number(rp.floorPlanId) ===
                                              Number(item.id) &&
                                            rp.typeDetailId === null
                                        )?.clientNotes
                                      }
                                    />
                                  )}
                                </Col>
                              {/* </Row> */}
                              {/* <Row> */}
                                <Col
                                  md='12' className='px-0'
                                  // style={{ marginTop: '-6%' }}
                                >
                                  {/* {item.plan2d && (
                                            <UncontrolledCollapse
                                              className='p-4'
                                              toggler={`#togglerImage${item.id}`}
                                            >
                                              <img src='https://www.researchgate.net/profile/Ana-Belen-Petro/publication/221121660/figure/fig1/AS:393937056485384@1470933321643/Top-original-image-600-400-pixels-Bottom-two-representations-of-the-original_Q320.jpg' />
                                            </UncontrolledCollapse>
                                          )} */}
                                  {isAvailableCount(item.units).length > 0 ? (
                                    <CustomCollapse
                                      item={item}
                                      isAvailableCount={isAvailableCount}
                                      currencyFormatter={currencyFormatter}
                                      pastDate={pastDate}
                                      isSelected={isSelected}
                                      selectedProperty={selectedProperty}
                                      property={property}
                                      reportId={reportId}
                                      reportCode={reportCode}
                                      reportProperties={reportProperties}
                                      pageName={pageName}
                                      // selectedFpUnits={selectedFpUnits}
                                      // selectedFloorPlans={selectedFloorPlans}
                                    />
                                  ) : null}
                                </Col>
                              {/* </Row> */}
                            </CardBody>
                          </Card>
                        </Col>
                      )
                    )}
                  </Row>
									{
										getFilteredFloorPlans(groupedFloorPlans[type], "UnAvailable")?.length > 0 &&
										<Button className='my-3 mt-4 btn-rounded btn btn-md' onClick={toggleShowUnavailableFloorPlans}>
											{showUnavailableFloorPlans ? "Hide" : "Show"} {getFilteredFloorPlans(groupedFloorPlans[type], "UnAvailable")?.length} Unavailable Floorplans&nbsp;{ showUnavailableFloorPlans ? <Icon icon='chevron-up' /> : <Icon icon='chevron-down' />}
										</Button>
									}
									<Row>
										{ showUnavailableFloorPlans &&
											getFilteredFloorPlans(groupedFloorPlans[type], "UnAvailable")?.map((item, index) => (
											<Col md='6' key={index} className='mt-2'>
												<Card className='rounded-medium'>
													<CardBody className='p-0'>
														<Row>
															<Col md='12'>
																<Row>
																	<Col md={item.plan2d ? '8' : '12'}>
																		<div className='px-4 py-3'>
																			<h4 className='text-left'>
																				<div className='color-green'>
																					{item.name.match(/^AUTO.*/) ? (
																						''
																					) : (
																						<>
																							<b>{item.name}</b>
																							{/* <span className='mx-2'>·</span> */}
																						</>
																					)}
																				</div>
																			</h4>
																			<div className="text-left">
																				<h5>
																					<div>
																					<b>
																						{getFloorPlanPrice(item.rentMin, item.rentMax)}
																					</b>
																					&nbsp;
																					</div>
																					<div>
																					<small>{unitTypes[type]}</small>
																					{/* <span className='mx-2'>·</span> */}
																					&nbsp;
																					<small>{Number(item.bath) !== 0 && `${item.bath} Bath`}</small>
																					&nbsp;
																					{(Number(item.sqftMin) !== 0) &&
																						<>
																							<small>
																								{currencyFormatter.format(
																									Number(item.sqftMin)
																								)}{' '}
																								sqft
																								<sup>2</sup>{' '}
																							</small>&nbsp;
																						</>
																					}
																						<small>{item.availableFrom !== "Available Now" && item.availableFrom}</small>
																					</div>
																				</h5>
																			</div>
																			{/* <hr className='mt-2 mb-0' /> */}
																		</div>
																		<div className='mb-3 text-left px-4'>
																			<em>No Units Available</em>
																		</div>
																	</Col>

																	{item.plan2d && (
																		<Col className='p-0'>
																			<div
																				style={{
																					height: '120px',
																					overflow: 'hidden',
																				}}
																			>
																				<img
																					className='plan-image'
																					id={`togglerImage${item.id}`}
																					src={item.plan2d}
																					onClick={(e) =>
																						toggleImage(
																							item.plan2d,
																							item.name
																						)
																					}
																				/>
																			</div>
																		</Col>
																	)}
																</Row>
															</Col>
														</Row>
													</CardBody>
												</Card>
											</Col>
										))}
									</Row>
                  <ImageModal
                    parentRef={toggleImage.bind(this)}
                    isOpen={isOpen}
                    imgSrc={imgSrc}
                    planName={planName}
                    setIsOpen={setIsOpen}
                  />
                </Col>
              </Row>
            </TabPane>
          ))}
        </TabContent>
      </Col>
    </Row>
  );
}
