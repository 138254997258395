import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TimePicker from 'react-time-picker';
import { useHistory } from 'react-router-dom';
import { REQUEST_TOUR } from '@api';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardImg,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Icon } from '@assets';

const RequestTour = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [time, setTime] = useState('10:00');
  const history = useHistory();
  toast.configure();
  const {
    name,
    imageUrls,
    bedroomNo,
    agentEmail,
    client_name,
    neighborhood,
    detailShow,
  } = props.location.details;

  const [requestTour, { data, loading, error }] = useMutation(REQUEST_TOUR, {
    onCompleted: (data) => {
      if (
        data.reportRequestTour.errors &&
        data.reportRequestTour.errors.length > 0
      ) {
        data.reportRequestTour.errors.map((err) =>
          toast(err, { type: 'error' })
        );
      } else {
        toast(data.reportRequestTour.message, { type: 'success' });
      }
    },
    onError: (e) => {
      console.log(e);
      toast(e.message, { type: 'error' });
    },
  });

  const submitRequest = () => {
    requestTour({
      variables: {
        startDate,
        agentEmail,
        name,
        clientName: client_name,
        time,
      },
    });
  };

  return (
    <>
      <Container className='p-4 property-report-header'>
        <h2>Request Tour</h2>
      </Container>

      <Container className='property-list'>
        <Card className='h-100'>
          <div className='property-card-image'>
            <CardImg
              top
              width='100%'
              src={
                imageUrls
                  ? imageUrls.length === 0
                    ? 'https://cdn.archilovers.com/projects/57d7daff-e586-4a1b-83b3-8d5bf0f8e070.jpg'
                    : imageUrls[0]
                  : 'https://cdn.archilovers.com/projects/57d7daff-e586-4a1b-83b3-8d5bf0f8e070.jpg'
              }
              alt={name}
            />
          </div>
          <CardBody>
            <CardTitle className='mb-0' tag='h5'>
              {detailShow ? neighborhood?.name : name}{' '}
              <small className='text-muted'>
                <Icon icon='bed' className='mx-2' />
                {bedroomNo.length > 1 ? 'Studio' : `${bedroomNo} Bedroom`}
              </small>
            </CardTitle>
          </CardBody>
        </Card>
        <Row>
          <Col xs='6' className='custom-date-picker mt-4 mb-5'>
            <DatePicker
              selected={Date.parse(startDate)}
              onChange={(date) => setStartDate(date)}
              minDate={new Date()}
              dateFormat='MMMM d, yyyy'
            />
          </Col>

          <Col
            xs='6'
            className='custom-time-picker mt-4 mb-5 react-time-picker'
          >
            <TimePicker
              onChange={setTime}
              value={time}
              clearIcon={''}
              clockIcon={''}
              format={'h:mm a'}
              className={'w-100'}
            />
          </Col>

          <Col md='6' className='text-center'>
            <Button
              onClick={submitRequest}
              color='primary'
              className='button-dashboard'
              block
            >
              Submit Tour Request
            </Button>
          </Col>

          <Col md='6' className='mt-3 mt-md-0 text-center'>
            <Button
              onClick={() => history.goBack()}
              color='primary'
              className='button-dashboard'
              block
            >
              Back
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RequestTour;
