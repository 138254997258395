import React, { useState, useEffect } from 'react';
import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import CardSection from './CardSection';
import PricingPlanCard from '../../../components/PricingPlanCard';
import {
  PLANS,
  SUBSCRIPTION_CREATE,
  SUBSCRIPTIONS,
  VISIBLE_CITIES,
} from '@api';
import { useQuery, useMutation } from '@apollo/client';
import { ToggleButton } from '@sparklib';
import { toast } from 'react-toastify';
import Spinner from '../../Spinner';
import {
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
function PaymentForm(props) {
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [selectPlan, setselectPlan] = useState('');
  const [plans, setPlans] = useState([]);
  const [isYearly, setIsYearly] = useState(false);
  const [error, setError] = useState('');
  const [coupon, setCoupon] = useState('');
  const [progress, setProgress] = useState(false);
  const { data: cityData } = useQuery(VISIBLE_CITIES);
  const [selectedCities, setSelectedCities] = useState([]);
  const [maxCities, setMaxCities] = useState(null);
  const [cityError, setCityError] = useState(false);
  const [couponError, setCouponError] = useState(false);
  const [createSubscription] = useMutation(SUBSCRIPTION_CREATE, {
    onCompleted: (data) => {
      setProgress(false);
      if (
        data.subscriptionCreate.errors &&
        data.subscriptionCreate.errors.length != 0
      ) {
        if (data.subscriptionCreate.message === 'Invalid coupon') {
          setCouponError(data.subscriptionCreate.message);
        } else {
          setError(data.subscriptionCreate.message);
        }
      } else {
        toast(data.subscriptionCreate.message, { type: 'success' });
        localStorage.removeItem('trial_end');
        localStorage.removeItem('subscription_popup');
        history.push(`/properties`);
        setModal(false);
      }
    },
    refetchQueries: [SUBSCRIPTIONS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });
  useQuery(SUBSCRIPTIONS);
  const {
    data: plansData,
    loading: planLoading,
    error: planError,
  } = useQuery(PLANS);

  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = (data, e) => {
    if (selectPlan) {
      setProgress(true);
      handleForm(e, data);
    } else {
      setError('Please select any plan');
    }
  };

  const handlePlan = (e) => {
    setSelectedCities(null);
    setselectPlan(e.target.value);

    const selectedPlan = plansData.plans.find(
      (plan) => e.target.value === plan.stripePlan?.id
    );
    if (selectedPlan) {
      setMaxCities(selectedPlan.maxCities);
    }

    setError('');
  };
  const logout = () => {
    history.push({
      pathname: '/logout',
    });
  };

  const handleForm = async (e, data) => {
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setError(result.error.message);
      setProgress(false);
    } else {
      setError('');
      createToken(result.token.id);
    }
  };

  const createToken = (token) => {
    createSubscription({
      variables: {
        stripeToken: token,
        stripePlanId: selectPlan,
        coupon: coupon,
        cityIds:
          maxCities === -1
            ? cityData.visibleCities.map((city) => Number(city.id))
            : selectedCities.map((city) => Number(city.value)),
      },
    });
  };

  useEffect(() => {
    if (plansData) {
      if (plansData.plans.length === 0) {
        setModal(false);
        toast('Plans not found', { type: 'error' });
      } else {
        let newPlans = [...plansData.plans];
        newPlans.sort(function (a, b) {
          return parseFloat(a.amount) - parseFloat(b.amount);
        });
        setPlans(newPlans);
        setModal(true);
      }
    }
  }, [plansData]);

  useEffect(() => {
    if (selectedCities && selectedCities.length > 0) {
      setCityError(false);
    }
  }, [selectedCities]);

  const planColors = [
    //red, blue, yellow, green
    { color: 'blue', highlight: false },
    { color: 'red', highlight: true },
    { color: 'blue', highlight: false },
    { color: 'blue', highlight: false },
    { color: 'red', highlight: true },
    { color: 'blue', highlight: false },
    { color: 'blue', highlight: false },
  ];

  return (
    <>
      {planLoading && <Spinner />}

      <Modal isOpen={modal} centered size='lg'>
        <ModalHeader>Choose Subscription</ModalHeader>
        <Form className='payment-form' onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className='text-center'>
            <h6>
              Choose the plan that suits your requirements.
              <br />
              We recommend yearly plans to save on subscription payment.
            </h6>

            {/*<Col md='12' className='my-4'>
              <ToggleButton
                selected={isYearly}
                firstOption='Yearly'
                secondOption='Monthly'
                width='130px'
                rounded
                toggleSelected={() => {
                  setIsYearly(!isYearly);
                }}
              />
            </Col>*/}
            <Row>
              <Col md='12' className='mb-4 px-4'>
                <div className='text-center mx-auto'>
                  <Row className='d-flex align-items-end select-plan'>
                    {plans &&
                      plans
                        .filter((plan) => plan.stripePlanId)
                        .map(
                          (
                            {
                              id,
                              name,
                              amount,
                              interval,
                              stripePlanId,
                              maxCities,
                            },
                            i
                          ) =>
                            interval === (isYearly ? 'year' : 'month') && (
                              <Col md='6' lg='4' key={id}>
                                <FormGroup check className='p-0 bg-transparent'>
                                  <Label check className='p-0 bg-transparent'>
                                    <PricingPlanCard
                                      highlight={
                                        planColors[i] && planColors[i].highlight
                                      }
                                      color={
                                        planColors[i]
                                          ? planColors[i].color
                                          : 'yellow'
                                      }
                                      planName={name}
                                      amount={amount}
                                      interval={interval}
                                      hideTrial={true}
                                      hideButton={true}
                                      cityAccess={
                                        maxCities && maxCities === -1
                                          ? 'All'
                                          : maxCities
                                      }
                                    />
                                    <Input
                                      type='radio'
                                      name='subscription'
                                      value={stripePlanId}
                                      checked={selectPlan === stripePlanId}
                                      onChange={handlePlan}
                                    />
                                    <span className='checkmark'></span>
                                  </Label>
                                </FormGroup>
                              </Col>
                            )
                        )}
                  </Row>
                </div>
              </Col>
            </Row>

            <Row>
              <Col md='12' className='px-4'>
                {selectPlan ? (
                  maxCities !== -1 ? (
                    <FormGroup className='text-left'>
                      <Label for='city'>Select Market</Label>
                      <Select
                        value={
                          maxCities === 1 && selectedCities
                            ? selectedCities[0]
                            : selectedCities
                        }
                        name='city'
                        classNamePrefix='default-select'
                        isMulti={maxCities !== 1}
                        isClearable={maxCities > 1}
                        placeholder='Select Market'
                        options={
                          typeof cityData !== 'undefined'
                            ? Object.values(cityData.visibleCities).map(
                                (city) =>
                                  JSON.parse(
                                    `{"value":${city.id}, "label":"${city.name}"}`
                                  )
                              )
                            : []
                        }
                        onChange={(selectedCity) => {
                          maxCities === 1
                            ? setSelectedCities([selectedCity])
                            : selectedCity.length <= maxCities ||
                              maxCities === -1
                            ? setSelectedCities(selectedCity)
                            : null;
                        }}
                        className='w-100'
                      />
                      {cityError && (
                        <p className='input-error'>Market required</p>
                      )}
                    </FormGroup>
                  ) : (
                    <h5>You will get access to all markets.</h5>
                  )
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Row>
              <Col md='8' className='pl-4'>
                <FormGroup className='text-left'>
                  <label> Payment Information</label>
                  <CardSection />
                </FormGroup>
              </Col>
              <Col md='4' className='pr-4'>
                <FormGroup className='text-left'>
                  <label> Coupon Code</label>
                  <Input
                    type='text'
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    name='coupon'
                    placeholder='Coupon code'
                  />
                  {couponError && <p className='input-error'>{couponError} </p>}
                </FormGroup>
              </Col>
            </Row>

            {error !== '' ? <p className='input-error s'>{error}</p> : null}
          </ModalBody>
          <ModalFooter>
            {localStorage.getItem('subscription_popup') ? (
              <Button color='light-purple' onClick={logout}>
                Logout
              </Button>
            ) : (
              <Button
                color='light-purple'
                onClick={() => {
                  setModal(false);
                  props.hideTrialPopup();
                }}
              >
                Continue Trial
              </Button>
            )}
            <Button color='success' disabled={progress}>
              {progress ? 'Progress' : 'Subscribe'}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <PaymentForm stripe={stripe} elements={elements} {...props} />
      )}
    </ElementsConsumer>
  );
}
