import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { 
	Row, 
	Col
} from 'reactstrap';
import { Icon } from '@assets';
import { AGENT } from '@api';
import { useParams } from 'react-router-dom';
import AgentCityAccessForm from './AgentCityAccessForm';

export default function AgentShow({broker, ...props}) {
	const [agent, setAgent] = useState(null);
	const { id: agentId } = useParams();

	useQuery(AGENT, {
		skip: !agentId, 
		variables: { id: Number(agentId) },
		onCompleted: (data) => {
			setAgent(data.agent)
		}
	});

	return (
		<div className='p-4 edit-popup'>
			{
				agent ? 
				<Row>
					<Col md='12' className='mt-md-3'>
						<Row className='mb-4 mt-3'>
							<Col md='12' className='mb-4'>
								<h4>{agent.name}</h4>
							</Col>
							{	agent.phoneNo?.length > 0 && 
								<Col lg='6'>
									<p>
										<Icon icon='phone' /> <a href={`tel:${agent.phoneNo}`}>{agent.phoneNo}</a>
									</p>
								</Col>}
							<Col lg='6'>
								<p>
									<Icon icon='envelope' /> <a href={`mailto:${agent.email}`}>{agent.email}</a>
								</p>
							</Col>
							{	agent.licenseNo?.length > 0 && 
								<Col lg='6'>
									<p>
										<Icon icon='id-card' />{agent.licenseNo}
									</p>
								</Col>}
							<Col md='12' className='mt-4 mb-2'>
								<h5>Agent Statistics</h5>
							</Col>
							<Col lg='6'>
								<p>
									<Icon icon='users' />{agent.activeClientsCount} Active Client{agent.activeClientsCount > 1 ? 's' : ''}
								</p>
							</Col>
							<Col lg='6'>
								<p>
									<Icon icon='building' />{agent.guestCardsCount} Guest Card{agent.guestCardsCount > 1 ? 's' : ''} Sent
								</p>
							</Col>
							<Col lg='6'>
								<p>
									<Icon icon='paper-plane' />{agent.sentListsCount} Report{agent.sentListsCount > 1 ? 's' : ''} Sent
								</p>
							</Col>
						</Row>
					</Col>

					<Col md='12' className=' mb-2'>
						<h5>Subscription</h5>
					</Col>
					
					<Col md='12'>
						{
							(agent && broker?.subscriptions?.length > 0) && <AgentCityAccessForm agent={agent} subscriptions={broker.subscriptions} />
						}
					</Col>
				</Row> :
				"Loading Agent Data..."
			}
		</div>
	);
}