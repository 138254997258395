import React, { useState, useEffect } from 'react'

import { NavLink } from 'react-router-dom';

import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button
} from 'reactstrap';

import { NewHeadLogo } from '@assets';

export default function NavBar() {

  const [isOpen, setIsOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {

    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll)
    };
  }, []);

  return (
    <>
      <Navbar light expand="sm" fixed="top" className={scroll ? "nav-white" : ""}>
        <Container className="py-lg-2 custom-container">
          {/* <NavbarBrand href='/'> */}
            <NavLink to="/">
              <img src={NewHeadLogo} alt="Logo" className="img-fluid" style={{ width: '195px' }} />
            </NavLink>
          {/* </NavbarBrand> */}

          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            {/* <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/new" className="">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/contact-us" className="">Contact</NavLink>
              </NavItem>
            </Nav> */}

            <Nav className="ml-auto d-flex align-items-center" navbar>
              {localStorage.getItem("auth_token") !== null ?
                <>
                <NavItem>
                  <NavLink to="/logout">Logout</NavLink>
                </NavItem>
                <NavLink to="/login"><Button className='btn-rounded' color="green">Go to App</Button></NavLink>
                </>
              : <>
                <NavItem>
                  <NavLink to="/login"><Button className='btn-rounded' color="blue">Login</Button></NavLink>
                </NavItem>
                <NavLink to="/signup"><Button className='btn-rounded' color="green">Try for Free</Button></NavLink>
                </>
              }
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}
