import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import Avatar from 'react-avatar';
// import "react-toastify/dist/ReactToastify.css";
// import "../../assets/styles/Dashboard.css";
import UpdatePassword from '../updatePassword';
import FeedbackForm from './FeedbackForm';
import Notification from './NotificationList';
import MyReport from './MyReport';
import TrialTimer from './TrialTimer';
import PaymentForm from './payment/PaymentForm';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from 'reactstrap';
import UserHome from '../user/UserHome';
import config from '@config';
import { Properties } from '@property';
import ClientList from '../user/clients/ClientList';
import UserProfile from '../user/UserProfile';
import Subscription from './Subscription';
// import GuestCard from "../user/guest_card/GuestCard";
import GuestHistory from '../user/guest_card/GuestHistory';
import InvoiceHistory from '../user/invoice/InvoiceHistory';
import AgentList from '../user/agents/AgentList';
import Inquiry from '../../website/Inquiry';
import TutorialList from '../../website/TutorialList';
import { SIGN_OUT_AS, NOTIFICATION_SUBSCRIPTION } from '@api';
import { useMutation, useQuery, useSubscription } from '@apollo/client';

import DashboardResponsiveMenu from '../DashboardResponsiveMenu';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { LogoIcon, Icon } from '@assets';
import NotificationSetting from './NotificationSetting';
import PaymentMethod from './payment/PaymentMethod';
import Settings from './Settings';
import _ from 'lodash/fp';
import { getNotificationColor } from '../shared/sparkLib/notificationTypes';
import ClientInquiry from './inquiries/ClientInquiry';
import EmailTemplateHistory from './emails/EmailTemplateHistory';

export default function Dashboard(props) {
  const stripePromise = loadStripe(config.url.REACT_APP_STRIPE_KEY);
  //console.log(stripePromise, "stripePromise");
  const [isOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [displayModal, setModal] = useState(false);
  const toggleModal = () => setModal(!displayModal);
  const [selctedCity, setSelectedCity] = useState({ label: '', value: '' });
  const [searchQuery, setsearchQuery] = useState('');
  const [displayFeedbackModal, setFeedbackModal] = useState(false);
  const toggleFeedback = () => setFeedbackModal(!displayFeedbackModal);
  const [display_sidebar, setSidebar] = useState(false);
  const toggleSidebar = () => setSidebar(!display_sidebar);
  const [isResponsiveMenuOpen, setIsResponsiveMenuOpen] = useState(false);
  const [trialPopup, setTrialPopup] = useState(false);
  const history = useHistory();
  const [showNotificationSetting, setShowNotificationSetting] = useState(false);
  const toggleNotificationSetting = () =>
    setShowNotificationSetting(!showNotificationSetting);

  const [singoutAs] = useMutation(SIGN_OUT_AS);
  const [notifications, setNotifications] = useState([]);
  const toggleNotifybar = () => {
    setShowNotification(!showNotification);
  };

  useEffect(() => {
    if (localStorage.getItem('subscription_popup') === 'true') {
      setTrialPopup(true);
    } else {
      // comment this line
      // setTrialPopup(true);
    }
  }, []);

  useSubscription(NOTIFICATION_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data?.notificationSubscription?.notifications?.length > 0) {
        handleNotifications(data.notificationSubscription.notifications);
        setNotifications(data.notificationSubscription.notifications);
      }
    },
  });

  function handleNotifications(receivedNotifications) {
    var notificaions = [];
    var cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)read_notifications\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    receivedNotifications.forEach((notificaion) => {
      if (cookieValue && JSON.parse(cookieValue)?.includes(notificaion.id)) {
        return;
      }
      if (new Date() - new Date(notificaion.updatedAt) < 604800000) {
        notificaions.push(notificaion);
      }
    });
    notificaions.forEach((notificaion) => {
      var str = notificaion.description;
      toast(str.length > 100 ? str.substr(0, 100) + '....' : str, {
        position: 'top-right',
        autoClose: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: 'dark',
        className: `bg-${notificaion.notificationType}`,
        closeOnClick: true,
        onClick: () => {
          history.push('/user-notifications');
        },
        onClose: () => saveReadNotifications(notificaion.id),
      });
    });
  }

  const isLogin = () => {
    if (
      localStorage.getItem('auth_token') === null ||
      localStorage.getItem('user_type') !== 'user'
    ) {
      history.push({
        pathname: '/login',
        state: { message: 'Please login to continue..' },
      });
      return;
    }
  };

  useEffect(() => {
    isLogin();
    return () => {};
  }, []);

  const backToAdmin = async () => {
    await singoutAs();
    const restoreLocalStorage = JSON.parse(
      localStorage.getItem('original_localStorage')
    );
    localStorage.clear();
    Object.keys(restoreLocalStorage).forEach((item) =>
      localStorage.setItem(item, restoreLocalStorage[item])
    );
    window.location.href = '/users';
  };

  const renderSwitch = () => {
    switch (props.path) {
      case 'properties':
        return <Properties city={selctedCity} {...props} />;
      case 'clients':
        return <ClientList search={searchQuery} city={selctedCity} />;
      // case 'user-profile':
      //   return <UserProfile />;
      // case 'user-subscriptions':
      //   return <Subscription />;
      // case 'payment-method':
      //   return <PaymentMethod />;
      case 'user-notifications':
        return <Notification allNotifications={notifications} />;
      // case 'notification-settings':
      //   return <NotificationSetting />;
      case 'guest-cards':
        return <GuestHistory {...props} />;
      case 'invoices':
        return <InvoiceHistory {...props} />;
      case 'emails':
        return <EmailTemplateHistory {...props} />;
      case 'recommendations':
        return <MyReport />;
      case 'agents':
        return <AgentList {...props} />;
      case 'settings':
        return <Settings {...props} />;
      case 'client-inquiries':
        return <ClientInquiry {...props} />;
      case 'tutorials':
        return <TutorialList {...props} />;
      default:
        return <UserHome city={selctedCity} {...props} />;
    }
  };

  const formatTimeStamp = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    // const options = { year: "numeric", month: "short", day: "numeric", hour: "" };
    // return new Date(dateString).toLocaleDateString(undefined, options);
    return new Intl.DateTimeFormat('default', options).format(
      Date.parse(dateString)
    );
  };
  /*   const time = new Date();
  time.setSeconds(time.getSeconds() + 600);  */

  // const formatDate = (dateString) => {
  //   const options = { year: "numeric", month: "short", day: "numeric" };
  //   return new Date(dateString).toLocaleDateString(undefined, options);
  // };

  {
    /* DropDown Open on Hover */
  }
  // const {
  //   defaultOpen,
  // } = props;
  // const [DropdownisOpen, setDropdownIsOpen] = useState(defaultOpen);
  {
    /* DropDown Open on Hover Ends */
  }

  function saveReadNotifications(notificaionId) {
    var readNotifications = [];
    var cookieValue = document.cookie.replace(
      /(?:(?:^|.*;\s*)read_notifications\s*\=\s*([^;]*).*$)|^.*$/,
      '$1'
    );
    if (cookieValue) {
      readNotifications = JSON.parse(cookieValue);
    }
    readNotifications.push(notificaionId);
    var currentDate = new Date();
    var expirationDate = new Date(
      currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
    );
    var cookieString =
      'read_notifications=' +
      JSON.stringify(_.uniq(readNotifications)) +
      '; expires=' +
      expirationDate.toUTCString() +
      '; path=/';
    document.cookie = cookieString;
  }

  return (
    <>
      {trialPopup === true ? (
        <>
          <Elements stripe={stripePromise}>
            <PaymentForm hideTrialPopup={() => setTrialPopup(false)} />
          </Elements>
        </>
      ) : (
        ''
      )}
      <Container fluid className='admin-header fixed-top d-none d-lg-block'>
        <Row>
          <Col md='12'>
            <Navbar dark expand='lg'>
              {/* <NavbarBrand to="/user-dashboard" onClick={toggleSidebar} className={display_sidebar ? "navbar-logo" : "navbar-logo nav-small"}>
                                    <img src={Logo} alt="Logo" className="h-75" /><span className=" ml-3 brand-name">SparkAPT</span></NavbarBrand> */}
              <NavbarBrand
                to='/user-dashboard'
                onClick={toggleSidebar}
                className='navbar-logo'
              >
                {/* <span className="brand-name">SparkAPT</span> */}
                <span className='brand-name'>
                  <img
                    src={LogoIcon}
                    alt='Logo Icon'
                    className='img-fluid dashboard-logo'
                  />
                </span>
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                {/* Search Field */}
                {/* <Nav navbar className="d-none d-lg-block">
                      <FormGroup className="mb-0 search-bar">
                          <Label for="appartment" className="mb-0 w-auto d-inline-block mr-lg-2"><Icon icon="search" /></Label>
                          <DebounceInput
                              type="search"
                              autoComplete="off"
                              className="mb-0 w-auto d-inline-block"
                              placeholder="Search By Keyword"
                              value={searchQuery}
                              debounceTimeout={700}
                              onChange={searchData} />
                          <Icon icon="arrow-right" className="cursor-pointer" onClick={() => setIsSearch(!isSearch)} />
                      </FormGroup>
                  </Nav>

                  <Nav className="mx-auto d-none d-lg-block" navbar>
                      <FormGroup className="mb-lg-0 apartment-area">
                          <Label for="appartment" className="mb-0 w-auto d-inline-block mr-lg-2">Currently searching apartments in</Label>
                          <Select
                              value={selctedCity}
                              options={cities}
                              onChange={handleChange}
                              isSearchable={true}
                          />
                      </FormGroup>
                  </Nav> */}

                <Nav navbar className='pl-lg-5'>
                  {/* <NavItem className="py-2">
                    <NavLink to="/user-dashboard">Dashboard</NavLink>
                  </NavItem> */}
                  <NavItem className='py-2'>
                    <NavLink to='/properties'>Properties</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/clients'>Clients</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/my-recommendations'>Lists</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/guest-cards'>Guest Cards</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/client-inquiries'>Generate Leads</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/invoices'>Invoices</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/emails'>Emails</NavLink>
                  </NavItem>
                  {localStorage.getItem('is_broker') === 'true' && (
                    <NavItem className='py-2'>
                      <NavLink to='/agents'>Agents</NavLink>
                    </NavItem>
                  )}
                </Nav>
                <Nav
                  className='ml-auto d-flex navbar-nav flex-row justify-content-between'
                  navbar
                >
                  {/* {localStorage.getItem('trial_end') && (
                    <NavItem className='d-flex align-items-center'>
                      <NavLink to onClick={() => setTrialPopup(true)}>
                        <TrialTimer
                          trialEndTimestamp={
                            new Date(localStorage.getItem('trial_end'))
                          }
                        />
                      </NavLink>
                    </NavItem>
                  )} */}
                  <NavItem className='d-flex align-items-center'>
                    <NavLink to onClick={toggleNotifybar}>
                      <Icon icon='bell' />
                    </NavLink>
                  </NavItem>

                  {/* DropDown Open on Hover */}
                  {/* <UncontrolledDropdown nav inNavbar 
                      isOpen={DropdownisOpen}
                      onMouseEnter={() => setDropdownIsOpen(true)}
                      onMouseLeave={() => setDropdownIsOpen(false)}
                      toggle={() => setDropdownIsOpen(!isOpen)}
                    > */}
                  {/* DropDown Open on Hover Ends*/}

                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className='text-white'>
                      <Avatar
                        name={localStorage.getItem('user_name')}
                        className='mr-2 text-dark'
                        round={true}
                        size='40'
                        color='white'
                      />
                      {localStorage.getItem('user_name')}
                    </DropdownToggle>

                    <DropdownMenu right>
                      {/* <DropdownItem>
                        <NavLink to='/user-profile'>
                          <Icon icon='user-edit' className='mr-3' />
                          My Profile
                        </NavLink>
                      </DropdownItem> */}

                      {/* { localStorage.getItem('is_agent') !== 'true' &&
												<DropdownItem>
													<NavLink to='/user-subscriptions'>
														<Icon icon='clipboard-list' className='mr-3' />
														My Subscription
													</NavLink>
												</DropdownItem>
											} */}

                      {/* <DropdownItem>
												<NavLink to='/payment-method'>
													<Icon icon='credit-card' className='mr-3' />
													Payment Method
												</NavLink>
											</DropdownItem> */}

                      {/* <DropdownItem>
                        <NavLink to="/user-notifications">
                          <Icon icon="bell" className="mr-3" />
                          User Notifications
                        </NavLink>
                      </DropdownItem> */}

                      {localStorage.getItem('original_localStorage') ? (
                        <DropdownItem>
                          <NavLink to='#' onClick={backToAdmin}>
                            <Icon icon='arrow-left' className='mr-3' />
                            Back To Admin
                          </NavLink>
                        </DropdownItem>
                      ) : (
                        ''
                      )}

                      {/* <DropdownItem>
                        <NavLink to='#' onClick={toggleModal}>
                          <Icon icon='unlock-alt' className='mr-3' />
                          Change Password
                        </NavLink>
                      </DropdownItem> */}

                      <DropdownItem>
                        <NavLink to='#' onClick={toggleFeedback}>
                          <Icon icon='envelope' className='mr-3' />
                          Contact Us
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='/settings'>
                          <Icon icon='cog' className='mr-3' />
                          Settings
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='/tutorials'>
                          <Icon icon='info' className='mr-3' />
                          Tutorial Videos
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='/logout'>
                          <Icon icon='sign-out-alt' className='mr-3 fa-w-20' />
                          Logout
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>

      {/* Reponsive Navbar */}

      <DashboardResponsiveMenu
        toggleResponsiveModel={() => {
          setIsResponsiveMenuOpen(!isResponsiveMenuOpen);
        }}
        isMenu={isResponsiveMenuOpen}
        content={
          <>
            <Nav navbar className='v-center'>
              <NavItem className='py-2'>
                <NavLink
                  to='/user-dashboard'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Dashboard
                </NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink
                  to='/properties'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Properties
                </NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink
                  to='/clients'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Clients
                </NavLink>
              </NavItem>
              {/* <NavItem className="py-2">
                <NavLink
                  to="/guest-card"
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Send Guest Card
                </NavLink>
              </NavItem> */}
              <NavItem className='py-2'>
                <NavLink
                  to='/my-recommendations'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Lists
                </NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink
                  to='/guest-cards'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Guest Cards
                </NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink
                  to='/client-inquiries'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Generate Leads
                </NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink
                  to='/invoices'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Invoices
                </NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink
                  to='/emails'
                  onClick={DashboardResponsiveMenu.toggleDashboardMenu}
                >
                  Emails
                </NavLink>
              </NavItem>
              {localStorage.getItem('is_broker') === 'true' && (
                <NavItem className='py-2'>
                  <NavLink to='/agents'>Agents</NavLink>
                </NavItem>
              )}
              {/* <NavItem className="py-2">
                <NavLink to onClick={toggleNotifybar}>Notifications</NavLink>
              </NavItem> */}
            </Nav>

            <div className='user-profile-bottom'>
              <Row>
                {/* <Col>
                  <NavLink to='/user-profile'>
                    <Icon icon='user' className='color-green' />
                    <p>My Profile</p>
                  </NavLink>
                </Col> */}

                {/* {localStorage.getItem('is_broker') !== 'true' &&
								<Col>
                  <NavLink to='/user-subscriptions'>
                    <Icon icon='clipboard-list' className='color-blue' />
                    <p>My Subscription</p>
                  </NavLink>
                </Col>} */}

                {/* <Col>
                  <NavLink to='/payment-method'>
                    <Icon icon='clipboard-list' className='color-blue' />
                    <p>Payment Method</p>
                  </NavLink>
                </Col> */}

                <Col>
                  <NavLink to='/user-notifications'>
                    <Icon icon='bell' className='color-orange' />
                    <p>Notifications</p>
                  </NavLink>
                </Col>

                {localStorage.getItem('original_localStorage') ? (
                  <Col>
                    <NavLink to='#' onClick={backToAdmin}>
                      <Icon icon='arrow-left' className='color-purple' />
                      <p>Back To Admin</p>
                    </NavLink>
                  </Col>
                ) : (
                  ''
                )}

                {/* <Col>
                  <NavLink to='#' onClick={toggleModal}>
                    <Icon icon='unlock-alt' className='color-yellow' />
                    <p>Change Password</p>
                  </NavLink>
                </Col> */}

                <Col>
                  <NavLink to='#' onClick={toggleFeedback}>
                    <Icon icon='envelope' className='color-blue' />
                    <p>Contact Us</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='/settings'>
                    <Icon icon='cog' className='color-orange' />
                    <p>Settings</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='/logout'>
                    <Icon icon='sign-out-alt' className='color-red' />
                    <p>Logout</p>
                  </NavLink>
                </Col>
              </Row>
            </div>
          </>
        }
      />
      {/* Reponsive Navbar */}

      {/* Sidebar */}
      {/* 
            <div className={display_sidebar ? "sidebar open" : "sidebar"}>
                <Row className="filters">
                    <Col md="12" className="mb-3">
                        <p>Rent</p>
                        <Row>
                            <Col xs="6">
                                <DebounceInput type="text" placeholder="Min." name="min_price" debounceTimeout={300} />
                            </Col>
                            <Col xs="6">
                                <DebounceInput type="text" placeholder="Max." name="max_price" debounceTimeout={300} />
                            </Col>
                        </Row>
                    </Col>

                    <Col md="12" className="mb-3">
                        <p>Availability</p>
                        <Row>
                            <Col xs="6">
                                <Input
                                    type="date"
                                    name="fromDate"
                                    id="fromDate"
                                    placeholder="From"
                                />
                            </Col>
                            <Col xs="6">
                                <Input
                                    type="date"
                                    name="toDate"
                                    id="toDate"
                                    placeholder="To"
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col md="12" className="mb-3">
                        <p>Built Year</p>
                        <Row>
                            <Col xs="6">
                                <DebounceInput type="text" placeholder="From" name="from_year" debounceTimeout={300} />
                            </Col>
                            <Col xs="6">
                                <DebounceInput type="text" placeholder="To" name="to_year" debounceTimeout={300} />
                            </Col>
                        </Row>
                    </Col>

                    <Col md="12" className="mb-3">
                        <DebounceInput type="text" placeholder="Min Square Feet" name="min_square_feet" debounceTimeout={300} />
                    </Col>

                    <Col md="12" className="mb-3">
                        <DebounceInput type="text" placeholder="Min Escorts" name="min_escorts" debounceTimeout={300} />
                    </Col>

                    <Col md="12" className="mb-3">
                        <DebounceInput type="text" placeholder="Min Send" name="min_send" debounceTimeout={300} />
                    </Col>

                    <Col md="12" className="mb-3">
                        <DebounceInput type="text" placeholder="Zip" name="zip" debounceTimeout={300} />
                    </Col>

                    <Col md="12" className="mb-3">
                        <Select
                            name="BedSize"
                            options={bedSizeOptions}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            placeholder="Bed Size"
                        />
                    </Col>
                    
                    <Col md="12" className="mb-3">
                        <Select
                            name="Pet"
                            options={petOptions}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            placeholder="Pet"
                        />
                    </Col>

                    <Col md="12" className="mb-3">
                        <Select
                            name="Bathroom"
                            options={bathromOptions}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            placeholder="Bathroom"
                        />
                    </Col>

                    <Col md="12" className="mb-3">
                        <Select
                            name="Neighborhood"
                            options={neighborhoodOptions}
                            className="basic-multi-select w-100"
                            classNamePrefix="select"
                            placeholder="Neighborhood"
                        />
                    </Col>

                    <Col md="12" className="mb-3">
                        <p>Addons</p>
                            <Select
                                isMulti
                                name="addons"
                                options={addonsOptions}
                                className="basic-multi-select w-100"
                                classNamePrefix="select"
                            />
                    </Col>

                    <Col md="12" className="mt-2 text-center">
                        <Button color="primary" className="button-dashboard"><Icon icon="search" size="md" className="mr-2" />Search</Button>
                    </Col>				

                </Row>
            </div>
        */}
      {/* Sidebar Ends */}

      {/* Notification Bar */}
      <div
        className={
          showNotification ? 'notification_bar open' : 'notification_bar'
        }
        style={{
          display: showNotification ? 'block' : 'none',
        }}
      >
        <Row>
          <Col md='12'>
            <ul className='list-unstyled'>
              {notifications?.length > 0 &&
                notifications.map(
                  (
                    { id, title, description, updatedAt, notificationType },
                    i
                  ) => (
                    // {notificationsData && notificationsData.map(({ id, title, description, createdAt }, i) => (
                    <li key={id}>
                      <NavLink
                        to='/user-notifications'
                        onClick={toggleNotifybar}
                        style={{
                          backgroundColor:
                            getNotificationColor(notificationType),
                        }}
                      >
                        <h6>
                          {title}&nbsp;
                          {new Date() - new Date(updatedAt) < 604800000 && (
                            <Badge color='primary' pill>
                              New
                            </Badge>
                          )}
                        </h6>
                        <p>{description}</p>
                        <p>
                          <small>{formatTimeStamp(updatedAt)}</small>
                        </p>
                      </NavLink>
                    </li>
                  )
                )}
            </ul>
          </Col>
        </Row>
      </div>
      {/* Notification Bar Ends */}

      <Container fluid className='my-65'>
        <FeedbackForm
          parentRef={toggleFeedback.bind(this)}
          displayModal={displayFeedbackModal}
        />
        {/* <UpdatePassword
          parentRef={toggleModal.bind(this)}
          displayModal={displayModal}
          token={localStorage.getItem('auth_token')}
        /> */}
        {/*	<FeedbackForm parentRef={toggleFeedback.bind(this)} displayModal={displayFeedbackModal} />
                <Row className="py-4 border-bottom position-relative">

                    <Col md="6" xl="4" className="mb-3 mb-md-0 d-flex align-items-center justify-content-end justify-content-md-start">
                        
                        <Button onClick={toggleSidebar} className={display_sidebar ? "sidebar-button left-positioned" : "sidebar-button"}>
                            <Icon icon="filter" className={display_sidebar ? "sidebar-button color-white d-none" : "sidebar-button color-white"} />
                            <Icon icon="times" size="lg" className={display_sidebar ? "sidebar-button color-white d-block" : "sidebar-button color-white d-none"} />
                        </Button>

                        
                        <div className="search-bar d-inline-block ml-5">
                            <FormGroup className="mb-0 w-auto d-inline-block">
                                <Label for="appartment" className="mb-0 w-auto d-inline-block mr-2"><Icon icon="search" /></Label>
                                <DebounceInput
                                    type="search"
                                    autoComplete="off"
                                    id="VariableSearch"
                                    className="mb-0 w-auto d-inline-block"
                                    placeholder="Search by keyword"
                                    debounceTimeout={300}
                                    value={searchQuery}
                                    onChange={searchData} />
                                <Icon icon="long-arrow-alt-right" size="lg" className="cursor-pointer" onClick={() => setIsSearch(!isSearch)} />
                            </FormGroup>
                        </div>
                    </Col>

                    <Col md="6" xl={{offset:'4', size: '4'}} className="d-flex align-items-center justify-content-center justify-content-md-end">
                        <FormGroup className="apartment-area mb-0 text-lg-right">
                            <Label for="appartment" className="mb-0 w-auto d-inline-block mr-2 mr-md-3">Currently searching apartments in</Label>
                            <Select
                                value={selctedCity}
                                options={cities}
                                onChange={handleChange}
                                isSearchable={true}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            */}
        {renderSwitch()}
      </Container>

      <div onClick={toggleFeedback} className='feedback-btn'>
        <Icon icon='question' size='lg' />
      </div>

      {/* <div className={display_sidebar ? "backdrop" : ""}></div> */}
      <div
        className={showNotification ? 'backdrop transparent' : ''}
        onClick={toggleNotifybar}
      ></div>
    </>
  );
}
