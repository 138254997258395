import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
  InputGroup,
  InputGroupText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Label,
} from 'reactstrap';
import _ from 'lodash/fp';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import 'react-toastify/dist/ReactToastify.css';
import { CLIENTS, SEND_REPORT, GUEST_REPORTS, CITIES, GET_DRAFT_REPORT, DELETE_DRAFT_REPORT_PROPERTY } from '@api';
import { useQuery, useMutation } from '@apollo/client';
import { Icon } from '@assets';
import { SimpleToggleButton } from '@sparklib';

export default function SendDraftReport(props) {
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [clients, setclients] = useState(null);
  const [progress, setprogress] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [clientIdError, setClientIdError] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [selectedCity, setSelectedCityValue] = useState();
  const [reportTitle, setReportTitle] = useState('');
  const [reportMsg, setReportMsg] = useState('');
  const [reportHidePropertyName, setReportHidePropertyName] = useState(false);
  const [citySelectError, setCitySelectError] = useState(false);
  const [draftReport, setDraftReport] = useState(null);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
		setReportTitle('');
		setReportMsg('');
    setReportHidePropertyName(false);
  };

  const {data: cityData} = useQuery(CITIES);
  const {data: clientData} = useQuery(CLIENTS, {
    onCompleted: (data) => {},
    onError: (e) => {
      console.log(e);
    },
  });

  const { data: draftReportData } = useQuery(GET_DRAFT_REPORT, {
    variables: {
      reportId: parseInt(props.reportId),
    },
    skip: !props.reportId,
    onCompleted: (data) => {
      if (data && data.draftReport) {
        setDraftReport(data.draftReport);
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const toggleReportProperty = (propertyId) => {
    deleteDraftReportProperty({
      variables: {
        reportId: parseInt(props.reportId),
        propertyId: parseInt(propertyId)
      },
    });
  };

  const [deleteDraftReportProperty] = useMutation(DELETE_DRAFT_REPORT_PROPERTY, {
    onCompleted: (data) => {
      toast(data.deleteDraftReportProperty.message, { type: 'success' });
    },
    refetchQueries: [GET_DRAFT_REPORT],
    onError: (e) => {
      console.log(e);
    },
  });

  useEffect(() => {
    if (draftReportData && draftReportData.draftReport === null) {
      props.sidebarToggle();
    }
  }, [draftReportData]);

  const [reportCreate] = useMutation(SEND_REPORT, {
    onCompleted: (data) => {
      if (data.reportCreate.errors && data.reportCreate.errors.length > 0) {
        data.reportCreate.errors.map((err) => toast(err, { type: 'error' }));
      } else {
        toast(data.reportCreate.message, { type: 'success' });
        document.getElementById('propertyReport').reset();
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setclients(null);
        props.sidebarToggle();
				if (data.reportCreate?.warning?.length > 0) toast(data.reportCreate.warning, { type: 'info', autoClose: false, hideProgressBar: true });
      }
    },
    refetchQueries: [GUEST_REPORTS],
    onError: (errors) => {
      toast(errors.message, { type: 'error' });
    },
  });

  const onSubmit = async (data, e) => {
		if (activeTab === '1') {
      if (!selectedCity) {
				setCitySelectError(true);
        return false;
      } else {
				setCitySelectError(false);
			}
    }
    if (activeTab === '2') {
      if (clients === null) {
        setClientIdError(true);
        return false;
      } else {
        setClientIdError(false);
      }
    }
    const propertyIds = draftReportData?.draftReport?.properties?.map(property => ({
      propertyId: parseInt(property.id)
    }));
    await reportCreate({
      variables: {
        title: data.title,
        message: data.message,
        hidePropertyName: reportHidePropertyName,
        cityId: selectedCity ? selectedCity.value : null,
        reportProperties: propertyIds,
        newClient: activeTab === '1' ? true : false,
        clientFirstName: firstName,
        clientLastName: lastName,
        clientPhone: phone,
        clientEmail: email,
        clientId: clients ? parseInt(clients.value) : null,
        reportId: parseInt(props.reportId)
      },
    });
  };


	function handleSendDraftReport() {
    const propertyIds = draftReportData?.draftReport?.properties?.map(property => ({
      propertyId: parseInt(property.id)
    }));   
		reportCreate({
			variables: {
			title: reportTitle,
			message: reportMsg,
      hidePropertyName: reportHidePropertyName,
      reportProperties: propertyIds,
			newClient: activeTab === '1' ? true : false,
			clientId: clients ? parseInt(clients.value) : null,
      reportId: parseInt(props.reportId)
		},
	});
	}

  function renderSelectedProperties() {
    return (
      <>
        {draftReportData?.draftReport?.properties?.map((property) => (
          <Col md='6' className='mb-4' key={property.id}>
            <div
              className='d-flex align-items-center'
              style={{
                border: '1px solid var(--gray-300)',
                borderRadius: 'var(--rounded-small)',
                minHeight: '144px',
              }}
            >
              <>
                {property.imageUrls[0] ? (
                  <img
                    className='w-100'
                    style={{ maxHeight: '144px', minHeight: '144px' }}
                    src={property.imageUrls[0]}
                    alt={property.name}
                  />
                ) : (
                  <span className='w-100 text-center'>
                    <small>no image</small>
                  </span>
                )}
                <Icon
                  icon='times'
                  className='remove-item'
                  onClick={() => toggleReportProperty(property.id)}
                />
              </>
            </div>
            {property.name}
          </Col>
        ))}
      </>
    );
  }
  
	useEffect(() => {
		if (clients && clientData){
			var client = clientData.clients.edges.filter(e => e.node.id === clients.value)
			if (client[0].node?.report?.title) {
        setReportTitle(client[0].node.report.title);
      } else if (props.draftTitle) {
        setReportTitle(props.draftTitle);
      } else {
        setReportTitle('');
      }    
			if (client[0].node?.report?.message){
				setReportMsg(client[0].node?.report?.message)
			} else setReportMsg('')

      if (client[0].node?.report){
        setReportHidePropertyName(client[0].node?.report?.hidePropertyName)
      } else setReportHidePropertyName(false)
		}
	}, [clients])

	const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
	};

	const isModifierKey = (event) => {
		const key = event.keyCode;
		return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
				(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
				(key > 36 && key < 41) || // Allow left, up, right, down
				(
						(event.ctrlKey === true || event.metaKey === true) &&
						(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
				)
	};

	const enforceFormat = (event) => {
		if(!isNumericInput(event) && !isModifierKey(event)){
				event.preventDefault();
		}
	};

	const formatToPhone = (event) => {
			if(isModifierKey(event)) {return;}

			const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
			const areaCode = input.substring(0,3);
			const middle = input.substring(3,6);
			const last = input.substring(6,10);

			if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
			else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
			else if(input.length > 0){event.target.value = `(${areaCode}`;}
      setPhone(event.target.value);
	};

	const inputElement = document.getElementById('phoneNumber');
	if (inputElement != null) {
		inputElement.addEventListener('keydown',enforceFormat);
		inputElement.addEventListener('keyup',formatToPhone);
	} 

  return draftReportData?.draftReport ? (
    <>
      <Container className='p-4'>
        <Row>
          <Col md='12'>
            <h4>
              Sending recommendation with {draftReportData?.draftReport?.properties?.length}{' '}
              {draftReportData?.draftReport?.properties?.length > 1 ? 'properties' : 'property'}
            </h4>
          </Col>
        </Row>
        <Row>
          <Col md='6'>
            <Row>
              <Col md='12' className='mt-md-3'>
                <Form
                  className='form'
                  id='propertyReport'
                  onSubmit={handleSubmit(onSubmit)}
                >

                  <Nav tabs className='theme-tabs'>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          toggle('1');
                        }}
                      >
                        <Icon icon='user-plus' />{' '}
                        <span className='d-none d-md-inline-block ml-2'>
                          New Client
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          toggle('2');
                        }}
                      >
                        <Icon icon='user' />{' '}
                        <span className='d-none d-md-inline-block ml-2'>
                          Existing Client
                        </span>
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId='1'>
                      <Row className='inputs-icons'>
                        <Col md='12'>
                          <FormGroup className='mt-4'>
                            <InputGroup>
                              <InputGroupText>
                                <Icon icon='user' />
                              </InputGroupText>
                              <Input
                                placeholder='First Name'
                                type='text'
                                name='firstName'
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                innerRef={register({
                                  required: true,
                                })}
                              />
                            </InputGroup>
                            {_.get('firstName.type', errors) === 'required' && (
                              <p className='input-error'>* First Name required.</p>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md='12'>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupText>
                                <Icon icon='user' />
                              </InputGroupText>
                              <Input
                                placeholder='Last Name'
                                type='text'
                                name='lastName'
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                innerRef={register({
                                  required: true,
                                })}
                              />
                            </InputGroup>
                            {_.get('lastName.type', errors) === 'required' && (
                              <p className='input-error'>* Last Name required.</p>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md='12'>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupText>
                                <Icon icon='envelope' />
                              </InputGroupText>
                              <Input
                                placeholder='Email'
                                type='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                innerRef={register({
                                  required: true,
                                })}
                              />
                            </InputGroup>
                            {_.get('email.type', errors) === 'required' && (
                              <p className='input-error'>* Email required.</p>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md='12'>
                          <FormGroup>
                            <InputGroup>
                              <InputGroupText>
                                <Icon icon='phone' />
                              </InputGroupText>
                              <Input
                                id="phoneNumber"
                                placeholder='Phone'
                                type='text'
                                name='phone'
                                value={phone}
                                onChange={(e) => { if (e.target.value?.length <= 16) setPhone(e.target.value)}}
                                autoComplete='off'
                                innerRef={register({
                                  required: true,
                                  minLength: 16,
                                  maxLength: 16,
                                })}
                              />
                            </InputGroup>
                            {_.get('phone.type', errors) === 'required' && (
                              <p className='input-error'>* Phone No required.</p>
                            )}
                            {_.get('phone.type', errors) === 'minLength' && (
                              <p className='input-error'>* Invalid Phone No</p>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg='12'>
                          <FormGroup>
                            <InputGroup>
                              <Select
                                name='cityId'
                                options={
                                  typeof cityData !== 'undefined'
                                    ? Object.values(cityData.cities).map((city) =>
                                        JSON.parse(
                                          `{"value":${city.id}, "label":"${city.name}"}`
                                        )
                                      )
                                    : []
                                }
                                value={selectedCity}
                                onChange={(selectedOption) =>
                                  setSelectedCityValue(selectedOption)
                                }
                                className='w-100 '
                                classNamePrefix='default-select'
                                id='selectedCity'
                                placeholder='Select Market'
                                isSearchable={true}
                              />
                            </InputGroup>
                            {citySelectError && (
                              <p className='input-error'>* Please select market</p>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId='2'>
                      <Row>
                        <Col md='12'>
                          <FormGroup className='mt-4'>
                            <Select
                              name='clientList'
                              options={
                                typeof clientData !== 'undefined'
                                  ? Object.values(clientData.clients.edges).filter(c => !c.node.discardedAt).map(
                                      (client) =>
                                        JSON.parse(
                                          `{"value":"${client.node.id}", "label":"${client.node.name.replace(/['"]/g, '\\"')}"}`
                                        )
                                    )
                                  : []
                              }
                              onChange={(e) => setclients(e)}
                              className='w-100'
                              classNamePrefix='default-select'
                              placeholder={
                                <>
                                  <Icon icon='users' className='mr-2' /> Client
                                  List
                                </>
                              }
                              value={clients}
                            />
                            {clientIdError && (
                              <p className='input-error'>
                                * Please select client.
                              </p>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <Row>
                    <Col md='12'>
                      {activeTab === '2' && clients && reportTitle.length > 0 ?
                        <div className='recommendation-note mb-3'>
                          <p>
                            You have sent list to this client before with following title and message.<br/>
                            Previously sent properties and newly selected properties by you will be merged, and shown to client.<br/>
                            Feel free to change title and message below.
                          </p>
                        </div> :
                        (clients ||  activeTab === '1') ?
                        <div className='recommendation-note mb-3'>
                          <p>
                            Please enter title and message for the properties you are sending to client.
                          </p>
                        </div> : null
                      }
                    </Col>
                  </Row>
                  {((activeTab === '2' && clients) || activeTab === '1') &&
                    <Row className='text-center inputs-icons'>
                      <Col md='12'>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupText>
                              <Icon icon='id-badge' />
                            </InputGroupText>
                            <Input
                              type='text'
                              placeholder='Title'
                              name='title'
                              value={reportTitle}
                              onChange={(e) => setReportTitle(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md='12'>
                        <FormGroup>
                          <InputGroup>
                            <InputGroupText>
                              <Icon icon='comment-alt' />
                            </InputGroupText>
                            <Input
                              type='textarea'
                              placeholder='Message'
                              name='message'
                              value={reportMsg}
                              onChange={(e) => setReportMsg(e.target.value)}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md='12'>
                        <div className='text-left'>
                          <SimpleToggleButton
                            selected={reportHidePropertyName}
                            firstOption='&nbsp;'
                            secondOption='&nbsp;'
                            width='65px'
                            rounded
                            toggleSelected={() => {
                              setReportHidePropertyName(!reportHidePropertyName);
                            }}
                          />
                          <p style={{ display: 'inline-block', marginLeft: "5px"}}>Hide Property Names (You can always re-activate this later)</p>
                        </div>
                      </Col>
                    </Row>
                  }
                  <Row className='mt-md-2'>
                    <Col lg='12' className='mt-md-2'>
                      {
                        activeTab === '1' ?
                        <Button
                          type='submit'
                          color='primary'
                          className='button-dashboard'
                        >
                          {progress ? 'Progress' : 'Send'}
                        </Button> :
                        <Button
                          color='primary'
                          className='button-dashboard'
                          onClick={handleSendDraftReport}
                        >
                          {progress ? 'Progress' : 'Send'}
                        </Button>
                      }
                      <Button
                        className='button-dashboard ml-2'
                        onClick={() => props.sidebarToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
          <Col md='6'>
            <Row
              className='mt-md-3 pt-3'
              style={{ maxHeight: '80vh'}}
            >
              {renderSelectedProperties()}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  ) : (
    <div
      className='p-4 edit-popup'
      style={{ marginTop: '35%', marginLeft: '45%' }}
    >
      <h4>Loading ...</h4>
    </div>
  );
}
