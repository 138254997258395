import React from 'react';
import { Icon } from '@assets';
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

export default function ShowHideColumns(props) {
  const { options, onChangeCallback, visibleColumns, ...otherProps } = props;
  return (
    <UncontrolledButtonDropdown
      className="cols-dropdown mx-2"
    >
      <DropdownToggle caret className='btn button-dashboard btn-blue'>
        Columns{' '}
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option, i) => (
          <DropdownItem
            key={i}
            onClick={() => {
              onChangeCallback(option);
            }}
          >
            {visibleColumns.find((column, i) => column === option.value) ? (
              <Icon icon='check' className='mr-2' />
            ) : (
              <Icon icon='check' className='mr-2 hidden' />
            )}
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  );
}
