import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select';
import Spinner from '../../components/Spinner';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import { SEARCH_USERS, USER_CREATE, USER_UPDATE, USER, ALL_BROKERS, GENERATE_API_KEY, EXPIRE_API_KEY } from '@api';
import { useMutation, useLazyQuery } from '@apollo/client';
import { ToggleButton, TrippleToggleButton } from '@sparklib';
import { Subscriptions } from './Subscription';
import { Icon } from '@assets';
import moment from 'moment';

export default function UserForm(props) {
  const { sidebarToggle, operation, setUserData } = props;
  const history = useHistory();
  const { id: userId } = useParams();
  const [progress, setProgress] = useState(false);
  const { register, handleSubmit, errors, clearErrors } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const [activeTab, setActiveTab] = useState('User');
  const [form_errors, setErrors] = useState([]);
	const [isUser, setIsUser] = useState(false);
	const [accountTypeValue, setAccountTypeValue] = useState('User');
	const [switchUserToAgent, setSwitchUserToAgent] = useState(false);
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [brokers, setBrokers] = useState(null);
	const [selectedBroker, setSelectedBroker] = useState(null);
	const [brokerSubscription, setBrokerSubscription] = useState(null);
	const [agentSelectedCities, setAgentSelectedCities] = useState(null);
  const newUser = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    licenseNo: '',
    approved: true,
    subscriptions: [],
    brokerageName: '',
    password: '',
    isVa: false,
    isAdmin: false,
    isBroker: false,
		apiKey: null,
		otpRequiredForLogin: true,
  };
  const [user, setUser] = useState(newUser);

	const [fetchAllUsers] = useLazyQuery(SEARCH_USERS, {
		fetchPolicy: 'network-only',
		onCompleted: (data) => {
			if (data.users){
				var filteredUsers = data.users.edges.map(user => user.node);
				setUserData(filteredUsers);
			}
		}
	})
  const [userCreate, { createLoading }] = useMutation(USER_CREATE, {
    onCompleted: (data) => {
      if (data.userCreate.errors && data.userCreate.errors.length > 0) {
        setErrors(data.userCreate.errors);
      } else {
        toast(data.userCreate.message, { type: 'success' });
        sidebarToggle();
				fetchAllUsers();
        history.push(`/users`);
      }
    },
    // refetchQueries: [SEARCH_USERS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

  const [userUpdate, { updateLoading }] = useMutation(USER_UPDATE, {
    onCompleted: (data) => {
      if (data.userUpdate.errors && data.userUpdate.errors.length > 0) {
        setErrors(data.userUpdate.errors);
      } else {
        toast(data.userUpdate.message, { type: 'success' });
        sidebarToggle();
				fetchAllUsers();
        history.push(`/users`);
      }
    },
    // refetchQueries: [SEARCH_USERS],
    onError: (e) => {
      console.log(e, 'error');
    },
  });

	const [fetchAllBrokers, { data: allBrokersData }] = useLazyQuery(ALL_BROKERS);

	useEffect(() => {
		if (allBrokersData?.allBrokers?.length > 0){
			setBrokers(allBrokersData.allBrokers);
			setSelectedBroker({value: allBrokersData.allBrokers[0]?.id, label: allBrokersData.allBrokers[0]?.name});
		}
	}, [allBrokersData])

  useEffect(() => {
    if (userId && userId > 0) refetchUser();
  }, [userId, user.subscriptions]);

  const [userQuery] = useLazyQuery(USER, {
    variables: { id: parseInt(userId) },
    skip: !userId || operation === 'new',
		fetchPolicy: "network-only",
    onCompleted: (data) => {
      clearErrors();
      setActiveTab(
        data.user.isAdmin ? 'Admin' : data.user.isVa ? 'VA' : 'User'
      );
      setUser({
        id: parseInt(data.user.id) || '',
        firstName: data.user.firstName || '',
        lastName: data.user.lastName || '',
        email: data.user.email || '',
        phoneNo: data.user.phoneNo || '',
        licenseNo: data.user.licenseNo || '',
        approved: data.user.approved || false,
        brokerageName: data.user.brokerageName || '',
        isVa: data.user.isVa,
        isAgent: data.user.isAgent,
        isAdmin: data.user.isAdmin,
        isBroker: data.user.isBroker,
        subscriptions: data.user.subscriptions,
        password: '',
				apiKey: data.user.apiKey,
				apiKeyExpiredAt: data.user.apiKeyExpiredAt,
				otpRequiredForLogin: data.user.otpRequiredForLogin,
      });
			if (!data.user.isAdmin && !data.user.isBroker && !data.user.isVa && !data.user.isAgent){
				setIsUser(true);
			}else{
				setIsUser(false);
			}
			resetAgentBrokerData();
    },
    // onError: (error) => {
    //   toast('User account not found', { type: 'error' });
    //   sidebarToggle();
    // },
  });

	const refetchUser = () => {
		if (Number(userId) > 0){			
			userQuery({
				variables: { id: parseInt(userId) },
			});
		}
  };

	const [generateApiKey] = useMutation(GENERATE_API_KEY, {
		// refetchQueries: [refetchUserData],
		onCompleted: (data) => {
			if (data.generateApiKey.errors?.length > 0) {
        toast(data.generateApiKey.message, { type: 'error' });
      } else {
        toast(data.generateApiKey.message, { type: 'success' });
				userQuery({
					variables: { id: parseInt(userId) },
				});
      }
		}
	});

	const handleGenerateApiKey = () => {
		generateApiKey({
			variables: {
				userId: Number(userId)
			}
		})
	};

	const [expireApiKey] = useMutation(EXPIRE_API_KEY, {
		// refetchQueries: [USER],
		onCompleted: (data) => {
			if (data.expireApiKey.errors?.length > 0) {
				toast(data.expireApiKey.message, { type: 'error' });
			} else {
				toast(data.expireApiKey.message, { type: 'success' });
				userQuery({
					variables: { id: parseInt(userId) },
				});
			}
		}
	});

	const handleExpireApiKey = () => {
		expireApiKey({
			variables: {
				userId: Number(userId)
			}
		})
	};

  useEffect(() => {
    setProgress(createLoading || updateLoading ? true : false);
  }, [createLoading, updateLoading]);

  useEffect(() => {
    if (operation === 'new') {
      setActiveTab('User');
      clearErrors();
      setUser(newUser);
    }
  }, [operation]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
    if (tab === 'User') {
      changeUser('isVa', false);
      changeUser('isAdmin', false);
    } else if (tab === 'Admin') {
      changeUser('isVa', false);
      changeUser('isAdmin', true);
    } else if (tab === 'VA') {
      changeUser('isVa', true);
      changeUser('isAdmin', false);
    }
  };

  const changeUser = (label, value) =>
    setUser((user) => {
      return { ...user, [label]: value };
    });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (operation === 'new') {
      await userCreate({
        variables: user,
      });
    } else {
			if (selectedBroker && agentSelectedCities){
				let agentCityIds = agentSelectedCities.map(c => Number(c.value));
				await userUpdate({
					variables: {
						...user,
						cityIds: agentCityIds,
						brokerId: Number(selectedBroker.value)
					}
				});
			}else {
				await userUpdate({
					variables: user,
				});
			}
    }
  };

	function toggleWarningModal(cancel=false){
		if (cancel) {
			setAccountTypeValue('User');
		}
		setShowWarningModal(!showWarningModal);
	}

	useEffect(() => {
		// resetAgentBrokerData();
		if (accountTypeValue === 'Agent'){
			setSwitchUserToAgent(true);
			setShowWarningModal(true);
			changeUser('isBroker', false);
			fetchAllBrokers();
		} else if (accountTypeValue === 'Manager'){
			changeUser('isBroker', true);
			setSwitchUserToAgent(false);
		} else{
			setSwitchUserToAgent(false);
			changeUser('isBroker', false);
		}
	}, [accountTypeValue]);

	useEffect(() => {
		// resetAgentBrokerData();
		setAgentSelectedCities(null);
		if (brokers && selectedBroker){
			var _selectedBroker = brokers.find(b => Number(b.id) === Number(selectedBroker.value));
			var _subscriptions = _selectedBroker.subscriptions.filter(s => s.active);
			if (_selectedBroker){
				setBrokerSubscription(_subscriptions[0]);
			}
		}
	}, [selectedBroker]);

	function resetAgentBrokerData(){
		setSwitchUserToAgent(false);
		setShowWarningModal(false);
		setBrokers(null);
		setSelectedBroker(null);
		setBrokerSubscription(null);
		setAgentSelectedCities(null);
	}

	useEffect(() => {
		setAccountTypeValue('User');
	}, [sidebarToggle]);

	const isNumericInput = (event) => {
    const key = event.keyCode;
    return ((key >= 48 && key <= 57) || // Allow number line
        (key >= 96 && key <= 105) // Allow number pad
    );
	};

	const isModifierKey = (event) => {
		const key = event.keyCode;
		return (event.shiftKey === true || key === 35 || key === 36) || // Allow Shift, Home, End
				(key === 8 || key === 9 || key === 13 || key === 46) || // Allow Backspace, Tab, Enter, Delete
				(key > 36 && key < 41) || // Allow left, up, right, down
				(
						(event.ctrlKey === true || event.metaKey === true) &&
						(key === 65 || key === 67 || key === 86 || key === 88 || key === 90)
				)
	};

	const enforceFormat = (event) => {
		if(!isNumericInput(event) && !isModifierKey(event)){
				event.preventDefault();
		}
	};

	const formatToPhone = (event) => {
			if(isModifierKey(event)) {return;}

			const input = event.target.value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
			const areaCode = input.substring(0,3);
			const middle = input.substring(3,6);
			const last = input.substring(6,10);

			if(input.length > 6){event.target.value = `(${areaCode}) ${middle} - ${last}`;}
			else if(input.length > 3){event.target.value = `(${areaCode}) ${middle}`;}
			else if(input.length > 0){event.target.value = `(${areaCode}`;}
	};

	const justFomatePhoneNo = (str) => {
		var input = str.replace(/\D/g,'').substring(0,10); // First ten digits of input only
		var areaCode = input.substring(0,3);
		var middle = input.substring(3,6);
		var last = input.substring(6,10);
		return `(${areaCode}) ${middle} - ${last}`;
	}

	const inputElement = document.getElementById('phoneNumber');
	if (inputElement != null) {
		inputElement.addEventListener('keydown',enforceFormat);
		inputElement.addEventListener('keyup',formatToPhone);
	} 
	if (user?.phoneNo && user.phoneNo.length < 16){
		var formatedPhoneNo = justFomatePhoneNo(user.phoneNo);
		document.getElementById('phoneNumber').value = formatedPhoneNo;
		if (formatedPhoneNo?.length === 16) changeUser('phoneNo', formatedPhoneNo);
	}

	function toolTip(icon, txt, id){
		return (
			<>
				<span
					href="#"
					id={'tooltip-'+id}
					style={{
						color: 'black',
						textDecoration: 'underline'
					}}
				>
					{icon}
				</span>
				<UncontrolledTooltip
					placement="top"
					target={'tooltip-'+id}
					style={{
						color: 'white',
						backgroundColor: 'black',
						fontWeight: 'bold'
					}}
				>
					{txt}
				</UncontrolledTooltip>
			</>
		)
	}

  return (
    <>
      {progress && <Spinner />}

			{showWarningModal &&
				<Modal isOpen={showWarningModal} toggle={toggleWarningModal}>
					<ModalHeader toggle={toggleWarningModal}>Warning</ModalHeader>
					<ModalBody>
						If you switch account from user to agent the user's current subscription will be cancelled and start using the broker's subscription.<br /><br />
						Are you sure you want to switch user to agent?
					</ModalBody>
					<ModalFooter>
						<Button
							color='danger'
							onClick={() => toggleWarningModal()}
						>
							Yes
						</Button>
						<Button color='success' onClick={() => toggleWarningModal(true)}>
							No
						</Button>
					</ModalFooter>
				</Modal>
			}

      <div className='p-4'>
        <Row>
          <Col md='12'>
            <h4>{operation === 'new' ? 'Create' : 'Edit'} Account</h4>
          </Col>
          <Col md='12'>
            {form_errors.length > 0 ? (
              <Col>
                {form_errors.map((err, index) => (
                  <Col className='bg-danger mt-2 text-white' key={index}>
                    {err}
                  </Col>
                ))}
              </Col>
            ) : null}
          </Col>
          <Col md='12' className='mt-md-3 inputs-icons'>
            <Row>
              <Col md='12' className='panel-tabs'>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'User' ? 'active' : ''}
                      onClick={() => {
                        toggle('User');
                      }}
                      disabled={operation !== 'new' ? true : false}
                    >
                      User
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'Admin' ? 'active' : ''}
                      onClick={() => {
                        toggle('Admin');
                      }}
                      disabled={operation !== 'new' ? true : false}
                    >
                      Admin
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === 'VA' ? 'active' : ''}
                      onClick={() => {
                        toggle('VA');
                      }}
                      disabled={operation !== 'new' ? true : false}
                    >
                      Virtual Assistant
                    </NavLink>
                  </NavItem>
                </Nav>

                {/* <AccountForm /> */}
                <Form onSubmit={handleSubmit(onSubmit)} id='create-user-form'>
                  <Row className='mt-3'>
                    {user.isVa === false && user.isAdmin === false && (
                      <Col lg='6' className='mb-3 d-flex align-items-center'>
                        <Label className='mb-0 mr-3'>Account</Label>
                        <ToggleButton
                          selected={user.approved}
                          firstOption='Active'
                          secondOption='Inactive'
                          width='120px'
                          rounded
                          toggleSelected={() => {
                            changeUser('approved', !user.approved);
                          }}
                        />
                      </Col>
                    )}
                  {/* </Row>
                  <Row className='mt-3'> */}
                    {/* {(user.isVa === false && user.isAdmin === false && user.isBroker === false && user.brokerId === null) && ( */}
                    {isUser && (
                      <Col lg='6' className='mb-3 d-flex align-items-center'>
                        <Label className='mb-0 mr-3'>Account Type</Label>
                        {/* <ToggleButton
                          selected={user.isBroker}
                          firstOption='Broker'
                          secondOption='User'
                          width='120px'
                          rounded
                          toggleSelected={() => {
                            changeUser('isBroker', !user.isBroker);
                          }}
                        /> */}
												<TrippleToggleButton 
													values={["User", "Manager", "Agent"]} 
													selected={accountTypeValue} 
													setSelected={setAccountTypeValue} 
												/>
                      </Col>
                    )}
										{ switchUserToAgent &&
											<>
												<Col lg='6'>
													<FormGroup className='text-left'>
														<Label for='broker'>Managers</Label>
														<Select
															value={selectedBroker}
															name='broker'
															classNamePrefix='default-select'
															isMulti={false}
															placeholder={<>Select Manager</>}
															options={
																brokers && brokers.length > 0
																	? Object.values(brokers).map(
																			(broker) =>
																				JSON.parse(
																					`{"value":${broker.id}, "label":"${broker.name}"}`
																				)
																		)
																	: []
															}
															onChange={(broker) => {
																setSelectedBroker(broker)
															}}
															className='w-100'
														/>
													</FormGroup>
												</Col>
												<Col lg='6'>
													<FormGroup className='text-left'>
														<Label for='market'>Market Access</Label>
														<Select
															value={agentSelectedCities}
															name='marketAccess'
															classNamePrefix='default-select'
															isMulti={true}
															placeholder={<>Select Market</>}
															options={
																brokerSubscription && brokerSubscription.cities.length > 0
																	? Object.values(brokerSubscription.cities).map(
																			(city) =>
																				JSON.parse(
																					`{"value":${city.id}, "label":"${city.name}"}`
																				)
																		)
																	: []
															}
															onChange={(selectedCity) => {
																brokerSubscription.plan.maxCities === 1
																? setAgentSelectedCities(selectedCity)
																: selectedCity.length <= brokerSubscription.plan.maxCities ||
																	brokerSubscription.plan.maxCities === -1
																? setAgentSelectedCities(selectedCity)
																: null;
															}}
															className='w-100'
														/>
													</FormGroup>
												</Col>
											</>
										}
                  </Row>
                  <Row>
                    <Col lg='6'>
                      <FormGroup>
                        <Label for='email'>Email</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='envelope' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='email'
                            value={user.email}
                            onChange={(e) =>
                              changeUser(e.target.name, e.target.value)
                            }
                            autoComplete='email'
                            innerRef={register({
                              required: '* Email is required.',
                              pattern: {
                                value:
                                  /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/,
                                message: '* Invalid email.',
                              },
                            })}
                          />
                        </InputGroup>
                        {_.get('email.type', errors) && (
                          <p className='input-error'>{errors.email?.message}</p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg='6'>
                      <FormGroup>
                        <Label for='password'>Password</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='eye' />
                          </InputGroupText>
                          <Input
                            type='password'
                            name='password'
                            autoComplete='new-password'
                            value={user.password}
                            onChange={(e) =>
                              changeUser(e.target.name, e.target.value)
                            }
                            innerRef={register({
                              required: operation === 'new' ? true : false,
                              minLength: 6,
                            })}
                          />
                        </InputGroup>
                        {_.get('password.type', errors) === 'required' && (
                          <p className='input-error'>* Password is required.</p>
                        )}
                        {_.get('password.type', errors) === 'minLength' && (
                          <p className='input-error'>
                            * Password should be minimum 6 letters
                          </p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg='4'>
                      <FormGroup>
                        <Label for='firstName'>First Name</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='user' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='firstName'
                            autoComplete='off'
                            value={user.firstName}
                            onChange={(e) =>
                              changeUser(e.target.name, e.target.value)
                            }
                            innerRef={register({
                              required: true,
                            })}
                          />
                        </InputGroup>
                        {_.get('firstName.type', errors) === 'required' && (
                          <p className='input-error'>
                            * First Name is required.
                          </p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg='4'>
                      <FormGroup>
                        <Label for='lastName'>Last Name</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='user' />
                          </InputGroupText>
                          <Input
                            type='text'
                            name='lastName'
                            value={user.lastName}
                            onChange={(e) =>
                              changeUser(e.target.name, e.target.value)
                            }
                            autoComplete='off'
                            innerRef={register({
                              required: true,
                            })}
                          />
                        </InputGroup>
                        {_.get('lastName.type', errors) === 'required' && (
                          <p className='input-error'>
                            * Last Name is required.
                          </p>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg='4'>
                      <FormGroup>
                        <Label for='phoneNo'>Phone</Label>
                        <InputGroup>
                          <InputGroupText>
                            <Icon icon='phone-alt' />
                          </InputGroupText>
                          <Input
														id='phoneNumber'
                            type='text'
                            name='phoneNo'
                            value={user.phoneNo}
                            onChange={(e) => {
                              if (e.target.value?.length <= 16) changeUser(e.target.name, e.target.value)
														}}
                            autoComplete='off'
														innerRef={register({
															minLength: 16,
															maxLength: 16,
														})}
                          />
                        </InputGroup>
												{(_.get('phoneNo.type', errors) === 'minLength' || _.get('phoneNo.type', errors) === 'maxLength') && (
													<p className='input-error'>* Invalid Phone Number.</p>
												)}
                        {/* {_.get('phoneNo.type', errors) === 'required' && (
                          <p className='input-error'>* Phone is required.</p>
                        )}
                        {_.get('phoneNo.type', errors) === 'pattern' && (
                          <p className='input-error'>* Only numbers allowed.</p>
                        )} */}
                      </FormGroup>
                    </Col>
                    {user.isVa === false && user.isAdmin === false && (
                      <>
                        <Col lg='6'>
                          <FormGroup>
                            <Label for='brokerageName'>Brokerage Name</Label>
                            <InputGroup>
                              <InputGroupText>
                                <Icon icon='building' />
                              </InputGroupText>
                              <Input
                                type='text'
                                name='brokerageName'
                                value={user.brokerageName}
                                autoComplete='off'
                                onChange={(e) =>
                                  changeUser(e.target.name, e.target.value)
                                }
																// disabled={user.isAgent}
                                // innerRef={register({
                                //   required: true,
                                // })}
                              />
                            </InputGroup>
														{/* 
                            {_.get('brokerageName.type', errors) ===
                              'required' && (
                              <p className='input-error'>
                                * Brokerage Name required.
                              </p>
                            )} */}
                          </FormGroup>
                        </Col>
                        <Col lg='6'>
                          <FormGroup>
                            <Label for='licenseNo'>License Number</Label>
                            <InputGroup>
                              <InputGroupText>
                                <Icon icon='id-badge' />
                              </InputGroupText>
                              <Input
                                type='text'
                                name='licenseNo'
                                value={user.licenseNo}
                                onChange={(e) =>
                                  changeUser(e.target.name, e.target.value)
                                }
                                autoComplete='off'
                                innerRef={register({
                                  required: true,
                                })}
                              />
                            </InputGroup>
                            {_.get('licenseNo.type', errors) === 'required' && (
                              <p className='input-error'>
                                * License Number required.
                              </p>
                            )}
                          </FormGroup>
                        </Col>
                      </>
                    )}
										<Col md='6'>
											<FormGroup>
												{/* <Label check>Secure Login</Label> */}
												<InputGroup className='align-items-center pt-2'>
													<Label check>Two Factor Authentication </Label>&nbsp;&nbsp;
													<ToggleButton
														selected={user.otpRequiredForLogin}
														firstOption='ON'
														secondOption='OFF'
														width='85px'
														rounded
														toggleSelected={() => {
															changeUser("otpRequiredForLogin", !user.otpRequiredForLogin);
														}}
													/>
												</InputGroup>
											</FormGroup>
										</Col>
										{ user.apiKey ?
											<>
												<Col lg="12">
													<Label for='phoneNo'>API Key</Label>
													<InputGroup>
														<InputGroupText className='pointer'>
															<Icon icon='copy' onClick={() => navigator.clipboard.writeText(user.apiKey)}/>
														</InputGroupText>
														<Input
															type='text'
															name='licenseNo'
															value={user.apiKey}
														/>&nbsp;&nbsp;
														{toolTip(<Icon icon="undo" size="lg" className='mt-2 text-primary pointer' onClick={handleGenerateApiKey}/>, "Regenerate API key", 102)}&nbsp;&nbsp;
														{toolTip(<Icon icon="trash" size="lg" className='mt-2 text-danger pointer' onClick={handleExpireApiKey}/>, "Revoke API key access", 103)}&nbsp;
													</InputGroup>
													{user.apiKeyExpiredAt && <p className='error-msg'>Your access to Spark API key has revoked </p>}
												</Col>
											</> : 
											<>
												<Col lg="12">
													<Label for='phoneNo'>API Key</Label>&nbsp;&nbsp;
													{toolTip(<Icon icon="plus" size="lg" className='mt-2 text-primary pointer' onClick={handleGenerateApiKey}/>, "Generate Spark API key", 101)}
												</Col>
											</>
										}
                    <Col lg='12' className='mt-md-3'>
                      <Button
                        color='primary'
                        className='button-dashboard'
                        disabled={progress}
                      >
                        {operation === 'new' ? 'Create' : 'Update'} Account
                      </Button>
                      <Button
                        className='button-dashboard ml-2'
                        onClick={() => sidebarToggle()}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              {user.isVa === false &&
                user.isAdmin === false &&
                operation != 'new' && (
                  <>
                    <Col lg='12'>
                      <hr />
                    </Col>
                    <Col lg='12' className=''>
                      <FormGroup>
                        <Subscriptions
                          userId={user.id}
													user={user}
                          subscriptions={user.subscriptions}
                          setSubscriptions={(newSubscriptions) => {
                            setUser({
                              ...user,
                              subscriptions: newSubscriptions,
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </>
                )}
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
