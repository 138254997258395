import { createContext } from 'react';

// Context is needed to read filter values otherwise columns are
// re-created when filters are changed and filter loses focus
export const FilterContext = createContext(undefined);

export function rowKeyGetter(row) {
  return row.id;
}

export function getComparator(sortColumn) {
  switch (sortColumn) {
    case 'name':
    case 'specials':
    case 'unitType':
    case 'bed':
    case 'email':
    case 'address':
    case 'managementCompany':
    case 'webLink':
    case 'googleReviewLink':
    case 'googleMap':
    case 'floorPlanLink':
    case 'photoGalleryLink':
    case 'aptNo':
    case 'communicationMethod':
    case 'essentialHousing':
      return (a, b) => {
        return a[sortColumn]?.localeCompare(b[sortColumn]);
      };
    case 'zip':
      return (a, b) => {
        // console.log(sortColumn,"[sortColumn]");
        // console.log(a["node"]["name"],"a[node][name]");
        // console.log(b,"b[sortColumn]");
        // =>  return a['node'][sortColumn].localeCompare(b['node'][sortColumn]);
        return a[sortColumn]?.localeCompare(b[sortColumn]);
      };
    case 'city':
      return (a, b) => {
        // console.log(a,b,"Sorting by neighbor");
        return a?.city?.name?.localeCompare(b?.city?.name);
      };
    case 'neighborhoodId':
      return (a, b) => {
        // console.log(a,b,"Sorting by neighbor");
        return a?.neighborhood?.name?.localeCompare(b?.neighborhood?.name);
      };
    case 'phone':
      return (a, b) => {
        return a[sortColumn]
          ?.replaceAll(/\s|\(|\)|\-/gi, '')
          .localeCompare(b[sortColumn]?.replaceAll(/\s|\(|\)|\-/gi, ''));
      };
    case 'bonus':
      return (a, b) => {
        return (
          (Number(a[sortColumn]?.replaceAll(/\s|\,|\$/gi, '')) || -1) -
          (Number(b[sortColumn]?.replaceAll(/\s|\,|\$/gi, '')) || -1)
        );
      };
    // case 'city':
    //   return (a, b) => {
    //     return a['city']['name'].localeCompare(b['city']['name']);
    //   };
    case 'unitsUpdatedAt':
      return (a, b) => {
        return new Date(a[sortColumn]) - new Date(b[sortColumn]);
      };
    case 'specialsUpdatedAt':
      return (a, b) => {
        return new Date(a[sortColumn]) - new Date(b[sortColumn]);
      };
    case 'moveIn':
      return (a, b) => {
        return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
      };
    case 'googleRating':
    case 'bath':
    case 'lat':
    case 'long':
      return (a, b) => {
        return parseFloat(a[sortColumn]) - parseFloat(b[sortColumn]);
      };
    case 'units':
    case 'isConfirmed':
    case 'escort':
    case 'sendEscort':
    case 'builtYear':
    case 'renovated':
    case 'size':
    case 'sqft':
    case 'price':
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    case 'incomeRequirementLevel':
      return (a, b) => {
        return ('' + a[sortColumn]).localeCompare(b[sortColumn]);
      };
    case 'typeDetails':
    case 'imageThumbUrls':
      return (a, b) => {
        return a[sortColumn].length - b[sortColumn].length;
      };
    case 'displayFloorPlanStatus':
      return (a, b) => {
        return a[sortColumn]?.localeCompare(b[sortColumn]);
      };
    case 'manualPricingFrequency':
      return (a, b) => {
        return a[sortColumn]?.localeCompare(b[sortColumn]);
      };
    case 'specialsAndCommissionPriority':
      return (a, b) => {
        return a[sortColumn]?.localeCompare(b[sortColumn]);
    };
    default:
      console.log(`Unsupported sortColumn: ${sortColumn}`);
      return () => {};
    // throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

export function inputStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
    event.stopPropagation();
  }
}

export function escapeStopPropagation(event) {
  if (['Escape'].includes(event.key)) {
    event.preventDefault();
  }
}

export function selectStopPropagation(event) {
  if (['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(event.key)) {
    event.stopPropagation();
  }
}
