import React, { useState, useEffect } from 'react';
import { TRIAL_PLANS } from '@api';
import { useQuery } from '@apollo/client';
import Spinner from '../components/Spinner';
import NavBar from '../components/NavBar';
import Footer from '../components/footer';
import { Link } from 'react-router-dom';
import { ToggleButton } from '@sparklib';
import PricingPlanCard from '../components/PricingPlanCard';

import {
  Container,
  Row,
  Col,
  Button,
  CardGroup,
  Card,
  CardTitle,
  CardBody,
  CardText,
  CardSubtitle,
} from 'reactstrap';

export default function Pricing() {
  const [isYearly, setIsYearly] = useState(true);
  const [plans, setPlans] = useState([]);
  const {
    data: plansData,
    loading,
    error,
  } = useQuery(TRIAL_PLANS, {
    variables: {},
  });

  useEffect(() => {
    if (plansData) {
      let newPlans = [...plansData.trialPlans];
      newPlans.sort(function (a, b) {
        return parseFloat(a.amount) - parseFloat(b.amount);
      });
      setPlans(newPlans);
    }
  }, [plansData]);

  const staticPlans = [
    //red, blue, yellow, green
    { price: 159, color: 'blue', highlight: false },
    { price: 179, color: 'red', highlight: true },
    { price: 219, color: 'blue', highlight: false },
    { price: 1668, color: 'blue', highlight: false },
    { price: 1908, color: 'red', highlight: true },
    { price: 2388, color: 'blue', highlight: false },
    { color: 'blue', highlight: false },
  ];
  return (
    <>
      {loading && <Spinner />}
      <NavBar />
      <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Pricing Plans</h1>
        </Container>
      </Container>

      <Container className='py-4'>
        <Row>
          <Col
          // md={{ size: '10', offset: '1' }}
          // lg={{ size: '10', offset: '1' }}
          >
            <Row className='mb-4'>
              <Col md='12'>
                <div className='mx-auto w-100 text-center'>
                  <h6>
                    Choose the plan that suits your requirements.
                    <br />
                    We recommend yearly plans to save on subscription payment.
                  </h6>
                  <hr />
                  <ToggleButton
                    selected={isYearly}
                    firstOption='Yearly'
                    secondOption='Monthly'
                    width='130px'
                    rounded
                    toggleSelected={() => {
                      setIsYearly(!isYearly);
                    }}
                  />
                  <br />
                </div>
              </Col>
            </Row>

            <Row>
              <Col md='12'>
                <div className='text-center mx-auto'>
                  {/* <CardGroup> */}
                  <Row className='d-flex align-items-end justify-content-center'>
                    {plans &&
                      plans.map(
                        ({ id, name, amount, interval, maxCities }, i) =>
                          interval === (isYearly ? 'year' : 'month') && (
                            <Col md='6' lg='3' key={id}>
                              <PricingPlanCard
                                highlight={
                                  staticPlans[i] && staticPlans[i].highlight
                                }
                                color={
                                  staticPlans[i]
                                    ? staticPlans[i].color
                                    : 'yellow'
                                }
                                planName={name}
                                original_amount={
                                  staticPlans[i] && staticPlans[i].price
                                    ? staticPlans[i].price
                                    : 0
                                }
                                amount={amount}
                                interval={interval}
                                cityAccess={
                                  maxCities && maxCities === -1
                                    ? 'All'
                                    : maxCities
                                }
                              />
                            </Col>
                          )
                      )}
                    <Col md='6' lg='3'>
                      <Card className='pricing-card green h-100'>
                        <CardBody>
                          <CardTitle tag='h3'>Enterprise</CardTitle>
                          <CardText
                            className='h-100 py-5'
                            style={{ minHeight: '239px' }}
                          >
                            <div style={{ paddingTop: '12px' }}>
                              Multiuser Platform
                              <br />
                              with user management
                              <br />
                              &<br />
                              ... a lot more coming up.
                            </div>
                          </CardText>
                          <CardText className='py-0 pb-4 px-4'>
                            <Link to='/contact-us'>
                              <Button
                                color='green'
                                className='btn button-dashboard'
                              >
                                Contact Sales
                              </Button>
                            </Link>
                          </CardText>
                        </CardBody>
                      </Card>
                    </Col>

                    {/* </CardGroup> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
}
