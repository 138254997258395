import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
	split
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import config from '@config';
import { getMainDefinition } from '@apollo/client/utilities';
import ActionCable from 'actioncable';
import ActionCableLink from "graphql-ruby-client/subscriptions/ActionCableLink";

// const errorLink = onError(
//   ({ response, operation }) => {
//     /* if (graphQLErrors)
//       graphQLErrors.forEach(({ message, locations, path }) =>
//         console.log(
//           `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
//         )
//       );

//     if (networkError) console.log(`[Network error]: ${networkError}`); */
//     if (operation.operationName === "IgnoreErrorsQuery") {
//       response.errors = null;
//     }
//   }
// );


// function logoutUser() {
// 	const history = useHistory();
// 	console.log("Calling logout user");

// 	toast("Session Expired", { type: "error" });
// 	history.push(`/login`);
// 	return 
// }


// async function getNewAuthToken(){
// 	const response = await fetch(`${config.url.REACT_APP_BACKEND_BASE_URL}refresh_token`, {
// 		method: 'POST',
// 		headers: {'Content-Type': 'application/json'},
// 		credentials: "include",
// 		body: JSON.stringify({ auth_token: localStorage.getItem("auth_token") })
// 	});
// 	let newToken = await response.json();
// 	if (newToken.auth_token){
// 		localStorage.setItem('auth_token', newToken.auth_token)
// 	} else {
// 		toast("Session Expired", { type: "error" });
// 		window.location.replace("/logout");
// 	}
// 	return newToken.auth_token;
// }
const cable = ActionCable.createConsumer(config.url.ACTION_CABLE_URL);
const wsLink = new ActionCableLink({ cable });

// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { createClient } from 'graphql-ws';

// const wsLink = new GraphQLWsLink(createClient({
//   url: config.url.ACTION_CABLE_URL,
// 	connectionParams: {
//     authToken: user.authToken,
//   },
// }));

// async function signOut(){
// 	await fetch(`${config.url.REACT_APP_BACKEND_BASE_URL}sign_out`, {
// 		method: 'POST',
// 		headers: {'Content-Type': 'application/json'},
// 		credentials: "include",
// 	});
// }

const errorLink = onError( ({ graphQLErrors, networkError, operation, forward }) => {
	for (let err of graphQLErrors) {
		switch (err.message) {
			case "Token Expired":
				localStorage.clear();
				localStorage.setItem("session_expired", true);
				window.location.replace("/login");
				break;

			case "Spark API key has expired":
				localStorage.clear();
				localStorage.setItem("api_key_expired", true);
				window.location.replace("/login");
				break;

			default:
				return;
		}
	}
	if (networkError) console.log(`[Network error]: ${networkError}`);
});

const options = {
  uri: config.url.REACT_APP_BACKEND_BASE_URL,
  credentials: 'include',
};

// Found in an article written in 2018
// const httpLink = ApolloLink.split(
//   operation => operation.getContext().hasUpload,
//   createUploadLink(options),
//   new BatchHttpLink(options)
// );

const httpLink = ApolloLink.split(
  (operation) => operation.getContext().hasUpload,
  createUploadLink(options),
  new HttpLink(options)
);

// const retryLink = new RetryLink({
//   delay: {
//     initial: 0,
//     max: 0,
//     jitter: true
//   },
//   attempts: {
//     max: 2,
//     retryIf: (error, _operation) => !!error
//   }
// });

// ORIGINAL
// const httpLink = new HttpLink({
//   uri: config.url.REACT_APP_BACKEND_BASE_URL,
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('auth_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  /* link: link, */
  // link: from([errorLink, link]),
  link: from([errorLink, authLink, splitLink]),
  // headers: {
  //   authorization: localStorage.getItem("auth_token")
  // }
  // Provide some optional constructor fields
  name: 'sparkapt-client',
  version: '1.0',
  // queryDeduplication: false, // WARNING: Will send same query to server every time
  // defaultOptions: {
  //   watchQuery: {
  //     fetchPolicy: 'cache-and-network', // First try looking in cache, and also send to server. Loads the server.
  //   },
  // },
});




// ============================ Start ============================

// async function getNewAuthToken(){
// 	const response = await fetch(`${config.url.REACT_APP_BACKEND_BASE_URL}refresh_token`, {
// 		method: 'POST',
// 		headers: {'Content-Type': 'application/json'},
// 		body: JSON.stringify({ auth_token: localStorage.getItem("auth_token"), refresh_token: localStorage.getItem("refresh_token") })
// 	});
// 	let newToken = await response.json();
// 	return newToken.auth_token;
// }

// const errorLink = onError( async ({ graphQLErrors, networkError, operation, forward }) => {

// 	for (let err of graphQLErrors) {
// 		switch (err.message) {
// 			case "Token Expired":
// 				// try {
// 					const newAuthToken = await getNewAuthToken();
// 					if (newAuthToken){
// 						localStorage.setItem('auth_token', newAuthToken);
// 						const oldHeaders = operation.getContext().headers;
// 						console.log("OLD HEADER: ", oldHeaders)
// 						// modify the operation context with a new token
// 						operation.setContext({
// 							headers: {
// 								...oldHeaders,
// 								authorization: `Bearer ${newAuthToken}`,
// 							},
// 						});
// 						console.log("Access token refreshed successfully", newAuthToken);
// 						console.log("NEW HEADERS", operation.getContext().headers)
// 						return forward(operation);
// 					} else {
// 						// history.push(`/logout`);
// 						logoutUser();
// 					}
// 				// } catch (e) {
// 				// 	toast('Session expired, Please log to continue', { type: 'error' });
// 				// 	// history.push(`/logout`);
// 				// 	logoutUser();
// 				// }
// 		}
// 	}
// 	if (networkError) console.log(`[Network error]: ${networkError}`);
// });

// ============================ End ============================
