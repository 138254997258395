import React, { useState, useEffect } from 'react';
import { Icon } from '@assets';
import {
	Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

export default function FilterMenu({ name, options, selectedOptions, onClickToggle, styles }) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} style={{...styles}}>
			<DropdownToggle caret>{name}</DropdownToggle>
      <DropdownMenu>
        {options.map((option, i) => (
          <DropdownItem
            key={i}
            onClick={() => {
              onClickToggle(option);
            }}
          >
            {selectedOptions.find((column) => column.value === option.value) ? (
              <Icon icon='check' className='mr-2' />
            ) : (
              <Icon icon='check' className='mr-2 hidden' />
            )}
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
