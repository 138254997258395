import React, { useState, useEffect } from 'react';
import { CITIES, UPDATE_PROPERTY_VIEW_SETTING, MY_PROFILE} from '@api';
import { useQuery, useMutation } from "@apollo/client";
import { toast } from 'react-toastify';
import { 
  FormGroup, 
  Input, 
  Label,
  Row,
  Col,
  Button 
} from 'reactstrap';

export default function PropertiesPageSettings(){
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedView, setSelectedView] = useState('');
  const { data: cityData } = useQuery(CITIES);
  const { data: profileData } = useQuery(MY_PROFILE);

  useEffect(() => {
    if (cityData && cityData.cities) {
      setCities(cityData.cities);
    }
  }, [cityData]);

  useEffect(() => {
    if (profileData && profileData.myProfile) {
      const { defaultCityId, defaultPropertyView } = profileData.myProfile;
      // Update selected city and view based on profile data
      if (defaultCityId) {
        const selectedCity = cities.find(city => city.id === String(defaultCityId));
        setSelectedCity(selectedCity);
      }
      if (defaultPropertyView) {
        setSelectedView(defaultPropertyView);
      }
    }
  }, [profileData, cities]);

  const handleCityChange = (city) => {
    setSelectedCity(city);
  };

  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  const [updatePropertyView] = useMutation(UPDATE_PROPERTY_VIEW_SETTING, {
    onCompleted: (data) => {
      if (
        data.updatePropertyViewSetting.errors &&
        data.updatePropertyViewSetting.length > 0
      ) {
        data.updatePropertyViewSetting.errors.map((err) =>
          toast(err, { type: 'error' })
        );
      } else {
        toast(data.updatePropertyViewSetting.message, { type: 'success' });
        localStorage.setItem('default_city_id', data.updatePropertyViewSetting.user.defaultCityId);
        localStorage.setItem('default_property_view', data.updatePropertyViewSetting.user.defaultPropertyView);
      }
    },
    onError: (e) => {
      console.log(e);
      toast(e.message, { type: 'error' });
    },
  });

  const handleSubmit = () => {
    updatePropertyView({
      variables: {
        defaultCityId: parseInt(selectedCity?.id),
        defaultPropertyView: selectedView
      }
    });
  };

  return(
    <>
    <FormGroup tag="fieldset">
      <legend className='pt-4 text-center'>Default City</legend>
      {cities.map((city) => (
        <FormGroup key={city.id} className='text-center' check>
          <Label check>
            <Input 
              type="radio" 
              name="city" 
              checked={selectedCity?.id === city.id}
              onChange={() => handleCityChange(city)} 
              /> 
             {city.name}
          </Label>
        </FormGroup>
      ))}
    </FormGroup>
    <FormGroup tag="fieldset">
      <legend className='pt-4 text-center'>Default View</legend>
      <FormGroup className='text-center' check>
        <Label check>
          <Input 
            type="radio" 
            name="view"
            checked={selectedView === 'list'}
            onChange={() => handleViewChange('list')} />
            List
        </Label>
      </FormGroup>
      <FormGroup className='text-center' check>
        <Label check>
          <Input 
            type="radio" 
            name="view"
            checked={selectedView === 'card'}
            onChange={() => handleViewChange('card')} />
            Card/Grid
        </Label>
      </FormGroup>
    </FormGroup>

    <Row>
      <Col md='12' className='text-center'>
        <Button color='primary' className='button-dashboard' type='submit' onClick={handleSubmit}>
          Update
        </Button>
      </Col>
    </Row>
    </>
  )
}