import React, { useEffect, useState } from 'react';
import { CITIES, NEIGHBORHOODS, ZIP_CODES } from '@api';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { Label, Row, Col } from 'reactstrap';

export default function CityNeighborhood({
  filters,
  getFilter,
  addFilter,
  style,
  activeFilterPill,
}) {
  const cities = useQuery(CITIES, {
    onCompleted: (data) => {
      if (data.cities && data.cities.length > 0) {
        if (data.cities[0].maplineUrl) {
          const maplineIframeCollection = document.getElementsByClassName("maplineIframe");
          for (let i = 0; i < maplineIframeCollection.length; i++) {
            maplineIframeCollection[i].src = data.cities[0].maplineUrl;
          }
        }
        else {
          const maplineIframeCollection = document.getElementsByClassName("maplineIframe");
          for (let i = 0; i < maplineIframeCollection.length; i++) {
            maplineIframeCollection[i].src = "";
          }
        }
      }
    }
  });
  const { data: citiesData } = cities;

  const { data: neighborhoodData } = useQuery(NEIGHBORHOODS, {
    variables: { cityId: Number(JSON.parse(getFilter('city'))?.value) },
    skip: !JSON.parse(getFilter('city'))?.value,
  });

  const { data: zipCodesData } = useQuery(ZIP_CODES, {
    variables: { cityId: Number(JSON.parse(getFilter('city'))?.value) },
    skip: !JSON.parse(getFilter('city'))?.value,
  });

  // This function is triggered when the checkbox changes
  const [isMapShown, setIsMapShown] = useState(true);

  const checkboxHandler = () => {
    setIsMapShown(!isMapShown);
  };

  useEffect(() => {
    if (
      citiesData &&
      citiesData.cities.length > 0 &&
      getFilter('city') === '{}'
    ) {
      const defaultCityId = JSON.parse(localStorage.getItem('default_city_id'));
      if (defaultCityId) {
        const defaultCity = citiesData.cities.find(city => city.id.toString() === String(defaultCityId));
        addFilter({
          e: `{"value":"${defaultCity.id}", "label":"${defaultCity.name}"}`,
          key: 'city',
        });
      }
      else {
        addFilter({
          e: `{"value":"${citiesData.cities[0].id}", "label":"${citiesData.cities[0].name}"}`,
          key: 'city',
        });
      }
    }
  }, [citiesData]);

  return (
    <div
      className={`filter-box-container ${
        activeFilterPill !== 'cityAndNeighborhoods' && 'hidden'
      }`}
    >
      <div className='filter-box' style={style}>
        <h6>Market</h6>
        <Row>
          {typeof citiesData !== 'undefined'
            ? citiesData.cities.map((city, index) => (
                <Col xs='4' key={index}>
                  <div className='' key={index}>
                    <Label className=''>
                      <input
                        className='d-inline-block w-auto mr-1'
                        type='radio'
                        name='city'
                        value={`{"value":"${city.id}", "label":"${city.name}"}`}
                        onChange={(e) => {
                          if (e.target.checked) {
                            addFilter({
                              e,
                              key: 'city',
                            });
                            addFilter({
                              e: [],
                              key: 'neighborhoods',
                            });
                            addFilter({
                              e: [],
                              key: 'zipCodes',
                            });
                            var selectedCityId = JSON.parse(e.target.value)?.value;
                            if (selectedCityId) {
                              var selectedCity = citiesData.cities.filter((city) => city.id === selectedCityId);
                              if (selectedCity) {
                                if (selectedCity[0].maplineUrl) {
                                  const maplineIframeCollection = document.getElementsByClassName("maplineIframe");
                                  for (let i = 0; i < maplineIframeCollection.length; i++) {
                                    maplineIframeCollection[i].src = selectedCity[0].maplineUrl;
                                  }
                                }
                                else {
                                  const maplineIframeCollection = document.getElementsByClassName("maplineIframe");
                                  for (let i = 0; i < maplineIframeCollection.length; i++) {
                                    maplineIframeCollection[i].src = "";
                                  }
                                }
                              }
                            }
                          }
                        }}
                        checked={
                          getFilter('city') === `{"value":"${city.id}", "label":"${city.name}"}`
                        }
                      />{' '}
                      {city.name}
                    </Label>
                  </div>
                </Col>
              ))
            : null}
        </Row>
        <h6 className='mt-2'>Neighborhoods</h6>
        <Row>
          <Col md='12'>
            <Select
              name='Neighborhood'
              isMulti
              value={getFilter('neighborhoods')}
              options={
                typeof neighborhoodData !== 'undefined'
                  ? Object.values(neighborhoodData.neighborhoods).map(
                      (neighborhood) =>
                        JSON.parse(
                          `{"value":"${neighborhood.id}", "label":"${neighborhood.name}"}`
                        )
                    )
                  : []
              }
              onChange={(e) => addFilter({ e, key: 'neighborhoods' })}
              className='basic-multi-select w-100'
              classNamePrefix='default-select'
              placeholder='Type a few characters and press enter...'
              menuPlacement={'auto'}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              closeMenuOnSelect={false}
            />
          </Col>
        </Row>
        
        <h6 className='mt-2'>Zip Codes</h6>
        <Row>
          <Col md='12'>
            <Select
              name='zipCodes'
              isMulti
              value={getFilter('zipCodes')}
              options={
                typeof zipCodesData !== 'undefined'
                  ? Object.values(zipCodesData.zipCodes).map(
                      (zipCode) =>
                        JSON.parse(
                          `{"value":"${zipCode}", "label":"${zipCode}"}`
                        )
                    )
                  : []
              }
              onChange={(e) => addFilter({ e, key: 'zipCodes' })}
              className='basic-multi-select w-100'
              classNamePrefix='default-select'
              placeholder='Zip Codes'
              menuPlacement={'auto'}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              closeMenuOnSelect={false}
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Label className='mt-2'>
              <input
                className='custom-checkbox d-inline-block w-auto mr-1'
                type='checkbox'
                name='NeighborhoodMap'
                value={true}
                checked={isMapShown}
                onChange={checkboxHandler}
              /> View Neighborhood Map
            </Label>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col md='12'>
            <iframe
              id="maplineIframe"
              className="maplineIframe"
              title="Map"
              width="100%"
              height="420"
              src=""
              frameBorder="0"
              allowFullScreen
              style={{display: isMapShown ? 'block' : 'none'}}
            ></iframe>
          </Col>
        </Row>
      </div>
    </div>
  );
}