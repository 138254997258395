import React from 'react';

import NavBar from '../NewLanding/components/newNavbar';
import Footer from '../NewLanding/components/newFooter';

import { Container, Row, Col } from 'reactstrap';
import DivisionTitle from '../NewLanding/components/divisionTitle';

export default function Privacy() {
  return (
    <>
      <NavBar />
      {/* <Container fluid className='py-5 text-center banner banner-small'>
        <Container className='my-5'>
          <h1>Privacy Policy</h1>
        </Container>
      </Container> */}

      <Container fluid className='py-5em mt-5em'>
        <Container className='custom-container'>
          <Row>
            <Col lg='12' className='text-center'>
              <DivisionTitle
                title="Privacy Policy"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className='bg-blue-shade py-5'>
        <Container>
          <Row>
            <Col md={{ size: '10', offset: '1' }} lg={{ size: '8', offset: '2' }}>
              <p>
                Protecting your private information is our priority. This
                Statement of Privacy applies to www.SparkAPT.com, and
                SparkLocation LLC and governs data collection and usage. For the
                purposes of this Privacy Policy, unless otherwise noted, all
                references to SparkLocation LLC include www.SparkAPT.com, SparkAPT
                and SparkAPT.com. The SparkLocation LLC website is a On-line
                non-downloadable multifamily real estate database software for
                licensed agents to view pricing, availability and other metrics
                related to real estate. site. By using the SparkLocation LLC
                website, you consent to the data practices described in this
                statement.
              </p>

              <h3>Collection of your Personal Information</h3>
              <p>
                In order to better provide you with products and services offered,
                SparkLocation LLC may collect personally identifiable information,
                such as your:
              </p>

              <ul className='custom-bullets'>
                <li>First and Last Name</li>
                <li>Mailing Address</li>
                <li>E-mail Address</li>
                <li>Real Estate License Number</li>
              </ul>

              <p>
                If you purchase SparkLocation LLC's products and services, we
                collect billing and credit card information. This information is
                used to complete the purchase transaction.
              </p>

              <p>
                We do not collect any personal information about you unless you
                voluntarily provide it to us. However, you may be required to
                provide certain personal information to us when you elect to use
                certain products or services. These may include: (a) registering
                for an account; (b) entering a sweepstakes or contest sponsored by
                us or one of our partners; (c) signing up for special offers from
                selected third parties; (d) sending us an email message; (e)
                submitting your credit card or other payment information when
                ordering and purchasing products and services. To wit, we will use
                your information for, but not limited to, communicating with you
                in relation to services and/or products you have requested from
                us. We also may gather additional personal or non-personal
                information in the future.
              </p>

              <h3>Use of your Personal Information</h3>
              <p>
                SparkLocation LLC collects and uses your personal information to
                operate and deliver the services you have requested.
              </p>
              <p>
                SparkLocation LLC may also use your personally identifiable
                information to inform you of other products or services available
                from SparkLocation LLC and its affiliates.
              </p>

              <h3>Sharing Information with Third Parties</h3>
              <p>
                SparkLocation LLC does not sell, rent or lease its customer lists
                to third parties.
              </p>
              <p>
                SparkLocation LLC may share data with trusted partners to help
                perform statistical analysis, send you email or postal mail,
                provide customer support, or arrange for deliveries. All such
                third parties are prohibited from using your personal information
                except to provide these services to SparkLocation LLC, and they
                are required to maintain the confidentiality of your information.
              </p>
              <p>
                SparkLocation LLC may disclose your personal information, without
                notice, if required to do so by law or in the good faith belief
                that such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on SparkLocation LLC or
                the site; (b) protect and defend the rights or property of
                SparkLocation LLC; and/or (c) act under exigent circumstances to
                protect the personal safety of users of SparkLocation LLC, or the
                public.
              </p>

              <h3>Automatically Collected Information</h3>
              <p>
                Information about your computer hardware and software may be
                automatically collected by SparkLocation LLC. This information can
                include: your IP address, browser type, domain names, access times
                and referring website addresses. This information is used for the
                operation of the service, to maintain quality of the service, and
                to provide general statistics regarding use of the SparkLocation
                LLC website.
              </p>

              <h3>Use of Cookies</h3>
              <p>
                The SparkLocation LLC website may use "cookies" to help you
                personalize your online experience. A cookie is a text file that
                is placed on your hard disk by a web page server. Cookies cannot
                be used to run programs or deliver viruses to your computer.
                Cookies are uniquely assigned to you, and can only be read by a
                web server in the domain that issued the cookie to you.
              </p>
              <p>
                One of the primary purposes of cookies is to provide a convenience
                feature to save you time. The purpose of a cookie is to tell the
                Web server that you have returned to a specific page. For example,
                if you personalize SparkLocation LLC pages, or register with
                SparkLocation LLC site or services, a cookie helps SparkLocation
                LLC to recall your specific information on subsequent visits. This
                simplifies the process of recording your personal information,
                such as billing addresses, shipping addresses, and so on. When you
                return to the same SparkLocation LLC website, the information you
                previously provided can be retrieved, so you can easily use the
                SparkLocation LLC features that you customized.
              </p>
              <p>
                You have the ability to accept or decline cookies. Most Web
                browsers automatically accept cookies, but you can usually modify
                your browser setting to decline cookies if you prefer. If you
                choose to decline cookies, you may not be able to fully experience
                the interactive features of the SparkLocation LLC services or
                websites you visit.
              </p>

              <h3>Links</h3>
              <p>
                This website contains links to other sites. Please be aware that
                we are not responsible for the content or privacy practices of
                such other sites. We encourage our users to be aware when they
                leave our site and to read the privacy statements of any other
                site that collects personally identifiable information.
              </p>

              <h3>Security of your Personal Information</h3>
              <p>
                SparkLocation LLC secures your personal information from
                unauthorized access, use, or disclosure. SparkLocation LLC uses
                the following methods for this purpose:
              </p>
              <ul className='custom-bullets'>
                <li>SSL Protocol</li>
              </ul>

              <p>
                When personal information (such as a credit card number) is
                transmitted to other websites, it is protected through the use of
                encryption, such as the Secure Sockets Layer (SSL) protocol.
              </p>

              <p>
                We strive to take appropriate security measures to protect against
                unauthorized access to or alteration of your personal information.
                Unfortunately, no data transmission over the Internet or any
                wireless network can be guaranteed to be 100% secure. As a result,
                while we strive to protect your personal information, you
                acknowledge that: (a) there are security and privacy limitations
                inherent to the Internet which are beyond our control; and (b)
                security, integrity, and privacy of any and all information and
                data exchanged between you and us through this Site cannot be
                guaranteed.
              </p>

              <h3>Right to Deletion</h3>
              <p>
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </p>
              <ul className='custom-bullets'>
                <li>Delete your personal information from our records; and</li>
                <li>
                  Direct any service providers to delete your personal information
                  from their records.
                </li>
              </ul>

              <p>
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to:
              </p>
              <ul className='custom-bullets'>
                <li>
                  Complete the transaction for which the personal information was
                  collected, fulfill the terms of a written warranty or product
                  recall conducted in accordance with federal law, provide a good
                  or service requested by you, or reasonably anticipated within
                  the context of our ongoing business relationship with you, or
                  otherwise perform a contract between you and us;
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive,
                  fraudulent, or illegal activity; or prosecute those responsible
                  for that activity;
                </li>
                <li>
                  Debug to identify and repair errors that impair existing
                  intended functionality;
                </li>
                <li>
                  Exercise free speech, ensure the right of another consumer to
                  exercise his or her right of free speech, or exercise another
                  right provided for by law;
                </li>
                <li>
                  Comply with the California Electronic Communications Privacy
                  Act;
                </li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or
                  statistical research in the public interest that adheres to all
                  other applicable ethics and privacy laws, when our deletion of
                  the information is likely to render impossible or seriously
                  impair the achievement of such research, provided we have
                  obtained your informed consent;
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with
                  your expectations based on your relationship with us;
                </li>
                <li>Comply with an existing legal obligation; or</li>
                <li>
                  Otherwise use your personal information, internally, in a lawful
                  manner that is compatible with the context in which you provided
                  the information.
                </li>
              </ul>

              <h3>Children Under Thirteen</h3>
              <p>
                SparkLocation LLC does not knowingly collect personally
                identifiable information from children under the age of thirteen.
                If you are under the age of thirteen, you must ask your parent or
                guardian for permission to use this website.
              </p>

              <h3>E-mail Communications</h3>
              <p>
                From time to time, SparkLocation LLC may contact you via email for
                the purpose of providing announcements, promotional offers,
                alerts, confirmations, surveys, and/or other general
                communication. In order to improve our Services, we may receive a
                notification when you open an email from SparkLocation LLC or
                click on a link therein.
              </p>
              <p>
                If you would like to stop receiving marketing or promotional
                communications via email from SparkLocation LLC, you may opt out
                of such communications by Customers can unsubscribe to all
                marketing emails by sending, "Stop" from their account email to{' '}
                <a href='mailto:data@sparkapt.com'>data@sparkapt.com.</a>
              </p>

              <h3>External Data Storage Sites</h3>
              <p>
                We may store your data on servers provided by third party hosting
                vendors with whom we have contracted.
              </p>

              <h3>Changes to this Statement</h3>
              <p>
                SparkLocation LLC reserves the right to change this Privacy Policy
                from time to time. We will notify you about significant changes in
                the way we treat personal information by sending a notice to the
                primary email address specified in your account, by placing a
                prominent notice on our website, and/or by updating any privacy
                information. Your continued use of the website and/or Services
                available after such modifications will constitute your: (a)
                acknowledgment of the modified Privacy Policy; and (b) agreement
                to abide and be bound by that Policy.
              </p>

              <h3>Contact Information</h3>
              <p>
                SparkLocation LLC welcomes your questions or comments regarding
                this Statement of Privacy. If you believe that SparkLocation LLC
                has not adhered to this Statement, please contact SparkLocation
                LLC at:
              </p>

              <p>
                SparkLocation LLC <br />
                1000 Brickell Ave Suite 715 <br />
                Miami, Florida 33131
              </p>

              <p>
                Email Address: <br />
                <a href='mailto:data@sparkapt.com'>data@sparkapt.com.</a>
              </p>

              <p>
                Telephone number: <br />
                <a href='tel:786-584-0219'>786-584-0219</a>
              </p>

              <p>
                <strong>Effective as of September 16, 2021</strong>
              </p>
            </Col>
						<Col md={{ size: '10', offset: '1' }} lg={{ size: '8', offset: '2' }}>
							<p>
								<br/>
								By continuing to use SparkAPT.com and agreeing to privacy policy & <a href='/terms-of-service'>terms of service</a>, you acknowledge that you have read, understood, and accepted our privacy policy & terms of service, including this text message opt-in provision.
							</p>
							<p>
								If you have any questions or concerns regarding our text message opt-in policy or any other aspect of our service, please contact our customer support team at <a href="mailto:data@sparkapt.com">data@sparkapt.com</a>.
							</p>
						</Col>
          </Row>
        </Container>
      </Container>
      <Footer />
    </>
  );
}
