import moment from 'moment';

export default function pastDate(dateValue, format = false) {
  let response = null;
  if (dateValue) {
    let givenDate = new Date(dateValue);
    let diff = new Date().getTime() - givenDate.getTime();
    if (diff > 0) {
      response = 'Now';
    } else {
      if (format === true) {
        // response = new Intl.DateTimeFormat('en-US', {
        //   month: 'short',
        //   day: 'numeric',
        // }).format(Date.parse(dateValue));
        response = moment(dateValue).format('MMM D');
      } else {
        response = dateValue;
      }
    }
  }
  return response;
}
