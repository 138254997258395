import React from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from 'reactstrap';

function PricingBox(props) {
  return (
    <>
      <div className='rounded-box pricing-box text-center py-lg-5 mb-4 mb-lg-0'>
        <p className={props.textColor}>{props.text}</p>
        <h3>{props.planName} Plan</h3>
        <div className='big-text'>
          {/* ${props.price}<small>/month</small> */}
          {props.billingType === 'Yearly' ? (
            <>
              ${12 * Number(props.price)}
              <small>/year</small>
            </>
          ) : (
            <>
              ${Number(props.price)}
              <small>/month</small>
            </>
          )}
        </div>
        {/* <p>
          {props.billingType === 'Yearly'
            ? `$${12 * Number(props.price)} `
            : null}
          Billed {props.billingType}
        </p> */}
        {props.billingType === 'Yearly' ? (
          <p style={{ marginTop: '-12px' }}>Save $120/year</p>
        ) : (
          <p style={{ marginTop: '-12px' }}>
            <br />
          </p>
        )}
        <h6 className='mt-4 mb-5'>{props.cityAccess} Market Access</h6>
        <NavLink
          to={{
            pathname: '/signup',
            search: `?plan=${
              props.planName
            }-${props.billingType.toLowerCase()}`,
          }}
        >
          <Button color={props.buttonColor} size='md' className='btn-rounded'>
            Start 3-Day Trial
          </Button>
        </NavLink>
      </div>
    </>
  );
}

export default PricingBox;
