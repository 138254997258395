import React, { useState, useEffect } from "react";
// import { HeadLogo } from './assets';
// import { NavLink } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  NavbarBrand
} from "reactstrap";

import { LogoIcon, Icon } from '@assets';

export default function DashboardResponsiveMenu(props) {

/*   const [display_dashboard_menu, setDashboardMenu] = useState(false);

  const toggleDashboardMenu = () => setDashboardMenu(!display_dashboard_menu); */

  return (
    <>
      <div
        className={
          props.isMenu ? "fullpage-navigation open" : "fullpage-navigation"
        }
        onClick={props.toggleResponsiveModel}
      >
        <Row className="h-100 d-flex justify-content-between text-center">
          <Col md="12">{props.content}</Col>
        </Row>
      </div>

      <Container fluid className="full-nav-header fixed-top py-2 d-lg-none">
        <Row className="d-flex align-items-center">
          <Col xs="6">
            <NavbarBrand to="/admin-dashboard" className="navbar-logo">
              {/* <span className="brand-name">SparkAPT</span> */}
              <span className='brand-name'><img src={LogoIcon} alt="Logo Icon" className="img-fluid dashboard-logo" /></span>
            </NavbarBrand>
          </Col>

          <Col xs="6" className="text-right">
            <Button
              onClick={props.toggleResponsiveModel}
              className="full-nav-button"
            >
              <Icon
                icon="bars"
                size="lg"
                className={
                  props.isMenu
                    ? "full-nav-button color-white d-none"
                    : "full-nav-button color-white"
                }
              />
              <Icon
                icon="times"
                size="lg"
                className={
                  props.isMenu
                    ? "full-nav color-white d-inline-block"
                    : "full-nav color-white d-none"
                }
              />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}
