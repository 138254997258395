import React, { useState, useEffect, memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
	Spinner,
	Label,
	Input,
	FormGroup,
	InputGroup,
	UncontrolledTooltip
} from 'reactstrap';
import Loader from '../../Spinner';
import SidebarRight from '../../SidebarRight';
import { toast } from 'react-toastify';
import { Icon } from '@assets';
import config from '@config';
import { CLIENT_INQUIRIES, MY_PROFILE, DELETE_CLIENT_INQUIRY, INQUIRY_TO_CLIENT, SEND_INQUIRY_FORM } from '@api';
import ClientInquiryShow from './ClientInquiryShow';
import ClientInquiryTable from './ClientInquiryTable';
import moment from 'moment';
import { unitTypes } from '@sparklib';

export default function ClientInquiry(props){
	let { id } = useParams();
	const history = useHistory();
  const [sidebarState, setSidebarState] = useState(id ? true : false);
	const [inquiries, setInquiries] = useState([]);
	const [client, setClient] = useState(null);
	const [showUrl, setShowUrl] = useState(false);
	const [isCheckedId, setIsCheckedId] = useState([]);
	const [isChecked, setIsChecked] = useState([]);
	const [allDeleteModal, setAllDeleteModal] = useState(false);
	const [isProgress, setIsProgress] = useState(false);
	const { data: inquiriesData, loading: loadingData } = useQuery(CLIENT_INQUIRIES)

	useEffect(() => {
		if (inquiriesData?.clientInquiries?.edges){
			setInquiries(inquiriesData.clientInquiries.edges)
		}
	}, [inquiriesData])

	useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);

	const [deleteClientInquiry] = useMutation(DELETE_CLIENT_INQUIRY, {
		refetchQueries: [CLIENT_INQUIRIES],
		onCompleted: (data) => {
			if (data.deleteClientInquiry?.errors) {
				toast.error(data.deleteClientInquiry.message)
			} else toast.success(data.deleteClientInquiry.message)
		}
	})

	function handleClientInquiryDelete(inquiryId){
		if (inquiryId){
			deleteClientInquiry({
				variables: {
					id: Number(inquiryId)
				}
			})
		}
	}

	const handleDeleteAll = async () => {
    setIsProgress(true);
    if (isCheckedId.length > 0) {
    	for (let x in isCheckedId) {
  			handleClientInquiryDelete(isCheckedId[x]); 
			}
    }
  };

  const onAllDeletePopup = () => {
    if (isCheckedId.length > 0) {
      setAllDeleteModal(!allDeleteModal);
    }
  };

  const handleSingleDelete = (id) => {
    setIsCheckedId([parseInt(id)]);
    setAllDeleteModal(true);
  };

	const [inquiryToClient] = useMutation(INQUIRY_TO_CLIENT, {
		refetchQueries: [CLIENT_INQUIRIES],
		onCompleted: (data) => {
			if (data.inquiryToClient?.errors) {
				toast.error(data.inquiryToClient.message)
			} else {
				toast.success(data.inquiryToClient.message)
				history.push("/clients");
			}
		}
	})

	function handleInquiryToClient(inquiryId){
		if (inquiryId){
			inquiryToClient({
				variables: {
					id: Number(inquiryId)
				}
			})
		}
	}

	const {data: profile} = useQuery(MY_PROFILE)

	const columns = React.useMemo(
    () => [
			{
				Header: 'Name',
				accessor: 'node.name',
			},
			{
        Header: 'Action',
        accessor: 'node.id',
        disableSortBy: true,
        minWidth: '150px',
        Cell: ({ value, row }) => (
        	<>
						<div onClick={(e) => e.stopPropagation()}>
							<Button
								color='blue'
								onClick={() => handleInquiryToClient(Number(value))}
								className='button-dashboard btn-sm'
							>
								Convert to Client
							</Button>
						</div>
	          {/*<div onClick={(e) => e.stopPropagation()}>
	            <UncontrolledButtonDropdown>
								<DropdownToggle caret>
	                <Icon icon='bars' />
	              </DropdownToggle>
	              <DropdownMenu>
	                <DropdownItem
										onClick={() => {
											setClient(row.original.node)
											setSidebarState(true);
											history.push(`/client-inquiries/${value}`);
										}}
	                >
	                  View
	                </DropdownItem>
	                <DropdownItem divider />
	                <DropdownItem
										onClick={() => handleInquiryToClient(Number(value))}
	                >
	                  Convert to Client
	                </DropdownItem>
	                <DropdownItem divider />
	                <DropdownItem
										onClick={() => handleClientInquiryDelete(Number(value))}
	                >
	                  Delete
	                </DropdownItem>
	              </DropdownMenu>
	            </UncontrolledButtonDropdown>
	          </div>*/}
          </>
        ),
      },
			{
				Header: 'Email',
				accessor: 'node.email',
			},
			{
				Header: 'Phone',
				accessor: 'node.phone',
			},
			{
				Header: 'City',
				accessor: 'node.city.name'
			},
			{
				Header: 'Lease Term',
				accessor: 'node.leaseTerm',
				Cell: ({value}) => (
					<>{value ? `${value} Months` : null}</>
				)
			},
			{
        Header: 'Budget',
        accessor: 'node.minRent',
        Cell: ({ value, row }) => (
          <div>
            {
              <div>
                {value && row.original.node.maxRent ? (
                  <div>
                    {value && '$' + currencyFormatter.format(value)} -{' '}
                    {'$' + currencyFormatter.format(row.original.node.maxRent)}{' '}
                  </div>
                ) : value ? (
                  <>{'$' + value + ' & Above'}</>
                ) : row.original.node.maxRent ? (
                  <>
                    {'Below ' +
                      '$' +
                      currencyFormatter.format(row.original.node.maxRent)}
                  </>
                ) : (
                  '-'
                )}
              </div>
            }
          </div>
        ),
      },
      {
        Header: 'M/I Date',
        accessor: 'node.availFrom',
        Cell: ({ value, row }) => (
          <div>
            {value ? (
              <div>
								{
									value && !row.original.node.availTo ? `After ${moment(value).format('L')}` :
									!value && row.original.node.availTo ? `Before ${moment(row.original.node.availTo).format('L')}` :
									`${moment(value).format('L')} - ${moment(row.original.node.availTo).format('L')}`
								}
              </div>
            ) : (
              '-'
            )}
          </div>
        ),
      },
			{
				Header: 'Bed / Bath',
				accessor: 'node.bedroomArray',
				Cell: ({ value, row }) => (
					<>{value.map(b => <>{unitTypes[b]},&nbsp;</>)}{row.original.node.bathroom}+ Bath</>
				)
			}
	]);

	const currencyFormatter = new Intl.NumberFormat('en-US', {
		/* style: 'currency', */
		currency: 'USD',
		maximumFractionDigits: 0,
	});

	useEffect(() => {
		if (!sidebarState) history.push("/client-inquiries");
	}, [sidebarState])

	const toggleShowUrl = useCallback(() => {
		setShowUrl(!showUrl)
	}, [showUrl])

	return (
		<>
		{loadingData && <Loader />}

			<SidebarRight
				sidebarButton={false}
				sidebarState={sidebarState}
				sidebarWidth='1150px'
				sidebarToggle={() => {
					setSidebarState(!sidebarState);
				}}
				sidebarContent={
					<ClientInquiryShow
						client={client}
						// new={operation}
						// sidebarToggle={() => {
						// 	setSidebarState(!sidebarState);
						// }}
						// broker={broker}
						// {...props}
					/>
				}
			/>
			<InviteAgent 
				isShow={showUrl} 
				toggleModel={toggleShowUrl} 
				urlName={profile?.myProfile?.urlName}
			/>
			<Modal isOpen={allDeleteModal} toggle={onAllDeletePopup}>
        <ModalHeader toggle={onAllDeletePopup}>Delete Confirmation</ModalHeader>
        <ModalBody>
          Are you sure you want to delete {isCheckedId.length} lead
          {isCheckedId.length > 1 ? 's' : ''}?
        </ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            disabled={isProgress}
            onClick={handleDeleteAll}
          >
            Yes
          </Button>
          <Button color='success' onClick={onAllDeletePopup}>
            No
          </Button>
        </ModalFooter>
      </Modal>
			<Row>
				<Col md='12'>
					{
						inquiries?.length > 0 ? 
						<ClientInquiryTable
							columns={columns}
							data={inquiries}
							filterable
							setSidebarState={setSidebarState}
							changeIsChecked={(isChecked) => setIsChecked(isChecked)}
							// sidebarContent={}
							rightAddons={
								<>
									<Button
										color='blue'
										onClick={() => setShowUrl(true)}
										className='button-dashboard'
									>
										<Icon icon='paper-plane' className='mr-2' />
										Copy Lead Gen Link to Share on Social Media
									</Button>
									<Button
										color='red'
										onClick={onAllDeletePopup}
										disabled={isCheckedId.length > 0 ? false : true}
										className='button-dashboard float-right mr-sm-5 mt-3 mt-sm-0'
									>
										<Icon icon='trash-alt' className='mr-2' />
										Delete
									</Button>
								</>
              }
						/> : 
						<div className='text-center' style={{ margin: '35vh 0' }}>
							<h4>Uh oh, you don't have leads yet!</h4>
							<Button
								onClick={() => setShowUrl(true)}
								color='success'
								className='button-dashboard mt-2'
							>
								Copy Lead Gen Link to Share on Social Media{' '}
								<Icon icon='chevron-right' className='ml-2' />
							</Button>
						</div>
					}
				</Col>
			</Row>
		</>
	);
}

const InviteAgent = memo((props) => {
	let url = config.url.WEB_DOMAIN + 'inquiry/' + props.urlName;
	let newUrl = config.url.WEB_DOMAIN + 'new-inquiry/' + props.urlName;
	return (
		<>
			<Modal
				isOpen={props.isShow}
				toggle={props.toggleModel}
				centered
				className='change-password-modal text-center'
			>
        <ModalHeader toggle={props.toggleModel}>Inquiry Form</ModalHeader>
				<ModalBody className='inputs-icons'>
					<div className='invite-agent-modal'>
						<h6>Share your inquiry link</h6>
						{!props.urlName ?
						<Spinner
							color="primary"
							type="grow"
						>
							Loading...
						</Spinner> : 
						<InquiryLink copyText={url} newCopyText={newUrl} />}
					</div>
					<br />
				</ModalBody>
			</Modal>
		</>
	);
})

function InquiryLink({ copyText, newCopyText }) {
	const [showTooltip, setShowTooltip] = useState(false);
	const [sendInquiryForm] = useMutation(SEND_INQUIRY_FORM, {
		onCompleted: (data) => {
			if (data.sendClientInquiryForm?.errors?.length > 0){
				toast.error(data.sendClientInquiryForm.message)
			} else {
				toast.success(data.sendClientInquiryForm.message)
				if (document.getElementById("email_input")){
					document.getElementById("email_input").value == ""
				}
			}
		}
	})

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
  }

	const handleNewCopyClick = () => {
    copyTextToClipboard(newCopyText)
  }

	function emailForm(){
		var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		let emailInput = document.getElementById("email_input")?.value
		let emailMsg = document.getElementById("emailMessage")?.value
		const emails = []
		emailInput?.split(",")?.forEach(e => {
			const email = e.trim()
			if (email.match(emailRegex)) emails.push(email)
		})
		if (emails.length > 0) {
			sendInquiryForm({
				variables: {
					emails: emails,
					message: emailMsg
				}
			})
		} else {
			toast.error("Please enter valid email address")
		}
	}

	useEffect(() => {
		if (showTooltip) setTimeout(() => setShowTooltip(false), 2000)
	}, [showTooltip])

  return (
    <>
			<Row style={{ display: 'flex', justifyContent: 'center'}}>
				<UncontrolledTooltip
					style={{transitionDuration: '2s'}}
					placement='top'
					isOpen={showTooltip}
					target='Tooltip-url'
					trigger='click'
					toggle={() => setShowTooltip(!showTooltip)}
				>
					URL Copied
				</UncontrolledTooltip>
				<Col className='mb-5 inquiry-link-div' md='12' id='Tooltip-url'>
					<div className='position-relative'>
						<input type="text" value={copyText} readOnly size={copyText.length} className='inquiry-link-box text-primary-emphasis' id='signup-url' onClick={handleCopyClick}></input>
						{/* <Icon icon="clipboard-list" color='blue' style={{ position: 'absolute', left: `${copyText.length + 30}px`, top: "50%", transform: 'translateY(-50%)' }}/> */}
					</div>
				</Col>
				<Col className='mb-5 inquiry-link-div' md='12' id='Tooltip-url'>
					<div className='position-relative'>
						<input type="text" value={newCopyText} readOnly size={newCopyText.length} className='inquiry-link-box text-primary-emphasis' id='signup-url' onClick={handleNewCopyClick}></input>
						{/* <Icon icon="clipboard-list" color='blue' style={{ position: 'absolute', left: `${copyText.length + 30}px`, top: "50%", transform: 'translateY(-50%)' }}/> */}
					</div>
				</Col>
				<p className='client-inquiry-or'><span>OR</span></p>
				<Col className='px-4 text-center' md='12'>
					<FormGroup>
						<br/>
						<Label for='emails'>Email your lead(s)</Label>
						<InputGroup>
							<Input
								id='email_input'
								name="text"
								defaultValue=""
								placeholder="Use commas to add additional email addresses"
							/>
						</InputGroup>
					</FormGroup>
					<FormGroup>
						<Label for='emailMessage'>Message</Label>
						<InputGroup>
							<Input
								type='textarea'
								name='emailMessage'
								id="emailMessage"
								rows={3}
								autoComplete='off'
								defaultValue='Hi, please fill out this form regarding your apartment search and we will be in touch with you shortly.'
							/>
						</InputGroup>
					</FormGroup>
					<Button
						onClick={emailForm}
						color='primary'
						className='button-dashboard mt-2'
					>
						Send Email
						<Icon icon='chevron-right' className='ml-2' />
					</Button>
				</Col>
    	</Row>
    </>
  );
}