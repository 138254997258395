import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import _ from 'lodash/fp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation, useQuery } from '@apollo/client';
import {
  ElementsConsumer,
  Elements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MY_PROFILE } from '../../../api/queries';
import config from '@config';
import { CHANGE_PAYMENT_METHOD } from '@api';
import { Icon } from '@assets';

function PaymentMethod(props) {
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(false);

  const {
    data: userData,
    loading: loadingUserData,
    refetch: fetchUserData,
  } = useQuery(MY_PROFILE);

  useEffect(() => {
    if (userData?.myProfile?.paymentMethods?.length > 0) {
      setPaymentMethod(userData.myProfile.paymentMethods[0]);
    }
  }, [userData]);

  const [changePaymentMethod] = useMutation(CHANGE_PAYMENT_METHOD, {
    onCompleted: (data) => {
      setProgress(false);
      if (data.changePaymentMethod.errors) {
        toast(data.changePaymentMethod.message, { type: 'error' });
      } else {
        toast(data.changePaymentMethod.message, { type: 'success' });
        fetchUserData();
      }
    },
  });

  const { register, handleSubmit, errors, watch } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onSubmit = (data, e) => {
    if (paymentMethod) {
      handleForm(e, data);
    } else {
      setError('No payment method found');
    }
  };

  const handleForm = async (e, data) => {
    const { stripe, elements } = props;
    if (!stripe || !elements) {
      return;
    }
    const card = elements.getElement(CardNumberElement);
    const card_exp = elements.getElement(CardExpiryElement);
    const card_cvc = elements.getElement(CardCvcElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setError(result.error.message);
      setProgress(false);
    } else {
      setError('');
      // createToken(result.token.id);
      await changePaymentMethod({
        variables: {
          stripeToken: result.token.id,
          currentPm: paymentMethod.card_id,
        },
      });
      card.clear();
      card_exp.clear();
      card_cvc.clear();
    }
  };

  function getCardIcon(cardName) {
    var card = cardName;
    switch (cardName) {
      case 'diners':
        card = 'diners-club';
        break;
    }
    return card;
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function getUpcomingInvoiceData() {
    if (userData?.myProfile?.upcomingInvoice) {
      var data = userData.myProfile.upcomingInvoice;
      if (data.amount_due > 0 && data.next_payment_date) {
        return (
          <Badge className='upcoming-invoice-badge'>
            Next Invoice: ${data.amount_due} on{' '}
            {formatDate(data.next_payment_date)}
          </Badge>
        );
      }
    } else return null;
  }

  return (
    <>
      {/* <Modal
        isOpen={true}
        toggle={() => history.goBack()}
        centered
        className='payment-method text-center'
      > */}
      <Col md='12' className='text-center mt-3'>
        <h4>{getUpcomingInvoiceData()}</h4>
      </Col>
      <Form className='form' onSubmit={handleSubmit(onSubmit)}>
        {/* <ModalHeader toggle={() => history.goBack()}>Payment Method</ModalHeader> */}
        {/* <ModalBody className='inputs-icons'> */}
        {loadingUserData ? (
          <Spinner color='primary' type='grow'>
            Loading...
          </Spinner>
        ) : paymentMethod ? (
          <div align='center'>
            <Card className='my-2 py-4 payment-method-card' outline>
              {/* <CardHeader>Current Payment Method</CardHeader> */}
              {/* <CardBody> */}
              <CardTitle tag='h5'>
                <Col md='12 mt-3'>
                  <Row>
                    <Col md='12'>
                      <strong>
                        {_.capitalize(paymentMethod.brand)}{' '}
                        {_.capitalize(paymentMethod.funding)} Card
                      </strong>
                    </Col>
                  </Row>
                </Col>
              </CardTitle>
              <Col md='12'>
                <Row>
                  <Col md='8' className='text-right'>
                    <h5>
                      <sub>**** **** ****</sub> {paymentMethod.last4}
                    </h5>
                  </Col>
                  <Col md='4' className='text-left'>
                    <FontAwesomeIcon
                      icon={['fab', `cc-${getCardIcon(paymentMethod.brand)}`]}
                      size='2x'
                      color='#EBD359'
                    />
                    &nbsp;&nbsp;
                  </Col>
                </Row>
              </Col>
              {/* </CardBody> */}
            </Card>
          </div>
        ) : (
          <p>No Payment Method Found</p>
        )}
        {paymentMethod && (
          <div className='mt-5 mx-5'>
            <CardSection />
          </div>
        )}
        {error ? <p className='input-error mx-5'>{error}</p> : null}
        {/* </ModalBody> */}

        {paymentMethod && (
          <div align='center' className='pt-3'>
            <div className='mx-5 pt-2 pb-3 bg-light rounded-medium'>
              <span>
                <Icon icon='exclamation-triangle' color='red' />
              </span>
              <div className='ml-2'>
                Your current payment method will be replaced with this payment
                method.
              </div>
            </div>
            <Button
              color='green'
              className='button-dashboard my-3'
              disabled={progress}
            >
              Change Payment Method
            </Button>
          </div>
        )}
      </Form>
      {/* </Modal> */}
    </>
  );
}

function CardSection() {
  const CARD_ELEMENT_OPTIONS = {
    class: 'form-control',
    style: {
      base: {
        color: '#495057',
        fontSize: '1.1em',
        // fontFamily: "Nunito Light",
        verticalAlign: 'middle',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: 'grey',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
    hidePostalCode: true,
  };

  return (
    <>
      <Row>
        <Col lg='12' className='mb-3'>
          <CardNumberElement
            options={CARD_ELEMENT_OPTIONS}
            className='form-control d-flex align-items-center'
          />
        </Col>
        <Col xs='6'>
          <CardExpiryElement
            options={CARD_ELEMENT_OPTIONS}
            className='form-control d-flex align-items-center'
          />
        </Col>
        <Col xs='6'>
          <CardCvcElement
            options={CARD_ELEMENT_OPTIONS}
            className='form-control d-flex align-items-center'
          />
        </Col>
      </Row>
    </>
  );
}

export default function InjectedCheckoutForm(props) {
  const stripePromise = loadStripe(config.url.REACT_APP_STRIPE_KEY);

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <PaymentMethod stripe={stripe} elements={elements} {...props} />
        )}
      </ElementsConsumer>
    </Elements>
  );
}
