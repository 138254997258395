import React, { useState, useEffect } from 'react';
import PropertyTable from './PropertyTable';
import 'react-toastify/dist/ReactToastify.css';
import { propertyTypes, bathTypes } from '@sparklib';
import { Icon } from '@assets';
import moment from 'moment';
import Highlighter from "react-highlight-words";
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PROPERTY_COLOR } from '@api';
import { toast } from 'react-toastify';
import CustomColorPicker from './CustomColorPicker';

export default function List({
  properties,
  path,
  setSidebarState,
  setProperty,
  selectedProperties,
  setSelectedProperties,
  setSelectedPropertyArray,
  selectedPropertyArray,
  paginationInfo,
  setPaginationInfo,
  setShouldSearch,
  setOrderBy,
  toggleSelection,
}) {
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedId, setIsCheckedId] = useState([]);
  const predefinedColors = [
    '#000000', '#FF0000', '#00FF00', '#0000FF',
    '#FF00FF', '#00FFFF', '#FFFF00', '#DEB887',
    '#800000', '#808000', '#800080', '#008080',
    '#C0C0C0', '#FFA500', '#A52A2A', '#FFFFFF'
  ];

  const [updateUserPropertyColor] = useMutation(UPDATE_USER_PROPERTY_COLOR, {
    onCompleted: (data) => {
      if (data.updateUserPropertyColor.message) {
        toast(data.updateUserPropertyColor.message, { type: 'success'});
      }
      if (data.updateUserPropertyColor.errors && data.updateUserPropertyColor.errors.length > 0) {
        data.updateUserPropertyColor.errors.forEach(err => toast(err, { type: 'error'}));
      }
    },
    onError: (e) => {
      console.log(e);
    },
  });

  const handleUpdateUserPropertyColor = (color, extraParams) => {
    updateUserPropertyColor({
      variables: {
        propertyId: parseInt(extraParams?.propertyId),
        color: color
      }
    })
  };

  const [sort, setSort] = useState({
    sortDirection: 'none',
    accessor: 'some_accessor',
  });
  // const columnHeaderClick = async (column) => {
  //   switch (column.sortDirection) {
  //     case 'none':
  //       setSort({ direction: 'ASC', accessor: column.id });
  //       const desc = await getClients('ASC', column.id);
  //       setData(desc);
  //       break;
  //     case 'ASC':
  //       setSort({ direction: 'DESC', accessor: column.id });
  //       const asc = await getClients('DESC', column.id);
  //       setData(asc);
  //       break;
  //     case 'DESC':
  //       setSort({ direction: 'none', accessor: column.id });
  //       const newData = await getClients('none', column.id);
  //       setData(newData);
  //       break;
  //   }
  // };

  function getBedBathValue(floorPlans) {
    return (
      <>
        {propertyTypes(floorPlans)} <Icon icon='bed' /> <br />
        {bathTypes(floorPlans)} <Icon icon='bath' />
      </>
    );
  }

  function getPriceValue(floorPlans) {
    const totalPrice = [];
    floorPlans.forEach((fp) =>
      fp.units?.map((u) => {
        if (u.price && u.isAvailable && u.price > 0) {
          totalPrice.push(u.price);
        }
      })
    );

		if (totalPrice?.length == 0 && floorPlans?.length > 0){
			floorPlans.forEach((fp) => {
				if (fp.rentMax > 0 && fp.isAvailable) {
					totalPrice.push(fp.rentMax);
				}
				if (fp.rentMin > 0 && fp.isAvailable) {
					totalPrice.push(fp.rentMin);
				}
			});
		}
    if (totalPrice.length > 0) {
      var minValue = Math.min(...totalPrice);
      var maxValue = Math.max(...totalPrice);
      return (
        <>
					{minValue === maxValue ?
					currencyFormatter.format(maxValue) :
					`${currencyFormatter.format(minValue)}-${currencyFormatter.format(maxValue)}`}
        </>
      );
    } else {
      return (
        <>
          <em>No rent info</em>
        </>
      );
    }
  }

  function getSizeValue(floorPlans) {
    const totalize = [];
    floorPlans.forEach((fp) =>
      fp.units?.map((u) => {
        if (u.size && u.isAvailable && u.size > 0) {
          totalize.push(u.size);
        }
      })
    );
		if (totalize?.length == 0 && floorPlans?.length > 0) {
			floorPlans.forEach((fp) => {
				if (parseInt(fp.sqftMin) > 100 && fp.isAvailable) totalize.push(parseInt(fp.sqftMin));
				if (parseInt(fp.sqftMax) > 100 && fp.isAvailable) totalize.push(parseInt(fp.sqftMax));
			});
		}
    if (totalize.length > 0) {
      var minValue = Math.min(...totalize);
      var maxValue = Math.max(...totalize);
      return (
        <>{minValue === maxValue ? maxValue : `${minValue}-${maxValue}`} ft<sup>2</sup>
        </>
      );
    } else {
      return (
        <>
          <em>No size info</em>
        </>
      );
    }
  }

  /* function togglePropertySelection(property) {
    const propertyObject = {};
    propertyObject.propertyId = Number(property.id);
    propertyObject.floorPlanId = null;
    propertyObject.typeDetailId = null;

    const result = selectedProperties.find(
      (p) => Number(p.propertyId) === Number(property.id)
    );
    if (result !== undefined) {
      let newArrayValue = selectedProperties.filter(
        (p) => Number(p.propertyId) != Number(property.id)
      );

      setSelectedProperties(newArrayValue);
      // setSelectedPropertyArray((selectedPropertyArray) =>
      //   selectedPropertyArray.filter((p) => p.node.id !== property.id)
      // );
    } else {
      setSelectedProperties([...selectedProperties, propertyObject]);
      // setSelectedPropertyArray((selectedProperties) => [
      //   ...selectedPropertyArray,
      //   { node: { ...property, highlight: true } },
      // ]);
    }
  } */

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  });
  const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
  });
  const columns = React.useMemo(
    () => [
      {
        accessor: 'node.id',
        // maxWidth: 60,
        // minWidth: 60,
        // width: 60,
        disableSortBy: true,
        Cell: ({ value, row }) => (
					<div
						onClick={(e) => {
							e.stopPropagation();
							toggleSelection(row.original.node);
						}}
						style={{ cursor: 'pointer', padding: '2px 0px' }}
					>
						{selectedPropertyArray.find(
							(p) => p.node.id === row.original.node.id
						) ? (
							<Icon icon='heart' size='lg' />
						) : (
							<>
								<Icon size='lg' icon={['far', 'heart']} id={'Tooltip-' + row.original.node.id} style={{outline: 'none'}}/>
							</>
						)}
					</div>
        ),
      },
      {
        id: 'name',
        Header: 'Community Name',
        accessor: 'node.name',
        // maxWidth: 250,
        // minWidth: 150,
        // width: 200,
        Cell: ({ value, row }) => (
          <div>
            <strong>
              <Highlighter
                highlightClassName="bg-warning"
                searchWords={document.getElementById("search")?.value?.split(" ") || []}
                autoEscape={true}
                textToHighlight={value ? value : '-'}
              />
            </strong>
          </div>
        ),
      },
      // {
      //   // id: "date_trunc('hour', type_details.updated_at)",
      //   id: 'type_details.updated_at',
      //   Header: 'Pricing updated',
      //   columnName: 'specials',
      //   disableSortBy: true,
      //   maxWidth: 150,
      //   minWidth: 120,
      //   width: 120,
      //   Cell: ({ value, row }) => (
			// 		<div>
      //       {lastUpdatedOn(row.original.originalNode, true) !== '-'
      //         ? moment(lastUpdatedOn(row.original.originalNode, true)).fromNow()
      //         : '-'}
      //     </div>
      //   ),
      // },
      {
        id: 'phone',
        Header: 'Phone',
        accessor: 'node.phone',
        // maxWidth: 250,
        // minWidth: 150,
        // width: 200,
        Cell: ({ value, row }) => (
          <div>
            {value ? (
              <a
                href={`tel:${value}`}
                onClick={(e) => e.stopPropagation()}
              >
                <Highlighter
                  highlightClassName="bg-warning"
                  searchWords={document.getElementById("search")?.value?.split(" ") || []}
                  autoEscape={true}
                  textToHighlight={value}
                />
              </a>
            ) : (
              ''
            )}
          </div>
        ),
      },
      {
        id: 'specials',
        Header: 'Specials',
        accessor: 'node.specials',
        // maxWidth: 250,
        // minWidth: 150,
        // width: 200,
        // disableSortBy: true,
        Cell: ({ value, row }) => (
          <>
            <div>
              <Highlighter
                highlightClassName="bg-warning"
                searchWords={document.getElementById("search")?.value?.split(" ") || []}
                autoEscape={true}
                textToHighlight={value?.trim() ? value : '-'}
              />
              <br />
              <em>(Updated {moment(row.original.node.specialsUpdatedAt).fromNow()})</em>
            </div>
          </>
        ),
      },
      {
        id: 'neighborhoods.name',
        Header: 'Neighborhood',
        accessor: 'node.neighborhood.name',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <>
            <Highlighter
              highlightClassName="bg-warning"
              searchWords={document.getElementById("search")?.value?.split(" ") || []}
              autoEscape={true}
              textToHighlight={value ? value : '-'}
            />
            {/* <br />
            <a
              className='text-decoration-none'
              href={
                row.original.node.googleMap !== ''
                  ? row.original.node.googleMap
                  : 'javascript:void(0)'
              }
              onClick={(e) => e.stopPropagation()}
              target={row.original.node.webLink !== '' ? '_blank' : ''}
            >
              <Icon icon='map-marker-alt' />{' '}
              {row.original.node.address ? row.original.node.address : ''}
            </a> */}
          </>
        ),
      },
      {
        id: 'built_year',
        Header: 'Built / Renov',
        accessor: 'node.builtYear',
        Cell: ({ value, row }) => (
          <>
            <Highlighter
              highlightClassName="bg-warning"
              searchWords={document.getElementById("search")?.value?.split(" ") || []}
              autoEscape={true}
              textToHighlight={row.original.node.builtYear > 0
                ? 'Built ' + row.original.node.builtYear
                : ''}
            />
            <br />
            <Highlighter
              highlightClassName="bg-warning"
              searchWords={document.getElementById("search")?.value?.split(" ") || []}
              autoEscape={true}
              textToHighlight={row.original.node.renovated > 0
                ? 'Renov ' + row.original.node.renovated
                : ''}
            />
          </>
        ),
      },
      {
        id: 'bed',
        Header: 'Bed / Bath',
        disableSortBy: true,
        accessor: 'node.floorPlans',
        Cell: ({ value, row }) => <>{getBedBathValue(value)}</>,
      },
      {
        id: 'price',
        Header: 'Rent',
        disableSortBy: true,
        accessor: 'node.floorPlans',
        Cell: ({ value, row }) => (
          <>
            {row.original.node.floorPlans?.length > 0 ? (
              getPriceValue(value)
            ) : (
              <>
                <em>No rent info</em>
              </>
            )}
          </>
        ),
      },
      {
        id: 'size',
        Header: 'Sqft',
        disableSortBy: true,
        accessor: 'node.floorPlans',
        Cell: ({ value, row }) => (
          <>
            {row.original.node.floorPlans?.length > 0 ? (
              getSizeValue(value)
            ) : (
              <>
                <em>No size info</em>
              </>
            )}
          </>
        ),
      },
      {
        id: 'send_escort',
        Header: 'Send',
        accessor: 'node.sendEscort',
        className: 'align-right',
        textAlign: 'right',
        // maxWidth: 80,
        // minWidth: 80,
        // width: 80,
        Cell: ({ value, row }) => (
          <div className='text-right'>
            {Number(value) > 0 ? percentFormatter.format(value / 100) : '-'}
          </div>
        ),
      },
      {
        id: 'escort',
        Header: 'Escort',
        accessor: 'node.escort',
        className: 'align-right',
        textAlign: 'right',
        // maxWidth: 90,
        // minWidth: 90,
        // width: 90,
        Cell: ({ value, row }) => (
          <div className='text-right'>
            {Number(value) > 0 ? percentFormatter.format(value / 100) : '-'}
          </div>
        ),
      },
      {
        id: 'bonus',
        Header: 'Bonus',
        accessor: 'node.bonus',
        className: 'align-right',
        textAlign: 'right',
        // maxWidth: 100,
        // minWidth: 100,
        // width: 100,
        Cell: ({ value, row }) => (
          <div className='text-right'>
            {Number(value) > 0 ? currencyFormatter.format(value) : '-'}
          </div>
        ),
      },
    ],
    [selectedPropertyArray]
  );

  // if (localStorage.getItem('is_broker') === 'true' || localStorage.getItem('is_agent') === 'true') {
  //   columns.splice(2, 0, {
  //     id: 'color',
  //     accessor: 'node.userPropertyColor.color',
  //     disableSortBy: true,
  //     Cell: ({ value, row }) => (
  //       <div
  //         onClick={(e) => {
  //           e.stopPropagation();
  //         }}
  //       >
  //         <CustomColorPicker
  //           predefinedColors={predefinedColors}
  //           onChange={handleUpdateUserPropertyColor}
  //           defaultColor={value ? value : '#000000'}
  //           disabled={localStorage.getItem('is_agent') === 'true'}
  //           extraParams={{propertyId: row.original.node.id}}
  //         />
  //       </div>
  //     ),
  //   });
  // }

  /*   function mergedProperties() {

    return [...selectedPropertyArray, ...properties];
  }
 */
  useEffect(() => {
    setIsCheckedId([]);
    var isSelectedIds = [];
    isChecked.map(function (key, index) {
      isSelectedIds.push(parseInt(key.original.node.id));
    });
    setIsCheckedId(isSelectedIds);
  }, [isChecked]);
  return (
    <PropertyTable
      columns={columns}
      data={[...selectedPropertyArray, ...properties]}
      filterable
      path={path}
      setSidebarState={setSidebarState}
      setProperty={setProperty}
      paginationInfo={paginationInfo}
      setPaginationInfo={setPaginationInfo}
      setShouldSearch={setShouldSearch}
      setOrderBy={setOrderBy}
      selectedPropertyArray={selectedPropertyArray}
    />
  );
}
