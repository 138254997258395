import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import '../../assets/styles/Dashboard.css';
import Avatar from 'react-avatar';

// import AdminHome from '../admin/AdminHome';
import SubscriptionList from '../admin/SubscriptionList';
import Plans from '../admin/Plan';
import Cities from '../admin/Cities';
import Inquiries from '../admin/Inquiries';
import AdminProfile from '../admin/AdminProfile';
import Leads from '../admin/leads/Leads';
import UpdatePassword from '../updatePassword';
import Notification from './notification/Notification';
import DashboardResponsiveMenu from './../DashboardResponsiveMenu';
import { Users } from '@user';
import { Properties, PropertyGrid } from '@property';
import AdminMainDashboard from '../admin/AdminMainDashboard';
import {
  Container,
  Row,
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { isLoggedIn } from '@sparklib';
//import ThemeModal from '../ThemeModal';

import { LogoIcon, Icon } from '@assets';
import { useSubscription } from '@apollo/client';
import { USERS_SUBSCRIPTION } from '@api';

export default function AdminDashboard(props) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [selctedCity, setSelectedCity] = useState({ label: '', value: '' });
  const [display_sidebar, setSidebar] = useState(false);
  // const [cities, setCities] = useState([]);
  const [searchQuery, setsearchQuery] = useState('');
  const toggle = () => setIsOpen(!isOpen);
  const toggleSidebar = () => setSidebar(!display_sidebar);
  const [isResponsiveMenuOpen, setIsResponsiveMenuOpen] = useState(false);

  const [displayModal, setModal] = useState(false);
  const toggleModal = () => setModal(!displayModal);

  // Theme Modal Popup
  const [displayThemeModal, setThemeModal] = useState(false);
  const toggleThemeModal = () => setThemeModal(!displayThemeModal);
	const [users, setUsers] = useState([]);

	useSubscription(USERS_SUBSCRIPTION, {
		onSubscriptionData: ({ subscriptionData: { data } }) => {
			if (data?.usersSubscription?.users) {
				setUsers(data?.usersSubscription?.users)
			}
			var updatedUser = data?.usersSubscription?.user
			if (updatedUser && users.length > 0) {
				var index = _.findIndex(users, {id: updatedUser.id})
				var updatedUsers = users.map((user) => {
					if (user.id === updatedUser.id){
						return updatedUser
					} else return user
				})
				setUsers(updatedUsers)
			}
		}
	})

  useEffect(() => {
    if (isLoggedIn('admin') !== true) {
      history.push({
        pathname: '/login',
        state: { message: 'Please login to continue..' },
      });
    }
    return () => {};
  }, []);

  const renderSwitch = () => {
    switch (props.path) {
      case 'admin-properties':
        return <Properties {...props} />;
      case 'manage-properties':
        return <PropertyGrid {...props} />;
      case 'users':
        return <Users {...props} userData={users} setUserData={setUsers} />;
      case 'subscriptions':
        return <SubscriptionList />;
      case 'plans':
        return <Plans />;
      case 'cities':
        return <Cities />;
      case 'inquiries':
        return <Inquiries />;
      case 'admin-profile':
        return <AdminProfile />;
      case 'notifications':
        return <Notification {...props} />;
      case 'leads':
        return <Leads />;
      default:
        return <AdminMainDashboard userData={users} />;
    }
  };

  return (
    <>
      {/* <Container fluid className={isOpen ? "admin-header fixed-top h-100" : "admin-header fixed-top"}> */}
      <Container fluid className='admin-header fixed-top d-none d-lg-block'>
        <Row>
          <Col md='12'>
            <Navbar dark expand='lg'>
              <NavbarBrand
                to='/admin-dashboard'
                onClick={toggleSidebar}
                className='navbar-logo'
              >
                {/* <span className='brand-name'>SparkAPT</span> */}
                <span className='brand-name'>
                  <img
                    src={LogoIcon}
                    alt='Logo Icon'
                    className='img-fluid dashboard-logo'
                  />
                </span>
              </NavbarBrand>
              <NavbarToggler onClick={toggle} />

              <Collapse isOpen={isOpen} navbar>
                <Nav navbar className='pl-5'>
                  <NavItem className='py-2'>
                    <NavLink to='/admin-dashboard'>Dashboard</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/admin-properties'>Properties</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/manage-properties'>Manage Properties</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/users'>Users</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/subscriptions'>Subscriptions</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/leads'>Leads</NavLink>
                  </NavItem>
                  <NavItem className='py-2'>
                    <NavLink to='/inquiries'>Inquiries</NavLink>
                  </NavItem>
                </Nav>

                <Nav
                  className='ml-auto d-flex navbar-nav flex-row justify-content-between'
                  navbar
                >
                  <NavItem className='d-flex align-items-center'>
                    <NavLink to='/notifications'>
                      <Icon icon='bell' />
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret className='text-white'>
                      <Avatar
                        name={localStorage.getItem('user_name')}
                        className='mr-2 text-dark'
                        round={true}
                        size='40'
                        color='white'
                      />
                      {localStorage.getItem('user_name')}
                    </DropdownToggle>

                    <DropdownMenu right>
                      <DropdownItem>
                        <NavLink to='/plans'>
                          <Icon icon='tasks' className='mr-3 fa-w-20' />
                          Plans
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='/cities'>
                          <Icon icon='tasks' className='mr-3 fa-w-20' />
                          Cities
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='/admin-profile'>
                          <Icon icon='building' className='mr-3 fa-w-20' />
                          My Profile
                        </NavLink>
                      </DropdownItem>

                      <DropdownItem onClick={toggleModal}>
                        <Icon icon='unlock-alt' className='mr-3 fa-w-20' />
                        Change Password
                      </DropdownItem>

                      <DropdownItem>
                        <NavLink to='/logout'>
                          <Icon icon='sign-out-alt' className='mr-3 fa-w-20' />
                          Logout
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Nav>
              </Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
      {/* Sidebar */}

      {/* Reponsive Navbar */}
      <DashboardResponsiveMenu
        toggleResponsiveModel={() => {
          setIsResponsiveMenuOpen(!isResponsiveMenuOpen);
        }}
        isMenu={isResponsiveMenuOpen}
        content={
          <>
            <Nav navbar className='v-center'>
              <NavItem className='py-2'>
                <NavLink to='/admin-dashboard'>Dashboard</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/admin-properties'>Properties</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/manage-properties'>Manage Properties</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/users'>Users</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/subscriptions'>Subscriptions</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/leads'>Leads</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/inquiries'>Inquiries</NavLink>
              </NavItem>
              <NavItem className='py-2'>
                <NavLink to='/notifications'>Notifications</NavLink>
              </NavItem>
            </Nav>

            <div className='user-profile-bottom'>
              {/* <h6 className="text-left px-3 mb-3">Hello {localStorage.getItem('user_name')}</h6> */}
              <Row>
                <Col>
                  <NavLink to='/plans'>
                    <Icon icon='tasks' className='color-green' />
                    <p>Plans</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='/admin-profile'>
                    <Icon icon='building' className='color-blue' />
                    <p>Profile</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='#' onClick={toggleModal}>
                    <Icon icon='unlock-alt' className='color-yellow' />
                    <p>Change Password</p>
                  </NavLink>
                </Col>

                <Col>
                  <NavLink to='/logout'>
                    <Icon icon='sign-out-alt' className='color-red' />
                    <p>Logout</p>
                  </NavLink>
                </Col>
              </Row>
            </div>
          </>
        }
      />
      {/* Reponsive Navbar */}

      {/* <Container fluid className={display_sidebar ? "property-data bg-silver my-65 pl-245" : "property-data bg-silver my-65"}> */}
      <Container fluid className='property-data my-65'>
        <UpdatePassword
          parentRef={toggleModal.bind(this)}
          displayModal={displayModal}
          token={localStorage.getItem('admin_token')}
        />

        {/* Theme Modal Code */}

        {/* <Button onClick={toggleThemeModal}>
            <Icon
              icon='unlock-alt'
              className='mr-3 fa-w-20'
            />
            Open Theme Modal
          </Button>

          <ThemeModal
            parentRef={toggleThemeModal.bind(this)}
            displayModal={displayThemeModal}
            header="Modal Header"
            btnColor="primary"
            btnText="Submit"
          /> */}

        {/* Theme Modal Code Ends */}

        {renderSwitch()}
      </Container>
    </>
  );
}
